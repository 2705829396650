import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { DataService } from '../data.service';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { UserService } from '../user.service';
var EmployeePickerComponent = /** @class */ (function () {
    function EmployeePickerComponent(dataService, userSerevice) {
        this.dataService = dataService;
        this.userSerevice = userSerevice;
        this.membersGroups = []; // members are references to members array
        /**
         * Subset of members. Can only select one that is available.
         * (Used in order to select only available members in diets, those found in the weekDay).
         * If the array is null or empty, all members can be selected.
         */
        this.availableMembers = [];
        this.placeholder = 'Velg ansatte';
        this.defaultMembers = [];
        this.memberChange = new EventEmitter();
        this.membersInit = new EventEmitter(); // emit value when teammembers have been added
    }
    /**
     * If the defaultMembers input decorator is not empty, it will override the values already in the formControl
     * @param teamMembers the members to be default in case there are no deafult members in the input, nor in the formControl on init
     */
    EmployeePickerComponent.prototype.setDefaultTeamMembers = function (teamMembers) {
        var members = [];
        if (!this.defaultMembers || this.defaultMembers.length <= 0) { // use exisiting value
            this.defaultMembers = this.membersFormCtrl.value;
        }
        if (this.defaultMembers && this.defaultMembers.length > 0) { // add the default members from input
            var _loop_1 = function (exisitingMember) {
                var foundMember = this_1.members.find(function (a) {
                    return (exisitingMember && a && a.unid === exisitingMember.unid);
                });
                if (foundMember) {
                    members = members.concat([foundMember]);
                }
            };
            var this_1 = this;
            for (var _i = 0, _a = this.defaultMembers; _i < _a.length; _i++) {
                var exisitingMember = _a[_i];
                _loop_1(exisitingMember);
            }
            this.membersFormCtrl.setValue(members);
        }
        if (!this.multiple && members && members.length > 0) { // assume first member to be the default
            this.membersFormCtrl.setValue(members[0]);
        }
        if (!this.membersFormCtrl.value) {
            this.membersFormCtrl.setValue("");
        }
        if (this.multiple && this.selectAllTeamMembers && !this.defaultMembers && !this.membersFormCtrl.value) {
            this.membersFormCtrl.setValue(teamMembers);
        }
    };
    EmployeePickerComponent.prototype.selectionChange = function (event) {
        this.memberChange.next(event);
    };
    EmployeePickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var prom1 = this.dataService.getTeamMembers().pipe(take(1));
        var prom2 = this.dataService.getTeamMembers(true).pipe(take(1));
        this.membersGroups.push({ groupName: 'Lag ' + this.userSerevice.teamId, members: [] });
        this.membersGroups.push({ groupName: 'Andre', members: [] });
        forkJoin([prom1, prom2]).subscribe(function (res) {
            _this.members = res[1].slice();
            var teamMembers = res[0];
            _this.addTeamMemberIfMissing(teamMembers);
            _this.setAvailableMembers();
            _this.setDefaultTeamMembers(teamMembers);
            _this.groupTeamMembmers(teamMembers);
            _this.membersInit.next(true);
        });
    };
    /**
     * Ads the current week's teammembers to the list of all members if they are not present.
     * The same applies to default members and members already present in the formControl.
     * @param teamMembers the list of teammembers on your current team for this week.
     */
    EmployeePickerComponent.prototype.addTeamMemberIfMissing = function (teamMembers) {
        var _this = this;
        var membersToAdd = {};
        teamMembers = teamMembers.concat(this.defaultMembers);
        if (this.membersFormCtrl && this.membersFormCtrl.value) {
            teamMembers = teamMembers.concat(this.membersFormCtrl.value);
        }
        teamMembers.forEach(function (teamMember) {
            var foundMem = _this.members.find(function (mem) { return mem && teamMember && mem.unid === teamMember.unid; });
            if (!foundMem && teamMember) {
                membersToAdd[teamMember.unid] = teamMember;
            }
        });
        for (var unid in membersToAdd) {
            if (membersToAdd.hasOwnProperty(unid)) {
                this.members.unshift(membersToAdd[unid]);
            }
        }
    };
    /**
     * If no members are used in the @Input()-decorator, make sure that all members can be selected
     */
    EmployeePickerComponent.prototype.setAvailableMembers = function () {
        if (this.availableMembers && this.availableMembers.length > 0) {
        }
        else {
            this.availableMembers = this.members.slice();
        }
    };
    /**
     * Will group your team at the top, and the rest afterwards in alphabetic order. Will
     * get the members from the this.members list, making sure that we have the reference, and not a copy.
     * @param teamMembers The list of teammembers in your current team for this week
     */
    EmployeePickerComponent.prototype.groupTeamMembmers = function (teamMembers) {
        var _this = this;
        if (teamMembers && teamMembers.length > 0) {
            var groupTeam_1 = this.members.filter(function (mem) {
                return (mem && teamMembers.find(function (teamMem) { return teamMem.unid === mem.unid; }));
            });
            var restOfMembers = this.members.filter(function (mem) {
                return (mem && !groupTeam_1.find(function (teamMem) { return teamMem.unid === mem.unid; }));
            });
            this.membersGroups[0].members = groupTeam_1;
            this.membersGroups[1].members = restOfMembers;
        }
        else {
            this.members.forEach(function (mem) {
                _this.membersGroups[1].members.push(mem);
            });
        }
    };
    EmployeePickerComponent.prototype.ngOnDestroy = function () {
        if (this.memberSub && !this.memberSub.closed) {
            this.memberSub.unsubscribe();
        }
    };
    EmployeePickerComponent.prototype.isAvailable = function (employee) {
        return this.availableMembers.find(function (member) { return member.unid === employee.unid; });
    };
    return EmployeePickerComponent;
}());
export { EmployeePickerComponent };
