import {LatLngTuple} from 'leaflet';
import {MeasuringPoint} from './measuring-point';
import {VerifyArticlesPoint} from './verify-articles-point';
import { ICulvert } from './i-culvert';

export class AllotmentHeader {
// Parsellhode, kun info relevant for visning i parsell-liste på ordren
// "Parsell nr 1"
  title: string;
// unid fra Rekkverk Ordre
  unid: string;
// Rekkverkstype
  rtType: string;
// Endetype, start
  startType: string; // match with compositeArticle.num
// Endetype, slutt
  endType: string; // match with compositeArticle.num
// Unid tilhørende ordre
  orderUnid: string;
// "AGWTF-RSEH"
  orderNum: string;
// docId fra Rekkverk Ordre
  docId: string;
// "K Aurstad entr"
  customer: string;
  /**
   *  Used as status (e.g. 4 is complete). {@link Allotment}
   */
  state: number;
  stopEndFirst: boolean;
// "V", "H"
  sideOfRoad: string;
// Lengde målt opp av selger
  lengthFromOrder: string;
  isComplete: boolean;
  isStarted: boolean;
// Brukernavn for pålogget bruker
  user: string;
// Indicator på kvalitet på de dårligste målepunktet i parsellen
  quality: number;
  isGetac: string;
  roadSection: number;
  startPos: string;
  endPos: string;
  sluttVegKortform: string;
  startVegKortform: string;
  gpsStartPos: LatLngTuple;
  gpsEndPos: LatLngTuple;
  body: string;
  // XXX The rest of the attributes here are new since updated version, used to display information about the saved allotment
  points: Array<MeasuringPoint>;
  // this list will only contain one entry when saved from Electron app
  verifyArticlesPoints: Array<VerifyArticlesPoint>
  rivLengde: number;
  rivDep: number;
  invoiceType: string;
  rivVrak: number;
  culverts: Array<ICulvert>;
  compositeArticles?: Array<{
    quantity: number, // 1.0,
    name: string, // "R20 Innerkurve (R=20-I)",
    saNum: string, // "220",
    comment: string, // "BG",
    id: string // "R=20-I",
    num: string
  }>
}
