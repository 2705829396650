import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimesheetProjectComponent } from './timesheet-project/timesheet-project.component';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {path: 'timesheet-project', component: TimesheetProjectComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TimesheetProjectRoutingModule { }
