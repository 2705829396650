<form class="dialog" #countDialogForm="ngForm" [formGroup]="dialogFormGroup" (ngSubmit)="confirm($event)">
  <button type="button" class="btn-close" color="accent" mat-icon-button (click)="closeDialog()">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
  <app-railing-categories-select
    [railingType]="data.railingType"
    [isDemolition]="data.isDemolition">
  </app-railing-categories-select>
  <button type="submit" class="btn-save" mat-raised-button color="primary">Lagre og lukk</button>
</form>
