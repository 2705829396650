import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../data.service';
import { Article } from '../../article';
import { CompositeArticle } from '../../composite-article';
import { takeUntil, take } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'app-select-article-form',
  templateUrl: './select-article-form.component.html',
  styleUrls: ['./select-article-form.component.scss']
})
export class SelectArticleFormComponent implements OnInit {

  @Input() typeOfArticle: string;
  @Input() placeholder: string;
  @Input() parentFormGroup: FormGroup; // Used by the parent for validation purposes and access.
  @Input() childFormControlName: string; // used by the parent to access the specific controller
  @Input() childValue: CompositeArticle | Article;
  @Input() includeCategories: Array<string> = []; // only add the included categories or all if empty in the dropdown
  @Input() excludeCategories: Array<string> = []; // Exclude present categories
  @Output() onArticleChanged: EventEmitter<Article | CompositeArticle> = new EventEmitter<Article | CompositeArticle>();
  articleFormControl: FormControl = new FormControl(null, [Validators.required]);
  articleFilterCtrl: FormControl = new FormControl(null, [Validators.required]);
  articles: Array<Article | CompositeArticle> = [];
  filteredArticles: ReplaySubject<Array<any>> = new ReplaySubject<Array<any>>(0);
  protected _onDestroy = new Subject<void>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  articleGroups: Array<string> = []; // group by category
  constructor(private dataService: DataService) { }

  ngOnInit() {
    if (this.parentFormGroup && this.childFormControlName) { // the parent component can control the form in this component.
      this.parentFormGroup.addControl(this.childFormControlName, this.articleFormControl);
      this.parentFormGroup.addControl(this.childFormControlName, this.articleFilterCtrl);
    }
    if (this.typeOfArticle === 'compositeArticle') {
      this.dataService.getStructureArticles().subscribe(articlesMap => {
        this.updateArticles(articlesMap);
        this.articles.sort((a, b) => this.sortByCategoryAndName(a, b));
        this.setDefaultArticle(this.childValue);
      });
    } else {
      this.dataService.getArticles().subscribe(articlesMap => {
        this.updateArticles(articlesMap);
        this.articles.sort((a, b) => this.sortByCategoryAndName(a, b));
        this.setDefaultArticle(this.childValue);
      });
    }

    // listen for search field value changes
    this.articleFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterArticles();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  /**
 * Sets the initial value after the filteredArticles are loaded initially
 */
  protected setInitialValue() {
    this.filteredArticles
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredArticles are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a, b) => a && b && a.name === b.name;
      });
  }


  filterArticles() {
    if (!this.articles) return
    let search = this.articleFilterCtrl.value;
    if (!search) {
      this.filteredArticles.next(this.articles);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the ArticleList
    this.filteredArticles.next(
      this.articles.filter(article => article['name'].toLowerCase().indexOf(search) > -1 || article['id'].startsWith(search))
    );
  }

  /**
   * First sort category wise, then sort by name
   */
  sortByCategoryAndName(a: Article | CompositeArticle, b: Article | CompositeArticle): number {
    if (a.category && !Array.isArray(a.category) && !Array.isArray(b.category)) {
      const order = a.category.toString().localeCompare(b.category.toString());
      if (order !== 0) {
        return order;
      }
    }
    if (a.id && b.id && a.id.localeCompare(b.id) !== 0) {
      return a.id.localeCompare(b.id);
    } else if (a.name && b.name && a.name.localeCompare(b.name) !== 0) {
      return a.name.localeCompare(b.name);
    } else {
      return 0;
    }
  }
  onChanged(event) {
    this.onArticleChanged.emit(this.articleFormControl.value);
  }

  setValue(article: CompositeArticle) {
    this.setDefaultArticle(article);
  }
  private updateArticles(articlesMap: { [key: string]: CompositeArticle | Article }) {
    this.articles = [];
    for (const key in articlesMap) {
      if (articlesMap.hasOwnProperty(key)) {
        if ('cat2' in articlesMap[key]) {
          // TODO (john) create a bug report to the Angular team?
          // XXX Cannot use property length, as it is used in the validation of objects in Angular's FormsModule, and fails if it is 0.
          const regularArticle = articlesMap[key] as Article;
          if (!regularArticle.length || regularArticle.length === 0) { delete regularArticle.length; }
          this.articles.push(<Article>articlesMap[key]);
        } else {
          this.articles.push(articlesMap[key]);
        }
      }
    }
    this.filteredArticles.next(this.articles);
  }
  /**
   * Set the default article or composite article in the dropdown if present.
   * The id of a regular article is unique, but not on a composite article.
   * We have to use the .num attribute if it is a composite article.
   * @param article either a composite article or a regular article
   */
  private setDefaultArticle(article: Article | CompositeArticle) {
    if (article) {
      let inputKey = article.id;
      if ((<CompositeArticle>article).num) {
        inputKey = (<CompositeArticle>article).num;
      }
      for (const art of this.articles) {
        let artKey = art.id;
        if ((<CompositeArticle>art).num) {
          artKey = (<CompositeArticle>art).num;
        }
        if (inputKey === artKey) {
          this.articleFormControl.setValue(art);
        }
      }
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
