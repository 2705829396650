export class IsiRoadRef {
// "EV", "FV" etc.
  roadCategory: string;

  roadStatus: string;

// Vegnr
  roadNumber: number;


  section: number;
  subSection: number;
  shortForm: string;

// Kilometrering
  meter: number;

// Fylkeskode
  county: number;
// Kommunekode
  municipality: number;
// Gatenavn
  streetName: string;
  curve: {
    streetName: string;
    municipality: number;
  }
}
