<div class="proposalList-container">
      <button class="btn-nyforslag" mat-flat-button color="primary" (click)="createNewProposal()" > Nytt Forslag </button>
      <table mat-table #table [dataSource] = "proposals" *ngIf="proposals" [class.isMobile]= "isMobile">
            <ng-container matColumnDef="proposalId">
                  <th mat-header-cell *matHeaderCellDef>proposalId</th>
                  <td mat-cell *matCellDef="let proposal"> 
                        <span class="mobile-label">proposalId:</span> 
                        {{proposal?.unid}} 
                  </td>
            </ng-container>
            <ng-container matColumnDef="proposal">
                  <th mat-header-cell *matHeaderCellDef>Forslag</th>
                  <td mat-cell *matCellDef="let proposal"> 
                        <span class="mobile-label">Forslag:</span> 
                        <a (click)="viewProposal(proposal?.unid)"> {{proposal?.subject}} </a>
                  </td>
            </ng-container>
            <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef>Date</th>
                  <td mat-cell *matCellDef="let proposal"> 
                        <span class="mobile-label">Date:</span> 
                        {{proposal?.docDate | date: 'dd.MM.yyyy'}} 
                  </td>
            </ng-container>
            <ng-container matColumnDef="author">
                  <th mat-header-cell *matHeaderCellDef>Av</th>
                  <td mat-cell *matCellDef="let proposal"> 
                        <span class="mobile-label">Av:</span>
                        {{proposal?.author}} 
                  </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></tr>
     </table> 
</div>
