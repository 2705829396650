import { Component, OnInit, Inject, OnDestroy, HostBinding } from '@angular/core';
import { AbstractDialogComponent } from '../../abstract-dialog/abstract-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { Order } from '../../../order';
import { DataService } from '../../../data.service';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '../../header/header.service';
import { Subscription } from 'rxjs';
import { OrderApprovalArticlesComponent } from '../order-approval-articles/order-approval-articles.component';
import { MediaService } from '../../../shared/media.service';

@Component({
  selector: 'app-order-approval',
  templateUrl: './order-approval.component.html',
  styleUrls: ['./order-approval.component.scss']
})
export class OrderApprovalComponent implements OnInit, OnDestroy {
  @HostBinding('class.approve-order-component') hostClass = true;
  order: Order;
  approvalOrder: any;
  approveOrder: any;
  displayedColumns: string[] = ['name', 'hoursWork', 'hoursTravel','hoursOvertime50', 'hoursNight'];
  orderFormGroup: FormGroup;
  orderUnid: string;
  orderDetailsSubscription: Subscription;
  isMobileView: boolean;
  parsellsApproved:boolean = false; 
  sjaAvailable:boolean = false;
  
  constructor( protected dialogRef: MatDialogRef<AbstractDialogComponent>,
    private dataService: DataService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private dialog: MatDialog,
    private mediaService: MediaService) {
      this.mediaService.getMedia().subscribe( media => {
        this.isMobileView = media.isMobileView;
      });
      this.orderFormGroup = new FormGroup({
        kilometer: new FormControl(),
        warnings: new FormControl(),
        lights: new FormControl()
      })
  }

  ngOnInit() {
    this.orderUnid = this.route.snapshot.paramMap.get('orderUnid');
    this.headerService.addNavItem({
      route: `/godkjennOrder/${this.orderUnid}`,
      label: 'Godkjenn Ordre',
      queryParams: ''
    });
    this.getOrderDetails(this.orderUnid);
    this.getParsellsStatus(this.orderUnid);
    this.dataService.getOrderForApproval(this.orderUnid).subscribe(res => {
      this.approvalOrder = res;
      this.sjaAvailable = this.approvalOrder.sjaSummaryList.length > 0 ? true : false;
      this.orderFormGroup.setValue({kilometer: this.approvalOrder.kmDrive, warnings:this.approvalOrder.numWarnings, lights:this.approvalOrder.numLights });
    })
  }
  getParsellsStatus(orderUnid: string) {
    this.dataService.getParcels(orderUnid).subscribe(res => {
      if (res.length===0) {
        // ordre har ingen parseller
        this.parsellsApproved = true;
      }
      for (const data of res) {
        if (data.state !== 0 && data.state !== 2 && data.state !== 3) { // ikke kontroller hvis utelatt
          if (data.state < 4) {
            this.parsellsApproved = false;
            break;
          } else if (data.state >= 4) {
            this.parsellsApproved = true;
          }
        }
      }
      console.log(this.parsellsApproved);
    });
  }

  getOrderDetails(orderUnid: string) {
    this.orderDetailsSubscription = this.dataService.getOrder(orderUnid).subscribe((order) => {
      this.order = order;
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(OrderApprovalArticlesComponent, {
      height: '80%',
      width: '90%',
      data: { type: OrderApprovalArticlesComponent, OrderUnid: this.orderUnid, OrderNumber: this.order.snum, OrderStatus: this.order.status, readonly: false }
    });
    dialogRef.beforeClosed().subscribe(result => { });
    dialogRef.afterClosed().subscribe(result => { });
  }
  
  SaveAsOrderCompleted() {
    this.approveOrder = this.approvalOrder
    this.approveOrder.kmDrive = this.orderFormGroup.get("kilometer").value;
    this.approveOrder.numWarnings = this.orderFormGroup.get("warnings").value;
    this.approveOrder.numLights = this.orderFormGroup.get("lights").value;
    console.log(this.orderUnid, this.approveOrder);
    this.dataService.saveOrderAsApproved(this.approvalOrder, this.orderUnid).subscribe(res => {
      console.log(res);
      this.order.status = "7";
      this.snackbar.open("Ordre er godkjent", '', {duration: 5000});
    })
  }
  ngOnDestroy() {
    this.headerService.removeNavItem({
      route: `/godkjennOrder/${this.orderUnid}`,
      label: 'Godkjenn Ordre',
      queryParams: ''
    });
    if (this.orderDetailsSubscription) {
      this.orderDetailsSubscription.unsubscribe();
    }
  }
}
