import { Component, OnInit, Input } from '@angular/core';
import { ClickFunction } from './click-function';

@Component({
  selector: 'app-dynamic-button',
  templateUrl: './dynamic-button.component.html',
  styleUrls: ['./dynamic-button.component.scss']
})
export class DynamicButtonComponent implements OnInit {

  @Input() btnTxt = '';
  @Input() btnIcon = '';
  @Input() clickFunction: ClickFunction;
  constructor() { }

  ngOnInit() {
  }

}
