import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from './data.service';
import { ElectronService } from './electron.service';
import { SettingsService } from './settings.service';
import { MatBottomSheet } from '@angular/material';
import { DownloadProgressComponent } from './components/download-progress/download-progress.component';
import { GpsService } from './gps/gps.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showDownloadIcon: boolean;
  @HostListener('document:touchstart', ['$event'])
  globalTouchEvent(event) {
    if (event) {
      event.stopPropagation();
    }
    const activeElement: any = document.activeElement;
    if (activeElement) {
      activeElement.blur();
    }
  }

  constructor(private translate: TranslateService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private dataService: DataService,
    private electronService: ElectronService,
    private renderer: Renderer2,
    private settingsService: SettingsService,
    private _bottomSheet: MatBottomSheet) {
    this.translate.setDefaultLang('en');
    if (this.electronService.isElectron()) {
      window.require('electron').ipcRenderer.on('navigate', (event, route) => {
        if (route === 'logout') {
          dataService.logout().subscribe(res => {
            document.title = 'Arvid Gjerde App';
            this.router.navigate(['/']);
          });
        } else {
          this.router.navigate(['/' + route]);
        }
      });
      this.electronService.downloadProgressAction$.subscribe(event => {
        if (event === 'show') {
          this.showDownloadIcon = true;
          this._bottomSheet.open(DownloadProgressComponent);
        } else if (event === 'hide') {
          this._bottomSheet.dismiss();
          this.showDownloadIcon = false;
        }
      })
      /*window.require('electron').ipcRenderer.on('download-progress', (event, progress) => {
        console.log("download-progress", progress);
        this._bottomSheet.open(DownloadProgressComponent);
      });*/
    }
    this.settingsService.getNightMode().subscribe( isNightMode => {
      this.toggleTheme(isNightMode);
    })
  }

  showDownloadProgress() {
    this._bottomSheet.open(DownloadProgressComponent);
  }

  toggleTheme(isNightMode: boolean) {
    const htmlRoots = document.getElementsByTagName('html');
    const bodyRoots = document.getElementsByTagName('body');
    if (bodyRoots && bodyRoots.length === 1 && htmlRoots && htmlRoots.length === 1) {
      if (!isNightMode) {
        this.renderer.removeClass(bodyRoots[0], 'dark-theme');
        this.renderer.removeClass(htmlRoots[0], 'dark-theme');
      } else {
        this.renderer.addClass(bodyRoots[0], 'dark-theme');
        this.renderer.addClass(htmlRoots[0], 'dark-theme');
      }
    }
  }

}
