import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-quality-bars',
  templateUrl: './quality-bars.component.html',
  styleUrls: ['./quality-bars.component.scss']
})
export class QualityBarsComponent implements OnInit, OnChanges {

  @Input() satQuality: number;
  qualityBars: Array<boolean> = [true, false, false, false, false];
  constructor() { }

  ngOnInit() {
    this.setQualityBars();
  }

  ngOnChanges() {
    this.setQualityBars();
  }

  private setQualityBars(): void {
    let quality: number = (this.satQuality * 2) - 1 || 0;
    for (let i = 1; i < 5; i++) {
      this.qualityBars[i] = --quality > 0;
    }
  }

}
