<mat-card>
    <mat-card-header>
        <div mat-card-avatar><mat-icon>swap_vert</mat-icon></div>
        <mat-card-title>Replikering</mat-card-title>
        
    </mat-card-header>
    <mat-card-actions>
            <button mat-raised-button color="primary" (click)="startReplication()" [disabled]="isReplicating">Start</button>
    </mat-card-actions>
    <mat-card-content>
            <mat-vertical-stepper #matStepper>
                    <ng-template matStepperIcon="edit">
                        <mat-icon>done</mat-icon>
                    </ng-template>
                    <mat-step *ngFor="let database of databases" [label]="database.title" [completed]="database.completed">
                
                        <span class="mat-card-subtitle">{{database.status}}</span>
                        <span class="mat-card-subtitle">replikering med {{database.withServer}}</span>
                       <!--  <span class="mat-card-subtitle" *ngIf="database.completed">replikert med {{database.withServer}}</span> -->
                        
                        <mat-progress-bar mode="buffer" [value]="database.progress" color="primary" *ngIf="!database.completed"></mat-progress-bar>
                        <div *ngIf="database.completed" class="mat-card-content">
                            <p>
                                <span>Hentet fra server: {{database.replicationSummary?.pullNotesAdded}}</span>
                            </p>
                            <p>
                                <span>Slettinger fra server: {{database.replicationSummary?.pullNotesDeleted}}</span>
                            </p>
                            <p>
                                <span>Sendt til server: {{database.replicationSummary?.pushNotesAdded}}</span>
                            </p>
                            <p>
                                <span>Slettinger sendt til server: {{database.replicationSummary?.pushNotesDeleted}}</span>
                            </p>
                        </div>
                    </mat-step>
                </mat-vertical-stepper>
    </mat-card-content>
</mat-card>

