import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'signsRequire'
})
export class SignsRequirePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value === '1' ? 'Ja' : 'Nei';
  }

}
