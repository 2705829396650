<form class="dialog" [formGroup]="dialogFormGroup" (ngSubmit)="saveProposal($event)">
      <h2> Forslag </h2>
      <button class="btn-close" color="accent" type="button" mat-icon-button (click)="closeDialog()">
            <mat-icon aria-label="Close">close</mat-icon>
      </button>
      <section>
            <mat-form-field>
                  <mat-label>Tittel</mat-label>
                  <input matInput  
                  formControlName="subject" 
                  name="subject"  
                  placeholder="Angi tittel"
                  required >
            </mat-form-field>
            <mat-form-field>
                  <mat-label>Område</mat-label>
                  <mat-select formControlName="department" 
                  name="department"  matNativeControl required>
                        <mat-option *ngFor="let department of departments" [value] = "department">
                              {{ department }}
                        </mat-option>
                  </mat-select>
            </mat-form-field>
            <mat-form-field>
                  <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" 
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="5" 
                  formControlName="body" 
                  name="body" 
                  placeholder="Forslag til Forbedring"></textarea>
            </mat-form-field>
      </section>
      <button type="submit" class="btn-save success" color="primary" mat-raised-button [disabled]="!dialogFormGroup.valid">
            Lagre Forslag
      </button>
</form>
