import { Component, OnInit, Inject, HostBinding } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { IsiWeekPerson } from '../../../isi-week-person';
import { IsiWeekHour } from '../../../isi-week-hour';
import { OrderLine } from '../../../order-line';
import { TimeSheetService } from '../../../time-sheet.service';
import * as moment from 'moment';
import { take, debounceTime } from 'rxjs/operators';
import { RequiredIfOtherFieldsPresentDirective } from '../../../shared/directives/required-if-other-fields-present.directive';
import { MediaMatcher } from '@angular/cdk/layout';
import { MediaService } from '../../../shared/media.service';

@Component({
  selector: 'app-time-sheet-registration-dialog',
  templateUrl: './time-sheet-registration-dialog.component.html',
  styleUrls: ['./time-sheet-registration-dialog.component.scss']
})
export class TimeSheetRegistrationDialogComponent implements OnInit {
  @HostBinding('class.mobile-view') isMobileView = false;
  formGroup: FormGroup;
  members: Array<IsiWeekPerson> = [];
  membersFormCtrl: FormControl;
  internFormCtrl: FormControl;
  sumHoursCtrl: FormControl;
  sumTravelCtrl: FormControl;
  sum50Ctrl: FormControl;
  //sum100Ctrl: FormControl;
  sumNightCtrl: FormControl;
  commentCtrl: FormControl;
  sumHours = 0;
  sumHoursTravel = 0
  sumHours50 = 0;
  //sumHours100 = 0;
  sumHoursNight = 0;
  comment = '';
  defaultTeamMembers: Array<IsiWeekPerson>;
  defaultIntern: IsiWeekPerson;
  savingInProgress = false;
  constructor(private dialogRef: MatDialogRef<TimeSheetRegistrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {weekHour: IsiWeekHour, orderLine: OrderLine},
    private timeSheetService: TimeSheetService,
    private snackbar: MatSnackBar,
    private mediaService: MediaService) {
      // TODO (john) create a custom validation function, making sure that the sum of hoursWork is always
      // more or the same as the sum of all overtime + travel
      this.mediaService.getMedia().subscribe( media => {
        this.isMobileView = media.isMobileView;
      });
  }
  ngOnInit() {
    if (this.data && this.data.orderLine && this.data.orderLine.teamMembers) {
      this.defaultTeamMembers = this.data.orderLine.teamMembers;
      this.defaultIntern = this.data.orderLine.intern;
    }
    if (this.data && this.data.weekHour) {
      this.sumHours = this.data.weekHour.hoursWork;
      this.sumHoursTravel = this.data.weekHour.hoursTravel;
      this.sumHours50 = this.data.weekHour.hoursOvertime50;
      //this.sumHours100 = this.data.weekHour.hoursOvertime100;
      this.sumHoursNight = this.data.weekHour.hoursNight;
      this.comment = this.data.weekHour.body;
      if (this.data.weekHour.unid) { // override default intern if existing weekHour.
        this.defaultIntern = this.data.weekHour.intern;
      }
    }
    // XXX Value of members will be set inside the employeepicker.
    this.membersFormCtrl = new FormControl('', [Validators.required]);
    this.membersFormCtrl.disable();
    this.internFormCtrl = new FormControl('', []);
    this.sumHoursCtrl = new FormControl(this.sumHours, [Validators.required, Validators.min(0), Validators.max(24)]);
    this.sumTravelCtrl = new FormControl(this.sumHoursTravel, [Validators.min(0), Validators.max(24)]);
    this.sum50Ctrl = new FormControl(this.sumHours50, [Validators.min(0), Validators.max(24)]);
    this.sumNightCtrl = new FormControl(this.sumHoursNight, [Validators.min(0), Validators.max(24)]);
    this.commentCtrl = new FormControl(this.comment);
    this.formGroup = new FormGroup({
      'members': this.membersFormCtrl,
      'intern': this.internFormCtrl,
      'sumHours': this.sumHoursCtrl,
      'sumTravel': this.sumTravelCtrl,
      'sum50': this.sum50Ctrl,
      'sumHoursNight': this.sumNightCtrl,
      'comment': this.commentCtrl
    }, [RequiredIfOtherFieldsPresentDirective('comment', ['sum50', 'sumHoursNight'])]);
  }
  teamMembersInit(event: boolean) {
    console.log("evt", event);
    console.log("this.membersFormCtrl.value.", this.membersFormCtrl.value);
    if (this.data && this.data.weekHour && this.data.weekHour.intern) {
      this.internFormCtrl.setValue(this.membersFormCtrl.value.find( (mem) => {
        return mem.unid === this.data.weekHour.intern.unid;
      }));
    }
  }
  save() {
    if (!this.timeSheetService.canEditWeekHour(moment(this.data.weekHour.haDate))) {
      this.snackbar.open('Lønnsberegningen er allerede utført, og timeregistreringen kan ikke endres', 'OK', { duration: 8000 });
      return;
    }
    if (!this.formGroup.valid) {
      this.snackbar.open('Noen påkrevde felt er ikke fylt ut', '', {duration: 4000});
      return;
    } else if (!this.formGroup.dirty) {
      this.snackbar.open('Ingen endringer å lagre', '', { duration: 4000 });
      return;
    }
    this.savingInProgress = true;
    // TODO (john) do a post, put on loading, wait for response, close if success, prompt toastr.
    const postWeekHour: IsiWeekHour = this.data.weekHour || new IsiWeekHour();
    postWeekHour.hoursNight = this.sumNightCtrl.value;
    postWeekHour.hoursOvertime50 = this.sum50Ctrl.value;
    postWeekHour.hoursTravel = this.sumTravelCtrl.value;
    postWeekHour.hoursWork = this.sumHoursCtrl.value;
    postWeekHour.body = this.commentCtrl.value;
    postWeekHour.teamMembers = this.membersFormCtrl.value;
    postWeekHour.intern = this.internFormCtrl.value;
    postWeekHour.lineKey = postWeekHour.lineKey || this.data.orderLine.id;
    postWeekHour.orderSubject = this.data.orderLine.orderSubject;
    postWeekHour.orderSNum = this.data.orderLine.orderSNum;
    postWeekHour.artNumber = this.data.orderLine.artNumber;
    postWeekHour.orderNum = this.data.orderLine.orderNum;
    postWeekHour.projName = this.data.orderLine.projName;
    postWeekHour.projNum = this.data.orderLine.projNum;
    this.timeSheetService.saveWeekHour(postWeekHour).pipe(take(1)).subscribe( res => {
      this.savingInProgress = false;
      postWeekHour.unid = res.unid;
      this.dialogRef.close({ weekHour: postWeekHour, orderLine: this.data.orderLine });
    }, error => {
      this.savingInProgress = false;
      this.snackbar.open('Kunne ikke lagre timeføring, prøv igjen', '', {duration: 5000});
    });
  }
  onFocusInHours(event, ctrl: FormControl) {
    if (ctrl && ctrl.value === 0) {
      ctrl.reset();
    }
  }
  onFocusOutHours(event, ctrl: FormControl) {
    if (ctrl && !ctrl.value) {
      ctrl.setValue(0);
    }
  }

}
