import {DataSource} from '@angular/cdk/collections';
import {Observable} from 'rxjs';
import { Sign } from './sign';

export class SignDataSource extends DataSource<Sign> {


    constructor(private _list$: Observable<Sign[]>) {
        super();
    }

    connect(): Observable<Sign[]> {
        return this._list$;
    }

    disconnect() {
    }
}
