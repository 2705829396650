import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatStepper, MAT_DIALOG_DATA } from '@angular/material';
import { CompositeArticle } from '../../composite-article';
import { Article } from '../../article';
import { SelectArticleFormComponent } from '../select-article-form/select-article-form.component';
import { DataService } from '../../data.service';
import { min } from 'rxjs/operators';
import { RailingType } from '../../railing-type';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-register-parsell-dialog',
  templateUrl: './register-parsell-dialog.component.html',
  styleUrls: ['./register-parsell-dialog.component.scss']
})
export class RegisterParsellDialogComponent extends AbstractDialogComponent implements OnInit {
  @ViewChild('startEndArticleSelect', { static: false }) startEndArticleSelect: SelectArticleFormComponent;
  @ViewChild('compArticles', { static: false }) compArticles: Array<CompositeArticle>;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  startRtType: any;
  endRtType: any;
  rivingRtType: any;
  startArticle: CompositeArticle; // the default is the nearest
  endArticle: CompositeArticle;
  articles: Array<Article>;
  public dialogFormGroup: FormGroup;
  compositeArticle: CompositeArticle;
  compositeArticles: Array<CompositeArticle> = [];
  compCalcArticles: Array<any> = [];
  selectedRailingType: RailingType;
  tmpNewCompoisteArticles: Array<CompositeArticle> = [];
  compositeArticlesSubject: Subject<Array<CompositeArticle>> = new Subject();
  startType: any;
  endType: any;
  confirm(event: any): void {
    throw new Error("Method not implemented.");
  }


  constructor(
    protected dialogRef: MatDialogRef<AbstractDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService) {
    super(dialogRef);
    if (!data.demolitionType) { data.demolitionType = '' };
    this.dialogFormGroup = new FormGroup({
      buildingForm: new FormGroup({
        startMeter: new FormControl(0, Validators.required),
        sluttMeter: new FormControl(0, Validators.required),
        lengde: new FormControl(0, [Validators.required, Validators.min(0)]),
        skinner: new FormControl(0, [Validators.min(0)]),
        side: new FormControl('H'),
        // startArticle: new FormControl('', Validators.required),
        // endArticle: new FormControl('', Validators.required)
      }),
      demolitionForm: new FormGroup({
        lengde: new FormControl(0),
        vrakskinner: new FormControl(0),
        vrakstolper: new FormControl(0),
        deponering: new FormControl(0)
      }),
      strukturArticleForm: new FormGroup({
        quantity: new FormControl(1, [Validators.required, Validators.min(1)])
      })
    });
  }

  ngOnInit() {
    this.startRtType = this.data.rtType;
    this.endRtType = this.data.rtType;
    this.rivingRtType = this.data.rtType;
    this.startType = this.data.startType;
    this.endType = this.data.endType;
    this.selectedRailingType = this.data.selectedRailingType;
    this.setInitialValues();
  }
  setInitialValues() {
    const parcelHeader = this.data.parcelHeader;
    let lengthFromOrder = parcelHeader.lengthFromOrder ? parcelHeader.lengthFromOrder : 0;

    const  startPos = parcelHeader.startPos ? parcelHeader.startPos : 0,
    endPos = parcelHeader.endPos ? parcelHeader.endPos : 0;
    if (lengthFromOrder === 0 && (startPos !== 0 || endPos !== 0)) {
      lengthFromOrder = Math.abs(startPos - endPos);
    }
    const skinner = Math.ceil(lengthFromOrder / 4);

    this.dialogFormGroup.get('buildingForm').patchValue({
      lengde: Number(lengthFromOrder), // make it a number, as it can be a string if returned from the order
      startMeter: startPos,
      sluttMeter: endPos,
      skinner: skinner
    })

  }
  startRtTypeChanged(railingType) {
    console.log(railingType);
    this.startRtType = railingType;
  }
  endRtTypeChanged(railingType) {
    this.endRtType = railingType;
  }
  rivingRtTypeChanged(railingType) {
    this.rivingRtType = railingType
  }
  onChildEndArticleChanged(article: Article | CompositeArticle) {
    if ('category' in article && typeof article.category === "string") {
      const articleCpy = Object.assign({}, <CompositeArticle>article);
      this.endType = articleCpy;
      console.log(this.endType);
      this.dialogFormGroup.get('buildingForm').patchValue({ endArticle: this.endType.num });
    }
  }
  onChildStartArticleChanged(article: Article | CompositeArticle) {
    if ('category' in article && typeof article.category === "string") {
      const articleCpy = Object.assign({}, <CompositeArticle>article);
      this.startType = articleCpy;
      console.log('onchildstartchange', this.startType);
      this.dialogFormGroup.get('buildingForm').patchValue({ startArticle: this.startType.num });
    }
  }
  onCompositeArticleChanged(compArticle: CompositeArticle) {
    this.compositeArticle = compArticle;
  }
  calculateLength() {
    const buildFormValue = this.dialogFormGroup.get('buildingForm').value;
    const startMeter = buildFormValue.startMeter;
    const endMeter = buildFormValue.sluttMeter;
    const lengde = Math.abs(endMeter - startMeter);
    const skinner = Math.ceil(lengde / 4);
    this.dialogFormGroup.get('buildingForm').patchValue({ lengde: lengde, skinner: skinner });
  }
  skinnerUpdate() {
    const buildFormValue = this.dialogFormGroup.get('buildingForm').value;
    const skinner = buildFormValue.skinner;
    const lengde = skinner * 4;
    const endMeter = parseInt(buildFormValue.startMeter) + lengde;
    this.dialogFormGroup.get('buildingForm').patchValue({ lengde: lengde, sluttMeter: endMeter });
  }
  addStrukturArticle() {
    const strukturData = this.dialogFormGroup.get('strukturArticleForm').value;
    // const compArticle = [];
    if (this.compositeArticle) {
      this.compCalcArticles.push({ num: this.compositeArticle.num, selectedQuantity: strukturData.quantity.toString() });
      const compositeArticleCpy = Object.assign({}, <CompositeArticle>this.compositeArticle);
      compositeArticleCpy.selectedQuantity = strukturData.quantity;
      this.tmpNewCompoisteArticles.push(compositeArticleCpy);
      this.compositeArticles = this.tmpNewCompoisteArticles;
    }
    this.notifyChildren();
  }
  notifyChildren() {
    this.compositeArticlesSubject.next(this.compositeArticles);
  }
  calculateArticles() {
    const buildData = this.dialogFormGroup.get('buildingForm').value;
    const rivingData = this.dialogFormGroup.get('demolitionForm').value;
    if (this.dialogFormGroup.get('buildingForm').valid) {
      this.dataService.getCalculatedArticlesWithoutGps(
        this.startRtType.id,
        this.startType.num,
        this.endType.num,
        buildData.lengde,
        buildData.side,
        this.compCalcArticles,
        this.rivingRtType.id,
        rivingData.lengde,
        this.data.parcel.invoiceType,
        this.data.parcel.unid
      ).subscribe(data => {
        this.articles = data
        // add start & end types to composite articles
        this.startType.selectedQuantity = 1;
        this.endType.selectedQuantity = 1;
        this.compositeArticles.push(this.startType);
        this.compositeArticles.push(this.endType);
        const resData = {
          type: RegisterParsellDialogComponent,
          articles: this.articles,
          length: Number(buildData.lengde),
          side: buildData.side,
          startArticle: this.startType,
          endArticle: this.endType,
          demolitionLength: rivingData.lengde,
          demolitionRailWreck: rivingData.vrakskinner,
          demolitionStickWreck: rivingData.vrakstolper,
          compositeArticles: this.compositeArticles,
          rtType: this.data.rtType.id
        };
        this.dialogRef.close(resData);
      })
    } else {
      this.stepper.selectedIndex = 0;
    }

    /* console.log(buildData,"-",this.startArticle.num,"-",this.endArticle.num,"-",this.data.rtType.id); */
  }
  registerParsell() {

  }
}
