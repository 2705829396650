<div class="dayheader" >
  <h2>Dag kommentarer</h2>
  <div id="dayButton">
    <button mat-raised-button color="primary" (click)="newDay()" [disabled]="archived">Ny dag</button>
  </div>
</div>

<mat-table [dataSource]="dataSource" [class.mobile-view]="isMobile" multiTemplateDataRows matSort>
  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Dato </mat-header-cell>
    <mat-cell *matCellDef="let day">
      <span class="mobile-label">Dato:</span>{{day.date|date:'dd.MM.yyyy'}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="comment">
    <mat-header-cell *matHeaderCellDef> Kommentar </mat-header-cell>
    <mat-cell *matCellDef="let day">
      <span class="mobile-label">Kommentar:</span>{{day.comment|slice:0:100 }}{{day.comment?.length>100 ? '...' : ''}}      
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="expand">
    <mat-header-cell *matHeaderCellDef>Detaljer</mat-header-cell>
    <mat-cell *matCellDef="let day">
      <button mat-raised-button color="accent" (click)="openDialog(day)" *ngIf="isMobile">
        <span>Vis detaljer</span>
        <!-- <span *ngIf="expandedElement===day">Skjul detaljer</span> -->
      </button>
      &nbsp;  
      <button mat-raised-button (click)="deleteDay(day)" color="primary" *ngIf="!archived && isMobile">
        Slett
      </button>       
      <button mat-icon-button (click)="expandedElement = expandedElement === day ? null : day" color="accent" *ngIf="!isMobile">
          <mat-icon aria-label="Vis detaljer" class="detail-icon" [ngClass]="{'clicked': expandedElement === day}">
            arrow_right_alt</mat-icon>
        </button>
      </mat-cell>
  </ng-container>
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let day" [attr.colspan]="columnsToDisplay.length">
      <div class="example-element-detail" [@detailExpand]="day == expandedElement ? 'expanded' : 'collapsed'">
        <div class="example-element-description">
            <div class="dayheader">
                <!-- <button mat-icon-button (click)="deleteDay(i)" style="grid-column: 2; grid-row: 1; justify-self: center;">
                  <mat-icon aria-label="Slett dag kommentar">clear</mat-icon>
                </button>-->
                <button mat-raised-button color="primary" style="grid-column: 8; grid-row: 2; justify-self: center; height:40px"
                  *ngIf="(dayComment.dirty || dayDate.dirty)" (click)="save(day, dayComment, dayDate)">
                  <mat-icon>save</mat-icon>Lagre
                </button> 
                <mat-form-field style="grid-column: 1; grid-row: 2;">
                  <input matInput [matDatepicker]="picker" placeholder="Velg dato" [(ngModel)]="day.date" #dayDate="ngModel"
                    required>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="dayheader">
                <div style="grid-column: 1/4;">
                  <mat-form-field style="width: 100%">
                    <mat-label>Kommentar</mat-label>
                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="10" [(ngModel)]="day.comment" #dayComment="ngModel" required></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div style="margin-bottom: 25px">
                <app-sja-risk-factors title="Risikomoment" [riskFactors]="day.riskFactors"
                  (riskfactorAdded)="onRiskFactorAdded(day)" [archived]="archived">
                </app-sja-risk-factors>
              </div>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>Handling </mat-header-cell>
      <mat-cell *matCellDef="let day;">
        <button mat-icon-button (click)="deleteDay(day)" *ngIf="!archived && !isMobile">
          <mat-icon aria-label="Slett dag kommentar">clear</mat-icon>
        </button>    
      </mat-cell>
    </ng-container>
  <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
  <mat-row *matRowDef="let myRowData; columns: columnsToDisplay;" class="example-element-row"
    [class.example-expanded-row]="expandedElement === myRowData"></mat-row>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']; " class="example-detail-row"></tr>
</mat-table>


<!-- <div *ngFor="let day of days; let i = index;">
  <div class="dayheader">
    <h2 style="grid-column: 1; grid-row: 1">{{day.date|date:'dd.MM.yy'}}</h2>
    <button mat-icon-button (click)="deleteDay(i)" style="grid-column: 2; grid-row: 1; justify-self: center;">
      <mat-icon aria-label="Slett dag kommentar">clear</mat-icon>
    </button>
    <button mat-raised-button style="grid-column: 3; grid-row: 1; justify-self: end;"
      *ngIf="(dayComment.dirty || dayDate.dirty)" (click)="save(day)">
      <mat-icon>save</mat-icon>Lagre
    </button>
    <mat-form-field style="grid-column: 1; grid-row: 2;">
      <input matInput [matDatepicker]="picker" placeholder="Velg dato" [(ngModel)]="day.date" #dayDate="ngModel"
        required>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="dayheader">
    <div style="grid-column: 1/4;">
      <mat-form-field style="width: 100%">
        <mat-label>Kommentar</mat-label>
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="10" [(ngModel)]="day.comment" #dayComment="ngModel" required></textarea>
      </mat-form-field>
    </div>
  </div>
  <div style="margin-bottom: 25px">
    <app-sja-risk-factors title="Risikomoment" [riskFactors]="day.riskFactors"
      (riskfactorAdded)="onRiskFactorAdded(day)">
    </app-sja-risk-factors>
  </div>
</div> -->