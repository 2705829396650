import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {Router} from '@angular/router';
import { DataService } from '../../data.service';
import { UserService } from '../../user.service';
import { NotesUser } from '../../notes-user';
import { AppConfig } from '../../../environments/environment';
import { ElectronService } from '../../electron.service';
import { MediaService } from '../../shared/media.service';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  selectedValue: string;
  password: string;
  config;
  errorMessage: string;
  users = [];
  isLoggingIn: boolean;
  isMobileView: boolean;

  loginForm = new FormGroup ({
    user: new FormControl(),
    password: new FormControl()
  });

  constructor(private dataService: DataService,
              private router: Router,
              private userService: UserService,
              public electronService: ElectronService,
              private mediaService: MediaService) {
    this.isLoggingIn = false;
    this.mediaService.getMedia().subscribe( media => {
      this.isMobileView = media.isMobileView;
    })
  }

  ngOnInit() {
    this.dataService.getLoginUsers().pipe().subscribe(data => {
      this.users = data;
    });
    /*this.dataService.getNotesIniDir().subscribe(res => {
      this.config = this.notesIni.init(res.path);
    })*/

  }

  loginUser() {
    // reset message & orderCache
    this.errorMessage = '';
    this.isLoggingIn = true;
    this.dataService.clearCache();
    // 1. modify notes.ini for selected user
    let user: NotesUser = new NotesUser();
    if (this.electronService.isElectron()) {
      user = this.loginForm.value.user;
    } else {
      user.notesName = this.loginForm.value.user;
      user.fullName = this.loginForm.value.user;
    }
    user.version = this.electronService.getVersion();
   // this.config.setUser(user);
   // this.config.write();
    user.password = this.loginForm.value.password;
    
    // 2. call login service
    if (this.electronService.isElectron()) {
      this.dataService.login(user).subscribe(res => {
        if (res.status === 'authorized') {
          this.userService.setLoggedInUser(user.fullName, res.commonName, res.team, res.roles, user.password);
          document.title = `Arvid Gjerde App - ${res.commonName}, Lag ${res.team}`;
          this.router.navigate(['/home']);
        } else {
          this.errorMessage = 'Feil brukernavn eller passord.'
          this.isLoggingIn = false;
        }
        this.isLoggingIn = false;
      })
    } else {
        this.dataService.attemptAuth(user).pipe(take(1)).subscribe(res => {
          if (res && this.dataService.isAuthenticated) {
            console.log('login success', res);
            this.userService.setCurrentUser().pipe(take(1)).subscribe(res => {
              console.log('set current user..', res);
            });
            this.router.navigateByUrl('/home');
          } else {
            console.log('failed to log in.')
          }
      }, err => {});
    }
  }

  onKey() {
    if (this.loginForm.valid) {
      this.loginUser();
    }
  }
}
