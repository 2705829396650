import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MatSelectChange, MatCheckboxChange } from '@angular/material';
import { IsiWeekDay } from '../../../isi-week-day';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { TimeSheetService } from '../../../time-sheet.service';
import { take, catchError, map, debounceTime, distinctUntilChanged, concatMap } from 'rxjs/operators';
import { UserService } from '../../../user.service';
import { IsiWeekPerson } from '../../../isi-week-person';
import { IsiDiet } from '../../../isi-diet';
import { of } from 'rxjs';
import { MediaService } from '../../../shared/media.service';
var WeekDayDialogComponent = /** @class */ (function () {
    function WeekDayDialogComponent(data, dialogRef, timeSheetService, snackbar, userService, mediaService) {
        var _this = this;
        this.data = data;
        this.dialogRef = dialogRef;
        this.timeSheetService = timeSheetService;
        this.snackbar = snackbar;
        this.userService = userService;
        this.mediaService = mediaService;
        this.isMobileView = false;
        this.avaliableTime = 13.0;
        this.timeSlots = [];
        this.hotels = [];
        // same length as the dietsController. Used for keeping openClose state. can be open or closed
        this.dietPanelStates = new Array();
        this.diets = [
            { value: 'Ingen', label: 'Ingen / Under 6 timer' },
            { value: 'Uten kok', label: 'Uten kokemulighet' },
            { value: 'Med kok', label: 'Med kokemulighet' },
            { value: 'Hotell', label: 'Hotell' },
            { value: 'Diett 6-12', label: 'Diett uten overnatting 6 - 12 timer' },
            { value: 'Diett 12+', label: 'Diett uten overnatting +12 timer' },
            { value: 'Lastebil', label: 'Lastebil' }
        ];
        this.test = [new IsiWeekPerson()];
        this.savingInProgress = false;
        this.copyDietCtrl = new FormControl(false);
        this.createTimeSlots();
        data.selectedWeekDay = data.selectedWeekDay || new IsiWeekDay();
        data.selectedWeekDay.diets = data.selectedWeekDay.diets || [];
        if (data.selectedWeekDay.hourAccountUnid || data.selectedWeekDay.unid) {
            var weekDayId = data.selectedWeekDay.hourAccountUnid || data.selectedWeekDay.unid;
            this.timeSheetService.getWeekDay(weekDayId).pipe(take(1)).subscribe(function (weekDayWithDiets) {
                _this.weekDay = weekDayWithDiets;
                _this.initFormGroup({ selectedDay: data.selectedDay, selectedWeekDay: weekDayWithDiets });
            });
        }
        else {
            this.initFormGroup(data);
        }
        this.mediaService.getMedia().subscribe(function (media) {
            _this.isMobileView = media.isMobileView;
        });
    }
    WeekDayDialogComponent.prototype.createTimeSlots = function () {
        for (var i = 0; i < (24 * 4); i++) {
            var timeSlot = Math.floor(i / 4) + ":" + 15 * (i % 4);
            this.timeSlots.push(moment(timeSlot, 'HH:mm').format('HH:mm'));
        }
    };
    WeekDayDialogComponent.prototype.copyDietChange = function (evt) {
        if (evt && evt.checked) {
            this.updateDietFromPreviousDiet();
        }
    };
    WeekDayDialogComponent.prototype.updateDietFromPreviousDiet = function () {
        var _this = this;
        var updateDietError = function () {
            _this.copyDietCtrl.patchValue(false, { onlySelf: true });
            _this.snackbar.open('Kunne ikke hente diett', '', { duration: 2000 });
        };
        var lastSavedWeekDayStr = localStorage.getItem('lastSavedweekDay');
        if (lastSavedWeekDayStr) {
            try {
                var lastSavedWeekDay = JSON.parse(lastSavedWeekDayStr);
                var dietGroups = this.initDietGroups(lastSavedWeekDay);
                for (var i = this.dietsCtrl.controls.length - 1; i >= 0; i--) {
                    this.deleteDiet(i);
                }
                dietGroups.controls.forEach(function (diet) {
                    _this.dietsCtrl.push(diet);
                });
                this.initDietChanges();
                this.snackbar.open('Diett oppdatert', '', { duration: 2000 });
            }
            catch (_a) {
                updateDietError();
            }
        }
        else {
            updateDietError();
        }
    };
    WeekDayDialogComponent.prototype.onFocusInHours = function (event, ctrlName) {
        if (this.formGroup.get(ctrlName) && this.formGroup.get(ctrlName).value === 0) {
            this.formGroup.get(ctrlName).reset();
        }
    };
    WeekDayDialogComponent.prototype.onFocusOutHours = function (event, ctrlName) {
        if (this.formGroup.get(ctrlName) && !this.formGroup.get(ctrlName).value) {
            this.formGroup.get(ctrlName).setValue(0);
        }
    };
    WeekDayDialogComponent.prototype.addNewDiet = function () {
        var missinMembers = this.getMemberMissingFromDiet();
        if (missinMembers && missinMembers.length > 0) {
            this.dietsCtrl.push(this.createDietGroup(missinMembers));
            this.dietChange(this.dietsCtrl.length - 1);
        }
        else {
            this.snackbar.open('Alle medlemmer finnes allerede i en diett. ' +
                'Vennligst fjern minst et medlem fra en av diettene', 'OK', { duration: 10000 });
        }
    };
    WeekDayDialogComponent.prototype.initFormGroup = function (data) {
        var dietGroups = this.initDietGroups(data.selectedWeekDay);
        this.formGroup = new FormGroup({
            'weekDayMembersCtrl': new FormControl(data.selectedWeekDay.teamMembers || null, [Validators.required]),
            'workDateCtrl': new FormControl(data.selectedDay.toDate(), []),
            'workStartTimeCtrl': new FormControl('07:00', [Validators.required]),
            'workEndTimeCtrl': new FormControl('20:00', [Validators.required]),
            'driveFromWorkCtrl': new FormControl(data.selectedWeekDay.hourTo || 0, [Validators.min(0)]),
            'driveToWorkCtrl': new FormControl(data.selectedWeekDay.hourFrom || 0, [Validators.min(0)]),
            'sumBreakCtrl': new FormControl(data.selectedWeekDay.hourPause || 0, [Validators.min(0)]),
            'sumBreakWorkCtrl': new FormControl(data.selectedWeekDay.hourPauseWork || 0, [Validators.min(0)]),
            'commentCtrl': new FormControl(data.selectedWeekDay.comment || '', []),
            'dietsCtrl': dietGroups,
        });
        this.formGroup.get('workDateCtrl').disable();
        if (data.selectedWeekDay) {
            if (data.selectedWeekDay.workStart) {
                var time = this.toLocaleTimeString(data.selectedWeekDay.workStart);
                this.formGroup.get('workStartTimeCtrl').setValue(time);
            }
            if (data.selectedWeekDay.workEnd) {
                var time = this.toLocaleTimeString(data.selectedWeekDay.workEnd);
                this.formGroup.get('workEndTimeCtrl').setValue(time);
            }
        }
        this.initDietChanges();
        this.timeChange();
    };
    WeekDayDialogComponent.prototype.initDietChanges = function () {
        for (var i = 0; i < this.dietPanelStates.length; i++) {
            this.dietChange(i);
        }
    };
    WeekDayDialogComponent.prototype.initDietGroups = function (weekDay) {
        var _this = this;
        var dietGroups = new FormArray([]);
        if (weekDay.diets.length > 0) {
            weekDay.diets.forEach(function (diet) {
                dietGroups.push(_this.createDietGroupFromDiet(diet));
            });
        }
        else {
            dietGroups.push(this.createDietGroup(null, weekDay.diett, weekDay.location, weekDay.meals));
        }
        return dietGroups;
    };
    WeekDayDialogComponent.prototype.toLocaleTimeString = function (date) {
        if (!date) {
            return '';
        }
        date = new Date(date); // in case we have milliseconds. Just create a new Date
        return date.toLocaleTimeString('nb', {
            hour12: false,
            hour: "numeric",
            minute: "numeric"
        });
    };
    WeekDayDialogComponent.prototype.createDietGroupFromDiet = function (diet) {
        return this.createDietGroup(diet.teamMembers, diet.diett, diet.location, diet.meals);
    };
    /**
     * @param defaultMembers used to add team members to the diet.
     */
    WeekDayDialogComponent.prototype.createDietGroup = function (defaultMembers, diett, location, meals) {
        var _this = this;
        if (defaultMembers === void 0) { defaultMembers = null; }
        if (diett === void 0) { diett = ''; }
        if (location === void 0) { location = ''; }
        if (meals === void 0) { meals = []; }
        diett = diett || '';
        location = location || '';
        meals = meals || [];
        var diet = this.getDietObject(diett, this.diets);
        var fg = new FormGroup({
            'membersCtrl': new FormControl(defaultMembers, [Validators.required]),
            'dietCtrl': new FormControl(diet),
            'diningGroup': new FormGroup({
                'breakfastCtrl': new FormControl(false, []),
                'lunchCtrl': new FormControl(false, []),
                'dinnerCtrl': new FormControl(false, []),
            }),
            'lodgingPlaceCtrl': new FormControl(location || '', [Validators.required, Validators.minLength(1)])
        });
        this.setMeals(fg, meals);
        this.dietPanelStates.push({
            isOpen: false,
            hideDining: meals.length <= 0,
            hideLodge: diet.value.toLocaleLowerCase() === 'ingen'
        });
        fg.get('lodgingPlaceCtrl').valueChanges
            .pipe(debounceTime(300), distinctUntilChanged(), map(function (value) { return typeof value === 'string' ? value : value.label; }), concatMap(function (name) { return _this.hotelFilter(name).pipe(take(1)); })).subscribe(function (res) {
            _this.hotels = res.data;
        });
        return fg;
    };
    WeekDayDialogComponent.prototype.hotelSelectKeyUp = function (event, dietIndex) {
        if (event && (event.code === '13' || event.key === 'Enter')) {
            var hotel = this.dietsCtrl.at(dietIndex).get('lodgingPlaceCtrl').value;
            this.setHotelAddressFromOptionClick(dietIndex, hotel);
        }
    };
    WeekDayDialogComponent.prototype.setHotelAddressFromOptionClick = function (dietIndex, hotel) {
        var _this = this;
        if (hotel && hotel.value && hotel.label) {
            this.timeSheetService.getHotel(hotel.value).pipe(take(1)).subscribe(function (res) {
                if (res && res.address) {
                    var address = res.companyName + ", " + (res.address.streetAddress || '') + ", " + (res.address.zip || '') + " " + (res.address.city || '');
                    _this.dietsCtrl.at(dietIndex).get('lodgingPlaceCtrl').setValue(address);
                }
                else {
                    _this.dietsCtrl.at(dietIndex).get('lodgingPlaceCtrl').setValue(hotel.label);
                }
            });
        }
    };
    /**
     * Find the correct object from the meals array in order to make selection work.
     * @param diett match the value in the dietsArray
     */
    WeekDayDialogComponent.prototype.getDietObject = function (diett, diets) {
        var diet = this.diets[0]; // default diet.
        diets.forEach(function (dietType) {
            if (dietType.value === diett) {
                diet = dietType;
            }
        });
        return diet;
    };
    WeekDayDialogComponent.prototype.setMeals = function (fg, meals) {
        meals.forEach(function (meal) {
            if (meal.toLocaleLowerCase() === 'f') {
                fg.get('diningGroup').get('breakfastCtrl').setValue(true);
            }
            else if (meal.toLocaleLowerCase() === 'l') {
                fg.get('diningGroup').get('lunchCtrl').setValue(true);
            }
            else if (meal.toLocaleLowerCase() === 'm') {
                fg.get('diningGroup').get('dinnerCtrl').setValue(true);
            }
        });
    };
    WeekDayDialogComponent.prototype.ngOnInit = function () {
    };
    WeekDayDialogComponent.prototype.deleteDiet = function (index) {
        this.dietsCtrl.removeAt(index);
        this.dietPanelStates.splice(index, 1);
    };
    WeekDayDialogComponent.prototype.weekDayMemberChange = function (event) {
        this.removeMemberNotInWeekDay(event.value);
        this.removeEmptyDietControls();
    };
    WeekDayDialogComponent.prototype.removeEmptyDietControls = function () {
        var _this = this;
        var removeControlIndicies = [];
        for (var i = this.dietsCtrl.length - 1; i >= 0; i--) {
            if (!this.dietsCtrl.at(i).get('membersCtrl').value || this.dietsCtrl.at(i).get('membersCtrl').value.length === 0) {
                removeControlIndicies.push(i);
            }
        }
        removeControlIndicies.forEach(function (index) { return _this.deleteDiet(index); });
    };
    /**
     * Removes any selected member in any of the diets if she does not exist in the weekDayMemebersCtrl.
     * One cannot add a person to a diet if she is not in the weekDay.
     * Also sets the member options to be the value of weekDayMemberCtrl in all diets.
     */
    WeekDayDialogComponent.prototype.removeMemberNotInWeekDay = function (weekPersons) {
        this.dietsCtrl.controls.forEach(function (dietGroup) {
            var membersToKeep = [];
            var dietGroupsToDelete = [];
            dietGroup.get('membersCtrl').value.forEach(function (emp) {
                var found = weekPersons.find(function (exisitingEmp) { return exisitingEmp.unid === emp.unid; });
                if (found) {
                    membersToKeep.push(emp);
                }
            });
            dietGroup.get('membersCtrl').setValue(membersToKeep);
        });
    };
    WeekDayDialogComponent.prototype.sumBreakChange = function (evt) {
        this.timeChange();
    };
    WeekDayDialogComponent.prototype.timeChange = function () {
        var workEndTime = this.formGroup.get('workEndTimeCtrl').value;
        var workStartTime = this.formGroup.get('workStartTimeCtrl').value;
        var sumBreak = this.formGroup.get('sumBreakCtrl').value;
        if (workEndTime && workStartTime) {
            var endTime = moment(workEndTime, 'HH:mm');
            var startTime = moment(workStartTime, "HH:mm");
            if (endTime.isBefore(startTime)) {
                endTime.add(1, 'd');
            }
            var duration = moment.duration(endTime.diff(startTime));
            this.avaliableTime = duration.asHours() - sumBreak;
        }
    };
    WeekDayDialogComponent.prototype.dietChange = function (index) {
        var selectedDiet = this.dietsCtrl.at(index).get('dietCtrl').value;
        if (selectedDiet && selectedDiet.value === 'Ingen') {
            this.dietPanelStates[index].hideDining = true;
            this.dietsCtrl.at(index).get('diningGroup').disable();
            this.dietPanelStates[index].hideLodge = true;
            this.dietsCtrl.at(index).get('lodgingPlaceCtrl').disable();
        }
        else if (selectedDiet && selectedDiet.value === 'Diett 6-12' || selectedDiet.value === 'Diett 12+') {
            this.dietPanelStates[index].hideDining = false;
            this.dietsCtrl.at(index).get('diningGroup').enable();
            this.dietPanelStates[index].hideLodge = true;
            this.dietsCtrl.at(index).get('lodgingPlaceCtrl').disable();
        }
        else {
            this.dietPanelStates[index].hideDining = false;
            this.dietsCtrl.at(index).get('diningGroup').enable();
            this.dietPanelStates[index].hideLodge = false;
            this.dietsCtrl.at(index).get('lodgingPlaceCtrl').enable();
        }
    };
    WeekDayDialogComponent.prototype.save = function () {
        var _this = this;
        if (!this.timeSheetService.canEditWeekHour(this.data.selectedDay)) {
            this.snackbar.open('Lønnsberegningen er allerede utført, og dagføring kan ikke endres', 'OK', { duration: 8000 });
            return;
        }
        var missingMembers = this.getMemberMissingFromDiet();
        var excessMembers = this.getExcessNumberOfMembersFromDiet();
        if (missingMembers.length > 0) {
            var missingMemberNames = missingMembers.map(function (member) { return member.name; });
            this.snackbar.open(missingMemberNames.toString() + ' finnes ikke i en diett', 'OK', { duration: 8000 });
        }
        else if (excessMembers.length > 0) {
            var excessMembersNames = excessMembers.map(function (member) { return member.name; });
            this.snackbar.open(excessMembersNames.toString() + ' finnes i mer enn én diett', 'OK', { duration: 8000 });
        }
        else {
            var postData_1 = new IsiWeekDay();
            var startHour = moment(this.data.selectedDay);
            var endHour = moment(this.data.selectedDay);
            startHour.hour(this.formGroup.get('workStartTimeCtrl').value.substr(0, 2));
            startHour.minute(this.formGroup.get('workStartTimeCtrl').value.substr(3, 5));
            startHour.second(0);
            endHour.hour(this.formGroup.get('workEndTimeCtrl').value.substr(0, 2));
            endHour.minute(this.formGroup.get('workEndTimeCtrl').value.substr(3, 5));
            endHour.second(0);
            postData_1.workStart = startHour.toDate();
            postData_1.workEnd = endHour.toDate();
            postData_1.hourAccountUnid = this.data.selectedWeekDay.hourAccountUnid || '';
            postData_1.date = this.data.selectedDay.toDate();
            postData_1.hourTo = this.formGroup.get('driveFromWorkCtrl') ? this.formGroup.get('driveFromWorkCtrl').value : 0;
            postData_1.hourFrom = this.formGroup.get('driveToWorkCtrl') ? this.formGroup.get('driveToWorkCtrl').value : 0;
            postData_1.hourPause = this.formGroup.get('sumBreakCtrl') ? this.formGroup.get('sumBreakCtrl').value : 0;
            postData_1.hourPauseWork = this.formGroup.get('sumBreakWorkCtrl') ? this.formGroup.get('sumBreakWorkCtrl').value : 0;
            postData_1.teamId = this.userService.teamId;
            postData_1.comment = this.formGroup.get('commentCtrl') ? this.formGroup.get('commentCtrl').value : '';
            postData_1.unid = this.data.selectedWeekDay.unid || '';
            // The database creates views categorized on the year and the week,
            // and we only care about which week it is on monday in the current week.
            // Even though 1. jan 2021 is week 1 in reality, we need to store it on week 53 2020
            postData_1.week = this.data.selectedWeekDay.week || moment(this.data.selectedDay).startOf('isoWeek').isoWeek();
            // TODO store each diet in an array together with location.
            postData_1.diets = [];
            for (var i = 0; i < this.dietPanelStates.length; i++) {
                var dietFormGroup = this.dietsCtrl.controls[i];
                postData_1.diets.push(new IsiDiet());
                postData_1.diets[i].diett = dietFormGroup.get('dietCtrl').value.value;
                if (!this.dietPanelStates[i].hideLodge) {
                    postData_1.diets[i].location = dietFormGroup.get('lodgingPlaceCtrl').value;
                }
                if (!this.dietPanelStates[i].hideDining) {
                    postData_1.diets[i].meals = [];
                    var diningGroup = dietFormGroup.get('diningGroup');
                    if (diningGroup && diningGroup.get('breakfastCtrl') && diningGroup.get('breakfastCtrl').value) {
                        postData_1.diets[i].meals.push('F');
                    }
                    if (diningGroup && diningGroup.get('lunchCtrl') && diningGroup.get('lunchCtrl').value) {
                        postData_1.diets[i].meals.push('L');
                    }
                    if (diningGroup && diningGroup.get('dinnerCtrl') && diningGroup.get('dinnerCtrl').value) {
                        postData_1.diets[i].meals.push('M');
                    }
                }
                postData_1.diets[i].teamMembers = dietFormGroup.get('membersCtrl').value;
            }
            postData_1.teamMembers = this.formGroup.get('weekDayMembersCtrl').value;
            this.savingInProgress = true;
            this.timeSheetService.saveWeekDay(postData_1).pipe(take(1)).subscribe(function (res) {
                _this.savingInProgress = false;
                if (res) {
                    try {
                        localStorage.setItem('lastSavedweekDay', JSON.stringify(postData_1));
                    }
                    catch (err) {
                        console.error(err);
                    }
                    postData_1.unid = res.unid;
                    postData_1.hourAccountUnid = res.hourAccountUnid;
                    _this.snackbar.open('Dagføring lagret', '', { duration: 4000 });
                    _this.dialogRef.close(postData_1);
                }
                else {
                    localStorage.setItem(postData_1.date.toLocaleString(), JSON.stringify(postData_1));
                    _this.snackbar.open('Det skjedde en feil under lagring av dagføring. Vennligst prøv igjen', '', { duration: 10000 });
                }
            }, catchError(function (err) {
                _this.savingInProgress = false;
                return null;
            }));
        }
    };
    WeekDayDialogComponent.prototype.getExcessNumberOfMembersFromDiet = function () {
        var excessMembers = [];
        var existingMembers = this.getMemberUnidsMap();
        var _loop_1 = function (unid) {
            if (existingMembers.hasOwnProperty(unid)) {
                if (existingMembers[unid] > 1) {
                    excessMembers.push(this_1.formGroup.get('weekDayMembersCtrl').value.find(function (member) { return member.unid === unid; }));
                }
            }
        };
        var this_1 = this;
        for (var unid in existingMembers) {
            _loop_1(unid);
        }
        return excessMembers;
    };
    WeekDayDialogComponent.prototype.getMemberMissingFromDiet = function () {
        var missingMembers = [];
        var unidsInDiets = Object.keys(this.getMemberUnidsMap());
        this.formGroup.get('weekDayMembersCtrl').value.forEach(function (member) {
            if (unidsInDiets && !unidsInDiets.includes(member.unid)) {
                missingMembers.push(member);
            }
        });
        return missingMembers;
    };
    WeekDayDialogComponent.prototype.getMemberUnidsMap = function () {
        var existingMemberUnids = {};
        this.dietsCtrl.controls.forEach(function (dietGroup) {
            if (dietGroup && dietGroup.get('membersCtrl').value) {
                dietGroup.get('membersCtrl').value.forEach(function (member) {
                    if (existingMemberUnids.hasOwnProperty(member.unid)) {
                        existingMemberUnids[member.unid] += 1;
                    }
                    else {
                        existingMemberUnids[member.unid] = 1;
                    }
                });
            }
        });
        return existingMemberUnids;
    };
    WeekDayDialogComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    WeekDayDialogComponent.prototype.displayHotelFn = function (hotel) {
        return typeof hotel === 'string' ? hotel : hotel.label;
    };
    WeekDayDialogComponent.prototype.hotelFilter = function (name) {
        var filterValue = name.toLowerCase();
        if (!name) {
            return of([]);
        }
        return this.timeSheetService.getHotels(filterValue).pipe(catchError(function () { return of([]); }));
    };
    Object.defineProperty(WeekDayDialogComponent.prototype, "dietsCtrl", {
        get: function () {
            return this.formGroup.get('dietsCtrl');
        },
        enumerable: true,
        configurable: true
    });
    return WeekDayDialogComponent;
}());
export { WeekDayDialogComponent };
