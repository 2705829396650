/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./team-resources.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i8 from "@angular/material/list";
import * as i9 from "@angular/material/core";
import * as i10 from "./team-resources.component";
import * as i11 from "../../user.service";
import * as i12 from "../../data.service";
var styles_TeamResourcesComponent = [i0.styles];
var RenderType_TeamResourcesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TeamResourcesComponent, data: {} });
export { RenderType_TeamResourcesComponent as RenderType_TeamResourcesComponent };
function View_TeamResourcesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vennligst vent mens ressurser hentes"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(4, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 4).diameter; var currVal_2 = i1.ɵnov(_v, 4).diameter; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }); }
function View_TeamResourcesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i7.View_MatListItem_0, i7.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i8.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i8.MatNavList], [2, i8.MatList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(603979776, 2, { _avatar: 0 }), i1.ɵqud(603979776, 3, { _icon: 0 }), (_l()(), i1.ɵeld(5, 0, null, 1, 2, "h4", [["class", "mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, [[1, 4]], 0, i9.MatLine, [], null, null), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 7, 0, currVal_2); }); }
function View_TeamResourcesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i7.View_MatListItem_0, i7.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i8.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i8.MatNavList], [2, i8.MatList]], null, null), i1.ɵqud(603979776, 4, { _lines: 1 }), i1.ɵqud(603979776, 5, { _avatar: 0 }), i1.ɵqud(603979776, 6, { _icon: 0 }), (_l()(), i1.ɵeld(5, 0, null, 1, 2, "h4", [["class", "mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, [[4, 4]], 0, i9.MatLine, [], null, null), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 7, 0, currVal_2); }); }
export function View_TeamResourcesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamResourcesComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "resourceList-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "employeeList"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h3", [["class", "mat-subheader"], ["mat-subheader", ""]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i8.MatListSubheaderCssMatStyler, [], null, null), (_l()(), i1.ɵted(-1, null, ["Ansatte"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamResourcesComponent_2)), i1.ɵdid(8, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "resourceList"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "h3", [["class", "mat-subheader"], ["mat-subheader", ""]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i8.MatListSubheaderCssMatStyler, [], null, null), (_l()(), i1.ɵted(-1, null, ["Driftsmidler"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TeamResourcesComponent_3)), i1.ɵdid(14, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.employees; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.assets; _ck(_v, 14, 0, currVal_2); }, null); }
export function View_TeamResourcesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-team-resources", [], null, null, null, View_TeamResourcesComponent_0, RenderType_TeamResourcesComponent)), i1.ɵdid(1, 114688, null, 0, i10.TeamResourcesComponent, [i11.UserService, i12.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TeamResourcesComponentNgFactory = i1.ɵccf("app-team-resources", i10.TeamResourcesComponent, View_TeamResourcesComponent_Host_0, {}, {}, []);
export { TeamResourcesComponentNgFactory as TeamResourcesComponentNgFactory };
