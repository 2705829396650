import { Component, OnInit, Inject, HostBinding } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatSelectChange, MatCheckboxChange } from '@angular/material';
import { IsiWeekDay } from '../../../isi-week-day';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { TimeSheetService } from '../../../time-sheet.service';
import { take, catchError, startWith, map, debounceTime, distinctUntilChanged, filter, mergeMap, flatMap, concatMap } from 'rxjs/operators';
import { UserService } from '../../../user.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { IsiWeekPerson } from '../../../isi-week-person';
import { IsiDiet } from '../../../isi-diet';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';
import { MediaService } from '../../../shared/media.service';

@Component({
  selector: 'app-week-day-dialog',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        transform: 'rotate(0deg)'
      })),
      state('closed', style({
        transform: 'rotate(-180deg)'
      })),
      transition('open => closed', [
        animate('0.3s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ]),
    ]),
  ],
  templateUrl: './week-day-dialog.component.html',
  styleUrls: ['./week-day-dialog.component.scss']
})
export class WeekDayDialogComponent implements OnInit {
  @HostBinding('class.mobile-view') isMobileView = false;
  fetchingPreviousDiets: boolean;
  formGroup: FormGroup;
  avaliableTime = 13.0;
  timeSlots: Array<string> = [];
  hotels: Array<{value: string, label: string}> = [];
  // same length as the dietsController. Used for keeping openClose state. can be open or closed
  dietPanelStates = new Array<{isOpen: boolean, hideDining: boolean, hideLodge: boolean}>();
  diets: Array<{value: string, label: string}> = [
    { value: 'Ingen', label: 'Ingen / Under 6 timer' },
    { value: 'Uten kok', label: 'Uten kokemulighet'},
    { value: 'Med kok', label: 'Med kokemulighet'},
    { value: 'Hotell', label: 'Hotell'},
    { value: 'Diett 6-12', label: 'Diett uten overnatting 6 - 12 timer'},
    { value: 'Diett 12+', label: 'Diett uten overnatting +12 timer'},
    { value: 'Lastebil', label: 'Lastebil'}
  ];
  test: Array<IsiWeekPerson> = [new IsiWeekPerson()];
  savingInProgress = false;
  weekDay: IsiWeekDay;
  copyDietCtrl: FormControl = new FormControl(false);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { selectedDay: moment.Moment, selectedWeekDay: IsiWeekDay},
    public dialogRef: MatDialogRef<WeekDayDialogComponent>,
    private timeSheetService: TimeSheetService,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private mediaService: MediaService) {
    this.createTimeSlots();
    data.selectedWeekDay = data.selectedWeekDay || new IsiWeekDay();
    data.selectedWeekDay.diets = data.selectedWeekDay.diets || [];
    if (data.selectedWeekDay.hourAccountUnid || data.selectedWeekDay.unid) {
      const weekDayId = data.selectedWeekDay.hourAccountUnid || data.selectedWeekDay.unid;
      this.timeSheetService.getWeekDay(weekDayId).pipe(take(1)).subscribe(weekDayWithDiets => {
        this.weekDay = weekDayWithDiets;
        this.initFormGroup({ selectedDay: data.selectedDay, selectedWeekDay: weekDayWithDiets});
      });
    } else {
      this.initFormGroup(data);
    }
    this.mediaService.getMedia().subscribe( media => {
      this.isMobileView = media.isMobileView;
    })
  }
  private createTimeSlots() {
    for (let i = 0; i < (24 * 4); i++) {
      const timeSlot = Math.floor(i / 4) + ":" + 15 * (i % 4);
      this.timeSlots.push(moment(timeSlot, 'HH:mm').format('HH:mm'));
    }
  }
  copyDietChange(evt: MatCheckboxChange) {
    if (evt && evt.checked) {
      this.updateDietFromPreviousDiet()
    }
  }

  updateDietFromPreviousDiet() {
    const updateDietError = () => {
      this.copyDietCtrl.patchValue(false, { onlySelf: true });
      this.snackbar.open('Kunne ikke hente diett', '', { duration: 2000 });
    }
    const lastSavedWeekDayStr = localStorage.getItem('lastSavedweekDay');
    if (lastSavedWeekDayStr) {
      try {
        const lastSavedWeekDay: IsiWeekDay = JSON.parse(lastSavedWeekDayStr);
        const dietGroups = this.initDietGroups(lastSavedWeekDay);
        for (let i = this.dietsCtrl.controls.length - 1; i >= 0; i--) {
          this.deleteDiet(i);
        }
        dietGroups.controls.forEach(diet => {
          this.dietsCtrl.push(diet);
        });
        this.initDietChanges();
        this.snackbar.open('Diett oppdatert', '', { duration: 2000 });
      } catch {
        updateDietError();
      }
    } else {
      updateDietError();
    }
  }
  onFocusInHours(event, ctrlName) {
    if (this.formGroup.get(ctrlName) && this.formGroup.get(ctrlName).value === 0) {
      this.formGroup.get(ctrlName).reset();
    }
  }
  onFocusOutHours(event, ctrlName) {
    if (this.formGroup.get(ctrlName) && !this.formGroup.get(ctrlName).value) {
      this.formGroup.get(ctrlName).setValue(0);
    }
  }
  addNewDiet() {
    const missinMembers = this.getMemberMissingFromDiet();
    if (missinMembers && missinMembers.length > 0) {
      this.dietsCtrl.push(this.createDietGroup(missinMembers));
      this.dietChange(this.dietsCtrl.length - 1);
    } else {
      this.snackbar.open('Alle medlemmer finnes allerede i en diett. ' +
        'Vennligst fjern minst et medlem fra en av diettene', 'OK', {duration: 10000});
    }
  }
  private initFormGroup(data: { selectedDay: moment.Moment, selectedWeekDay: IsiWeekDay }) {
    const dietGroups = this.initDietGroups(data.selectedWeekDay);
    this.formGroup = new FormGroup({
      'weekDayMembersCtrl': new FormControl(data.selectedWeekDay.teamMembers || null, [Validators.required]),
      'workDateCtrl': new FormControl(data.selectedDay.toDate(), []),
      'workStartTimeCtrl': new FormControl('07:00', [Validators.required]),
      'workEndTimeCtrl': new FormControl('20:00', [Validators.required]),
      'driveFromWorkCtrl': new FormControl(data.selectedWeekDay.hourTo || 0, [Validators.min(0)]),
      'driveToWorkCtrl': new FormControl(data.selectedWeekDay.hourFrom || 0, [Validators.min(0)]),
      'sumBreakCtrl': new FormControl(data.selectedWeekDay.hourPause || 0, [Validators.min(0)]),
      'sumBreakWorkCtrl': new FormControl(data.selectedWeekDay.hourPauseWork || 0, [Validators.min(0)]),
      'commentCtrl': new FormControl(data.selectedWeekDay.comment || '', []),
      'dietsCtrl': dietGroups,
    });
    this.formGroup.get('workDateCtrl').disable();
    if (data.selectedWeekDay) {
      if (data.selectedWeekDay.workStart) {
        const time = this.toLocaleTimeString(data.selectedWeekDay.workStart);
        this.formGroup.get('workStartTimeCtrl').setValue(time);
      }
      if (data.selectedWeekDay.workEnd) {
        const time = this.toLocaleTimeString(data.selectedWeekDay.workEnd);
        this.formGroup.get('workEndTimeCtrl').setValue(time);
      }
    }
    this.initDietChanges();
    this.timeChange();
  }
  private initDietChanges() {
    for (let i = 0; i < this.dietPanelStates.length; i++) {
      this.dietChange(i);
    }
  }
  private initDietGroups(weekDay: IsiWeekDay): FormArray {
    const dietGroups = new FormArray([]);
    if (weekDay.diets.length > 0) {
      weekDay.diets.forEach(diet => {
        dietGroups.push(this.createDietGroupFromDiet(diet));
      });
    } else {
      dietGroups.push(
        this.createDietGroup(null, weekDay.diett, weekDay.location, weekDay.meals)
      );
    }
    return dietGroups;
  }
  private toLocaleTimeString(date: Date): string {
    if (!date) { return ''; }
    date = new Date(date); // in case we have milliseconds. Just create a new Date
    return date.toLocaleTimeString('nb', {
      hour12: false,
      hour: "numeric",
      minute: "numeric"
    });
  }
  private createDietGroupFromDiet (diet: IsiDiet): FormGroup {
    return this.createDietGroup(diet.teamMembers, diet.diett, diet.location, diet.meals);
  }
  /**
   * @param defaultMembers used to add team members to the diet.
   */
  private createDietGroup(defaultMembers: Array<IsiWeekPerson> = null,
    diett: string = '', location: string = '', meals: Array<string> = []): FormGroup {
    diett = diett || '';
    location = location || '';
    meals = meals || [];
    const diet: {value: string, label: string} = this.getDietObject(diett, this.diets);
    const fg = new FormGroup({
      'membersCtrl': new FormControl(defaultMembers, [Validators.required]),
      'dietCtrl': new FormControl(diet),
      'diningGroup': new FormGroup({
        'breakfastCtrl': new FormControl(false, []),
        'lunchCtrl': new FormControl(false, []),
        'dinnerCtrl': new FormControl(false, []),
      }),
      'lodgingPlaceCtrl': new FormControl(location || '', [Validators.required, Validators.minLength(1)])
    });
    this.setMeals(fg, meals);
    this.dietPanelStates.push({
      isOpen: false,
      hideDining: meals.length <= 0,
      hideLodge: diet.value.toLocaleLowerCase() === 'ingen'
    });
    fg.get('lodgingPlaceCtrl').valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map(value => typeof value === 'string' ? value : value.label),
        concatMap(name => this.hotelFilter(name).pipe(take(1)))
    ).subscribe( (res: any) => {
     this.hotels = res.data;
    });
    return fg;
  }
  hotelSelectKeyUp(event: KeyboardEvent, dietIndex) {
    if (event && (event.code === '13' || event.key === 'Enter')) {
      const hotel = this.dietsCtrl.at(dietIndex).get('lodgingPlaceCtrl').value;
      this.setHotelAddressFromOptionClick(dietIndex, hotel);
    }
  }
  setHotelAddressFromOptionClick(dietIndex: number, hotel: {value: string, label: string}) {
    if (hotel && hotel.value && hotel.label) {
      this.timeSheetService.getHotel(hotel.value).pipe(take(1)).subscribe( res => {
        if (res && res.address) {
          const address = `${res.companyName}, ${res.address.streetAddress || ''}, ${res.address.zip || ''} ${res.address.city || ''}`
          this.dietsCtrl.at(dietIndex).get('lodgingPlaceCtrl').setValue(address);
        } else {
          this.dietsCtrl.at(dietIndex).get('lodgingPlaceCtrl').setValue(hotel.label);
        }
      });
    }
  }
  /**
   * Find the correct object from the meals array in order to make selection work.
   * @param diett match the value in the dietsArray
   */
  private getDietObject(diett: string, diets: Array<{value: string, label: string}>): {value: string, label: string} {
    let diet = this.diets[0]; // default diet.
    diets.forEach(dietType => {
      if (dietType.value === diett) {
        diet = dietType;
      }
    });
    return diet;
  }
  private setMeals(fg: FormGroup, meals: Array<string>) {
    meals.forEach(meal => {
      if (meal.toLocaleLowerCase() === 'f') {
        fg.get('diningGroup').get('breakfastCtrl').setValue(true);
      } else if (meal.toLocaleLowerCase() === 'l') {
        fg.get('diningGroup').get('lunchCtrl').setValue(true);
      } else if (meal.toLocaleLowerCase() === 'm') {
        fg.get('diningGroup').get('dinnerCtrl').setValue(true);
      }
    });
  }
  ngOnInit() {
  }
  deleteDiet(index: number) {
    this.dietsCtrl.removeAt(index);
    this.dietPanelStates.splice(index, 1);
  }
  weekDayMemberChange(event: MatSelectChange) {
    this.removeMemberNotInWeekDay(event.value);
    this.removeEmptyDietControls();
  }
  private removeEmptyDietControls() {
    const removeControlIndicies = []
    for (let i = this.dietsCtrl.length - 1; i >= 0; i--) {
      if (!this.dietsCtrl.at(i).get('membersCtrl').value || this.dietsCtrl.at(i).get('membersCtrl').value.length === 0) {
        removeControlIndicies.push(i);
      }
    }
    removeControlIndicies.forEach(index => this.deleteDiet(index));
  }
  /**
   * Removes any selected member in any of the diets if she does not exist in the weekDayMemebersCtrl.
   * One cannot add a person to a diet if she is not in the weekDay.
   * Also sets the member options to be the value of weekDayMemberCtrl in all diets.
   */
  private removeMemberNotInWeekDay(weekPersons: Array<IsiWeekPerson>) {
    this.dietsCtrl.controls.forEach(dietGroup => {
      const membersToKeep = [];
      const dietGroupsToDelete = [];
      dietGroup.get('membersCtrl').value.forEach(emp => {
        const found = weekPersons.find((exisitingEmp) => exisitingEmp.unid === emp.unid);
        if (found) {
          membersToKeep.push(emp);
        }
      });
      dietGroup.get('membersCtrl').setValue(membersToKeep);
    });
  }
  sumBreakChange(evt: Event) {
    this.timeChange();
  }
  timeChange() {
    const workEndTime = this.formGroup.get('workEndTimeCtrl').value;
    const workStartTime = this.formGroup.get('workStartTimeCtrl').value;
    const sumBreak = this.formGroup.get('sumBreakCtrl').value;
    if (workEndTime && workStartTime) {
      const endTime = moment(workEndTime, 'HH:mm');
      const startTime = moment(workStartTime, "HH:mm");
      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'd');
      }
      const duration = moment.duration(endTime.diff(startTime));
      this.avaliableTime = duration.asHours() - sumBreak;
    }
  }

  dietChange(index: number) {
    const selectedDiet = this.dietsCtrl.at(index).get('dietCtrl').value;
    if (selectedDiet && selectedDiet.value === 'Ingen') {
      this.dietPanelStates[index].hideDining = true;
      this.dietsCtrl.at(index).get('diningGroup').disable();
      this.dietPanelStates[index].hideLodge = true;
      this.dietsCtrl.at(index).get('lodgingPlaceCtrl').disable();
    } else if (selectedDiet && selectedDiet.value === 'Diett 6-12' || selectedDiet.value === 'Diett 12+') {
      this.dietPanelStates[index].hideDining = false;
      this.dietsCtrl.at(index).get('diningGroup').enable();
      this.dietPanelStates[index].hideLodge = true;
      this.dietsCtrl.at(index).get('lodgingPlaceCtrl').disable();
    } else {
      this.dietPanelStates[index].hideDining = false;
      this.dietsCtrl.at(index).get('diningGroup').enable();
      this.dietPanelStates[index].hideLodge = false;
      this.dietsCtrl.at(index).get('lodgingPlaceCtrl').enable();
    }
  }
  save() {
    if (!this.timeSheetService.canEditWeekHour(this.data.selectedDay)) {
      this.snackbar.open('Lønnsberegningen er allerede utført, og dagføring kan ikke endres', 'OK', { duration: 8000 });
      return;
    }
    const missingMembers = this.getMemberMissingFromDiet();
    const excessMembers = this.getExcessNumberOfMembersFromDiet();
    if (missingMembers.length > 0) {
      const missingMemberNames: Array<string> = missingMembers.map((member) => member.name);
      this.snackbar.open(missingMemberNames.toString() + ' finnes ikke i en diett', 'OK', {duration: 8000});
    } else if (excessMembers.length > 0) {
      const excessMembersNames: Array<string> = excessMembers.map((member) => member.name);
      this.snackbar.open(excessMembersNames.toString() + ' finnes i mer enn én diett', 'OK', { duration: 8000 });
    } else {
      const postData: IsiWeekDay = new IsiWeekDay();
      const startHour: moment.Moment = moment(this.data.selectedDay);
      const endHour: moment.Moment = moment(this.data.selectedDay);
      startHour.hour(this.formGroup.get('workStartTimeCtrl').value.substr(0, 2));
      startHour.minute(this.formGroup.get('workStartTimeCtrl').value.substr(3, 5));
      startHour.second(0);
      endHour.hour(this.formGroup.get('workEndTimeCtrl').value.substr(0, 2));
      endHour.minute(this.formGroup.get('workEndTimeCtrl').value.substr(3, 5));
      endHour.second(0);
      postData.workStart = startHour.toDate();
      postData.workEnd = endHour.toDate();
      postData.hourAccountUnid = this.data.selectedWeekDay.hourAccountUnid || '';
      postData.date = this.data.selectedDay.toDate();
      postData.hourTo = this.formGroup.get('driveFromWorkCtrl') ? this.formGroup.get('driveFromWorkCtrl').value : 0;
      postData.hourFrom = this.formGroup.get('driveToWorkCtrl') ? this.formGroup.get('driveToWorkCtrl').value : 0;
      postData.hourPause = this.formGroup.get('sumBreakCtrl') ? this.formGroup.get('sumBreakCtrl').value : 0;
      postData.hourPauseWork = this.formGroup.get('sumBreakWorkCtrl') ? this.formGroup.get('sumBreakWorkCtrl').value : 0;
      postData.teamId = this.userService.teamId;
      postData.comment = this.formGroup.get('commentCtrl') ? this.formGroup.get('commentCtrl').value : '';
      postData.unid = this.data.selectedWeekDay.unid || '';
      // The database creates views categorized on the year and the week,
      // and we only care about which week it is on monday in the current week.
      // Even though 1. jan 2021 is week 1 in reality, we need to store it on week 53 2020
      postData.week = this.data.selectedWeekDay.week || moment(this.data.selectedDay).startOf('isoWeek').isoWeek();
      // TODO store each diet in an array together with location.
      postData.diets = [];
      for (let i = 0; i < this.dietPanelStates.length; i++) {
        const dietFormGroup = this.dietsCtrl.controls[i];
        postData.diets.push(new IsiDiet());
        postData.diets[i].diett = dietFormGroup.get('dietCtrl').value.value;
        if (!this.dietPanelStates[i].hideLodge) {
          postData.diets[i].location = dietFormGroup.get('lodgingPlaceCtrl').value;
        }
        if (!this.dietPanelStates[i].hideDining) {
          postData.diets[i].meals = [];
          const diningGroup = dietFormGroup.get('diningGroup');
          if (diningGroup && diningGroup.get('breakfastCtrl') && diningGroup.get('breakfastCtrl').value) {
            postData.diets[i].meals.push('F');
          }
          if (diningGroup && diningGroup.get('lunchCtrl') && diningGroup.get('lunchCtrl').value) {
            postData.diets[i].meals.push('L');
          }
          if (diningGroup && diningGroup.get('dinnerCtrl') && diningGroup.get('dinnerCtrl').value) {
            postData.diets[i].meals.push('M');
          }
        }
        postData.diets[i].teamMembers = dietFormGroup.get('membersCtrl').value;
      }
      postData.teamMembers = this.formGroup.get('weekDayMembersCtrl').value;
      this.savingInProgress = true;
      this.timeSheetService.saveWeekDay(postData).pipe(take(1)).subscribe(res => {
        this.savingInProgress = false;
        if (res) {
          try {
            localStorage.setItem('lastSavedweekDay', JSON.stringify(postData));
          } catch (err) {
            console.error(err);
          }
          postData.unid = res.unid;
          postData.hourAccountUnid = res.hourAccountUnid;
          this.snackbar.open('Dagføring lagret', '', { duration: 4000 });
          this.dialogRef.close(postData);
        } else {
          localStorage.setItem(postData.date.toLocaleString(), JSON.stringify(postData));
          this.snackbar.open('Det skjedde en feil under lagring av dagføring. Vennligst prøv igjen', '', { duration: 10000 });
        }
      }, catchError(err => {
        this.savingInProgress = false;
        return null;
      }));
    }
  }

  private getExcessNumberOfMembersFromDiet(): Array<IsiWeekPerson> {
    const excessMembers = [];
    const existingMembers = this.getMemberUnidsMap();
    for (const unid in existingMembers) {
      if (existingMembers.hasOwnProperty(unid)) {
        if (existingMembers[unid] > 1) {
          excessMembers.push(this.formGroup.get('weekDayMembersCtrl').value.find((member: IsiWeekPerson) => member.unid === unid));
        }
      }
    }
    return excessMembers;
  }
  private getMemberMissingFromDiet(): Array<IsiWeekPerson> {
    const missingMembers = [];
    const unidsInDiets = Object.keys(this.getMemberUnidsMap());
    this.formGroup.get('weekDayMembersCtrl').value.forEach((member: IsiWeekPerson) => {
      if (unidsInDiets && !unidsInDiets.includes(member.unid)) {
        missingMembers.push(member);
      }
    });
    return missingMembers;
  }
  private getMemberUnidsMap(): {[unid: string]: number} {
    const existingMemberUnids: { [unid: string]: number } = {};
    this.dietsCtrl.controls.forEach(dietGroup => {
      if (dietGroup && dietGroup.get('membersCtrl').value) {
        dietGroup.get('membersCtrl').value.forEach((member: IsiWeekPerson) => {
          if (existingMemberUnids.hasOwnProperty(member.unid)) {
            existingMemberUnids[member.unid] += 1;
          } else {
            existingMemberUnids[member.unid] = 1;
          }
        });
      }
    });
    return existingMemberUnids;
  }
  close() {
    this.dialogRef.close();
  }
  displayHotelFn(hotel?: any): string {
    return typeof hotel === 'string' ? hotel : hotel.label;
  }
  private hotelFilter(name: string): Observable<Array<any>> {
    const filterValue = name.toLowerCase();
    if (!name) {
      return of ([]);
    }
    return this.timeSheetService.getHotels(filterValue).pipe(catchError(() => of([])));
  }
  get dietsCtrl(): FormArray {
    return <FormArray> this.formGroup.get('dietsCtrl');
  }
}
