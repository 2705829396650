<form class="dialog" #addArticleDialogForm="ngForm" [formGroup]="dialogFormGroup" (ngSubmit)="confirm($event)">
  <h2>{{actionTitle}} artikkel</h2>
  <button class="btn-close" type="button" color="accent" mat-icon-button (click)="closeDialog()">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
  <section class="select-article-section" [class.mobile-view]="isMobile">
    <app-select-article-form
      [typeOfArticle]="typeOfArticle"
      [parentFormGroup]="dialogFormGroup"
      [childFormControlName]="'articleFormControl'"
      [childValue]="editArticle">
    </app-select-article-form>
    <br>
    <mat-form-field>
      <input type="number" matInput appCustomIncDecBtns [useFiniteNumbers]="true" placeholder="Antall" [formControl]="quantityFormControl" required>
      <mat-error *ngIf="quantityFormControl.invalid">Kan kun legge til et positivt antall artikler</mat-error>
    </mat-form-field>
  </section>
  <button type="submit" class="btn-save success" color="primary" mat-raised-button [disabled]="dialogFormGroup.invalid">
    <div *ngIf="quantityFormControl.value>1">{{actionTitle}} artikler</div>
    <div *ngIf="quantityFormControl.value<=1">{{actionTitle}} artikkel</div>
  </button>
</form>
