import { Component, OnInit, Input, Output, OnChanges, ViewChild, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {CdkTextareaAutosize} from '@angular/cdk/text-field'; // auto resize of textarea
import { DataService } from '../../../data.service';
import { SjaService } from '../../../sja/sja.service';
import { UserService } from '../../../user.service';

@Component({
  selector: 'app-sja-edit',
  templateUrl: './sja-edit.component.html',
  styleUrls: ['./sja-edit.component.scss']
})
export class SjaEditComponent implements OnInit, OnChanges {
  @Input() sja;
  @Input() orderParsells: Array<string>;
  @Output() saveSja = new EventEmitter();

  sjaForm = new FormGroup ({
    parsells: new FormControl(),
    employees: new FormControl(),
    arbId: new FormControl(),
    comment: new FormControl(),
    action1: new FormControl(),
    action2: new FormControl(),
    action3: new FormControl(),
    action4: new FormControl(),
    action5: new FormControl(),
  });
  toggleLabel = "Ikke relevant";
  action5Label = 'Nei';
  parsells = [{value: "A", label: "Alle"}];
  myTeam = [];
  workExamples;
  constructor(private dataService: DataService, private sjaService: SjaService, private user: UserService) {

  }

  @ViewChild('autosize', {static: false}) autosize: CdkTextareaAutosize;
  ngOnInit() {
    this.sjaService.getWorkExamples().subscribe( examples => {
      this.workExamples = examples;
    });
    if (this.orderParsells) {
      this.orderParsells.forEach(parsNum => {
        this.parsells.push({ value: parsNum, label: parsNum});
      })
    }
  }
  setLabels() {
    if (this.sjaForm.get('action4').value) {
      this.toggleLabel = 'Utført';
    }
    if (this.sjaForm.get('action5').value) {
      this.action5Label = 'Ja';
    }
  }

  showHMSInfo() {
    if (this.sjaForm.get('action4').value) {
      return true;
    }
    return false;
  }

  showAction5Info() {
    if (this.sjaForm.get('action5').value) {
      return true;
    }
    return false;
  }

  action5Change(event) {
    if (event.checked) {
      this.action5Label = 'Ja';
    } else {
      this.action5Label = 'Nei';
    }
  }

  ngOnChanges(event) {
    this.dataService.getTeamEmployyes(this.user.teamId).subscribe(employees => {
      this.myTeam = employees;
    })
    this.rebuildForm();
    this.setLabels();
  }

  rebuildForm() {
    this.sjaForm.reset(this.sja);
    if (this.sja.status === 'Arkivert') {
      this.sjaForm.disable();
    } else {
      this.sjaForm.enable();
    }
  }

  saveSJA() {
    if (this.sja.unid) {
      this.sjaForm.value.unid = this.sja.unid;
    }
    if (this.sja.created) {
      this.sjaForm.value.created = this.sja.created;
    }
    this.saveSja.emit(this.sjaForm.value);
    this.sjaForm.markAsPristine();
  }

  toggleChange(event) {
    if (event.checked) {
      this.toggleLabel = 'Utført';
    } else {
      this.toggleLabel = 'Ikke relevant';
    }
  }

}
