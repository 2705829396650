var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, OnDestroy, OnInit, Type } from '@angular/core';
import { Allotment } from '../../allotment';
import { latLng, LayerGroup, marker, icon, DomUtil, layerGroup, polyline, } from 'leaflet';
import * as L from 'leaflet';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountDialogComponent } from '../count-dialog/count-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { RailingDialogComponent } from '../railing-dialog/railing-dialog.component';
import { GpsService } from '../../gps/gps.service';
import { GpsQuality } from '../../gps/gps-quality';
import { ActivationEnd, Router } from '@angular/router';
import { IconFactory } from '../../icon-factory';
import { OverlayContainer } from '@angular/cdk/overlay';
import { SavePointDialogComponent } from '../save-point-dialog/save-point-dialog.component';
import { RegisterParsellDialogComponent } from '../register-parsell-dialog/register-parsell-dialog.component';
import { DataService } from '../../data.service';
import { Subject } from 'rxjs';
import { MeasureService } from '../../measure.service';
import { AddArticleDialogComponent } from '../add-article-dialog/add-article-dialog.component';
import { LengthConfirmedSnackbarComponent } from '../length-confirmed-snackbar/length-confirmed-snackbar.component';
import { VerifyArticlesPoint } from '../../verify-articles-point';
import { UserService } from '../../user.service';
import { ConfirmDeletePointDialogComponent } from '../confirm-delete-point-dialog/confirm-delete-point-dialog.component';
import { QualityService } from '../../quality.service';
import { QualityAuditDialogComponent } from '../quality-audit-dialog/quality-audit-dialog.component';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { ElectronService } from '../../electron.service';
import { DialogService } from '../../dialog.service';
import { catchError } from 'rxjs/operators';
import { HeaderService } from '../header/header.service';
import { LoggerService } from '../../../environments/environment';
import { ArticlesComponent } from '../articles/articles.component';
import { MapService } from '../../map.service';
var MeasureComponent = /** @class */ (function () {
    function MeasureComponent(dialog, gpsService, router, overlayContainer, dataService, measureService, snackBar, userService, qualityService, electronService, dialogService, headerService, log, mapService) {
        var _this = this;
        this.dialog = dialog;
        this.gpsService = gpsService;
        this.router = router;
        this.overlayContainer = overlayContainer;
        this.dataService = dataService;
        this.measureService = measureService;
        this.snackBar = snackBar;
        this.userService = userService;
        this.qualityService = qualityService;
        this.electronService = electronService;
        this.dialogService = dialogService;
        this.headerService = headerService;
        this.log = log;
        this.mapService = mapService;
        this.hostClass = true;
        this.savingInProgress = false;
        this.qualityBars = [true, false, false, false, false];
        this.lastPoint = false;
        this.expanding = false;
        this.pointStatusTxt = 'Parsell ikke påbegynt';
        this.accumulatedArticles = [];
        this.articleAdjustments = {};
        // temporary object, storing the edits made from the dialog until all articles are added.
        this.tmpArticleAdjustments = {};
        this.hasFlownToPosOnMap = false;
        this.compositeArticles = [];
        this.compositeArticlesSubject = new Subject();
        this.markerGroup = new LayerGroup();
        this.allotmentLoaded = false;
        // This is used for article calculation if the last verified point is deleted. Set to null when new calculation
        this.lastDeletedVerifiedPoint = null;
        // TODO (john) make sure that `endArticleAdded` still works after a point has been deleted.
        // TODO (john) maybe set `endArticleAdded` to false if the deleted point is the last measuring point in the track?
        this.endArticleAdded = false;
        this.gpsDisabled = false;
        this.getMyPosition = function () {
            _this.gpsSub = _this.gpsService.getObservable().subscribe(function (gpsAndRoadRefData) {
                var gpsData = gpsAndRoadRefData.gpsData;
                if (_this.myMap && gpsData && gpsData.lat !== 0.0 && !_this.hasFlownToPosOnMap) {
                    _this.myMap.flyTo([gpsData.lat, gpsData.lon], 18);
                    _this.hasFlownToPosOnMap = true;
                }
                if (gpsData && gpsData.lat !== 0.0 && gpsData.type !== 'RMC') {
                    _this.gpsData = gpsData;
                    if (_this.parcel) {
                        _this.distance = _this.measureService.getDistanceSincePoint(_this.parcel.points[_this.parcel.points.length - 1], gpsData);
                        if (_this.myPositionMarker) {
                            _this.myPositionMarker.removeFrom(_this.myMap);
                        }
                        _this.satQuality = _this.gpsQuality.getGpsQuality(gpsData.quality, gpsData.hdop);
                        _this.setQualityBars();
                        _this.myPositionMarker = marker(latLng(gpsData.lat, gpsData.lon), {
                            icon: icon({
                                iconSize: [25, 41],
                                iconAnchor: [13, 41],
                                iconUrl: 'assets/marker-icon.png',
                                shadowUrl: 'assets/marker-shadow.png'
                            })
                        }).setZIndexOffset(0);
                        _this.myPositionMarker.addTo(_this.myMap);
                        _this.roadRef = gpsAndRoadRefData.roadRef;
                        if (_this.roadRef && _this.roadRef.curve) {
                            _this.roadRef.municipality = _this.roadRef.curve.municipality;
                        }
                    }
                }
            });
        };
        this.isElectron = this.electronService.isElectron();
        this.overlayContainer.getContainerElement().classList.add('secondary-theme');
        this.satQuality = 1;
        this.gpsQuality = new GpsQuality();
        this.routerSubscription = this.router.events.subscribe(function (event) {
            if (event && event instanceof ActivationEnd) {
                _this.orderUnid = event.snapshot.params['orderUnid'];
                _this.parcelUnid = event.snapshot.params['parcelId'];
                _this.getParcel(_this.parcelUnid);
            }
        });
        if (this.gpsDisabled === false) {
            this.getMyPosition();
        }
        this.getSaJson();
        this.options = {
            layers: [],
            attributionControl: false,
            zoomControl: false,
            zoom: 11,
            maxZoom: 23,
            minZoom: 4,
            center: latLng(62.527048, 7.556067)
        };
    }
    MeasureComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.headerService.addNavItem({
            route: "/measure/" + this.orderUnid + "/" + this.parcelUnid,
            label: 'Registrering',
            queryParams: ''
        });
        // offlinemap.tileLayers.init();
        this.auditSubscription = this.qualityService.getQualityAudits().subscribe(function (res) {
            _this.qualityAudits = res;
        });
        this.formGroup = new FormGroup({
            'typeOfPoint': new FormControl('', [Validators.required]),
            'gpsEnabled': new FormControl('')
        });
    };
    MeasureComponent.prototype.ngAfterViewInit = function () {
    };
    MeasureComponent.prototype.canDeactivate = function () {
        if (this.pointStatusTxt === 'Parsell ikke påbegynt' || this.parcel.isComplete) {
            return true;
        }
        // Otherwise ask the user with the dialog service and return its
        // observable which resolves to true or false when the user decides
        return this.dialogService.confirm('Vil du avbryte innmåling av spor?\n\nMålepunkt hittil vil ikke bli lagra.');
    };
    MeasureComponent.prototype.onMapReady = function (map) {
        return __awaiter(this, void 0, void 0, function () {
            var vector;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.myMap = map;
                        this.myMap.options.zoomControl = false;
                        map.setView(L.latLng(62.527048, 7.556067), 11)
                            .on('popupclose ', function ($event) { return _this.onPopupClose($event); });
                        return [4 /*yield*/, this.mapService.getVectorTileLayer()];
                    case 1:
                        vector = _a.sent();
                        vector.addTo(map);
                        this.markerGroup = layerGroup().addTo(this.myMap);
                        this.myMap.on('click', function ($event) { return _this.onMapCLick($event); });
                        this.myMap.scrollWheelZoom.disable();
                        return [2 /*return*/];
                }
            });
        });
    };
    MeasureComponent.prototype.ngOnDestroy = function () {
        if (this.gpsSub) {
            this.gpsSub.unsubscribe();
        }
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
        if (this.categorySubscription) {
            this.categorySubscription.unsubscribe();
        }
        if (this.auditSubscription) {
            this.auditSubscription.unsubscribe();
        }
        this.headerService.removeNavItem({
            route: "/measure/" + this.orderUnid + "/" + this.parcelUnid,
            label: 'Registrering',
            queryParams: ''
        });
    };
    MeasureComponent.prototype.editParcel = function () {
        this.parcel.isComplete = false;
        this.parcelHeader.state = 1;
        this.lastPoint = true;
        this.notifyChildren();
    };
    MeasureComponent.prototype.getParcel = function (unid) {
        var _this = this;
        this.parcel = new Allotment(unid);
        this.parcel.points = [];
        forkJoin(this.dataService.getParcels(this.orderUnid), this.dataService.getOrder(this.orderUnid)).subscribe(function (res) {
            _this.order = res[1];
            _this.order.allotmentHeaders = res[0];
            for (var _i = 0, _a = _this.order.allotmentHeaders; _i < _a.length; _i++) {
                var parcelHead = _a[_i];
                if (parcelHead.unid === unid) {
                    _this.parcel.title = parcelHead.title;
                    _this.parcel.startVegKortform = parcelHead.startVegKortform;
                    _this.parcel.sluttVegKortform = parcelHead.sluttVegKortform;
                    _this.parcelHeader = parcelHead;
                    _this.allotmentLoaded = true;
                    _this.parcel.rtType = parcelHead.rtType;
                    _this.parcel.invoiceType = parcelHead.invoiceType;
                    if (parcelHead.state && parcelHead.state >= Allotment.STATE_REGISTRATION_COMPLETE) {
                        if (parcelHead.points != null) {
                            for (var _b = 0, _c = parcelHead.points; _b < _c.length; _b++) {
                                var p = _c[_b];
                                var isCountingPoint = p.verifyArticlesPoint;
                                _this.saveMarker(p, isCountingPoint, false, false);
                            }
                        }
                        _this.parcel.startType = parcelHead.startType;
                        _this.parcel.endType = parcelHead.endType;
                        _this.accumulatedArticles = [];
                        _this.initExistingArticlesFromOrder(parcelHead);
                        _this.parcel.isComplete = true;
                        _this.pointStatusTxt = 'Parsell utført.';
                        _this.updateBuildLength();
                        if (parcelHead.points != null) {
                            _this.updateCompositeArticles(_this.parcel);
                        }
                        else {
                            _this.updateCArticles(parcelHead);
                        }
                        if (parcelHead.points && parcelHead.points.length > 0) {
                            var lat = _this.gpsService.getDecimalDegreesFromDegreeMinutes(_this.parcel.points[0].gga.latitude);
                            var lon = _this.gpsService.getDecimalDegreesFromDegreeMinutes(_this.parcel.points[0].gga.longitude);
                            _this.myMap.flyTo([lat, lon], 18);
                            _this.myMap.once('moveend', function () {
                                _this.updatePolyLine();
                            });
                        }
                        else {
                            _this.gpsDisabled = true;
                            _this.formGroup.patchValue({ 'gpsEnabled': true });
                            _this.formGroup.get('gpsEnabled').disable();
                        }
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    }
                }
            }
            _this.categorySubscription = _this.dataService.getRailTypesCategories().subscribe(function (data) {
                var rt = data;
                for (var cat in rt) {
                    if (rt.hasOwnProperty(cat)) {
                        for (var _i = 0, _a = rt[cat]; _i < _a.length; _i++) {
                            var obj = _a[_i];
                            if (obj.id === _this.parcel.rtType) {
                                _this.selectedRailingType = obj;
                            }
                        }
                    }
                }
                _this.plotPointsFromOrderOnMap();
            });
        });
    };
    MeasureComponent.prototype.zoomOut = function (event) {
        if (this.myMap.getZoom() >= 8) {
            this.myMap.setZoom(this.myMap.getZoom() - 1);
        }
    };
    MeasureComponent.prototype.zoomIn = function (event) {
        this.myMap.setZoom(this.myMap.getZoom() + 1);
    };
    MeasureComponent.prototype.goToMyLocation = function (event) {
        if (this.myPositionMarker) {
            this.myMap.flyTo(this.myPositionMarker.getLatLng(), 18);
        }
    };
    MeasureComponent.prototype.goToTrackLocatoin = function (event) {
        if (this.parcel && this.parcel.points && this.parcel.points.length > 0) {
            var lat = this.gpsService.getDecimalDegreesFromDegreeMinutes(this.parcel.points[0].gga.latitude);
            var lon = this.gpsService.getDecimalDegreesFromDegreeMinutes(this.parcel.points[0].gga.longitude);
            this.myMap.flyTo([lat, lon], 18);
        }
    };
    MeasureComponent.prototype.onMapCLick = function (event) {
        if (this.selectedPointMarker) {
            DomUtil.removeClass(this.selectedPointMarker.getElement(), 'active-marker');
        }
        this.selectedPointMarker = null;
        this.selectedMeasuringPoint = null;
    };
    MeasureComponent.prototype.onMarkerClick = function (event, measuringPoint, pointMarker) {
        if (pointMarker) {
            DomUtil.addClass(pointMarker.getElement(), 'active-marker');
        }
        if (this.selectedPointMarker) {
            DomUtil.removeClass(this.selectedPointMarker.getElement(), 'active-marker');
        }
        if (pointMarker === this.selectedPointMarker) {
            this.selectedPointMarker = null;
        }
        else {
            this.selectedPointMarker = pointMarker;
            this.selectedMeasuringPoint = measuringPoint;
        }
    };
    MeasureComponent.prototype.openSavePointDialog = function () {
        if (this.gpsDisabled) {
            this.openDialog(RegisterParsellDialogComponent);
        }
        else {
            this.openDialog(SavePointDialogComponent);
        }
    };
    MeasureComponent.prototype.animationDone = function () {
        this.expanding = false;
    };
    MeasureComponent.prototype.openRailDialog = function (railingType, isDemolition) {
        this.openDialog(RailingDialogComponent, { railingType: railingType, isDemolition: isDemolition });
    };
    MeasureComponent.prototype.openDialog = function (type, args) {
        var _this = this;
        var dialogRef;
        var data;
        var panelClass = '';
        switch (type) {
            case CountDialogComponent:
                data = { type: type, gpsQuality: this.gpsQuality, gpsData: this.gpsData };
                break;
            case RailingDialogComponent:
                data = { type: type, railingType: args.railingType, isDemolition: args.isDemolition };
                break;
            case SavePointDialogComponent:
                var pointsToCalculate = void 0;
                try {
                    pointsToCalculate = this.getPoinstToCalculate(this.parcel.points);
                }
                catch (e) {
                    this.log.error(e);
                }
                var lengthSinceLastCountingPoint = void 0;
                try {
                    lengthSinceLastCountingPoint = this.getTotalDistance(pointsToCalculate);
                }
                catch (e) {
                    this.log.error(e);
                }
                panelClass = ['save-point-dialog-panel-class', 'satelite-quality-' + this.satQuality];
                data = {
                    type: type,
                    gpsQuality: this.satQuality,
                    gpsData: this.gpsData,
                    typeOfPoint: this.lastPointType,
                    points: pointsToCalculate,
                    rtType: this.selectedRailingType,
                    demolitionType: this.selectedDemolitionType,
                    tmpArticleAdjustments: this.tmpArticleAdjustments,
                    order: this.order,
                    parcel: this.parcel,
                    isiRoadRef: this.roadRef,
                    lengthSinceLastCountingPoint: lengthSinceLastCountingPoint,
                    parsellStatus: this.parsellStatus,
                    endArticleAdded: this.endArticleAdded,
                    isDemobilizingState: this.isDemobilizingState,
                    isMeasuringState: this.isMeasuringState
                };
                break;
            case RegisterParsellDialogComponent:
                data = {
                    type: type,
                    rtType: this.selectedRailingType,
                    demolitionType: this.selectedDemolitionType,
                    selectedRailingType: this.selectedRailingType,
                    parcel: this.parcel,
                    parcelHeader: this.parcelHeader,
                    startType: this.sa[Number.parseInt(this.parcelHeader.startType)],
                    endType: this.sa[Number.parseInt(this.parcelHeader.endType)]
                };
                break;
            case AddArticleDialogComponent:
                var typeOfArticle = void 0;
                if (args && args.typeOfArticle) {
                    typeOfArticle = args.typeOfArticle;
                }
                data = { type: type, typeOfArticle: typeOfArticle };
                break;
            case ConfirmDeletePointDialogComponent:
                data = { type: type };
                if (args) {
                    data.pointToDelete = args.pointToDelete;
                    data.isLastVerifiedPoint = args.isLastVerifiedPoint;
                }
                break;
            case QualityAuditDialogComponent:
                data = { type: type };
                break;
            default:
                break;
        }
        dialogRef = this.dialog.open(type, {
            height: '80%',
            width: '90%',
            panelClass: panelClass,
            data: data
        });
        dialogRef.beforeClose().subscribe(function (result) {
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result !== 'cancel') {
                switch (result.type) {
                    case CountDialogComponent:
                        break;
                    case RailingDialogComponent:
                        if (result.isDemolition) {
                            _this.selectedDemolitionType = result.railingType;
                        }
                        else {
                            _this.selectedRailingType = result.railingType;
                        }
                        break;
                    case SavePointDialogComponent:
                        _this.parsellStatus = result.parsellStatus;
                        _this.lastPointType = result.typeOfPoint;
                        _this.isMeasuringState = result.isMeasuringState;
                        _this.isDemobilizingState = result.isDemobilizingState;
                        _this.lastPoint = result.isLastPoint;
                        _this.selectedDemolitionType = result.demolitionType;
                        _this.selectedRailingType = result.rtType;
                        _this.endArticleAdded = result.endArticleAdded;
                        var isCountingPoint = result.articles ? true : false;
                        _this.saveMarker(result.tmpPoint, isCountingPoint, true, true);
                        if (_this.lastPoint) {
                            _this.pointStatusTxt = "Fullført";
                        }
                        else {
                            _this.updatePointStatusTxt(result.typeOfPoint);
                        }
                        // we only receive the articles when we confirm the materials from the dialog
                        if (result.articles) {
                            _this.updateAdjustedArticlesAndResetTmpAdjustments(result.tmpArticleAdjustments);
                            _this.updateArticlesAndVerifyLastPoint(result.articles);
                            _this.lastDeletedVerifiedPoint = null;
                        }
                        _this.updateBuildLength();
                        _this.updateCompositeArticles(_this.parcel);
                        if (_this.parcel.points && _this.parcel.points.length > 0 && !_this.hasFlownToPosOnMap) {
                            var lastPointInAllotment = _this.parcel.points[_this.parcel.points.length - 1];
                            _this.myMap.flyTo([parseFloat(lastPointInAllotment.gga.latitude), parseFloat(lastPointInAllotment.gga.longitude)], 18);
                            _this.hasFlownToPosOnMap = true;
                        }
                        _this.shouldPromptQualityQuestion();
                        break;
                    case RegisterParsellDialogComponent:
                        _this.accumulatedArticles = result.articles;
                        _this.parcelHeader.sideOfRoad = result.side;
                        _this.startArticle = result.startArticle;
                        _this.endArticle = result.endArticle;
                        _this.lengde = result.length;
                        _this.demolitionLength = result.demolitionLength;
                        _this.demolitionRailWreck = result.demolitionRailWreck;
                        _this.demolitionStickWreck = result.demolitionStickWreck;
                        _this.compositeArticles = result.compositeArticles;
                        _this.rtType = result.rtType;
                        _this.updateBuildLength();
                        _this.notifyChildren();
                        break;
                    case AddArticleDialogComponent:
                        if (result.article && result.quantity) {
                            if (result.addType === 'compositeArticle') {
                                var artToAdd = result.article;
                                artToAdd.selectedQuantity = result.quantity;
                                _this.compositeArticles.push(artToAdd);
                                _this.notifyChildren();
                                _this.dataService.getArticlesFromCompositeArticle(_this.parcel.rtType, artToAdd.num, artToAdd.selectedQuantity).subscribe(function (res) {
                                    res.forEach(function (article) {
                                        _this.addArticleToList(article, article.quantityNew, false);
                                    });
                                });
                                _this.snackBar.open('Strukturartikkel lagt til i listen', '', { duration: 5000 });
                            }
                            else {
                                _this.addArticleToList(result.article, result.quantity, true);
                            }
                        }
                        break;
                    case ConfirmDeletePointDialogComponent:
                        if (result) {
                            _this.handleArticlesAfterDeletePoint(result);
                        }
                        break;
                    case QualityAuditDialogComponent:
                        var qualityAudit = result.qualityAudit;
                        _this.parcel.qualityQuestionAnswered = true;
                        _this.parcel.qualityAnswer = qualityAudit.answer;
                        _this.parcel.qualityQuestion = "" + result.qualityNumber;
                        _this.parcel.qualityAuditLocationId = "" + (_this.parcel.points.length - 1);
                        _this.parcel.qualityQuestionText = qualityAudit.question;
                        _this.parcel.qualityQuestionCompleted = new Date();
                        break;
                    default:
                        break;
                }
            }
        });
    };
    MeasureComponent.prototype.notifyChildren = function () {
        this.compositeArticlesSubject.next(this.compositeArticles);
    };
    MeasureComponent.prototype.subtractArticlesSinceLastCountingPoint = function (lastVerifiedPoint) {
        var _this = this;
        // XXX Deleted point is last point that is verified. Remove articles since last verified point calculation.
        // XXX Manual adjustments are still kept.
        var tmpPointsToCalc = this.getPoinstToCalculate(this.parcel.points);
        tmpPointsToCalc.push(lastVerifiedPoint);
        var demoType = this.selectedDemolitionType || { id: '' };
        var splitPoints = this.measureService.splitPoints(tmpPointsToCalc);
        var requests = this.measureService.createCalculatedArticlesRequests(splitPoints, this.selectedRailingType.id, demoType.id, this.parcel.invoiceType, this.parcel.unid).requests;
        forkJoin(requests).subscribe(function (res) {
            var articlesMap = _this.measureService.createArticlesMap(res);
            for (var _i = 0, _a = _this.accumulatedArticles; _i < _a.length; _i++) {
                var accumulatedArticle = _a[_i];
                if (articlesMap[accumulatedArticle.id]) {
                    accumulatedArticle.quantityOld -= articlesMap[accumulatedArticle.id].quantityOld;
                    accumulatedArticle.quantityTotal -= articlesMap[accumulatedArticle.id].quantityTotal;
                    accumulatedArticle.quantitySuggested -= articlesMap[accumulatedArticle.id].quantitySuggested;
                }
            }
        });
    };
    /**
     * Randomly prompts the user with a quality question.
     * Always prompts if last point, and not answered any question yet.
     */
    MeasureComponent.prototype.shouldPromptQualityQuestion = function () {
        if (this.lastPoint && !this.parcel.qualityQuestionAnswered) {
            this.promptRandomQualityQuestion();
        }
        else if (!this.parcel.qualityQuestionAnswered) {
            var randomNum = Math.random();
            if (this.parcel.lengthFromOrder && parseFloat(this.parcel.lengthFromOrder)) {
                var lengthFromOrder = parseFloat(this.parcel.lengthFromOrder);
                if (lengthFromOrder > 0 && randomNum > 1 / lengthFromOrder * 10) {
                    this.promptRandomQualityQuestion();
                }
            }
            else {
                if (randomNum > 0.9) {
                    this.promptRandomQualityQuestion();
                }
            }
        }
    };
    MeasureComponent.prototype.promptRandomQualityQuestion = function () {
        this.openDialog(QualityAuditDialogComponent);
    };
    MeasureComponent.prototype.addCompositeArticleClick = function (event) {
        this.openDialog(AddArticleDialogComponent, { typeOfArticle: 'compositeArticle' });
    };
    MeasureComponent.prototype.addArticleClick = function (event) {
        this.openDialog(AddArticleDialogComponent, { typeOfArticle: 'article' });
    };
    MeasureComponent.prototype.handleArticlesAfterDeletePoint = function (result) {
        if (result.deleteArticles) { // delete all articles, but keep the composite articles.
            this.deleteArticlesAndRemoveVerifyFlag();
            this.redrawTrack();
            this.moveCompositeArticlesToPreviousPoint(result.pointToDelete);
            this.lastDeletedVerifiedPoint = null;
        }
        else if (result.pointToDelete && result.pointToDelete.verifyArticlesPoint && result.isLastVerifiedPoint) {
            // deleted point is the last verified point (last counting point).
            // keep articles. Start new calculation from the deleted point.
            if (!this.lastDeletedVerifiedPoint) { // only keep the first `lastDeletedVerifiedPoint` until new calculation.
                this.lastDeletedVerifiedPoint = result.pointToDelete;
            }
        }
        else {
            this.moveCompositeArticlesToPreviousPoint(result.pointToDelete);
        }
        this.updateCompositeArticles(this.parcel);
    };
    MeasureComponent.prototype.moveCompositeArticlesToPreviousPoint = function (pointToDelete) {
        if (pointToDelete && pointToDelete.compositeArticles && pointToDelete.compositeArticles.length > 0) {
            for (var i = 1; i < this.parcel.points.length; i++) {
                var p = this.parcel.points[i];
                if (p.gga.latitude === pointToDelete.gga.latitude &&
                    p.gga.longitude === pointToDelete.gga.longitude) {
                    var prevPoint = this.parcel.points[i - 1];
                    prevPoint.compositeArticles = prevPoint.compositeArticles || [];
                    prevPoint.compositeArticles = prevPoint.compositeArticles.concat(pointToDelete.compositeArticles);
                }
            }
        }
    };
    /**
     * Will merge all the articles with the suggestion from the server and confirmation by the user.
     * It will also verify the last point in the parcel.
     * @param articles
     */
    MeasureComponent.prototype.updateArticlesAndVerifyLastPoint = function (articles) {
        var _this = this;
        var tmpAccumulatedArticles = [];
        if (articles && articles.length > 0) {
            articles.forEach(function (article) {
                var foundArticle = false;
                _this.accumulatedArticles.forEach(function (accumulatedArticle) {
                    if (accumulatedArticle.id === article.id) {
                        foundArticle = true;
                        accumulatedArticle.quantitySuggested += article.quantitySuggested;
                        accumulatedArticle.quantityOld += article.quantityOld;
                        accumulatedArticle.quantityTotal += article.quantityTotal;
                    }
                });
                if (!foundArticle) {
                    tmpAccumulatedArticles.push(article);
                }
            });
            // force update in child component by creating new array.
            this.accumulatedArticles = [].concat(this.accumulatedArticles).concat(tmpAccumulatedArticles);
            this.parcel.points[this.parcel.points.length - 1].verifyArticlesPoint = true;
        }
    };
    /**
     * Delete the selected `measuringPoint`.
     * Remove any classes on the selected element.
     * Set status to deleted if deleted point is a verified point (can be used for article calculation later).
     * Updates polyline and total distance.
     */
    MeasureComponent.prototype.deletePoint = function (measuringPoint) {
        var indexToDel = -1;
        for (var i = 0; i < this.parcel.points.length; i++) {
            if (this.parcel.points[i].gga.latitude === measuringPoint.gga.latitude &&
                this.parcel.points[i].gga.longitude === measuringPoint.gga.longitude) {
                indexToDel = i;
                break;
            }
        }
        if (indexToDel >= 0) {
            // Check if point has been used for article calculation.
            for (var i = this.parcel.points.length - 1; i >= indexToDel; i--) {
                if (this.parcel.points[i].verifyArticlesPoint) {
                    var isLastVerifiedPoint = (indexToDel === i);
                    this.parcel.points[indexToDel].state = this.parcel.points[indexToDel].state + 10 /* MODULATOR */;
                    this.openDialog(ConfirmDeletePointDialogComponent, {
                        pointToDelete: this.parcel.points[indexToDel],
                        isLastVerifiedPoint: isLastVerifiedPoint
                    });
                    break;
                }
            }
            if (indexToDel === this.parcel.points.length - 1) {
                if (this.lastPoint) { // remove articles if the last point was the end point of the parcel.
                    measuringPoint.compositeArticles = [];
                }
                this.lastPoint = false;
            }
            if (this.parcel.points[indexToDel].state < 10 /* MODULATOR */) {
                // We keep the verified points in the allotment for new calculation purposes, but do not draw them on the map.
                this.parcel.points.splice(indexToDel, 1);
            }
            if (this.selectedPointMarker) {
                DomUtil.removeClass(this.selectedPointMarker.getElement(), 'active-marker');
                this.selectedPointMarker.removeFrom(this.myMap);
                this.selectedPointMarker = null;
            }
        }
        this.updatePolyLine();
        this.updateTotalDistance(this.parcel);
        this.myMap.closePopup();
    };
    MeasureComponent.prototype.setQualityBars = function () {
        var quality = (this.satQuality * 2) - 1 || 0;
        for (var i = 1; i < 5; i++) {
            this.qualityBars[i] = --quality > 0;
        }
    };
    MeasureComponent.prototype.plotPointsFromOrderOnMap = function () {
    };
    /**
     * TODO (john) remove `flyToMarker` and `updatePolyline` because they are not part of the method description?
     * Create a marker, and add the latest point to the point list in the parcel.
     * @param {MeasuringPoint} tmpPoint
     * @param {boolean} isCountingPoint
     * @param {boolean} flyToMarker
     * @param {boolean} updatePolyline
     */
    MeasureComponent.prototype.saveMarker = function (tmpPoint, isCountingPoint, flyToMarker, updatePolyline) {
        var _this = this;
        var savedPoint;
        var satQuality;
        var latlngTpl;
        if (tmpPoint) {
            latlngTpl = latLng(parseFloat(tmpPoint.gga.latitude), parseFloat(tmpPoint.gga.longitude));
            savedPoint = tmpPoint;
            satQuality = this.gpsQuality.getGpsQualityFromFixedNumber(tmpPoint.gga.fixQuality, tmpPoint.gga.hdopPrecision);
        }
        var markerIconOptions = IconFactory.create(satQuality, tmpPoint.state, isCountingPoint);
        var lat = this.gpsService.getDecimalDegreesFromDegreeMinutes(savedPoint.gga.latitude);
        var lon = this.gpsService.getDecimalDegreesFromDegreeMinutes(savedPoint.gga.longitude);
        this.parcel.points.push(savedPoint);
        if (tmpPoint.state < 10 /* MODULATOR */ ||
            (tmpPoint && tmpPoint.state < 10 /* MODULATOR */)) {
            // do not create a marker for deleted points.
            var popupContent = '<p>Informasjon om valgt punkt er ikke implementert enda</p>';
            var markerPopup = L.popup()
                .setLatLng(latlngTpl)
                .setContent(popupContent);
            var pointMarker_1 = marker(latLng(lat, lon), markerIconOptions)
                // .bindPopup(markerPopup) // removed because isi.offline map is not working with it
                .setZIndexOffset(1000)
                .on('click', function ($event) { return _this.onMarkerClick($event, savedPoint, pointMarker_1); })
                .addTo(this.markerGroup);
        }
        if (updatePolyline) {
            this.updatePolyLine();
        }
        this.updateTotalDistance(this.parcel);
        if (flyToMarker) {
            this.myMap.flyTo([lat, lon]);
        }
    };
    MeasureComponent.prototype.updatePolyLine = function () {
        if (this.polyLine) {
            this.polyLine.removeFrom(this.myMap);
        }
        var latlngs = [];
        for (var _i = 0, _a = this.parcel.points; _i < _a.length; _i++) {
            var point = _a[_i];
            if (point.state < 10 /* MODULATOR */) {
                latlngs.push([this.gpsService.getDecimalDegreesFromDegreeMinutes(point.gga.latitude),
                    this.gpsService.getDecimalDegreesFromDegreeMinutes(point.gga.longitude)]);
            }
        }
        this.polyLine = polyline(latlngs, { color: 'red', opacity: 0.5 }).addTo(this.myMap);
    };
    MeasureComponent.prototype.onPopupClose = function (event) {
        this.selectedPointMarker = null;
    };
    MeasureComponent.prototype.updatePointStatusTxt = function (status) {
        switch (status) {
            case 1 /* DEMOLITION_AND_MEASURE_POINT */:
                this.pointStatusTxt = "Riving og montering pågår";
                break;
            case 0 /* DEMOLITION_POINT */:
                this.pointStatusTxt = "Riving pågår";
                break;
            case 2 /* MEASURE_POINT */:
                this.pointStatusTxt = "Montering pågår";
                break;
            default:
                this.pointStatusTxt = "Fullført.";
                break;
        }
    };
    MeasureComponent.prototype.updateTotalDistance = function (parcel) {
        parcel.distance = 0;
        for (var i = 1; i < parcel.points.length; i++) {
            if (parcel.points[i]) {
                var pointEnd = parcel.points[i];
                var pointStart = parcel.points[i - 1];
                parcel.distance += this.measureService.getDistanceBetweenMeasuringPoints(pointEnd, pointStart);
            }
        }
    };
    MeasureComponent.prototype.getPoinstToCalculate = function (points) {
        var newPoints = [];
        if (points) {
            for (var i = points.length - 1; i >= 0; i--) {
                newPoints.push(points[i]);
                if (points[i].verifyArticlesPoint) {
                    return newPoints.reverse();
                }
            }
        }
        return newPoints.reverse();
    };
    MeasureComponent.prototype.updateAdjustedArticlesAndResetTmpAdjustments = function (tmpArticleAdjustments) {
        for (var tmpArticleKey in tmpArticleAdjustments) {
            if (this.articleAdjustments.hasOwnProperty(tmpArticleKey)) {
                this.articleAdjustments[tmpArticleKey].quantityAdjusted += tmpArticleAdjustments[tmpArticleKey].quantityAdjusted;
                this.articleAdjustments[tmpArticleKey].quantityTotal += tmpArticleAdjustments[tmpArticleKey].quantityTotal;
                this.articleAdjustments[tmpArticleKey].quantityUsed += tmpArticleAdjustments[tmpArticleKey].quantityUsed;
                this.articleAdjustments[tmpArticleKey].quantitySuggested += tmpArticleAdjustments[tmpArticleKey].quantitySuggested;
            }
            else {
                this.articleAdjustments[tmpArticleKey] = {
                    quantityAdjusted: tmpArticleAdjustments[tmpArticleKey].quantityAdjusted,
                    quantityTotal: tmpArticleAdjustments[tmpArticleKey].quantityTotal,
                    quantityUsed: tmpArticleAdjustments[tmpArticleKey].quantityUsed,
                    quantitySuggested: tmpArticleAdjustments[tmpArticleKey].quantitySuggested,
                };
            }
        }
        // reset the temporary article adjustments
        this.tmpArticleAdjustments = {};
    };
    MeasureComponent.prototype.updateCompositeArticles = function (parcel) {
        this.compositeArticles = [];
        for (var _i = 0, _a = parcel.points; _i < _a.length; _i++) {
            var point = _a[_i];
            if (point && point.compositeArticles && point.compositeArticles.length > 0) {
                for (var _b = 0, _c = point.compositeArticles; _b < _c.length; _b++) {
                    var composite = _c[_b];
                    this.compositeArticles.push(composite);
                }
            }
        }
        this.notifyChildren();
    };
    MeasureComponent.prototype.updateCArticles = function (parcelHead) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this.dataService.getStructureArticles().subscribe(function (data) {
                                    resolve(data);
                                });
                            })];
                    case 1:
                        _a.sa = _b.sent();
                        parcelHead.compositeArticles.forEach(function (article) {
                            var compArt = Object.assign({}, _this.sa[article.num]);
                            compArt.selectedQuantity = article.selectedQuantity;
                            _this.compositeArticles.push(compArt);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MeasureComponent.prototype.addCompositeArticleToPoints = function (article, quantity) {
        if (this.parcel && this.parcel.points) {
            var lastIndex = 0;
            if (this.parcel.points.length > 0) {
                lastIndex = this.parcel.points.length - 1;
            }
            this.parcel.points[lastIndex].compositeArticles = this.parcel.points[lastIndex].compositeArticles || [];
            var sameTypeExists = false;
            for (var _i = 0, _a = this.parcel.points[lastIndex].compositeArticles; _i < _a.length; _i++) {
                var ca = _a[_i];
                if (ca.id === article.id) {
                    ca.selectedQuantity = ca.selectedQuantity || 0;
                    ca.selectedQuantity += quantity;
                    sameTypeExists = true;
                    break;
                }
            }
            if (!sameTypeExists) {
                article.selectedQuantity = quantity;
                this.parcel.points[lastIndex].compositeArticles.push(article);
            }
            this.updateCompositeArticles(this.parcel);
            this.snackBar.open('Strukturartikkel lagt til i listen', '', { duration: 5000 });
        }
    };
    MeasureComponent.prototype.addArticleToList = function (article, quantity, showSnackbar) {
        var regularArticle = article;
        // always use adjusted when adding articles manually
        regularArticle.quantityOld = 0;
        regularArticle.quantitySuggested = 0;
        regularArticle.quantityTotal = 0;
        regularArticle.quantityNew = 0;
        if (this.articleAdjustments.hasOwnProperty(regularArticle.id)) {
            this.articleAdjustments[regularArticle.id].quantityAdjusted += quantity;
            this.accumulatedArticles = [].concat(this.accumulatedArticles);
        }
        else {
            this.articleAdjustments[regularArticle.id] = {
                quantityAdjusted: quantity,
                quantityTotal: quantity,
                quantityUsed: 0,
                quantitySuggested: 0,
            };
            this.accumulatedArticles = [].concat(this.accumulatedArticles).concat([regularArticle]);
        }
        if (showSnackbar) {
            this.snackBar.open('Artikkel lagt til i listen', '', { duration: 5000 });
        }
    };
    MeasureComponent.prototype.saveAndConfirmLength = function () {
        var _this = this;
        var postAllotment = this.createAllotmentToSave();
        this.savingInProgress = true;
        this.dataService.saveAllotment(postAllotment).subscribe(function (res) {
            _this.savingInProgress = false;
            if (res) {
                _this.parcel.isComplete = true;
                _this.parcel.state = Allotment.STATE_REGISTRATION_COMPLETE;
                _this.snackBar.openFromComponent(LengthConfirmedSnackbarComponent, {
                    duration: 2000
                });
            }
        }, catchError(function (err) {
            _this.savingInProgress = false;
            return null;
        }));
    };
    MeasureComponent.prototype.goToOrders = function () {
        var urlTree = this.router.createUrlTree(['/order/' + this.orderUnid]);
        this.router.navigateByUrl(urlTree);
    };
    MeasureComponent.prototype.onArticleChanged = function (event) {
        this.updateBuildLength();
    };
    MeasureComponent.prototype.getSaJson = function () {
        var _this = this;
        this.dataService.getStructureArticles().subscribe(function (data) {
            _this.sa = data;
        });
    };
    MeasureComponent.prototype.updateBuildLength = function () {
        this.buildLength = this.measureService.getBuildLength(this.accumulatedArticles, this.articleAdjustments);
    };
    MeasureComponent.prototype.deleteArticlesAndRemoveVerifyFlag = function () {
        this.accumulatedArticles = [];
        this.articleAdjustments = {};
        for (var _i = 0, _a = this.parcel.points; _i < _a.length; _i++) {
            var p = _a[_i];
            p.verifyArticlesPoint = false;
        }
    };
    MeasureComponent.prototype.redrawTrack = function () {
        this.markerGroup.clearLayers();
        var tmpPoints = [];
        for (var _i = 0, _a = this.parcel.points; _i < _a.length; _i++) {
            var p = _a[_i];
            tmpPoints.push(p);
        }
        this.parcel.points = [];
        for (var _b = 0, tmpPoints_1 = tmpPoints; _b < tmpPoints_1.length; _b++) {
            var p = tmpPoints_1[_b];
            var isCountingPoint = p.verifyArticlesPoint;
            this.saveMarker(p, isCountingPoint, false, false);
        }
        this.updatePolyLine();
    };
    MeasureComponent.prototype.saveParsell = function () {
        var _this = this;
        var postAllotment = new Allotment();
        postAllotment.appVersion = this.electronService.getVersion();
        postAllotment.title = this.parcelHeader.title;
        postAllotment.unid = this.parcelHeader.unid;
        postAllotment.state = Allotment.STATE_REGISTRATION_COMPLETE;
        postAllotment.docId = this.parcelHeader.docId;
        postAllotment.sideOfRoad = this.parcelHeader.sideOfRoad;
        postAllotment.lengthFromOrder = this.parcelHeader.lengthFromOrder;
        postAllotment.customer = this.parcelHeader.customer;
        postAllotment.rtType = this.selectedRailingType.id;
        if (this.startArticle && this.endArticle) {
            postAllotment.startType = this.startArticle.num + " - " + this.startArticle.id;
            postAllotment.endType = this.endArticle.num + " - " + this.endArticle.id;
        }
        else if (this.parcelHeader.startType && this.parcelHeader.endType) {
            this.parcelHeader.compositeArticles.forEach(function (art) {
                _this.parcelHeader.startType === art.num ? (postAllotment.startType = _this.parcelHeader.startType + " - " + art.name) : '00';
                _this.parcelHeader.endType === art.num ? postAllotment.endType = _this.parcelHeader.endType + " - " + art.name : '00';
            });
        }
        postAllotment.isComplete = true;
        postAllotment.user = this.userService.userName || "";
        postAllotment.orderUnid = this.order.unid;
        postAllotment.orderNum = this.order.snum;
        postAllotment.afterReg = false;
        postAllotment.roadSection = this.parcelHeader.roadSection;
        postAllotment.allotmentLastSaved = new Date();
        postAllotment.distance = this.lengde;
        postAllotment.demolitionLength = this.demolitionLength;
        postAllotment.demolitionRailWreck = this.demolitionRailWreck;
        postAllotment.demolitionStickWreck = this.demolitionStickWreck;
        var verifyArticlePoint = new VerifyArticlesPoint();
        verifyArticlePoint.measuringPoints = [];
        verifyArticlePoint.articles = [];
        this.accumulatedArticles.forEach(function (art) {
            verifyArticlePoint.articles.push(Object.assign({}, art)); // shallow copy, preventing the original article to be changed
        });
        for (var _i = 0, _a = verifyArticlePoint.articles; _i < _a.length; _i++) {
            var a = _a[_i];
            if (this.articleAdjustments[a.id]) {
                // XXX need to change the values in order to make the material list on the intranet correct.
                // XXX old and new are not used in GUI in electron app, so it is safe to change them when posting.
                a.quantityTotal = this.measureArticles.formControlMap[a.id].totalControl.value;
                a.quantityOld = this.measureArticles.formControlMap[a.id].usedControl.value;
                a.quantityNew = a.quantityTotal - a.quantityOld;
            }
        }
        postAllotment.verifyArticlesPoints = [verifyArticlePoint];
        postAllotment.compositeArticles = [];
        this.compositeArticles.forEach(function (articles) {
            postAllotment.compositeArticles.push({
                num: articles.num,
                name: articles.name,
                selectedQuantity: articles.selectedQuantity.toString()
            });
        });
        this.savingInProgress = true;
        console.log(JSON.stringify(postAllotment, null, 2));
        this.dataService.saveAllotment(postAllotment).subscribe(function (res) {
            _this.savingInProgress = false;
            console.log('res', JSON.stringify(res, null, 2));
            if (res) {
                _this.parcel.isComplete = true;
                _this.parcel.state = Allotment.STATE_REGISTRATION_COMPLETE;
                _this.snackBar.openFromComponent(LengthConfirmedSnackbarComponent, {
                    duration: 2000
                });
            }
        }, catchError(function (err) {
            _this.savingInProgress = false;
            return null;
        }));
    };
    /**
     * Used to create an object that matches the way GeTac saved the allotment.
     * @returns {Allotment}
     */
    MeasureComponent.prototype.createAllotmentToSave = function () {
        var postAllotment = new Allotment();
        postAllotment.appVersion = this.electronService.getVersion();
        postAllotment.startType = "00";
        postAllotment.endType = "00";
        postAllotment.isComplete = true;
        postAllotment.state = Allotment.STATE_REGISTRATION_COMPLETE;
        postAllotment.user = this.userService.userName || "";
        postAllotment.lengthFromOrder = this.parcelHeader.lengthFromOrder;
        postAllotment.unid = this.parcelHeader.unid;
        postAllotment.title = this.parcelHeader.title;
        postAllotment.docId = this.parcelHeader.docId;
        postAllotment.roadSection = this.parcelHeader.roadSection;
        postAllotment.orderUnid = this.parcelHeader.orderUnid;
        postAllotment.rtType = this.selectedRailingType.id;
        postAllotment.sideOfRoad = this.parcelHeader.sideOfRoad;
        postAllotment.afterReg = false;
        postAllotment.customer = this.parcelHeader.customer;
        postAllotment.qualityAuditLocationId = this.parcel.qualityAuditLocationId;
        postAllotment.qualityQuestionCompleted = this.parcel.qualityQuestionCompleted;
        postAllotment.qualityQuestionText = this.parcel.qualityQuestionText;
        postAllotment.qualityQuestion = this.parcel.qualityQuestion;
        postAllotment.qualityQuestionAnswered = this.parcel.qualityQuestionAnswered;
        postAllotment.qualityAnswer = this.parcel.qualityAnswer;
        postAllotment.stopEndFirst = this.parcel.stopEndFirst;
        try {
            // sett start og slutt type fra første og siste monteringspunkt.
            for (var i = 0; i < this.parcel.points.length; i++) {
                var point = this.parcel.points[i];
                if (point.state % 10 !== 0) {
                    for (var j = 0; j < point.compositeArticles.length; j++) {
                        var compArt = point.compositeArticles[j];
                        if (compArt.category === 'Ende') {
                            postAllotment.startType = compArt.num + " - " + compArt.id;
                            break;
                        }
                    }
                    break;
                }
            }
            for (var i = this.parcel.points.length - 1; i >= 0; i--) {
                var point = this.parcel.points[i];
                if (point.state % 10 !== 0) {
                    // find end composite article
                    for (var j = 0; j < point.compositeArticles.length; j++) {
                        var compArt = point.compositeArticles[j];
                        if (compArt.category === 'Ende') {
                            postAllotment.endType = compArt.num + " - " + compArt.id;
                            break;
                        }
                    }
                    break;
                }
            }
        }
        catch (e) {
            console.error("error getting end types..");
        }
        postAllotment.orderNum = this.order.snum;
        postAllotment.quality = this.measureService.getLowestQualityFromTrack(this.parcel.points);
        if (postAllotment.quality <= 1 /* LOW */) {
            postAllotment.quality = 1 /* LOW */;
        }
        postAllotment.allotmentLastSaved = new Date();
        var verifyArticlePoint = new VerifyArticlesPoint();
        verifyArticlePoint.measuringPoints = [];
        postAllotment.points = [];
        var uniqueId = 0;
        for (var _i = 0, _a = this.parcel.points; _i < _a.length; _i++) {
            var p = _a[_i];
            p.railType = this.selectedRailingType;
            p.uniqueId = "" + uniqueId++;
            p.verified = true;
            if (!p.gga.altitude) {
                p.gga.altitude = "0";
            }
            if (p.state < 10 /* MODULATOR */) { // do not add deleted points.
                verifyArticlePoint.measuringPoints.push(p);
                postAllotment.points.push(p);
            }
        }
        this.measureService.calcDistsanceBetweenPoints(postAllotment.points);
        verifyArticlePoint.articles = [];
        this.accumulatedArticles.forEach(function (art) {
            verifyArticlePoint.articles.push(Object.assign({}, art)); // shallow copy, preventing the original article to be changed
        });
        for (var _b = 0, _c = verifyArticlePoint.articles; _b < _c.length; _b++) {
            var a = _c[_b];
            if (this.articleAdjustments[a.id]) {
                // XXX need to change the values in order to make the material list on the intranet correct.
                // XXX old and new are not used in GUI in electron app, so it is safe to change them when posting.
                a.quantityTotal = this.measureArticles.formControlMap[a.id].totalControl.value;
                a.quantityOld = this.measureArticles.formControlMap[a.id].usedControl.value;
                a.quantityNew = a.quantityTotal - a.quantityOld;
            }
        }
        postAllotment.verifyArticlesPoints = [verifyArticlePoint];
        return postAllotment;
    };
    MeasureComponent.prototype.getTotalDistance = function (points) {
        var tempDist = 0;
        for (var _i = 0, points_1 = points; _i < points_1.length; _i++) {
            var p = points_1[_i];
            tempDist += p.distanceFromPreviousPoint;
        }
        return tempDist;
    };
    MeasureComponent.prototype.disableGps = function (e) {
        console.log(e);
        if (e.checked === true) {
            this.gpsDisabled = true;
        }
        else {
            this.gpsDisabled = false;
        }
    };
    MeasureComponent.prototype.initExistingArticlesFromOrder = function (parcelHead) {
        if (parcelHead.verifyArticlesPoints) {
            this.articleAdjustments = {};
            for (var _i = 0, _a = parcelHead.verifyArticlesPoints; _i < _a.length; _i++) {
                var verifyArticlePoint = _a[_i];
                for (var _b = 0, _c = verifyArticlePoint.articles; _b < _c.length; _b++) {
                    var a = _c[_b];
                    if (!this.articleAdjustments[a.id]) {
                        this.articleAdjustments[a.id] = {
                            quantityAdjusted: a.quantityTotal - a.quantitySuggested,
                            quantityTotal: a.quantityTotal,
                            quantitySuggested: a.quantitySuggested,
                            quantityUsed: a.quantityOld
                        };
                    }
                    else {
                        this.articleAdjustments[a.id].quantityAdjusted += a.quantityTotal - a.quantitySuggested;
                        this.articleAdjustments[a.id].quantityTotal += a.quantityTotal;
                        this.articleAdjustments[a.id].quantitySuggested += a.quantitySuggested;
                        this.articleAdjustments[a.id].quantityUsed += a.quantityOld;
                    }
                    this.accumulatedArticles.push(a);
                }
            }
        }
    };
    return MeasureComponent;
}());
export { MeasureComponent };
