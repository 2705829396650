import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerWebService {

  constructor() { }

  info(message: string ) {
  }
  debug(message: string) {
  }
  error(error: any) {
  }
}
