import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {Article} from '../../article';
import {ArticleAdjustment} from '../../article-adjustment';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() articleAdjustments: {[key: string]: ArticleAdjustment} = {};
  @Input() articles: Array<Article>;
  @Input() editable = true;
  @Input() viewModeOnly = true;
  @Output() articlesChangedEmitter: EventEmitter<Article> = new EventEmitter<Article>();
  @Input() smallerCell: {[key: string]: boolean} = {};
  formControlMap: {[key: string]: {totalControl: FormControl, usedControl: FormControl}} = {};
  displayedColumns: Array<string> = ['id', 'length', 'quantitySuggested', 'quantityUsed', 'quantityTotal'];
  dataSource: MatTableDataSource<Article> =  new MatTableDataSource<Article>();
  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<Article>(this.articles);
    this.changeDetector.detectChanges();
  }
  ngOnChanges(event) {
    console.log('onchange articles component,viewmode', this.viewModeOnly);
    this.dataSource = new MatTableDataSource<Article>(this.articles);
    this.initTempArticles();
    for (const art of this.dataSource.data) {
      if(this.viewModeOnly == false) {
        this.formControlMap[art.id].totalControl.enable();
        this.formControlMap[art.id].usedControl.enable();
      };
     }
  }

  ngAfterViewInit() {
    this.initTempArticles();
  }
  adjustArticle(event, article: Article) {
    this.updateAdjustedQuantity(article);
    this.articlesChangedEmitter.emit(article);
  }
  private updateAdjustedQuantity(article: Article) {
    for (const dataSourceArticle of this.dataSource.data) {
      if (dataSourceArticle.id === article.id) {
        this.articleAdjustments[article.id].quantityAdjusted =
        this.formControlMap[article.id].totalControl.value - article.quantitySuggested;
        this.articleAdjustments[article.id].quantityTotal = this.formControlMap[article.id].totalControl.value;
        this.articleAdjustments[article.id].quantityUsed = this.formControlMap[article.id].usedControl.value;
      }
    }
  }
  private initTempArticles() {
    // XXX This calculation and visual representation will only work on allotments saved used with the new application, not getac
    // The reason is because we are only ysing the adjustments to display all articles
    for (const art of this.dataSource.data) {
      if (!this.articleAdjustments.hasOwnProperty(art.id)) {
        this.articleAdjustments[art.id] = {
          quantityAdjusted: 0,
          quantityUsed: 0,
          quantityTotal: art.quantityTotal,
          quantitySuggested: art.quantitySuggested,
        };
      } else {
        this.articleAdjustments[art.id].quantityTotal = art.quantityTotal;
        this.articleAdjustments[art.id].quantitySuggested = art.quantitySuggested;
      }
      const totalArticles = (this.articleAdjustments[art.id] ? this.articleAdjustments[art.id].quantityAdjusted : 0) +
        (this.articleAdjustments[art.id] ? this.articleAdjustments[art.id].quantitySuggested : 0);
      const usedArticles = (this.articleAdjustments[art.id] ? this.articleAdjustments[art.id].quantityUsed : 0);
      if (!this.formControlMap.hasOwnProperty(art.id)) {
        this.formControlMap[art.id] = {
          totalControl: new FormControl({
            value: totalArticles,
            disabled: this.viewModeOnly}, []),
          usedControl: new FormControl({
            value: usedArticles,
            disabled: this.viewModeOnly
          }, []),
        };
      } else {
        this.formControlMap[art.id].totalControl.setValue(totalArticles);
        this.formControlMap[art.id].usedControl.setValue(usedArticles);
      }
    }
  }
}
