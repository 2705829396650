<section class="railing-section">
  <mat-form-field *ngIf="railingCategories">
    <mat-select placeholder="Kategori" [formControl]="catFormControl" panelClass="article-picker">
      <mat-option *ngFor="let cat of railingCategories" [value]="cat">
        {{ cat }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="railingTypes">
    <mat-select [formControl]="typeFormControl"
                placeholder="Rekkverkstype"
                panelClass="article-picker"
                (selectionChange)="onRailingTypeChanged($event)">
      <mat-option style="width: 1000px; max-width: none;" *ngFor="let type of railingTypes[catFormControl.value]" [value]="type">
        {{ type.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</section>
<!-- <section>
  
</section>
 -->