import { ValidatorFn, ValidationErrors, FormGroup } from '@angular/forms';

/**
 * Put this function on the formGroup that needs to require a field based on other fields.
 * @param requiredControlName the name of the control in the group to apply validation on
 * @param controllerNames The names of controlles that should be checked for validationg required controller
 */
export function RequiredIfOtherFieldsPresentDirective(requiredControlName: string, controllerNames: Array<string>): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const foundRequiredFields: string[] = [];
    controllerNames = controllerNames || [];
    requiredControlName = requiredControlName || '';
    controllerNames.forEach(ctrl => {
      if (formGroup.get(ctrl) && formGroup.get(ctrl).value) {
        foundRequiredFields.push(ctrl);
      }
    });
    let currentErrors = formGroup.get(requiredControlName).errors;
    if (foundRequiredFields.length > 0 && !formGroup.get(requiredControlName).value) {
      if (!currentErrors) {
        currentErrors = {};
      }
      currentErrors.otherFieldPresent = foundRequiredFields.toString() + ' finnes. Derfor må dette feltet fylles ut også'
      formGroup.get(requiredControlName).setErrors(
        currentErrors
      );
    } else {
      if (currentErrors && currentErrors.otherFieldPresent) {
        delete currentErrors.otherFieldPresent;
      }
      if (currentErrors && Object.keys(currentErrors) && Object.keys(currentErrors).length === 0) {
        currentErrors = null;
      }
      formGroup.get(requiredControlName).setErrors(
        currentErrors
      );
    }
    return null;
  };
}
