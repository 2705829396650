<form class="dialog" [formGroup]="dialogFormGroup">
  <h2>Verkstedsoppgave</h2>
  <button class="btn-close" color="accent" type="button" mat-icon-button (click)="closeDialog()">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
  <section class="select-article-section">
    <div *ngIf="data?.task?.status===9">
      <h5>Utført av {{data?.task?.signedBy}}, {{data?.task?.dateEnd|date: 'dd.MM.yyyy'}}</h5>
    </div>
    <mat-form-field>
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5" formControlName="body" name="body" reqiured="true"
        placeholder="Oppgavebeskrivelse"></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Prosjekt" formControlName="projNum" name="projNum" reqiured="true">
        <mat-option *ngFor="let proj of projects" [value]="proj.projNum">
          {{proj.projNum}} {{proj.projName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Frist uke" formControlName="weekSelector" name="weekSelector" reqiured="true">
        <mat-option *ngFor="let week of weeks" [value]="week.value">
          {{week.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="visKameraBtn" mat-raised-button color="primary" (click)="openCamera()" [disabled] = "takepicture == true">Vis kamera</button>
    <!-- <button class="takePicBtn" mat-raised-button color="primary" (click)="openCamera()" [disabled] = "takepicture == false"> Legg til bilde</button> -->
    <!-- <button class="savePicBtn" mat-raised-button color="primary" (click)="getCapturedImage()" [disabled] = "takepicture == false" >Lager Bilde</button> -->
    <app-camera *ngIf="takepicture" class="webcam" (onImageSave)="getImage($event)"></app-camera>
    <div [hidden]="takepicture" id="snapshot" width="640" height="360"></div>
  </section>
  <button type="submit" class="btn-save success" color="primary" mat-raised-button [disabled]="!dialogFormGroup.valid" (click)="confirm()">
    Lagre
  </button>

</form>