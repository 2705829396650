import { Allotment } from './allotment';
import { AllotmentHeader } from './allotment-header';

export class Order {
  unid: string;
  // AGWTF-RSEH
  // orderId: string; @deprecated
  orderNum: string; // new otderId
  // 72709
  // orderNo: string; // deprecated
  snum: string; // new orderNo
  // Flatmark
  /**
   * @deprecated
   */
  location: string; // derecated
  subject: string; // new location?
  arbIdUrl?: string;
  // EV136
  roadNo: string;
  // Z/4m
  rtType: string;
  // Vegrekkverk
  rtCategory: string;
  // 5 Eksist
  rtStartEndArticle: string;
  // 8 4m ende
  rtStopEndArticle: string;
  // @Deprecated
  private allotments: Array<Allotment>;
  // Liste over parsellhoder
  allotmentHeaders: Array<AllotmentHeader>;
  custContact: string;
  teamComment: Array<Comment>;
  infoForTeam: string;
  description: string;
  custName: string;
  status: string;
  author: string;
  created: string;
  cableDetection: any;
  arbEks: string;
  arbId: string;
  attachments: Array<any>;
  images: Array<any>;
  woComment: string;
  compId: string;
  activationVTS: string;
}
