import {ChangeDetectorRef, EventEmitter, Component, OnInit, Input, Output, OnChanges } from '@angular/core';
import { SjaDataSource } from '../../../sja/sja-data-source';
import { BehaviorSubject } from 'rxjs';
import { MediaService } from '../../../shared/media.service';


@Component({
  selector: 'app-sja-archive',
  templateUrl: './sja-archive.component.html',
  styleUrls: ['./sja-archive.component.scss']
})
export class SjaArchiveComponent implements OnInit, OnChanges {
  @Input() sjaList: Array<any>;
  @Output() sjaSelectedEmitter = new EventEmitter();

  subject: BehaviorSubject<any>;
  dataSource: SjaDataSource;
  columnsToDisplay = ['sjaId', 'sjaStatus', 'sjaDate'];
  isMobileView: boolean;

  constructor(private mediaService: MediaService) { 
    this.mediaService.getMedia().subscribe(media => {
      this.isMobileView = media.isMobileView;
      console.log("isMobile", this.isMobileView)
    })
  }

  ngOnInit() {

  }

   ngOnChanges(event) {
    this.subject = new BehaviorSubject(this.sjaList);
    this.dataSource = new SjaDataSource(this.subject.asObservable());
  }

  refresh(sja) {
    if (this.sjaList) {
      for (let i = 0; i < this.sjaList.length; i++) {
        if (this.sjaList[i].created === sja.created) {
          this.sjaList[i] = sja;
        }
      }
    }
    this.subject.next(this.sjaList);
  }

  onSjaClick(row) {
    this.sjaSelectedEmitter.emit(row);
  }
}
