import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-day-input',
  templateUrl: './day-input.component.html',
  styleUrls: ['./day-input.component.scss']
})
export class DayInputComponent implements OnInit, OnDestroy {
  @Input() selectedDate: moment.Moment;
  @Output() dateChange: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();
  constructor() {
    if (!this.selectedDate) {
      this.selectedDate = moment();
    }
  }

  getNextDay() {
    this.selectedDate = moment(this.selectedDate).add(1, 'day');
    this.dateChange.next(this.selectedDate);
  }
  getPrevDay() {
    this.selectedDate = moment(this.selectedDate).add(-1, 'day');
    this.dateChange.next(this.selectedDate);
  }
  ngOnInit() {
  }

  ngOnDestroy() {
  }
  onContainerClick(event: MouseEvent) {
  }

  get selectedDay() {
    return moment(this.selectedDate);
  }
}
