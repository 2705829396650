import * as i0 from "@angular/core";
var LoggerWebService = /** @class */ (function () {
    function LoggerWebService() {
    }
    LoggerWebService.prototype.info = function (message) {
    };
    LoggerWebService.prototype.debug = function (message) {
    };
    LoggerWebService.prototype.error = function (error) {
    };
    LoggerWebService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggerWebService_Factory() { return new LoggerWebService(); }, token: LoggerWebService, providedIn: "root" });
    return LoggerWebService;
}());
export { LoggerWebService };
