<div class="dialog">
    <button class="btn-close" type="button" color="accent" mat-icon-button (click)="closeDialog()">
        <mat-icon aria-label="Close">close</mat-icon>
    </button>
    <app-camera class="webcam" (onImageSave)="getImage($event,order)"></app-camera>
   <!--  <div class="trigger-buttons"> -->
      <!--   <button class="btn-save success" 
            mat-raised-button>Ta
            bilde</button> -->

        <!-- <button mat-fab aria-label="Ta bilde" color="primary" (click)="triggerSnapshot();" *ngIf="showLive">
            <mat-icon>photo_camera</mat-icon>
        </button>
        <button  mat-fab aria-label="Ta nytt bilde bilde" color="primary" (click)="newImg()" *ngIf="!showLive"><mat-icon>add_a_photo</mat-icon></button>
        <button mat-fab aria-label="Lagre" color="primary" (click)="saveImg()" *ngIf="!showLive"><mat-icon>save</mat-icon></button> -->
    <!-- </div> -->

</div>