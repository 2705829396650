import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  apiurl: any;

  constructor(private http: HttpClient) {
    this.apiurl = AppConfig.apiUrl;
  }

  getOpenStorages(): Observable<Array<any>> {
    const url = `${this.apiurl}/storages/open`;
    return new Observable<Array<any>>(observer => {
      this.http.get<any>(url).pipe(map(res => {
        observer.next(res.data);
      })).subscribe();
    });
  }

  getArkivStorages(): Observable<Array<any>> {
    const url = `${this.apiurl}/storages/archived`;
    return new Observable<Array<any>>(observer => {
      this.http.get<any>(url).pipe(map(res => {
        observer.next(res.data);
      })).subscribe();
    });
  }

  getStorage(storageId: string) {
    const url = `${this.apiurl}/storages/${storageId}`;
    return this.http.get<any>(url).pipe(map(storage => {
      return storage.data;
    }))
  }

  getCounties() {
    const url = `${this.apiurl}/storages/counties`;
    return this.http.get<any>(url).pipe(map(data => {
      return data.data;
    }))
  }

  getArticleList() {
    const url = `${this.apiurl}/articles/maincategories/materiale`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }

  UpdateStorage(storageId: string, article: any): Observable<any> {
    const url = `${this.apiurl}/storages/${storageId}/article`;
    return this.http.post<any>(url, article)
      .pipe(map(res => {
        return res.data.articles;
      }))
  }
  getOrderList(teamId: any) {
    const url = `${this.apiurl}/orders/open/${teamId}`
    return this.http.get<any>(url).pipe(map(data => {
      return data.data;
    }))
  }
  addStorage(storage: any): Observable<any> {
    const url = `${this.apiurl}/storages/`;
    return this.http.post<any>(url, storage)
      .pipe(map(res => {
        return res.data;
      }))
  }
  saveStorageAsArkiv(storage: any): Observable<any> {
    const url = `${this.apiurl}/storages/${storage.unid}/archive`;
    return this.http.post<any>(url,storage).pipe(map(res => {
      return res.data;
    }))
  }
}
