<form class="dialog" [formGroup]="dialogFormGroup" (ngSubmit)="confirm($event)">
  <h2>Kommentar</h2>
  <button class="btn-close" type="button" color="accent" mat-icon-button (click)="closeDialog()">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
  <section class="select-article-section">
    <mat-form-field>
      <textarea matInput 
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5" 
        formControlName="body" 
        name="body" 
        reqiured="true" 
        placeholder="Kommentar"></textarea>
    </mat-form-field>
  </section>
  <button type="submit" class="btn-save success" color="primary" mat-raised-button [disabled]="!dialogFormGroup.valid">
    Lagre
  </button>
</form>