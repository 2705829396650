import { Component, OnInit, Inject, ViewChildren, QueryList, ChangeDetectorRef, ViewChild, HostBinding } from '@angular/core';
import { AbstractDialogComponent } from '../../abstract-dialog/abstract-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatTable, MatSelect } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../data.service';
import { groupBy, toArray, mergeMap, takeUntil, take, map } from 'rxjs/operators';
import { from } from 'rxjs/internal/observable/from';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MediaService } from '../../../shared/media.service';
import { Article } from '../../../article';
import { timingSafeEqual } from 'crypto';
import { ReplaySubject, Subject } from 'rxjs';
import { SettingsService } from '../../../settings.service';

@Component({
  selector: 'app-order-approval-articles',
  templateUrl: './order-approval-articles.component.html',
  styleUrls: ['./order-approval-articles.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrderApprovalArticlesComponent extends AbstractDialogComponent implements OnInit {
  @HostBinding('class.order-approval-articles-component') hostClass = true;
  dialogFormGroup: FormGroup;
  articleColumns = ['Artikkel', 'Tilbud', 'Getac', 'Godkjent', 'Habrukte', 'Avvik'];
  parsellColumns = ['Parsell', 'Tilbud', 'Getac', 'Godkjent', 'Habrukte', 'Avvik'];
  articles: any;
  orderUnid: string;
  groupArticles = [];
  dataArticle = [];
  dataSource: any;
  expandedElement: Array<any> | null;
  isMobileView: boolean;
  buildLength: number;
  parcels: any;
  readonly: boolean;
  orderSnum: string;
  workArticles: Array<Article>;
  filteredArticles: ReplaySubject<Article[]> = new ReplaySubject<Article[]>(0);
  protected _onDestroy = new Subject<void>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  orderStatus: any;
  isNightMode: boolean = false;

  confirm(event: any): void {
    throw new Error("Method not implemented.");
  }

  constructor(protected dialogRef: MatDialogRef<AbstractDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any, private dataService: DataService,
    private cd: ChangeDetectorRef, private mediaService: MediaService,
    private settingsService: SettingsService) {
    super(dialogRef);
    this.dialogFormGroup = new FormGroup({
      // parsell: new FormControl(),
      article: new FormControl('', Validators.required),
      articleFilterCtrl: new FormControl(''),
      count: new FormControl(0, [Validators.required, Validators.min(1)])
    });
    this.mediaService.getMedia().subscribe(media => {
      this.isMobileView = media.isMobileView;
    });
    this.orderUnid = data.OrderUnid;
    this.orderSnum = data.OrderNumber;
    this.orderStatus = data.OrderStatus;
    this.readonly = data.readonly;
    this.settingsService.getNightMode().subscribe(nightMode => {
      this.isNightMode = nightMode;
    });
  }

  ngOnInit() {
    this.getOrderForApproval();
    /* this.dataService.getParcels(this.orderUnid).subscribe(parcels => {
      this.parcels = parcels.sort((a, b) => {
        try {
          return (Number(a.title) > Number(b.title)) ? 1 : -1;
        } catch (err) {
          return 0;
        }
      });
    }); */
    this.getWorkArticleList();
    // listen for search field value changes
    this.dialogFormGroup.get('articleFilterCtrl').valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterArticles();
      });
  }
  getOrderForApproval() {
    this.dataService.getOrderForApproval(this.orderUnid).subscribe(res => {
      this.articles = res.allArticles;
      this.buildLength = res.prodLength;
      this.createArticleGroup();
      this.mapData();
    });
  }
  ngAfterViewInit() {
    this.setInitialValue();
  }
  /**
    * Sets the initial value after the filteredArticles are loaded initially
    */
  protected setInitialValue() {
    this.filteredArticles
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredArticles are loaded initially
        // and after the mat-option elements are available
        // this.singleSelect.compareWith = (a: any, b: any) => a && b && a.name === b.name;
      });
  }
  filterArticles() {
    if (!this.workArticles) return
    let search = this.dialogFormGroup.get('articleFilterCtrl').value;
    if (!search) {
      this.filteredArticles.next(this.workArticles);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the ArticleList
    this.filteredArticles.next(
      this.workArticles.filter(article => article['name'].toLowerCase().indexOf(search) > -1)
    );
  }

  getWorkArticleList() {
    this.dataService.getWorkArticles()
      .subscribe(res => {
        this.workArticles = res;
        // load the initial article list
        this.filteredArticles.next(this.workArticles);
        // Set initial selection 
        //this.dialogFormGroup.get('articleFilterCtrl').setValue(this.workArticles[0]['id']  +"-"+ this.workArticles[0]['name']);
      })
  }

  createArticleGroup() {
    from(this.articles).pipe(
      groupBy(a => a['artNum']),
      mergeMap((group$) => group$.pipe(toArray()))
    ).subscribe(val => {
      this.groupArticles.push(val)
    });
  }

  saveWorkArticle() {
    if (this.dialogFormGroup.valid) {
      const ol = {
        artNum: this.dialogFormGroup.get('article').value,
        count: this.dialogFormGroup.get('count').value,
        parentId: this.orderUnid
      }
      this.dataService.saveOrderLine(ol).subscribe();
      this.articles = [];
      this.groupArticles = [];
      this.dataArticle = [];
      this.getOrderForApproval();
      this.dialogFormGroup.reset();
    }
    /* const parsell = this.dialogFormGroup.get('parsell').value;
    const articleId = this.dialogFormGroup.get('article').value;
    const count = this.dialogFormGroup.get('count').value;
    const articleName = this.getArticle(articleId);
    console.log(parsell,articleId,articleName[0],count,this.orderUnid); */
  }

  getArticle(articleId: string) {
    return this.workArticles.filter(({ id }) => id === articleId).map(({ name }) => { return name });
  }

  mapData() {
    this.groupArticles.forEach(article => {
      let parsells = [];
      article.forEach(details => {
        parsells.push({
          Parsell: details.parsellNum == 0.0 ? 'M' : 'Parsell ' + details.parsellNum,
          Getac: details.antall,
          Tilbud: details.antallTilbud,
          Godkjent: details.justert,
          Avvik: details.antallAvvik,
          Habrukte: details.heravBrukte
        })
      })
      const tilbud = parsells.map(el => el.Tilbud).reduce((a, b) => a + b, 0);
      const getac = parsells.map(el => el.Getac).reduce((a, b) => a + b, 0);
      const godkjent = parsells.map(el => el.Godkjent).reduce((a, b) => a + b, 0);
      const haBrukt = parsells.map(el => el.Habrukte).reduce((a, b) => a + b, 0);
      const avvik = parsells.map(el => el.Avvik).reduce((a, b) => a + b, 0);

      this.dataArticle.push({
        Artikkel: article[0].artNum + ' ' + article[0].artInfo[0],
        Tilbud: tilbud, Getac: getac, Godkjent: godkjent, Habrukte: haBrukt, Avvik: avvik,
        parsell: new MatTableDataSource(parsells)
      });
    })
    this.dataSource = new MatTableDataSource(this.dataArticle);
  }

  toggleRow(element: any) {
    element.parsell && (element.parsell as MatTableDataSource<any>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    this.cd.detectChanges();
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
