<div class="riskheader" [class.mobile-view]="isMobile">
  <h3>{{title}}</h3>
  <div id="sjaButton">
    <a mat-raised-button color="primary" (click)="newRiskMoment()" [disabled]="archived">Nytt risikomoment</a>
  </div>
</div>
<div *ngIf="showInlineForm">test</div>
<div class="riskform" *ngIf="inlineForm && showInlineForm">
  <form [formGroup]="inlineFormGroup" (ngSubmit)="addRiskMoment($event)">
    <h2>{{isEdit ? 'Endre': 'Nytt' }} risikomoment</h2>
    <section class="select-article-section">
      <mat-form-field>
        <input matInput type="text" formControlName="operation" name="operation" reqiured="true" placeholder="Operasjon" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" formControlName="description" name="description" reqiured="true" placeholder="Faremoment" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" formControlName="measures" name="measures" reqiured="true" placeholder="Tiltak" />
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="Ansvarlig" formControlName="responsible" name="responsible" reqiured="true">
          <mat-option *ngFor="let emp of myTeam" [value]="emp">
            {{emp.name}} 
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>
    <button type="submit" class="btn-save success" color="primary" mat-raised-button [disabled]="inlineFormGroup.invalid">
      {{isEdit ? 'Endre': 'Legg til risikomoment' }}
    </button>
  </form>
</div>
<mat-table [dataSource]="dataSource" [class.isMobile]="isMobileView" *ngIf="!showInlineForm">
  <ng-container matColumnDef="operation">
    <mat-header-cell *matHeaderCellDef> Operasjon </mat-header-cell>
    <mat-cell *matCellDef="let risk; let i = index" (click)="editRow(i)">
      <span class="mobile-label">Operasjon:</span> {{risk?.operation}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef> Faremoment </mat-header-cell>
    <mat-cell *matCellDef="let risk">
      <span class="mobile-label">Faremoment:</span> {{risk?.description}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="measures">
    <mat-header-cell *matHeaderCellDef> Tiltak </mat-header-cell>
    <mat-cell *matCellDef="let risk">
      <span class="mobile-label">Tiltak:</span> {{risk?.measures}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="responsible">
    <mat-header-cell *matHeaderCellDef> Ansvarlig </mat-header-cell>
    <mat-cell *matCellDef="let risk">
      <span class="mobile-label">Ansvarlig:</span> {{risk?.responsible.name}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef> Handling </mat-header-cell>
    <mat-cell *matCellDef="let i = index">
      <button mat-icon-button (click)="deleteRow(i)" *ngIf="!isMobile">
        <mat-icon aria-label="Slett risikomoment">clear</mat-icon>
      </button>
      
      <button mat-raised-button color="primary" (click)="editRow(i)" *ngIf="isMobile">
        <mat-icon>create</mat-icon>
      </button>
      <div></div>
      <button mat-raised-button color="primary" (click)="deleteRow(i)" *ngIf="isMobile">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
  <mat-row *matRowDef="let myRowData; columns: columnsToDisplay" class="element-row"></mat-row>
</mat-table>