import { Component, OnInit, HostBinding, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment-timezone';
import { IsiWeekDay } from '../../../isi-week-day';
import { IsiWeekHour } from '../../../isi-week-hour';
import { TimeSheetService } from '../../../time-sheet.service';
import { MatDialog, MatSnackBar, MatDatepickerInputEvent } from '@angular/material';
import { UserService } from '../../../user.service';
import { OrderLineDialogComponent, OrderLineDialogData } from '../order-line-dialog/order-line-dialog.component';
import { take, switchMap, catchError, finalize} from 'rxjs/operators';
import { Order } from '../../../order';
import { Project } from '../../../project';
import { IsiWeekPerson } from '../../../isi-week-person';
import { OrderLine } from '../../../order-line';
import { ArtNumber } from '../../../art-number.enum';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { highlightFadeTrigger } from '../../../triggers/highlight-fade-trigger';
import { TimeSheetRegistrationDialogComponent } from '../time-sheet-registartion-dialog/time-sheet-registration-dialog.component';
import { WeekDayDialogComponent } from '../week-day-dialog/week-day-dialog.component';
import { environment } from '../../../../environments';
import { Subject, Subscription } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';
import { MediaService } from '../../../shared/media.service';
import { HeaderService } from '../../../components/header/header.service';
import { WeekTeamDietComponent } from '../week-team-diet/week-team-diet.component';
@Component({
  selector: 'app-time-sheet',
  templateUrl: './time-sheet.component.html',
  styleUrls: ['./time-sheet.component.scss'],
  animations: [
    highlightFadeTrigger('fadeout')
  ]
})
export class TimeSheetComponent implements OnInit, OnDestroy {
  @HostBinding('class.app-time-sheet-component') hostClas = true;
  @HostBinding('class.mobile-view') isMobileView = false;
  @ViewChild (WeekTeamDietComponent,{static: false}) childDiett: WeekTeamDietComponent;
  readonly ARTNUMBER_ORDER = ArtNumber.ORDER;
  readonly ARTNUMBER_PROJECT = ArtNumber.PROJECT;
  fadeoutStates = {};
  formGroup: FormGroup;
  footerFormGroup: FormGroup;
  loadingWeek = false;
  showFullWeek = false; // toggle friday, saturday and sunday to be displayed as well.
  weekDays: Array<IsiWeekDay> = [null, null, null, null, null, null, null]; // "Dagføring"
  // This object is only a client specific object, used to display each project-/order-line in the time sheet.
  // Generated based on the hourList from the server.
  orderLines: Array<OrderLine> = null;
  weekDaysSums: Array<IsiWeekHour> = []; // Total sum of each order/project for each day for all team members. (the bottom line sum)
  // Holds the sum of each day, where the person's initials is the key
  teamMembersSums: {[key: string]: {hourList: Array<IsiWeekHour>, intern: boolean}} = {};
  selectedDate: moment.Moment; // the selected date from the week picker. Defaults to todays dota, adds or subtracts a week when changed.
  beginWeek: moment.Moment; // used to display the corret dates in the top of the time sheet (mon-sun)
  weekHours: {[key: string]: Array<IsiWeekHour>} = {}; // {'orderId': [null,null,aWeekHour,null,null,null,null]};
  weekSummaryIsOpen: boolean;
  /**
   * The values are the keys in which we need to access data from an IsiWeekDay.
   * Label is the title of the summary
   */
  summaryTitles: Array<{values: Array<string>, label: string, filter?: string}> = [
    { label: 'starttid/sluttid', values: ['workStart', 'workEnd'], filter: 'date'},
    { label: 'kjøring til/fra Gjerde', values: ['hourFrom', 'hourTo']},
    { label: 'sum pause/herav betalt', values: ['hourPause', 'hourPauseWork']},
    { label: 'kommentar dagføring', values: ['comment'], filter: 'comment'}
  ];
  private weekSub$: Subscription;
  public currentWeekNumber: number;
  public currentYear: number;
  constructor(private timeSheetService: TimeSheetService, private dialog: MatDialog, public user: UserService,
    private snackbar: MatSnackBar, private mediaService: MediaService, private headerService: HeaderService) {
    this.formGroup = new FormGroup({
      'showFullWeek': new FormControl(false, [])
    });
    this.footerFormGroup = new FormGroup({});
    this.selectedDate = moment();
    this.selectedDate.locale('').weekday();
    this.beginWeek = this.selectedDate.clone().startOf('isoWeek').tz('Europe/Oslo');
    this.beginWeek.set('hours', 12);
    this.currentWeekNumber = this.beginWeek.isoWeek();
    this.currentYear = this.beginWeek.year();
    this.mediaService.getMedia().subscribe( media => {
      this.isMobileView = media.isMobileView;
    })
  }
  ngOnInit() {
    this.getWeek(this.selectedDate);
    this.headerService.addNavItem({
      route: `/timesheet`,
      label: `Timeføring`,
      queryParams: ''
    });
  }
  ngOnDestroy() {
    if (this.weekSub$) {
      this.weekSub$.unsubscribe();
    }
    this.headerService.removeNavItem({
      route: `/timesheet`,
      label: `Timeføring`,
      queryParams: ''
    });
  }
  /**
   * Create or edit a specific week hour
   * @param weekHour
   * @param orderLine
   * @param weekdayIndex a number 0-6, representing the day of the week (mon -> sun)
   */
  createOrEditWeekHour(weekHour: IsiWeekHour, orderLine: OrderLine, weekdayIndex: number) {
    const selectedDay = this.selectedDate.clone().startOf('isoWeek').tz('Europe/Oslo');
    selectedDay.set('hours', 12);
    selectedDay.add(weekdayIndex, 'day');
    if (weekHour) {
    } else {
      weekHour = new IsiWeekHour();
    }
    weekHour.haUnid = this.weekDays[weekdayIndex].unid;
    weekHour.haDate = weekHour.haDate || selectedDay.toDate();
    const dialogWidth = this.isMobileView ? '100%' : '80%';
    const dialog = this.dialog.open(TimeSheetRegistrationDialogComponent, {
      width: dialogWidth,
      panelClass: 'time-registration-dialog',
      data: {weekHour: weekHour, orderLine: orderLine}
    });
    dialog.afterClosed().subscribe( (res: {weekHour: IsiWeekHour, orderLine, OrderLine}) => {
      if (res && res.weekHour) {
        if (this.weekHours[res.weekHour.lineKey]) {
          const existingWeekHour = this.weekHours[res.weekHour.lineKey][weekdayIndex];
          if (existingWeekHour && existingWeekHour.unid === res.weekHour.unid) {
            // Edit existing weekHour
            // TODO edit the exisiting weekHour instead of replacing with new?
            this.weekHours[res.weekHour.lineKey][weekdayIndex] = res.weekHour;
          } else {
            // new week hour
            this.weekHours[res.weekHour.lineKey][weekdayIndex] = res.weekHour;
          }
        }
        setTimeout(() => {
          this.generateOrderLinesAndInitTeamMembersSums(this.weekHours);
          this.generateOrderSums(this.orderLines, this.weekHours);
          this.generateWeekDaySums(this.weekHours);
          this.generateTeamMembersSums(this.orderLines, this.weekHours);
        })
      }
    });
  }
  /**
   *
   * @param index a number between 0 and 6, representing mon-sun
   * @param weekDay
   */
  createOrEditWeekDay(index: number, weekDay: IsiWeekDay) {
    const selectedDay = this.selectedDate.clone().startOf('isoWeek').tz('Europe/Oslo');
    selectedDay.set('hours', 12);
    selectedDay.add(index, 'day');
    const weekDayToEdit: IsiWeekDay = weekDay || new IsiWeekDay();
    weekDayToEdit.week = selectedDay.isoWeek();
    const dialogWidth = this.isMobileView ? '100%' : '80%';
    const isiWeekDialog = this.dialog.open(WeekDayDialogComponent, {
      width: dialogWidth,
      panelClass: 'week-day-dialog',
      data: {
        selectedDay: selectedDay,
        selectedWeekDay: weekDayToEdit
      }
    });
    isiWeekDialog.afterClosed().subscribe((res: IsiWeekDay) => {
      if (res) {
        this.weekDays[index] = res;
      }
    });
  }
  toggleShowFullWeek(event) {
    this.showFullWeek = this.formGroup.get('showFullWeek').value;
    this.beginWeek = this.selectedDate.clone().startOf('isoWeek').tz('Europe/Oslo'); // reset the counter as it is modified in the html
    this.beginWeek.set('hours', 12);
  }
  onDateChanged(event: moment.Moment) {
    console.log("got ev", event);
    this.selectedDate = event;
    this.beginWeek = this.selectedDate.clone().startOf('isoWeek').tz('Europe/Oslo');
    this.beginWeek.set('hours', 12);
    if (this.beginWeek.isoWeek() !== this.currentWeekNumber || this.beginWeek.year() !== this.currentYear) {
      this.weekHours = {};
      this.weekDays = [];
      this.orderLines = [];
      this.generateOrderLinesAndInitTeamMembersSums(this.weekHours);
      this.generateOrderSums(this.orderLines, this.weekHours);
      this.generateWeekDaySums(this.weekHours);
      this.generateTeamMembersSums(this.orderLines, this.weekHours);
      this.getWeek(this.selectedDate);
    }
    this.currentWeekNumber = this.beginWeek.isoWeek();
    this.currentYear = this.beginWeek.year();
    this.childDiett.weekData(this.currentWeekNumber, this.currentYear);
  }
  datePickerChanged(type: string, event: MatDatepickerInputEvent<Date>) {
    if (event) {
      this.onDateChanged(moment(event.value));
    }
  }
  /**
   * Thursday determines the week number.
   * @param artNumber
   * @param orderLine the current orderLine object (slightly different from Order).
   */
  createOrEditOrderLine(artNumber: ArtNumber, orderLine?: any) {
    const now = moment();
    this.loadingWeek = true;
    const thursdayThisWeek = moment(now).startOf('isoWeek').add(3, 'day');
    this.timeSheetService.getWeek(this.user.teamId, thursdayThisWeek.year(), thursdayThisWeek.isoWeek())
      .pipe(take(1)).subscribe((todaysWeek: { dayList: Array<IsiWeekDay>, hourList: { [key: string]: Array<IsiWeekHour> }}) => {
      this.loadingWeek = false;
      const orderLlineDialogData = new OrderLineDialogData();
      const tmpOrder = new Order();
      const tmpProject = new Project();
      if (orderLine) {
        tmpOrder.orderNum = orderLine.orderNum;
        tmpOrder.snum = orderLine.orderSNum;
        tmpOrder.subject = orderLine.orderSubject;
        tmpOrder.unid = orderLine.unid;
        tmpProject.projName = orderLine.projName;
        tmpProject.projNum = orderLine.projNum;
        orderLlineDialogData.order = tmpOrder;
        orderLlineDialogData.project = tmpProject;
        orderLlineDialogData.members = orderLine.teamMembers;
        orderLlineDialogData.artNumber = {label: orderLine.artNumber, value: orderLine.artNumber};
        orderLlineDialogData.isNew = false;
      } else {
        orderLlineDialogData.isNew = true;
        orderLlineDialogData.artNumber = {label: artNumber, value: artNumber};
      }
      orderLlineDialogData.selectedWeekDay = todaysWeek.dayList[this.getTodaysDay()];
      const dialogWidth = this.isMobileView ? '100%' : '80%';
      const orderLinedialogRef = this.dialog.open(OrderLineDialogComponent, {
        width: dialogWidth,
        panelClass: 'order-line-dialog',
        data: orderLlineDialogData
      });
      orderLinedialogRef.afterClosed().subscribe((result: OrderLineDialogData) => {
        if (result) {
          if (result.isNew) {
            const orderLineKey = this.generateNewOrderLine(result);
            const existingWeekHour = this.getExistingWeekHour(orderLineKey, this.getTodaysDay(), todaysWeek.hourList);
            if (result.speedHours && !existingWeekHour) { // can only save speedhours on todays date, and only if not already exists
              this.saveSpeedHour(result, orderLineKey);
            } else if (result.speedHours && existingWeekHour) {
              this.snackbar.open('Det finnes allerede en timeføring i dag på denne ordrelinjen, se uke: ' + now.isoWeek(),
                'OK', {duration: 10000});
            }
          } else {
            const oldKey = this.generateOrderLineKey(orderLlineDialogData);
            this.editOrderLine(oldKey, result);
          }
        } else {
          // nothing
        }
      });
    }, catchError( () => {
      this.loadingWeek = false;
      return null;
    }), (() => {
      this.loadingWeek = false;
    }));
  }
  /**
   * Gets a reference to an existing weekHour using orderLineKey and a a day of the week (0-6).
   */
  private getExistingWeekHour(orderLineKey: string, index: number, weekHours: { [key: string]: Array<IsiWeekHour>}): IsiWeekHour {
    let existingWeekHour: IsiWeekHour = null;
    if (weekHours[orderLineKey] && weekHours[orderLineKey][index]) {
      existingWeekHour = weekHours[orderLineKey][index];
    }
    return existingWeekHour;
  }
  private saveSpeedHour(result: OrderLineDialogData, orderLineKey: string) {
    const simpleWeekHour = new IsiWeekHour();
    simpleWeekHour.hoursWork = result.speedHours;
    simpleWeekHour.hoursNight = 0;
    simpleWeekHour.hoursOvertime50 = 0;
    simpleWeekHour.hoursTravel = 0;
    simpleWeekHour.body = result.comment ? result.comment : '';
    if (result && result.selectedWeekDay && result.selectedWeekDay.date) {
      simpleWeekHour.haDate = result.selectedWeekDay.date;
    } else {
      simpleWeekHour.haDate = moment().toDate();
    }
    simpleWeekHour.created = moment().toDate();
    simpleWeekHour.haUnid = result.selectedWeekDay.hourAccountUnid || result.selectedWeekDay.unid;
    simpleWeekHour.intern = null;
    simpleWeekHour.lineKey = orderLineKey;
    simpleWeekHour.teamMembers = result.members;
    simpleWeekHour.orderSubject = result.order ? result.order.subject : '';
    simpleWeekHour.orderSNum = result.order ? result.order.snum : '';
    simpleWeekHour.artNumber = result.order ? ArtNumber.ORDER : ArtNumber.PROJECT;
    simpleWeekHour.orderNum = result.order ? result.order.orderNum : '';
    simpleWeekHour.projName = result.project ? result.project.projName : '';
    simpleWeekHour.projNum = result.project ? result.project.projNum : '';
    this.timeSheetService.saveWeekHour(simpleWeekHour).pipe(take(1)).subscribe(res => {
      simpleWeekHour.unid = res.unid;
      if (this.selectedDate.isoWeek() === moment().isoWeek()) { // update grid if selected week is todays week
        this.weekHours[orderLineKey].splice(this.getTodaysDay(), 1, simpleWeekHour);
        this.generateOrderLinesAndInitTeamMembersSums(this.weekHours);
        this.generateOrderSums(this.orderLines, this.weekHours);
        this.generateWeekDaySums(this.weekHours);
        this.generateTeamMembersSums(this.orderLines, this.weekHours);
        this.snackbar.open('Hurtigregistrering av timer lagret', 'OK', { duration: 4000 });
      } else {
        this.snackbar.open('Timeføringen ble registrert, men ligger på uke: ' + result.selectedWeekDay.week, 'OK', {duration: 4000});
      }
    });
  }
  completeWeek() {
    // TODO complete week
    this.snackbar.open('Ikke implementert enda', '', { duration: 5000 });
  }
  deleteOrder(order: any) {
    // TODO delete order
    this.snackbar.open('Ikke implementert enda', '', { duration: 5000 });
  }
  getKeys(someMap: any) {
    const keys =  Object.keys(someMap);
    return keys;
  }
  getTotalSumMember(initials: string): IsiWeekHour {
    const totalSum = new IsiWeekHour();
    this.teamMembersSums[initials].hourList.forEach((weekHour) => {
      totalSum.hoursWork += weekHour.hoursWork;
      totalSum.hoursTravel += weekHour.hoursTravel;
      totalSum.hoursOvertime50 += weekHour.hoursOvertime50;
      totalSum.hoursNight += weekHour.hoursNight;
    });
    return totalSum;
  }
  /**
   * Generates a new order line, or displays a snackbar message if the same orderline exists.
   * @param dialogData
   */
  private generateNewOrderLine(dialogData: OrderLineDialogData): string {
    const orderLineKey = this.generateOrderLineKey(dialogData);
    let foundKey = false;
    for (const ol of this.orderLines) {
      if (ol.id === orderLineKey) {
        foundKey = true;
      }
    }
    if (!foundKey && dialogData.order) {
      const existingOrderLineIndex = this.getExistingOrderLineIndex(this.orderLines, dialogData.order.snum, 'orderSNum');
      this.orderLines.splice(existingOrderLineIndex, 0, this.newOrderLine(orderLineKey, dialogData.order, dialogData.members));
      this.weekHours[orderLineKey] = [null, null, null, null, null, null, null];
      this.snackbar.open('Ny ordrelinje opprettet 👍', '', { duration: 3000 });
    } else if (!foundKey && dialogData.project) {
      const existingOrderLineIndex = this.getExistingOrderLineIndex(this.orderLines, dialogData.project.projNum, 'projNum');
      this.orderLines.splice(existingOrderLineIndex, 0, this.newOrderLine(orderLineKey, dialogData.project, dialogData.members));
      this.weekHours[orderLineKey] = [null, null, null, null, null, null, null];
      this.snackbar.open('Ny prosjektlinje opprettet 👍', '', { duration: 3000 });
    } else {
      this.snackbar.open('Denne ordrelinjen er allerede opprettet', '', {duration: 3000});
    }
    this.animateOrderLine(orderLineKey);
    return orderLineKey;
  }
  /**
   * @returns {number} 0-6, 0 being monday, 6 being sunday.
   */
  private getTodaysDay() {
    let currDay = new Date().getDay() - 1;
    if (currDay === -1) {
      currDay = 6;
    }
    return currDay;
  }
  private animateOrderLine(orderLineKey: string) {
    this.fadeoutStates[orderLineKey] = true;
    setTimeout(() => {
      this.fadeoutStates[orderLineKey] = false;
      const orderLineDOM = document.getElementById(orderLineKey);
      if (orderLineDOM) {
        orderLineDOM.scrollIntoView({ behavior: "smooth" });
      }
    });
  }
  private getExistingOrderLineIndex(orderLines: Array<OrderLine>, sNumOrProjNum: string, lookupKey: string) {
    let foundIndex = orderLines.findIndex((ol) => {
      if (sNumOrProjNum === ol[lookupKey]) {
        return true;
      }
    });
    if (foundIndex < 0) {
      foundIndex = 0;
    }
    return foundIndex;
  }
  /**
   * Edit the `weekHours` on the same orderLine if a change was made.
   * Regenerates the orderlines, and sums.
   * Will do a batch save operation on all IsiWeekHour objects.
   * @param oldOrderLineKey
   * @param dialogData
   */
  private editOrderLine(oldOrderLineKey: string, dialogData: OrderLineDialogData) {
    const newOrderLineKey = this.generateOrderLineKey(dialogData);
    if (this.weekHours[newOrderLineKey]) {
      this.snackbar.open('Det finnes allerede en ordre med valgte ansatte i timelisten', '', {duration: 3000});
      this.fadeoutStates[newOrderLineKey] = true;
      setTimeout(() => {
        this.fadeoutStates[newOrderLineKey] = false;
      });
      return;
    }
    // Update weekHour.
    for (let i = 0; i < this.orderLines.length; i++) { // replace the old orderLine with the new
      if (this.orderLines[i].id === oldOrderLineKey) {
        const weekHours = this.weekHours[oldOrderLineKey];
        for (const weekHour of weekHours) {
          if (weekHour) {
            // update data in the hourWeek.
            weekHour.teamMembers = [].concat(dialogData.members);
            weekHour.lineKey = newOrderLineKey;
            if (dialogData.order) {
              weekHour.orderSNum = dialogData.order.snum;
              weekHour.orderNum = dialogData.order.orderNum;
              weekHour.orderSubject = dialogData.order.subject;
            } else if (dialogData.project) {
              weekHour.projNum = dialogData.project.projNum;
              weekHour.projName = dialogData.project.projName;
            }
            this.timeSheetService.saveWeekHour(weekHour).pipe(take(1)).subscribe( res => {});
          }
        }
        // replace the old key with the new in the map
        this.weekHours[newOrderLineKey] = [].concat(this.weekHours[oldOrderLineKey]);
        delete this.weekHours[oldOrderLineKey];
        // TODO (save batch operation with updated weekHours)
        break;
      }
    }
    // generate all order lines and sums again.
    this.generateOrderLinesAndInitTeamMembersSums(this.weekHours);
    this.generateOrderSums(this.orderLines, this.weekHours);
    this.generateWeekDaySums(this.weekHours);
    this.generateTeamMembersSums(this.orderLines, this.weekHours);
  }
  /**
   * Creates a new `OrderLine`
   * @param key
   * @param orderOrProject
   * @param members
   */
  private newOrderLine(key, orderOrProject: Order|Project, members: Array<IsiWeekPerson>): OrderLine {
    let project: Project;
    let order: Order;
    let orderLine: OrderLine = null;
    if (orderOrProject && (<Order> orderOrProject).snum) {
      order = <Order> orderOrProject;
    } else if (orderOrProject && (<Project>orderOrProject).projNum) {
      project = <Project> orderOrProject;
    }
    orderLine = {
      id: key,
      projNum: '',
      teamMembers: members,
      sumHoursWork: 0,
      sumHoursOvertime50: 0,
      sumHoursNight: 0,
      sumHoursTravel: 0,
    }
    if (order) {
      // orderLine.intern =  order.intern;
      orderLine.artNumber = ArtNumber.ORDER;
      orderLine.orderSNum = order.snum;
      orderLine.orderNum = order.orderNum;
      orderLine.orderSubject = order.subject;
    } else if (project) {
      orderLine.artNumber = ArtNumber.PROJECT;
      orderLine.projNum = project.projNum;
      orderLine.projName = project.projName;
    }
    return orderLine;
  }
  /**
   * Will create a key based on the dialog data. Will generate existing key from `this.orderLines` if
   * the dialog data has the same loaction, artNumber, snum AND teamMembers.
   * @param dialogData
   */
  private generateOrderLineKey(dialogData: OrderLineDialogData): string {
    let orderLineKey = ''; // building the key based on members and id of order/project.
    if (dialogData.artNumber.value === ArtNumber.ORDER) {
      orderLineKey = dialogData.order.snum + ' ' + dialogData.order.subject + ' ' + ArtNumber.ORDER;
    } else if (dialogData.artNumber.value === ArtNumber.PROJECT) {
      orderLineKey = dialogData.project.projNum + ' ' + dialogData.project.projName + ' ' + ArtNumber.PROJECT;
    }
    const sortedMembers = dialogData.members.sort((a, b) => {
      if (a && a.initials && b && b.initials) {
        return a.initials.toLowerCase().localeCompare(b.initials.toLowerCase());
      }
      return 0;
    });
    // TODO use sortedMembers instead of members, to ensure correct orderLineKey.
    for (let i = 0; i < dialogData.members.length; i++) {
      orderLineKey += ' ' + dialogData.members[i].initials;
      if (i < dialogData.members.length - 1) { orderLineKey += ','; }
    }
    return orderLineKey;
  }

  /**
   * The thursday determines the week number
   */
  private getWeek(selectedDate: moment.Moment) {
    this.loadingWeek = true;
    if (this.weekSub$ && !this.weekSub$.closed) {
      this.weekSub$.unsubscribe();
    }
    const thursdayThisWeek = moment(selectedDate).startOf('isoWeek').add(3, 'day');
    this.weekSub$ = this.timeSheetService.getWeek(
      this.user.teamId, thursdayThisWeek.year(), thursdayThisWeek.isoWeek()).subscribe((res) => {
        this.loadingWeek = false;
        this.weekDays = res.dayList || [null, null, null, null, null, null, null];
        // this.addSingleWeekdayForTesting();
        this.weekHours = res.hourList || {};
        this.generateOrderLinesAndInitTeamMembersSums(this.weekHours);
        this.generateOrderSums(this.orderLines, this.weekHours);
        this.generateWeekDaySums(this.weekHours);
        this.generateTeamMembersSums(this.orderLines, this.weekHours);
    }, err => {
      this.loadingWeek = false;
    });
  }
  private addSingleWeekdayForTesting() {
    if (!environment.production) { // add a single weekday for testing
      let allisnull = true;
      for (const weekday of this.weekDays) {
        if (weekday) { allisnull = false; }
      }
      if (allisnull) {
        const testDay = new IsiWeekDay();
        testDay.workStart = new Date();
        testDay.workEnd = new Date();
        this.weekDays = [testDay, null, null, null, null, null, null];
      }
    }
  }
  private generateWeekDaySums (weekHours: {[key: string]: Array<IsiWeekHour>}) {
    this.weekDaysSums = [
      new IsiWeekHour(), new IsiWeekHour(), new IsiWeekHour(), new IsiWeekHour(), new IsiWeekHour(), new IsiWeekHour(), new IsiWeekHour()
    ];
      for (const key in this.weekHours) {
        if (this.weekHours[key]) {
          for (let i = 0; i < this.weekHours[key].length; i++) {
            if (this.weekHours[key][i]) {
              this.weekDaysSums[i].hoursWork += this.weekHours[key][i].hoursWork;
              this.weekDaysSums[i].hoursTravel += this.weekHours[key][i].hoursTravel;
              this.weekDaysSums[i].hoursOvertime50 += this.weekHours[key][i].hoursOvertime50;
              this.weekDaysSums[i].hoursNight += this.weekHours[key][i].hoursNight;
              if (this.weekHours[key][i].body) {
                this.weekDaysSums[i].body += this.weekHours[key][i].body;
              }
            }
          }
        }
      }
  }
  private generateTeamMembersSums(orderLines: Array<OrderLine>, weekHours: {[key: string]: Array<IsiWeekHour>}) {
    this.teamMembersSums = {};
    for (const orderKey in this.weekHours) { // each order line
      if (this.weekHours[orderKey]) {
        for (let dayIndex = 0; dayIndex < this.weekHours[orderKey].length; dayIndex++) { // each hour from mon-sun
          const weekHour = this.weekHours[orderKey][dayIndex];
          if (weekHour) {
            for (const teamMember of weekHour.teamMembers) {
              if (teamMember) {
                const initials = teamMember.initials || teamMember.name;
                if (!this.teamMembersSums[initials]) { // init team member if not exists
                  this.teamMembersSums[initials] = {
                    hourList: [new IsiWeekHour(), new IsiWeekHour(), new IsiWeekHour(),
                    new IsiWeekHour(), new IsiWeekHour(), new IsiWeekHour(), new IsiWeekHour()],
                    intern: false
                  };
                  this.teamMembersSums[initials].intern = weekHour.intern ? weekHour.intern.unid === teamMember.unid : false;
                } else if (!this.teamMembersSums[initials].intern) {
                  // check if is intern again (if not already), because some orderLines may have the member as an intern or not.
                  // The sum lines should display warning color member is intern in one of the orderLines.
                  this.teamMembersSums[initials].intern = weekHour.intern ? weekHour.intern.unid === teamMember.unid : false;
                }
                // accumulate hours
                this.teamMembersSums[initials].hourList[dayIndex].hoursWork += weekHour.hoursWork;
                this.teamMembersSums[initials].hourList[dayIndex].hoursTravel += weekHour.hoursTravel;
                this.teamMembersSums[initials].hourList[dayIndex].hoursOvertime50 += weekHour.hoursOvertime50;
                this.teamMembersSums[initials].hourList[dayIndex].hoursNight += weekHour.hoursNight;
              }
            }
          }
        }
      }
    }
  }
  /**
   * Add the total work ours for each project
   * @param orderLines the generated orderLines
   * @param weekHours The list of "timeføringer", used to add the total
   */
  private generateOrderSums(orderLines: Array<OrderLine>, weekHours: {[key: string]: Array<IsiWeekHour>}) {
    for (const order of orderLines) {
      for (const weekHour of weekHours[order.id]) {
        if (weekHour) {
          order.sumHoursWork += weekHour.hoursWork;
          order.sumHoursNight += weekHour.hoursNight;
          order.sumHoursOvertime50 += weekHour.hoursOvertime50;
          order.sumHoursTravel += weekHour.hoursTravel;
        }
      }
    }
  }
  private generateOrderLinesAndInitTeamMembersSums(weekHours: {[key: string]: Array<IsiWeekHour>}) {
    this.orderLines = [];
    this.teamMembersSums = {};
    const keys = Object.keys(weekHours).sort();
    for (const key of keys) {
      if (weekHours[key]) {
        let orderLineCreated = false;
        for (const anHour of weekHours[key]) {
          if (anHour) {
            if (!orderLineCreated) {
              orderLineCreated = true;
              this.orderLines.push({
                id: key,
                orderSNum: anHour.orderSNum,
                orderNum: anHour.orderNum,
                orderSubject: anHour.orderSubject,
                projNum: anHour.projNum,
                projName: anHour.projName,
                artNumber: anHour.artNumber,
                intern: anHour.intern,
                teamMembers: anHour.teamMembers,
                sumHoursWork: 0,
                sumHoursOvertime50: 0,
                sumHoursNight: 0,
                sumHoursTravel: 0,
              });
            } else if (orderLineCreated && anHour.intern) {
              // add the first intern that exists on a weekHour, if not present on first existing weekHour on this orderLine
              this.orderLines[this.orderLines.length - 1].intern = anHour.intern;
              break;
            }
          }
        }
      }
    }
  }
  getAvailableTime(weekDay: IsiWeekDay): number {
    if (weekDay && weekDay.workEnd && weekDay.workStart) {
      const endTime = moment(weekDay.workEnd);
      const startTime = moment(weekDay.workStart);
      if (endTime.isBefore(startTime)) { // working until the next day.
        endTime.add(1, 'd');
      }
      const duration = moment.duration(endTime.diff(startTime));
      const hourPause = weekDay.hourPause ? weekDay.hourPause : 0;
      return (duration.asHours()) - hourPause;
    }
    return 0;
  }
  getSelectedWeekDay(): IsiWeekDay {
    return this.weekDays[this.selectedDate.locale('en-gb').weekday()];
  }
  getSelectedOrderLineWeekHour(orderLine: OrderLine): IsiWeekHour {
    const dayOfWeek: number = this.selectedDate.locale('en-gb').weekday();
    const weekHoursForOrder = this.weekHours[orderLine.id];
    return weekHoursForOrder[dayOfWeek];
  }
}
