<select [(ngModel)]="selectedDevice" (change)="changeCamera()">
    <option *ngFor="let device of devices" [value]="device.deviceId">{{device.label}}</option>
</select>
<video autoplay class="camera" [hidden]="!showLive" [ngClass]="{'hide':!showLive, 'show': showLive}"></video>

<!-- <webcam [captureImageData]="true" width="1024" height="768" mirrorImage="never" imageType="image/jpeg" (imageCapture)="handleImage($event)" [trigger]="triggerObservable"
    *ngIf="showLive"></webcam> -->
<button mat-fab aria-label="Ta bilde" class="btn-placement" color="primary" (click)="takePicture()" *ngIf="showLive">
    <mat-icon>photo_camera</mat-icon>
</button>
<button mat-fab aria-label="Ta nytt bilde bilde" class="btn-placement" [class] color="primary" (click)="newImage()" *ngIf="!showLive">
    <mat-icon>add_a_photo</mat-icon>
</button>
<button mat-fab aria-label="Lagre" color="primary" (click)="getCapturedImage()" *ngIf="!showLive" class="btn-save-placement">
    <mat-icon>save</mat-icon>
</button>
<div class="snapshot" [hidden]="showLive" [ngClass]="{'hide':showLive, 'show': !showLive}">
    <!-- <canvas #myCanvas style="width: 100%;"></canvas> -->
    <img #myCanvas style="width: 100%;" [src]="imageData">
    <canvas #hiddenCanvas width="1280" height="720" [hidden]=true></canvas>
    <br />
</div>