<mat-card>
  <mat-card-title>
    {{data?.contactName}}
  </mat-card-title>
  <mat-card-subtitle>
    {{contact?.company}}
  </mat-card-subtitle>
  <mat-card-content>
    <ul>
      <li>e-post: {{contact?.email}}</li>
      <li *ngFor="let p of contact?.phone">
        {{p.label}}: {{p.value}}
      </li>
    </ul>
  </mat-card-content>
</mat-card>