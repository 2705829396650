import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { SjaService } from '../../../sja/sja.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSort, MatDialog } from '@angular/material';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SjaDataSource } from '../../../sja/sja-data-source';
import { BehaviorSubject } from 'rxjs';
import { NgModel } from '@angular/forms';
import { MediaService } from '../../../shared/media.service';
import { MobileDialogComponent } from '../mobile-dialog/mobile-dialog.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-sja-day',
  templateUrl: './sja-day.component.html',
  styleUrls: ['./sja-day.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SjaDayComponent implements OnInit, OnChanges {
  @Input() days;
  @Input() parent;
  @Input() archived;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  orderUnid: string;
  columnsToDisplay = ['date', 'comment', 'expand', 'action'];
  dataSource: SjaDataSource;
  subject: BehaviorSubject<any>;
  expandedElement;
  isMobile: boolean;

  constructor(private sjaService: SjaService,
              private route: ActivatedRoute,
              private snackbar: MatSnackBar,
              private mediaService: MediaService,
              public dialog: MatDialog) {
    this.mediaService.getMedia().subscribe(media => {
      this.isMobile = media.isMobileView;
    })
  }

  ngOnInit() {
    this.orderUnid = this.route.snapshot.paramMap.get('orderUnid');
  }

  ngOnChanges() {
    this.subject = new BehaviorSubject(this.days);
    this.dataSource = new SjaDataSource(this.subject.asObservable());
  }

  openDialog(day) {
    console.log('open day in dialog', day);
    day.archived = this.archived;
    day.orderUnid = this.orderUnid;
    const dialogRef = this.dialog.open(MobileDialogComponent, {
      height: '90%',
      width: '95%',
      data: day,
    });
    dialogRef.disableClose = true;
    dialogRef.beforeClose().pipe(take(1)).subscribe(bc => {
      console.log('before close');
    });

    dialogRef.beforeClosed().pipe(take(1)).subscribe(bcd => {
      console.log('before closed');
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      console.log('dialog closed, sja day component', result);
      if (result) {
        day.comment = result.dayComment;
        day.date = result.dayDate;
        this.save(day, result.dayComment, result.dayDate);
      }
    });
  }

  newDay() {
    if (!this.days) {
      this.days = [];
    }
    const newDay = {
      date: new Date(),
      riskFactors: [],
      sjaUnid: this.parent.unid
    }
    this.days.unshift(newDay);
    this.expandedElement = newDay;
    this.subject.next(this.days);
    this.openDialog(newDay);
  }

  save(day: any, dayComment: NgModel, dayDate: NgModel) {
    this.sjaService.saveDayComment(this.orderUnid, day).subscribe(response => {
      this.snackbar.open("Dagkommentar er lagret.", '', { duration: 5000 });
      day.unid = response.unid;
      if (dayComment && dayDate) {
        dayComment.reset(day.comment);
        dayDate.reset(day.date);
        this.subject.next(this.days);
      }
    })
  }

  onRiskFactorAdded(day: any) {
    this.save(day, undefined, undefined);
    this.ngOnChanges();
  }

  deleteDay(row) {
    const index = this.days.indexOf(row);
    const doDelete = confirm('Er du sikker på du vil slette dag kommentar?')
    if (doDelete) {
      const day = this.days[index];
      this.sjaService.deleteDayComment(this.orderUnid, day).subscribe( data => {
        this.snackbar.open('Dagkommentar er slettet.', '', {duration: 5000});
        this.days.splice(index, 1);
        this.subject.next(this.days);
      }, error => {
        this.snackbar.open('Feil under sletting av dagkommentar.', '' , { duration: 5000 });
      });
    }
  }

}
