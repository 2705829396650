<div class="home" [class.mobile-view]="isMobileView">
      <div class="employeeDropdown" [formGroup]="frmGroup" [hidden] ="!isAdmin">
            <mat-form-field>
                  <mat-label>Velg Ansatt</mat-label>
                  <mat-select formControlName="employee" (ngModelChange)="onEmployeeChange($event)">
                        <mat-option *ngFor="let emp of employeeList" [value]="emp.empId">
                              {{ emp.name }}
                        </mat-option>
                  </mat-select>
            </mat-form-field>
      </div>
      <br>
      <ng-container *ngIf="!isMobileView">
            <mat-card class="home-card" [class.mobile-view]="isMobileView">

                  <div [class.mini-grid]="!showFullWeek" [class.mega-grid]="showFullWeek">
                        <div class="year-week-picker">
                              <app-year-input (dateChange)="onDateChanged($event)" [selectedDate]="selectedDate">
                              </app-year-input>
                              <app-week-input (dateChange)="onDateChanged($event)" [selectedDate]="selectedDate">
                              </app-week-input>
                              <div class="weekdays">
                                    <div *ngFor="let number of [0,1,1,1,1,1,1]; let i = index;"
                                          class="week-day week-day-mon-sun">
                                          <span
                                                *ngIf="(i < 4 || i > 3 && showFullWeek) || (isMobileView)">{{beginWeek.add(number,'day') | date : 'EEEdd.MMM'}}</span>
                                    </div>
                              </div>
                              <div class="checkbox double-span-end" [formGroup]="formGroup">
                                    <mat-checkbox formControlName="showFullWeek" (change)="toggleShowFullWeek($event)">
                                          Vis helg</mat-checkbox>
                              </div>
                        </div>
                  </div>
                  <hr>
                  <div class="projects">
                        <app-create-project-line [employeeId]="employeeId" [selectedWeekYear]="selectedWeekYear"
                              [selectedDate]="selectedDate" [fullWeek]="fullWeek"></app-create-project-line>

                  </div>
            </mat-card>
      </ng-container>
      <ng-container *ngIf="isMobileView">
            <div class="date-picker-bar">
                  <button class="date-picker-btn" mat-button color="accent" (click)="picker.open()">Velg dato</button>
                  <mat-form-field id="datepickerFormField">
                        <input matInput [matDatepicker]="picker" placeholder="Choose a date"
                              (dateChange)="datePickerChanged('change', $event)">
                        <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                  <div class="year-day-picker double-span-start">
                        <app-day-input (dateChange)="onDateChanged($event)" [selectedDate]="selectedDate">
                        </app-day-input>
                  </div>
            </div>
            <br>
            <div class="projects">
                  <app-create-project-line-mobile  [selectedDate]="selectedDate"  [selectedWeekYear]="selectedWeekYear" [employeeId]="employeeId"></app-create-project-line-mobile>

            </div>
      </ng-container>

</div>