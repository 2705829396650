import { Component, OnInit, Renderer2, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import { SettingsService } from '../../settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-culvert',
  templateUrl: './culvert.component.html',
  styleUrls: ['./culvert.component.scss']
})
export class CulvertComponent implements OnInit, OnDestroy {
  @HostBinding('class.agjerde-icon-container') hostClass = true;

  isNightMode: boolean;
  private nightModeSub: Subscription;
  constructor(private settingsService: SettingsService, private renderer: Renderer2, private elemRef: ElementRef) {
    this.nightModeSub = this.settingsService.getNightMode().subscribe(isNightMode => {
      this.isNightMode = isNightMode;
    })
  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
    if (this.nightModeSub) {
      this.nightModeSub.unsubscribe();
    }
  }


}
