var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AbstractDialogComponent } from '../../abstract-dialog/abstract-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../data.service';
import { UserService } from '../../../user.service';
var CreateNewProposalComponent = /** @class */ (function (_super) {
    __extends(CreateNewProposalComponent, _super);
    function CreateNewProposalComponent(dialogRef, data, dataService, user) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.dataService = dataService;
        _this.user = user;
        //UserName: String;
        _this.departments = [];
        _this.data = data;
        _this.dialogFormGroup = new FormGroup({
            subject: new FormControl('', Validators.required),
            department: new FormControl('', Validators.required),
            body: new FormControl('')
        });
        return _this;
    }
    CreateNewProposalComponent.prototype.confirm = function (event) {
        throw new Error("Method not implemented.");
    };
    CreateNewProposalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataService.getEmployeeDepartments().pipe().subscribe(function (depts) {
            _this.departments = depts;
            _this.departments.push("Andre(eksterne)");
        });
    };
    CreateNewProposalComponent.prototype.saveProposal = function (event) {
        var _this = this;
        var proposalDate = this.dialogFormGroup.value;
        if (proposalDate) {
            this.dataService.SaveProposal(proposalDate).subscribe(function (proposal) {
                _this.dialogRef.close(proposal.data);
            });
        }
    };
    return CreateNewProposalComponent;
}(AbstractDialogComponent));
export { CreateNewProposalComponent };
