<div class="sja-container mat-typography" [class.mobile-view]="isMobileView">
    <h1 class="title" *ngIf="order">Ordre: {{order?.sNum}}, {{order?.subject}} ({{order?.roadNum}})</h1>
    <mat-card>
      <h2>SJA</h2>
      <div id="sjaButton">
        <button mat-raised-button color="primary" (click)="newSJA()">Opprett ny SJA</button>
      </div>
      <mat-card-content>
        <div *ngIf="!order" class="loading">
          <div>Vennligst vent mens SJA hentes</div>
          <mat-spinner></mat-spinner>
        </div>
        <app-sja-archive #sjaarchive [(sjaList)]="sjaList" class="sjaArchive" (sjaSelectedEmitter)="onSelectedSja($event)"></app-sja-archive>
      </mat-card-content>
    </mat-card>

    <p></p>
    <mat-card *ngIf="selectedSja">
      <mat-card-content>
        <app-sja-edit [sja]="selectedSja" [orderParsells]="order.parsellList" (saveSja)="onSaveSja($event,sjaarchive)"></app-sja-edit>
      </mat-card-content>
    </mat-card>
    <p></p>
    <mat-card *ngIf="selectedSja && selectedSja?.unid">
      <mat-card-content>
        <app-sja-risk-factors [riskFactors]="selectedSja?.riskFactors" (riskfactorAdded)="onRiskFactorAdded($event)" inlineForm="false" [archived]="selectedSja?.archived>0"></app-sja-risk-factors>
      </mat-card-content>
    </mat-card>
    <p></p>
    <mat-card *ngIf="selectedSja && selectedSja?.unid">
      <mat-card-content>
        <app-sja-day [days]="selectedSja?.sjaDays" [parent]="selectedSja" [archived]="selectedSja?.archived>0"></app-sja-day>
      </mat-card-content>
    </mat-card>
    <p></p>
    <mat-card *ngIf="selectedSja && (selectedSja?.signList || selectedSja?.extraSignList)">
      <mat-card-content>
        <app-sja-signs-log #signslog [signs]="selectedSja?.signList" [extraSigns]="selectedSja?.extraSignList" (saveSigns)="onSaveSigns($event,selectedSja)" [archived]="selectedSja?.archived>0"></app-sja-signs-log>
      </mat-card-content>
    </mat-card>
    <br />
</div>
<div class="sticky-footer" *ngIf="selectedSja && selectedSja.unid" [class.mobile-view]="isMobileView">
  <form>    
    <button type="button" mat-raised-button class="btn-save success" color="accent" (click)="archiveSja()" [disabled]="selectedSja?.archived>0" *ngIf="selectedSja.unid">Avslutt SJA</button>
    <div class="placeholder"></div>
  </form>
</div>
<app-road-information></app-road-information>