import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {AbstractDialogComponent} from '../../abstract-dialog/abstract-dialog.component';
import { DataService } from '../../../data.service';
import { UserService } from '../../../user.service';
import { MediaService } from '../../../shared/media.service';

@Component({
  templateUrl: './sja-risk-factors-dialog.component.html',
  styleUrls: ['./sja-risk-factors-dialog.component.scss']
})

export class SjaRiskFactorsDialogComponent extends AbstractDialogComponent implements OnInit {
  dialogFormGroup: FormGroup;
  myTeam;
  isEdit;
  isMobile: boolean;


  constructor(
    public dialogRef: MatDialogRef<AbstractDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private dataService: DataService,
    private mediaService: MediaService, private user: UserService) {
      super(dialogRef);

      this.dialogFormGroup = new FormGroup ({
        operation: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        measures: new FormControl('', Validators.required),
        responsible: new FormControl('', Validators.required)
      });
      this.mediaService.getMedia().subscribe(media => {
        this.isMobile = media.isMobileView;
      })
      if (this.data.risk) {
        this.isEdit = true;
        this.dialogFormGroup.setValue(this.data.risk);
      } else {
        this.isEdit = false;
      }
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.dataService.getTeamEmployyes(this.user.teamId).subscribe(employees => {
      this.myTeam = employees;
      if (this.data.risk) {
        const selectedEmployee = this.myTeam.find(employee => {
           return employee.unid === this.data.risk.responsible.unid;
        });
        this.dialogFormGroup.get('responsible').setValue(selectedEmployee);
      }
    })
  }
  confirm(event) {
    this.dialogRef.close({form: this.dialogFormGroup.value, isEdit: this.isEdit} );
  }
}
