    <!-- <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
        style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <circle cx="12" cy="12" r="10" style="fill:none;" />
        <clipPath id="_clip1">
            <circle cx="12" cy="12" r="10" />
        </clipPath>
        <g id="outerCircle" clip-path="url(#_clip1)">
            <path fill="white"
                d="M12,2C17.519,2 22,6.481 22,12C22,17.519 17.519,22 12,22C6.481,22 2,17.519 2,12C2,6.481 6.481,2 12,2ZM12,4C16.415,4 20,7.585 20,12C20,16.415 16.415,20 12,20C7.585,20 4,16.415 4,12C4,7.585 7.585,4 12,4Z" />
        </g>
        <g transform="matrix(1.07692,0,0,1.07692,0.692308,-2.53846)">
            <circle cx="10.5" cy="13.5" r="6.5" style="fill:none;" />
            <clipPath id="_clip2">
                <circle cx="10.5" cy="13.5" r="6.5" />
            </clipPath>
            <g id="waves" clip-path="url(#_clip2)">
                <g id="v" fill="white" transform="matrix(0.928571,0,0,0.928571,-0.642857,2.35714)">
                    <path
                        d="M5,13.297C5.222,13.198 5.667,13 6,13C6.333,13 6.667,13.297 7,13.297C7.333,13.297 7.667,13 8,13C8.333,13 8.667,13.297 9,13.297C9.333,13.297 9.667,13 10,13C10.333,13 10.667,13.297 11,13.297C11.333,13.297 11.667,13 12,13C12.333,13 12.667,13.297 13,13.297C13.333,13.297 13.667,13 14,13C14.333,13 14.667,13.297 15,13.297C15.333,13.297 15.667,13 16,13C16.333,13 16.667,13.297 17,13.297C17.333,13.297 17.667,13 18,13C18.333,13 18.778,13.198 19,13.297L18.538,15.537L17.167,17.152L13.186,19.49L9.077,19.235L7.322,17.983L5.421,15.4L5.168,13.821L5,13.297Z" />
                </g>
            </g>
        </g>
</svg> -->


<div class="agjerde-icon">
    <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
        style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <circle cx="12" cy="12" r="10" style="fill:none;" />
        <clipPath id="_clip1">
            <circle cx="12" cy="12" r="10" />
        </clipPath>
        <g clip-path="url(#_clip1)">
            <path id="outerCircle" [attr.fill]="isNightMode?'white':'black'"
                d="M12,2C17.519,2 22,6.481 22,12C22,17.519 17.519,22 12,22C6.481,22 2,17.519 2,12C2,6.481 6.481,2 12,2ZM12,4C16.415,4 20,7.585 20,12C20,16.415 16.415,20 12,20C7.585,20 4,16.415 4,12C4,7.585 7.585,4 12,4Z" />
        </g>
        <g transform="matrix(1.07692,0,0,1.07692,0.692308,-2.53846)">
            <circle cx="10.5" cy="13.5" r="6.5" style="fill:none;" />
            <clipPath id="_clip2">
                <circle cx="10.5" cy="13.5" r="6.5" />
            </clipPath>
            <g clip-path="url(#_clip2)">
                <g transform="matrix(0.928571,0,0,0.928571,-0.642857,2.35714)">
                    <path id="waves" [attr.fill]="isNightMode?'white':'black'"
                        d="M4,14C4.097,13.6 5.333,13 6,13C6.667,13 7.333,14 8,14C8.667,14 9.333,13 10,13C10.667,13 11.333,14 12,14C12.667,14 13.333,13 14,13C14.667,13 15.333,14 16,14C16.667,14 17.333,13 18,13C18.667,13 19.91,13.577 20,14C20.09,14.423 19.01,15.012 18.538,15.537C18.066,16.062 17.735,16.732 17.167,17.152C16.275,17.811 14.534,19.143 13.186,19.49C11.857,19.832 10.054,19.486 9.077,19.235C8.381,19.056 7.818,18.503 7.322,17.983C6.713,17.344 5.975,16.064 5.421,15.4C4.995,14.89 3.903,14.4 4,14Z" />
                </g>
            </g>
        </g>
    </svg>
</div>
