<form id="sjaEdit" [formGroup]="sjaForm" class="mat-typography">
  <div class="form-content">
    <h2>SJA: {{sja?.created|date:'dd.MM.yyyy HH:mm'}} <span>({{sja?.status}})</span></h2>
    <mat-form-field>
      <mat-select placeholder="Velg parseller" formControlName="parsells" name="parsells" required="true" multiple>
        <mat-option *ngFor="let parsell of parsells" [value]="parsell.value">
          {{parsell.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Varslingseksempel" formControlName="arbId" name="arbId" reqiured="true">
        <mat-option *ngFor="let eks of workExamples" [value]="eks.value">
         {{eks.value}} - {{eks.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Velg ansatte" formControlName="employees" name="employees" required="true" multiple>
        <mat-option *ngFor="let employee of myTeam" [value]="employee.unid">
          {{employee.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput placeholder="Kommentar" required="true" formControlName="comment" name="comment"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
  </div>
  <div class="risk-content">
    <h2>Risikovurdering</h2>
    <mat-checkbox formControlName="action1" name="action1" required="true">Risikovurdering er foretatt før arbeidet startes opp. Føreforhold vurderes hver dag.</mat-checkbox>
    <mat-checkbox formControlName="action2" name="action2" required="true">Alle arbeidsoperasjoner er dekket av AG-661 Risikoanalyse og forebyggende tiltak.</mat-checkbox>
    <mat-checkbox formControlName="action3" name="action3" required="true">Skilting er ikke i konflikt med annen arbeidssvarsling og det er tatt hensyn til annen fartsgrense.</mat-checkbox>
    <br/><br/>
    <label>HMS-samordning iverksettes om nødvendig. Samordningsansvar fra ordre ikke relevant.</label>
    <mat-slide-toggle formControlName="action4" name="action4" required="false" (change)="toggleChange($event)">{{toggleLabel}}</mat-slide-toggle>
    <p style="margin-top: 10px;" *ngIf="showHMSInfo()">* påkrevd med kommentar om HMS-samordning </p>
    <br/>
    <label>Fare for myke trafikkanter?</label>
    <mat-slide-toggle formControlName="action5" name="action5" required="false" (change)="action5Change($event)">{{action5Label}}</mat-slide-toggle>
    <!-- <mat-radio-group formControlName="action5" name="action5" required="false" (change)="action5Change($event)" aria-label="Fare for myke traffikanter? Ja/Nei">
      <mat-radio-button value="Ja">Ja</mat-radio-button>
      <mat-radio-button value="Nei">Nei</mat-radio-button>
    </mat-radio-group> -->
    <p style="margin-top: 10px;" *ngIf="showAction5Info()">* påkrevd med kommentar hvis fare for myke traffikanter </p>
  </div>
  <div id="saveButton">
    <button mat-raised-button color="primary" (click)="saveSJA()" [disabled]="this.sja.archived>0 || !sjaForm.valid || sjaForm.pristine">Lagre SJA</button>
  </div>
</form>