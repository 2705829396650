import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Project } from '../../project';
import { DataService } from '../../data.service';
import { take } from 'rxjs/operators';
import { UserService } from '../../user.service';
import * as moment from 'moment';
import { TaskService } from '../../task.service';
import { CameraDialogComponent } from '../camera-dialog/camera-dialog.component';
import { AppConfig } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CameraComponent } from '../camera/camera.component';
import { ElectronService } from '../../electron.service';

@Component({
  selector: 'app-workshop-task-dialog',
  templateUrl: './workshop-task-dialog.component.html',
  styleUrls: ['./workshop-task-dialog.component.scss']
})
export class WorkshopTaskDialogComponent extends AbstractDialogComponent implements OnInit {
  @ViewChild(CameraComponent, { static: false }) camera: CameraComponent
  dialogFormGroup: FormGroup;
  data: any;
  projects: Array<Project> = [];
  weeks: Array<any> = [];
  fd = new FormData();
  takepicture: boolean = false;
  canvasImage: any;

  constructor(protected dialogRef: MatDialogRef<WorkshopTaskDialogComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) data: any,
    private http: HttpClient,
    private electronService: ElectronService,
    private dataService: DataService,
    private taskService: TaskService,
    private user: UserService) {
    super(dialogRef);
    this.data = data;
    this.dialogFormGroup = new FormGroup({
      body: new FormControl('', Validators.required),
      projNum: new FormControl('', Validators.required),
      weekSelector: new FormControl()
    });
  }

  ngOnInit() {
    if (this.data.task && !this.data.task.body) {
      // auto-task doesn't have a body field, only subject.
      this.data.task['body'] = this.data.task.subject;
    }
    this.dataService.getProjects().pipe(take(1)).subscribe(projs => {
      this.projects = projs;
     
    });
    if (this.data.task) {      
      this.dialogFormGroup.get('weekSelector').setValue(this.data.task.weekNumber);
      this.dialogFormGroup.get('projNum').setValue(this.data.task.projNum);
      this.dialogFormGroup.get('body').setValue(this.data.task.body);
      if (this.data.task.imageName) {
        this.putImage(this.data.task.replicaId, this.data.task.unid, this.data.task.imageName);
      }
    } else {
      this.setDefaultProject();
    }
    this.getWeeks();
    const m = moment();    
    this.dialogFormGroup.get('weekSelector').setValue(`${m.isoWeek()}/${m.year()}`);
    
    if (this.data.task && this.data.task.status===9) {
      this.dialogFormGroup.disable();  
    }

  }
  putImage(replicaId:string, unid:string, imgName: string){
    let src = `${AppConfig.apiUrl}/attachment/${replicaId}/${unid}?file=${imgName}`;
    if (!this.electronService.isElectron()) {
      src = `https://files.agjerde.no/v3/${replicaId}/${unid}/${imgName}`;
    }

    var img = document.createElement('img');
    img.src = src;
    img.width = 300;
    img.className = ''
    const childCount = document.getElementById("snapshot").childElementCount;
    var child = document.getElementById("snapshot");
    if (childCount > 0) child.removeChild(child.childNodes[0]);
    document.getElementById('snapshot').appendChild(img);
  }

  confirm(): void {
    const taskData = this.dialogFormGroup.value;
    if (this.data.task) {
      this.fd.append('unid', this.data.task.unid);
    }
    var snum = Math.floor((Math.random() * 100000) + 10000).toString();
    this.fd.append('taskSNum', snum);

    this.fd.append('projNum', taskData['projNum']);
    this.fd.append('body', taskData['body']);
    this.fd.append('weekSelector', taskData['weekSelector']);
    // this.camera.stopCamera();
    this.taskService.saveWorkshopTask(this.fd).subscribe(task => {
      this.data = { task: task };
      /*  var snum = Math.floor((Math.random() * 100000) + 10000).toString();
       this.fd.append('unid', task.unid);
       this.fd.append('taskSNum', snum); */
      this.dialogRef.close(this.data);
    });
  }

  compareFn(v1, v2) {
    console.log('v1', v1, "v2", v2 )
    return v1 && v2 ? v1.value === v2.value : v1 === v2;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setDefaultProject() {
    console.log('set default project!');
    this.dialogFormGroup.get('projNum').setValue(this.getDefaultProject());
  }

  getWeeks() {
    this.weeks.push({ value: "0", label: 'Åpen-uten uke' });
    const currentWeek = moment().isoWeek();
    const year = moment().year();
    const lastWeek = moment(year, "YYYY").isoWeeksInYear();
    for (let i = 0; i < 3; i++) {
      var week = currentWeek + i;
      if (week > 52 && lastWeek == 52) {
        week = 0 + i;
      } else if (lastWeek == 53 && week == 54) {
        week = 1;
      } else if (lastWeek == 53 && week > 54) {
        week = 2
      }
      const uke = `Uke ${week}/${year} | ${week}/${year}`;
      this.weeks.push({ value: `${week}/${year}`, label: uke });
    }
  }

  getDefaultProject() {
    console.log('get default project')
    let defaultProject = "0";
    const teamId = this.user.teamId;
    if (teamId === "A") {
      defaultProject = "610";
    } else if (teamId === "B") {
      defaultProject = "620";
    } else if (teamId === "C") {
      defaultProject = "630";
    } else if (teamId === "D") {
      defaultProject = "640";
    } else if (teamId === "E") {
      defaultProject = "650";
    } else if (teamId === "F") {
      defaultProject = "660";
    } else if (teamId === "G") {
      defaultProject = "670";
    } else if (teamId === "H") {
      defaultProject = "680";
    } else if (teamId === "I") {
      defaultProject = "690";
    } else if (teamId === 'Z') {
      defaultProject = "620";
    } else {
      defaultProject = "0";
    }
    return defaultProject;
  }

  openCamera() {
    this.takepicture = true;
  }

  getImage(e) {
    this.takepicture = false;
    this.canvasImage = e;
    this.fd = e.formData;
    if (e) {
      var img = document.createElement('img');
      img.src = e.imageData;
      img.width = 200;
      img.className = '';
      const childCount = document.getElementById("snapshot").childElementCount;
      var child = document.getElementById("snapshot");
      if (childCount > 0) child.removeChild(child.childNodes[0]);
      document.getElementById('snapshot').appendChild(img);
    }
  }

  getCapturedImage() {
    this.camera.takePicture();
    // this.camera.getCapturedImage();
  }

}
