import { Component, OnInit, OnDestroy, ViewChild, HostBinding } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SjaService } from '../../../sja/sja.service';
import { SjaArchiveComponent } from '../sja-archive/sja-archive.component';
import { ActivatedRoute } from '@angular/router';

import { HeaderService } from '../../header/header.service';
import { DataService } from '../../../data.service';
import { SjaSignsLogComponent } from '../sja-signs-log/sja-signs-log.component';
import { ElectronService } from '../../../electron.service';
import { MediaService } from '../../../shared/media.service';


@Component({
  selector: 'app-sja',
  templateUrl: './sja.component.html',
  styleUrls: ['./sja.component.scss']
})
export class SjaComponent implements OnInit, OnDestroy {
  @ViewChild('sjaarchive', {static: false}) sjaarchive: SjaArchiveComponent;
  @ViewChild('signslog', {static: false}) signslog: SjaSignsLogComponent;
  @HostBinding('class.app-sja-component') hostClass = true;
  order;
  sjaList: Array<any>;
  selectedSja;
  orderUnid: string;
  isElectron: boolean;
  isMobileView: boolean;

  constructor(private sjaService: SjaService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private dataService: DataService,
    private headerService: HeaderService,
    private electronService: ElectronService,
    private mediaService: MediaService) {
      this.isElectron = this.electronService.isElectron();
      this.sjaList = new Array<any>();
      this.mediaService.getMedia().subscribe(media => {
        this.isMobileView = media.isMobileView;        
      })
  }

  ngOnInit() {
    this.orderUnid = this.route.snapshot.paramMap.get('orderUnid');
    this.headerService.addNavItem({
      route: `/sja/${this.orderUnid}`,
      label: 'SJA',
      queryParams: ''
    });
    this.sjaService.getOrderSjaArchive(this.orderUnid).subscribe(order => {
      this.order = order;
      if (order.sjaList) {
        this.sjaList = order.sjaList;
      }
    });
  }

  ngOnDestroy() {
    this.headerService.removeNavItem({
      route: `/sja/${this.orderUnid}`,
      label: 'SJA',
      queryParams: ''
    });

  }

  newSJA() {
    // add default team member
    this.dataService.getTeamMembers().subscribe(members => {
      this.selectedSja = {
        created: new Date().getTime(),
        status: "Ikke lagret",
        parsell: ["A"]
      }
      this.selectedSja.employees = members.map(member => {
        return member.unid;
      })
      // add to table
      this.sjaList.push(this.selectedSja);
      this.sjaarchive.refresh(this.selectedSja);
    });
  }

  onRiskFactorAdded(riskFactors: any) {
    this.selectedSja.riskFactors = riskFactors;
    this.sjaService.saveSja(this.orderUnid, this.selectedSja).subscribe(savedSja => {
      this.selectedSja = savedSja;
    });
  }

  onSaveSja(sja: any, sjaarchive: SjaArchiveComponent) {
    this.sjaList = this.sjaList.map(sjaElement => {
      if (sjaElement.created === sja.created) {
        return sja;
      } else {
        return sjaElement;
      }
    })
    sja.riskFactors = this.selectedSja.riskFactors;
    sja.orderNum = this.order.orderNum;
    this.sjaService.saveSja(this.orderUnid, sja).subscribe(savedSja => {
      this.selectedSja = savedSja;
      sjaarchive.refresh(this.selectedSja);
      if (this.signslog) {
        this.signslog.refresh(this.selectedSja.signList);
      }
      this.snackbar.open("SJA er lagret.", '', { duration: 5000 });
    });
  }


  onSelectedSja(row) {
    this.selectedSja = row;
    if (!this.selectedSja.extraSignList) {
      this.selectedSja.extraSignList = new Array<any>();
    }
  }


  onSaveSigns(event, selectedSja) {
    this.sjaService.saveSignsOnSja(this.orderUnid, selectedSja).subscribe(savedSja => {
    });
  }

  archiveSja() {
    // archive SJA
    // 1. removeAllSigns
    this.sjaService.removeAllSigns(this.selectedSja.signList, false);
    this.sjaService.removeAllSigns(this.selectedSja.extraSignList, false);
    // 2. set item "Arkivert" date
    this.selectedSja['archived'] = new Date().getTime();
    this.selectedSja['status'] = "Arkivert";
    this.sjaService.saveSja(this.orderUnid, this.selectedSja).subscribe(data => {});
    // 3. remove "Active" item from document
    // 4. status?

  }

}
