/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./week-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "@angular/material/form-field";
import * as i9 from "./week-input.component";
var styles_WeekInputComponent = [i0.styles];
var RenderType_WeekInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WeekInputComponent, data: {} });
export { RenderType_WeekInputComponent as RenderType_WeekInputComponent };
export function View_WeekInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "week-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["color", "primary"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getPrevWeek() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-icon", [["aria-label", "Previous week"], ["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["chevron_left"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "selected-week"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" Uke ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["color", "primary"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getNextWeek() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "mat-icon", [["aria-label", "Next week"], ["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(11, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["chevron_right"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 2, 0, currVal_2); _ck(_v, 4, 0); var currVal_8 = "primary"; _ck(_v, 9, 0, currVal_8); _ck(_v, 11, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 4).inline; var currVal_4 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = _co.selectedWeek; _ck(_v, 7, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 9).disabled || null); var currVal_7 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 11).inline; var currVal_10 = (((i1.ɵnov(_v, 11).color !== "primary") && (i1.ɵnov(_v, 11).color !== "accent")) && (i1.ɵnov(_v, 11).color !== "warn")); _ck(_v, 10, 0, currVal_9, currVal_10); }); }
export function View_WeekInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-week-input", [], null, null, null, View_WeekInputComponent_0, RenderType_WeekInputComponent)), i1.ɵprd(6144, null, i8.MatFormFieldControl, null, [i9.WeekInputComponent]), i1.ɵdid(2, 245760, null, 0, i9.WeekInputComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var WeekInputComponentNgFactory = i1.ɵccf("app-week-input", i9.WeekInputComponent, View_WeekInputComponent_Host_0, { selectedDate: "selectedDate" }, { dateChange: "dateChange" }, []);
export { WeekInputComponentNgFactory as WeekInputComponentNgFactory };
