/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./composite-article-sheet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "./composite-article-sheet.component";
import * as i7 from "@angular/material/bottom-sheet";
import * as i8 from "../../data.service";
var styles_CompositeArticleSheetComponent = [i0.styles];
var RenderType_CompositeArticleSheetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompositeArticleSheetComponent, data: {} });
export { RenderType_CompositeArticleSheetComponent as RenderType_CompositeArticleSheetComponent };
function View_CompositeArticleSheetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", ", (Antall: ", ") "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; var currVal_1 = _v.context.$implicit.name; var currVal_2 = _v.context.$implicit.quantityTotal; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_CompositeArticleSheetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "mat-card", [["class", "mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i3.MatCardTitle, [], null, null), (_l()(), i1.ɵted(4, null, [" ", " (Antall: ", ") "])), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "mat-card-subtitle", [["class", "mat-card-subtitle"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i3.MatCardSubtitle, [], null, null), (_l()(), i1.ɵted(7, null, [" Type: ", " "])), (_l()(), i1.ɵeld(8, 0, null, 0, 4, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompositeArticleSheetComponent_1)), i1.ɵdid(12, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.articles; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.data == null) ? null : _co.data.name); var currVal_2 = ((_co.data == null) ? null : _co.data.quantity); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = ((_co.data == null) ? null : _co.data.rtType); _ck(_v, 7, 0, currVal_3); }); }
export function View_CompositeArticleSheetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-composite-article-sheet", [], null, null, null, View_CompositeArticleSheetComponent_0, RenderType_CompositeArticleSheetComponent)), i1.ɵdid(1, 114688, null, 0, i6.CompositeArticleSheetComponent, [i7.MAT_BOTTOM_SHEET_DATA, i8.DataService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompositeArticleSheetComponentNgFactory = i1.ɵccf("app-composite-article-sheet", i6.CompositeArticleSheetComponent, View_CompositeArticleSheetComponent_Host_0, {}, {}, []);
export { CompositeArticleSheetComponentNgFactory as CompositeArticleSheetComponentNgFactory };
