var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { TimsheetProjectService } from '../timsheet-project.service';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatSelect, MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
var CreateProjectLineComponent = /** @class */ (function () {
    function CreateProjectLineComponent(timesheetProjectService, fb, snackbar) {
        this.timesheetProjectService = timesheetProjectService;
        this.fb = fb;
        this.snackbar = snackbar;
        this.projectCodeList = [];
        this.rowVisible = [];
        this.projectWeekData = {};
        this.formFields = [
            {
                name: 'Project Select',
                formControl: 'projectNumber',
                type: 'select'
            },
            {
                name: 'SalaryCode Select',
                formControl: 'salaryCode',
                type: 'select'
            },
            {
                name: 'Day 1',
                formControl: 'day1',
                type: 'text',
                day: 'mandag',
                visible: false,
                dayVisible: true
            },
            {
                name: 'Day 2',
                formControl: 'day2',
                type: 'text',
                day: 'tirsdag',
                visible: false,
                dayVisible: true
            },
            {
                name: 'Day 3',
                formControl: 'day3',
                type: 'text',
                day: 'onsdag',
                visible: false,
                dayVisible: true
            },
            {
                name: 'Day 4',
                formControl: 'day4',
                type: 'text',
                day: 'torsdag',
                visible: false,
                dayVisible: true
            },
            {
                name: 'Day 5',
                formControl: 'day5',
                type: 'text',
                day: 'fredag',
                visible: false,
                dayVisible: false
            },
            {
                name: 'Day 6',
                formControl: 'day6',
                type: 'text',
                day: 'lørdag',
                visible: false,
                dayVisible: false
            },
            {
                name: 'Day 7',
                formControl: 'day7',
                type: 'text',
                day: 'søndag',
                visible: false,
                dayVisible: false
            },
        ];
        this.selectedProject = [];
        this.filteredProjects = new ReplaySubject(0);
        this._onDestroy = new Subject();
        this.saveIndex = -1;
        this.weekProjects = [];
        this.selectedDate = moment();
        this.selectedDate.locale('').weekday();
        this.beginWeek = this.selectedDate.clone().startOf('isoWeek').tz('Europe/Oslo');
        this.beginWeek.set('hours', 12);
        this.currentWeekNumber = this.beginWeek.isoWeek();
        this.currentYear = this.beginWeek.year();
    }
    CreateProjectLineComponent.prototype.ngOnInit = function () {
        var _this = this;
        /*
          this.getProjects();
          this.getSalaryCodes(); */
        this.getProjectList();
        this.timesheetProjectForm = this.fb.group({
            projectLine: this.fb.array([this.addProjectLineDetails()])
        });
        // subscription for week and year changes 
        this.selectedWeekYear.subscribe(function (data) {
            _this.currentWeekNumber = data.week;
            _this.currentYear = data.year;
            _this.getProjectList();
            _this.getWeekData(_this.currentYear, _this.currentWeekNumber, _this.empId);
        });
        // subscription for employee changes
        this.employeeId.subscribe(function (data) {
            _this.empId = data.userId;
            _this.currentUserId = data.currentUserId;
            _this.isAdmin = data.isAdmin;
            _this.getProjectList();
            _this.getWeekData(_this.currentYear, _this.currentWeekNumber, _this.empId);
        });
        // subscription for showFullWeek
        this.fullWeek.subscribe(function (data) {
            _this.showFullWeek = data.data;
            if (_this.showFullWeek)
                [6, 7, 8].map(function (a) { return _this.formFields[a].dayVisible = true; });
            else
                [6, 7, 8].map(function (a) { return _this.formFields[a].dayVisible = false; });
        });
    };
    CreateProjectLineComponent.prototype.filterProjects = function (i) {
        if (!this.projects)
            return;
        if (this.projectRows.length == 0)
            return;
        var search = this.timesheetProjectForm.value.projectLine[i].projectNumberfilter;
        if (!search && search == "") {
            this.filteredProjects.next(this.projects);
            return;
        }
        else {
            search = search.toLowerCase();
        }
        this.filteredProjects.next(this.projects.filter(function (project) { return project['projName'].toLowerCase().indexOf(search) > -1; }));
    };
    CreateProjectLineComponent.prototype.ngAfterViewInit = function () {
        this.setInitialValue();
    };
    CreateProjectLineComponent.prototype.projectSearch = function (index) {
        this.filterProjects(index);
    };
    /**
    * Sets the initial value after the filteredBanks are loaded initially
    */
    CreateProjectLineComponent.prototype.setInitialValue = function () {
        var _this = this;
        this.filteredProjects
            .pipe(take(0), takeUntil(this._onDestroy))
            .subscribe(function () {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredBanks are loaded initially
            // and after the mat-option elements are available
            _this.singleSelect.compareWith = function (a, b) { return a && b && a.projName === b.projName; };
        });
    };
    CreateProjectLineComponent.prototype.getProjectList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectList, salaryCodes;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve) {
                            _this.getProjects().subscribe(function (res) {
                                resolve(res);
                            });
                        })];
                    case 1:
                        projectList = _a.sent();
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this.getSalaryCodes().subscribe(function (res) {
                                    resolve(res);
                                });
                            })];
                    case 2:
                        salaryCodes = _a.sent();
                        this.projectCodeList = [];
                        projectList.forEach(function (proj) {
                            salaryCodes.forEach(function (code) {
                                _this.projectCodeList.push({ project: proj, salaryCode: code });
                            });
                        });
                        this.getWeekData(this.currentYear, this.currentWeekNumber, this.empId);
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateProjectLineComponent.prototype.getWeekData = function (year, week, empId) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.timesheetProjectService.getWeekProjectDetails(year, week, empId).subscribe(function (res) {
                setTimeout(function () {
                    resolve(res);
                }, 2000);
                _this.createProjectLines(res);
            });
        });
    };
    CreateProjectLineComponent.prototype.createProjectLines = function (weekProjectsData) {
        var _this = this;
        this.weekProjects = [];
        this.projectList = this.projectCodeList;
        this.saveIndex = weekProjectsData['weekProjects'].length;
        if (this.saveIndex > 0) {
            weekProjectsData['weekProjects'].forEach(function (project) {
                var pName = _this.projects.filter(function (proj) { return proj.projNum == project.projectNumber; });
                var sName = project.salaryCode == "" ? [] : _this.salaryCodes.filter(function (code) { return code[0] == project.salaryCode; });
                _this.weekProjects.push({
                    projectNumber: pName[0].projNum,
                    projectName: pName[0].projName,
                    salaryCode: project.salaryCode == "" ? "100" : sName[0][0],
                    salaryCodeName: project.salaryCode == "" ? "Internt" : sName[0][1]
                });
            });
        }
        this.projectRows.clear();
        weekProjectsData.weekProjects.forEach(function (data, i) {
            _this.addProjectLineToFormArray();
            var dayInfo = [];
            data.projectHours.forEach(function (phours) {
                var dayOfWeek = phours.dayOfWeek;
                switch (dayOfWeek) {
                    case 1:
                        _this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue({
                            day7: phours.hoursWork,
                            day7comment: phours.comment,
                            day7salary: phours.generateSalary == "" || phours.generateSalary == "0" ? false : true,
                            day7unid: phours.unid,
                        }, { emitEvent: false });
                        break;
                    case 2:
                        _this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue({
                            day1: phours.hoursWork,
                            day1comment: phours.comment,
                            day1salary: phours.generateSalary == "" || phours.generateSalary == "0" ? false : true,
                            day1unid: phours.unid,
                        }, { emitEvent: false });
                        break;
                    case 3:
                        _this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue({
                            day2: phours.hoursWork,
                            day2comment: phours.comment,
                            day2salary: phours.generateSalary == "" || phours.generateSalary == "0" ? false : true,
                            day2unid: phours.unid,
                        }, { emitEvent: false });
                        break;
                    case 4:
                        _this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue({
                            day3: phours.hoursWork,
                            day3comment: phours.comment,
                            day3salary: phours.generateSalary == "" || phours.generateSalary == "0" ? false : true,
                            day3unid: phours.unid,
                        }, { emitEvent: false });
                        break;
                    case 5:
                        _this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue({
                            day4: phours.hoursWork,
                            day4comment: phours.comment,
                            day4salary: phours.generateSalary == "" || phours.generateSalary == "0" ? false : true,
                            day4unid: phours.unid,
                        }, { emitEvent: false });
                        break;
                    case 6:
                        _this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue({
                            day5: phours.hoursWork,
                            day5comment: phours.comment,
                            day5salary: phours.generateSalary == "" || phours.generateSalary == "0" ? false : true,
                            day5unid: phours.unid,
                        }, { emitEvent: false });
                        break;
                    case 7:
                        _this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue({
                            day6: phours.hoursWork,
                            day6comment: phours.comment,
                            day6salary: phours.generateSalary == "" || phours.generateSalary == "0" ? false : true,
                            day6unid: phours.unid,
                        }, { emitEvent: false });
                        break;
                }
            });
            _this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue({
                projectNumber: data.projectNumber,
                salaryCode: data.salaryCode == "" ? "100" : data.salaryCode,
            }, { emitEvent: false });
            _this.timesheetProjectForm['controls']['projectLine']['controls'][i].get('projectNumber')['disable']();
            _this.timesheetProjectForm['controls']['projectLine']['controls'][i].get('projectNumberfilter')['disable']();
            _this.timesheetProjectForm['controls']['projectLine']['controls'][i].get('salaryCode')['disable']();
            var index = _this.projectCodeList.findIndex(function (prow) { return prow['project']['projNum'] == data.projectNumber && prow['salaryCode'][0] == data.salaryCode; });
            _this.projectCodeList.splice(index, 1);
        });
    };
    CreateProjectLineComponent.prototype.getProjects = function () {
        var _this = this;
        var projList = new Subject();
        this.timesheetProjectService.getProjects().subscribe(function (res) {
            _this.projects = res;
            projList.next(res);
            // load the initial project list
            _this.filteredProjects.next(_this.projects);
            var initialProjectId = "0";
            if (_this.projects.length > 0)
                initialProjectId = _this.projects[0]['projNum'];
            for (var i = 0; i < _this.projectRows.length; i++) {
                _this.timesheetProjectForm['controls']['projectLine']['controls'][i]['controls']['projectNumber'].setValue(initialProjectId);
            }
        });
        return projList.asObservable();
    };
    CreateProjectLineComponent.prototype.getSalaryCodes = function () {
        var _this = this;
        var sCode = new Subject();
        this.timesheetProjectService.getSalaryCode().subscribe(function (res) {
            _this.salaryCodes = [];
            res.forEach(function (data) {
                var code = data.split(" ");
                _this.salaryCodes.push(code);
            });
            sCode.next(_this.salaryCodes);
        });
        return sCode.asObservable();
    };
    CreateProjectLineComponent.prototype.addCommentBox = function (fc, index) {
        console.log(fc);
        this.formFields.map(function (data) { data.visible = false; });
        this.rowVisible.fill(false);
        this.rowVisible[index] = true;
        fc.visible = true;
    };
    CreateProjectLineComponent.prototype.projectSelect = function (project) {
        this.selectableSalaryCode = this.projectCodeList.filter(function (pcode) {
            if (pcode['project']['projNum'] == project)
                return pcode;
        });
    };
    CreateProjectLineComponent.prototype.saveWeekData = function () {
        var _this = this;
        this.projectWeekData['year'] = this.currentYear;
        this.projectWeekData['weekNumber'] = this.currentWeekNumber;
        this.projectWeekData['empId'] = this.empId;
        var weekProjects = [];
        this.timesheetProjectForm.getRawValue().projectLine.forEach(function (data) {
            var pHours = [];
            for (var i = 1; i <= 7; i++) {
                var hoursWork = eval('data.day' + i).length == 0 ? 0 : eval('data.day' + i);
                var comment = eval('data.day' + i + 'comment') == 0 ? '' : eval('data.day' + i + 'comment');
                var salary = eval('data.day' + i + 'salary') == false ? '0' : '1';
                var unid = eval('data.day' + i + 'unid') == 0 ? '' : eval('data.day' + i + 'unid');
                var dt = moment().year(_this.currentYear).isoWeek(_this.currentWeekNumber);
                var daynumber = dt.startOf('isoWeek').isoWeekday(1).add('d', i - 1).toISOString();
                if (hoursWork !== 0) {
                    pHours.push({ 'unid': unid, 'dateEnd': daynumber, 'hoursWork': hoursWork, 'comment': comment, 'generateSalary': salary });
                }
            }
            weekProjects.push({ 'projectNumber': data.projectNumber, 'salaryCode': data.salaryCode, 'projectHours': pHours, 'emplId': _this.empId });
        });
        this.projectWeekData['weekProjects'] = weekProjects;
        if (this.timesheetProjectForm.valid) {
            this.timesheetProjectService.saveWeekProjectDetails(this.projectWeekData).subscribe(function (res) {
                _this.getWeekData(_this.currentYear, _this.currentWeekNumber, _this.empId);
                _this.snackbar.open('Lagring vellyket', '', { duration: 2000 });
            });
        }
    };
    CreateProjectLineComponent.prototype.addProjectLineDetails = function () {
        var _this = this;
        var projectLineFormGroup = this.fb.group({});
        this.formFields.forEach(function (field) {
            if (field.type == 'select') {
                projectLineFormGroup.addControl(field.formControl, _this.fb.control([], Validators.required));
                if (field.formControl == 'projectNumber') {
                    projectLineFormGroup.addControl(field.formControl + 'filter', _this.fb.control(''));
                }
            }
            else if (field.type == 'text') {
                projectLineFormGroup.addControl(field.formControl + 'comment', _this.fb.control([]));
                projectLineFormGroup.addControl(field.formControl + 'salary', _this.fb.control(false));
                projectLineFormGroup.addControl(field.formControl + 'unid', _this.fb.control([]));
                projectLineFormGroup.addControl(field.formControl, _this.fb.control([]));
            }
        });
        this.filteredProjects.next(this.projects);
        return projectLineFormGroup;
    };
    CreateProjectLineComponent.prototype.addProjectLineToFormArray = function () {
        this.projectRows.push(this.addProjectLineDetails());
        this.rowVisible.push(false);
    };
    Object.defineProperty(CreateProjectLineComponent.prototype, "projectRows", {
        get: function () {
            return this.timesheetProjectForm.get('projectLine');
        },
        enumerable: true,
        configurable: true
    });
    CreateProjectLineComponent.prototype.ngOnDestroy = function () {
        this._onDestroy.next();
        this._onDestroy.complete();
    };
    return CreateProjectLineComponent;
}());
export { CreateProjectLineComponent };
