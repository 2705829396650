var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AbstractDialogComponent } from '../../abstract-dialog/abstract-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SjaService } from '../../../sja/sja.service';
import { GpsService } from '../../../gps/gps.service';
import { RoadRefPipe } from '../../../road-ref.pipe';
import { MediaService } from '../../../shared/media.service';
var SjaAddSignDialogComponent = /** @class */ (function (_super) {
    __extends(SjaAddSignDialogComponent, _super);
    function SjaAddSignDialogComponent(dialogRef, data, sjaService, gpsService, mediaService, roadRefPipe) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.sjaService = sjaService;
        _this.gpsService = gpsService;
        _this.mediaService = mediaService;
        _this.roadRefPipe = roadRefPipe;
        _this.dialogFormGroup = new FormGroup({
            sign: new FormControl('', Validators.required),
            side: new FormControl(),
            meters: new FormControl(0, Validators.required),
            comment: new FormControl(),
            coordinates: new FormControl(),
            section: new FormControl(),
            subSection: new FormControl(),
            shortForm: new FormControl(),
            road: new FormControl()
        });
        _this.mediaService.getMedia().subscribe(function (media) {
            _this.isMobile = media.isMobileView;
        });
        return _this;
    }
    SjaAddSignDialogComponent.prototype.ngOnDestroy = function () {
    };
    SjaAddSignDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sjaService.getSignsIcons().subscribe(function (signs) {
            _this.signs = signs;
            if (_this.data.type === 'edit') {
                _this.title = 'Rediger skilt';
                _this.buttonLabel = "Endre";
                for (var i = 0; i < _this.signs.length; i++) {
                    if (_this.signs[i].value === _this.data.sign.id) {
                        _this.dialogFormGroup.get("sign").setValue(_this.signs[i]);
                        break;
                    }
                }
                _this.dialogFormGroup.get("sign").setValue(_this.data.sign.id);
                _this.dialogFormGroup.get("side").setValue(_this.data.sign.side);
                console.log("edit sidng: ", _this.data.sign.log[_this.data.sign.log.length - 1].position);
                _this.dialogFormGroup.get("shortForm").setValue(_this.data.sign.log[_this.data.sign.log.length - 1].position.shortForm);
            }
            else if (_this.data.type === 'new') {
                _this.title = "Nytt skilt";
                _this.buttonLabel = "Legg til";
                _this.getCurrentGpsPos();
            }
        });
    };
    SjaAddSignDialogComponent.prototype.getCurrentGpsPos = function () {
        this.currentPos = this.gpsService.getCurrentPos();
        if (this.currentPos && this.currentPos.gpsData) {
            this.dialogFormGroup.get('coordinates').setValue([this.currentPos.gpsData.lat, this.currentPos.gpsData.lon]);
            var roadRef = this.currentPos.roadRef;
            if (roadRef) {
                this.dialogFormGroup.get('meters').setValue(roadRef.meter);
                this.dialogFormGroup.get('section').setValue(roadRef.section);
                this.dialogFormGroup.get('subSection').setValue(roadRef.subSection);
                this.dialogFormGroup.get('shortForm').setValue(roadRef.shortForm);
                this.dialogFormGroup.get('road').setValue(roadRef.shortForm.split(" ")[0]);
            }
            else {
                this.dialogFormGroup.get('shortForm').setValue("");
                this.dialogFormGroup.get('section').setValue("");
                this.dialogFormGroup.get('road').setValue("");
            }
        }
    };
    SjaAddSignDialogComponent.prototype.confirm = function (event) {
        this.dialogRef.close(this.dialogFormGroup.value);
    };
    return SjaAddSignDialogComponent;
}(AbstractDialogComponent));
export { SjaAddSignDialogComponent };
