import {MeasuringPoint} from './measuring-point';
import {VerifyArticlesPoint} from './verify-articles-point';
import {AllotmentHeader} from './allotment-header';

export class Allotment {
  public static STATE_REGISTRATION_COMPLETE = 4;
// "Parsell nr 1"
  title: string;
// unid fra Rekkverk Ordre
  unid: string;
// "Ikke påbegynt", "I arbeid", "Ferdig registrert"
  state: number;
// docId fra Rekkverk Ordre
  docId: string;
// "V", "H"
  sideOfRoad: string;
// Lengde, fra Rekkverk ordre, målt opp av selger
  lengthFromOrder: string;
// Navn på kunde"
  customer: string;
// Rekkverkstype
  rtType: string;
// Endetype, start
  startType: string;
// Endetype, slutt
  endType: string;
  isComplete: boolean;
  isStarted: boolean;
// Bruker pålogget Målepunkt-app
  user: string;
// Målepunkter registrert
  points: Array<MeasuringPoint>;
// Tellepunkter registrert
  verifyArticlesPoints: Array<VerifyArticlesPoint>;
// Parsell er kun riving
  demolition: boolean;
// Rekkverkstype riving
  demolitionType: string;
// Rekkverk riving length
  demolitionLength:string;
// Number of damaged rail 
  demolitionRailWreck: string;
// Number of damaged sticks
  demolitionStickWreck: string;
// Sluttende registreres først
  stopEndFirst: boolean;
  appVersion: string;
  appUpdated: string;
// @Deprecated
  worstPointFixQuality: number;
// @Deprecated
  worstPointHdop: number;
// Verdi som beregnes ut fra kvaliteten i det målepunktet med dårligst kvalitet
  quality: number;
// Målepunkt-id (uniqueId) for hvor stikkprøve ble gjennomført.
  qualityAuditLocationId: string;
// Er stikkprøve utført. Kun ett spørsmål pr. parsell
  qualityQuestionAnswered: boolean;
// Nummeret på spørsmålet
  qualityQuestion: string;
// Stikkprøve svar. "Ja" eller "Nei" for `questionType === 1`. tall (f.eks "75" eller "2") for `questionType === 2`
  qualityAnswer: string;
// Spørsmålstekst ved tidspunkt for stikkprøve
  qualityQuestionText: string;
// Dato for stikkprøve
  qualityQuestionCompleted: Date;
  isGetac: string;
// unid fra Rekkverk ordre
  orderUnid: string;
// ordreNum ("33WTF34-KMOE") fra Rekkverk ordre
  orderNum: string;
// Parsellen er til etterregistrering (styrer valg i skjermbildet)
  afterReg: boolean;
// Kode for vegens "hovedparsell"
  roadSection: number;
  invoiceType: string;
// Sist lagret
  allotmentLastSaved: Date;
  distance: number;
  startVegKortform: string;
  sluttVegKortform: string;
  compositeArticles: Array<object>;
  constructor(allotmentHeaderOrUnid?: AllotmentHeader|string) {
    if (allotmentHeaderOrUnid instanceof AllotmentHeader) {
      const allotmentHeader: AllotmentHeader =  <AllotmentHeader>allotmentHeaderOrUnid;
      this.title = allotmentHeader.title;
      this.unid = allotmentHeader.unid;
    } else {
      this.unid = <string>allotmentHeaderOrUnid;
    }
    this.points = [];
  }
}
