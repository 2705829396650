<div *ngIf="loading" class="loading">
  <div>Vennligst vent mens Oppgaver hentes</div>
  <mat-spinner></mat-spinner>
</div>
<div class="taskList-container">
  <div class="taskList-header">
    <button mat-flat-button color="primary" (click)="createWorkshopTask()"> Ny Verkstedsoppgave </button>
    <mat-form-field class="week">
      <mat-label>Vis uke..</mat-label>
      <mat-select [(ngModel)]="selectedWeek" (selectionChange)="filterWeek($event)">
        <mat-option *ngFor="let week of weeks" [value]="week.value">
          {{week.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <table mat-table #table [dataSource]="tasks" *ngIf="tasks.length>0" [class.isMobile]="isMobile">
    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef> Tittel </th>
      <td mat-cell *matCellDef="let task">
        <span class="mobile-label">Tittel:</span>
        <a (click)="editTask(task)">
          <span style="cursor: pointer">{{task.subject}}</span>
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="vedlegg">
      <th mat-header-cell *matHeaderCellDef> Vedlegg </th>
      <td mat-cell *matCellDef="let task">
        <span class="mobile-label">Vedlegg:</span>
        <span><mat-icon *ngIf="task.imageName">attachment</mat-icon></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Tildelt </th>
      <td mat-cell *matCellDef="let task">
        <span class="mobile-label">Tildelt:</span>
        {{task.name}}
      </td>
    </ng-container>
    <ng-container matColumnDef="signedBy">
      <th mat-header-cell *matHeaderCellDef> Utført av </th>
      <td mat-cell *matCellDef="let task">
        <span class="mobile-label">Utført:</span>
        {{task.signedBy}}
      </td>
    </ng-container>
    <ng-container matColumnDef="dateEnd">
      <th mat-header-cell *matHeaderCellDef> Frist </th>
      <td mat-cell *matCellDef="let task">
        <span class="mobile-label">Frist:</span>
        {{task.dateEnd|date: 'dd.MM.yyyy'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Handling </th>
      <td mat-cell *matCellDef="let task">
        <span class="mobile-label">Handling:</span>
        <a (click)="deleteTask(task)">
          <mat-icon>clear</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="taskColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: taskColumns;" [class.completed]="row.status===9"></tr>
  </table>
</div>