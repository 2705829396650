export class Article {
// Enhet, "Stk", "m"
  unit: string;

// Byggelengde
  length: number;
  rtTypes: Array<string>;

// Navn (fra artikkelregisteret)
  name: string;

// Id (fra artikkelregisteret)
  id: string;
// Kategori (fra artikkelregisteret)
  cat2: string;

  category: Array<string>;

// Manuell artikkel (arbeid)
  humanWork: boolean;

// Antall foreslått av artikkelberegningen
  quantitySuggested: number;

// Antall nye
  quantityNew: number;

// Antall gamle/gjenbrukte
  quantityOld: number;

// Totalt antall
  quantityTotal: number;

// "R" for riv
  origin: string;

// hentes fra artikkelregisteret til bruk i beregningen
  computeType: string;
}
