import 'zone.js/dist/zone-mix';
import 'reflect-metadata';
import '../polyfills';
import { BrowserModule } from '@angular/platform-browser';
import {NgModule, ErrorHandler} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { RoadRefPipe } from './road-ref.pipe';

import { WebsocketService } from './websocket.service';
import { HomeComponent } from './components/home/home.component';
import {RouterModule, Routes} from '@angular/router';
import {MeasureComponent} from './components/measure/measure.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {ArticlesComponent} from './components/articles/articles.component';
import {RailingDialogComponent} from './components/railing-dialog/railing-dialog.component';
import {CountDialogComponent} from './components/count-dialog/count-dialog.component';
import {HeaderComponent} from './components/header/header.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import { HttpRequestInterceptor } from './http-request-interceptor';
import {WebcamModule} from 'ngx-webcam';

import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogRef
} from '@angular/material';
import {CdkTableModule} from '@angular/cdk/table';
import {GeoService} from './geo.service';
import { ParcelsComponent } from './components/parcels/parcels.component';
import { SavePointDialogComponent } from './components/save-point-dialog/save-point-dialog.component';
import {DataService} from './data.service';
import { OrderComponent } from './components/order/order.component';
import {NaviService} from './navi.service';
import { OrderStatusPipe } from './order-status.pipe';
import { NotImplementedDialogComponent } from './components/not-implemented-dialog/not-implemented-dialog.component';
import { LoginComponent } from './components/login/login.component';
import {UserService} from './user.service';
import {MeasureService} from './measure.service';
import { AddArticleDialogComponent } from './components/add-article-dialog/add-article-dialog.component';
import { MeasureSnackbarComponent } from './components/measure-snackbar/measure-snackbar.component';
import { LengthConfirmedSnackbarComponent } from './components/length-confirmed-snackbar/length-confirmed-snackbar.component';
import { ReplicateComponent } from './components/replicate/replicate.component';
import { CompositeArticlesComponent } from './components/composite-articles/composite-articles.component';
import { SelectArticleFormComponent } from './components/select-article-form/select-article-form.component';
import { LoggerService } from '../environments/environment';
import { IsiErrorHandler } from './log/isi-error-handler';
import { ConfirmDeletePointDialogComponent } from './components/confirm-delete-point-dialog/confirm-delete-point-dialog.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MapComponent } from './components/map/map.component';
import { RailingCategoriesSelectComponent } from './components/railing-categories-select/railing-categories-select.component';
import { SjaArchiveComponent } from './components/sja/sja-archive/sja-archive.component';
import { SjaComponent } from './components/sja/sja/sja.component';
import { SjaEditComponent } from './components/sja/sja-edit/sja-edit.component';
import {QualityService} from './quality.service';
import { QualityAuditDialogComponent } from './components/quality-audit-dialog/quality-audit-dialog.component';
import { TimeSheetModule } from './time-sheet/time-sheet.module';
import { TimesheetProjectModule } from './timesheet-project/timesheet-project.module';
import { SharedModule } from './shared/shared.module';
import { InfoPageModule } from './info-page/info-page.module';
import { StorageModule } from './storage/storage.module'
import { SjaRiskFactorsComponent } from './components/sja/sja-risk-factors/sja-risk-factors.component';
import { SjaRiskFactorsDialogComponent } from './components/sja/sja-risk-factors-dialog/sja-risk-factors-dialog.component';
import { SjaSignsLogComponent } from './components/sja/sja-signs-log/sja-signs-log.component';
import { SjaAddSignDialogComponent } from './components/sja/sja-add-sign-dialog/sja-add-sign-dialog.component';

import { CanDeactivateGuard } from './can-deactivate.guard';
import { SignStatusPipe } from './sja/sign-status.pipe';
import { SignPipe } from './sja/sign.pipe';
import { SjaDayComponent } from './components/sja/sja-day/sja-day.component';
import { SignsRequirePipe } from './sja/signs-require.pipe';
import { WorkshopTaskDialogComponent } from './components/workshop-task-dialog/workshop-task-dialog.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { OrderCommentDialogComponent } from './components/order/order-comment-dialog/order-comment-dialog.component';
import { SettingsService } from './settings.service';
import { DownloadProgressComponent } from './components/download-progress/download-progress.component';
import { QualityBarsComponent } from './components/quality-bars/quality-bars.component';
import { ContactSheetComponent } from './components/contact-sheet/contact-sheet.component';
import { ParcelsTableComponent } from './components/parcels-table/parcels-table.component';
import { IconsModule } from './icons/icons.module';
import { CulvertsDialogComponent } from './components/culverts-dialog/culverts-dialog.component';
import { CulvertsComponent } from './components/culverts/culverts.component';
import { RoadInformationComponent } from './components/road-information/road-information.component';
import { CompositeArticleSheetComponent } from './components/composite-article-sheet/composite-article-sheet.component';
import { TeamResourcesComponent } from './components/team-resources/team-resources.component';
import { ProposalsComponent } from './components/proposals/proposals.component';
import { ProposalDetailComponent } from './components/proposals/proposal-detail/proposal-detail.component';
import { CreateNewProposalComponent } from './components/proposals/create-new-proposal/create-new-proposal.component';
import { StorageDetailsComponent } from './storage/storage-details/storage-details.component';
import { CreateNewStorageComponent } from './storage/create-new-storage/create-new-storage.component';
import { TeamMapComponent } from './components/team-map/team-map.component';
import { OrderApprovalComponent } from './components/order/order-approval/order-approval.component';
import { OrderApprovalArticlesComponent } from './components/order/order-approval-articles/order-approval-articles.component';
import { MobileDialogComponent } from './components/sja/mobile-dialog/mobile-dialog.component';
import { RegisterParsellDialogComponent } from './components/register-parsell-dialog/register-parsell-dialog.component';
import { CameraComponent } from './components/camera/camera.component';
import { CameraDialogComponent } from './components/camera-dialog/camera-dialog.component';
import { AuthGuard } from './auth.guard';
import { MapService } from './map.service';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const appRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'order/:orderUnid',
    component: OrderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'measure/:orderUnid/:parcelId',
    component: MeasureComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'measure/:orderUnid',
    component: ParcelsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'measure',
    component: MeasureComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'parcels',
    component: ParcelsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'replicate',
    component: ReplicateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sja/:orderUnid',
    component: SjaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ':orderUnid/approve',
    component: OrderApprovalComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tasks',
    component: TasksComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'teammap',
    component: TeamMapComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order/:orderUnid/teammap',
    component: TeamMapComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'camera',
    component: CameraComponent
  },
  { path: '**', component: HomeComponent,
  canActivate: [AuthGuard] }
];

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HomeComponent,
    MeasureComponent,
    HeaderComponent,
    CountDialogComponent,
    RailingDialogComponent,
    ArticlesComponent,
    RoadRefPipe,
    ParcelsComponent,
    SavePointDialogComponent,
    OrderComponent,
    OrderStatusPipe,
    NotImplementedDialogComponent,
    LoginComponent,
    AddArticleDialogComponent,
    MeasureSnackbarComponent,
    LengthConfirmedSnackbarComponent,
    ReplicateComponent,
    CompositeArticlesComponent,
    SelectArticleFormComponent,
    ConfirmDeletePointDialogComponent,
    MapComponent,
    RailingCategoriesSelectComponent,
    SjaArchiveComponent,
    SjaComponent,
    SjaEditComponent,
    QualityAuditDialogComponent,
    SjaRiskFactorsComponent,
    SjaRiskFactorsDialogComponent,
    SjaSignsLogComponent,
    SjaAddSignDialogComponent,
    SignStatusPipe,
    SignPipe,
    SjaDayComponent,
    SignsRequirePipe,
    WorkshopTaskDialogComponent,
    OrderCommentDialogComponent,
    DownloadProgressComponent,
    QualityBarsComponent,
    ContactSheetComponent,
    ParcelsTableComponent,
    CulvertsDialogComponent,
    CulvertsComponent,
    RoadInformationComponent,
    CompositeArticleSheetComponent,
    ProposalDetailComponent,
    CreateNewProposalComponent,
    StorageDetailsComponent,
    CreateNewStorageComponent,
    TeamMapComponent,
    OrderApprovalComponent,
    OrderApprovalArticlesComponent,
    MobileDialogComponent,
    RegisterParsellDialogComponent,
    CameraComponent,
    CameraDialogComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    TimeSheetModule,
    TimesheetProjectModule,
    InfoPageModule,
    StorageModule,
    IconsModule,
    WebcamModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false, useHash: true } // <-- debugging purposes only
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    LeafletModule.forRoot()
  ],
  providers: [WebsocketService, GeoService, RoadRefPipe, DataService, NaviService, OrderStatusPipe,
    UserService, MeasureService, LoggerService, QualityService, SettingsService, MapService,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true, disableClose: true}},
    {provide: MatDialogRef, useValue: {}},
    { provide: ErrorHandler, useClass: IsiErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [CountDialogComponent, RailingDialogComponent, SavePointDialogComponent, NotImplementedDialogComponent,RegisterParsellDialogComponent,
    AddArticleDialogComponent, MeasureSnackbarComponent, LengthConfirmedSnackbarComponent,
    ConfirmDeletePointDialogComponent, QualityAuditDialogComponent, SjaRiskFactorsDialogComponent, SjaAddSignDialogComponent,
    WorkshopTaskDialogComponent, OrderCommentDialogComponent, CulvertsDialogComponent, ProposalDetailComponent,
    CreateNewProposalComponent, StorageDetailsComponent, CreateNewStorageComponent, OrderApprovalArticlesComponent, MobileDialogComponent,CameraDialogComponent]
})
export class AppModule { }
