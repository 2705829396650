import { OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../data.service';
import { take } from 'rxjs/internal/operators/take';
import { ArtNumber } from '../../../art-number.enum';
var OrderLineDialogComponent = /** @class */ (function () {
    function OrderLineDialogComponent(data, dialogRef, dataService) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.dataService = dataService;
        this.ARTNUMBER_ORDER = ArtNumber.ORDER;
        this.ARTNUMBER_PROJECT = ArtNumber.PROJECT;
        this.orders = [];
        this.projects = [];
        this.members = [];
        this.now = new Date();
        this.artNumbers = [
            { value: ArtNumber.ORDER, label: 'Akkordønn' }, { value: ArtNumber.PROJECT, label: 'Timearbeid' }
        ];
        this.projectCtrl = new FormControl('', [Validators.required]);
        this.orderCtrl = new FormControl('', [Validators.required]);
        this.formGroup = new FormGroup({
            'members': new FormControl('', [Validators.required]),
            'artNumber': new FormControl('', [Validators.required]),
            'speedHours': new FormControl(0, [Validators.min(0)]),
            'comment': new FormControl('', [])
        });
        this.formGroup.get('comment').disable();
        // adds only one of the controlles for validation purposes.
        if (this.data && this.data.artNumber.value === ArtNumber.PROJECT) {
            this.formGroup.addControl('project', this.projectCtrl);
        }
        else if (this.data && this.data.artNumber.value === ArtNumber.ORDER) {
            this.formGroup.addControl('order', this.orderCtrl);
        }
        if (!data.selectedWeekDay) {
            this.speedHours.disable();
        }
    }
    OrderLineDialogComponent.prototype.speedHourChange = function (event) {
        if (this.formGroup.get('speedHours').value > 0) {
            this.formGroup.get('comment').enable();
        }
        else {
            this.formGroup.get('comment').disable();
        }
        if (this.formGroup.get('project') && this.formGroup.get('speedHours').value > 0) {
            this.formGroup.get('comment').setValidators([Validators.required]);
            this.formGroup.get('comment').markAsTouched();
        }
        else {
            this.formGroup.get('comment').setValidators([]);
        }
        this.formGroup.get('comment').updateValueAndValidity();
    };
    OrderLineDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setDefaultArtNumber();
        if (this.data && this.data.artNumber.value === ArtNumber.ORDER) {
            this.dataService.getOrders().pipe(take(1)).subscribe(function (orders) {
                _this.orders = orders;
                _this.setDefaultOrder();
            });
        }
        else if (this.data && this.data.artNumber.value === ArtNumber.PROJECT) {
            this.dataService.getProjects().pipe(take(1)).subscribe(function (projs) {
                _this.projects = projs;
                _this.setDefaultProject();
            });
        }
        this.dataService.getTeamMembers().pipe(take(1)).subscribe(function (members) {
            _this.members = members;
        });
    };
    OrderLineDialogComponent.prototype.ngOnDestroy = function () {
    };
    OrderLineDialogComponent.prototype.projectChanged = function (event) {
        this.getExistingWeekHour(this.projectCtrl.value);
    };
    OrderLineDialogComponent.prototype.orderChanged = function (event) {
        this.getExistingWeekHour(this.orderCtrl.value);
    };
    OrderLineDialogComponent.prototype.getExistingWeekHour = function (orderOrProject) {
    };
    OrderLineDialogComponent.prototype.save = function () {
        var selectedMembers = this.formGroup.get("members").value;
        var selectedartNumber = this.formGroup.get("artNumber").value;
        var speedHours = this.formGroup.get('speedHours').value;
        var comment = this.formGroup.get('comment').value;
        var res = {
            members: selectedMembers,
            artNumber: selectedartNumber,
            isNew: this.data.isNew,
            speedHours: speedHours,
            selectedWeekDay: this.data.selectedWeekDay,
            comment: comment
        };
        if (this.data && this.data.artNumber.value === ArtNumber.ORDER) {
            res.order = this.formGroup.get("order").value;
        }
        else if (this.data && this.data.artNumber.value === ArtNumber.PROJECT) {
            res.project = this.formGroup.get("project").value;
        }
        this.dialogRef.close(res);
    };
    OrderLineDialogComponent.prototype.setDefaultArtNumber = function () {
        var _this = this;
        if (this.data && this.data.artNumber) {
            var existingArtNumbers = this.artNumbers.filter(function (a) {
                return a.value === _this.data.artNumber.value;
            });
            if (existingArtNumbers && existingArtNumbers.length > 0) {
                this.artNumberFormCtrl.setValue(existingArtNumbers[0]);
            }
        }
    };
    OrderLineDialogComponent.prototype.setDefaultProject = function () {
        var foundProject = false;
        if (this.data && this.data.project) {
            for (var _i = 0, _a = this.projects; _i < _a.length; _i++) {
                var project = _a[_i];
                if (project.projNum === this.data.project.projNum) {
                    foundProject = true;
                    this.projectCtrl.setValue(project);
                }
            }
        }
        if (!foundProject) {
            this.projects.push(this.data.project);
            this.projectCtrl.setValue(this.data.project);
        }
    };
    OrderLineDialogComponent.prototype.setDefaultOrder = function () {
        var foundOrder = false;
        if (this.data && this.data.order) {
            for (var _i = 0, _a = this.orders; _i < _a.length; _i++) {
                var order = _a[_i];
                if (order.orderNum === this.data.order.orderNum) {
                    foundOrder = true;
                    this.orderFormCtrl.setValue(order);
                }
            }
        }
        if (!foundOrder) {
            this.orders.push(this.data.order);
            this.orderFormCtrl.setValue(this.data.order);
        }
    };
    Object.defineProperty(OrderLineDialogComponent.prototype, "membersFormCtrl", {
        get: function () {
            return this.formGroup.get("members");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderLineDialogComponent.prototype, "orderFormCtrl", {
        get: function () {
            return this.formGroup.get("order");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderLineDialogComponent.prototype, "artNumberFormCtrl", {
        get: function () {
            return this.formGroup.get("artNumber");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderLineDialogComponent.prototype, "speedHours", {
        get: function () {
            return this.formGroup.get("speedHours");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderLineDialogComponent.prototype, "comment", {
        get: function () {
            return this.formGroup.get("comment");
        },
        enumerable: true,
        configurable: true
    });
    return OrderLineDialogComponent;
}());
export { OrderLineDialogComponent };
var OrderLineDialogData = /** @class */ (function () {
    function OrderLineDialogData() {
    }
    return OrderLineDialogData;
}());
export { OrderLineDialogData };
