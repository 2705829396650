var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Type } from '@angular/core';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
var RailingDialogComponent = /** @class */ (function (_super) {
    __extends(RailingDialogComponent, _super);
    function RailingDialogComponent(dialogRef, data, http, formBuilder) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.http = http;
        _this.formBuilder = formBuilder;
        return _this;
    }
    RailingDialogComponent.prototype.ngOnInit = function () {
        this.dialogFormGroup = new FormGroup({ '': new FormControl('', []) });
    };
    RailingDialogComponent.prototype.confirm = function (event) {
        this.dialogRef.close({
            railingType: this.railingCatSelectComponent.typeFormControl.value,
            type: RailingDialogComponent,
            isDemolition: this.data.isDemolition
        });
    };
    return RailingDialogComponent;
}(AbstractDialogComponent));
export { RailingDialogComponent };
