var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { MatDialogRef, MatTabChangeEvent } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
var CountDialogComponent = /** @class */ (function (_super) {
    __extends(CountDialogComponent, _super);
    function CountDialogComponent(dialogRef, data) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.confirmDemolitionPoint = false;
        _this.confirmCountPoint = false;
        _this.selectedIndex = 0;
        _this.dialogFormGroup = new FormGroup({
            'demolitionCheckboxForm': new FormControl('', []),
            'countCheckboxForm': new FormControl('', [])
        });
        return _this;
    }
    CountDialogComponent.prototype.ngOnInit = function () {
        // TODO (john) set the selected Index to the tab that makes sense. If demolition only, select tab2, if measure only, select tab 1.
    };
    CountDialogComponent.prototype.confirm = function (event) {
        if (this.dialogFormGroup.controls.demolitionCheckboxForm.value) {
            // TODO (john) do some logic
        }
        if (this.dialogFormGroup.controls.countCheckboxForm.value) {
            // TODO (john) do some logic
        }
        this.dialogRef.close(this.data);
    };
    CountDialogComponent.prototype.onTabChanged = function (event) {
        this.selectedIndex = event.index;
        switch (event.index) {
            case 0:
                this.txtConfirmBtn = 'Bekreft monteringsmateriell';
                break;
            case 1:
                this.txtConfirmBtn = 'Bekreft rivingsmateriell';
                break;
            default:
                break;
        }
    };
    return CountDialogComponent;
}(AbstractDialogComponent));
export { CountDialogComponent };
