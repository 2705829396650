import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservableLike, Subject, forkJoin } from 'rxjs';
import { IsiWeek } from './isi-week';
import { map, switchMap } from 'rxjs/operators';
import { WeekDay } from '@angular/common';
import { IsiWeekDay } from './isi-week-day';
import { IsiWeekHour } from './isi-week-hour';
import { weekdaysShort } from 'moment';
import { UserService } from './user.service';
import { AppConfig } from '../environments/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeSheetService {
  apiUrl: string;
  constructor(private http: HttpClient, private userService: UserService) {
    this.apiUrl = AppConfig.apiUrl;
  }
  getWeek(team: string, year: number, weekNumber: number){
    return this.http.get<any>(`${this.apiUrl}/hours/weeks?team=${team}&year=${year}&week=${weekNumber}`)
    .pipe(map(res => res.data));
  }
  getWeekInfo(...unid: Array<string>): Observable<any[]> {
    let response = [];
    let i = 0;
    unid.forEach(id => {
      response[i] = this.getWeekDay(id);
      i++;
    })
    return forkJoin([...response]);
  }
  getWeekHour(unid: string): Observable<IsiWeekHour> {
    return this.http.get<any>(`${this.apiUrl}/hours/weeks/hours/${unid}`)
      .pipe(map(res => {
        return res.data;
      }));
  }
  getWeekDay(unid: string): Observable<IsiWeekDay> {
    return this.http.get<any>(`${this.apiUrl}/hours/weeks/days/${unid}`)
      .pipe(map(res => {
        return res.data;
      }));
  }
  saveWeekDay(weekday: IsiWeekDay): Observable<IsiWeekDay> {
    if (!weekday.teamId) {
      weekday.teamId = this.userService.teamId;
    }
    return this.http.post<{data: IsiWeekDay, meta: any}>(`${this.apiUrl}/hours/weeks/days`, weekday)
      .pipe(map(res => {
        return res.data;
      }, error => {
        return error;
      }));
  }
  saveWeekHour(weekHour: IsiWeekHour): Observable<IsiWeekHour> {
    if (!weekHour.teamId) {
      weekHour.teamId = this.userService.teamId;
    }
    return this.http.post<{ data: IsiWeekHour, meta: any }>(`${this.apiUrl}/hours/weeks/hours`, weekHour)
      .pipe(map(res => {
        return res.data;
      }, error => {
        return error;
      }));
  }
  /**
   * 
   * @param orderNum {string} example orderId: BB396E-RSEH
   */
  getWeekHoursByOrderId(orderNum: string): Observable<Array<IsiWeekHour>> {
    return this.http.get<{ data: Array<IsiWeekHour>, meta: any }>(`${this.apiUrl}/hours/weeks/orders/` + orderNum)
      .pipe(map(res => {
        return res.data;
      }, error => {
        return error;
      }));
  }

  getHotels(qry: string): Observable<Array<any>> {
    let username = this.userService.commonName;
    let password = this.userService.password;
    if (AppConfig.environment === 'JER') {
      username = AppConfig.username;
      password = AppConfig.password;
    }
    const headers = new HttpHeaders({ 'Authorization': 'Basic ' + btoa(username + ':' + password)})
    return this.http.get<any>(AppConfig.ibricksApi + `/services/org/${qry}?orgId=F1AAA&nace=55`, {headers: headers});
  }
  getHotel(unid: string): Observable<any> {
    let username = this.userService.commonName;
    let password = this.userService.password;
    if (AppConfig.environment === 'JER') {
      username = AppConfig.username;
      password = AppConfig.password;
    }
    const headers = new HttpHeaders({ 'Authorization': 'Basic ' + btoa(username + ':' + password) })
    return this.http.get<any>(AppConfig.ibricksApi + `/services/org/${unid}?orgId=F1AAA`, { headers: headers }).
      pipe(map(res => res.data));
  }
  /**
   * Cannot edit weekDay for the previous month and earlier if the date has passed the 20th.
   * Can still edit weekDay of previous month if date has NOT passed the 20th.
   */
  canEditWeekHour(selectedDay: moment.Moment): boolean {
    const firstDayOfSelectedDayMonth = selectedDay.clone().startOf('month');
    const today = moment().startOf('isoWeek');
    if (today.date() >= 20) {
      return (firstDayOfSelectedDayMonth.add(1, 'month')).isAfter(today);
    } else {
      return (firstDayOfSelectedDayMonth.add(2, 'month')).isAfter(today);
    }
  }
}
