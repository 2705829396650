<div class="home mat-typography" [class.mobile-view]="isMobileView">
	<mat-accordion class="home-card" multi="true">
		<mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<h2>Oppgaver <span> (verksted Lag {{userService.teamId}}) </span></h2>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<app-tasks></app-tasks>
		</mat-expansion-panel>
		<mat-expansion-panel [expanded]="step === 2">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<h2>Oversikt Ressurser <span>(Lag {{userService.teamId}})</span> </h2>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<app-team-resources></app-team-resources>
		</mat-expansion-panel>
		<mat-expansion-panel [expanded]="step === 3">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<h2> Forbedringsforslag </h2>
				</mat-panel-title>
			</mat-expansion-panel-header>			
			<app-proposals></app-proposals>
		</mat-expansion-panel>
	</mat-accordion>
</div>
<div class="home mat-typography" [class.mobile-view] = "isMobileView">
	<mat-card class="home-card news-container">
		<span class="date">{{ defaultNews?.docDate | date:'dd.MM.yyyy'}}</span>
		<h2> {{ defaultNews?.subject }}</h2>
		<p [innerHTML] = "defaultNews?.body"></p>
		<mat-form-field>
			<mat-select matNativeControl [formControl]="selectFormControl" [(value)]="selected" (selectionChange)="changeDefaultNews(selected)">
				<mat-option *ngFor = "let newsItem of news; let i=index" [value]= "i">
					{{ newsItem?.subject }}
				</mat-option>
			</mat-select>
		</mat-form-field>  
	</mat-card>
</div>