import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {QualityAudit} from './quality-audit';
import {Observable} from 'rxjs/internal/Observable';
import { AppConfig } from '../environments/environment';

@Injectable()
export class QualityService {
  apiUrl;
  constructor(private http: HttpClient) {
    this.apiUrl = AppConfig.apiUrl;
  }

  getQualityAudits(): Observable<Array<QualityAudit>> {
    const url = `${this.apiUrl}/quality/audits`;
    return this.http.get<any>(url).pipe(map(res => {
      if (res) {
        return res.data;
      }
    }));
  }
  getRandomQuestion(): Observable<{num: number, qualityAudit: QualityAudit}> {
    return this.getQualityAudits().pipe(map(data => {
      if (data) {
        const questionNum = Math.floor(Math.random() * Math.floor(data.length));
        return {num: questionNum, qualityAudit: data[questionNum]};
      }
      return null;
    }));
  }

}
