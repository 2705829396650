import {DivIcon, Icon, MarkerOptions, } from 'leaflet';
import * as L from 'leaflet';
import {GPS_QUALITY} from './gps/gps-quality';
import {POINT_STATUS} from './point-status';

export class IconFactory {
  static greenIcon = 'assets/green-icon.png';
  static redIcon = 'assets/red-icon.png';
  static orangeIcon = 'assets/orange-icon.png';
  static greenDemolitionIcon = 'assets/green-icon-demolition.png';
  static redDemolitionIcon = 'assets/red-icon-demolition.png';
  static orangeDemolitionIcon = 'assets/orange-icon-demolition.png';
  static greenDemolitionAndMeasureIcon = 'assets/green-icon-demolition-measure.png';
  static orangeDemolitionAndMeasureIcon = 'assets/orange-icon-demolition-measure.png';
  static redDemolitionAndMeasureIcon = 'assets/red-icon-demolition-measure.png';

  static create(satQuality: number, pointStatus: number, isCountingPoint?: boolean): MarkerOptions {
    let iconUrl = this.redIcon;
    if (pointStatus % 10 === POINT_STATUS.DEMOLITION_POINT ) {
      iconUrl = this.redDemolitionIcon;
      if (satQuality === GPS_QUALITY.GOOD) { iconUrl = this.orangeDemolitionIcon; }
      if (satQuality === GPS_QUALITY.BEST) { iconUrl = this.greenDemolitionIcon; }
    } else if (pointStatus % 10 === POINT_STATUS.DEMOLITION_AND_MEASURE_POINT) {
      iconUrl = this.redDemolitionAndMeasureIcon;
      if (satQuality === GPS_QUALITY.GOOD) { iconUrl = this.orangeDemolitionAndMeasureIcon; }
      if (satQuality === GPS_QUALITY.BEST) { iconUrl = this.greenDemolitionAndMeasureIcon; }
    } else {
      if (satQuality === GPS_QUALITY.GOOD) { iconUrl = this.orangeIcon; }
      if (satQuality === GPS_QUALITY.BEST) { iconUrl = this.greenIcon; }
    }
    const icon: DivIcon = L.divIcon({
      html: `
<div class="icon-container">
  <img src="${iconUrl}">
   ${isCountingPoint ? `<label>T</label>` : ''}
</div>`,
      className: "simple-div-icon",
      iconSize:     [14, 14],
      iconAnchor:   [7, 7],
      popupAnchor:  [0, 0],
      bgPos:        [7, 7]
    });
    return {icon: icon, opacity: 0.8};
  }
}
