import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRippleModule, MatInputModule, MatFormFieldModule, MatButtonModule,
  MatIconModule, MatCheckboxModule, MatStepperModule, MatRadioModule, MatDialogModule,
  MatTabsModule, MatCardModule, MatTableModule, MatSlideToggleModule, MatSelectModule,
  MatExpansionModule, MatListModule, MatProgressBarModule, MatSnackBarModule, MatMenuModule,
  MatDatepickerModule, MAT_DATE_LOCALE, MatNativeDateModule, MatProgressSpinnerModule,
  MatBottomSheetModule, MatChipsModule, MatTooltipModule, MatAutocompleteModule, MatGridListModule} from '@angular/material';
import {MatSortModule} from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule, NG_VALIDATORS } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import localeNo from '@angular/common/locales/nb';
import { EmployeePickerComponent } from '../employee-picker/employee-picker.component';
import { RequiredIfOtherFieldsPresentDirective } from './directives/required-if-other-fields-present.directive';
import { CustomIncDecBtnsDirective } from './directives/custom-inc-dec-btns.directive';
import { DynamicButtonComponent } from './dynamic-button/dynamic-button.component';
import { DownloadProgressComponent } from '../components/download-progress/download-progress.component';
import { ContactSheetComponent } from '../components/contact-sheet/contact-sheet.component';
import { CompositeArticleSheetComponent } from '../components/composite-article-sheet/composite-article-sheet.component'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { YearInputComponent } from '../time-sheet/components/year-input/year-input.component';
import { WeekInputComponent } from '../time-sheet/components/week-input/week-input.component';
import { DayInputComponent } from '../time-sheet/components/day-input/day-input.component';

registerLocaleData(localeNo);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    BrowserAnimationsModule,
    MatRippleModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatStepperModule,
    MatRadioModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatProgressBarModule,
    MatSnackBarModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatStepperModule,
    MatBottomSheetModule,
    MatChipsModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSortModule,
    MatGridListModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    BrowserAnimationsModule,
    MatRippleModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatStepperModule,
    MatRadioModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatGridListModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    MatChipsModule,
    MatTooltipModule,
    MatAutocompleteModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    EmployeePickerComponent,
    CustomIncDecBtnsDirective,
    DynamicButtonComponent,
    YearInputComponent,
    WeekInputComponent,
    DayInputComponent,
    MatSortModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'nb-NO'},
    { provide: LOCALE_ID, useValue: 'nb' },
    { provide: NG_VALIDATORS, useExisting: RequiredIfOtherFieldsPresentDirective, multi: true }
  ],
  declarations: [EmployeePickerComponent, CustomIncDecBtnsDirective, DynamicButtonComponent,YearInputComponent,WeekInputComponent,DayInputComponent],
  entryComponents: [DynamicButtonComponent, DownloadProgressComponent, ContactSheetComponent, CompositeArticleSheetComponent]
})
export class SharedModule { }
