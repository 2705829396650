import {Component, Inject, Input, OnInit} from '@angular/core';
import {AbstractDialogComponent} from '../abstract-dialog/abstract-dialog.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material';
import {DataService} from '../../data.service';
import {CompositeArticle} from '../../composite-article';
import {Article} from '../../article';
import { MediaService } from '../../shared/media.service';

@Component({
  selector: 'app-add-article-dialog',
  templateUrl: './add-article-dialog.component.html',
  styleUrls: ['./add-article-dialog.component.scss']
})
export class AddArticleDialogComponent extends AbstractDialogComponent implements OnInit {
  dialogFormGroup: FormGroup;
  articles: Array<Article | CompositeArticle> = [];
  quantityFormControl: FormControl = new FormControl(1, [Validators.required, Validators.min(0)]);
  @Input() typeOfArticle = 'compositeArticle';
  editArticle: Article;
  isMobile: boolean;
  actionTitle = "Legg til";
  constructor(public dialogRef: MatDialogRef<AbstractDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private dataService: DataService, private mediaService: MediaService) {
    super(dialogRef);
    this.dialogFormGroup = new FormGroup({
      'quantityFormControl': this.quantityFormControl});
    if (data && data.typeOfArticle) {
      this.typeOfArticle = data.typeOfArticle;
    }
    this.mediaService.getMedia().subscribe(media => {
      this.isMobile = media.isMobileView;
    })

  }

  ngOnInit() {
    if (this.data.editArticle) {
      console.log('editArticle',JSON.stringify(this.editArticle));
      this.actionTitle = "Rediger";
      this.editArticle = new Article();
      this.editArticle.id = this.data.editArticle.artNum;
      this.editArticle.name = this.data.editArticle.artInfo[0];
      this.data.editArticle;  
      this.quantityFormControl.setValue(this.data.editArticle.count);
      
    }
  }
  confirm(event) {
    this.dialogRef.close({
      type: this.data.type,
      article: this.dialogFormGroup.get('articleFormControl').value,
      quantity: this.quantityFormControl.value,
      addType: this.typeOfArticle
    });
  }

}
