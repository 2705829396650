import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CulvertComponent } from './culvert/culvert.component';
import { BridgeComponent } from './bridge/bridge.component';
import { OrderArticleComponent } from './order-article/order-article.component';



@NgModule({
  declarations: [CulvertComponent, BridgeComponent, OrderArticleComponent],
  imports: [
    CommonModule
  ],
  exports: [CulvertComponent, BridgeComponent, OrderArticleComponent]
})
export class IconsModule { }
