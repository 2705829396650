import { Component, OnInit, Input,Output, OnDestroy, EventEmitter } from '@angular/core';
import { AllotmentHeader } from '../../allotment-header';
import { SjaDataSource } from '../../sja/sja-data-source';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../data.service';
import { MatSnackBar, MatDialog, MatDialogRef, MatBottomSheet } from '@angular/material';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Allotment } from '../../allotment';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { GpsService } from '../../gps/gps.service';
import { GpsData } from '../../gps-data';
import { take } from 'rxjs/operators';
import { CulvertsDialogComponent } from '../culverts-dialog/culverts-dialog.component';
import { CompositeArticleSheetComponent } from '../composite-article-sheet/composite-article-sheet.component';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-parcels-table',
  templateUrl: './parcels-table.component.html',
  styleUrls: ['./parcels-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ParcelsTableComponent implements OnInit, OnDestroy {
  @Input() orderUnid: string;
  @Output() allotmentsLoaded = new EventEmitter<Array<AllotmentHeader>>();
  @Output() selectParsellEmitter = new EventEmitter<Array<any>>();

  parcels: Array<AllotmentHeader>;
  dataSource: SjaDataSource;
  displayedColumns: Array<string> = [
    'parsellSelect','orderId', 'rtType', 'sideOfRoad', 'hpStart', 'startType', 'hpEnd',
    'endType', 'lengthFromOrder', 'riv', 'vrak', 'culverts'
  ];
  subject: BehaviorSubject<any>;
  sa: any;
  STATE_REGISTRATION_COMPLETE = Allotment.STATE_REGISTRATION_COMPLETE;
  gpsSubscription: Subscription;
  currentPos: GpsData = null;
  parcelClostestToCurrentPos: AllotmentHeader;
  parsellSelectFormGroup: FormGroup;
  parsellSelectCheckbox =  new FormArray([]);
  seletedParsells: Array<any> = [];
  constructor(
    private router: Router,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private gpsService: GpsService,
    private _bottomSheet: MatBottomSheet,
    private fb:FormBuilder) {
    this.subscribeToGps();
  }
  
  private subscribeToGps() {
    this.gpsSubscription = this.gpsService.getObservable().subscribe(gpsAndRoadRef => {
      this.currentPos = gpsAndRoadRef.gpsData;
      this.setClosestParcel();
    });
  }

  private setClosestParcel() {
    let closesParcel = null;
    let minLatLonDiff = Infinity;
    if (this.parcels) {
      this.parcels.forEach(p => {
        // TODO (john) create common method
        if (p && p.gpsEndPos && p.gpsEndPos.length >= 2) {
          const latLonDiff = Math.abs(
            parseFloat('' + p.gpsEndPos[0]) - this.currentPos.lat + parseFloat('' + p.gpsEndPos[1]) - this.currentPos.lon
          );
          if (latLonDiff < minLatLonDiff) {
            minLatLonDiff = latLonDiff;
            closesParcel = p;
          }
        }
        if (p && p.gpsStartPos && p.gpsStartPos.length >= 2) {
          const latLonDiff = Math.abs(
            parseFloat('' + p.gpsStartPos[0]) - this.currentPos.lat + parseFloat('' + p.gpsStartPos[1]) - this.currentPos.lon
          );
          if (latLonDiff < minLatLonDiff) {
            minLatLonDiff = latLonDiff;
            closesParcel = p;
          }
        }
      });
    }
    this.parcelClostestToCurrentPos = closesParcel;
  }
  ngOnInit() {
    this.subject = new BehaviorSubject(this.parcels);
    this.dataSource = new SjaDataSource(this.subject.asObservable());
    this.getParcels(this.orderUnid);
    this.getSaJson();
  }
  ngOnDestroy() {
    if (this.gpsSubscription) {
      this.gpsSubscription.unsubscribe();
    }
  }
  addParsellSelect(parcels) {
    this.parsellSelectFormGroup = this.fb.group({});
    parcels.forEach(element => {
      this.parsellSelectFormGroup.addControl(element.title,this.fb.control(''))
    });
  }
  showSAInfo(sa: any, rtType: string) {
    console.log("get info on sa", sa, rtType);
    this._bottomSheet.open(CompositeArticleSheetComponent, { data: {
      name: sa.name,
      quantity: sa.quantity,
      saNum: sa.saNum,
      rtType: rtType
    }});
  }
  getParcels(orderUnid: string) {
    this.dataService.getParcels(orderUnid).subscribe(parcels => {
      this.parcels = parcels
      this.subject.next(this.parcels);
      this.allotmentsLoaded.emit(this.parcels);
      // this.addParsellSelect(this.parcels);
    })
  }
  onParsellSelect(parcel,e) {
    if(e.checked) {
      this.seletedParsells.push({title:parcel.title,orderId:this.orderUnid,docId:parcel.docId});
    } else {
      const index = this.seletedParsells.findIndex(e => {if(e.docId == parcel.docId) return e })
      this.seletedParsells.splice(index,1);
    }
    this.selectParsellEmitter.emit(this.seletedParsells);
  }
  onParcelClick(parcel: AllotmentHeader) {
    const parcelId = parcel.unid || '';
    const urlTree = this.router.createUrlTree(['measure/', this.orderUnid, parcelId]);
    this.router.navigateByUrl(urlTree);
  }

  isUtelatt(parcel: AllotmentHeader) {
    if (parcel) {
       if (parcel.state === 0 || parcel.state === 2 || parcel.state === 3 || parcel.state === 10) {
         return true;
       }
    }
    return false;
  }

  newParcel() {
    this.dataService.newParcel(this.orderUnid).subscribe((newParcel: AllotmentHeader) => {
      this.parcels.push(newParcel);
      this.subject.next(this.parcels);
      this.snackBar.open(`Opprettet ny tom parsell nummer ${newParcel.title}`, '', { duration: 5000 });
    });
  }
  culvertClick(evt: MouseEvent, parcel: AllotmentHeader) {
    if (evt) {
      evt.stopPropagation();
    }
    this.openCulvertDialog(parcel);
  }
  culvertMouseDown(evt: MouseEvent) { // prevent ripples going to list item
    if (evt) {
      evt.stopPropagation();
    }
  }
  private openCulvertDialog(parcel: AllotmentHeader) {
    const dialogRef: MatDialogRef<CulvertsDialogComponent> = this.dialog.open(CulvertsDialogComponent, {
      data: {culverts: parcel.culverts},
      width: "80vw",
      height: "80vh"
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe( res => {
    })
  }
  private getSaJson() {
    this.dataService.getStructureArticles().subscribe(data => {
      this.sa = data;
    });
  }

}
