var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatSelect } from '@angular/material';
import { StorageService } from '../storage.service';
import { AbstractDialogComponent } from '../../components/abstract-dialog/abstract-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { map, takeUntil, take } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
var StorageDetailsComponent = /** @class */ (function (_super) {
    __extends(StorageDetailsComponent, _super);
    function StorageDetailsComponent(dialogRef, data, storageService, router) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.storageService = storageService;
        _this.router = router;
        _this.locationAvailable = false;
        _this.articleColumns = ['article', 'count', 'action'];
        _this.historyColumns = ['time', 'action', 'user'];
        _this.articles = []; // articles in the storage 
        _this.filteredArticles = new ReplaySubject(0);
        _this._onDestroy = new Subject();
        _this.gpsPos = [];
        _this.do_refresh = false;
        _this.storageId = data.storageId;
        _this.dialogFormGroup = new FormGroup({
            addArticleFormGroup: new FormGroup({
                articleSelect: new FormControl('', Validators.required),
                articleFilterCtrl: new FormControl(''),
                articleCount: new FormControl('', [Validators.required, Validators.min(1)]),
                comment: new FormControl('')
            })
        });
        return _this;
    }
    StorageDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.storageService.getStorage(this.storageId).subscribe(function (data) {
            _this.storage = data;
            _this.getLocation(_this.storage);
            _this.articles = data.articles;
            _this.articles.forEach(function (article) {
                var artNum = article.artNr;
                _this.dialogFormGroup.addControl(artNum, new FormControl(0, [Validators.required, Validators.max(article.antall), Validators.min(1)]));
            });
        });
        this.getArticleList();
        // listen for search field value changes
        this.dialogFormGroup.get('addArticleFormGroup.articleFilterCtrl').valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(function () {
            _this.filterArticles();
        });
    };
    StorageDetailsComponent.prototype.ngAfterViewInit = function () {
        this.setInitialValue();
    };
    StorageDetailsComponent.prototype.getName = function (element) {
        if (element.kommentar) {
            return element.kommentar;
        }
        else {
            return element.navn;
        }
    };
    /**
     * Sets the initial value after the filteredArticles are loaded initially
     */
    StorageDetailsComponent.prototype.setInitialValue = function () {
        var _this = this;
        this.filteredArticles
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(function () {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredArticles are loaded initially
            // and after the mat-option elements are available
            _this.singleSelect.compareWith = function (a, b) { return a && b && a.name === b.name; };
        });
    };
    StorageDetailsComponent.prototype.filterArticles = function () {
        if (!this.articleList)
            return;
        var search = this.dialogFormGroup.get('addArticleFormGroup.articleFilterCtrl').value;
        if (!search) {
            this.filteredArticles.next(this.articleList);
            return;
        }
        else {
            search = search.toLowerCase();
        }
        // filter the ArticleList
        this.filteredArticles.next(this.articleList.filter(function (article) { return article['name'].toLowerCase().indexOf(search) > -1 || article['id'].startsWith(search); }));
    };
    StorageDetailsComponent.prototype.isValid = function (controlName, antall) {
        return this.dialogFormGroup.get(controlName).value > 0 && this.dialogFormGroup.get(controlName).value <= antall;
    };
    StorageDetailsComponent.prototype.isValidLeggTil = function (controlName) {
        return this.dialogFormGroup.get(controlName).value > 0;
    };
    StorageDetailsComponent.prototype.getArticleList = function () {
        var _this = this;
        this.storageService.getArticleList().pipe(map(function (data) { return data.sort(function (a, b) { return a.id - b.id; }); }))
            .subscribe(function (articlesMap) {
            _this.articleList = articlesMap;
            // load the initial article list
            _this.filteredArticles.next(_this.articleList);
            // Set initial selection
            //this.dialogFormGroup.get('addArticleFormGroup.articleFilterCtrl').setValue(this.articleList[0]['id']  +"-"+ this.articleList[0]['name']);
        });
    };
    StorageDetailsComponent.prototype.addArticle = function () {
        var _this = this;
        var artNo = this.dialogFormGroup.get('addArticleFormGroup.articleSelect').value;
        var count = this.dialogFormGroup.get('addArticleFormGroup.articleCount').value;
        var comment = this.dialogFormGroup.get('addArticleFormGroup.comment').value;
        var art = this.articleList.find(function (article) { return article['id'] === artNo; });
        var artName = art['name'];
        var artIndex = -1;
        if (this.articles)
            artIndex = this.articles.findIndex(function (_a) {
                var artNr = _a.artNr;
                return artNr === artNo;
            });
        var article = {};
        if (artIndex != -1) {
            article = this.articles[artIndex];
            var antall = article['antall'] + count;
            article['antall'] = antall;
            if (article['artNr'] == "99999") {
                article['kommentar'] = comment;
                article['antall'] = count;
            }
        }
        else {
            article['navn'] = artName;
            article['artNr'] = artNo;
            article['antall'] = count;
            if (article['artNr'] == "99999")
                article['kommentar'] = comment;
        }
        this.storageService.UpdateStorage(this.storageId, article).subscribe(function (storage) {
            _this.ngOnInit();
            _this.dialogFormGroup.get('addArticleFormGroup.articleSelect').setValue('');
            _this.dialogFormGroup.get('addArticleFormGroup.articleCount').setValue('');
            _this.dialogFormGroup.get('addArticleFormGroup.comment').setValue('');
        });
    };
    StorageDetailsComponent.prototype.updateMaterialCount = function (artNO, buttonType) {
        var _this = this;
        console.log(artNO, buttonType);
        var value = this.dialogFormGroup.get(artNO).value;
        this.dialogFormGroup.get(artNO).setValue(0);
        var artIndex = this.articles.findIndex(function (_a) {
            var artNr = _a.artNr;
            return artNr === artNO;
        });
        var article = this.articles[artIndex];
        if (buttonType === 'add') {
            article['antall'] = article['antall'] + value;
        }
        if (buttonType === 'remove') {
            article['antall'] = article['antall'] - value;
        }
        this.storageService.UpdateStorage(this.storageId, article).subscribe(function (articles) {
            _this.articles = articles;
        });
    };
    StorageDetailsComponent.prototype.setCountToDeafult = function (artNum) {
        this.dialogFormGroup.get(artNum).setValue(0);
    };
    StorageDetailsComponent.prototype.getLocation = function (storage) {
        if (storage.unloadGps === "") {
            if (storage.gpsPos !== "" && storage.gpsPos !== "0;0") {
                this.locationAvailable = true;
                var points = storage.gpsPos.split(";");
                this.gpsPos.push(points[0], points[1]);
            }
        }
        else {
            this.locationAvailable = true;
            var points = storage.unloadGps.split(";");
            this.gpsPos.push(points[0], points[1]);
        }
    };
    StorageDetailsComponent.prototype.zoomToStorage = function (storage) {
        this.onNoClick();
        var urlTree = this.router.createUrlTree(['lager/' + storage.unid + '/map'], { queryParams: { gpsPos: this.gpsPos } });
        this.router.navigateByUrl(urlTree);
    };
    StorageDetailsComponent.prototype.archiveStorage = function () {
        var _this = this;
        this.storageService.saveStorageAsArkiv(this.storage).subscribe(function (data) {
            _this.storage.status = data.status;
            _this.do_refresh = true;
        });
    };
    StorageDetailsComponent.prototype.onNoClick = function () {
        this.dialogRef.close({ do_refresh: this.do_refresh });
    };
    StorageDetailsComponent.prototype.confirm = function (event) {
        throw new Error("Method not implemented.");
    };
    StorageDetailsComponent.prototype.ngOnDestroy = function () {
        this._onDestroy.next();
        this._onDestroy.complete();
    };
    return StorageDetailsComponent;
}(AbstractDialogComponent));
export { StorageDetailsComponent };
