var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatStepper } from '@angular/material';
import { SelectArticleFormComponent } from '../select-article-form/select-article-form.component';
import { DataService } from '../../data.service';
import { Subject } from 'rxjs';
var RegisterParsellDialogComponent = /** @class */ (function (_super) {
    __extends(RegisterParsellDialogComponent, _super);
    function RegisterParsellDialogComponent(dialogRef, data, dataService) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.dataService = dataService;
        _this.compositeArticles = [];
        _this.compCalcArticles = [];
        _this.tmpNewCompoisteArticles = [];
        _this.compositeArticlesSubject = new Subject();
        if (!data.demolitionType) {
            data.demolitionType = '';
        }
        ;
        _this.dialogFormGroup = new FormGroup({
            buildingForm: new FormGroup({
                startMeter: new FormControl(0, Validators.required),
                sluttMeter: new FormControl(0, Validators.required),
                lengde: new FormControl(0, [Validators.required, Validators.min(0)]),
                skinner: new FormControl(0, [Validators.min(0)]),
                side: new FormControl('H'),
            }),
            demolitionForm: new FormGroup({
                lengde: new FormControl(0),
                vrakskinner: new FormControl(0),
                vrakstolper: new FormControl(0),
                deponering: new FormControl(0)
            }),
            strukturArticleForm: new FormGroup({
                quantity: new FormControl(1, [Validators.required, Validators.min(1)])
            })
        });
        return _this;
    }
    RegisterParsellDialogComponent.prototype.confirm = function (event) {
        throw new Error("Method not implemented.");
    };
    RegisterParsellDialogComponent.prototype.ngOnInit = function () {
        this.startRtType = this.data.rtType;
        this.endRtType = this.data.rtType;
        this.rivingRtType = this.data.rtType;
        this.startType = this.data.startType;
        this.endType = this.data.endType;
        this.selectedRailingType = this.data.selectedRailingType;
        this.setInitialValues();
    };
    RegisterParsellDialogComponent.prototype.setInitialValues = function () {
        var parcelHeader = this.data.parcelHeader;
        var lengthFromOrder = parcelHeader.lengthFromOrder ? parcelHeader.lengthFromOrder : 0;
        var startPos = parcelHeader.startPos ? parcelHeader.startPos : 0, endPos = parcelHeader.endPos ? parcelHeader.endPos : 0;
        if (lengthFromOrder === 0 && (startPos !== 0 || endPos !== 0)) {
            lengthFromOrder = Math.abs(startPos - endPos);
        }
        var skinner = Math.ceil(lengthFromOrder / 4);
        this.dialogFormGroup.get('buildingForm').patchValue({
            lengde: Number(lengthFromOrder),
            startMeter: startPos,
            sluttMeter: endPos,
            skinner: skinner
        });
    };
    RegisterParsellDialogComponent.prototype.startRtTypeChanged = function (railingType) {
        console.log(railingType);
        this.startRtType = railingType;
    };
    RegisterParsellDialogComponent.prototype.endRtTypeChanged = function (railingType) {
        this.endRtType = railingType;
    };
    RegisterParsellDialogComponent.prototype.rivingRtTypeChanged = function (railingType) {
        this.rivingRtType = railingType;
    };
    RegisterParsellDialogComponent.prototype.onChildEndArticleChanged = function (article) {
        if ('category' in article && typeof article.category === "string") {
            var articleCpy = Object.assign({}, article);
            this.endType = articleCpy;
            console.log(this.endType);
            this.dialogFormGroup.get('buildingForm').patchValue({ endArticle: this.endType.num });
        }
    };
    RegisterParsellDialogComponent.prototype.onChildStartArticleChanged = function (article) {
        if ('category' in article && typeof article.category === "string") {
            var articleCpy = Object.assign({}, article);
            this.startType = articleCpy;
            console.log('onchildstartchange', this.startType);
            this.dialogFormGroup.get('buildingForm').patchValue({ startArticle: this.startType.num });
        }
    };
    RegisterParsellDialogComponent.prototype.onCompositeArticleChanged = function (compArticle) {
        this.compositeArticle = compArticle;
    };
    RegisterParsellDialogComponent.prototype.calculateLength = function () {
        var buildFormValue = this.dialogFormGroup.get('buildingForm').value;
        var startMeter = buildFormValue.startMeter;
        var endMeter = buildFormValue.sluttMeter;
        var lengde = Math.abs(endMeter - startMeter);
        var skinner = Math.ceil(lengde / 4);
        this.dialogFormGroup.get('buildingForm').patchValue({ lengde: lengde, skinner: skinner });
    };
    RegisterParsellDialogComponent.prototype.skinnerUpdate = function () {
        var buildFormValue = this.dialogFormGroup.get('buildingForm').value;
        var skinner = buildFormValue.skinner;
        var lengde = skinner * 4;
        var endMeter = parseInt(buildFormValue.startMeter) + lengde;
        this.dialogFormGroup.get('buildingForm').patchValue({ lengde: lengde, sluttMeter: endMeter });
    };
    RegisterParsellDialogComponent.prototype.addStrukturArticle = function () {
        var strukturData = this.dialogFormGroup.get('strukturArticleForm').value;
        // const compArticle = [];
        if (this.compositeArticle) {
            this.compCalcArticles.push({ num: this.compositeArticle.num, selectedQuantity: strukturData.quantity.toString() });
            var compositeArticleCpy = Object.assign({}, this.compositeArticle);
            compositeArticleCpy.selectedQuantity = strukturData.quantity;
            this.tmpNewCompoisteArticles.push(compositeArticleCpy);
            this.compositeArticles = this.tmpNewCompoisteArticles;
        }
        this.notifyChildren();
    };
    RegisterParsellDialogComponent.prototype.notifyChildren = function () {
        this.compositeArticlesSubject.next(this.compositeArticles);
    };
    RegisterParsellDialogComponent.prototype.calculateArticles = function () {
        var _this = this;
        var buildData = this.dialogFormGroup.get('buildingForm').value;
        var rivingData = this.dialogFormGroup.get('demolitionForm').value;
        if (this.dialogFormGroup.get('buildingForm').valid) {
            this.dataService.getCalculatedArticlesWithoutGps(this.startRtType.id, this.startType.num, this.endType.num, buildData.lengde, buildData.side, this.compCalcArticles, this.rivingRtType.id, rivingData.lengde, this.data.parcel.invoiceType, this.data.parcel.unid).subscribe(function (data) {
                _this.articles = data;
                // add start & end types to composite articles
                _this.startType.selectedQuantity = 1;
                _this.endType.selectedQuantity = 1;
                _this.compositeArticles.push(_this.startType);
                _this.compositeArticles.push(_this.endType);
                var resData = {
                    type: RegisterParsellDialogComponent,
                    articles: _this.articles,
                    length: Number(buildData.lengde),
                    side: buildData.side,
                    startArticle: _this.startType,
                    endArticle: _this.endType,
                    demolitionLength: rivingData.lengde,
                    demolitionRailWreck: rivingData.vrakskinner,
                    demolitionStickWreck: rivingData.vrakstolper,
                    compositeArticles: _this.compositeArticles,
                    rtType: _this.data.rtType.id
                };
                _this.dialogRef.close(resData);
            });
        }
        else {
            this.stepper.selectedIndex = 0;
        }
        /* console.log(buildData,"-",this.startArticle.num,"-",this.endArticle.num,"-",this.data.rtType.id); */
    };
    RegisterParsellDialogComponent.prototype.registerParsell = function () {
    };
    return RegisterParsellDialogComponent;
}(AbstractDialogComponent));
export { RegisterParsellDialogComponent };
