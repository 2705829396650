import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggerService } from '../../environments/environment'

@Injectable()
export class IsiErrorHandler extends ErrorHandler {

  private logger: LoggerService;
  constructor(private injector: Injector) {
    // The true paramter tells Angular to rethrow exceptions, so operations like 'bootstrap' will result in an error
    // when an error happens. If we do not rethrow, bootstrap will always succeed.
    super();
  }

   handleError(error) {
    // send the error to the logger service
    if (this.logger == null) {
      this.logger = this.injector.get(LoggerService);
    }
    this.logger.error(error)
    // delegate to the default handler
    super.handleError(error);
   }
 }
