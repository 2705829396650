<div class="culvert-list">
    <div *ngFor="let culvert of culverts" >
        <div (click)="changeBackground(culvert)"
        [ngStyle]="{'background-color':culvert.background != ''? 'green' :'', 
        color:culvert.background != ''? 'white' :'' }"
        class="culvert">
        <button class="icon" mat-flat-button type="button" ><app-culvert></app-culvert></button>
            <div >
                <b>
                    <ng-container *ngFor="let roadRef of culvert.roadRefs; let i = index;">
                        {{roadRef?.shortForm}} ({{culvert?.sideOfRoad}})
                    </ng-container>
                    <ng-container *ngIf="culvert?.roadRefs.length == 0">
                        {{culvert.roadRef}} ({{culvert?.sideOfRoad}})
                    </ng-container>
                </b>
                <div>
                    <ng-container *ngFor="let property of culvert?.properties;let i= index">
                        {{property?.label}}: {{property?.value}} {{(i<culvert.properties.length-1) ? ' - ' :''}}
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>