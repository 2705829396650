<div *ngIf="roadRef" class="road-info" 
[class.open]="isOpen"
[class.closed]="!isOpen && !isClosing"
[class.closing]="isClosing"
[class.opening]="isOpening"
[@openClose]="isOpen ? 'open' : 'closed'" 
(click)="toggleRoadInfo($event)"
(@openClose.done)="onAnimationEvent($event)"
(@openClose.start)="onAnimationEvent($event)">
    <div id="road-info-close-icon">
        <mat-icon *ngIf="isOpen && !isOpening">close</mat-icon>
        <div *ngIf="!isOpen || isOpening">&nbsp;</div>
    </div>
    <div id="road-info-main-content">
        <div *ngIf="isOpen && !isOpening">
                {{roadRef?.shortForm}}
        </div>
        <div *ngIf="isOpen && !isOpening && gpsData" style="font-size: 10px;">
            Posisjon: {{gpsData?.lat|number: '1.6-6'}} / {{gpsData?.lon|number: '1.6-6'}}
        </div>
        <div *ngIf="!isOpen || isOpening">&nbsp;</div>
    </div>
    <mat-icon id="road-info-satellite-icon" class="satelite-quality" [class.satelite-quality-1]="satQuality===0"
        [class.satelite-quality-1]="satQuality===1" [class.satelite-quality-2]="satQuality===2"
        [class.satelite-quality-3]="satQuality===3">settings_input_antenna
    </mat-icon>
</div>
<div class="passthrough"></div>
