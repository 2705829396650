<div *ngIf="loading" class="loading">
      <div>Vennligst vent mens ressurser hentes</div>
      <mat-spinner></mat-spinner>
</div>
<div class="resourceList-container">
      <div class="employeeList">
            <h3 mat-subheader>Ansatte</h3>
            <mat-list-item *ngFor = "let emp of employees">
                  <h4 mat-line>{{ emp }}</h4>
            </mat-list-item> 
      </div>
      
     <div class="resourceList">
            <h3 mat-subheader>Driftsmidler</h3>
            <mat-list-item *ngFor = "let asset of assets">
                  <h4 mat-line>{{ asset }}</h4>
            </mat-list-item> 
     </div>
           
</div>
