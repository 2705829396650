var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ErrorHandler, Injector } from '@angular/core';
import { LoggerService } from '../../environments/environment';
var IsiErrorHandler = /** @class */ (function (_super) {
    __extends(IsiErrorHandler, _super);
    function IsiErrorHandler(injector) {
        var _this = 
        // The true paramter tells Angular to rethrow exceptions, so operations like 'bootstrap' will result in an error
        // when an error happens. If we do not rethrow, bootstrap will always succeed.
        _super.call(this) || this;
        _this.injector = injector;
        return _this;
    }
    IsiErrorHandler.prototype.handleError = function (error) {
        // send the error to the logger service
        if (this.logger == null) {
            this.logger = this.injector.get(LoggerService);
        }
        this.logger.error(error);
        // delegate to the default handler
        _super.prototype.handleError.call(this, error);
    };
    return IsiErrorHandler;
}(ErrorHandler));
export { IsiErrorHandler };
