import {HostListener, Inject, HostBinding} from '@angular/core';
import {MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormGroup} from '@angular/forms';
import { hostViewClassName } from '@angular/compiler';

/**
 * All dialogs should extend this class. Remember to add the sub class to app modules
 * as well as importing the scss file from this component to the scss of the sub class.
 */
export abstract class AbstractDialogComponent {

  abstract dialogFormGroup: FormGroup;
  @HostListener("window:scroll", [])
  @HostBinding('class.abstract-dialog')
  onWindowScroll(event) {
    // prevent the background from scrolling when the dialog is open.
    if (event) { event.stopPropagation(); }
  }
  protected constructor(protected dialogRef: MatDialogRef<AbstractDialogComponent>) {}
  closeDialog(): void {
    this.dialogRef.close('cancel');
  }
  abstract confirm(event): void;
}
