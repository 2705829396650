import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-length-confirmed-snackbar',
  templateUrl: './length-confirmed-snackbar.component.html',
  styleUrls: ['./length-confirmed-snackbar.component.scss']
})
export class LengthConfirmedSnackbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
