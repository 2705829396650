import { Component, OnInit, ViewChild  } from '@angular/core';
import { MatVerticalStepper} from '@angular/material';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-replicate',
  templateUrl: './replicate.component.html',
  styleUrls: ['./replicate.component.scss']
})
export class ReplicateComponent implements OnInit {
  isReplicating: boolean;
  status: string;
  databases: any;
  databasesReverse: any;
  replicationSummary: any;
  currDb: any;
  replicateSocket: WebSocket;
  @ViewChild('matStepper', {static: false}) matStepper;

  constructor(private dataService: DataService ) {
    this.isReplicating = true; // disable start replica button

  }

  ngOnInit() {
    this.getDatabases();
  }

  getDatabases() {
    this.dataService.getReplicationSettins().subscribe(settings => {
      this.databases = settings;
      this.isReplicating = false; // enable start replica button when settings are loaded.
    })
  }

  startReplication() {
    this.isReplicating = true;
    this.replicateSocket = new WebSocket("ws://localhost:8080/replicate/replicate-ws")
    this.replicateSocket.onopen = (event2: any) => {
      console.log("socket open..")
      this.replicateDatabases(0);
    }
  }
  replicateDatabases(index: number) {
    console.log("replicateDatabase index", index);
    let firstDb = undefined;
    firstDb =  this.databases[index];
    console.log("this.databases[index]", this.databases[index]);
    console.log("this.databases", this.databases);

    if (index < this.databases.length) {
      this.replicate(firstDb, index);
    } else {
      this.replicateSocket.close();
      this.isReplicating = false;
      // TODO: hide window..
    }
  }

  replicate(currDb: any, index: number) {
    console.log("replicate db: ", currDb, "index:" + index);
    // 1. get databases to replicate
    let currRange = 100;
    // 2. create websocket connection
    currDb.action = 'replicate';
    console.log("send data", currDb)
    this.replicateSocket.send(JSON.stringify( currDb ));

    this.replicateSocket.onmessage = (event) => {
      console.log("onmessage", event.data);
      const rep_progress = JSON.parse(event.data);

      // 4. update progress
      if (rep_progress.range) {
        currRange = rep_progress.range;
      }
      if (rep_progress.pos) {
        if (currRange === 0) {
          currRange = rep_progress.pos;
        }
        if (rep_progress.pos !== 65535) {
          currDb.progress = (rep_progress.pos / currRange) * 100;
        }
      }
      if (rep_progress.status) {
        // set staus text
        currDb.status = rep_progress.status;
        if (rep_progress.status === 'finished') {
          currDb.status = "Fullført"
          currDb.completed = true;
          this.matStepper.next()
          this.replicateDatabases(index + 1);
        } else if (rep_progress.status === 'started') {
          currDb.status = "Starter replikering.."
          currDb.completed = false;
        } else {
          currDb.status = rep_progress.status;
        }
      }

      if (rep_progress.replication_stats) {
        currDb.replicationSummary = rep_progress.replication_stats;
      }
    }
  }

}
