import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../data.service';
import { AbstractDialogComponent } from '../../abstract-dialog/abstract-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-proposal-detail',
  templateUrl: './proposal-detail.component.html',
  styleUrls: ['./proposal-detail.component.scss']
})
export class ProposalDetailComponent implements OnInit {
  clarificationFormGroup: FormGroup;
  commentsFormGroup: FormGroup;
  showCommentbox = false;
  showAddCommentBtn = true;
  parentId: string;
  proposal: any;
  constructor(protected dialogRef: MatDialogRef<AbstractDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService) {
      this.parentId = data.proposalId;
      this.commentsFormGroup = new FormGroup({
      comment: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.dataService.getSingleProposal(this.parentId).subscribe(data => {
      this.proposal = data;
    });
  }

  openCommentField() {
    this.closeInputBox();
    this.showCommentbox = true;
    this.showAddCommentBtn = false;
  }
  closeInputBox() {
    if (this.showCommentbox) {
      this.showCommentbox = false;
      this.showAddCommentBtn = true;
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  
  saveComment(event: any): void {
    const commentData = this.commentsFormGroup.get('comment').value;
    this.dataService.SaveProposalComments(this.parentId, commentData).subscribe(comment => {
      this.data = { parentUnid: this.parentId, body: commentData }
      this.ngOnInit();
    })
    console.log (commentData);
    this.closeInputBox();
  }
}
