import { Component, OnInit, ViewChild } from '@angular/core';
import {AllotmentHeader} from '../../allotment-header';

import {state, style, transition, trigger, animate} from '@angular/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar, MatTableDataSource} from '@angular/material';
import {DataService} from '../../data.service';
import {Allotment} from '../../allotment';
import { BehaviorSubject } from 'rxjs';
import { SjaDataSource } from '../../sja/sja-data-source';
import { ParcelsTableComponent } from '../parcels-table/parcels-table.component';
import { ElectronService } from '../../electron.service';

@Component({
  selector: 'app-parcels',
  templateUrl: './parcels.component.html',
  styleUrls: ['./parcels.component.scss'],
})
export class ParcelsComponent implements OnInit {
  @ViewChild('parcelsTable', {static: false}) parcelsTable: ParcelsTableComponent;
  orderUnid: string;
  isElectron: boolean;
  constructor(private router: Router, private dataService: DataService,
              private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar,
              private electronService: ElectronService) {
    this.isElectron = this.electronService.isElectron();
    this.orderUnid = this.activatedRoute.snapshot.params['orderUnid'];
  }
  ngOnInit() {
  }
  newParcel() {
    this.parcelsTable.newParcel();
  }
}
