var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { QualityService } from '../../quality.service';
var QualityAuditDialogComponent = /** @class */ (function (_super) {
    __extends(QualityAuditDialogComponent, _super);
    function QualityAuditDialogComponent(dialogRef, data, qualityService) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.qualityService = qualityService;
        _this.dialogRef.disableClose = true;
        _this.data = data;
        _this.yesNoControl = new FormControl('Ja', [Validators.required]);
        _this.numberControl = new FormControl(0, [Validators.required]);
        _this.textControl = new FormControl('', [Validators.required]);
        _this.dialogFormGroup = new FormGroup({
            'yesNoControl': _this.yesNoControl,
            'numberControl': _this.numberControl,
            'textControl': _this.textControl
        });
        return _this;
    }
    QualityAuditDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.questionSubscription = this.qualityService.getRandomQuestion().subscribe(function (res) {
            _this.qualityAudit = res.qualityAudit;
            _this.qualityNumber = res.num;
            if (_this.qualityAudit.questionType === 1) {
                _this.textControl.disable();
            }
        });
    };
    QualityAuditDialogComponent.prototype.onDestroy = function () {
        if (this.questionSubscription) {
            this.questionSubscription.unsubscribe();
        }
    };
    QualityAuditDialogComponent.prototype.confirm = function (event) {
        if (this.qualityAudit.questionType === 1) {
            this.qualityAudit.answer = this.yesNoControl.value;
        }
        else {
            this.qualityAudit.answer = this.textControl.value; // parse number to string
        }
        this.data.qualityAudit = this.qualityAudit;
        this.data.qualityNumber = this.qualityNumber;
        this.dialogRef.close(this.data);
    };
    ;
    return QualityAuditDialogComponent;
}(AbstractDialogComponent));
export { QualityAuditDialogComponent };
