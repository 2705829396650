import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { InfoPageComponent } from './info-page.component';
import { SharedModule } from '../shared/shared.module';
import { TasksComponent } from '../components/tasks/tasks.component';
import { TeamResourcesComponent } from '../components/team-resources/team-resources.component';
import { ProposalsComponent } from '../components/proposals/proposals.component';

const routes: Routes = [
  {path: 'info', component: InfoPageComponent}
];

@NgModule({
  declarations: [
    InfoPageComponent, 
    TasksComponent,
    TeamResourcesComponent,
    ProposalsComponent
  ],
  imports: [
      RouterModule.forChild(routes),
      CommonModule,
      SharedModule,
  ],
  exports: [RouterModule ],
})
export class InfoPageModule { }
