export const AppConfig = {
  production: true,
  environment: 'WEB',
  log_path: 'c:/logs',
  apiUrl: 'https://api.agjerde.no/v1/',
  ibricksApi: 'https://api.ibricks.no',
  username: '',
  password: ''
};

export { LoggerWebService as LoggerService } from '../app/log/logger.web.service';
