export class MyCoordinates implements Coordinates {
  accuracy: number;
  altitude: number | null;
  altitudeAccuracy: number | null;
  heading: number | null;
  latitude: number;
  longitude: number;
  speed: number | null;

  constructor(latitude, longitude) {
    this.latitude = latitude;
    this.longitude = longitude;
  }
}
