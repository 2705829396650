import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user.service';
import { AppConfig } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimsheetProjectService {
  apiUrl: string;
  constructor(private http: HttpClient, private userService: UserService) {
    this.apiUrl = AppConfig.apiUrl;
  }
  getProjects(): Observable<any> {
    const url = `${this.apiUrl}/projects`
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }, error => {
      return error;
    }));
  }
  getSalaryCode(): Observable<any> {
    const url = `${this.apiUrl}/hours/weeks/salarycodes`
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }, error => {
      return error;
    }));
  }
  getWeekProjectDetails(year,week,empId): Observable<any> {
    const url = `${this.apiUrl}/hours/weeks/projects?emplId=${empId}&year=${year}&week=${week}`
    return this.http.get<any>(url).pipe(map(res => {
      if (res) {
        return res.data;
      }
      return null;
    }, error => {
      return error;
    }));
  }
  saveWeekProjectDetails(projectWeekData): Observable<any> {
    const url = `${this.apiUrl}/hours/weeks/hours/projects`
    return this.http.post<any>(url, projectWeekData).pipe(map(res => {
      if (res) {
        return res.data;
      }
      return null;
    }, error => {
      return error;
    }));
  }
}
