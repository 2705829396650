import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfig } from '../environments/environment';

@Injectable()
export class UserService {

  private _teamId = 'Z';
  private _userName = 'Bruker';
  private _commonName = 'Bruker'
  private _roles: Array<string>;
  private _password: string;
  private userChanged$: BehaviorSubject<string> = new BehaviorSubject(this._userName);
  apiUrl;
  constructor(private http: HttpClient) {
    this.apiUrl = AppConfig.apiUrl;
  }
  get teamId(): string { return this._teamId; };
  set teamId(id) { this._teamId = id; };
  get userName(): string { return this._userName; };
  set userName(userName) { this._userName = userName; };
  get commonName(): string { return this._commonName; };
  set commonName(commonName) { this._commonName = commonName; };
  get password(): string {
    return this._password;
  }
  set roles(roles: Array<string>) {
    this._roles = roles;
  }
  get roles(): Array<string> { return this._roles};
  isAdmin() {
    let isAdmin = false;
    if (this._roles && this._roles.length > 0) {
      this._roles.forEach((role: string) => {
        if (role.indexOf("[Admin]") !== -1) {
          isAdmin = true;
        }
      });
    } else {
      return false;
    }
    return isAdmin;
  }

  changeTeam(team) {
    this._teamId = team;
    return this.http.post(this.apiUrl + '/login/team', {teamId: team},{withCredentials:true});
  }

  setCurrentUser() {
    return this.http.get(this.apiUrl + '/login/current',{withCredentials:true}).pipe(map( (user: any) => {
      this._commonName = user.data.commonName;
      this._roles = user.data.roles;
      this._teamId = user.data.team;
      this._userName = user.data.user;
    }));
  }
  getCurrentUser() {
    return this.http.get(this.apiUrl + '/login/current',{withCredentials: true}).pipe(map( (user: any) => {
      if (this._userName == 'Bruker') {
        console.log('set user settings', user);
        this._userName = user.data.user;        
        this._commonName = user.data.commonName;
        this._roles = user.data.roles;
        this._teamId = user.data.team;
      }
      
      return user.data;
    }));
  }
  setLoggedInUser(fullName: string, commonName: string, team: string, roles: Array<string>, password: string) {
    this._userName = fullName;
    this._commonName = commonName;
    this._teamId = team;
    this._roles = roles;
    this._password = password;
    this.userChanged$.next(this._userName);
  }
  getUser(): Observable<string> {
    console.log("user get");
    return this.userChanged$.asObservable();
  }
}
