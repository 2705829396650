<div class='dietSummary-container'> 
      <div class='diet-block' *ngFor = "let name of namesTeam">
            <strong>{{ name }}:</strong>
            <div class="day-diet-block" *ngFor = "let diet of diets[name]">
                  <strong>{{diet.dato | date: "dd.MM.yyyy"}} : &nbsp; </strong>
                  <span>{{diet.diett}} <span *ngIf="diet.location != ''">: {{diet.location}}</span></span>
                  <span *ngIf="diet.meals && diet.meals.length > 0"> ( {{diet.meals}} )</span>
            </div>
            <br>
      </div>
</div>