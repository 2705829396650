/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./camera-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "../camera/camera.component.ngfactory";
import * as i9 from "../camera/camera.component";
import * as i10 from "../../gps/gps.service";
import * as i11 from "@angular/common/http";
import * as i12 from "@angular/material/snack-bar";
import * as i13 from "../../electron.service";
import * as i14 from "../../user.service";
import * as i15 from "./camera-dialog.component";
import * as i16 from "@angular/material/dialog";
var styles_CameraDialogComponent = [i0.styles];
var RenderType_CameraDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CameraDialogComponent, data: {} });
export { RenderType_CameraDialogComponent as RenderType_CameraDialogComponent };
export function View_CameraDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { camera: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "button", [["class", "btn-close"], ["color", "accent"], ["mat-icon-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "mat-icon", [["aria-label", "Close"], ["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-camera", [["class", "webcam"]], null, [[null, "onImageSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onImageSave" === en)) {
        var pd_0 = (_co.getImage($event, _co.order) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_CameraComponent_0, i8.RenderType_CameraComponent)), i1.ɵdid(8, 114688, [[1, 4]], 0, i9.CameraComponent, [i10.GpsService, i11.HttpClient, i12.MatSnackBar, i13.ElectronService, i14.UserService], null, { onImageSave: "onImageSave" })], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 3, 0, currVal_2); _ck(_v, 5, 0); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 5).inline; var currVal_4 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_3, currVal_4); }); }
export function View_CameraDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-camera-dialog", [], [[2, "abstract-dialog", null]], [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CameraDialogComponent_0, RenderType_CameraDialogComponent)), i1.ɵdid(1, 114688, null, 0, i15.CameraDialogComponent, [i16.MatDialogRef, i16.MAT_DIALOG_DATA, i11.HttpClient, i12.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).onWindowScroll; _ck(_v, 0, 0, currVal_0); }); }
var CameraDialogComponentNgFactory = i1.ɵccf("app-camera-dialog", i15.CameraDialogComponent, View_CameraDialogComponent_Host_0, {}, {}, []);
export { CameraDialogComponentNgFactory as CameraDialogComponentNgFactory };
