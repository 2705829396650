<form class="dialog" [formGroup]="dialogFormGroup" (ngSubmit)="confirm($event)" >
  <h2>Endre skilt</h2>
  <button class="btn-close" color="accent" type="button" mat-icon-button (click)="closeDialog()">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
  <section class="select-article-section mat-typography" [class.mobile-view]="isMobile">
    <mat-form-field>
      <mat-label>Skilt</mat-label>
      <mat-select placeholder="Velg skilt.." formControlName="sign">
        <mat-select-trigger>
          <mat-list>
            <mat-list-item>
              <img src="assets/skilt/{{dialogFormGroup.get('sign')?.value}}.jpg" class="sign-icon">
            </mat-list-item>
          </mat-list>
        </mat-select-trigger>
        <mat-option *ngFor="let s of signs" [value]="s.value">
          <mat-list>
            <mat-list-item>
              <img src="assets/skilt/{{s?.value}}.jpg" class="sign-icon">
              <h3 mat-line>{{s?.label}}</h3>
            </mat-list-item>
          </mat-list>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-radio-group formControlName="side" name="side">
      <mat-radio-button class="example-radio-button" value="V" class="radio-padding">
        Venstre
      </mat-radio-button>
      <mat-radio-button class="example-radio-button" value="B" class="radio-padding">
        Begge
      </mat-radio-button>
      <mat-radio-button class="example-radio-button" value="H" class="radio-padding">
        Høyre
      </mat-radio-button>
    </mat-radio-group>
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Kortform" formControlName="shortForm" name="shortForm" type="text">
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <textarea matInput placeholder="Kommentar" formControlName="comment" name="comment"></textarea>
    </mat-form-field>
  </section>
  
  <button type="button" class="btn-gps success" color="primary" (click)="getCurrentGpsPos()" mat-raised-button>
    Hent posisjon fra GPS <mat-icon aria-label="Update">update</mat-icon>
  </button>
  <button type="submit" class="btn-save success" color="primary" mat-raised-button [disabled]="dialogFormGroup.invalid">
    {{buttonLabel}}
  </button>
</form>