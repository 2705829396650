import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { TimsheetProjectService } from '../timsheet-project.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { ReplaySubject, Subject, Observable } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-create-project-line-mobile',
  templateUrl: './create-project-line-mobile.component.html',
  styleUrls: ['./create-project-line-mobile.component.scss']
})
export class CreateProjectLineMobileComponent implements OnInit {
  @Input() selectedDate: moment.Moment;
  @Input() selectedWeekYear: Observable<any>;
  @Input() employeeId: Observable<any>;
  beginWeek: moment.Moment;
  currentWeekNumber: number;
  currentYear: number;
  public timesheetProjectForm: FormGroup;
  projectList: Array<any> = [];
  filteredProjects: ReplaySubject<Array<any>> = new ReplaySubject<Array<any>>(0);
  protected _onDestroy = new Subject<void>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  salaryCodes: any[];
  empId: string;
  currentWeekDay: number;
  projectWeekData:object = {};
  selectableSalaryCode: object[];
  projectCodeList: Array<object> = [];
  saveIndex: number = -1;
  dayProjects: Array<object> = [];
  currentUserId: string;
  constructor( private timesheetProjectService: TimsheetProjectService,private fb:FormBuilder) {
    this.selectedDate = moment();
    this.selectedDate.locale('').weekday();
    this.beginWeek = this.selectedDate.clone().startOf('isoWeek').tz('Europe/Oslo');
    this.beginWeek.set('hours', 12);
    this.currentWeekNumber = this.beginWeek.isoWeek();
    this.currentYear = this.beginWeek.year();
    this.currentWeekDay = this.selectedDate.weekday();
   }

  ngOnInit() {
    this.timesheetProjectForm = this.fb.group({
      projectLine: this.fb.array([this.addProjectDetails()])
    });
    this.getProjectList();
    
     // subscription for week and year changes 
     this.selectedWeekYear.subscribe(data => {
      this.currentWeekNumber = data.week;
      this.currentYear = data.year;
      this.currentWeekDay = data.weekDay;
      this.getWeekData(this.currentYear,this.currentWeekNumber,this.currentWeekDay,this.empId);
    });
    
    // subscription for employee changes
    this.employeeId.subscribe(data => {
      this.empId = data.userId;
      this.currentUserId = data.currentUserId;
      this.getWeekData(this.currentYear,this.currentWeekNumber,this.currentWeekDay,this.empId);
    });
  }
  async getProjectList() {
    var projectList = await<any> new Promise(resolve => {
      this.getProjects().subscribe(res => {
        resolve(res);
      })
    });
    var salaryCodes = <any>await new Promise(resolve => {
      this.getSalaryCodes().subscribe(res => {
        resolve(res);
      })
    });
    this.projectCodeList = [];
    projectList.forEach(proj => {   
      salaryCodes.forEach(code => {
        this.projectCodeList.push({ project: proj, salaryCode: code });
      });
    })
    this.getWeekData(this.currentYear,this.currentWeekNumber,this.currentWeekDay,this.empId);
  }
 
  ngAfterViewInit() {
    this.setInitialValue();
  }
  protected setInitialValue() {
    this.filteredProjects
      .pipe(take(0), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a, b) => a && b && a.projName === b.projName;
      });
  }
  getProjects() {
    var projList = new Subject<any>();
    this.timesheetProjectService.getProjects().subscribe(res => {
      this.projectList = res;
      projList.next(res);
      // load the initial project list
      this.filteredProjects.next(this.projectList);
    
      let initialProjectId = "0";
      if(this.projectList.length > 0) initialProjectId = this.projectList[0]['projNum'];
      for (var i=0 ; i < this.projectRows.length; i++) {
        this.timesheetProjectForm['controls']['projectLine']['controls'][i]['controls']['projectNumber'].setValue(initialProjectId);
      }
    });
    return projList.asObservable();
  }
  getSalaryCodes() {
    var sCode = new Subject<any>();
    this.timesheetProjectService.getSalaryCode().subscribe(res => {
      this.salaryCodes = [];
      res.forEach(data => {
        const code = data.split(" ");
        this.salaryCodes.push(code);
      }); 
      sCode.next(this.salaryCodes);
    });
    return sCode.asObservable();
  }
  getWeekData(year: number, week: number, weekDay:number, empId: string) {
    this.dayProjects = [];
    this.saveIndex = -1;
    this.timesheetProjectService.getWeekProjectDetails(year, week,empId).subscribe(res => {
      this.projectRows.clear();
      res.weekProjects.forEach((data) => {
        var projectHour = data.projectHours.filter(phour=> {
          // since in data from timesheetProjectService the monday referes to weekDay of 2 and sunday referes to weekday of 1 so have to add 1 to the weekDay while comparing and minus 6 when comparing for sunday 
          if(phour.dayOfWeek > 1 && phour.dayOfWeek == weekDay+1) return phour.hoursWork
          else if(phour.dayOfWeek == 1 && phour.dayOfWeek == weekDay-6) return phour.hoursWork
          else return 0;
        });
        if(projectHour.length > 0) {
          this.createProjectLine(projectHour[0],data.projectNumber,data.salaryCode);
          var projname = this.projectList.filter(proj => proj.projNum == data.projectNumber);
          var salaryCodeName = data.salaryCode == "" ? "Internt" : this.salaryCodes.filter(code => code[0] == data.salaryCode);
          this.dayProjects.push({projectNumber: data.projectNumber, 
            projectName: projname[0].projName,
            salaryCode: data.salaryCode == "" ? "100": data.salaryCode,
            salaryCodeName: salaryCodeName[0][1]
          });
        }
        console.log(this.dayProjects);
      }); 
    })
  }
  createProjectLine(projectHours,projectNumber,salaryCode) {
    this.addProjectLineToFormArray();
    this.saveIndex = this.projectRows.length;
    const index = this.projectRows.length-1;
    this.timesheetProjectForm['controls']['projectLine']['controls'][index].patchValue(
      {
        projectNumber: projectNumber,
        salaryCode: salaryCode == "" ? "100": salaryCode,
        hoursWork: projectHours['hoursWork'],
        comment: projectHours['comment'],
        unid:projectHours['unid']
      },{emitEvent: false}
    );
    this.timesheetProjectForm['controls'] ['projectLine']['controls'][index].get('projectNumber')['disable']();
    this.timesheetProjectForm['controls'] ['projectLine']['controls'][index].get('projectNumberFilter')['disable']();
    this.timesheetProjectForm['controls'] ['projectLine']['controls'][index].get('salaryCode')['disable']();
    var projIndex = this.projectCodeList.findIndex(prow => prow['project']['projNum'] == projectNumber && prow['salaryCode'][0] == salaryCode);
    if(projIndex ! = -1 ) this.projectCodeList.splice(projIndex,1);
  }
  onProjectSelect(projNo) {
    this.selectableSalaryCode = this.projectCodeList.filter(pcode => {
      if(pcode['project']['projNum'] == projNo) return pcode });
  }
  hoursChange(e) {
    console.log(e);
  }
  saveWeekData() {
    this.projectWeekData['year'] = this.currentYear;
    this.projectWeekData['weekNumber'] = this.currentWeekNumber;
    var weekProjects = [];
    this.timesheetProjectForm.getRawValue().projectLine.forEach(data => {
      var pHours = [];
      const hoursWork = data.hoursWork;
      const comment = data.comment;
      const unid = data.unid;
      const dayNumber = this.selectedDate.toISOString();
      pHours.push({ 'unid': unid, 'dateEnd': dayNumber, 'hoursWork': hoursWork, 'comment': comment })
      weekProjects.push({ 'projectNumber': data.projectNumber, 'salaryCode': data.salaryCode, 'projectHours': pHours })
    });
    this.projectWeekData['weekProjects'] = weekProjects;
    if (this.timesheetProjectForm.valid) {
      this.timesheetProjectService.saveWeekProjectDetails(this.projectWeekData).subscribe(res => {
        this.getWeekData(this.currentYear,this.currentWeekNumber,this.currentWeekDay,this.empId);
        console.log(res);
      });
    }
  }
  projectSearch(searchKey) {
    this.filterProjects(searchKey);
  }
  filterProjects(searchKey) {
    if(!this.projectList) return
    if (this.projectRows.length == 0 ) return 
    var search = searchKey;
    if(!search && search == "") {
     this.filteredProjects.next(this.projectList);
      return;
    }
    else {
      search = search.toLowerCase();
    } 
    this.filteredProjects.next(
      this.projectList.filter(project => project['projName'].toLowerCase().indexOf(search) > -1)
    );
  }
  addProjectDetails()
  {
    const projectFormGroup = this.fb.group({});
    projectFormGroup.addControl('projectNumber', this.fb.control([],Validators.required));
    projectFormGroup.addControl('salaryCode', this.fb.control([],Validators.required));
    projectFormGroup.addControl('projectNumberFilter', this.fb.control(''));
    projectFormGroup.addControl('hoursWork', this.fb.control(0));
    projectFormGroup.addControl('unid', this.fb.control(''));
    projectFormGroup.addControl('comment', this.fb.control(''));
    return projectFormGroup;
  }
  addProjectLineToFormArray() {
    this.projectRows.push(this.addProjectDetails());
  }
  get projectRows() {
    return (<FormArray>this.timesheetProjectForm.get('projectLine'));
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
