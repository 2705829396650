/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./length-confirmed-snackbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./length-confirmed-snackbar.component";
var styles_LengthConfirmedSnackbarComponent = [i0.styles];
var RenderType_LengthConfirmedSnackbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LengthConfirmedSnackbarComponent, data: {} });
export { RenderType_LengthConfirmedSnackbarComponent as RenderType_LengthConfirmedSnackbarComponent };
export function View_LengthConfirmedSnackbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Parsellen er lagret og fullf\u00F8rt \uD83C\uDF89\n"]))], null, null); }
export function View_LengthConfirmedSnackbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-length-confirmed-snackbar", [], null, null, null, View_LengthConfirmedSnackbarComponent_0, RenderType_LengthConfirmedSnackbarComponent)), i1.ɵdid(1, 114688, null, 0, i2.LengthConfirmedSnackbarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LengthConfirmedSnackbarComponentNgFactory = i1.ɵccf("app-length-confirmed-snackbar", i2.LengthConfirmedSnackbarComponent, View_LengthConfirmedSnackbarComponent_Host_0, {}, {}, []);
export { LengthConfirmedSnackbarComponentNgFactory as LengthConfirmedSnackbarComponentNgFactory };
