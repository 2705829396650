import {Injectable} from "@angular/core";
import {Layer} from "leaflet";

@Injectable()
export class MapService {
  public async getVectorTileLayer(): Promise<Layer> {
    // @ts-ignore
    const {vectorTileLayer} = await import('esri-leaflet-vector');
    return vectorTileLayer(
      'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheBasis_WM/VectorTileServer',
      {
        attribution:
          '<a href="https://www.geodataonline.no/">Geodata Online</a> | <a href="http://www.isi.no">iSi As</a>',
        style: (style: {sources: {esri: {maxzoom: number}}}) => {
          style.sources.esri.maxzoom = 15;
          return style;
        }
      }
    ) as Promise<Layer>;
  }
}
