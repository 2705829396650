import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SjaDataSource } from '../../../sja/sja-data-source';
import { MatDialog } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { SjaRiskFactorsDialogComponent } from '../sja-risk-factors-dialog/sja-risk-factors-dialog.component';
import { MediaService } from '../../../shared/media.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../data.service';
import { UserService } from '../../../user.service';

@Component({
  selector: 'app-sja-risk-factors',
  templateUrl: './sja-risk-factors.component.html',
  styleUrls: ['./sja-risk-factors.component.scss']
})
export class SjaRiskFactorsComponent implements OnInit {
  @Input() riskFactors: Array<any>;
  @Input() archived: boolean;
  @Input() title: string;
  @Input() inlineForm = false;
  @Output() riskfactorAdded = new EventEmitter();


  subject: BehaviorSubject<any>;
  dataSource: SjaDataSource;
  columnsToDisplay = ['operation', 'description', 'measures', 'responsible', 'action'];
  isMobile: boolean;
  isEdit: boolean;
  currentIndex: number;
  inlineFormGroup: FormGroup;
  showInlineForm = false;
  myTeam: any;

  constructor(public dialog: MatDialog, private mediaService: MediaService,private dataService: DataService, private user: UserService) {
    this.title = this.title || 'Andre forhold';
    this.mediaService.getMedia().subscribe(media => {
      this.isMobile = media.isMobileView;
    })
    //if (this.inlineForm) {
      this.inlineFormGroup = new FormGroup ({
        operation: new FormControl('',Validators.required),
        description: new FormControl('',Validators.required),
        measures: new FormControl('',Validators.required),
        responsible: new FormControl('',Validators.required)
      });
    //}
  }

  ngOnInit() {
    this.subject = new BehaviorSubject(this.riskFactors);
    this.dataSource = new SjaDataSource(this.subject.asObservable());
    this.dataService.getTeamEmployyes(this.user.teamId).subscribe(employees => {
      this.myTeam = employees;
      let index = "rowData";
      let data
      if (this.riskFactors && this.riskFactors.length > 0 ) {
        data = {
          risk : this.riskFactors[index]
        }
      }
      if (data && data.risk) {
        const selectedEmployee = this.myTeam.find(employee => {
           return employee.unid === data.risk.responsible.unid;
        });
        this.myTeam.push(selectedEmployee)
      }
    })
  }

  editRow(index) {
    if (this.inlineForm === true) {
      this.inlineFormGroup.setValue(this.riskFactors[index]);
      this.isEdit = true;
      this.showInlineForm = true;
      this.currentIndex = index;
    } else {
      this.openDialog(index);
    }
  }

  deleteRow(index: number) {
    const doDelete = confirm("Er du sikker på du vil slette risikomoment?");
    if (doDelete) {
      this.riskFactors.splice(index, 1);
      this.subject.next(this.riskFactors);
      this.riskfactorAdded.emit(this.riskFactors);
    }
  }

  addRiskMoment(event) {
    console.log('add risk moment');
    if (this.inlineFormGroup.valid) {
      this.showInlineForm = false;
      if (this.riskFactors) {
        if (this.isEdit) {
          this.riskFactors[this.currentIndex] = this.inlineFormGroup.value;
        } else {
          this.riskFactors.push(this.inlineFormGroup.value);
        }
      } else {
        this.riskFactors = [this.inlineFormGroup.value];
      }
      this.subject.next(this.riskFactors);
      this.riskfactorAdded.emit(this.riskFactors);
    }
  }

  newRiskMoment() {
    
    if (this.inlineForm === true) {
      
      this.inlineFormGroup.reset();
      this.showInlineForm = true;
    } else {
      
      this.openDialog(undefined);
    }
  }

  openDialog(index): void {
    let data;
    if (index === undefined) {
      data = {
        risk: undefined
      };
    }
    if (this.riskFactors && this.riskFactors.length > 0 ) {
      data = {
        risk : this.riskFactors[index]
      }
    }

    const dialogRef = this.dialog.open(SjaRiskFactorsDialogComponent, {
      height: '80%',
      width: '90%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.form) {
        if (result.isEdit) {
          this.riskFactors[index] = result.form;
        } else {
          if (this.riskFactors) {
            this.riskFactors.push(result.form);
          } else {
            this.riskFactors = [result.form];
          }
        }
        this.subject.next(this.riskFactors);
        this.riskfactorAdded.emit(this.riskFactors);
      }
    });
  }

}
