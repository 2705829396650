import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Type,
  HostBinding,
  ViewChild
} from '@angular/core';
import { Allotment } from '../../allotment';

import {
  latLng,
  LayerGroup,
  Map,
  Marker,
  marker,
  icon,
  MarkerOptions,
  Polyline,
  DomUtil,
  layerGroup,
  polyline,
} from 'leaflet';
import * as L from 'leaflet';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { CountDialogComponent } from '../count-dialog/count-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { RailingDialogComponent } from '../railing-dialog/railing-dialog.component';
import { RailingType } from '../../railing-type';
import { GpsService } from '../../gps/gps.service';
import { GPS_QUALITY, GpsQuality } from '../../gps/gps-quality';
import { GpsData } from '../../gps-data';
import { ActivationEnd, Router } from '@angular/router';
import { MeasuringPoint } from '../../measuring-point';
import { IconFactory } from '../../icon-factory';
import { POINT_STATUS } from '../../point-status';
import { OverlayContainer } from '@angular/cdk/overlay';
import { SavePointDialogComponent } from '../save-point-dialog/save-point-dialog.component';
import { RegisterParsellDialogComponent } from '../register-parsell-dialog/register-parsell-dialog.component';
import { DataService } from '../../data.service';
import { Order } from '../../order';
import { Subscription, Observable, Subject } from 'rxjs';
import { MeasureService } from '../../measure.service';
import { Article } from '../../article';
import { AddArticleDialogComponent } from '../add-article-dialog/add-article-dialog.component';
import * as offlinemap from '@isias/isi.offlinemap';
import { ArticleAdjustment } from '../../article-adjustment';
import { LengthConfirmedSnackbarComponent } from '../length-confirmed-snackbar/length-confirmed-snackbar.component';
import { CompositeArticle } from '../../composite-article';
import { VerifyArticlesPoint } from '../../verify-articles-point';
import { AllotmentHeader } from '../../allotment-header';
import { UserService } from '../../user.service';
import { ConfirmDeletePointDialogComponent } from '../confirm-delete-point-dialog/confirm-delete-point-dialog.component';
import { QualityService } from '../../quality.service';
import { QualityAudit } from '../../quality-audit';
import { QualityAuditDialogComponent } from '../quality-audit-dialog/quality-audit-dialog.component';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { ElectronService } from '../../electron.service';
import { DialogService } from '../../dialog.service';
import { catchError} from 'rxjs/operators';
import { HeaderService } from '../header/header.service';
import { LoggerService } from '../../../environments/environment';
import { ArticlesComponent } from '../articles/articles.component';
import { MapService } from '../../map.service';

@Component({
  selector: 'app-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.scss']
})
export class MeasureComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class.app-measure-component') hostClass = true;
  @ViewChild('measureArticles', { static: false }) measureArticles: ArticlesComponent;
  private routerSubscription: Subscription;
  private categorySubscription: Subscription;
  private auditSubscription: Subscription;
  savingInProgress = false;
  parsellStatus;
  isDemobilizingState;
  isMeasuringState;
  parcel: Allotment;
  parcelHeader: AllotmentHeader;
  satQuality: number;
  qualityBars: Array<boolean> = [true, false, false, false, false];
  myMap: Map;
  myPositionMarker: Marker;
  polyLine: Polyline;
  formGroup: FormGroup;
  lastPoint = false;
  lastPointType: number;
  expanding = false;
  selectedRailingType: RailingType;
  selectedDemolitionType: RailingType;
  gpsQuality: GpsQuality;
  gpsData: GpsData;
  roadRef: any;
  orderUnid: string;
  order: Order;
  parcelUnid: string;
  distance: number;
  selectedPointMarker; // : Marker;
  pointStatusTxt = 'Parsell ikke påbegynt';
  accumulatedArticles: Array<Article> = [];
  articleAdjustments: { [key: string]: ArticleAdjustment } = {};
  // temporary object, storing the edits made from the dialog until all articles are added.
  tmpArticleAdjustments: { [key: string]: ArticleAdjustment } = {};
  selectedMeasuringPoint: MeasuringPoint;
  sa: any;
  options;
  buildLength: number;
  hasFlownToPosOnMap = false;
  compositeArticles: Array<any> = [];
  compositeArticlesSubject: Subject<Array<CompositeArticle>> = new Subject();
  markerGroup: LayerGroup = new LayerGroup();
  allotmentLoaded = false;
  qualityAudits: Array<QualityAudit>;
  gpsSub: Subscription;
  // This is used for article calculation if the last verified point is deleted. Set to null when new calculation
  lastDeletedVerifiedPoint: MeasuringPoint = null;
  // TODO (john) make sure that `endArticleAdded` still works after a point has been deleted.
  // TODO (john) maybe set `endArticleAdded` to false if the deleted point is the last measuring point in the track?
  endArticleAdded = false;
  public isElectron;
  gpsDisabled = false;
  startArticle: CompositeArticle;
  endArticle: CompositeArticle;
  lengde: number;
  demolitionLength: any;
  demolitionRailWreck: any;
  demolitionStickWreck: any;
  rtType: string;

  constructor(public dialog: MatDialog, private gpsService: GpsService,
    private router: Router, private overlayContainer: OverlayContainer,
    private dataService: DataService, private measureService: MeasureService, private snackBar: MatSnackBar,
    private userService: UserService, private qualityService: QualityService, private electronService: ElectronService,
    private dialogService: DialogService, private headerService: HeaderService,
    private log: LoggerService, private mapService: MapService) {
    this.isElectron = this.electronService.isElectron();
    this.overlayContainer.getContainerElement().classList.add('secondary-theme');
    this.satQuality = 1;
    this.gpsQuality = new GpsQuality();
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event && event instanceof ActivationEnd) {
        this.orderUnid = event.snapshot.params['orderUnid'];
        this.parcelUnid = event.snapshot.params['parcelId'];
        this.getParcel(this.parcelUnid);
      }
    });
    if (this.gpsDisabled === false) { this.getMyPosition(); }
    this.getSaJson();
    this.options = {
      layers: [],
      attributionControl: false,
      zoomControl: false,
      zoom: 11,
      maxZoom: 23,
      minZoom: 4,
      center: latLng(62.527048, 7.556067)
    };
  }

  ngOnInit() {
    this.headerService.addNavItem({
      route: `/measure/${this.orderUnid}/${this.parcelUnid}`,
      label: 'Registrering',
      queryParams: ''
    });

    // offlinemap.tileLayers.init();
    this.auditSubscription = this.qualityService.getQualityAudits().subscribe(res => {
      this.qualityAudits = res;
    });
    this.formGroup = new FormGroup({
      'typeOfPoint': new FormControl('', [Validators.required]),
      'gpsEnabled': new FormControl('')
    });
  }
  ngAfterViewInit() {
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.pointStatusTxt === 'Parsell ikke påbegynt' || this.parcel.isComplete) {
      return true;
    }
    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    return this.dialogService.confirm('Vil du avbryte innmåling av spor?\n\nMålepunkt hittil vil ikke bli lagra.');
  }

  async onMapReady(map: L.Map): Promise<void> {
    this.myMap = map;
    this.myMap.options.zoomControl = false;

    map.setView(L.latLng(62.527048, 7.556067), 11)
      .on('popupclose ', ($event) => this.onPopupClose($event));

    const vector = await this.mapService.getVectorTileLayer();
    vector.addTo(map);

    this.markerGroup = layerGroup().addTo(this.myMap);
    this.myMap.on('click', ($event) => this.onMapCLick($event));
    this.myMap.scrollWheelZoom.disable();

  }
  ngOnDestroy() {
    if (this.gpsSub) {
      this.gpsSub.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.categorySubscription) {
      this.categorySubscription.unsubscribe();
    }
    if (this.auditSubscription) {
      this.auditSubscription.unsubscribe();
    }

    this.headerService.removeNavItem({
      route: `/measure/${this.orderUnid}/${this.parcelUnid}`,
      label: 'Registrering',
      queryParams: ''
    });
  }
  editParcel() {
    this.parcel.isComplete = false;
    this.parcelHeader.state = 1;
    this.lastPoint = true;
    this.notifyChildren();
  }
  getParcel(unid: string) {
    this.parcel = new Allotment(unid);
    this.parcel.points = [];

    forkJoin(this.dataService.getParcels(this.orderUnid), this.dataService.getOrder(this.orderUnid)).subscribe((res: Array<any>) => {
      this.order = res[1];
      this.order.allotmentHeaders = res[0];
      for (const parcelHead of this.order.allotmentHeaders) {
        if (parcelHead.unid === unid) {
          this.parcel.title = parcelHead.title;
          this.parcel.startVegKortform = parcelHead.startVegKortform;
          this.parcel.sluttVegKortform = parcelHead.sluttVegKortform;
          this.parcelHeader = parcelHead;
          this.allotmentLoaded = true;
          this.parcel.rtType = parcelHead.rtType;
          this.parcel.invoiceType = parcelHead.invoiceType;
          if (parcelHead.state && parcelHead.state >= Allotment.STATE_REGISTRATION_COMPLETE) {
            if (parcelHead.points != null) {
              for (const p of parcelHead.points) {
                const isCountingPoint = p.verifyArticlesPoint;
                this.saveMarker(p, isCountingPoint, false, false);
              }
            }
            this.parcel.startType = parcelHead.startType;
            this.parcel.endType = parcelHead.endType;
            this.accumulatedArticles = [];
            this.initExistingArticlesFromOrder(parcelHead);
            this.parcel.isComplete = true;
            this.pointStatusTxt = 'Parsell utført.';
            this.updateBuildLength();
            if (parcelHead.points != null) {
              this.updateCompositeArticles(this.parcel);
            } else {
             this.updateCArticles(parcelHead);
            }
            if (parcelHead.points &&  parcelHead.points.length > 0) {
              const lat = this.gpsService.getDecimalDegreesFromDegreeMinutes(this.parcel.points[0].gga.latitude);
              const lon = this.gpsService.getDecimalDegreesFromDegreeMinutes(this.parcel.points[0].gga.longitude);
              this.myMap.flyTo([lat, lon], 18);
              this.myMap.once('moveend', () => {
                this.updatePolyLine();
              });
            } else {
              this.gpsDisabled = true;
              this.formGroup.patchValue({ 'gpsEnabled': true });
              this.formGroup.get('gpsEnabled').disable();
            }
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          }
        }
      }
      this.categorySubscription = this.dataService.getRailTypesCategories().subscribe(data => {
        const rt = data;
        for (const cat in rt) {
          if (rt.hasOwnProperty(cat)) {
            for (const obj of rt[cat]) {
              if (obj.id === this.parcel.rtType) {
                this.selectedRailingType = obj;
              }
            }
          }
        }
        this.plotPointsFromOrderOnMap();
      });
    });
  }
  getMyPosition = () => {
    this.gpsSub = this.gpsService.getObservable().subscribe(gpsAndRoadRefData => {
      const gpsData = gpsAndRoadRefData.gpsData;
      if (this.myMap && gpsData && gpsData.lat !== 0.0 && !this.hasFlownToPosOnMap) {
        this.myMap.flyTo([gpsData.lat, gpsData.lon], 18);
        this.hasFlownToPosOnMap = true;
      }
      if (gpsData && gpsData.lat !== 0.0 && gpsData.type !== 'RMC') {
        this.gpsData = gpsData;
        if (this.parcel) {
          this.distance = this.measureService.getDistanceSincePoint(this.parcel.points[this.parcel.points.length - 1], gpsData);
          if (this.myPositionMarker) { this.myPositionMarker.removeFrom(this.myMap); }
          this.satQuality = this.gpsQuality.getGpsQuality(gpsData.quality, gpsData.hdop);
          this.setQualityBars();
          this.myPositionMarker = marker(latLng(gpsData.lat, gpsData.lon), {
            icon: icon({
              iconSize: [25, 41],
              iconAnchor: [13, 41],
              iconUrl: 'assets/marker-icon.png',
              shadowUrl: 'assets/marker-shadow.png'
            })
          }).setZIndexOffset(0);
          this.myPositionMarker.addTo(this.myMap);
          this.roadRef = gpsAndRoadRefData.roadRef;
          if (this.roadRef && this.roadRef.curve) {
            this.roadRef.municipality = this.roadRef.curve.municipality;
          }
        }
      }
    });
  }
  zoomOut(event) {
    if (this.myMap.getZoom() >= 8) {
      this.myMap.setZoom(this.myMap.getZoom() - 1);
    }
  }
  zoomIn(event) {
    this.myMap.setZoom(this.myMap.getZoom() + 1);
  }
  goToMyLocation(event) {
    if (this.myPositionMarker) {
      this.myMap.flyTo(this.myPositionMarker.getLatLng(), 18);
    }
  }
  goToTrackLocatoin(event) {
    if (this.parcel && this.parcel.points && this.parcel.points.length > 0) {
      const lat = this.gpsService.getDecimalDegreesFromDegreeMinutes(this.parcel.points[0].gga.latitude);
      const lon = this.gpsService.getDecimalDegreesFromDegreeMinutes(this.parcel.points[0].gga.longitude);
      this.myMap.flyTo([lat, lon], 18);
    }
  }
  onMapCLick(event) {
    if (this.selectedPointMarker) {
      DomUtil.removeClass(this.selectedPointMarker.getElement(), 'active-marker');
    }
    this.selectedPointMarker = null;
    this.selectedMeasuringPoint = null;
  }
  onMarkerClick(event, measuringPoint: MeasuringPoint, pointMarker: Marker) {
    if (pointMarker) {
      DomUtil.addClass(pointMarker.getElement(), 'active-marker');
    }
    if (this.selectedPointMarker) {
      DomUtil.removeClass(this.selectedPointMarker.getElement(), 'active-marker');
    }
    if (pointMarker === this.selectedPointMarker) {
      this.selectedPointMarker = null;
    } else {
      this.selectedPointMarker = pointMarker;
      this.selectedMeasuringPoint = measuringPoint;
    }
  }
  openSavePointDialog() {
    if (this.gpsDisabled) {
      this.openDialog(RegisterParsellDialogComponent);
    } else {
      this.openDialog(SavePointDialogComponent);
    }
  }
  animationDone() {
    this.expanding = false;
  }
  openRailDialog(railingType: RailingType, isDemolition: boolean) {
    this.openDialog(RailingDialogComponent, { railingType: railingType, isDemolition: isDemolition });
  }
  openDialog(type: Type<AbstractDialogComponent>, args?: any): void {
    let dialogRef;
    let data;
    let panelClass: string | string[] = '';
    switch (type) {
      case CountDialogComponent:
        data = { type: type, gpsQuality: this.gpsQuality, gpsData: this.gpsData };
        break;
      case RailingDialogComponent:
        data = { type: type, railingType: args.railingType, isDemolition: args.isDemolition };
        break;
      case SavePointDialogComponent:
        let pointsToCalculate;
        try {
          pointsToCalculate = this.getPoinstToCalculate(this.parcel.points);
        } catch (e) {
          this.log.error(e);
        }
        let lengthSinceLastCountingPoint;
        try {
          lengthSinceLastCountingPoint = this.getTotalDistance(pointsToCalculate);
        } catch (e) {
          this.log.error(e);
        }
        panelClass = ['save-point-dialog-panel-class', 'satelite-quality-' + this.satQuality];
        data = {
          type: type,
          gpsQuality: this.satQuality,
          gpsData: this.gpsData,
          typeOfPoint: this.lastPointType,
          points: pointsToCalculate,
          rtType: this.selectedRailingType,
          demolitionType: this.selectedDemolitionType,
          tmpArticleAdjustments: this.tmpArticleAdjustments,
          order: this.order,
          parcel: this.parcel,
          isiRoadRef: this.roadRef,
          lengthSinceLastCountingPoint: lengthSinceLastCountingPoint,
          parsellStatus: this.parsellStatus,
          endArticleAdded: this.endArticleAdded,
          isDemobilizingState: this.isDemobilizingState,
          isMeasuringState: this.isMeasuringState
        };
        break;
      case RegisterParsellDialogComponent:
        data = {
          type: type,
          rtType: this.selectedRailingType,
          demolitionType: this.selectedDemolitionType,
          selectedRailingType: this.selectedRailingType,
          parcel: this.parcel,
          parcelHeader: this.parcelHeader,
          startType: this.sa[Number.parseInt(this.parcelHeader.startType)],
          endType: this.sa[Number.parseInt(this.parcelHeader.endType)]
        }
        break;
      case AddArticleDialogComponent:
        let typeOfArticle;
        if (args && args.typeOfArticle) { typeOfArticle = args.typeOfArticle; }
        data = { type: type, typeOfArticle: typeOfArticle };
        break;
      case ConfirmDeletePointDialogComponent:
        data = { type: type };
        if (args) {
          data.pointToDelete = args.pointToDelete;
          data.isLastVerifiedPoint = args.isLastVerifiedPoint;
        }
        break;
      case QualityAuditDialogComponent:
        data = { type: type };
        break;
      default:
        break;
    }
    dialogRef = this.dialog.open(type, {
      height: '80%',
      width: '90%',
      panelClass: panelClass,
      data: data
    });
    dialogRef.beforeClose().subscribe(result => {
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancel') {
        switch (result.type) {
          case CountDialogComponent:
            break;
          case RailingDialogComponent:
            if (result.isDemolition) {
              this.selectedDemolitionType = result.railingType;
            } else {
              this.selectedRailingType = result.railingType;
            }
            break;
          case SavePointDialogComponent:
            this.parsellStatus = result.parsellStatus;
            this.lastPointType = result.typeOfPoint;
            this.isMeasuringState = result.isMeasuringState;
            this.isDemobilizingState = result.isDemobilizingState;
            this.lastPoint = result.isLastPoint;
            this.selectedDemolitionType = result.demolitionType;
            this.selectedRailingType = result.rtType;
            this.endArticleAdded = result.endArticleAdded;
            const isCountingPoint = result.articles ? true : false;
            this.saveMarker(result.tmpPoint, isCountingPoint, true, true);
            if (this.lastPoint) {
              this.pointStatusTxt = "Fullført";
            } else {
              this.updatePointStatusTxt(result.typeOfPoint);
            }
            // we only receive the articles when we confirm the materials from the dialog
            if (result.articles) {
              this.updateAdjustedArticlesAndResetTmpAdjustments(result.tmpArticleAdjustments);
              this.updateArticlesAndVerifyLastPoint(result.articles);
              this.lastDeletedVerifiedPoint = null;
            }
            this.updateBuildLength();
            this.updateCompositeArticles(this.parcel);
            if (this.parcel.points && this.parcel.points.length > 0 && !this.hasFlownToPosOnMap) {
              const lastPointInAllotment = this.parcel.points[this.parcel.points.length - 1];
              this.myMap.flyTo([parseFloat(lastPointInAllotment.gga.latitude), parseFloat(lastPointInAllotment.gga.longitude)], 18);
              this.hasFlownToPosOnMap = true;
            }
            this.shouldPromptQualityQuestion();
            break;
          case RegisterParsellDialogComponent:
            this.accumulatedArticles = result.articles;
            this.parcelHeader.sideOfRoad = result.side;
            this.startArticle = result.startArticle;
            this.endArticle = result.endArticle;
            this.lengde = result.length;
            this.demolitionLength = result.demolitionLength;
            this.demolitionRailWreck = result.demolitionRailWreck;
            this.demolitionStickWreck = result.demolitionStickWreck;
            this.compositeArticles = result.compositeArticles;
            this.rtType = result.rtType;
            this.updateBuildLength();
            this.notifyChildren();
            break;
          case AddArticleDialogComponent:
            if (result.article && result.quantity) {
              if (result.addType === 'compositeArticle') {
                const artToAdd = result.article;
                artToAdd.selectedQuantity = result.quantity;
                this.compositeArticles.push(artToAdd);
                this.notifyChildren();
                this.dataService.getArticlesFromCompositeArticle(
                  this.parcel.rtType,
                  artToAdd.num,
                  artToAdd.selectedQuantity
                ).subscribe(res => {
                  res.forEach( (article: Article) => {
                    this.addArticleToList(article, article.quantityNew, false)
                  })
                });
                this.snackBar.open('Strukturartikkel lagt til i listen', '', { duration: 5000 });
              } else {
                this.addArticleToList(result.article, result.quantity, true);
              }
            }
            break;
          case ConfirmDeletePointDialogComponent:
            if (result) {
              this.handleArticlesAfterDeletePoint(result);
            }
            break;
          case QualityAuditDialogComponent:
            const qualityAudit: QualityAudit = result.qualityAudit;
            this.parcel.qualityQuestionAnswered = true;
            this.parcel.qualityAnswer = qualityAudit.answer;
            this.parcel.qualityQuestion = "" + result.qualityNumber;
            this.parcel.qualityAuditLocationId = "" + (this.parcel.points.length - 1);
            this.parcel.qualityQuestionText = qualityAudit.question;
            this.parcel.qualityQuestionCompleted = new Date();
            break;
          default:
            break;
        }
      }
    });
  }
  notifyChildren() {
    this.compositeArticlesSubject.next(this.compositeArticles);
  }
  private subtractArticlesSinceLastCountingPoint(lastVerifiedPoint: MeasuringPoint) {
    // XXX Deleted point is last point that is verified. Remove articles since last verified point calculation.
    // XXX Manual adjustments are still kept.
    const tmpPointsToCalc = this.getPoinstToCalculate(this.parcel.points);
    tmpPointsToCalc.push(lastVerifiedPoint);
    const demoType = this.selectedDemolitionType || { id: '' };
    const splitPoints: Array<Array<MeasuringPoint>> = this.measureService.splitPoints(tmpPointsToCalc);
    const requests: Array<Observable<Array<Article>>> = this.measureService.createCalculatedArticlesRequests(
      splitPoints,
      this.selectedRailingType.id,
      demoType.id,
      this.parcel.invoiceType,
      this.parcel.unid
    ).requests;
    forkJoin(requests).subscribe(res => {
      const articlesMap = this.measureService.createArticlesMap(res);
      for (const accumulatedArticle of this.accumulatedArticles) {
        if (articlesMap[accumulatedArticle.id]) {
          accumulatedArticle.quantityOld -= articlesMap[accumulatedArticle.id].quantityOld;
          accumulatedArticle.quantityTotal -= articlesMap[accumulatedArticle.id].quantityTotal;
          accumulatedArticle.quantitySuggested -= articlesMap[accumulatedArticle.id].quantitySuggested;
        }
      }
    });
  }

  /**
   * Randomly prompts the user with a quality question.
   * Always prompts if last point, and not answered any question yet.
   */
  shouldPromptQualityQuestion(): void {
    if (this.lastPoint && !this.parcel.qualityQuestionAnswered) {
      this.promptRandomQualityQuestion();
    } else if (!this.parcel.qualityQuestionAnswered) {
      const randomNum = Math.random();
      if (this.parcel.lengthFromOrder && parseFloat(this.parcel.lengthFromOrder)) {
        const lengthFromOrder = parseFloat(this.parcel.lengthFromOrder);
        if (lengthFromOrder > 0 && randomNum > 1 / lengthFromOrder * 10) {
          this.promptRandomQualityQuestion();
        }
      } else {
        if (randomNum > 0.9) {
          this.promptRandomQualityQuestion();
        }
      }
    }
  }
  promptRandomQualityQuestion() {
    this.openDialog(QualityAuditDialogComponent);
  }
  addCompositeArticleClick(event) {
    this.openDialog(AddArticleDialogComponent, { typeOfArticle: 'compositeArticle' });
  }
  addArticleClick(event) {
    this.openDialog(AddArticleDialogComponent, { typeOfArticle: 'article' });
  }
  handleArticlesAfterDeletePoint(result: {
    type: Type<AbstractDialogComponent>,
    deleteArticles: boolean,
    pointToDelete: MeasuringPoint,
    isLastVerifiedPoint: boolean
  }) {
    if (result.deleteArticles) { // delete all articles, but keep the composite articles.
      this.deleteArticlesAndRemoveVerifyFlag();
      this.redrawTrack();
      this.moveCompositeArticlesToPreviousPoint(result.pointToDelete);
      this.lastDeletedVerifiedPoint = null;
    } else if (result.pointToDelete && result.pointToDelete.verifyArticlesPoint && result.isLastVerifiedPoint) {
      // deleted point is the last verified point (last counting point).
      // keep articles. Start new calculation from the deleted point.
      if (!this.lastDeletedVerifiedPoint) { // only keep the first `lastDeletedVerifiedPoint` until new calculation.
        this.lastDeletedVerifiedPoint = result.pointToDelete;
      }
    } else {
      this.moveCompositeArticlesToPreviousPoint(result.pointToDelete);
    }
    this.updateCompositeArticles(this.parcel);
  }

  moveCompositeArticlesToPreviousPoint(pointToDelete) {
    if (pointToDelete && pointToDelete.compositeArticles && pointToDelete.compositeArticles.length > 0) {
      for (let i = 1; i < this.parcel.points.length; i++) {
        const p = this.parcel.points[i];
        if (p.gga.latitude === pointToDelete.gga.latitude &&
          p.gga.longitude === pointToDelete.gga.longitude) {
          const prevPoint = this.parcel.points[i - 1];
          prevPoint.compositeArticles = prevPoint.compositeArticles || [];
          prevPoint.compositeArticles = prevPoint.compositeArticles.concat(pointToDelete.compositeArticles);
        }
      }
    }
  }
  /**
   * Will merge all the articles with the suggestion from the server and confirmation by the user.
   * It will also verify the last point in the parcel.
   * @param articles
   */
  updateArticlesAndVerifyLastPoint(articles) {
    const tmpAccumulatedArticles = [];
    if (articles && articles.length > 0) {
      articles.forEach(article => {
        let foundArticle = false;
        this.accumulatedArticles.forEach(accumulatedArticle => {
          if (accumulatedArticle.id === article.id) {
            foundArticle = true;
            accumulatedArticle.quantitySuggested += article.quantitySuggested;
            accumulatedArticle.quantityOld += article.quantityOld;
            accumulatedArticle.quantityTotal += article.quantityTotal;
          }
        });
        if (!foundArticle) {
          tmpAccumulatedArticles.push(article);
        }
      });
      // force update in child component by creating new array.
      this.accumulatedArticles = [].concat(this.accumulatedArticles).concat(tmpAccumulatedArticles);
      this.parcel.points[this.parcel.points.length - 1].verifyArticlesPoint = true;
    }
  }

  /**
   * Delete the selected `measuringPoint`.
   * Remove any classes on the selected element.
   * Set status to deleted if deleted point is a verified point (can be used for article calculation later).
   * Updates polyline and total distance.
   */
  deletePoint(measuringPoint) {
    let indexToDel = -1;
    for (let i = 0; i < this.parcel.points.length; i++) {
      if (this.parcel.points[i].gga.latitude === measuringPoint.gga.latitude &&
        this.parcel.points[i].gga.longitude === measuringPoint.gga.longitude) {
        indexToDel = i;
        break;
      }
    }
    if (indexToDel >= 0) {
      // Check if point has been used for article calculation.
      for (let i = this.parcel.points.length - 1; i >= indexToDel; i--) {
        if (this.parcel.points[i].verifyArticlesPoint) {
          const isLastVerifiedPoint = (indexToDel === i);
          this.parcel.points[indexToDel].state = this.parcel.points[indexToDel].state + POINT_STATUS.MODULATOR;
          this.openDialog(ConfirmDeletePointDialogComponent,
            {
              pointToDelete: this.parcel.points[indexToDel],
              isLastVerifiedPoint: isLastVerifiedPoint
            });
          break;
        }
      }
      if (indexToDel === this.parcel.points.length - 1) {
        if (this.lastPoint) { // remove articles if the last point was the end point of the parcel.
          measuringPoint.compositeArticles = [];
        }
        this.lastPoint = false;
      }
      if (this.parcel.points[indexToDel].state < POINT_STATUS.MODULATOR) {
        // We keep the verified points in the allotment for new calculation purposes, but do not draw them on the map.
        this.parcel.points.splice(indexToDel, 1);
      }
      if (this.selectedPointMarker) {
        DomUtil.removeClass(this.selectedPointMarker.getElement(), 'active-marker');
        this.selectedPointMarker.removeFrom(this.myMap);
        this.selectedPointMarker = null;
      }
    }
    this.updatePolyLine();
    this.updateTotalDistance(this.parcel);
    this.myMap.closePopup();
  }
  private setQualityBars(): void {
    let quality: number = (this.satQuality * 2) - 1 || 0;
    for (let i = 1; i < 5; i++) {
      this.qualityBars[i] = --quality > 0;
    }
  }
  private plotPointsFromOrderOnMap() {
  }

  /**
   * TODO (john) remove `flyToMarker` and `updatePolyline` because they are not part of the method description?
   * Create a marker, and add the latest point to the point list in the parcel.
   * @param {MeasuringPoint} tmpPoint
   * @param {boolean} isCountingPoint
   * @param {boolean} flyToMarker
   * @param {boolean} updatePolyline
   */
  private saveMarker(tmpPoint: MeasuringPoint, isCountingPoint?: boolean, flyToMarker?: boolean,
    updatePolyline?: boolean) {
    let savedPoint;
    let satQuality;
    let latlngTpl;
    if (tmpPoint) {
      latlngTpl = latLng(parseFloat(tmpPoint.gga.latitude), parseFloat(tmpPoint.gga.longitude));
      savedPoint = tmpPoint;
      satQuality = this.gpsQuality.getGpsQualityFromFixedNumber(tmpPoint.gga.fixQuality, tmpPoint.gga.hdopPrecision);
    }
    const markerIconOptions: MarkerOptions = IconFactory.create(
      satQuality, tmpPoint.state, isCountingPoint);
    const lat = this.gpsService.getDecimalDegreesFromDegreeMinutes(savedPoint.gga.latitude);
    const lon = this.gpsService.getDecimalDegreesFromDegreeMinutes(savedPoint.gga.longitude);
    this.parcel.points.push(savedPoint);
    if (tmpPoint.state < POINT_STATUS.MODULATOR ||
      (tmpPoint && tmpPoint.state < POINT_STATUS.MODULATOR)) {
      // do not create a marker for deleted points.
      const popupContent = '<p>Informasjon om valgt punkt er ikke implementert enda</p>';
      const markerPopup = L.popup()
        .setLatLng(latlngTpl)
        .setContent(popupContent);
      const pointMarker: Marker = marker(latLng(lat, lon), markerIconOptions)
        // .bindPopup(markerPopup) // removed because isi.offline map is not working with it
        .setZIndexOffset(1000)
        .on('click', ($event) => this.onMarkerClick($event, savedPoint, pointMarker))
        .addTo(this.markerGroup);
    }
    if (updatePolyline) {
      this.updatePolyLine();
    }
    this.updateTotalDistance(this.parcel);
    if (flyToMarker) {
      this.myMap.flyTo([lat, lon]);
    }
  }
  private updatePolyLine() {
    if (this.polyLine) {
      this.polyLine.removeFrom(this.myMap);
    }
    const latlngs = [];
    for (const point of this.parcel.points) {
      if (point.state < POINT_STATUS.MODULATOR) {
        latlngs.push([this.gpsService.getDecimalDegreesFromDegreeMinutes(point.gga.latitude),
        this.gpsService.getDecimalDegreesFromDegreeMinutes(point.gga.longitude)]);
      }
    }
    this.polyLine = polyline(latlngs, { color: 'red', opacity: 0.5 }).addTo(this.myMap);
  }
  private onPopupClose(event) {
    this.selectedPointMarker = null;
  }
  private updatePointStatusTxt(status: number) {
    switch (status) {
      case POINT_STATUS.DEMOLITION_AND_MEASURE_POINT:
        this.pointStatusTxt = "Riving og montering pågår";
        break;
      case POINT_STATUS.DEMOLITION_POINT:
        this.pointStatusTxt = "Riving pågår";
        break;
      case POINT_STATUS.MEASURE_POINT:
        this.pointStatusTxt = "Montering pågår";
        break;
      default:
        this.pointStatusTxt = "Fullført."
        break;
    }
  }
  private updateTotalDistance(parcel: Allotment) {
    parcel.distance = 0;
    for (let i = 1; i < parcel.points.length; i++) {
      if (parcel.points[i]) {
        const pointEnd = parcel.points[i];
        const pointStart = parcel.points[i - 1];
        parcel.distance += this.measureService.getDistanceBetweenMeasuringPoints(pointEnd, pointStart);
      }
    }
  }

  getPoinstToCalculate(points: Array<MeasuringPoint>) {
    const newPoints = [];
    if (points) {
      for (let i = points.length - 1; i >= 0; i--) {
        newPoints.push(points[i]);
        if (points[i].verifyArticlesPoint) {
          return newPoints.reverse();
        }
      }
    }
    return newPoints.reverse();
  }

  public updateAdjustedArticlesAndResetTmpAdjustments(tmpArticleAdjustments) {
    for (const tmpArticleKey in tmpArticleAdjustments) {
      if (this.articleAdjustments.hasOwnProperty(tmpArticleKey)) {
        this.articleAdjustments[tmpArticleKey].quantityAdjusted += tmpArticleAdjustments[tmpArticleKey].quantityAdjusted;
        this.articleAdjustments[tmpArticleKey].quantityTotal += tmpArticleAdjustments[tmpArticleKey].quantityTotal;
        this.articleAdjustments[tmpArticleKey].quantityUsed += tmpArticleAdjustments[tmpArticleKey].quantityUsed;
        this.articleAdjustments[tmpArticleKey].quantitySuggested += tmpArticleAdjustments[tmpArticleKey].quantitySuggested;
      } else {
        this.articleAdjustments[tmpArticleKey] = {
          quantityAdjusted: tmpArticleAdjustments[tmpArticleKey].quantityAdjusted,
          quantityTotal: tmpArticleAdjustments[tmpArticleKey].quantityTotal,
          quantityUsed: tmpArticleAdjustments[tmpArticleKey].quantityUsed,
          quantitySuggested: tmpArticleAdjustments[tmpArticleKey].quantitySuggested,
        };
      }
    }
    // reset the temporary article adjustments
    this.tmpArticleAdjustments = {};
  }

  private updateCompositeArticles(parcel: Allotment) {
    this.compositeArticles = [];
      for (const point of parcel.points) {
        if (point && point.compositeArticles && point.compositeArticles.length > 0) {
          for (const composite of point.compositeArticles) {
            this.compositeArticles.push(composite);
          }
        }
      }
    this.notifyChildren();
  }
  async updateCArticles(parcelHead: any) {
    this.sa = await <any> new Promise(resolve => {
      this.dataService.getStructureArticles().subscribe(data => {
       resolve(data);
      });
    })
    parcelHead.compositeArticles.forEach(article => {
      const compArt = Object.assign({}, this.sa[article.num]);
      compArt.selectedQuantity = article.selectedQuantity;
      this.compositeArticles.push(compArt);
    })
  }

  public addCompositeArticleToPoints(article: CompositeArticle, quantity: number) {
    if (this.parcel && this.parcel.points) {
      let lastIndex = 0;
      if (this.parcel.points.length > 0) { lastIndex = this.parcel.points.length - 1; }
      this.parcel.points[lastIndex].compositeArticles = this.parcel.points[lastIndex].compositeArticles || [];
      let sameTypeExists = false;
      for (const ca of this.parcel.points[lastIndex].compositeArticles) {
        if (ca.id === article.id) {
          ca.selectedQuantity = ca.selectedQuantity || 0;
          ca.selectedQuantity += quantity;
          sameTypeExists = true;
          break;
        }
      }
      if (!sameTypeExists) {
        article.selectedQuantity = quantity;
        this.parcel.points[lastIndex].compositeArticles.push(article);
      }
      this.updateCompositeArticles(this.parcel);
      this.snackBar.open('Strukturartikkel lagt til i listen', '', { duration: 5000 });
    }

  }
  private addArticleToList(article: Article, quantity: number, showSnackbar: boolean) {
    const regularArticle = article as Article;
    // always use adjusted when adding articles manually
    regularArticle.quantityOld = 0;
    regularArticle.quantitySuggested = 0;
    regularArticle.quantityTotal = 0;
    regularArticle.quantityNew = 0;
    if (this.articleAdjustments.hasOwnProperty(regularArticle.id)) {
      this.articleAdjustments[regularArticle.id].quantityAdjusted += quantity;
      this.accumulatedArticles = [].concat(this.accumulatedArticles);
    } else {
      this.articleAdjustments[regularArticle.id] = {
        quantityAdjusted: quantity,
        quantityTotal: quantity,
        quantityUsed: 0,
        quantitySuggested: 0,
      };
      this.accumulatedArticles = [].concat(this.accumulatedArticles).concat([regularArticle]);
    }
    if (showSnackbar) {
      this.snackBar.open('Artikkel lagt til i listen', '', { duration: 5000 });
    }
  }
  saveAndConfirmLength() {
    const postAllotment = this.createAllotmentToSave();
    this.savingInProgress = true;
    this.dataService.saveAllotment(postAllotment).subscribe(res => {
      this.savingInProgress = false;
      if (res) {
        this.parcel.isComplete = true;
        this.parcel.state = Allotment.STATE_REGISTRATION_COMPLETE;
        this.snackBar.openFromComponent(LengthConfirmedSnackbarComponent, {
          duration: 2000
        });
      }
    }, catchError(err => {
      this.savingInProgress = false;
      return null;
    }));
  }
  goToOrders() {
    const urlTree = this.router.createUrlTree(['/order/' + this.orderUnid]);
    this.router.navigateByUrl(urlTree);
  }
  onArticleChanged(event) {
    this.updateBuildLength();
  }
  private getSaJson() {
    this.dataService.getStructureArticles().subscribe(data => {
      this.sa = data;
    });
  }
  private updateBuildLength() {
    this.buildLength = this.measureService.getBuildLength(this.accumulatedArticles, this.articleAdjustments);
  }
  private deleteArticlesAndRemoveVerifyFlag() {
    this.accumulatedArticles = [];
    this.articleAdjustments = {};
    for (const p of this.parcel.points) {
      p.verifyArticlesPoint = false;
    }
  }
  private redrawTrack() {
    this.markerGroup.clearLayers();
    const tmpPoints = [];
    for (const p of this.parcel.points) {
      tmpPoints.push(p);
    }
    this.parcel.points = [];
    for (const p of tmpPoints) {
      const isCountingPoint = p.verifyArticlesPoint;
      this.saveMarker(p, isCountingPoint, false, false);
    }
    this.updatePolyLine();
  }

  saveParsell() {
    const postAllotment = new Allotment();
    postAllotment.appVersion = this.electronService.getVersion();
    postAllotment.title = this.parcelHeader.title;
    postAllotment.unid = this.parcelHeader.unid;
    postAllotment.state = Allotment.STATE_REGISTRATION_COMPLETE;
    postAllotment.docId = this.parcelHeader.docId;
    postAllotment.sideOfRoad = this.parcelHeader.sideOfRoad;
    postAllotment.lengthFromOrder = this.parcelHeader.lengthFromOrder;
    postAllotment.customer = this.parcelHeader.customer;
    postAllotment.rtType = this.selectedRailingType.id;
    if (this.startArticle && this.endArticle) {
      postAllotment.startType = this.startArticle.num + " - " + this.startArticle.id;
      postAllotment.endType = this.endArticle.num + " - " + this.endArticle.id;
    } else if (this.parcelHeader.startType && this.parcelHeader.endType) {
      this.parcelHeader.compositeArticles.forEach(art => {
        this.parcelHeader.startType === art.num ?  (postAllotment.startType = this.parcelHeader.startType + " - " + art.name) : '00'
        this.parcelHeader.endType === art.num ?  postAllotment.endType = this.parcelHeader.endType + " - " + art.name : '00'
      })
    }
    postAllotment.isComplete = true;
    postAllotment.user = this.userService.userName || "";
    postAllotment.orderUnid = this.order.unid;
    postAllotment.orderNum = this.order.snum;
    postAllotment.afterReg = false;
    postAllotment.roadSection = this.parcelHeader.roadSection;
    postAllotment.allotmentLastSaved = new Date();
    postAllotment.distance = this.lengde;
    postAllotment.demolitionLength = this.demolitionLength;
    postAllotment.demolitionRailWreck = this.demolitionRailWreck;
    postAllotment.demolitionStickWreck = this.demolitionStickWreck;
    const verifyArticlePoint = new VerifyArticlesPoint();
    verifyArticlePoint.measuringPoints = [];
    verifyArticlePoint.articles = [];
    this.accumulatedArticles.forEach(art => {
      verifyArticlePoint.articles.push(Object.assign({}, art)); // shallow copy, preventing the original article to be changed
    });
    for (const a of verifyArticlePoint.articles) {
      if (this.articleAdjustments[a.id]) {
        // XXX need to change the values in order to make the material list on the intranet correct.
        // XXX old and new are not used in GUI in electron app, so it is safe to change them when posting.
        a.quantityTotal = this.measureArticles.formControlMap[a.id].totalControl.value;
        a.quantityOld = this.measureArticles.formControlMap[a.id].usedControl.value;
        a.quantityNew = a.quantityTotal - a.quantityOld;
      }
    }
    postAllotment.verifyArticlesPoints = [verifyArticlePoint];
    postAllotment.compositeArticles = [];
    this.compositeArticles.forEach(articles => {
      postAllotment.compositeArticles.push({
        num: articles.num,
        name: articles.name,
        selectedQuantity: articles.selectedQuantity.toString()
      });
    })
    this.savingInProgress = true;
    console.log(JSON.stringify(postAllotment, null, 2));
    this.dataService.saveAllotment(postAllotment).subscribe(res => {
      this.savingInProgress = false;
      console.log('res', JSON.stringify(res, null, 2));
      if (res) {
        this.parcel.isComplete = true;
        this.parcel.state = Allotment.STATE_REGISTRATION_COMPLETE;
        this.snackBar.openFromComponent(LengthConfirmedSnackbarComponent, {
          duration: 2000
        });
      }
    }, catchError(err => {
      this.savingInProgress = false;
      return null;
    }));
  }
  /**
   * Used to create an object that matches the way GeTac saved the allotment.
   * @returns {Allotment}
   */
  private createAllotmentToSave() {
    const postAllotment = new Allotment();
    postAllotment.appVersion = this.electronService.getVersion();
    postAllotment.startType = "00";
    postAllotment.endType = "00";
    postAllotment.isComplete = true;
    postAllotment.state = Allotment.STATE_REGISTRATION_COMPLETE;
    postAllotment.user = this.userService.userName || "";
    postAllotment.lengthFromOrder = this.parcelHeader.lengthFromOrder;
    postAllotment.unid = this.parcelHeader.unid;
    postAllotment.title = this.parcelHeader.title;
    postAllotment.docId = this.parcelHeader.docId;
    postAllotment.roadSection = this.parcelHeader.roadSection;
    postAllotment.orderUnid = this.parcelHeader.orderUnid;
    postAllotment.rtType = this.selectedRailingType.id;
    postAllotment.sideOfRoad = this.parcelHeader.sideOfRoad;
    postAllotment.afterReg = false;
    postAllotment.customer = this.parcelHeader.customer;
    postAllotment.qualityAuditLocationId = this.parcel.qualityAuditLocationId;
    postAllotment.qualityQuestionCompleted = this.parcel.qualityQuestionCompleted;
    postAllotment.qualityQuestionText = this.parcel.qualityQuestionText;
    postAllotment.qualityQuestion = this.parcel.qualityQuestion;
    postAllotment.qualityQuestionAnswered = this.parcel.qualityQuestionAnswered;
    postAllotment.qualityAnswer = this.parcel.qualityAnswer;
    postAllotment.stopEndFirst = this.parcel.stopEndFirst;

    try {
      // sett start og slutt type fra første og siste monteringspunkt.
      for (let i = 0; i < this.parcel.points.length; i++) {
        const point = this.parcel.points[i];
        if (point.state % 10 !== 0) {
          for (let j = 0; j < point.compositeArticles.length; j++) {
            const compArt = point.compositeArticles[j];
            if (compArt.category === 'Ende') {
              postAllotment.startType = compArt.num + " - " + compArt.id;
              break;
            }
          }
          break;
        }
      }
      for (let i = this.parcel.points.length - 1; i >= 0; i--) {
        const point = this.parcel.points[i];
        if (point.state % 10 !== 0) {

          // find end composite article
          for (let j = 0; j < point.compositeArticles.length; j++) {
            const compArt = point.compositeArticles[j];
            if (compArt.category === 'Ende') {
              postAllotment.endType = compArt.num + " - " + compArt.id;
              break;
            }
          }
          break;
        }
      }
    } catch (e) {
      console.error("error getting end types..");
    }

    postAllotment.orderNum = this.order.snum;
    postAllotment.quality = this.measureService.getLowestQualityFromTrack(this.parcel.points);
    if (postAllotment.quality <= GPS_QUALITY.LOW) {
      postAllotment.quality = GPS_QUALITY.LOW;
    }
    postAllotment.allotmentLastSaved = new Date();
    const verifyArticlePoint = new VerifyArticlesPoint();
    verifyArticlePoint.measuringPoints = [];
    postAllotment.points = [];
    let uniqueId = 0;
    for (const p of this.parcel.points) {
      p.railType = this.selectedRailingType;
      p.uniqueId = "" + uniqueId++;
      p.verified = true;
      if (!p.gga.altitude) { p.gga.altitude = "0"; }
      if (p.state < POINT_STATUS.MODULATOR) { // do not add deleted points.
        verifyArticlePoint.measuringPoints.push(p);
        postAllotment.points.push(p);
      }
    }
    this.measureService.calcDistsanceBetweenPoints(postAllotment.points);
    verifyArticlePoint.articles = [];
    this.accumulatedArticles.forEach(art => {
      verifyArticlePoint.articles.push(Object.assign({}, art)); // shallow copy, preventing the original article to be changed
    });
    for (const a of verifyArticlePoint.articles) {
      if (this.articleAdjustments[a.id]) {
        // XXX need to change the values in order to make the material list on the intranet correct.
        // XXX old and new are not used in GUI in electron app, so it is safe to change them when posting.
        a.quantityTotal = this.measureArticles.formControlMap[a.id].totalControl.value;
        a.quantityOld = this.measureArticles.formControlMap[a.id].usedControl.value;
        a.quantityNew = a.quantityTotal - a.quantityOld;
      }
    }
    postAllotment.verifyArticlesPoints = [verifyArticlePoint];
    return postAllotment;
  }

  getTotalDistance(points: Array<MeasuringPoint>): number {
    let tempDist = 0;
    for (const p of points) {
      tempDist += p.distanceFromPreviousPoint;
    }
    return tempDist;
  }
  disableGps(e) {
    console.log(e);
    if (e.checked === true) {
      this.gpsDisabled = true;
    } else {
      this.gpsDisabled = false;
    }
  }
  private initExistingArticlesFromOrder(parcelHead: AllotmentHeader) {
    if (parcelHead.verifyArticlesPoints) {
      this.articleAdjustments = {};
      for (const verifyArticlePoint of parcelHead.verifyArticlesPoints) {
        for (const a of verifyArticlePoint.articles) {
          if (!this.articleAdjustments[a.id]) {
            this.articleAdjustments[a.id] = {
              quantityAdjusted: a.quantityTotal - a.quantitySuggested,
              quantityTotal: a.quantityTotal,
              quantitySuggested: a.quantitySuggested,
              quantityUsed: a.quantityOld
            };
          } else {
            this.articleAdjustments[a.id].quantityAdjusted += a.quantityTotal - a.quantitySuggested;
            this.articleAdjustments[a.id].quantityTotal += a.quantityTotal;
            this.articleAdjustments[a.id].quantitySuggested += a.quantitySuggested;
            this.articleAdjustments[a.id].quantityUsed += a.quantityOld;
          }
          this.accumulatedArticles.push(a);
        }
      }
    }
  }
}
