<form name="projectLineForm" ng-submit="submitForm()" class="form-inline" [formGroup]="timesheetProjectForm"
      [class.mini-grid]="!showFullWeek" [class.mega-grid]="showFullWeek">
      <div class="projectLine" *ngFor="let row of projectRows.controls; let ind = index;">
            <div formArrayName="projectLine" *ngFor="let field of formFields">
                  <div [formGroupName]="ind">
                        <div class="form-group">
                              <div *ngIf="field.type == 'text'" class="hours-input" [hidden]="!field.dayVisible">
                                    <mat-form-field>
                                          <input type="number" matInput [placeholder]=""
                                                [formControlName]="field.formControl"
                                                (click)="addCommentBox(field,ind)" />
                                    </mat-form-field>
                              </div>
                              <div class="project-select"
                                    *ngIf="field.type == 'select' && field.name == 'Project Select'">
                                    <div [hidden]="ind < saveIndex">
                                          <mat-form-field>
                                                <mat-label>Velg Projekt</mat-label>
                                                <mat-select [formControlName]="field.formControl" #singleSelect
                                                      (ngModelChange)="projectSelect($event)">
                                                      <mat-option>
                                                            <ngx-mat-select-search
                                                                  [formControlName]="field.formControl+'filter'"
                                                                  placeholderLabel="Velg Projekt" i18n-placeholderLabel
                                                                  noEntriesFoundLabel="'ingen angitt'"
                                                                  i18n-noEntriesFoundLabel
                                                                  (ngModelChange)="projectSearch(ind)">
                                                            </ngx-mat-select-search>
                                                      </mat-option>
                                                      <mat-option *ngFor="let project of filteredProjects | async"
                                                            [value]="project.projNum">
                                                            {{project.projNum + ' '}} {{project.projName}}
                                                      </mat-option>
                                                </mat-select>
                                          </mat-form-field>
                                    </div>
                                    <div *ngIf="ind < saveIndex && weekProjects.length > 0">
                                          <!--  {{timesheetProjectForm.controls['projectLine'].controls[ind].controls['projectNumber'].value}} -->
                                          <p> {{ weekProjects[ind].projectNumber+" "+ weekProjects[ind].projectName }}
                                          </p>
                                    </div>
                              </div>

                              <div class="projectCode-select"
                                    *ngIf="field.type == 'select' && field.name == 'SalaryCode Select'">
                                    <div [hidden]="ind < saveIndex">
                                          <mat-form-field>
                                                <mat-label>Velg code</mat-label>
                                                <mat-select [formControlName]="field.formControl">
                                                      <mat-option *ngFor="let code of selectableSalaryCode"
                                                            [value]="code.salaryCode[0]">
                                                            {{code.salaryCode[0]+" "+code.salaryCode[1]}}
                                                      </mat-option>
                                                </mat-select>
                                          </mat-form-field>
                                    </div>
                                    <div *ngIf="ind < saveIndex && weekProjects.length > 0">
                                          <p> {{ weekProjects[ind].salaryCode + " " + weekProjects[ind].salaryCodeName}}
                                          </p>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
            <div formArrayName="projectLine" *ngFor="let field of formFields" [class]="field.formControl+'-comment'"
                  [hidden]="!field.visible || !rowVisible[ind]">
                  <div [formGroupName]="ind">
                        <div class="comment-input" *ngIf="field.type == 'text'">
                              <mat-form-field>
                                    <textarea matInput [placeholder]="'Kommentar '+field.day"
                                          [formControlName]="field.formControl+'comment'"></textarea>
                              </mat-form-field>

                        </div>
                  </div>
            </div>
            <div formArrayName="projectLine" *ngFor="let field of formFields" [class]="field.formControl+'-salary'"
                  [hidden]="!field.visible || !rowVisible[ind]">
                  <div [formGroupName]="ind">
                        <div class="comment-input" *ngIf="field.type == 'text'">
                              <!-- <mat-form-field> -->
                                    <mat-checkbox [formControlName]="field.formControl+'salary'" class="example-margin">Til lønn</mat-checkbox>
                              <!-- </mat-form-field> -->
                        </div>
                  </div>
            </div>
            <div formArrayName="projectLine" *ngFor="let field of formFields" [class]="field.formControl+'-unid'"
                  hidden>
                  <div [formGroupName]="ind">
                        <div class="unid-input" *ngIf="field.type == 'text'">
                              <mat-form-field>
                                    <input matInput [placeholder]="" [formControlName]="field.formControl+'unid'" />
                              </mat-form-field>
                        </div>
                  </div>
            </div>
      </div>
      <div *ngIf="this.empId == this.currentUserId || isAdmin">
            <button mat-raised-button color="primary" (click)="addProjectLineToFormArray()">Ny Prosjektlinje</button>
            <button mat-raised-button color="primary" class="save-button" (click)="saveWeekData()"
                  [disabled]="this.projectRows.length < 1">Lagre</button>
      </div>
</form>