import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Sign } from './sign';
import { SignLog } from './sign-log';
import { UserService } from '../user.service';
import { AppConfig } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../user.service";
var SjaService = /** @class */ (function () {
    function SjaService(http, userService) {
        this.http = http;
        this.userService = userService;
        this.apiUrl = AppConfig.apiUrl;
    }
    SjaService.prototype.getOrder = function (orderUnid) {
        var url = this.apiUrl + "/orders/" + orderUnid + "/sja";
        return this.http.get(url).pipe(map(function (res) {
            return res.data;
        }));
    };
    SjaService.prototype.getOrderSjaArchive = function (orderUnid) {
        var url = this.apiUrl + "/orders/" + orderUnid + "/sja";
        return this.http.get(url).pipe(map(function (res) {
            return res.data;
        }));
    };
    SjaService.prototype.getWorkExamples = function () {
        var url = this.apiUrl + "/orders/sja/signplans";
        return this.http.get(url).pipe(map(function (res) {
            res.data.unshift(res.data.pop()); // move the last element in the array first.
            return res.data;
        }));
    };
    SjaService.prototype.getSignsIcons = function () {
        var url = this.apiUrl + "/orders/sja/signs";
        return this.http.get(url).pipe(map(function (res) {
            return res.data;
        }));
    };
    SjaService.prototype.saveSja = function (orderUnid, sja) {
        var url = this.apiUrl + "/orders/" + orderUnid + "/sja";
        return this.http.post(url, sja).pipe(map(function (res) {
            return res.data;
        }));
    };
    SjaService.prototype.deleteDayComment = function (orderUnid, day) {
        var url = this.apiUrl + "/orders/" + orderUnid + "/sja/day/delete/" + day.unid;
        return this.http.get(url);
    };
    SjaService.prototype.saveDayComment = function (orderUnid, day) {
        var url = this.apiUrl + "/orders/" + orderUnid + "/sja/day";
        return this.http.post(url, day).pipe(map(function (res) {
            return res.data;
        }));
    };
    SjaService.prototype.saveSignsOnSja = function (orderUnid, sja) {
        var url = this.apiUrl + "/orders/" + orderUnid + "/sja/signs";
        return this.http.post(url, sja).pipe(map(function (res) {
            return res.data;
        }));
    };
    SjaService.prototype.removeAllSigns = function (signs, night) {
        var _this = this;
        if (signs) {
            signs.forEach(function (sign) {
                var signStatus = sign.log[sign.log.length - 1].status;
                if (signStatus === Sign.PLACED || signStatus === Sign.VERIFIED) {
                    _this.removeSign(sign, night);
                }
            });
        }
    };
    SjaService.prototype.removeSign = function (sign, night) {
        var log = new SignLog();
        if (night) {
            log.status = Sign.REMOVED_FOR_NIGHT;
            log.comment = 'Tatt inn for kvelden.';
        }
        else {
            log.status = Sign.REMOVED;
        }
        log.user = this.userService.commonName;
        log.date = new Date().getTime();
        sign.log.push(log);
    };
    SjaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SjaService_Factory() { return new SjaService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserService)); }, token: SjaService, providedIn: "root" });
    return SjaService;
}());
export { SjaService };
