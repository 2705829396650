import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RailingType} from '../../railing-type';
import { DataService} from '../../data.service';

@Component({
  selector: 'app-railing-categories-select',
  templateUrl: './railing-categories-select.component.html',
  styleUrls: ['./railing-categories-select.component.scss']
})
export class RailingCategoriesSelectComponent implements OnInit {

  railingTypes = new Map<{category: string}, Array<RailingType>>();
  railingCategories = new Array<string>();
  formGroup: FormGroup;
  typeFormControl: FormControl;
  catFormControl: FormControl;
  @Input() railingType: RailingType;
  @Input() isDemolition: boolean;
  @Output() railingTypeChanged: EventEmitter<RailingType> = new EventEmitter<RailingType>();
  constructor(private dataService: DataService) {
  }

  ngOnInit() {
    const railingCategory = (this.railingType && this.railingType.category) ? this.railingType.category : 'Annet';
    this.catFormControl = new FormControl(railingCategory, [Validators.required]);
    this.typeFormControl = new FormControl(this.railingType, [Validators.required]);

    this.formGroup = new FormGroup({'': new FormControl('', [])});
    this.formGroup.addControl('typeFormControl', this.typeFormControl);
    this.formGroup.addControl('catFormControl', this.catFormControl);
    this.dataService.getRailTypesCategories().subscribe(data => {
      this.railingTypes = data;
      for (const cat in data) {
        this.railingCategories.push(cat);
        for (const type of data[cat]) {
          if (this.railingType && this.railingType.id === type.id) { this.typeFormControl.setValue(type); }
        }
      }
      if (this.isDemolition && !this.railingType.id) {
        // set default value for select.
        this.typeFormControl.setValue(data["Skinnerekkverk veg"][11]);
        this.catFormControl.setValue("Skinnerekkverk veg");
        this.railingTypeChanged.emit(this.typeFormControl.value);
      }

    });
  }

  onRailingTypeChanged(event) {
    this.railingTypeChanged.emit(this.typeFormControl.value);
  }
}
