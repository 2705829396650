<div class="dialog" [class.mobile-view]="isMobileView">
      <h2>Samlet artikkelforbruk ordre {{ orderSnum }}</h2>
      <button class="btn-close" color="accent" type="button" mat-icon-button (click)="closeDialog()">
            <mat-icon aria-label="Close">close</mat-icon>
      </button>
      <form class="frmNewArticle" [formGroup]="dialogFormGroup" *ngIf="(orderStatus && orderStatus < 7) && !readonly">
            <!-- <mat-form-field >
                  <mat-label> Parsell </mat-label>
                  <mat-select formControlName="parsell">
                        <mat-option *ngFor="let parcel of parcels" [value]="parcel.title"> parsell {{ parcel.title }} </mat-option>
                  </mat-select>
            </mat-form-field> -->
            <mat-form-field class="articleSelect">
                  <mat-label>Velg artikkel</mat-label>
                  <mat-select formControlName="article" required #singleSelect>
                        <mat-option>
                              <ngx-mat-select-search formControlName="articleFilterCtrl"
                                    placeholderLabel="Velg artikkel" i18n-placeholderLabel
                                    noEntriesFoundLabel="'ingen angitt'" i18n-noEntriesFoundLabel>
                              </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let article of filteredArticles | async" [value]="article.id">{{
                              article.id
                              }} - {{ article.name }}</mat-option>
                  </mat-select>
            </mat-form-field>
            <mat-form-field class="articleCount">
                  <mat-label>Antall:</mat-label>
                  <input matInput type="number" appCustomIncDecBtns maxlength="5" formControlName="count" required>
            </mat-form-field>
            <button class="btn-create" [ngClass]="isNightMode ? 'greyButton' : ''" color="primary" type="button" mat-raised-button
                  [disabled]="dialogFormGroup.invalid" (click)="saveWorkArticle()"> Legg til</button>
      </form>
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
            <ng-container matColumnDef="{{column}}" *ngFor="let column of articleColumns; index as i">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{column}}
                  </th>
                  <td mat-cell *matCellDef="let element">

                        <span class="mobile-label">{{column}}: </span>
                        {{ element[column] }}
                  </td>
            </ng-container>
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="articleColumns.length">
                        <mat-icon (click)="expandedElement = expandedElement === element ? null : element">
                              {{expandedElement === element ? 'expand_less' : 'expand_more'}}</mat-icon>
                        <div class="example-element-detail" *ngIf="element.parsell?.data.length"
                              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                              <div class="inner-table" *ngIf="expandedElement">

                                    <table #innerTables mat-table [dataSource]="element.parsell">
                                          <ng-container matColumnDef="{{innerColumn}}"
                                                *ngFor="let innerColumn of parsellColumns">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{innerColumn}}
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                      <span class="mobile-label">{{innerColumn}}: </span>
                                                      {{element[innerColumn]}}
                                                </td>
                                          </ng-container>
                                          <!-- <tr mat-header-row *matHeaderRowDef="parsellColumns"></tr> -->
                                          <tr mat-row *matRowDef="let row; columns: parsellColumns;"></tr>
                                    </table>
                              </div>
                        </div>
                  </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="articleColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: articleColumns;"
                  [class.example-element-row]="element.parsell?.data.length"
                  [class.example-expanded-row]="expandedElement === element" (click)="toggleRow(element)">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
      <h3 class="summary-line">Sum byggelengde (materiell): {{buildLength}}, monteringslengde: {{buildLength}} meter
      </h3>
</div>