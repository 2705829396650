import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TimesheetProjectRoutingModule } from './timesheet-project-routing.module';
import { TimesheetProjectComponent } from './timesheet-project/timesheet-project.component';
import { CreateProjectLineComponent } from './create-project-line/create-project-line.component';
import { CreateProjectLineMobileComponent } from './create-project-line-mobile/create-project-line-mobile.component';

@NgModule({
  imports: [
    CommonModule,
    TimesheetProjectRoutingModule,
    SharedModule
  ],
  declarations: [TimesheetProjectComponent, CreateProjectLineComponent, CreateProjectLineMobileComponent]
})
export class TimesheetProjectModule { }
