/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./order-article.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./order-article.component";
import * as i3 from "../../settings.service";
var styles_OrderArticleComponent = [i0.styles];
var RenderType_OrderArticleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderArticleComponent, data: {} });
export { RenderType_OrderArticleComponent as RenderType_OrderArticleComponent };
export function View_OrderArticleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "agjerde-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, ":svg:svg", [["enable-background", "new 0 0 24 24"], ["fill", "black"], ["height", "18px"], ["viewBox", "0 0 24 24"], ["width", "18px"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:rect", [["fill", "none"], ["height", "24"], ["width", "24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:path", [["d", "M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12.88,17.76V19h-1.75v-1.29 c-0.74-0.18-2.39-0.77-3.02-2.96l1.65-0.67c0.06,0.22,0.58,2.09,2.4,2.09c0.93,0,1.98-0.48,1.98-1.61c0-0.96-0.7-1.46-2.28-2.03 c-1.1-0.39-3.35-1.03-3.35-3.31c0-0.1,0.01-2.4,2.62-2.96V5h1.75v1.24c1.84,0.32,2.51,1.79,2.66,2.23l-1.58,0.67 c-0.11-0.35-0.59-1.34-1.9-1.34c-0.7,0-1.81,0.37-1.81,1.39c0,0.95,0.86,1.31,2.64,1.9c2.4,0.83,3.01,2.05,3.01,3.45 C15.9,17.17,13.4,17.67,12.88,17.76z"]], [[1, "fill", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isNightMode ? "white" : "black"); _ck(_v, 5, 0, currVal_0); }); }
export function View_OrderArticleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-order-article", [], [[2, "agjerde-icon-container", null]], null, null, View_OrderArticleComponent_0, RenderType_OrderArticleComponent)), i1.ɵdid(1, 245760, null, 0, i2.OrderArticleComponent, [i3.SettingsService, i1.Renderer2, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hostClass; _ck(_v, 0, 0, currVal_0); }); }
var OrderArticleComponentNgFactory = i1.ɵccf("app-order-article", i2.OrderArticleComponent, View_OrderArticleComponent_Host_0, {}, {}, []);
export { OrderArticleComponentNgFactory as OrderArticleComponentNgFactory };
