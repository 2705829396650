import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { MatTableDataSource,MatDialog, MatSnackBar } from '@angular/material';
import { MediaService } from '../../shared/media.service';
import { ProposalDetailComponent } from './proposal-detail/proposal-detail.component';
import { CreateNewProposalComponent } from './create-new-proposal/create-new-proposal.component';

@Component({
  selector: 'app-proposals',
  templateUrl: './proposals.component.html',
  styleUrls: ['./proposals.component.scss']
})
export class ProposalsComponent implements OnInit {
  
  loading = true;
  proposals: MatTableDataSource<any>;
  isMobileView: boolean;
  displayedColumns: Array<string> = ['date','proposal','author'];
  
  constructor( private dataService: DataService,
               private mediaService: MediaService,
               private dialog: MatDialog,
               private snackbar: MatSnackBar) {
      this.mediaService.getMedia().subscribe( media => {
        this.isMobileView = media.isMobileView;
     })
  }
  viewProposal(pId: any) {
    const dialogRef = this.dialog.open(ProposalDetailComponent, {
      height: '80%',
      width: '90%',
      data: {proposalId: pId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          if (result.task && result.task.unid) {
            this.snackbar.open("Endringer på proposal er lagret", '', {duration: 5000});
          }
      }
    });
  }
  
  createNewProposal() {
    const dialogRef = this.dialog.open( CreateNewProposalComponent , {
      height: '80%',
      width: '90%',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          if (result && result.unid) {
            this.ngOnInit();
            this.snackbar.open("Ny proposal er lagret", '', {duration: 5000});
          }
      }
    });
  }
  ngOnInit() {
    this.dataService.getQualityProposals().subscribe(data => {
      this.proposals = new MatTableDataSource(data);     
    });
  }

}
