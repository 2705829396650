import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AbstractDialogComponent } from '../../components/abstract-dialog/abstract-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StorageService } from '../storage.service';
import { MediaService } from '../../shared/media.service';
import { GpsService } from '../../gps/gps.service';
import { GpsData } from '../../gps-data';
import { IsiRoadRef } from '../../isi-road-ref';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-create-new-storage',
  templateUrl: './create-new-storage.component.html',
  styleUrls: ['./create-new-storage.component.scss']
})
export class CreateNewStorageComponent extends AbstractDialogComponent implements OnInit {
  dialogFormGroup: FormGroup;
  counties: any;
  allTeams = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'Z'];
  teamId = '';
  myTeamId = '';
  orders = [];
  isMobileView: boolean;
  currentPos: { gpsData: GpsData; roadRef: IsiRoadRef; };

  constructor(protected dialogRef: MatDialogRef<AbstractDialogComponent>,
    private storageService: StorageService, 
    private mediaService: MediaService,
    private gpsService: GpsService,
    private userservice: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    super(dialogRef);
    this.mediaService.getMedia().subscribe( media => {
      this.isMobileView = media.isMobileView;
    });
    this.dialogFormGroup = new FormGroup({
      name: new FormControl('', Validators.required),
      team: new FormControl(''),
      county: new FormControl('', Validators.required),
      plannedPosition: new FormControl(''),
      position: new FormControl(''),
      roadnumber: new FormControl(''),
      hp: new FormControl(''),
      kilometer: new FormGroup({
        From: new FormControl(''),
        To: new FormControl('')
      }),
      order: new FormGroup({
        linkOrder: new FormControl('')
      }),
      status: new FormControl(''),
      comments: new FormControl('')
    });
  }

  ngOnInit() {
    this.getCounties();
    this.myTeamId = this.userservice.teamId;
    this.dialogFormGroup.get('team').valueChanges.subscribe(val => {
      this.teamId = val;
      this.getTeamOrders(val);
    });

  }
  getCounties() {
    this.storageService.getCounties().subscribe(data => {
      this.counties = data
    })
  }
  getTeamOrders(teamId: any) {
    this.storageService.getOrderList(teamId).subscribe(data => {
      this.orders = data;
    })
  }
  getCurrentPosition() {
    this.currentPos = this.gpsService.getCurrentPos();
    if (this.currentPos && this.currentPos.gpsData) {
      const lat = this.currentPos.gpsData.lat.toFixed(7);
      const lon = this.currentPos.gpsData.lon.toFixed(7);
      this.dialogFormGroup.patchValue({position: lat+';'+lon });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  confirm(event: any): void {
    const name = this.dialogFormGroup.get('name').value;
    const team = this.teamId;
    const county = this.dialogFormGroup.get('county').value;
    const plannedPosition = this.dialogFormGroup.get('plannedPosition').value;
    const position = this.dialogFormGroup.get('position').value;
    let order : Array<string>  = this.dialogFormGroup.get('order.linkOrder').value;
    const status = this.dialogFormGroup.get('status').value;
    const comments = this.dialogFormGroup.get('comments').value;

    let storage = {
      "subject": name,
      "teamId": team,
      "countyNumber": county,
      "status": "1",
      "comment": comments,
      "gpsPos": plannedPosition,
      "unloadGps": position,
      "order": order
    }
    this.storageService.addStorage(storage).subscribe(data => {
      this.dialogRef.close(data);
    })

  }
}
