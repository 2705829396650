<div class="header">
  <button class="nav-item" mat-button (click)="goTo('home')" *ngIf="isAuth">Ordreliste</button>
  
 
  
  <div *ngIf="!navItems || navItems.length==0" class="placeholder"></div>
  <div class="nav-items" *ngFor="let navItem of navItems; let first = first; let last = last">
    <!--<mat-icon *ngIf="!last" aria-label="chevron">chevron_right</mat-icon>-->
    <button *ngIf="navItem" class="nav-item" [class.last-item]="last" (click)="goTo(navItem.route, navItem.queryParams)" mat-button>{{navItem.label}}</button>
  </div>
  
  <button class="btn-shortcut" type="button" mat-button [matMenuTriggerFor]="menu" (click)="updateRoles()"> <mat-icon aria-label="shortcut">more_horiz</mat-icon> </button>
  <mat-menu #menu="matMenu" >
    <!-- <button mat-menu-item >Bytt bruker</button> -->
    <button mat-menu-item [matMenuTriggerFor]="teams" *ngIf="isAdmin">Bytt lag</button>
    <mat-menu #teams="matMenu">
      <button mat-menu-item *ngFor="let team of allTeams" (click)="changeTeam(team)">Lag {{team}}</button>
    </mat-menu>
    <button mat-menu-item [matMenuTriggerFor]="quality">Kvalitet (intranett)</button>
    <mat-menu #quality="matMenu">
      <button mat-menu-item (click)="openLink('https://intra.agjerde.no/agjerde/intra.nsf/QaShaPlans.xsp')">SHA-planer</button>
      <button mat-menu-item (click)="openLink('https://intra.agjerde.no/agjerde/intra.nsf/QaSigns.xsp')">Varslingsplaner</button>
      <button mat-menu-item (click)="openLink('https://agjerde.ibricks.no/qadocuments')">Styrende dokumenter</button>
      <button mat-menu-item (click)="openLink('https://intra.agjerde.no/agjerde/intra.nsf/QaHms.xsp')"> HMS-datablad</button>
      <button mat-menu-item (click)="openLink('https://intra.agjerde.no/agjerde/intra.nsf/QualityOverview.xsp')">Viktige kvalitetsdata</button>
    </mat-menu>
    <button mat-menu-item color="primary" (click)="goTo('info')" *ngIf="isAuth">Infoside / Oppgaver</button>
    <button mat-menu-item (click)="goTo('/timesheet')" *ngIf="isAuth">Timeføring</button>
    <button *ngIf="!isElectron() && isAuth" mat-menu-item (click)="goTo('/timesheet-project')">Timeføring-Prosjekt</button>
    <button mat-menu-item (click)="createWorkshopTask()" *ngIf="isAuth">Ny verkstedsoppgave</button>
    <button mat-menu-item (click)="openLink('https://agjerde.ibricks.no/incidents/add?incident-type=TYPE_DEVIATION')">Opprett avvik (iBricks)</button>
    <button mat-menu-item (click)="openLink('https://agjerde.ibricks.no/incidents/add?incident-type=TYPE_PROPOSAL')">Forbedringsforslag (iBricks)</button>
    <button mat-menu-item (click)="openLink('https://intra.agjerde.no/agjerde/intra.nsf/MyPage.xsp')">Min Side (intranett)</button>
    <button mat-menu-item (click)="toggleNightMode()">{{isNightMode? 'Slå på dagmodus' : 'Slå på Nattmodus'}}</button>
    <button mat-menu-item (click)="goTo('/camera')" >Kamera</button>
  </mat-menu>
</div>