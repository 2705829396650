import { MediaMatcher } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/layout";
var MediaService = /** @class */ (function () {
    function MediaService(mediaMatcher) {
        var _this = this;
        this.mediaMatcher = mediaMatcher;
        this.media = new BehaviorSubject({ isMobileView: false });
        var isMobileView = window.innerWidth <= 900;
        this.media.next({ isMobileView: isMobileView });
        var mediaQueryList = mediaMatcher.matchMedia('(min-width: 900px)');
        mediaQueryList.addEventListener('change', function (event) { return _this.mediaListener(event); });
    }
    MediaService.prototype.mediaListener = function (event) {
        var isMobileView = !(event && event.matches);
        this.media.next({ isMobileView: isMobileView });
    };
    MediaService.prototype.getMedia = function () {
        return this.media;
    };
    MediaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MediaService_Factory() { return new MediaService(i0.ɵɵinject(i1.MediaMatcher)); }, token: MediaService, providedIn: "root" });
    return MediaService;
}());
export { MediaService };
