<form class="dialog" [formGroup]="dialogFormGroup" (ngSubmit)="confirm($event)">
  <h2>{{isEdit ? 'Endre': 'Nytt' }} risikomoment</h2>
  <button class="btn-close" color="accent" type="button" mat-icon-button (click)="closeDialog()">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
  <section class="select-article-section" [class.mobile-view]="isMobile">
    <mat-form-field>
      <input matInput type="text" formControlName="operation" name="operation" reqiured="true" placeholder="Operasjon" />
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="description" name="description" reqiured="true" placeholder="Faremoment" />
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="measures" name="measures" reqiured="true" placeholder="Tiltak" />
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Ansvarlig" formControlName="responsible" name="responsible" reqiured="true">
        <mat-option *ngFor="let emp of myTeam" [value]="emp">
          {{emp.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </section>
  <button type="submit" class="btn-save success" color="primary" mat-raised-button [disabled]="dialogFormGroup.invalid">
    {{isEdit ? 'Endre': 'Legg til risikomoment' }}
  </button>
</form>