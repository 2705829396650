<div class="home mat-typography" [class.mobile-view]="isMobileView">
	<mat-card class="home-card" multi="true">
		<mat-card-header>
			<mat-card-title style="grid-column: 1/2">
				<h2>Ordreliste</h2>
			</mat-card-title>
			<mat-card-subtitle>(Lag {{userService.teamId}})</mat-card-subtitle>
			<mat-form-field name="orderType" ngDefaultControl style="grid-column: 3;">
				<mat-select [(ngModel)]="selectedType" (selectionChange)="getOrders()">
					<mat-option [value]="1"> Til montering </mat-option>
					<mat-option [value]="2"> Fullførte </mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field style="grid-column: 4">
				<mat-label>Søk</mat-label>
				<input matInput (keyup)="applyFilter($event)" placeholder="Søk">
			</mat-form-field>
			<div style="grid-column: 5">
				<button type="button" mat-button (click)="goToLocationMap()">Vis i kart <mat-icon>map</mat-icon>
				</button>
			</div>
		</mat-card-header>
		<mat-card-content>
			<div *ngIf="loading" class="loading">
				<div>Vennligst vent mens ordrene hentes</div>
				<mat-spinner></mat-spinner>
			</div>

			<div class="orderList-container">

				<table mat-table #table [dataSource]="orderList" [class.isMobile]="isMobileView" matSort>
					<!-- Position Column -->
					<ng-container matColumnDef="snum">
						<mat-header-cell *matHeaderCellDef mat-sort-header>Ordre</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<span class="mobile-label">Ordre:</span>
							<div (click)="onRowClick(element)">
								<strong>{{element?.snum}} {{element?.subject}}</strong>
							</div>
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="customer">
						<mat-header-cell *matHeaderCellDef>Kunde</mat-header-cell>
						<mat-cell *matCellDef="let element" (click)="onRowClick(element)">
							<span class="mobile-label">Kunde:</span>
							{{element?.custName}}
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="customerContact">
						<mat-header-cell *matHeaderCellDef>Kontakt</mat-header-cell>

						<mat-cell *matCellDef="let element" (click)="onRowClick(element)">
							<span class="mobile-label">Kontakt:</span>
							{{element?.custContact}}
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="allotmentHeaders">
						<mat-header-cell *matHeaderCellDef>Parseller</mat-header-cell>
						<mat-cell *matCellDef="let element" (click)="onRowClick(element)">
							<span class="mobile-label">Parseller:</span>
							{{element?.parsCount}}
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="allotmentLength">
						<mat-header-cell *matHeaderCellDef>Lengde</mat-header-cell>
						<mat-cell *matCellDef="let element" (click)="onRowClick(element)">
							<span class="mobile-label">Lengde:</span>
							{{element?.parsLengthFromOrderTotal}} / {{element?.parsLengthFromRegisterTotal}}
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="rivLength">
						<mat-header-cell *matHeaderCellDef>Riv m</mat-header-cell>
						<mat-cell *matCellDef="let element" (click)="onRowClick(element)">
							<span class="mobile-label">Riv m:</span>
							{{element?.rivLengthFromOrderTotal}} / {{element?.rivLengthFromRegisterTotal}}
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="county">
						<mat-header-cell *matHeaderCellDef mat-sort-header>Fylke</mat-header-cell>
						<mat-cell *matCellDef="let element" (click)="onRowClick(element)">
							<span class="mobile-label">Fylke:</span>
							{{element?.countyName}}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="week">
						<mat-header-cell *matHeaderCellDef mat-sort-header>Uke</mat-header-cell>
						<mat-cell *matCellDef="let element" (click)="onRowClick(element)">
							<span class="mobile-label">Uke:</span>
							{{element?.week}}
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="orderSelect">
						<mat-header-cell *matHeaderCellDef>
						</mat-header-cell>
						<mat-cell *matCellDef="let element; let i = index">
							<span class="material-icons" *ngIf="i != 0 " (click)="moveUp(i,element)">
								arrow_upward
							</span>
							<span class="material-icons" *ngIf="i != orders.length-1" (click)="moveDown(i,element)">
								arrow_downward
							</span>
							<!-- <mat-checkbox  (click)="$event.stopPropagation()" (change)="onOrderSelect(element,$event)" >
						  </mat-checkbox> -->
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="modifiedDate">
						<mat-header-cell *matHeaderCellDef mat-sort-header> Sist endret
						</mat-header-cell>
						<mat-cell *matCellDef="let element" (click)="onRowClick(element)">
							<span class="mobile-label">Fylke:</span>
							{{element?.modifiedDate | date: 'dd.MM.yyyy'}}
						</mat-cell>
					</ng-container>
					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>
				</table>
			</div>
		</mat-card-content>
		<mat-card-actions *ngIf="this.selectedType != 2">
			<button mat-raised-button type="button" color="primary" (click)="saveSortOrder()">Lagre sortering</button>
		</mat-card-actions>
	</mat-card>

</div>
<div class="sticky-footer">
	<form #homeFooterForm="ngForm" [formGroup]="footerFormGroup">
		<button mat-raised-button type="button" color="primary"
			(click)="goToTimeSheetRegistration()">Timeføring</button>
		<button mat-raised-button type="button" color="primary" (click)="goToInfoPage()">Info</button>
		<button mat-raised-button type="button" color="primary" (click)="goToStoragePage()">Lager</button>
	</form>
</div>
<app-road-information *ngIf="isElectron"></app-road-information>