<div class="dialogHeader">
      <h1 mat-dialog-title>Nytt Lager</h1>
      <button class="btn-close" color="accent" type="button" mat-icon-button (click)="onNoClick()">
            <mat-icon aria-label="Close">close</mat-icon>
      </button>
</div>
<div class="dialogContent" [class.isMobile]="isMobileView">
      <form  class="new-storage" #addStorageDialogForm = "ngForm" [formGroup]="dialogFormGroup" > 
            <mat-form-field>
                  <input matInput formControlName='name' placeholder="Navn" required>
            </mat-form-field>
            <mat-form-field>
                  <mat-label>Velg lag</mat-label>
                  <mat-select formControlName='team'  [(ngModel)]="myTeamId">
                        <mat-option *ngFor ="let team of allTeams" [value] = "team"> Lag {{ team }} </mat-option>
                  </mat-select>
            </mat-form-field>
            <mat-form-field>
                  <mat-label>Velg fylke</mat-label>
                  <mat-select formControlName='county' required>
                        <mat-option *ngFor="let fylke of counties" [value]="fylke.number"> {{fylke.name}} </mat-option>
                  </mat-select>
            </mat-form-field>
            <div class="position">
                  <h4>Posisjon: </h4>
                  <mat-form-field>
                        <input matInput formControlName='plannedPosition' placeholder="Planlagt posisjon" readonly>
                        <!-- <mat-hint>Angis slik: latitude;longitude (NB: Husk semikolon)</mat-hint> -->
                  </mat-form-field>
                  <span> - </span>
                  <mat-form-field>
                        <input matInput formControlName='position' placeholder="Levert posisjon" readonly>
                  </mat-form-field>
                  <button mat-raised-button (click)="getCurrentPosition()">Hent fra GPS</button>
            </div>
           
           <!--  <mat-form-field>
                  <input matInput formControlName='roadnumber' placeholder="Vegnr">
            </mat-form-field>
            <mat-form-field>
                  <input matInput formControlName='hp' placeholder="HP">
            </mat-form-field>
            <div formGroupName = "kilometer"> 
                  <h4>Kilometer: </h4>
                  <mat-form-field>
                        <input   matInput formControlName='From' placeholder="Fra">
                  </mat-form-field>
                  <span> / </span>
                  <mat-form-field>
                        <input matInput formControlName='To' placeholder="Til">
                  </mat-form-field>
            </div> -->
            <div formGroupName = "order" >
                  <mat-form-field >
                        <mat-label>Velg ordre</mat-label>
                        <mat-select formControlName='linkOrder' [disabled]="teamId == ''" multiple>
                              <mat-option *ngFor ="let order of orders" [value] = 'order?.orderNum'> {{ order?.subject }} </mat-option>
                        </mat-select>
                        <mat-hint *ngIf = "teamId == ''">velg lag først</mat-hint>
                  </mat-form-field>
            </div>
            <br>
            <!-- <div >
                  <mat-radio-group formControlName ='status' aria-label="Status">
                        <label id="status-label">Status:</label>
                        <mat-radio-button value="1">Åpen</mat-radio-button>
                        <mat-radio-button value="2">Arkivert</mat-radio-button>
                  </mat-radio-group>
            </div>
            <br> -->
            <mat-form-field class='storage-comment'>
                  <textarea matInput formControlName='comments' placeholder="Kommentar"></textarea>
            </mat-form-field>
            <div>
            <button type="submit" color="primary" mat-raised-button [disabled]="dialogFormGroup.invalid" (click) ="confirm($event)"> Lagre </button></div>

      </form>
</div>