import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { ElectronService} from './electron.service';

import { Observable, from } from 'rxjs';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private electronService: ElectronService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      if (!this.electronService.isElectron()) {
        let withCredentials = true;
        if (req.url.indexOf('vegvesen.no') !== -1) {
          withCredentials = false;
        }
        req = req.clone({
          withCredentials: withCredentials
        });
      }
      return next.handle(req);
  }
}
