import { Component, OnInit, OnDestroy, HostBinding, ElementRef, Renderer2, HostListener } from '@angular/core';
import { GpsService } from '../../gps/gps.service';
import { Subscription } from 'rxjs';
import { GpsQuality } from '../../gps/gps-quality';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { RoadRefPipe } from '../../road-ref.pipe';
import { ElectronService } from '../../electron.service';
import { take, timeout } from 'rxjs/operators';
import { UserService } from '../../user.service';
import { RoadInformationService } from '../../road-information.service';
import { IsiRoadRef } from '../../isi-road-ref';
import { GpsData } from '../../gps-data';

@Component({
  selector: 'app-road-information',
  templateUrl: './road-information.component.html',
  styleUrls: ['./road-information.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '48px',
        width: '350px',
      })),
      state('closed', style({
        height: '48px',
        width: '48px',
      })),
      transition('open => closed', [
        animate('350ms ease-in-out')
      ]),
      transition('closed => open', [
        animate('350ms ease-in-out')
      ]),
    ]),
  ],
})
export class RoadInformationComponent implements OnInit, OnDestroy {
  @HostBinding('class.road-information-component') hostClass = true;
  gpsSub: Subscription;
  isOpen = false;
  isOpening = true
  isClosing = true;
  satQuality = 1;
  roadRef: any;
  gpsData: GpsData;
  private gpsQuality: GpsQuality = new GpsQuality();
  constructor(
    private gpsService: GpsService,
    private userService: UserService,
    private roadInformationService: RoadInformationService) {
    this.isOpen = this.roadInformationService.isOpen;
    this.userService.getUser().subscribe(user => {
      if (!this.gpsSub && user) {
        this.getMyPosition();
      }
    });
  }
  getMyPosition = () => {
    this.gpsSub = this.gpsService.getObservable().pipe().subscribe(gpsAndRoadRef => {
      const gpsData = gpsAndRoadRef.gpsData;
      this.gpsData = gpsData;

      if (gpsData && gpsData && gpsData.lat !== 0.0 && gpsData.type !== 'RMC') {
        this.satQuality = this.gpsQuality.getGpsQuality(gpsData.quality, gpsData.hdop);
        this.roadRef = gpsAndRoadRef.roadRef;
        if (this.roadRef) {
          if (this.roadRef.curve) {
            this.roadRef.municipality = this.roadRef.curve.municipality;
          }
        } else {
          this.roadRef = {
            curve: {
              streetName: "Ingen vegdata."
            },
            meter: 0,
            roadNumber: "--",
            section: '-',
            roadCategory: '-',
            roadStatus: '-'
          }
        }
      } else {
        this.satQuality = 0;
        this.roadRef = {
        curve: {
          streetName: "Ingen GPS data."
        },
        meter: 0,
        roadNumber: "--",
        section: '-',
        roadCategory: '-',
        roadStatus: '-'
      }
      }
    }, err => {
      console.log("err getting gps pos:", err);
      this.satQuality = 0;
      this.roadRef = {
        curve: {
          streetName: "Ingen GPS data."
        },
        meter: 0,
        roadNumber: "--",
        section: '-',
        roadCategory: '-',
        roadStatus: '-'
      }
      if (this.gpsSub) {
        console.log("got error, try to unsubscribe")
        this.gpsSub.unsubscribe();
        //this.gpsService.stopGps();
      }

      setTimeout(() => {
        console.log('try to connect again..');
        // this.gpsService.setupGps();
        this.getMyPosition();
      }, 2000);
    });
  }
  ngOnInit() {
  }
  ngOnDestroy() {
    if (this.gpsSub) {
      this.gpsSub.unsubscribe();
    }
  }
  toggleRoadInfo(event: MouseEvent) {
    if (!this.isOpening && !this.isClosing) {
      this.roadInformationService.isOpen = !this.roadInformationService.isOpen;
      this.isOpen = this.roadInformationService.isOpen;
    }
  }
  onAnimationEvent(event: AnimationEvent) {
    this.isClosing = false;
    this.isOpening = false;
    if (event && event.fromState === 'open' && event.phaseName === 'start') {
      this.isClosing = true;
    } else if (event && event.fromState === 'closed' && event.phaseName === 'start') {
      this.isOpening = true;
    } else {
    }
  }
}
