import { Component, OnInit, Inject } from '@angular/core';
import { AbstractDialogComponent } from '../../abstract-dialog/abstract-dialog.component';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from '../../../user.service';
import { DataService } from '../../../data.service';
import { OrderComment } from '../../../order-comment';

@Component({
  selector: 'app-order-comment-dialog',
  templateUrl: './order-comment-dialog.component.html',
  styleUrls: ['./order-comment-dialog.component.scss']
})
export class OrderCommentDialogComponent extends AbstractDialogComponent implements OnInit {
  dialogFormGroup: FormGroup;
  data: any;

  constructor(protected dialogRef: MatDialogRef<AbstractDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any,
    private user: UserService,
    private dataService: DataService) {
    super(dialogRef);
    this.data = data;

    this.dialogFormGroup = new FormGroup({
      body: new FormControl('', Validators.required),
    });
    if (data.comment) {
      this.dialogFormGroup.get('body').setValue(data.comment.body);
    }
  }


  ngOnInit() {
  }

  confirm(event) {
    const orderComment: OrderComment = new OrderComment();
    orderComment.body = this.dialogFormGroup.get('body').value;
    orderComment.teamId = this.user.teamId;
    orderComment.user = this.user.commonName;
    if (this.data.comment) {
      orderComment.unid = this.data.comment.unid;
    }

    this.dataService.saveOrderComment(this.data.orderUnid, orderComment).subscribe( comment => {
      this.dialogRef.close(this.data);
    });
  }

}
