<form class="dialog" #countDialogForm="ngForm" [formGroup]="dialogFormGroup" (ngSubmit)="confirm($event)">
  <h2>Stikkprøve</h2>
  <div class="question">
    {{qualityAudit?.question}}
  </div>
  <div class="answer">
    <div *ngIf="qualityAudit?.questionType===1">
      <mat-radio-group [formControl]="yesNoControl">
        <mat-radio-button value="Ja">Ja</mat-radio-button>
        <br>
        <br>
        <mat-radio-button  value="Nei">Nei</mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="qualityAudit?.questionType===2">
      <mat-form-field>
        <input matInput [formControl]="textControl" type="text">
      </mat-form-field>
    </div>
  </div>
  <button type="submit" class="btn-save success" color="primary" mat-raised-button [disabled]="!dialogFormGroup.valid">
    Fullfør stikkprøve
  </button>
  <div class="placeholder"></div>
</form>
