var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../data.service';
import { take } from 'rxjs/operators';
import { UserService } from '../../user.service';
import * as moment from 'moment';
import { TaskService } from '../../task.service';
import { AppConfig } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CameraComponent } from '../camera/camera.component';
import { ElectronService } from '../../electron.service';
var WorkshopTaskDialogComponent = /** @class */ (function (_super) {
    __extends(WorkshopTaskDialogComponent, _super);
    function WorkshopTaskDialogComponent(dialogRef, dialog, data, http, electronService, dataService, taskService, user) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.dialog = dialog;
        _this.http = http;
        _this.electronService = electronService;
        _this.dataService = dataService;
        _this.taskService = taskService;
        _this.user = user;
        _this.projects = [];
        _this.weeks = [];
        _this.fd = new FormData();
        _this.takepicture = false;
        _this.data = data;
        _this.dialogFormGroup = new FormGroup({
            body: new FormControl('', Validators.required),
            projNum: new FormControl('', Validators.required),
            weekSelector: new FormControl()
        });
        return _this;
    }
    WorkshopTaskDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.data.task && !this.data.task.body) {
            // auto-task doesn't have a body field, only subject.
            this.data.task['body'] = this.data.task.subject;
        }
        this.dataService.getProjects().pipe(take(1)).subscribe(function (projs) {
            _this.projects = projs;
        });
        if (this.data.task) {
            this.dialogFormGroup.get('weekSelector').setValue(this.data.task.weekNumber);
            this.dialogFormGroup.get('projNum').setValue(this.data.task.projNum);
            this.dialogFormGroup.get('body').setValue(this.data.task.body);
            if (this.data.task.imageName) {
                this.putImage(this.data.task.replicaId, this.data.task.unid, this.data.task.imageName);
            }
        }
        else {
            this.setDefaultProject();
        }
        this.getWeeks();
        var m = moment();
        this.dialogFormGroup.get('weekSelector').setValue(m.isoWeek() + "/" + m.year());
        if (this.data.task && this.data.task.status === 9) {
            this.dialogFormGroup.disable();
        }
    };
    WorkshopTaskDialogComponent.prototype.putImage = function (replicaId, unid, imgName) {
        var src = AppConfig.apiUrl + "/attachment/" + replicaId + "/" + unid + "?file=" + imgName;
        if (!this.electronService.isElectron()) {
            src = "https://files.agjerde.no/v3/" + replicaId + "/" + unid + "/" + imgName;
        }
        var img = document.createElement('img');
        img.src = src;
        img.width = 300;
        img.className = '';
        var childCount = document.getElementById("snapshot").childElementCount;
        var child = document.getElementById("snapshot");
        if (childCount > 0)
            child.removeChild(child.childNodes[0]);
        document.getElementById('snapshot').appendChild(img);
    };
    WorkshopTaskDialogComponent.prototype.confirm = function () {
        var _this = this;
        var taskData = this.dialogFormGroup.value;
        if (this.data.task) {
            this.fd.append('unid', this.data.task.unid);
        }
        var snum = Math.floor((Math.random() * 100000) + 10000).toString();
        this.fd.append('taskSNum', snum);
        this.fd.append('projNum', taskData['projNum']);
        this.fd.append('body', taskData['body']);
        this.fd.append('weekSelector', taskData['weekSelector']);
        // this.camera.stopCamera();
        this.taskService.saveWorkshopTask(this.fd).subscribe(function (task) {
            _this.data = { task: task };
            /*  var snum = Math.floor((Math.random() * 100000) + 10000).toString();
             this.fd.append('unid', task.unid);
             this.fd.append('taskSNum', snum); */
            _this.dialogRef.close(_this.data);
        });
    };
    WorkshopTaskDialogComponent.prototype.compareFn = function (v1, v2) {
        console.log('v1', v1, "v2", v2);
        return v1 && v2 ? v1.value === v2.value : v1 === v2;
    };
    WorkshopTaskDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    WorkshopTaskDialogComponent.prototype.setDefaultProject = function () {
        console.log('set default project!');
        this.dialogFormGroup.get('projNum').setValue(this.getDefaultProject());
    };
    WorkshopTaskDialogComponent.prototype.getWeeks = function () {
        this.weeks.push({ value: "0", label: 'Åpen-uten uke' });
        var currentWeek = moment().isoWeek();
        var year = moment().year();
        var lastWeek = moment(year, "YYYY").isoWeeksInYear();
        for (var i = 0; i < 3; i++) {
            var week = currentWeek + i;
            if (week > 52 && lastWeek == 52) {
                week = 0 + i;
            }
            else if (lastWeek == 53 && week == 54) {
                week = 1;
            }
            else if (lastWeek == 53 && week > 54) {
                week = 2;
            }
            var uke = "Uke " + week + "/" + year + " | " + week + "/" + year;
            this.weeks.push({ value: week + "/" + year, label: uke });
        }
    };
    WorkshopTaskDialogComponent.prototype.getDefaultProject = function () {
        console.log('get default project');
        var defaultProject = "0";
        var teamId = this.user.teamId;
        if (teamId === "A") {
            defaultProject = "610";
        }
        else if (teamId === "B") {
            defaultProject = "620";
        }
        else if (teamId === "C") {
            defaultProject = "630";
        }
        else if (teamId === "D") {
            defaultProject = "640";
        }
        else if (teamId === "E") {
            defaultProject = "650";
        }
        else if (teamId === "F") {
            defaultProject = "660";
        }
        else if (teamId === "G") {
            defaultProject = "670";
        }
        else if (teamId === "H") {
            defaultProject = "680";
        }
        else if (teamId === "I") {
            defaultProject = "690";
        }
        else if (teamId === 'Z') {
            defaultProject = "620";
        }
        else {
            defaultProject = "0";
        }
        return defaultProject;
    };
    WorkshopTaskDialogComponent.prototype.openCamera = function () {
        this.takepicture = true;
    };
    WorkshopTaskDialogComponent.prototype.getImage = function (e) {
        this.takepicture = false;
        this.canvasImage = e;
        this.fd = e.formData;
        if (e) {
            var img = document.createElement('img');
            img.src = e.imageData;
            img.width = 200;
            img.className = '';
            var childCount = document.getElementById("snapshot").childElementCount;
            var child = document.getElementById("snapshot");
            if (childCount > 0)
                child.removeChild(child.childNodes[0]);
            document.getElementById('snapshot').appendChild(img);
        }
    };
    WorkshopTaskDialogComponent.prototype.getCapturedImage = function () {
        this.camera.takePicture();
        // this.camera.getCapturedImage();
    };
    return WorkshopTaskDialogComponent;
}(AbstractDialogComponent));
export { WorkshopTaskDialogComponent };
