<h1 mat-dialog-title>
  Dagføring
  <div class="available-hours">Tilgjengelige timer {{avaliableTime}}</div>
</h1>

<div mat-dialog-content>
  <form *ngIf="formGroup" [formGroup]="formGroup" [class.mobile-view]="isMobileView">
    <app-employee-picker class="week-day-team-members" [membersFormCtrl]="formGroup.get('weekDayMembersCtrl')" [selectAllTeamMembers]="true"
      placeholder="Lagmedlemmer" multiple="true"
      (memberChange)="weekDayMemberChange($event)">
    </app-employee-picker>
    <div *ngIf="!isMobileView">&nbsp;</div>
    <div *ngIf="!isMobileView">&nbsp;</div>
    <mat-form-field id="choose-date-field">
        <input matInput formControlName="workDateCtrl" [matDatepicker]="picker" placeholder="Dato for dagføring">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Starttid</mat-label>
      <mat-select formControlName="workStartTimeCtrl" (selectionChange)="timeChange()" required>
        <mat-option *ngFor="let slot of timeSlots" [value]="slot">
          {{slot}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Sluttid</mat-label>
      <mat-select formControlName="workEndTimeCtrl" (selectionChange)="timeChange()" required>
        <mat-option *ngFor="let slot of timeSlots" [value]="slot">
          {{slot}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="!isMobileView">&nbsp;</div>
    <div *ngIf="!isMobileView">&nbsp;</div>
    <mat-form-field>
      <input matInput appCustomIncDecBtns type="number" formControlName="driveFromWorkCtrl" placeholder="Kjøring fra Gjerde"
      (focusin)="onFocusInHours($event, 'driveFromWorkCtrl')" (focusout)="onFocusOutHours($event, 'driveFromWorkCtrl')">
    </mat-form-field>
    <mat-form-field>
      <input matInput appCustomIncDecBtns type="number" formControlName="driveToWorkCtrl" placeholder="Kjøring til Gjerde"
      (focusin)="onFocusInHours($event, 'driveToWorkCtrl')" (focusout)="onFocusOutHours($event, 'driveToWorkCtrl')">
    </mat-form-field>
    <div *ngIf="!isMobileView">&nbsp;</div>
    <div *ngIf="!isMobileView">&nbsp;</div>
    <div *ngIf="!isMobileView">&nbsp;</div>
    <mat-form-field>
      <input matInput appCustomIncDecBtns type="number" formControlName="sumBreakCtrl" placeholder="Sum pause" (change)="sumBreakChange($event)"
      (focusin)="onFocusInHours($event, 'sumBreakCtrl')" (focusout)="onFocusOutHours($event, 'sumBreakCtrl')">
    </mat-form-field>
    <!-- <mat-form-field>
      <input matInput appCustomIncDecBtns type="number" formControlName="sumBreakWorkCtrl" placeholder="Herav betalt pause"
        (focusin)="onFocusInHours($event, 'sumBreakWorkCtrl')"
        (focusout)="onFocusOutHours($event, 'sumBreakWorkCtrl')">
    </mat-form-field> -->
    <div>&nbsp;</div>
    <div *ngIf="!isMobileView">&nbsp;</div>
    <div *ngIf="!isMobileView">&nbsp;</div>
    <mat-form-field class="day-comment">
      <textarea type="text" formControlName="commentCtrl" matInput min="3" max="4" placeholder="kommentar"></textarea>
    </mat-form-field>
    <div *ngIf="!isMobileView">&nbsp;</div>
    <div class="new-section">
      <h2>Diett</h2>
      <section class="calc-articles-checkbox-section">
        <mat-checkbox [formControl]="copyDietCtrl" (change)="copyDietChange($event)">Kopiere diett fra forrige dag</mat-checkbox>
      </section>
      <mat-spinner *ngIf="fetchingPreviousDiets" [diameter]="20" color="accent"></mat-spinner>
    </div>
    <div class="diet-container">
      <mat-accordion togglePosition="before">
        <mat-expansion-panel
        *ngFor="let dietFormGroup of dietsCtrl.controls; let i = index"
        (opened)="dietPanelStates[i].isOpen = true"
        (closed)="dietPanelStates[i].isOpen = false"
        formArrayName="dietsCtrl">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Diett
            </mat-panel-title>
            <mat-panel-description>
              <ng-container *ngIf="dietFormGroup.get('membersCtrl').value">
                <ng-container *ngFor="let member of dietFormGroup.get('membersCtrl').value; let memberIndex = index">
                  {{' ' + member?.initials}}
                  <ng-container *ngIf="memberIndex < dietFormGroup.get('membersCtrl').value.length-1">,</ng-container>
                </ng-container>
              </ng-container>
              <div class="btn-delete-diet" (click)="deleteDiet(i)">
                <mat-icon color="warn">delete</mat-icon> Slett diett
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="single-diet" [class.mobile-view]="isMobileView" [formGroupName]="i">
            <app-employee-picker [membersFormCtrl]="dietFormGroup.get('membersCtrl')" [selectAllTeamMembers]="true" placeholder="Lagmedlemmer" multiple="true"
            [availableMembers]="formGroup.get('weekDayMembersCtrl').value">
            </app-employee-picker>
            <mat-form-field class="type-of-diet-picker">
              <mat-select placeholder="Diett" formControlName="dietCtrl" (selectionChange)="dietChange(i)">
                <mat-option *ngFor="let diet of diets" [value]="diet">
                  {{diet.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <section *ngIf="!dietPanelStates[i].hideDining" [formGroupName]="'diningGroup'" class="dining-group">
              <mat-checkbox formControlName="breakfastCtrl">Frokost</mat-checkbox>
              <mat-checkbox formControlName="lunchCtrl">Lunsj</mat-checkbox>
              <mat-checkbox formControlName="dinnerCtrl">Middag</mat-checkbox>
            </section>

            <!-- <mat-form-field *ngIf="!dietPanelStates[i].hideLodge" class="lodge">
              <input matInput type="text" formControlName="lodgingPlaceCtrl" placeholder="Overnattingssted" required>
            </mat-form-field> -->
            <mat-form-field *ngIf="!dietPanelStates[i].hideLodge" class="lodge">
              <input type="text" placeholder="Overnattingssted" aria-label="Overnattingssted" matInput formControlName="lodgingPlaceCtrl" required
                [matAutocomplete]="auto" (keyup)="hotelSelectKeyUp($event, i)">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayHotelFn">
                <mat-option *ngFor="let hotel of hotels" [value]="hotel" (click)="setHotelAddressFromOptionClick(i, hotel)">
                  {{hotel.label}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <div *ngIf="!isMobileView">&nbsp;</div>
    </div>
    <div *ngIf="!isMobileView">&nbsp;</div>
    <h2 class="new-section">Endringslogg</h2>
    <div class="diet-container">
      <mat-accordion togglePosition="before">
        <mat-expansion-panel
          (opened)="changeLogisOpen = true" (closed)="changeLogisOpen = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Endringslogg
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngFor="let log of weekDay?.revLog"> {{log}}</div>
          <div *ngIf="!weekDay?.revLog">Ingen endringer</div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </form>
</div>
<div *ngIf="!isMobileView">&nbsp;</div>
<div *ngIf="formGroup" class="mat-dialog-actions">
  <button mat-raised-button (click)="addNewDiet()" color="accent">Legg til ny diett</button>
  <button mat-raised-button (click)="save()" [disabled]="!formGroup.valid || savingInProgress" color="primary">
    <div class="button-content">
      Lagre
      <mat-spinner *ngIf="savingInProgress" [diameter]="20" color="accent"></mat-spinner>
    </div>
  </button>
  <button mat-raised-button (click)="close()" cdkFocusInitial>Avbryt</button>
</div>