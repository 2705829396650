<form class="registerParsellDialog" #pointDialogForm="ngForm" [formGroup]="dialogFormGroup">
    <button class="btn-close" color="accent" type="button" mat-icon-button (click)="closeDialog()">
        <mat-icon aria-label="Close">close</mat-icon>
    </button>
    <!-- <mat-slide-toggle [color]="'primary'" formControlName="measureSwitch" >
        Montering
    </mat-slide-toggle> -->
    <mat-vertical-stepper #stepper>
        <mat-step [stepControl]="buildingForm">
            <form class="montering" formGroupName="buildingForm" #buildingForm="ngForm">
                <ng-template matStepLabel>Montering</ng-template>
                <div>
                    <h4> Startende </h4>
                    <app-railing-categories-select #mounting *ngIf="data.rtType" [isDemolition]="false"
                        [railingType]="data.rtType" (railingTypeChanged)="startRtTypeChanged($event)">
                    </app-railing-categories-select>
                    <app-select-article-form #startEndArticleSelect [placeholder]="'Endetype på første punkt'"
                        [typeOfArticle]="'compositeArticle'" [(parentFormGroup)]="dialogFormGroup"
                        [childFormControlName]="'articleFormControl'" [childValue]="startType"
                        (onArticleChanged)="onChildStartArticleChanged($event)" [includeCategories]="['Ende','Ingen']">
                    </app-select-article-form>
                </div>
                <div>
                    <h4> Sluttende </h4>
                    <app-railing-categories-select #mounting *ngIf="data.rtType" [isDemolition]="false"
                        [railingType]="data.rtType" (railingTypeChanged)="endRtTypeChanged($event)">
                    </app-railing-categories-select>
                    <app-select-article-form #startEndArticleSelect [placeholder]="'Endetype på siste punkt'"
                        [typeOfArticle]="'compositeArticle'" [(parentFormGroup)]="dialogFormGroup"
                        [childFormControlName]="'articleFormControlName'" [childValue]="endType"
                        (onArticleChanged)="onChildEndArticleChanged($event)" [includeCategories]="['Ende','Ingen']">
                    </app-select-article-form>
                </div>
                <div class="lengthCalc">
                    <mat-form-field>
                        <mat-label>Start Meter</mat-label>
                        <input type="number" matInput placeholder="" formControlName="startMeter"
                            (change)="calculateLength()" appCustomIncDecBtns>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Slutt Meter</mat-label>
                        <input type="number" matInput placeholder="" formControlName="sluttMeter"
                            (change)="calculateLength()" appCustomIncDecBtns>
                    </mat-form-field>
                    <!--  <button mat-raised-button (click)="calculateLength()"> Calculate Lengde </button> -->
                    <mat-form-field>
                        <mat-label>Skinner</mat-label>
                        <input type="number" matInput placeholder="" formControlName="skinner"  (change)="skinnerUpdate()" appCustomIncDecBtns>
                    </mat-form-field>
                </div>

                <mat-form-field>
                    <mat-label>Lengde</mat-label>
                    <input type="number" matInput placeholder="" formControlName="lengde">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Side</mat-label>
                    <mat-select formControlName="side">
                        <mat-option value="H">Høyre</mat-option>
                        <mat-option value="V">Venstre</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="stepper">
                    <button mat-raised-button matStepperNext color="primary"
                        [disabled]="!dialogFormGroup.get('buildingForm').valid">Neste</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="demolitionForm">
            <form class="riving" #demolitionForm="ngForm" formGroupName="demolitionForm">
                <div class="rtType">
                    <ng-template matStepLabel>Riving</ng-template>
                    <app-railing-categories-select #demolition [isDemolition]="true" [railingType]="data.demolitionType"
                        (railingTypeChanged)="rivingRtTypeChanged($event)">
                    </app-railing-categories-select>
                </div>
                <mat-form-field>
                    <mat-label>Lengde</mat-label>
                    <input matInput placeholder="" formControlName="lengde" type="number" appCustomIncDecBtns>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Vrakskinner</mat-label>
                    <input matInput placeholder="" formControlName="vrakskinner" type="number" appCustomIncDecBtns>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Vrakstolper</mat-label>
                    <input matInput placeholder="" formControlName="vrakstolper" type="number" appCustomIncDecBtns>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Deponering</mat-label>
                    <input matInput placeholder="" formControlName="deponering" type="number" appCustomIncDecBtns>
                </mat-form-field>
                <div class="stepper">
                    <button mat-raised-button matStepperPrevious color="default">Tilbake</button> &nbsp;&nbsp;
                    <button mat-raised-button matStepperNext color="primary">Neste</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="strukturArticleForm">
            <form class="articles" #strukturArticleForm="ngForm" formGroupName="strukturArticleForm">
                <ng-template matStepLabel>Strukturartikler</ng-template>
                <app-select-article-form [typeOfArticle]="'compositeArticle'" [parentFormGroup]="dialogFormGroup"
                    [childFormControlName]="strukturArticleForm" [excludeCategories]="['Ende']"
                    (onArticleChanged)="onCompositeArticleChanged($event)">
                </app-select-article-form>
                <br>
                <mat-form-field style="grid-column: 1;">
                    <input type="number" matInput appCustomIncDecBtns [useFiniteNumbers]="true" placeholder="Antall"
                        formControlName="quantity" required>
                    <!-- <mat-error *ngIf="quantityFormControl.invalid">Kan kun legge til et positivt antall artikler</mat-error> -->
                </mat-form-field>
                <div class="stepper">
                    <button mat-raised-button color="primary" (click)="addStrukturArticle()">Legg til</button>
                    &nbsp;&nbsp;
                </div>
            </form>
            <div class="compositeArticles">
                <h3>Strukturartikler</h3>
                <app-composite-articles #compArticles [compositeArticlesSubject]="compositeArticlesSubject"
                    [compositeArticles]="compositeArticles" [rtType]="selectedRailingType?.id"></app-composite-articles>
            </div>
        </mat-step>
    </mat-vertical-stepper>
    <button class="btn-save" mat-raised-button color="primary" (click)="calculateArticles()">Fullfør
        registrering</button>

</form>