import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StorageComponent } from './storage.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { StorageMapComponent } from './storage-map/storage-map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

const routes: Routes = [
  { path: 'lager', component: StorageComponent },
  { path: 'lager/:unid', component: StorageComponent },
  { path: 'lager/map', component: StorageMapComponent },
  { path: 'lager/:storageUnid/map', component: StorageMapComponent },
];

@NgModule({
  declarations: [StorageComponent, StorageMapComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    LeafletModule
  ],
  exports: [RouterModule],
})
export class StorageModule { }
