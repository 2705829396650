<div class="logheader" [class.mobile-view]="isMobileView">
  <div class="item item-title">
    <h2>Skiltlogg </h2>
  </div>


  <div class="item">
    <button mat-raised-button color="primary" (click)="verify()" *ngIf="!archived && !isMobileView">Kontroller alle skilt</button>
    <button mat-raised-button color="primary" (click)="verify()" *ngIf="!archived && isMobileView">
      <mat-icon>done_all</mat-icon>
    </button>
  </div>
  <div class="item">
    <button mat-raised-button color="primary" (click)="removeAll(true)" *ngIf="!hasRemovedSign && !archived && !isMobileView">Ta inn
      skilt for kvelden</button>
    <button mat-raised-button color="primary" (click)="placeSignsBack()" *ngIf="hasRemovedSign && !archived && !isMobileView">Sett ut
      igjen fra siste posisjon</button>
    <button mat-raised-button color="primary" (click)="removeAll(true)" *ngIf="!hasRemovedSign && !archived && isMobileView">
      <mat-icon>nights_stay</mat-icon>
    </button>
  </div>
  <div class="item">
    <button mat-raised-button color="primary" (click)="removeAll(false)" *ngIf="!archived && !isMobileView">Ta inn alle skilt</button>
    <button mat-raised-button color="primary" (click)="removeAll(false)" *ngIf="!archived && isMobileView">
      <mat-icon>archive</mat-icon>
    </button>

  </div>
  <div class="item item-title">
    <button mat-raised-button color="primary" (click)="newSign()" *ngIf="!archived && !isMobileView">Ekstra skilt</button>
    <button mat-raised-button color="primary" (click)="newSign()" *ngIf="!archived && isMobileView">
      <mat-icon>add_circle_outline</mat-icon>
    </button>
  </div>
</div>
<mat-table [dataSource]="dataSource" multiTemplateDataRows [class.mobile-view]="isMobileView">
  <ng-container matColumnDef="sign">
    <mat-header-cell *matHeaderCellDef> Skilt </mat-header-cell>
    <mat-cell *matCellDef="let sign">
      <!-- <span class="mobile-label">Skilt:</span> -->
      <img src="assets/skilt/{{sign.id}}.jpg" height="32px"> 
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="meter">
    <mat-header-cell *matHeaderCellDef> Vegref </mat-header-cell>
    <mat-cell *matCellDef="let sign: Sign">
      <span class="mobile-label"> Veg:</span>
      {{sign| sign:'placed'}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="number">
    <mat-header-cell *matHeaderCellDef> Ant </mat-header-cell>
    <mat-cell *matCellDef="let sign">
      <span class="mobile-label"> Ant:</span>
      {{sign.count}} 
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="side">
    <mat-header-cell *matHeaderCellDef>Pos </mat-header-cell>
    <mat-cell *matCellDef="let sign">
      <span class="mobile-label"> Pos:</span>
      {{sign.side}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="required">
    <mat-header-cell *matHeaderCellDef>Krav </mat-header-cell>
    <mat-cell *matCellDef="let sign">
      <span class="mobile-label"> Krav:</span>
      {{sign.required|signsRequire}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>Plasser </mat-header-cell>
    <mat-cell *matCellDef="let sign">
      <span class="mobile-label"> Plasser:</span>
      <button mat-raised-button color="primary" (click)="placeSign(sign)" *ngIf="isSignPlaced(sign) && !archived && !isMobileView">Sett
        ut</button>
      <button mat-raised-button color="primary" (click)="editSign(sign)" *ngIf="!isSignPlaced(sign) && !archived && !isMobileView">Endre</button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let sign" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail"  [class.mobile-view]="isMobileView" [@detailExpand]="sign == expandedElement ? 'expanded' : 'collapsed'">
          <div class="example-element-description">
            <table style="width:100%;">
              <tr>
                <th>Vegref.</th>
                <th>Gps</th>
                <th>Status</th>
                <th>Dato</th>
                <th>Bruker</th>
                <th>Kommentar</th>
              </tr>
              <tr *ngFor="let logEntry of sign.log">
                <td>
                  <span class="mobile-label"> Vegref:</span>
                  <span *ngIf="logEntry?.position?.shortForm">{{logEntry?.position?.shortForm}}</span></td>
                <td>
                  <span class="mobile-label"> Gps:</span>
                  <mat-icon *ngIf="logEntry.position?.coordinates">settings_input_antenna</mat-icon></td>
                <td>
                  <span class="mobile-label"> Status:</span>
                  {{logEntry.status|signStatus}}</td>
                <td>
                  <span class="mobile-label"> Dato:</span>
                  {{logEntry.date|date: 'dd.MM.yyyy HH:mm'}}</td>
                <td>
                  <span class="mobile-label"> Bruker:</span>
                  {{logEntry.user}}</td>
                <td>
                  <span class="mobile-label"> Kommentar:</span>
                  {{logEntry.comment}}</td>
              </tr>
            </table>
          </div>
        </div>
      </td>
    </ng-container>

  <ng-container matColumnDef="log">
    <mat-header-cell *matHeaderCellDef>Skiltlogg </mat-header-cell>
    <mat-cell *matCellDef="let sign">
      <span *ngIf="sign.log">
        <span [ngClass]="{'status-placed': sign.log[sign.log.length-1].status === 2 || sign.log[sign.log.length-1].status === 4}">{{sign.log[sign.log.length-1].status|signStatus}}</span> - {{ sign.log[sign.log.length-1].date|date:
        'dd.MM.yy
        HH:mm'}}
      </span>
      <button *ngIf="!isMobileView" mat-icon-button (click)="expandedElement = expandedElement === sign ? null : sign" color="accent">
          <mat-icon aria-label="Vis logg" class="detail-icon" [ngClass]="{'clicked': expandedElement === sign}">arrow_right_alt</mat-icon>
      </button>
      <button  mat-raised-button color="primary" *ngIf="!isSignPlaced(sign) && isMobileView" (click)="removeOneSign(sign)" class="removeButton" >
        Ta inn
      </button>
      &nbsp;
      <button *ngIf="isMobileView" mat-raised-button color="primary" (click)="expandedElement = expandedElement === sign ? null : sign" >
        <span *ngIf="expandedElement !== sign"> Vis detaljer </span>
        <span *ngIf="expandedElement === sign"> Skjul detaljer</span>
      </button> &nbsp;
      <button  mat-raised-button color="primary" (click)="placeSign(sign)" *ngIf="isSignPlaced(sign) && !archived && isMobileView">Sett
        ut</button>
      <button mat-raised-button color="primary" (click)="editSign(sign)" *ngIf="!isSignPlaced(sign) && !archived && isMobileView">Endre</button>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="removeAction">
    <mat-header-cell *matHeaderCellDef>Ta inn </mat-header-cell>
    <mat-cell *matCellDef="let sign">
      <button mat-icon-button *ngIf="!isSignPlaced(sign) && !isMobileView" (click)="removeOneSign(sign)" class="removeButton" color="accent">
        <mat-icon aria-label="Ta inn skilt">clear</mat-icon>
      </button>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
  <mat-row *matRowDef="let myRowData; columns: columnsToDisplay" class="example-element-row"
    [class.example-expanded-row]="expandedElement === myRowData"></mat-row>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</mat-table>
<div class="logheader" style="padding-top:1.25em;">
  <div class="item item-title">
    <h2 *ngIf="hasExtrasigns()">Ekstraskilt</h2>
  </div>
</div>
<mat-table *ngIf="hasExtrasigns()" [dataSource]="extraSignsDataSource" [class.mobile-view]="isMobileView" multiTemplateDataRows>
  <ng-container matColumnDef="sign">
    <mat-header-cell *matHeaderCellDef> Skilt </mat-header-cell>
    <mat-cell *matCellDef="let sign"><img src="assets/skilt/{{sign.id}}.jpg" height="32px"> </mat-cell>
  </ng-container>
  <ng-container matColumnDef="meter">
    <mat-header-cell *matHeaderCellDef> Vegref </mat-header-cell>
    <mat-cell *matCellDef="let sign"> 
      <span class="mobile-label"> Veg:</span>
      {{sign| sign:'placed'}} 
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="number">
    <mat-header-cell *matHeaderCellDef> Ant </mat-header-cell>
    <mat-cell *matCellDef="let sign"> 
      <span class="mobile-label"> Ant:</span>
      {{sign.count}} 
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="side">
    <mat-header-cell *matHeaderCellDef>Pos </mat-header-cell>
    <mat-cell *matCellDef="let sign"> 
      <span class="mobile-label"> Pos:</span>
      {{sign.side}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="required">
    <mat-header-cell *matHeaderCellDef>Krav </mat-header-cell>
    <mat-cell *matCellDef="let sign"> 
      <span class="mobile-label"> Krav:</span>
      {{sign.required}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>Plasser </mat-header-cell>
    <mat-cell *matCellDef="let sign">
      <span class="mobile-label"> Plasser:</span>
      <button mat-raised-button color="primary" (click)="placeSign(sign)" *ngIf="isSignPlaced(sign) && !isMobileView">Sett ut</button>
      <button mat-raised-button color="primary" (click)="editSign(sign)" *ngIf="!isSignPlaced(sign) && !isMobileView">Endre</button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let sign" [attr.colspan]="columnsToDisplay.length">
      <div class="example-element-detail" [class.mobile-view]="isMobileView" [@detailExpand]="sign == expandedElement ? 'expanded' : 'collapsed'">
        <div class="example-element-description">
          <table style="width:100%;">
            <tr>
              <th>Vegref</th>
              <th>Status</th>
              <th>Dato</th>
              <th>Bruker</th>
              <th>Kommentar</th>
            </tr>
            <tr *ngFor="let logEntry of sign.log">
              <td>
                <span class="mobile-label"> M:</span> 
                {{logEntry?.position?.shortForm}}
              </td>
              <td>
                <span class="mobile-label"> Status:</span>
                {{logEntry.status|signStatus}}
              </td>
              <td>
                <span class="mobile-label"> Dato:</span>
                {{logEntry.date|date: 'dd.MM.yyyy HH:mm'}}
              </td>
              <td>
                <span class="mobile-label"> Bruker:</span>
                {{logEntry.user}}
              </td>
              <td>
                <span class="mobile-label"> Kommentar:</span>
                {{logEntry.comment}}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="log">
    <mat-header-cell *matHeaderCellDef>Skiltlogg </mat-header-cell>
    <mat-cell *matCellDef="let sign">
      <!-- <span class="mobile-label"> Plasser:</span> -->
      <span *ngIf="sign.log">
        {{sign.log[sign.log.length-1].status|signStatus}} - {{ sign.log[sign.log.length-1].date|date: 'dd.MM.yy
        HH:mm'}}
      </span>
      <button mat-icon-button *ngIf="!isMobileView" (click)="expandedElement = expandedElement === sign ? null : sign" color="accent">
        <mat-icon aria-label="Vis logg" class="detail-icon" [ngClass]="{'clicked': expandedElement === sign}">arrow_right_alt</mat-icon>
      </button>
      &nbsp;
      <button *ngIf="isMobileView" mat-raised-button color="primary" (click)="expandedElement = expandedElement === sign ? null : sign" >
        <span *ngIf="expandedElement !== sign"> Vis detaljer </span>
        <span *ngIf="expandedElement === sign"> Skjul detaljer</span>
      </button> &nbsp;
      <button  mat-raised-button color="primary" (click)="placeSign(sign)" *ngIf="isSignPlaced(sign) && !archived && isMobileView">Sett
        ut</button>
      <button mat-raised-button color="primary" (click)="editSign(sign)" *ngIf="!isSignPlaced(sign) && !archived && isMobileView">Endre</button>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="removeAction">
    <mat-header-cell *matHeaderCellDef>Ta inn </mat-header-cell>
    <mat-cell *matCellDef="let sign">
      <button mat-icon-button *ngIf="!isSignPlaced(sign)" (click)="removeOneSign(sign)" class="removeButton" color="accent">
        <mat-icon aria-label="Ta inn skilt">clear</mat-icon>
      </button>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
  <mat-row *matRowDef="let myRowData; columns: columnsToDisplay" class="example-element-row"
    [class.example-expanded-row]="expandedElement === myRowData"></mat-row>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</mat-table>