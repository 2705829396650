/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dynamic-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./dynamic-button.component";
var styles_DynamicButtonComponent = [i0.styles];
var RenderType_DynamicButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DynamicButtonComponent, data: {} });
export { RenderType_DynamicButtonComponent as RenderType_DynamicButtonComponent };
export function View_DynamicButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "dynamic-btn"], ["mat-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickFunction($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.btnTxt; _ck(_v, 2, 0, currVal_2); }); }
export function View_DynamicButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dynamic-button", [], null, null, null, View_DynamicButtonComponent_0, RenderType_DynamicButtonComponent)), i1.ɵdid(1, 114688, null, 0, i6.DynamicButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DynamicButtonComponentNgFactory = i1.ɵccf("app-dynamic-button", i6.DynamicButtonComponent, View_DynamicButtonComponent_Host_0, { btnTxt: "btnTxt", btnIcon: "btnIcon", clickFunction: "clickFunction" }, {}, []);
export { DynamicButtonComponentNgFactory as DynamicButtonComponentNgFactory };
