import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
import { AppConfig } from '../environments/environment';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user.service";
var TimeSheetService = /** @class */ (function () {
    function TimeSheetService(http, userService) {
        this.http = http;
        this.userService = userService;
        this.apiUrl = AppConfig.apiUrl;
    }
    TimeSheetService.prototype.getWeek = function (team, year, weekNumber) {
        return this.http.get(this.apiUrl + "/hours/weeks?team=" + team + "&year=" + year + "&week=" + weekNumber)
            .pipe(map(function (res) { return res.data; }));
    };
    TimeSheetService.prototype.getWeekInfo = function () {
        var _this = this;
        var unid = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            unid[_i] = arguments[_i];
        }
        var response = [];
        var i = 0;
        unid.forEach(function (id) {
            response[i] = _this.getWeekDay(id);
            i++;
        });
        return forkJoin(response.slice());
    };
    TimeSheetService.prototype.getWeekHour = function (unid) {
        return this.http.get(this.apiUrl + "/hours/weeks/hours/" + unid)
            .pipe(map(function (res) {
            return res.data;
        }));
    };
    TimeSheetService.prototype.getWeekDay = function (unid) {
        return this.http.get(this.apiUrl + "/hours/weeks/days/" + unid)
            .pipe(map(function (res) {
            return res.data;
        }));
    };
    TimeSheetService.prototype.saveWeekDay = function (weekday) {
        if (!weekday.teamId) {
            weekday.teamId = this.userService.teamId;
        }
        return this.http.post(this.apiUrl + "/hours/weeks/days", weekday)
            .pipe(map(function (res) {
            return res.data;
        }, function (error) {
            return error;
        }));
    };
    TimeSheetService.prototype.saveWeekHour = function (weekHour) {
        if (!weekHour.teamId) {
            weekHour.teamId = this.userService.teamId;
        }
        return this.http.post(this.apiUrl + "/hours/weeks/hours", weekHour)
            .pipe(map(function (res) {
            return res.data;
        }, function (error) {
            return error;
        }));
    };
    /**
     *
     * @param orderNum {string} example orderId: BB396E-RSEH
     */
    TimeSheetService.prototype.getWeekHoursByOrderId = function (orderNum) {
        return this.http.get(this.apiUrl + "/hours/weeks/orders/" + orderNum)
            .pipe(map(function (res) {
            return res.data;
        }, function (error) {
            return error;
        }));
    };
    TimeSheetService.prototype.getHotels = function (qry) {
        var username = this.userService.commonName;
        var password = this.userService.password;
        if (AppConfig.environment === 'JER') {
            username = AppConfig.username;
            password = AppConfig.password;
        }
        var headers = new HttpHeaders({ 'Authorization': 'Basic ' + btoa(username + ':' + password) });
        return this.http.get(AppConfig.ibricksApi + ("/services/org/" + qry + "?orgId=F1AAA&nace=55"), { headers: headers });
    };
    TimeSheetService.prototype.getHotel = function (unid) {
        var username = this.userService.commonName;
        var password = this.userService.password;
        if (AppConfig.environment === 'JER') {
            username = AppConfig.username;
            password = AppConfig.password;
        }
        var headers = new HttpHeaders({ 'Authorization': 'Basic ' + btoa(username + ':' + password) });
        return this.http.get(AppConfig.ibricksApi + ("/services/org/" + unid + "?orgId=F1AAA"), { headers: headers }).
            pipe(map(function (res) { return res.data; }));
    };
    /**
     * Cannot edit weekDay for the previous month and earlier if the date has passed the 20th.
     * Can still edit weekDay of previous month if date has NOT passed the 20th.
     */
    TimeSheetService.prototype.canEditWeekHour = function (selectedDay) {
        var firstDayOfSelectedDayMonth = selectedDay.clone().startOf('month');
        var today = moment().startOf('isoWeek');
        if (today.date() >= 20) {
            return (firstDayOfSelectedDayMonth.add(1, 'month')).isAfter(today);
        }
        else {
            return (firstDayOfSelectedDayMonth.add(2, 'month')).isAfter(today);
        }
    };
    TimeSheetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TimeSheetService_Factory() { return new TimeSheetService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserService)); }, token: TimeSheetService, providedIn: "root" });
    return TimeSheetService;
}());
export { TimeSheetService };
