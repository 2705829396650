import { Injectable } from '@angular/core';
import { MeasuringPoint } from './measuring-point';
import { GpsData } from './gps-data';
import { GpsService } from './gps/gps.service';
import { LatLngTuple } from 'leaflet';
import { ArticleAdjustment } from './article-adjustment';
import { Article } from './article';
import { Order } from './order';
import { DataService } from './data.service';
import { POINT_STATUS } from './point-status';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompositeArticle } from './composite-article';
import { AllotmentHeader } from './allotment-header';
import { GPS_QUALITY, GpsQuality } from './gps/gps-quality';

@Injectable()
export class MeasureService {

  sa: any;
  constructor(private gpsService: GpsService, private dataService: DataService) {
    this.dataService.getStructureArticles().subscribe((data => {
      this.sa = data;
    }));
  }

  getDistanceBetweenMeasuringPoints(pointEnd: MeasuringPoint, pointStart: MeasuringPoint): number {
    if (pointEnd && pointStart) {
      const latDecimDegrees = this.gpsService.getDecimalDegreesFromDegreeMinutes(pointStart.gga.latitude);
      const lonDecimDegrees = this.gpsService.getDecimalDegreesFromDegreeMinutes(pointStart.gga.longitude);
      return this.getDistanceSincePoint(pointEnd, new GpsData(latDecimDegrees, lonDecimDegrees));
    }
    return 0;
  }

  /**
   * @param {LatLngTuple} pointEnd  (decimal degrees)
   * @param {MeasuringPoint} pointStart
   * @returns {number}
   */
  getDistanceBetweenPoints(pointEnd: LatLngTuple, pointStart: MeasuringPoint): number {
    const lat2Radians = this.gpsService.getRadiansFromDegreesMinutes(pointStart.gga.latitude);
    const lon2Radians = this.gpsService.getRadiansFromDegreesMinutes(pointStart.gga.longitude);
    const lat1Radians = pointEnd[0] * Math.PI / 180;
    const lon1Radians = pointEnd[1] * Math.PI / 180;
    return this.gpsService.getDistance(lat1Radians, lon1Radians, lat2Radians, lon2Radians);
  }
  /**
   * @param {point} the last point to compare against
   * @param {GpsData} gpsData the data to compare with the latest saved point
   * @returns {number}
   */
  getDistanceSincePoint(point: MeasuringPoint, gpsData: GpsData): number {
    if (point && gpsData) {
      const lat2Radians = this.gpsService.getRadiansFromDegreesMinutes(point.gga.latitude);
      const lon2Radians = this.gpsService.getRadiansFromDegreesMinutes(point.gga.longitude);
      const lat1Radians = gpsData.lat * Math.PI / 180;
      const lon1Radians = gpsData.lon * Math.PI / 180;
      return this.gpsService.getDistance(lat1Radians, lon1Radians, lat2Radians, lon2Radians);
    }
    return 0;
  }
  getBuildLength(accumulatedArticles: Array<Article>, articleAdjustments?: { [key: string]: ArticleAdjustment }) {
    let buildLength = 0;
    for (const article of accumulatedArticles) {
      if (article.length && article.length > 0) {
        if (articleAdjustments && articleAdjustments[article.id]) {
          if (articleAdjustments[article.id].quantityTotal === 0) {
            buildLength += (articleAdjustments[article.id].quantitySuggested) * article.length;
          } else {
            buildLength += (articleAdjustments[article.id].quantityTotal) * article.length;
          }
        } else {
          if (article.quantityTotal === 0) {
            buildLength += (article.quantityNew) * article.length;
          } else {
            buildLength += (article.quantityTotal) * article.length;
          }
        }
      }
    }
    return buildLength;
  }
  // TODO (john) add proper documentation
  createArticlesMap(res: any): { [key: string]: Article } {
    const articlesMap: any = {};
    res.forEach(allArticles => {
      allArticles.forEach(article => {
        if (article && article.quantitySuggested) {
          if (articlesMap.hasOwnProperty(article.id)) {
            articlesMap[article.id].quantitySuggested += article.quantitySuggested;
            articlesMap[article.id].quantityOld += article.quantityOld;
            articlesMap[article.id].quantityTotal += article.quantityTotal;
          } else {
            articlesMap[article.id] = article;
          }
        }
      });
    });
    return articlesMap;
  }
  getAllotmentHeader(order, parcelUnid): AllotmentHeader {
    for (const allotmentHeader of order.allotmentHeaders) {
      if (allotmentHeader.unid === parcelUnid) {
        return allotmentHeader;
      }
    }
    return null;
  }

  /**
   * @param {MeasuringPoint} point the point to compare against the allotment.
   * @param {AllotmentHeader} allotmentHeader allotment to compare against
   * @returns {boolean} true if closer to end point in allotment, otherwise closer to start point.
   */
  isPointClosestToEndOfAllotment(point: MeasuringPoint, allotmentHeader: AllotmentHeader): boolean {
    const startPos = allotmentHeader.gpsStartPos;
    const endPos = allotmentHeader.gpsEndPos;
    const startPosProximity = this.getDistanceBetweenPoints(startPos, point);
    const endPosProximity = this.getDistanceBetweenPoints(endPos, point);
    return (Math.abs(endPosProximity) < Math.abs(startPosProximity));
  }
  /**
   * Find the composite article from the order that is closest to the first registered point
   * if not set already
   */
  findNearestStartEndCompositeArticle(order: Order, point: MeasuringPoint, parcelUnid: string, isLastPoint: boolean): CompositeArticle {
    if (point && order) {
      const allotmentHeader: AllotmentHeader = this.getAllotmentHeader(order, parcelUnid);
      let hasPosition = false;
      if (allotmentHeader.gpsStartPos.length > 0 && allotmentHeader.gpsEndPos.length > 0) {
        hasPosition = true;
      } else {
        if (isLastPoint) {
          const sa = Object.assign({}, this.sa[allotmentHeader.endType]);
          sa.selectedQuantity = 1;
          return sa;
        } else {
          const sa = Object.assign({}, this.sa[allotmentHeader.startType]);
          sa.selectedQuantity = 1;
          return sa;
        }
      }

      if (hasPosition) {
        if (this.isPointClosestToEndOfAllotment(point, allotmentHeader) && this.sa[allotmentHeader.endType]) {
          const sa = Object.assign({}, this.sa[allotmentHeader.endType]);
          sa.selectedQuantity = 1;
          return sa;
        } else if (this.sa[allotmentHeader.startType]) {
          const sa = Object.assign({}, this.sa[allotmentHeader.startType]);
          sa.selectedQuantity = 1;
          return sa;
        }
      }
    }
    return null;
  }



  splitPoints(points: Array<MeasuringPoint>): Array<Array<MeasuringPoint>> {
    console.log("points to split", points);
    let splitPoints: Array<Array<MeasuringPoint>> = [[]];
    if (!points || points.length === 0) {
      return splitPoints;
    }
    // extract measure arrays
    const measureArrays: Array<Array<MeasuringPoint>> = [[]];
    points.forEach( p => {
      if (p.state % 10 === POINT_STATUS.DEMOLITION_AND_MEASURE_POINT ||
          p.state % 10 === POINT_STATUS.MEASURE_POINT) {
        const lastInnerArray = measureArrays[measureArrays.length - 1];
        const measurePoint = Object.assign({}, p);
        measurePoint.state = POINT_STATUS.MEASURE_POINT;
        lastInnerArray.push(measurePoint);
      } else {
        measureArrays.push([]);
      }
    });
    // extract demolition arrays
    const demolitionArrays: Array<Array<MeasuringPoint>> = [[]];
    points.forEach(p => {
      if (p.state % 10 === POINT_STATUS.DEMOLITION_AND_MEASURE_POINT ||
        p.state % 10 === POINT_STATUS.DEMOLITION_POINT) {
        const lastInnerArray = demolitionArrays[demolitionArrays.length - 1];
        const demolitionPoint = Object.assign({}, p);
        demolitionPoint.state = POINT_STATUS.DEMOLITION_POINT;
        lastInnerArray.push(demolitionPoint);
      } else {
        demolitionArrays.push([]);
      }
    });
    splitPoints = [...measureArrays, ...demolitionArrays].filter(arr => arr && arr.length > 0);
    return splitPoints;
  }
  getLowestQualityFromTrack(points: Array<MeasuringPoint>): number {
    let currentLowestQuality = GPS_QUALITY.BEST;
    const gpsQuality = new GpsQuality();
    for (const p of points) {
      const satQuality = gpsQuality.getGpsQualityFromFixedNumber(p.gga.fixQuality, p.gga.hdopPrecision);
      if (satQuality < currentLowestQuality) {
        currentLowestQuality = satQuality;
      }
    }
    return currentLowestQuality;
  }
  /**
   * Will use the splitPoints array to figure out if the points should be demolition or measure.
   * Will also calculate new distance since last point on all the entries in splitPoints.
   * @param {Array<Array<MeasuringPoint>>} splitPoints
   * @returns {railIds: Array<string>, requests: Array<Observable<Array<Article>>>}
   */
  public createCalculatedArticlesRequests(splitPoints: Array<Array<MeasuringPoint>>, rtTypeId: string, demolitionTypeId: string, invoiceType: string, allotmentUnid: string): { railIds: Array<string>, requests: Array<Observable<Array<Article>>> } {
    const res = { railIds: [], requests: [] };
    for (let i = 0; i < splitPoints.length; i++) {
      this.calcDistsanceBetweenPoints(splitPoints[i]);
      let demolition = false;
      let railId = rtTypeId;
      if (splitPoints[i] && splitPoints[i][0].state % 10 === POINT_STATUS.MEASURE_POINT) {
        railId = rtTypeId;
      } else if (splitPoints[i] && splitPoints[i][0].state % 10 === POINT_STATUS.DEMOLITION_POINT) {
        demolition = true;
        railId = demolitionTypeId;
      }
      res.railIds.push(railId);
      res.requests.push(this.dataService.getCalculatedArticles(railId, splitPoints[i], demolition, invoiceType, allotmentUnid));
    }
    return res;
  }
  /**
   * @param {Array<MeasuringPoint>} points
   * @returns {number} the total distance between all points
   */
  public calcDistsanceBetweenPoints(points: Array<MeasuringPoint>): number {
    let tmpLength = 0;
    if (points && points.length > 0) {
      points[0].distanceFromPreviousPoint = 0;
      for (let i = 1; i < points.length; i++) {
        if (points[i]) {
          const pointEnd = points[i];
          const pointStart = points[i - 1];
          pointEnd.distanceFromPreviousPoint = this.getDistanceBetweenMeasuringPoints(pointEnd, pointStart);
          tmpLength += pointEnd.distanceFromPreviousPoint;
        }
      }
    }
    return tmpLength;
  }
}
