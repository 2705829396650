import { Component, OnInit, OnDestroy, Inject, AfterViewInit, ɵConsole, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect } from '@angular/material';
import { StorageService } from '../storage.service';
import { AbstractDialogComponent } from '../../components/abstract-dialog/abstract-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { map, startWith, takeUntil, take } from 'rxjs/operators';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { strictEqual } from 'assert';
import { Router } from '@angular/router';

@Component({
  selector: 'app-storage-details',
  templateUrl: './storage-details.component.html',
  styleUrls: ['./storage-details.component.scss']
})
export class StorageDetailsComponent extends AbstractDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  dialogFormGroup: FormGroup;
  storageId: string;
  storage;
  locationAvailable = false;
  articleColumns: string[] = ['article', 'count', 'action'];
  historyColumns: string[] = ['time', 'action', 'user'];
  articles: Array<any> = [];     // articles in the storage 
  articleList: Array<any>;  // List of all available articles
  filteredArticles: ReplaySubject<Array<any>> = new ReplaySubject<Array<any>>(0);
  protected _onDestroy = new Subject<void>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  gpsPos = [];
  do_refresh = false;
  selected: any; // fix build

  constructor(protected dialogRef: MatDialogRef<AbstractDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private storageService: StorageService, private router: Router) {
    super(dialogRef);
    this.storageId = data.storageId;
    this.dialogFormGroup = new FormGroup({
      addArticleFormGroup: new FormGroup({
        articleSelect: new FormControl('', Validators.required),
        articleFilterCtrl: new FormControl(''),
        articleCount: new FormControl('', [Validators.required, Validators.min(1)]),
        comment: new FormControl('')
      })
    });
  }

  ngOnInit() {
    this.storageService.getStorage(this.storageId).subscribe(data => {
      this.storage = data;
      this.getLocation(this.storage);
      this.articles = data.articles;
      this.articles.forEach((article: any) => {
        const artNum = article.artNr;
        this.dialogFormGroup.addControl(artNum,
          new FormControl(0, [Validators.required, Validators.max(article.antall), Validators.min(1)]));
      });
    });
    this.getArticleList();

    // listen for search field value changes
    this.dialogFormGroup.get('addArticleFormGroup.articleFilterCtrl').valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterArticles();
      });
  }
  ngAfterViewInit() {
    this.setInitialValue();
  }

  getName(element) {
    if (element.kommentar) {
      return element.kommentar;
    } else {
      return element.navn;
    }
  }

  /**
   * Sets the initial value after the filteredArticles are loaded initially
   */
  protected setInitialValue() {
    this.filteredArticles
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredArticles are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a, b) => a && b && a.name === b.name;
      });
  }

  filterArticles() {
    if (!this.articleList) return
    let search = this.dialogFormGroup.get('addArticleFormGroup.articleFilterCtrl').value;
    if (!search) {
      this.filteredArticles.next(this.articleList);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the ArticleList
    this.filteredArticles.next(
      this.articleList.filter(article => article['name'].toLowerCase().indexOf(search) > -1 || article['id'].startsWith(search))
    );
  }

  isValid(controlName: string, antall: number) {
    return this.dialogFormGroup.get(controlName).value > 0 && this.dialogFormGroup.get(controlName).value <= antall;
  }
  isValidLeggTil(controlName: string) {
    return this.dialogFormGroup.get(controlName).value > 0;
  }

  getArticleList() { // to get list of All Available articles 
    this.storageService.getArticleList().pipe(map(data => data.sort((a, b) => a.id - b.id)))
      .subscribe(articlesMap => {
        this.articleList = articlesMap;
        // load the initial article list
        this.filteredArticles.next(this.articleList);
        // Set initial selection
        //this.dialogFormGroup.get('addArticleFormGroup.articleFilterCtrl').setValue(this.articleList[0]['id']  +"-"+ this.articleList[0]['name']);

      });
  }

  addArticle() {
    let artNo = this.dialogFormGroup.get('addArticleFormGroup.articleSelect').value;
    let count = this.dialogFormGroup.get('addArticleFormGroup.articleCount').value;
    let comment = this.dialogFormGroup.get('addArticleFormGroup.comment').value;
    let art = this.articleList.find((article) => article['id'] === artNo);
    let artName = art['name'];
    let artIndex = -1; 
    if(this.articles) artIndex = this.articles.findIndex(({ artNr }) => artNr === artNo);
    let article = {};
    if (artIndex != -1) {
      article = this.articles[artIndex];
      let antall = article['antall'] + count;
      article['antall'] = antall;
      if(article['artNr'] == "99999") { 
        article['kommentar'] =  comment;
        article['antall'] = count;
      }
    } else {
      article['navn'] = artName;
      article['artNr'] = artNo;
      article['antall'] = count;
      if(article['artNr'] == "99999") article['kommentar'] =  comment
    }
    this.storageService.UpdateStorage(this.storageId, article).subscribe(storage => {
      this.ngOnInit();
      this.dialogFormGroup.get('addArticleFormGroup.articleSelect').setValue('');
      this.dialogFormGroup.get('addArticleFormGroup.articleCount').setValue('');
      this.dialogFormGroup.get('addArticleFormGroup.comment').setValue('');
    })
  }

  updateMaterialCount(artNO: string, buttonType: string) {
    console.log(artNO, buttonType);
    const value = this.dialogFormGroup.get(artNO).value;
    this.dialogFormGroup.get(artNO).setValue(0);
    const artIndex = this.articles.findIndex(({ artNr }) => artNr === artNO);
    const article = this.articles[artIndex];
    if (buttonType === 'add') { article['antall'] = article['antall'] + value; }
    if (buttonType === 'remove') { article['antall'] = article['antall'] - value; }
    this.storageService.UpdateStorage(this.storageId, article).subscribe(articles => {
      this.articles = articles;
    })
  }
  setCountToDeafult(artNum: string) {
    this.dialogFormGroup.get(artNum).setValue(0);
  }
  getLocation(storage: any) {
    if (storage.unloadGps === "") {
      if (storage.gpsPos !== "" && storage.gpsPos !== "0;0") {
        this.locationAvailable = true;
        const points = storage.gpsPos.split(";");
        this.gpsPos.push(points[0], points[1]);
      }
    } else {
      this.locationAvailable = true
      const points = storage.unloadGps.split(";");
      this.gpsPos.push(points[0], points[1]);
    }
  }
  zoomToStorage(storage) {
    this.onNoClick();
    const urlTree = this.router.createUrlTree(['lager/' + storage.unid + '/map'], { queryParams: { gpsPos: this.gpsPos } });
    this.router.navigateByUrl(urlTree);
  }

  archiveStorage() {
    this.storageService.saveStorageAsArkiv(this.storage).subscribe(data => {
      this.storage.status = data.status;
      this.do_refresh = true;
    });
  }
  onNoClick(): void {
    this.dialogRef.close({ do_refresh: this.do_refresh });
  }
  confirm(event: any): void {
    throw new Error("Method not implemented.");
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
