import { Component, OnInit, OnDestroy, Output, EventEmitter, HostBinding } from '@angular/core';
import { HeaderService } from '../../components/header/header.service';
import * as moment from 'moment-timezone';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import { DataService } from '../../data.service';
import { UserService } from '../../user.service';
import { MediaService } from '../../shared/media.service';
@Component({
  selector: 'app-timesheet-project',
  templateUrl: './timesheet-project.component.html',
  styleUrls: ['./timesheet-project.component.scss']
})
export class TimesheetProjectComponent implements OnInit, OnDestroy {
  @HostBinding('class.mobile-view') isMobileView = false;
  selectedDate: moment.Moment;
  beginWeek: moment.Moment;
  currentWeekNumber: number;
  selectedWeekYear: EventEmitter<any> = new EventEmitter();
  currentYear: number;
  currentWeekDay: number;
  weekHours: {};
  weekDays: any[];
  frmGroup: FormGroup;
  employeeList: any;
  currentUser: any;
  currentUserId: string;
  employeeId: EventEmitter<any> = new EventEmitter();
  fullWeek: EventEmitter<any> = new EventEmitter();
  showFullWeek = false; // toggle friday, saturday and sunday to be displayed as well.
  formGroup: FormGroup;
  isAdmin: boolean = false;
  constructor(private headerService: HeaderService, private dataService: DataService, private userService: UserService,private mediaService: MediaService) { 
    this.selectedDate = moment();
    this.selectedDate.locale('').weekday();
    this.beginWeek = this.selectedDate.clone().startOf('isoWeek').tz('Europe/Oslo');
    this.beginWeek.set('hours', 12);
    this.currentWeekNumber = this.beginWeek.isoWeek();
    this.currentYear = this.beginWeek.year();
    this.formGroup = new FormGroup({
      'showFullWeek': new FormControl(false, [])
    });
    this.frmGroup = new FormGroup({
      employee: new FormControl('')
    })
    this.mediaService.getMedia().subscribe( media => {
      this.isMobileView = media.isMobileView;
    })
  }

  ngOnInit() {
    this.headerService.addNavItem({
      route: `/timesheet-project`,
      label: `Timeføring-Prosjekt`,
      queryParams: ''
    });
    this.getEmployees();
    this.selectedWeekYear.emit({week:this.currentWeekNumber,year:this.currentYear});
    this.formGroup.get('showFullWeek').valueChanges.subscribe(data => {
      this.fullWeek.emit({data});
    })
    /* this.frmGroup.get('employee').valueChanges.subscribe(data => {
      this.employeeId.emit({'userId':data});
    }) */
  }
  getEmployees() {
    this.dataService.getEmployyes().subscribe(res => {
      this.employeeList = res;
      this.employeeList.push({empId: "2312", initials: "NT", name: "Nils Tarjei Hjelme", teamId: 'Z'});
      this.getCurrentUserDetails(this.employeeList);
    })
  }
  onEmployeeChange(eid) {
    console.log('eid',eid);
    this.userService.getCurrentUser().subscribe(user => {
      if(user.roles && user.roles.indexOf("[Admin]") != -1) {
        this.isAdmin = true;
      } 
    });
    this.employeeId.emit({'userId':eid, 'currentUserId': this.currentUserId, 'isAdmin': this.isAdmin });
  }
  getCurrentUserDetails(empList) {
    this.userService.getCurrentUser().subscribe(res => {
      this.currentUser = res;
      const currentUserId = empList.filter(data => {
        if (data.name === this.currentUser.commonName) {return data };
      });
      this.currentUserId =  currentUserId[0].empId
      this.frmGroup.get('employee').setValue(this.currentUserId);
      this.employeeId.emit({'userId': this.currentUserId, 'currentUserId': this.currentUserId});
    });

  }
  toggleShowFullWeek(event) {
    this.showFullWeek = this.formGroup.get('showFullWeek').value;
    this.beginWeek = this.selectedDate.clone().startOf('isoWeek').tz('Europe/Oslo'); // reset the counter as it is modified in the html
    this.beginWeek.set('hours', 12);
  }
  datePickerChanged(type: string, event: MatDatepickerInputEvent<Date>) {
    if (event) {
      this.onDateChanged(moment(event.value));
    }
  }
  onDateChanged(event: moment.Moment) {
    this.selectedDate = event;
    this.beginWeek = this.selectedDate.clone().startOf('isoWeek').tz('Europe/Oslo');
    this.beginWeek.set('hours', 12);
    this.currentWeekNumber = this.beginWeek.isoWeek();
    this.currentYear = this.beginWeek.year();
    this.currentWeekDay = this.selectedDate.locale('').isoWeekday();
    this.selectedWeekYear.emit({week:this.currentWeekNumber,year:this.currentYear,weekDay: this.currentWeekDay});
  }
  
  ngOnDestroy() {
    this.headerService.removeNavItem({
      route: `/timesheet-project`,
      label: `Timeføring-Prosjekt`,
      queryParams: ''
    });
  }

}
