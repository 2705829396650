import 'zone.js/dist/zone-mix';
import 'reflect-metadata';
import '../polyfills';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HomeComponent } from './components/home/home.component';
import { Routes } from '@angular/router';
import { MeasureComponent } from './components/measure/measure.component';
import { ParcelsComponent } from './components/parcels/parcels.component';
import { OrderComponent } from './components/order/order.component';
import { LoginComponent } from './components/login/login.component';
import { ReplicateComponent } from './components/replicate/replicate.component';
import { SjaComponent } from './components/sja/sja/sja.component';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { TasksComponent } from './components/tasks/tasks.component';
import { TeamMapComponent } from './components/team-map/team-map.component';
import { OrderApprovalComponent } from './components/order/order-approval/order-approval.component';
import { CameraComponent } from './components/camera/camera.component';
import { AuthGuard } from './auth.guard';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
var appRoutes = [
    {
        path: '',
        component: LoginComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'order/:orderUnid',
        component: OrderComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'measure/:orderUnid/:parcelId',
        component: MeasureComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [AuthGuard]
    },
    {
        path: 'measure/:orderUnid',
        component: ParcelsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'measure',
        component: MeasureComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'parcels',
        component: ParcelsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'replicate',
        component: ReplicateComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'sja/:orderUnid',
        component: SjaComponent,
        canActivate: [AuthGuard]
    },
    {
        path: ':orderUnid/approve',
        component: OrderApprovalComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'tasks',
        component: TasksComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'teammap',
        component: TeamMapComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'order/:orderUnid/teammap',
        component: TeamMapComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'camera',
        component: CameraComponent
    },
    { path: '**', component: HomeComponent,
        canActivate: [AuthGuard] }
];
var ɵ0 = (HttpLoaderFactory), ɵ1 = { hasBackdrop: true, disableClose: true }, ɵ2 = {};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };
