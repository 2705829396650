var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '../header/header.service';
import { marker, polyline, LayerGroup, layerGroup, latLng } from 'leaflet';
import * as L from 'leaflet';
import 'leaflet';
import 'leaflet.markercluster';
import { GeoService } from '../../geo.service';
import { ActivatedRoute } from '@angular/router';
import { MediaService } from '../../shared/media.service';
import { UserService } from '../../user.service';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MapService } from '../../map.service';
var TeamMapComponent = /** @class */ (function () {
    function TeamMapComponent(headerService, geoService, activatedRoute, mediaService, user, mapService) {
        var _this = this;
        this.headerService = headerService;
        this.geoService = geoService;
        this.activatedRoute = activatedRoute;
        this.mediaService = mediaService;
        this.user = user;
        this.mapService = mapService;
        this.options = {
            zoom: 5,
            maxZoom: 23,
            minZoom: 4,
            center: [65, 17],
        };
        this.teamMarkerGroup = new LayerGroup();
        this.greenIcon = L.icon({
            iconUrl: 'assets/map-icons/greenFlag.ico',
            iconSize: [60, 60],
            iconAnchor: [40, 57],
            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        });
        this.pinkIcon = L.icon({
            iconUrl: 'assets/map-icons/pinkFlag.ico',
            iconSize: [60, 60],
            iconAnchor: [42, 57],
            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        });
        this.blackIcon = L.icon({
            iconUrl: 'assets/map-icons/blackPoint.png',
            iconSize: [30, 30],
            iconAnchor: [15, 15],
            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        });
        this.svgCulvert = '<svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd stroke-linejoin:round;stroke-miterlimit:2;"> <circle cx="12" cy="12" r="10" style="fill:none;" /><clipPath id="_clip1"><circle cx="12" cy="12" r="10" /></clipPath> <g clip-path="url(#_clip1)"> <path id="outerCircle" [attr.fill]="isNightMode?"white":"black"" d="M12,2C17.519,2 22,6.481 22,12C22,17.519 17.519,22 12,22C6.481,22 2,17.519 2,12C2,6.481 6.481,2 12,2ZM12,4C16.415,4 20,7.585 20,12C20,16.415 16.415,20 12,20C7.585,20 4,16.415 4,12C4,7.585 7.585,4 12,4Z" /></g> <g transform="matrix(1.07692,0,0,1.07692,0.692308,-2.53846)"><circle cx="10.5" cy="13.5" r="6.5" style="fill:none;" /><clipPath id="_clip2"><circle cx="10.5" cy="13.5" r="6.5" /></clipPath><g clip-path="url(#_clip2)"><g transform="matrix(0.928571,0,0,0.928571,-0.642857,2.35714)"><path id="waves" [attr.fill]="isNightMode?"white":"black"" d="M4,14C4.097,13.6 5.333,13 6,13C6.667,13 7.333,14 8,14C8.667,14 9.333,13 10,13C10.667,13 11.333,14 12,14C12.667,14 13.333,13 14,13C14.667,13 15.333,14 16,14C16.667,14 17.333,13 18,13C18.667,13 19.91,13.577 20,14C20.09,14.423 19.01,15.012 18.538,15.537C18.066,16.062 17.735,16.732 17.167,17.152C16.275,17.811 14.534,19.143 13.186,19.49C11.857,19.832 10.054,19.486 9.077,19.235C8.381,19.056 7.818,18.503 7.322,17.983C6.713,17.344 5.975,16.064 5.421,15.4C4.995,14.89 3.903,14.4 4,14Z" /></g> </g></g>';
        this.urlCulvert = encodeURI("data:image/svg+xml," + this.svgCulvert).replace(new RegExp('#', 'g'), '%23');
        /* CustomIcon = L.icon({
            iconUrl: this.urlCulvert,
            iconSize:     [40, 40],
            shadowSize:   [50, 64],
            iconAnchor:   [22, 94],
            shadowAnchor: [4, 62],
            popupAnchor:  [-3, -76]
        }); */
        this.customIcon = L.divIcon({ html: this.svgCulvert, iconSize: [24, 24], iconAnchor: [12, 12] });
        this.equipmentIconUrl = 'assets/map-icons/greenTruck.png';
        this.equipmentsList = [];
        this.mediaService.getMedia().subscribe(function (media) {
            _this.isMobileView = media.isMobileView;
        });
        this.orderUnid = this.activatedRoute.snapshot.params['orderUnid'];
    }
    TeamMapComponent.prototype.ngOnInit = function () {
        this.headerService.addNavItem({
            route: "/teammap",
            label: "Kart",
            queryParams: ''
        });
    };
    TeamMapComponent.prototype.ngOnDestroy = function () {
        this.headerService.removeNavItem({
            route: "/teammap",
            label: "Kart",
            queryParams: ''
        });
        if (this.equipmentSubscription) {
            this.equipmentSubscription.unsubscribe();
        }
    };
    TeamMapComponent.prototype.onMapReady = function (map) {
        return __awaiter(this, void 0, void 0, function () {
            var vector;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.myMap = map;
                        map.setView(L.latLng([62.5364071, 7.888097]), 11);
                        return [4 /*yield*/, this.mapService.getVectorTileLayer()];
                    case 1:
                        vector = _a.sent();
                        vector.addTo(map);
                        this.teamMarkerGroup = layerGroup().addTo(this.myMap);
                        if (this.orderUnid == undefined) { // map for team
                            this.geoService.getCulvertsPos().subscribe(function (res) {
                                if (res.features.length > 0)
                                    _this.createCulvertMarkers(res.features);
                            });
                            this.geoService.getTeamPos().subscribe(function (res) {
                                _this.createPolylines(res);
                                _this.getEquipmentList(_this.user.teamId);
                            });
                        }
                        else { // map for order
                            this.geoService.getOrderCulvertsPos(this.orderUnid).subscribe(function (res) {
                                if (res.features.length > 0)
                                    _this.createCulvertMarkers(res.features);
                            });
                            this.geoService.getOrderPos(this.orderUnid).subscribe(function (res) {
                                _this.createPolylines(res);
                                _this.getEquipmentList(_this.user.teamId);
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    TeamMapComponent.prototype.createCulvertMarkers = function (culvertPoints) {
        var _this = this;
        culvertPoints.forEach(function (data) {
            var points = data.geometry.coordinates;
            var roadRef = data.properties.roadRefs;
            var properties = data.properties.culvertProperties;
            var roadRefMsg = "";
            var propertiesMsg = "";
            properties.forEach(function (data) {
                propertiesMsg = propertiesMsg + data.label + ': ' + data.value + '<br>';
            });
            if (data.geometry.type == "Point") {
                points[0].pop();
                roadRefMsg = roadRef[0].shortForm;
                marker(latLng(points[0][0], points[0][1]), { icon: _this.customIcon }).addTo(_this.myMap).bindPopup('Stikkrenne: ' + roadRefMsg + '<hr>' + propertiesMsg);
            }
            else if (data.geometry.type == "LineString") {
                points.map(function (data) { data.pop(); });
                var i = 0;
                points.forEach(function (point) {
                    roadRefMsg = roadRef[i].shortForm;
                    marker(latLng(point[0], point[1]), { icon: _this.customIcon }).addTo(_this.myMap).bindPopup('Stikkrenne: ' + roadRefMsg + '<hr>' + propertiesMsg);
                    i++;
                });
                polyline(points, { color: 'black', weight: 10 }).addTo(_this.myMap).bindPopup('Stikkrenne: <hr>' + propertiesMsg);
            }
        });
    };
    TeamMapComponent.prototype.getEquipmentList = function (teamId) {
        var _this = this;
        var coord = [];
        this.equipmentSubscription = timer(0, 15000).pipe(switchMap(function () { return _this.geoService.getEquipmentPos(); })).subscribe(function (res) {
            // remove all existing markers from map, before adding new ones
            if (_this.equipmentMarkers) {
                _this.equipmentMarkers.forEach(function (mmarker) {
                    mmarker.remove();
                });
                _this.equipmentMarkers = [];
            }
            else {
                _this.equipmentMarkers = [];
            }
            res.forEach(function (data) {
                if (data.groups !== undefined && data.groups[0].name === 'Lag ' + teamId && data.status) {
                    _this.equipmentsList.push(data);
                    var speed = data.status.location.speed == null ? 0 : data.status.location.speed;
                    var lat = data.status.location.point.lat;
                    var lon = data.status.location.point.lon;
                    _this.equipmentIconUrl = speed > 5 ? 'assets/map-icons/greenTruck.png' : 'assets/map-icons/redTruck.png';
                    if (data.type === "6Rekkverksutstyr") {
                        _this.equipmentIconUrl = 'assets/map-icons/trafficlight.png';
                    }
                    else if (data.type === "7Minimaskiner") {
                        _this.equipmentIconUrl = 'assets/map-icons/excavator.png';
                    }
                    else if (data.type === "6Tilhengere") {
                        _this.equipmentIconUrl = 'assets/map-icons/trailer.png';
                    }
                    ;
                    var Icon = L.icon({
                        iconUrl: _this.equipmentIconUrl,
                        iconSize: [30, 30],
                        iconAnchor: [15, 30],
                        popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
                    });
                    var formattedDate = _this.getFormatedTime(data.status.time.received);
                    var dirIcon = _this.getDirectionIcon(data.status.location.direction, speed);
                    var popupMsg = data.name + '<hr>' + data.registration_id + '<br>Fart: ' +
                        speed + ' km/t<br>Retning: ' + dirIcon + '<hr> Sist oppdatert: ' + formattedDate;
                    var eqMarker = marker(latLng(lat, lon), { icon: Icon }).addTo(_this.myMap).bindPopup(popupMsg);
                    _this.equipmentMarkers.push(eqMarker);
                    coord.push([lat, lon]);
                }
            });
        });
    };
    TeamMapComponent.prototype.getFormatedTime = function (receivedtime) {
        var time = new Date(receivedtime);
        return Intl.DateTimeFormat('nb', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }).format(time);
    };
    TeamMapComponent.prototype.getDirectionIcon = function (direction, speed) {
        var dir = "";
        var deg = direction;
        if (speed <= 2) {
            dir = "none";
        }
        else {
            if (deg > 32 && deg <= 67) {
                dir = "ne";
            }
            else if (deg <= 112) {
                dir = "e";
            }
            else if (deg <= 148) {
                dir = "se";
            }
            else if (deg <= 202) {
                dir = "s";
            }
            else if (deg <= 247) {
                dir = "sw";
            }
            else if (deg <= 292) {
                dir = "w";
            }
            else if (deg < 337) {
                dir = "nw";
            }
            else {
                dir = "n";
            }
        }
        var imgName = dir + ".GIF";
        var imgSrc = "<img width='15px' style='padding-top: 4px; vertical-align: top;' src='assets/direction-arrows/" + imgName + "'/>";
        return imgSrc;
    };
    TeamMapComponent.prototype.curvertLines = function (culvert) {
        var _this = this;
        // console.log(culverts);
        var coord = [];
        culvert.forEach(function (c, i) {
            var coordArray = c[0].split(' ');
            coordArray.pop();
            coord.push(coordArray);
        });
        if (coord.length > 1) {
            coord.forEach(function (points) {
                marker(latLng(points), { icon: _this.customIcon }).addTo(_this.myMap);
            });
        }
        else {
            marker(latLng(coord[0]), { icon: this.customIcon }).addTo(this.myMap);
        }
    };
    TeamMapComponent.prototype.createPolylines = function (res) {
        var _this = this;
        var features = res.features;
        this.markers = L.markerClusterGroup({
            showCoverageOnHover: false,
            zoomToBoundsOnClick: true,
            spiderfyOnMaxZoom: false,
            disableClusteringAtZoom: 14
        });
        if (features && features.length > 0) {
            features.forEach(function (data) {
                var latlng = data.geometry.coordinates;
                var popMsg = _this.getPopupMessage(data.properties);
                var markerPopup = L.popup().setContent(popMsg);
                var startPoint = [];
                var startMarker;
                latlng.map(function (data) { data.pop(); });
                var startPoint = latlng[0];
                if (data.geometry.type == "LineString") {
                    var endPoint = latlng[latlng.length - 1];
                    var endMarker = marker(endPoint, { icon: _this.pinkIcon });
                    startMarker = marker(startPoint, { icon: _this.greenIcon });
                    var pl = polyline(latlng, { color: 'green', weight: 10, dashArray: '20' });
                    L.featureGroup([startMarker, endMarker, pl])
                        .bindPopup(markerPopup)
                        .addTo(_this.teamMarkerGroup); // map cluster
                }
                else if (data.geometry.type == "Point") {
                    startMarker = marker(startPoint, { icon: _this.greenIcon }).addTo(_this.teamMarkerGroup).bindPopup(markerPopup);
                }
                _this.markers.addLayer(startMarker);
            });
            var coordinates = features.map(function (data) { return data.geometry.coordinates; });
            coordinates.concat(coordinates);
            this.teamMarkerGroup.addLayer(this.markers);
            this.myMap.fitBounds(coordinates); // to set the zoom level based on the coordinates
        }
    };
    TeamMapComponent.prototype.getPopupMessage = function (properties) {
        var popupHeader = '<strong>Ordre ' + properties.order + ' ' +
            properties.orderSubject
            + '<br>parsell ' + properties.parsell
            + '</strong>';
        var popupContent = 'Lengde: ' + properties.lengde
            + '<br>Vegref start: ' + properties.startRoadRef
            + '<br>Vegref slutt: ' + properties.endRoadRef
            + '<br>Type: ' + properties.rtType
            + '<br>Riving: ' + properties.rivLengde + 'm'
            + '<br>Starttype: ' + properties.startType
            + '<br>Endetype: ' + properties.endType;
        var popupMsg = popupHeader + ' <hr>' + popupContent;
        return popupMsg;
    };
    TeamMapComponent.prototype.onMapCLick = function ($event) {
        throw new Error("Method not implemented.");
    };
    TeamMapComponent.prototype.onPopupClose = function (event) {
        this.selectedPointMarker = null;
    };
    return TeamMapComponent;
}());
export { TeamMapComponent };
