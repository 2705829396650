/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./culverts-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../culverts/culverts.component.ngfactory";
import * as i3 from "../culverts/culverts.component";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./culverts-dialog.component";
import * as i9 from "@angular/material/dialog";
var styles_CulvertsDialogComponent = [i0.styles];
var RenderType_CulvertsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CulvertsDialogComponent, data: {} });
export { RenderType_CulvertsDialogComponent as RenderType_CulvertsDialogComponent };
export function View_CulvertsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Stikkrenner"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-culverts", [], null, null, null, i2.View_CulvertsComponent_0, i2.RenderType_CulvertsComponent)), i1.ɵdid(5, 114688, null, 0, i3.CulvertsComponent, [], { culverts: [0, "culverts"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "bottom-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Lukk"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.culverts; _ck(_v, 5, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 8).disabled || null); var currVal_2 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_1, currVal_2); }); }
export function View_CulvertsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-culverts-dialog", [], null, null, null, View_CulvertsDialogComponent_0, RenderType_CulvertsDialogComponent)), i1.ɵdid(1, 114688, null, 0, i8.CulvertsDialogComponent, [i9.MatDialogRef, i9.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CulvertsDialogComponentNgFactory = i1.ɵccf("app-culverts-dialog", i8.CulvertsDialogComponent, View_CulvertsDialogComponent_Host_0, {}, {}, []);
export { CulvertsDialogComponentNgFactory as CulvertsDialogComponentNgFactory };
