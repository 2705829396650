export class Position {
  shortForm: string;
  meter: number;
  road: string;
  section: number;
  subSection: number;
  coordinates: Array<number>;
  municipality: number;
  county: number;
}
