var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractDialogComponent } from '../../abstract-dialog/abstract-dialog.component';
import { DataService } from '../../../data.service';
import { UserService } from '../../../user.service';
import { MediaService } from '../../../shared/media.service';
var SjaRiskFactorsDialogComponent = /** @class */ (function (_super) {
    __extends(SjaRiskFactorsDialogComponent, _super);
    function SjaRiskFactorsDialogComponent(dialogRef, data, dataService, mediaService, user) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.dataService = dataService;
        _this.mediaService = mediaService;
        _this.user = user;
        _this.dialogFormGroup = new FormGroup({
            operation: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required),
            measures: new FormControl('', Validators.required),
            responsible: new FormControl('', Validators.required)
        });
        _this.mediaService.getMedia().subscribe(function (media) {
            _this.isMobile = media.isMobileView;
        });
        if (_this.data.risk) {
            _this.isEdit = true;
            _this.dialogFormGroup.setValue(_this.data.risk);
        }
        else {
            _this.isEdit = false;
        }
        return _this;
    }
    SjaRiskFactorsDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    SjaRiskFactorsDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataService.getTeamEmployyes(this.user.teamId).subscribe(function (employees) {
            _this.myTeam = employees;
            if (_this.data.risk) {
                var selectedEmployee = _this.myTeam.find(function (employee) {
                    return employee.unid === _this.data.risk.responsible.unid;
                });
                _this.dialogFormGroup.get('responsible').setValue(selectedEmployee);
            }
        });
    };
    SjaRiskFactorsDialogComponent.prototype.confirm = function (event) {
        this.dialogRef.close({ form: this.dialogFormGroup.value, isEdit: this.isEdit });
    };
    return SjaRiskFactorsDialogComponent;
}(AbstractDialogComponent));
export { SjaRiskFactorsDialogComponent };
