import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { TaskService } from '../../task.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { UserService } from '../../user.service';
import * as moment from 'moment';
import { HeaderService } from '../header/header.service';
import { MediaService } from '../../shared/media.service';
import { WorkshopTaskDialogComponent } from '../workshop-task-dialog/workshop-task-dialog.component';
import { timingSafeEqual } from 'crypto';
@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
  tasks = [];
  taskColumns: string[] = ['subject','vedlegg', 'name','signedBy', 'dateEnd', 'action'];
  weeks: Array<any> = [];
  selectedWeek: number;
  loading = true;
  isMobileView: boolean;
  constructor(private taskService: TaskService,
              private snackbar: MatSnackBar,
              private user: UserService,
              private headerService: HeaderService,
              private dialog: MatDialog,
              private mediaService: MediaService) {
                this.mediaService.getMedia().subscribe( media => {
                  this.isMobileView = media.isMobileView;
                })
  }

  ngOnInit() {
    const m = moment();
    this.selectedWeek = m.isoWeek();

    this.taskService.getWorkshopTasks(this.user.teamId, this.selectedWeek).subscribe(tasks => {
      this.loading = false;
      this.tasks = tasks;
    })

    this.getWeeks();
    this.taskService.getTaskActions().subscribe(action => {
      this.taskService.getWorkshopTasks(this.user.teamId, this.selectedWeek).subscribe(tasks => {
        this.tasks = tasks;
      })
    });

    
  }

 

  filterWeek(event) {
    this.loading = true;
    this.taskService.getWorkshopTasks(this.user.teamId, this.selectedWeek ).subscribe(tasks => {
      this.tasks = tasks;
      this.loading = false;
    })
  }

  deleteTask(task: any) {
    const doDelete = confirm("Er du sikker på du vil slette oppgaven? ")
    if (doDelete) {
      this.taskService.deleteTask(task).subscribe(status => {
        if (status === "success") {
          console.log("oppgave slettet");
          this.snackbar.open('Oppgaven ble slettet', '', {duration: 5000});
        }
      });
    }
  }

  editTask(task: any) {
    const dialogRef = this.dialog.open(WorkshopTaskDialogComponent, {
      height: '80%',
      width: '90%',
      data: {task}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          if (result.task && result.task.unid) {
            this.snackbar.open("Endringer på verkstedsoppgave er lagret", '', {duration: 5000});
          }
      }
    });
  }
  createWorkshopTask() {
    const dialogRef = this.dialog.open(WorkshopTaskDialogComponent, {
      height: '80%',
      width: '90%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          if (result.task && result.task.unid) {
            this.snackbar.open("Ny Verkstedsoppgave er opprettet", '', {duration: 5000});
          }
      }
    });
  }
  getWeeks() {
    this.weeks.push({value:0, label: "Åpen uke"})
    for (let i = 1; i <= 53; i++) {
      const uke = `Uke ${i}`;
      this.weeks.push({value: i, label: uke});
    }
  }

}
