import { AfterViewInit, Component, Inject, OnInit, ViewChild, HostBinding, OnDestroy } from '@angular/core';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { POINT_STATUS } from '../../point-status';
import { PARSEL_STATUS } from '../../parsel-status';
import { MAT_DIALOG_DATA, MatSnackBar, MatTableDataSource, MatStepper } from '@angular/material';
import { DataService } from '../../data.service';
import { MeasuringPoint } from '../../measuring-point';
import { MeasureService } from '../../measure.service';
import { Article } from '../../article';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { ArticleAdjustment } from '../../article-adjustment';
import { CompositeArticle } from '../../composite-article';
import { IsiRoadRef } from '../../isi-road-ref';
import { RailingCategoriesSelectComponent } from '../railing-categories-select/railing-categories-select.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectArticleFormComponent } from '../select-article-form/select-article-form.component';
import { hostViewClassName } from '@angular/compiler';

@Component({
  selector: 'app-save-point-dialog',
  templateUrl: './save-point-dialog.component.html',
  styleUrls: ['./save-point-dialog.component.scss'],
  animations: [
    trigger('addArticleState', [
      state('inactive', style({
        transform: 'translateY(112%)'
      })),
      state('active', style({
        transform: 'translateY(0%)'
      })),
      transition('inactive => active', animate('500ms ease-in')),
      transition('active => inactive', animate('500ms ease-out'))
    ]),
    trigger('hideArticleState', [
      state('active', style({
        transform: 'translateY(112%)'
      })),
      state('inactive', style({
        transform: 'translateY(0%)'
      })),
      transition('inactive => active', animate('500ms ease-in')),
      transition('active => inactive', animate('500ms ease-out'))
    ])
  ]
})
export class SavePointDialogComponent extends AbstractDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class.app-save-point-dialog') hostClass = true;
  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  @ViewChild('startEndArticleSelect', {static: false}) startEndArticleSelect: SelectArticleFormComponent;

  dialogFormGroup: FormGroup;
  addArticleFormGroup: FormGroup;
  measureSwitch: FormControl;
  demolitionSwitch: FormControl;
  showArticlesCheckbox: FormControl;
  calcLengthInput: FormControl;
  quantityFormControl: FormControl;
  changeDirectionControl: FormControl;
  isMeasuring = false;
  isDemobilizing = false;
  addingManualArticle = false;
  addingCompositeArticle = false;
  addArticleState = "inactive";
  hideArticleState = "active";
  savePointTxt = "Lagre";
  tmpCalculatedArticles: MatTableDataSource<Article> = new MatTableDataSource();
  tmpArticleAdjustments: { [key: string]: ArticleAdjustment };
  tmpPoint: MeasuringPoint;
  pointsToCalculate: Array<MeasuringPoint> = [];
  articleFormControlName = "articleFormControl";
  lengthCalculation: number;
  lengthSinceLastCountingPoint: number;
  isiRoadRef: IsiRoadRef;
  showArticles = false;
  containsMeasuringPoint = false;
  lblEndType: string;
  buildLength = 0;
  demolitionLength = 0;
  trackLength = 0;
  previousParsellState;
  previousDemoParsellState;
  childFormControlName = "manualArticleForm";
  compositeArticlesSubject:Subject<Array<CompositeArticle>> = new Subject();
  compositeArticles: Array<CompositeArticle> = []; // used for displaying all composite articles (from `data` and `tmpPoint`)
  tmpNewCompoisteArticles: Array<CompositeArticle> = []; // used to keep track of added composite articles inside this dialog
  tmpNewArticles: Array<Article> = []; // used to keep track of added articles inside this dialog
  startOrEndArticle: CompositeArticle; // the default is the nearest
  calculateArticlesSubscription: Subscription;
  directionChanged = false;
  endIsSelected = false;
  isCalculating = false;
  invoiceType: string;
  steps = [
    {
      label: 'Startende',
      endType: ''
    },
    {
      label: 'Målepunkt',
      endType: ''
    },
    {
      label: 'Sluttende',
      endType: ''
    }
  ]
  parsellStatus: number;
  @ViewChild('mounting', {static: false}) mountingSelectComponent: RailingCategoriesSelectComponent;
  @ViewChild('demolition', {static: false}) demolitionSelectComponent: RailingCategoriesSelectComponent;
  constructor(protected dialogRef: MatDialogRef<AbstractDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService, private measureService: MeasureService, private snackbar: MatSnackBar) {
    super(dialogRef);
    this.parsellStatus = this.data.parsellStatus;
    this.isMeasuring = this.data.isMeasuringState;
    this.isDemobilizing = this.data.isDemobilizingState;
    this.invoiceType = this.data.parcel.invoiceType;
    console.log('invoiceType', this.data)
    if (this.data.parcel && this.data.parcel.points && this.data.parcel.points.length === 0) {
      this.isMeasuring = false;
      this.isDemobilizing = false;
      this.data.typeOfPoint = null;
    }

    if (this.data.parcel && this.data.parcel.points && this.data.parcel.points.length > 0) {
      // get start end type
      // find first measure point
      if (this.data.parcel.stopEndFirst) {
        this.changeDirection(false);
      }
      this.data.parcel.points.some((point: MeasuringPoint) => {
        if (point.state === 1 || point.state === 2) {
          point.compositeArticles.forEach((sa: CompositeArticle) => {
            if (sa.category.toUpperCase() === 'ENDE') {
              this.steps[0].endType = sa.name;
            }
          });
          return true;
        }
      });
    }
    this.isiRoadRef = data.isiRoadRef;
    this.lengthSinceLastCountingPoint = this.data.lengthSinceLastCountingPoint;
    this.data.demolitionType = this.data.demolitionType || { id: '' };
    this.data.rtType = this.data.rtType || { id: '' };
    this.tmpArticleAdjustments = data.tmpArticleAdjustments;
    this.measureSwitch = new FormControl({
      value: this.isMeasuring,
      disabled: this.data.rtType.id ? false : true
    }, []);

    this.changeDirectionControl = new FormControl({
      value: this.directionChanged
    });
    this.demolitionSwitch = new FormControl({
      value: this.isDemobilizing,
      disabled: this.data.demolitionType.id ? false : true
    }, []);
    this.showArticlesCheckbox = new FormControl(this.showArticles, []);
    this.calcLengthInput = new FormControl(this.lengthCalculation, [Validators.required]);
    this.quantityFormControl = new FormControl(1, [Validators.min(1), Validators.required]);
    this.addArticleFormGroup = new FormGroup({
      'quantityFormControl': this.quantityFormControl
    });
    this.dialogFormGroup = new FormGroup({
      'measureSwitch': this.measureSwitch,
      'demolitionSwitch': this.demolitionSwitch,
      'showArticlesCheckbox': this.showArticlesCheckbox,
      'changeDirectionControl': this.changeDirectionControl
    });
    this.updateSavePoint();
    if (!this.isDemobilizing && !this.isMeasuring && this.data.parcel.points.length > 0) {
      this.calculateArticles();
    }
  }

  updateStartOrEndDialogText() {
    const points = this.data.parcel.points;
    if (points && this.tmpPoint &&
      (points.length === 0 || (points.length > 0 && !this.isDemobilizing && !this.isMeasuring))) {
      const allotmentHeader = this.measureService.getAllotmentHeader(this.data.order, this.data.parcel.unid);
      if (allotmentHeader) {
        if (this.measureService.isPointClosestToEndOfAllotment(this.tmpPoint, allotmentHeader)) {
          if (this.parsellStatus === 1) {
            this.data.parcel.stopEndFirst = true;
            this.changeDirection(false);
          }
        } else {
          if (this.parsellStatus === 1) {
            this.directionChanged = false;
          }
        }
        if (this.parsellStatus !== 1) {
          this.changeDirectionControl.disable();
        }
      }
    }
  }

  setStepperIndex() {
    switch (this.parsellStatus) {
      case 1:
        this.stepper.selectedIndex = 0;
        break;
      case 2:
        this.stepper.selectedIndex = 1;
        break;
      case 3:
        if (this.stepper) {
          this.stepper.selectedIndex = 2;
          if (this.stepper.selected) {
            this.stepper.selected.completed = true;
            this.stepper.selected.editable = false;
          }
        }
        break;
    }
  }
  ngOnInit() {
  }
  ngOnDestroy(): void {
    if (this.calculateArticlesSubscription) {
      this.calculateArticlesSubscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    setTimeout( () => {
      this.setStepperIndex();
    });
    if (this.data && this.data.parcel && this.data.parcel.points) {
      this.initRailingTypeSelectState();
    }
  }
  initRailingTypeSelectState() {
    setTimeout(() => { // XXX using timeout because of bug: https://github.com/angular/angular/issues/17572
      for (const p of this.data.parcel.points) {
        if (p.state === POINT_STATUS.DEMOLITION_POINT) {
          this.demolitionSelectComponent.formGroup.disable({ onlySelf: false });
        } else if (p.state === POINT_STATUS.MEASURE_POINT) {
          this.mountingSelectComponent.formGroup.disable({ onlySelf: false });
        } else {
          this.demolitionSelectComponent.formGroup.disable({ onlySelf: false });
          this.mountingSelectComponent.formGroup.disable({ onlySelf: false });
        }
      }
    });
  }
  confirm(event, articles?: Array<Article>) {
    const isLastPoint = !this.isMeasuring && !this.isDemobilizing;
    if (!articles && isLastPoint) {
      articles = this.tmpCalculatedArticles.data;
    }
    if (!this.isMeasuring && this.trackContainsMeasuringPoint(this.data.points)) { // assumes that the endArticle is added
      this.data.endArticleAdded = true;
    }
    this.data.parcel.stopEndFirst = this.directionChanged;

    if (!this.tmpPoint.isiRoadRef) {
      this.tmpPoint.isiRoadRef = this.isiRoadRef;
    }

    const resData = {
      type: SavePointDialogComponent,
      parsellStatus: this.parsellStatus,
      typeOfPoint: this.data.typeOfPoint,
      gpsData: this.data.gpsData,
      gpsQuality: this.data.gpsQuality,
      isLastPoint: isLastPoint,
      articles: articles,
      rtType: this.data.rtType,
      demolitionType: this.data.demolitionType,
      tmpArticleAdjustments: this.tmpArticleAdjustments,
      tmpPoint: this.tmpPoint,
      endArticleAdded: this.data.endArticleAdded,
      isMeasuringState: this.isMeasuring,
      isDemobilizingState: this.isDemobilizing
    };
    this.dialogRef.close(resData);
  };
  measureSwitchChange(event) {
    this.isMeasuring = !this.isMeasuring;
    if (this.previousParsellState) {
      if (this.previousParsellState === 10) {
        this.parsellStatus = undefined;
      } else {
        this.parsellStatus = this.previousParsellState;
      }
      this.previousParsellState = undefined;
    }

    this.updateSavePoint();
    this.updateStartOrEndDialogText();
    this.showOrHideArticles();
    this.setStepperIndex();
    this.addStartOrEndCompositeArticle();
    this.updateCompositeArticles();
    this.calculateArticles();
    if (this.parsellStatus === 3) {
      this.showArticlesCheckbox.setValue(true);
      this.showArticlesChange("measure");
    }
  }
  demolitionSwitchChange(event) {
    this.isDemobilizing = !this.isDemobilizing;
    if (this.previousDemoParsellState && this.parsellStatus !== PARSEL_STATUS.MEASURING_END) {
      if (this.previousDemoParsellState === 10) {
        this.parsellStatus = undefined;
      } else {
        this.parsellStatus = this.previousDemoParsellState;
      }
      this.previousParsellState = undefined;
    }
    this.updateSavePoint();
    this.calculateArticles();
    this.updateStartOrEndDialogText();
    this.showOrHideArticles();

    if (this.parsellStatus === 3) {
      this.showArticlesCheckbox.setValue(true);
      this.showArticlesChange("measure");
    } else if (this.parsellStatus === 1) {
      this.addStartOrEndCompositeArticle();
      this.updateCompositeArticles();
    }
  }

  changeDirection(doCheck) {
    const step2type = this.steps[2].endType;
    this.steps[2].endType = this.steps[0].endType;
    this.steps[0].endType = step2type;
    this.steps = this.steps.reverse();

    this.directionChanged = !this.directionChanged;

    this.checkStartOrEndCompositeArticles(doCheck); // false default
  }
  showOrHideArticles() {
    if (this.tmpCalculatedArticles &&
      this.data.parcel.points.length > 0 &&
      (this.showArticles || !this.isDemobilizing && !this.isMeasuring)
    ) {
      this.hideArticleState = "inactive";
    } else {
      this.hideArticleState = "active";
    }
  }

  showArticlesChange(event) {
    this.showArticles = this.showArticlesCheckbox.value;
    this.showOrHideArticles();
    let doCheck = true;
    if (this.endIsSelected) {
      doCheck = false;
    }
    this.checkStartOrEndCompositeArticles(doCheck);
    if (event !== 'measure') {
      // do not calculate when event come from measureSwitch change
      this.calculateArticles();
    }
  }
  calcLengthChange(event) {
    this.updateSavePoint();
    this.calculateArticles();
  }
  switchClick(switchId: string) {
    if (switchId === 'demolition') {
      if (this.demolitionSwitch.disabled) {
        this.snackbar.open("Vennligst velg en rivingstype 🏗️", "", { duration: 5000 });
      }
    }
  }
  onChildStartOrEndArticleChanged(article: Article | CompositeArticle) {
    if ('category' in article && typeof article.category === "string") {
      const articleCpy = Object.assign({}, <CompositeArticle>article);
      articleCpy.selectedQuantity = 1;
      this.startOrEndArticle = articleCpy;
      this.checkStartOrEndCompositeArticles(false);
      this.addStartOrEndCompositeArticle();
      this.calculateArticles();
      this.endIsSelected = true;
    }
  }
  updateSavePoint() {
    this.previousParsellState = this.parsellStatus;
    if (this.previousParsellState === undefined) {
      this.previousParsellState = 10;
    }
    this.previousDemoParsellState = this.parsellStatus;
    if (this.previousDemoParsellState === undefined) {
      this.previousDemoParsellState = 10;
    }
    this.tmpPoint = new MeasuringPoint(this.data.gpsData, this.data.typeOfPoint);


    // different cases
    switch (this.parsellStatus) {
      case PARSEL_STATUS.DEMOLITION_BEFORE_MEASURING:
        if (this.isMeasuring && this.isDemobilizing) {
          this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
          this.parsellStatus = PARSEL_STATUS.MEASURING_START;
        } else if (this.isMeasuring && this.tmpPoint.state === POINT_STATUS.DEMOLITION_POINT) {
          this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
          this.parsellStatus = PARSEL_STATUS.MEASURING_START;
        } else if (this.isMeasuring) {
          this.data.typeOfPoint = POINT_STATUS.MEASURE_POINT;
          this.parsellStatus = PARSEL_STATUS.MEASURING_START;
        } else if (this.isDemobilizing) {
          this.data.typeOfPoint = POINT_STATUS.DEMOLITION_POINT;
        }
        this.savePointTxt = "Lagre punkt";

        break;
      case PARSEL_STATUS.MEASURING_START:
        if (this.isMeasuring && this.isDemobilizing) {
          this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
          this.parsellStatus = PARSEL_STATUS.MEASURING_AND_DEMOLITION;
          this.previousDemoParsellState = this.parsellStatus;
          this.previousParsellState = this.parsellStatus;
        } else if (!this.isDemobilizing && this.isMeasuring && this.tmpPoint.state === POINT_STATUS.DEMOLITION_AND_MEASURE_POINT) {
          this.savePointTxt = "Lagre punkt";
          if (this.trackContainsMeasuringPoint(this.data.points)) {
            this.parsellStatus = PARSEL_STATUS.MEASURING;
            this.data.typeOfPoint = POINT_STATUS.MEASURE_POINT;
          } else {
            this.parsellStatus = PARSEL_STATUS.MEASURING_START;
            this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
          }
        } else if (!this.isDemobilizing && this.tmpPoint.state === POINT_STATUS.DEMOLITION_AND_MEASURE_POINT) {
          this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
          this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
        } else if (this.isMeasuring && !this.isDemobilizing) {
          this.data.typeOfPoint = POINT_STATUS.MEASURE_POINT;
          this.savePointTxt = "Lagre punkt";
          this.parsellStatus = PARSEL_STATUS.MEASURING;
        } else if (this.isDemobilizing) {
          this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
          this.savePointTxt = "Lagre punkt";
          this.parsellStatus = PARSEL_STATUS.DEMOLITION_AFTER_MEASURING;
        } else if (!this.isDemobilizing && !this.isMeasuring) {
          // keep current point state
          this.data.typeOfPoint = this.tmpPoint.state;
          this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
          this.parsellStatus = PARSEL_STATUS.MEASURING_END;
        }

        break;
      case PARSEL_STATUS.MEASURING:
        if (!this.isMeasuring && this.isDemobilizing) {
          this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
          this.parsellStatus = PARSEL_STATUS.MEASURING_END_AND_DEMOLITION;
          this.savePointTxt = "Lagre punkt";
        } else if (!this.isMeasuring && !this.isDemobilizing) {
          this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
          this.parsellStatus = PARSEL_STATUS.MEASURING_END;
        } else if (this.isDemobilizing && this.isMeasuring) {
          this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
          this.parsellStatus = PARSEL_STATUS.MEASURING_AND_DEMOLITION;
        } else if (!this.isDemobilizing && this.isMeasuring) {
          this.data.typeOfPoint = POINT_STATUS.MEASURE_POINT;
          this.parsellStatus = PARSEL_STATUS.MEASURING;
        }
        break;
      case PARSEL_STATUS.MEASURING_AND_DEMOLITION:
        this.savePointTxt = "Lagre punkt";
        if (!this.isMeasuring && !this.isDemobilizing) {
          this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
          this.parsellStatus = PARSEL_STATUS.MEASURING_END;
          this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
        } else if (this.isMeasuring && !this.isDemobilizing) {
          this.parsellStatus = PARSEL_STATUS.MEASURING;
        } else if (!this.isMeasuring && this.isDemobilizing) {
          this.parsellStatus = PARSEL_STATUS.MEASURING_END_AND_DEMOLITION;
        }
        break;
      case PARSEL_STATUS.MEASURING_END:
        if (!this.isMeasuring && !this.isDemobilizing) {
          if (this.tmpPoint.state === POINT_STATUS.DEMOLITION_AND_MEASURE_POINT
              && this.previousParsellState !== PARSEL_STATUS.MEASURING_END) {
            this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
            this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
            this.parsellStatus = PARSEL_STATUS.DEMOLITION_AFTER_MEASURING;
          } else if (this.parsellStatus === PARSEL_STATUS.MEASURING_END
            && this.tmpPoint.state === POINT_STATUS.DEMOLITION_AND_MEASURE_POINT) {
            this.data.typeOfPoint = POINT_STATUS.MEASURE_POINT;
            this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
          } else if (this.parsellStatus === PARSEL_STATUS.MEASURING_END
                     && this.tmpPoint.state !== POINT_STATUS.DEMOLITION_POINT) {
            this.parsellStatus = PARSEL_STATUS.DEMOLITION_AFTER_MEASURING;
            this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
          } else {
            this.data.typeOfPoint = POINT_STATUS.MEASURE_POINT;
            this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
          }
        } else if (!this.isMeasuring && this.isDemobilizing) {
          if (this.previousParsellState === PARSEL_STATUS.MEASURING_START) {
            this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
            this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
          } else if (this.previousParsellState === PARSEL_STATUS.MEASURING_END) {
            this.parsellStatus = PARSEL_STATUS.MEASURING_END_AND_DEMOLITION;
            this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
            this.savePointTxt = "Lagre punkt";
          } else {
            this.data.typeOfPoint = POINT_STATUS.DEMOLITION_POINT;
            this.savePointTxt = "Lagre punkt";
            if (this.tmpPoint.state === POINT_STATUS.MEASURE_POINT
                && this.previousParsellState !== PARSEL_STATUS.MEASURING_END) {
              this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
              this.parsellStatus = PARSEL_STATUS.MEASURING_END;
            } else {
              this.parsellStatus = PARSEL_STATUS.MEASURING_END;
              this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
            }
          }

        } else if (this.isMeasuring && !this.isDemobilizing) {

        } else if (this.isMeasuring && this.isDemobilizing) {

        }
        break;
      case PARSEL_STATUS.DEMOLITION_AFTER_MEASURING:
        if (!this.isDemobilizing) {
          this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
        } else {
          this.savePointTxt = "Lagre punkt";
        }
        this.parsellStatus = PARSEL_STATUS.DEMOLITION_AFTER_MEASURING;
        this.data.typeOfPoint = POINT_STATUS.DEMOLITION_POINT;
        break;
      case PARSEL_STATUS.MEASURING_END_AND_DEMOLITION:
        if (this.isDemobilizing) {
          this.savePointTxt = "Lagre punkt";
        } else {
          this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
        }
        this.parsellStatus = PARSEL_STATUS.DEMOLITION_AFTER_MEASURING;
        this.data.typeOfPoint = POINT_STATUS.DEMOLITION_POINT;
        break;
      case undefined:
        if (this.isMeasuring && this.isDemobilizing) {
          this.data.typeOfPoint = POINT_STATUS.DEMOLITION_AND_MEASURE_POINT;
          this.parsellStatus = PARSEL_STATUS.MEASURING_START;
        } else if (this.isMeasuring) {
          this.data.typeOfPoint = POINT_STATUS.MEASURE_POINT;
          this.parsellStatus = PARSEL_STATUS.MEASURING_START;
        } else if (this.isDemobilizing) {
          this.data.typeOfPoint = POINT_STATUS.DEMOLITION_POINT;
          this.parsellStatus = PARSEL_STATUS.DEMOLITION_BEFORE_MEASURING;
        }
        this.savePointTxt = "Lagre første punkt";
        break;
    }

    this.tmpPoint.isiRoadRef = this.isiRoadRef;

    const isLastPoint = this.parsellStatus === 3;
    let isEnd = false;
    if ((isLastPoint || this.directionChanged) && !(isLastPoint && this.directionChanged)) {
      isEnd = true;
      //this.endIsSelected = false;
    }

    if (!this.endIsSelected) {
      this.startOrEndArticle = this.measureService.
        findNearestStartEndCompositeArticle(this.data.order, this.tmpPoint, this.data.parcel.unid, isEnd);
    } else {
      // this.checkStartOrEndCompositeArticles(false);
      this.addStartOrEndCompositeArticle();
    }
    if (this.tmpNewCompoisteArticles.length > 0) {
      this.tmpPoint.compositeArticles = [].concat(this.tmpNewCompoisteArticles);
    }

    this.tmpPoint.state = this.data.typeOfPoint;
    this.containsMeasuringPoint = this.trackContainsMeasuringPoint(this.data.points);
  }

  checkStartOrEndCompositeArticles(doCheck) {
    /** COPYED FROM updateSavePoint */
    this.tmpPoint = new MeasuringPoint(this.data.gpsData, this.data.typeOfPoint);

    const isLastPoint = this.parsellStatus === 3;
    let isEnd = false;
    if ((isLastPoint || this.directionChanged) && !(isLastPoint && this.directionChanged)) {
      isEnd = true;
    }
    if (doCheck) {
      this.startOrEndArticle = this.measureService.
        findNearestStartEndCompositeArticle(this.data.order, this.tmpPoint, this.data.parcel.unid, isEnd);
    }
    if (this.startEndArticleSelect) {
      this.startEndArticleSelect.setValue(this.startOrEndArticle);
    }

    if (this.tmpNewCompoisteArticles) {
      this.tmpPoint.compositeArticles = [].concat(this.tmpNewCompoisteArticles);
    }

    if (doCheck) {
      this.addStartOrEndCompositeArticle();
    }
    /** END COPY */
  }
  addArticlesAndPoint(event) {
    this.confirm(event, this.tmpCalculatedArticles.data);
  }
  private calculateArticles() {
    this.isCalculating = true;
    this.pointsToCalculate = [];
    for (const originalPoint of this.data.points) {
      let pointCopy = Object.assign({}, originalPoint);
      if (pointCopy.verifyArticlesPoint) {
        pointCopy.compositeArticles = [];
      }
      this.pointsToCalculate.push(pointCopy); // make a copy of the points instead of changing reference.
    }
    this.pointsToCalculate.push(this.tmpPoint);
    this.lengthCalculation = this.calcDistsanceBetweenPoints(this.pointsToCalculate);
    this.calcLengthInput.setValue(this.lengthCalculation.toFixed(2));
    const splitPoints: Array<Array<MeasuringPoint>> = this.splitPoints(this.pointsToCalculate);
    const requests: Array<Observable<Array<Article>>> = this.createCalculatedArticlesRequests(
      splitPoints,
      this.data.rtType.id,
      this.data.demolitionType.id,
      this.invoiceType,
      this.data.parcel.unid
    ).requests;
    if (this.calculateArticlesSubscription) {
      this.calculateArticlesSubscription.unsubscribe();
    }
    this.calculateArticlesSubscription = forkJoin(requests).subscribe(res => {
      const articlesMap = this.measureService.createArticlesMap(res);
      const articles = [];
      for (const key in articlesMap) {
        if (articlesMap[key]) {
          articles.push(articlesMap[key]);
        }
      }
      this.buildLength = this.measureService.getBuildLength(articles);
      this.demolitionLength = this.getDemolitionLength(this.pointsToCalculate);
      this.trackLength = +this.getTrackLength(this.pointsToCalculate);
      this.tmpCalculatedArticles = new MatTableDataSource<any>(articles);
      this.isCalculating = false;
    },
    error => {
      this.isCalculating = false;
    });
    this.updateCompositeArticles();
  }
  onDemolitionTypeChanged(railingType) {
    this.data.demolitionType = railingType;
    if (railingType.id) {
      this.demolitionSwitch.enable();
    } else {
      this.demolitionSwitch.disable();
    }
  }
  onRtTypeChanged(railingType) {
    this.data.rtType = railingType;
    if (railingType.id) {
      this.measureSwitch.enable();
    } else {
      this.measureSwitch.disable();
    }
  }
  /**
   * Will use the splitPoints array to figure out if the points should be demolition or measure.
   * Will also calculate new distance since last point on all the entries in splitPoints.
   * @param {Array<Array<MeasuringPoint>>} splitPoints
   * @returns {railIds: Array<string>, requests: Array<Observable<Array<Article>>>}
   */
  createCalculatedArticlesRequests(splitPoints: Array<Array<MeasuringPoint>>, rtTypeId: string, demolitionTypeId: string, invoiceType: string, allotmentUnid: string): { railIds: Array<string>, requests: Array<Observable<Array<Article>>> } {
    return this.measureService.createCalculatedArticlesRequests(splitPoints, rtTypeId, demolitionTypeId, invoiceType, allotmentUnid);
  }
  splitPoints(points): Array<Array<MeasuringPoint>> {
    return this.measureService.splitPoints(points);
  }

  showAddCompositeArticle(event) {
    this.addingCompositeArticle = true;
    this.addArticleState = "active";
    this.hideArticleState = "active";
  }
  showAddArticle(event) {
    this.addingManualArticle = true;
    this.addArticleState = "active";
    this.hideArticleState = "active";
  }
  cancelAddArticle(event) {
    this.addingCompositeArticle = false;
    this.addingManualArticle = false;
    this.addArticleState = "inactive";
    this.hideArticleState = "inactive";
  }
  confirmAddArticle(event) {
    const val: Article | CompositeArticle = this.addArticleFormGroup.get(this.childFormControlName).value;
    if (this.addingManualArticle && val) {
      const articleCpy = Object.assign({}, <Article>val);
      this.tmpNewArticles.push(articleCpy);
    } else if (this.addingCompositeArticle && val) {
      const compositeArticleCpy = Object.assign({}, <CompositeArticle>val);
      compositeArticleCpy.selectedQuantity = this.quantityFormControl.value;
      this.tmpNewCompoisteArticles.push(compositeArticleCpy);
      this.checkStartOrEndCompositeArticles(true);
      this.calculateArticles();
    }
    this.cancelAddArticle(event);
  }
  updateCompositeArticles() {
    this.compositeArticles = [];
    const calcPoints = this.data.points.concat([this.tmpPoint]);
    for (const p of calcPoints) {
      if (p && p.compositeArticles && !p.verifyArticlesPoint) { // dont add composite articles if point has been counted.
        this.compositeArticles = this.compositeArticles.concat(p.compositeArticles);
        this.notifyChildren();
      }
    }
  }
  notifyChildren() {
    this.compositeArticlesSubject.next(this.compositeArticles);
  }
  // XXX assumes that there are only one continuous track of measuring points,
  // XXX and demolition is only done before and/or after a measuring track.
  private addStartOrEndCompositeArticle() {
    this.tmpPoint.compositeArticles = this.tmpPoint.compositeArticles || [];
    if (this.parsellStatus === PARSEL_STATUS.MEASURING_END) { // add end article
      this.tmpPoint.compositeArticles.push(this.startOrEndArticle);
      this.steps[2].endType = this.startOrEndArticle.name;
    } else if (this.parsellStatus === PARSEL_STATUS.MEASURING_START) { // add start article
      this.tmpPoint.compositeArticles.push(this.startOrEndArticle);
      this.steps[0].endType = this.startOrEndArticle.name;
    } else if (!this.isMeasuring && this.trackContainsMeasuringPoint(this.data.points) && !this.data.endArticleAdded) { // add end article
      this.tmpPoint.compositeArticles.push(this.startOrEndArticle);
      this.steps[2].endType = this.startOrEndArticle.name;
    }
  }

  private trackContainsMeasuringPoint(allPoints: Array<MeasuringPoint>) {
    for (const p of allPoints) {
      if (p &&
        (p.state % POINT_STATUS.MODULATOR === POINT_STATUS.MEASURE_POINT ||
          p.state % POINT_STATUS.MODULATOR === POINT_STATUS.DEMOLITION_AND_MEASURE_POINT)) {
        return true;
      }
    }
    return false;
  }
  private calcDistsanceBetweenPoints(points: Array<MeasuringPoint>): number {
    return this.measureService.calcDistsanceBetweenPoints(points);
  }

  private getDemolitionLength(points: Array<MeasuringPoint>): number {
    const tempPoints: Array<MeasuringPoint> = [];
    for (let i = 0; i < points.length; i++) {
      const point: MeasuringPoint = Object.assign({}, points[i]);
      if (point.state <= POINT_STATUS.DEMOLITION_AND_MEASURE_POINT) {
        if (i > 0) {
          const prevPoint: MeasuringPoint = points[i - 1];
          if (prevPoint.state > POINT_STATUS.DEMOLITION_AND_MEASURE_POINT) {
            point.distanceFromPreviousPoint = 0;
          } else {
            point.distanceFromPreviousPoint = this.measureService.getDistanceBetweenMeasuringPoints(point, prevPoint);
          }
        }
        tempPoints.push(point);
      }
    }
    return this.getTrackLength(tempPoints);
  }

  private getTrackLength(points: Array<MeasuringPoint>): number {
    let tmpLength = 0;
    for (const p of points) {
      tmpLength += p.distanceFromPreviousPoint;
    }
    return tmpLength;
  }
}
