<form class="dialog" #countDialogForm="ngForm" [formGroup]="dialogFormGroup" (ngSubmit)="confirm($event)">
  <h2>Bekreftelse på sletting av punkt</h2>
  <button class="btn-close" type="button" color="accent" mat-icon-button (click)="closeDialog()">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
  <div class="confirm-content">
    <p>
      Du har valgt å slette et punkt som har vært med i en beregning.
      Vil du slette dine artikler, og gjøre en ny beregning senere? (Strukturartikler blir tatt vare på).
    </p>
    <div class="button-group">
      <button mat-raised-button [color]="'secondary'" (click)="deleteArticlesOnClose($event,false)">Behold artikler</button>
      <button mat-raised-button [color]="'primary'" (click)="deleteArticlesOnClose($event,true)">Slett artikler</button>
    </div>
  </div>
  <button type="submit" class="btn-save success" color="primary" mat-raised-button>
    Lukk
  </button>
  <div class="placeholder"></div>
</form>
