import { Observable, Subscriber } from 'rxjs';
import * as i0 from "@angular/core";
var HeaderService = /** @class */ (function () {
    function HeaderService() {
        var _this = this;
        this.titleObservable = new Observable(function (subscription) {
            _this.titleSubscriber = subscription;
            // subscription.next('1. tittel');
        });
    }
    HeaderService.prototype.addNavItem = function (item) {
        // const uniqueId = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
        var navItem = Object.assign({ action: 'add' }, item);
        if (this.titleSubscriber) {
            this.titleSubscriber.next(navItem);
        }
    };
    HeaderService.prototype.resetNavItems = function () {
        this.titleSubscriber.next({ action: 'removeAll' });
    };
    HeaderService.prototype.removeNavItem = function (item) {
        var navItem = Object.assign({ action: 'remove' }, item);
        this.titleSubscriber.next(navItem);
    };
    HeaderService.prototype.getPageTitle = function () {
        return this.titleObservable;
    };
    HeaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HeaderService_Factory() { return new HeaderService(); }, token: HeaderService, providedIn: "root" });
    return HeaderService;
}());
export { HeaderService };
