import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  transform(status: string, args?: any): any {
    status = "" + status;
    let statusText = "Åpen";
    switch (status) {
      case "1":
        break;
      case "2":
        break;
      default:
        statusText = "Åpen";
        break;
    }
    return statusText;
  }

}
