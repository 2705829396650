import {Component, Inject, OnInit} from '@angular/core';
import {AbstractDialogComponent} from '../abstract-dialog/abstract-dialog.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material';
import {QualityAudit} from '../../quality-audit';
import {QualityService} from '../../quality.service';
import {Subscription} from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-quality-audit-dialog',
  templateUrl: './quality-audit-dialog.component.html',
  styleUrls: ['./quality-audit-dialog.component.scss']
})
export class QualityAuditDialogComponent extends AbstractDialogComponent implements OnInit {

  dialogFormGroup: FormGroup;
  yesNoControl: FormControl;
  // TODO (john) java code needs to add another `questionType` in order to be sure that the question requires a number as answer
  numberControl: FormControl;
  textControl: FormControl;
  data: any;
  qualityAudit: QualityAudit;
  questionSubscription: Subscription;
  qualityNumber: number;
  constructor(protected dialogRef: MatDialogRef<AbstractDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any,
              private qualityService: QualityService) {
    super(dialogRef);
    this.dialogRef.disableClose = true;
    this.data = data;
    this.yesNoControl = new FormControl('Ja', [Validators.required]);
    this.numberControl = new FormControl(0, [Validators.required]);
    this.textControl = new FormControl('', [Validators.required]);
    this.dialogFormGroup = new FormGroup({
      'yesNoControl': this.yesNoControl,
      'numberControl': this.numberControl,
      'textControl': this.textControl
    });
  }

  ngOnInit() {
    this.questionSubscription = this.qualityService.getRandomQuestion().subscribe( res => {
      this.qualityAudit = res.qualityAudit;
      this.qualityNumber = res.num;
      if (this.qualityAudit.questionType === 1) {
        this.textControl.disable();
      }
    });
  }
  onDestroy() {
    if (this.questionSubscription) {
      this.questionSubscription.unsubscribe();
    }
  }
  confirm(event): void {
    if (this.qualityAudit.questionType === 1) {
      this.qualityAudit.answer = this.yesNoControl.value;
    } else {
      this.qualityAudit.answer = this.textControl.value; // parse number to string
    }
    this.data.qualityAudit = this.qualityAudit;
    this.data.qualityNumber = this.qualityNumber;
    this.dialogRef.close(this.data);
  };

}
