import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {MyPosition} from './my-position';
import {MyCoordinates} from './my-coordinates';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './user.service';
import { AppConfig } from '../environments/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class GeoService {
  apiUrl: string;

  constructor(private http: HttpClient, private userService: UserService) {
    this.apiUrl = AppConfig.apiUrl;
   }

  getCurrentPos(): Observable<Position> {
    return new Observable( observer => {
      setInterval(() => {
        observer.next(this.getRandomPos());
      }, 5000);
    });
  }

  private getRandomPos() {
    const latitude = Math.random() * 0.04 + 62.5;
    const longitude = Math.random() * 0.04  + 7.6;
    const pos = new MyPosition(new MyCoordinates(latitude, longitude), new Date().getTime());
    return pos;
  }

  getTeamPos(): Observable<any> {
    const url = `${this.apiUrl}/orders/open/geojson/${this.userService.teamId}`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }), catchError(err => {
      console.error("Could not get coordinates", err);
      return [];
    }));
  }
  getOrderPos(orderUnid: string): Observable<any> {
    const url = `${this.apiUrl}/orders/${orderUnid}/geojson`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }), catchError(err => {
      console.error("Could not get coordinates", err);
      return [];
    }));
  }
  getEquipmentPos(): Observable<any> {
    const url = `https://api.guardsystems.com/v1.7/assets?_embed=status,groups`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json').set('api_key', '54a08ff935a07833f3f5b2bbc8d18bf6998bf3c4');
    return this.http.get<any[]>(url, {headers: headers}).pipe(map(res => {
      return res;
    }), catchError(err => {
      console.error("Could not get equipments data", err);
      return [];
    }));
  }
  getCulvertsPos(): Observable<any> {
    const url = `${this.apiUrl}/orders/open/culverts/geojson/${this.userService.teamId}`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }), catchError(err => {
      console.error("Could not get coordinates", err);
      return [];
    }));
  }
  getOrderCulvertsPos(orderUnid: string): Observable<any> {
    const url = `${this.apiUrl}/orders/${orderUnid}/geojson/culverts`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }), catchError(err => {
      console.error("Could not get coordinates", err);
      return [];
    }));
  }
}
