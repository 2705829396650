var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { TimsheetProjectService } from '../timsheet-project.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { ReplaySubject, Subject, Observable } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import * as moment from 'moment';
var CreateProjectLineMobileComponent = /** @class */ (function () {
    function CreateProjectLineMobileComponent(timesheetProjectService, fb) {
        this.timesheetProjectService = timesheetProjectService;
        this.fb = fb;
        this.projectList = [];
        this.filteredProjects = new ReplaySubject(0);
        this._onDestroy = new Subject();
        this.projectWeekData = {};
        this.projectCodeList = [];
        this.saveIndex = -1;
        this.dayProjects = [];
        this.selectedDate = moment();
        this.selectedDate.locale('').weekday();
        this.beginWeek = this.selectedDate.clone().startOf('isoWeek').tz('Europe/Oslo');
        this.beginWeek.set('hours', 12);
        this.currentWeekNumber = this.beginWeek.isoWeek();
        this.currentYear = this.beginWeek.year();
        this.currentWeekDay = this.selectedDate.weekday();
    }
    CreateProjectLineMobileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.timesheetProjectForm = this.fb.group({
            projectLine: this.fb.array([this.addProjectDetails()])
        });
        this.getProjectList();
        // subscription for week and year changes 
        this.selectedWeekYear.subscribe(function (data) {
            _this.currentWeekNumber = data.week;
            _this.currentYear = data.year;
            _this.currentWeekDay = data.weekDay;
            _this.getWeekData(_this.currentYear, _this.currentWeekNumber, _this.currentWeekDay, _this.empId);
        });
        // subscription for employee changes
        this.employeeId.subscribe(function (data) {
            _this.empId = data.userId;
            _this.currentUserId = data.currentUserId;
            _this.getWeekData(_this.currentYear, _this.currentWeekNumber, _this.currentWeekDay, _this.empId);
        });
    };
    CreateProjectLineMobileComponent.prototype.getProjectList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectList, salaryCodes;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve) {
                            _this.getProjects().subscribe(function (res) {
                                resolve(res);
                            });
                        })];
                    case 1:
                        projectList = _a.sent();
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this.getSalaryCodes().subscribe(function (res) {
                                    resolve(res);
                                });
                            })];
                    case 2:
                        salaryCodes = _a.sent();
                        this.projectCodeList = [];
                        projectList.forEach(function (proj) {
                            salaryCodes.forEach(function (code) {
                                _this.projectCodeList.push({ project: proj, salaryCode: code });
                            });
                        });
                        this.getWeekData(this.currentYear, this.currentWeekNumber, this.currentWeekDay, this.empId);
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateProjectLineMobileComponent.prototype.ngAfterViewInit = function () {
        this.setInitialValue();
    };
    CreateProjectLineMobileComponent.prototype.setInitialValue = function () {
        var _this = this;
        this.filteredProjects
            .pipe(take(0), takeUntil(this._onDestroy))
            .subscribe(function () {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredBanks are loaded initially
            // and after the mat-option elements are available
            _this.singleSelect.compareWith = function (a, b) { return a && b && a.projName === b.projName; };
        });
    };
    CreateProjectLineMobileComponent.prototype.getProjects = function () {
        var _this = this;
        var projList = new Subject();
        this.timesheetProjectService.getProjects().subscribe(function (res) {
            _this.projectList = res;
            projList.next(res);
            // load the initial project list
            _this.filteredProjects.next(_this.projectList);
            var initialProjectId = "0";
            if (_this.projectList.length > 0)
                initialProjectId = _this.projectList[0]['projNum'];
            for (var i = 0; i < _this.projectRows.length; i++) {
                _this.timesheetProjectForm['controls']['projectLine']['controls'][i]['controls']['projectNumber'].setValue(initialProjectId);
            }
        });
        return projList.asObservable();
    };
    CreateProjectLineMobileComponent.prototype.getSalaryCodes = function () {
        var _this = this;
        var sCode = new Subject();
        this.timesheetProjectService.getSalaryCode().subscribe(function (res) {
            _this.salaryCodes = [];
            res.forEach(function (data) {
                var code = data.split(" ");
                _this.salaryCodes.push(code);
            });
            sCode.next(_this.salaryCodes);
        });
        return sCode.asObservable();
    };
    CreateProjectLineMobileComponent.prototype.getWeekData = function (year, week, weekDay, empId) {
        var _this = this;
        this.dayProjects = [];
        this.saveIndex = -1;
        this.timesheetProjectService.getWeekProjectDetails(year, week, empId).subscribe(function (res) {
            _this.projectRows.clear();
            res.weekProjects.forEach(function (data) {
                var projectHour = data.projectHours.filter(function (phour) {
                    // since in data from timesheetProjectService the monday referes to weekDay of 2 and sunday referes to weekday of 1 so have to add 1 to the weekDay while comparing and minus 6 when comparing for sunday 
                    if (phour.dayOfWeek > 1 && phour.dayOfWeek == weekDay + 1)
                        return phour.hoursWork;
                    else if (phour.dayOfWeek == 1 && phour.dayOfWeek == weekDay - 6)
                        return phour.hoursWork;
                    else
                        return 0;
                });
                if (projectHour.length > 0) {
                    _this.createProjectLine(projectHour[0], data.projectNumber, data.salaryCode);
                    var projname = _this.projectList.filter(function (proj) { return proj.projNum == data.projectNumber; });
                    var salaryCodeName = data.salaryCode == "" ? "Internt" : _this.salaryCodes.filter(function (code) { return code[0] == data.salaryCode; });
                    _this.dayProjects.push({ projectNumber: data.projectNumber,
                        projectName: projname[0].projName,
                        salaryCode: data.salaryCode == "" ? "100" : data.salaryCode,
                        salaryCodeName: salaryCodeName[0][1]
                    });
                }
                console.log(_this.dayProjects);
            });
        });
    };
    CreateProjectLineMobileComponent.prototype.createProjectLine = function (projectHours, projectNumber, salaryCode) {
        this.addProjectLineToFormArray();
        this.saveIndex = this.projectRows.length;
        var index = this.projectRows.length - 1;
        this.timesheetProjectForm['controls']['projectLine']['controls'][index].patchValue({
            projectNumber: projectNumber,
            salaryCode: salaryCode == "" ? "100" : salaryCode,
            hoursWork: projectHours['hoursWork'],
            comment: projectHours['comment'],
            unid: projectHours['unid']
        }, { emitEvent: false });
        this.timesheetProjectForm['controls']['projectLine']['controls'][index].get('projectNumber')['disable']();
        this.timesheetProjectForm['controls']['projectLine']['controls'][index].get('projectNumberFilter')['disable']();
        this.timesheetProjectForm['controls']['projectLine']['controls'][index].get('salaryCode')['disable']();
        var projIndex = this.projectCodeList.findIndex(function (prow) { return prow['project']['projNum'] == projectNumber && prow['salaryCode'][0] == salaryCode; });
        if (projIndex = -1)
            this.projectCodeList.splice(projIndex, 1);
    };
    CreateProjectLineMobileComponent.prototype.onProjectSelect = function (projNo) {
        this.selectableSalaryCode = this.projectCodeList.filter(function (pcode) {
            if (pcode['project']['projNum'] == projNo)
                return pcode;
        });
    };
    CreateProjectLineMobileComponent.prototype.hoursChange = function (e) {
        console.log(e);
    };
    CreateProjectLineMobileComponent.prototype.saveWeekData = function () {
        var _this = this;
        this.projectWeekData['year'] = this.currentYear;
        this.projectWeekData['weekNumber'] = this.currentWeekNumber;
        var weekProjects = [];
        this.timesheetProjectForm.getRawValue().projectLine.forEach(function (data) {
            var pHours = [];
            var hoursWork = data.hoursWork;
            var comment = data.comment;
            var unid = data.unid;
            var dayNumber = _this.selectedDate.toISOString();
            pHours.push({ 'unid': unid, 'dateEnd': dayNumber, 'hoursWork': hoursWork, 'comment': comment });
            weekProjects.push({ 'projectNumber': data.projectNumber, 'salaryCode': data.salaryCode, 'projectHours': pHours });
        });
        this.projectWeekData['weekProjects'] = weekProjects;
        if (this.timesheetProjectForm.valid) {
            this.timesheetProjectService.saveWeekProjectDetails(this.projectWeekData).subscribe(function (res) {
                _this.getWeekData(_this.currentYear, _this.currentWeekNumber, _this.currentWeekDay, _this.empId);
                console.log(res);
            });
        }
    };
    CreateProjectLineMobileComponent.prototype.projectSearch = function (searchKey) {
        this.filterProjects(searchKey);
    };
    CreateProjectLineMobileComponent.prototype.filterProjects = function (searchKey) {
        if (!this.projectList)
            return;
        if (this.projectRows.length == 0)
            return;
        var search = searchKey;
        if (!search && search == "") {
            this.filteredProjects.next(this.projectList);
            return;
        }
        else {
            search = search.toLowerCase();
        }
        this.filteredProjects.next(this.projectList.filter(function (project) { return project['projName'].toLowerCase().indexOf(search) > -1; }));
    };
    CreateProjectLineMobileComponent.prototype.addProjectDetails = function () {
        var projectFormGroup = this.fb.group({});
        projectFormGroup.addControl('projectNumber', this.fb.control([], Validators.required));
        projectFormGroup.addControl('salaryCode', this.fb.control([], Validators.required));
        projectFormGroup.addControl('projectNumberFilter', this.fb.control(''));
        projectFormGroup.addControl('hoursWork', this.fb.control(0));
        projectFormGroup.addControl('unid', this.fb.control(''));
        projectFormGroup.addControl('comment', this.fb.control(''));
        return projectFormGroup;
    };
    CreateProjectLineMobileComponent.prototype.addProjectLineToFormArray = function () {
        this.projectRows.push(this.addProjectDetails());
    };
    Object.defineProperty(CreateProjectLineMobileComponent.prototype, "projectRows", {
        get: function () {
            return this.timesheetProjectForm.get('projectLine');
        },
        enumerable: true,
        configurable: true
    });
    CreateProjectLineMobileComponent.prototype.ngOnDestroy = function () {
        this._onDestroy.next();
        this._onDestroy.complete();
    };
    return CreateProjectLineMobileComponent;
}());
export { CreateProjectLineMobileComponent };
