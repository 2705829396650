/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quality-bars.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./quality-bars.component";
var styles_QualityBarsComponent = [i0.styles];
var RenderType_QualityBarsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QualityBarsComponent, data: {} });
export { RenderType_QualityBarsComponent as RenderType_QualityBarsComponent };
function View_QualityBarsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[2, "fill-bar", null], [2, "empty-bar", null]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = !_v.context.$implicit; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_QualityBarsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "satelite-quality"]], [[2, "satelite-quality-1", null], [2, "satelite-quality-1", null], [2, "satelite-quality-2", null], [2, "satelite-quality-3", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QualityBarsComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.qualityBars; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.satQuality === 0); var currVal_1 = (_co.satQuality === 1); var currVal_2 = (_co.satQuality === 2); var currVal_3 = (_co.satQuality === 3); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_QualityBarsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-quality-bars", [], null, null, null, View_QualityBarsComponent_0, RenderType_QualityBarsComponent)), i1.ɵdid(1, 638976, null, 0, i3.QualityBarsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QualityBarsComponentNgFactory = i1.ɵccf("app-quality-bars", i3.QualityBarsComponent, View_QualityBarsComponent_Host_0, { satQuality: "satQuality" }, {}, []);
export { QualityBarsComponentNgFactory as QualityBarsComponentNgFactory };
