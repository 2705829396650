var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../data.service';
import { Article } from '../../article';
import { MediaService } from '../../shared/media.service';
var AddArticleDialogComponent = /** @class */ (function (_super) {
    __extends(AddArticleDialogComponent, _super);
    function AddArticleDialogComponent(dialogRef, data, dataService, mediaService) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.dataService = dataService;
        _this.mediaService = mediaService;
        _this.articles = [];
        _this.quantityFormControl = new FormControl(1, [Validators.required, Validators.min(0)]);
        _this.typeOfArticle = 'compositeArticle';
        _this.actionTitle = "Legg til";
        _this.dialogFormGroup = new FormGroup({
            'quantityFormControl': _this.quantityFormControl
        });
        if (data && data.typeOfArticle) {
            _this.typeOfArticle = data.typeOfArticle;
        }
        _this.mediaService.getMedia().subscribe(function (media) {
            _this.isMobile = media.isMobileView;
        });
        return _this;
    }
    AddArticleDialogComponent.prototype.ngOnInit = function () {
        if (this.data.editArticle) {
            console.log('editArticle', JSON.stringify(this.editArticle));
            this.actionTitle = "Rediger";
            this.editArticle = new Article();
            this.editArticle.id = this.data.editArticle.artNum;
            this.editArticle.name = this.data.editArticle.artInfo[0];
            this.data.editArticle;
            this.quantityFormControl.setValue(this.data.editArticle.count);
        }
    };
    AddArticleDialogComponent.prototype.confirm = function (event) {
        this.dialogRef.close({
            type: this.data.type,
            article: this.dialogFormGroup.get('articleFormControl').value,
            quantity: this.quantityFormControl.value,
            addType: this.typeOfArticle
        });
    };
    return AddArticleDialogComponent;
}(AbstractDialogComponent));
export { AddArticleDialogComponent };
