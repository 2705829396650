import { SignLog } from "./sign-log";

export class Sign {
  public static CREATED = 1;
  public static PLACED = 2;
  public static REMOVED = 3;
  public static VERIFIED = 4;
  public static REMOVED_FOR_NIGHT = 5;

  id: string;
  uuid: string;
  side: string;
  count: number;
  required: string;
  log: Array<SignLog>;

  get placedPosition(): string {
    let placedPosition: string;
    for (let i = this.log.length; i > 0; i--) {
      if (this.log[i - 1].status === Sign.PLACED) {
        // find the last placed position
        placedPosition = this.log[i - 1].position.shortForm;
        break;
      }
    }
    if (placedPosition === undefined) {
      // no sign has been placed, use created place
      if (this.log) {
        placedPosition = "" + this.log[0].position.meter;
      } else {
        placedPosition = "";
      }
    }
    return placedPosition;
  }

  removeSign() {
    
  }
}
