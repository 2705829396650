import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';
import { ElectronService } from '../../electron.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-download-progress',
  templateUrl: './download-progress.component.html',
  styleUrls: ['./download-progress.component.scss']
})
export class DownloadProgressComponent implements OnInit {
  progress;
  constructor(private _bottomSheetRef: MatBottomSheetRef<DownloadProgressComponent>,
              private electronService: ElectronService,
              private cd: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.electronService.downloadProgress$.subscribe( (data: any) => {
      this.progress = data.progress;
      this.cd.markForCheck();
    });
  }

}
