<mat-table id="parsellTable" *ngIf="parcels && displayedColumns && dataSource" #parcelTable [dataSource]="dataSource"
    multiTemplateDataRows>
    <!-- Checkbox Column -->
  <ng-container matColumnDef="parsellSelect">
    <mat-header-cell *matHeaderCellDef>
    </mat-header-cell>
    <mat-cell *matCellDef="let parcel" [class.best]="parcel?.quality==3" [class.good]="parcel?.quality==2" [class.bad]="parcel?.quality<=1 && (parcel?.state >= STATE_REGISTRATION_COMPLETE && parcel?.state < 10)">
      <mat-checkbox  (click)="$event.stopPropagation()" (change)="onParsellSelect(parcel,$event)" >
      </mat-checkbox>
    </mat-cell>
  </ng-container>
    <!-- Position Column -->
    <ng-container matColumnDef="orderId">
        <mat-header-cell *matHeaderCellDef>Parsell</mat-header-cell>
        <mat-cell *matCellDef="let parcel">
            <span class="mobile-label">Parsell:</span>
            {{parcel?.title}} &nbsp; <mat-icon *ngIf="parcelClostestToCurrentPos === parcel">local_shipping</mat-icon>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="rtType">
        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
        <mat-cell *matCellDef="let parcel">
            <span class="mobile-label">Type:</span>
            <div *ngIf="parcel?.rtTypeUrl != ''; else elseBlock"> 
                <a class="link" href={{parcel?.rtTypeUrl}} target="_blank">{{parcel?.rtType}} </a>
            </div>
            <ng-template #elseBlock>
                {{parcel?.rtType}}
            </ng-template>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sideOfRoad">
        <mat-header-cell *matHeaderCellDef style="justify-content: space-evenly;">Pos</mat-header-cell>
        <mat-cell *matCellDef="let parcel" style="justify-content: space-evenly;" (click)="onParcelClick(parcel)"><span
                class="mobile-label">Pos:</span>{{parcel?.sideOfRoad}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="hpStart">
        <mat-header-cell *matHeaderCellDef>Veg. Start</mat-header-cell>
        <mat-cell *matCellDef="let parcel" (click)="onParcelClick(parcel)"><span class="mobile-label">Start
                m:</span>{{parcel?.startVegKortform}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="startType">
        <mat-header-cell *matHeaderCellDef>Starttype</mat-header-cell>
        <mat-cell *matCellDef="let parcel" (click)="onParcelClick(parcel)">
            <span class="mobile-label">Starttype:</span>
            <div *ngIf="sa && sa[parcel?.startType]">{{sa[parcel?.startType].name}}</div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="hpEnd">
        <mat-header-cell *matHeaderCellDef style="justify-content: space-evenly;">Veg. Slutt</mat-header-cell>
        <mat-cell *matCellDef="let parcel" (click)="onParcelClick(parcel)" style="justify-content: space-evenly;">
            <span class="mobile-label">Slutt:</span>
            {{parcel?.sluttVegKortform}}            
        </mat-cell>
    </ng-container>


    <ng-container matColumnDef="endType">
        <mat-header-cell *matHeaderCellDef>Endetype</mat-header-cell>
        <mat-cell *matCellDef="let parcel" (click)="onParcelClick(parcel)">
            <span class="mobile-label">Endetype:</span>
            <div *ngIf="sa && sa[parcel?.endType]">{{sa[parcel?.endType].name}}</div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lengthFromOrder">
        <mat-header-cell *matHeaderCellDef style="justify-content: space-evenly;">Lengde</mat-header-cell>
        <mat-cell *matCellDef="let parcel" (click)="onParcelClick(parcel)" style="justify-content: space-evenly;">
            <span class="mobile-label">Lengde:</span>
            {{ parcel?.lengthFromOrder }} / {{ parcel?.length }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="riv">
        <mat-header-cell *matHeaderCellDef>Riv m</mat-header-cell>
        <mat-cell *matCellDef="let parcel" (click)="onParcelClick(parcel)">
            <span class="mobile-label">Riv m:</span>
            {{parcel?.rivLengdeFromOrder | number: '1.0-0'}} / {{parcel?.rivLengde | number: '1.0-0'}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="vrak">
        <mat-header-cell *matHeaderCellDef>Vrak / Dep</mat-header-cell>
        <mat-cell *matCellDef="let parcel" (click)="onParcelClick(parcel)">
            <span class="mobile-label">Vrak / Dep:</span>
            {{parcel?.rivVrak | number: '1.0'}} / {{parcel?.rivDep | number: '1.0'}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="culverts">
        <mat-header-cell *matHeaderCellDef  style="justify-content: space-evenly;"></mat-header-cell>
        <mat-cell *matCellDef="let parcel"  style="justify-content: space-evenly;">
            <button mat-icon-button type="button" *ngIf="parcel?.culverts?.length>0" (click)="culvertClick($event, parcel)" (mousedown)="culvertMouseDown($event)"><app-culvert></app-culvert></button>
            <button mat-icon-button type="button" *ngIf="parcel?.rtCategories === 'Brurekkverk'"><app-bridge></app-bridge></button>
            <button mat-icon-button type="button" *ngIf="parcel?.invoiceType === 'orderArticle'"><app-order-article></app-order-article></button>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let parcel" [attr.colspan]="displayedColumns.length"
            [@detailExpand]="(parcel?.compositeArticles?.length > 0 || parcel?.body) ? 'expanded' : 'collapsed'">
            <div class="example-element-detail">
                <div class="example-element-description">
                    <ul *ngIf="parcel?.compositeArticles?.length > 0">
                        <li class="compositeArticles" *ngFor="let sa of parcel?.compositeArticles">
                            <a (click)="showSAInfo(sa,parcel?.rtType)">{{sa.selectedQuantity}} stk. {{sa.name}},
                                {{sa.comment}}</a>
                        </li>
                    </ul>
                    <span *ngIf="parcel?.body" class="example-element-description-attribution"> Kommentar -- {{parcel?.body}} </span>
                    
                </div>
            </div>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="example-element-row element-row"
        [class.completed]="row?.state >= STATE_REGISTRATION_COMPLETE && row?.state < 10"
        [class.utelatt]="isUtelatt(row)" 
        [class.example-expanded-row]="false"></mat-row>
    <mat-row mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row element-row"
        [class.extended]="(row?.compositeArticles?.length>0 || row.body)"></mat-row>
</mat-table>