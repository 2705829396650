import { IsiWeekPerson } from "./isi-week-person";
import { IsiDiet } from "./isi-diet";

export class IsiWeekDay {
    /**
     * @deprecated Use diets instead
     */
    location: string;
    /**
     * @deprecated Use diets instead
     */
    diett: string;
    /**
     * @deprecated Use diets instead
     */
    meals: Array<string>;

    workStart: Date;
    workEnd: Date;
    date: Date;
    teamMembers: Array<IsiWeekPerson>;
    hourFrom: number; // drvie FROM work to aGjerde
    hourTo: number; // drive from aGjerde TO work
    hourPause: number;
    teamId: string;
    week: number;
    unid?: string;
    comment?: string;
    diets?: Array<IsiDiet>;
    hourAccountUnid: string; // used to solve replication conflict issue.
    revLog?: Array<string>;
    hourPauseWork?: number; // the number of paid pause hours
}
