import {GGAsentence} from './ggasentence';

export class GpsData {
  age?: Date;
  alt: number;
  geoidal: number;
  hdop: number;
  lat: number; // decimalDegrees
  lon: number; // decimalDegrees
  quality: string; // e.g. 'rtk-float' or 'rtk'
  raw: string; /** {@see GGAsentence} */
  satelites: number;
  stationID?: string;
  time: Date;
  type: string;
  valid: boolean;
  constructor(latitude: number, longitude: number) {
    this.lat = latitude;
    this.lon = longitude;
  }
}
