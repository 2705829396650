<mat-table *ngIf="dataSource && dataSource.data.length>0" #compositeArticleTable [dataSource]="dataSource">

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Strukturartikler</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.name}} (Antall: {{element.selectedQuantity}})</mat-cell>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column -->
  <ng-container matColumnDef="expandedDetail">
    <mat-cell *matCellDef="let detail" class="sub-cell">
       <div *ngIf="!detail.element.subItems">Vennligst vent mens vi henter tilhørende artikler</div>
       <app-articles id="subItemArticles" *ngIf="detail.element.subItems" [articles]="detail.element.subItems" [editable]="false"></app-articles>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
           matRipple
           class="menu-item"
           [class.expanded]="expandedElement == row"
           (click)="selectMenuItem(row)">
  </mat-row>
  <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow; let i = index"
           [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'"
           class="expanded-row">
  </mat-row>
</mat-table>
