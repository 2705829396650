<div class="parcels">
  <mat-card class="mat-typography">
    <mat-card-header id="parcelHeader">
      <mat-card-title>Velg parsell</mat-card-title>
      <button id="newParcelBtn" mat-raised-button color="primary" (click)="newParcel()">Ny tom parsell</button>
    </mat-card-header>
    <mat-card-content>
          <app-parcels-table #parcelsTable [orderUnid]="orderUnid"></app-parcels-table>
    </mat-card-content>
    <mat-card-actions>
        
      </mat-card-actions>
  </mat-card>
</div>
<app-road-information *ngIf="isElectron"></app-road-information>
