import { Injectable } from '@angular/core';
import { Observable, Subscription, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  titleObservable: Observable<any>;
  titleSubscriber: Subscriber<any>;
  constructor() {
    this.titleObservable = new Observable(subscription => {
      this.titleSubscriber = subscription;
      // subscription.next('1. tittel');
    });
  }

  addNavItem(item: any) {
   // const uniqueId = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
   const navItem = Object.assign({action: 'add'}, item);
    if (this.titleSubscriber) {
      this.titleSubscriber.next(navItem);
    }
  }

  resetNavItems() {
    this.titleSubscriber.next({action: 'removeAll'})
  }

  removeNavItem(item) {
    const navItem = Object.assign({action: 'remove'}, item);
    this.titleSubscriber.next(navItem);
  }

  getPageTitle() {
    return this.titleObservable;
  }
}
