<form class="dialog" #countDialogForm="ngForm" [formGroup]="dialogFormGroup" (ngSubmit)="confirm($event)">
  <h2>Denne funksjonen er ikke implementert enda</h2>
  <button class="btn-close" type="button" color="accent" mat-icon-button (click)="closeDialog()">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
  &nbsp;
  <button type="submit" class="btn-save success" color="primary" mat-raised-button>
    Lukk
  </button>
  <div class="placeholder"></div>
</form>
