import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../data.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { Order } from '../../../order';
import { NotesUser } from '../../../notes-user';
import { IsiWeekPerson } from '../../../isi-week-person';
import { Project } from '../../../project';
import { IsiWeekHour } from '../../../isi-week-hour';
import { ArtNumber } from '../../../art-number.enum';
import { IsiWeekDay } from '../../../isi-week-day';

@Component({
  selector: 'app-order-line-dialog',
  templateUrl: './order-line-dialog.component.html',
  styleUrls: ['./order-line-dialog.component.scss']
})
export class OrderLineDialogComponent implements OnInit, OnDestroy {
  readonly ARTNUMBER_ORDER = ArtNumber.ORDER;
  readonly ARTNUMBER_PROJECT = ArtNumber.PROJECT;
  formGroup: FormGroup;
  orders: Array<Order> = [];
  projects: Array<Project> = [];
  members: Array<IsiWeekPerson> = [];
  now = new Date();
  artNumbers: Array<{value: ArtNumber, label: string}> = [
    {value: ArtNumber.ORDER, label: 'Akkordønn'}, {value: ArtNumber.PROJECT, label: 'Timearbeid'}
  ];
  projectCtrl: FormControl;
  orderCtrl: FormControl;
  constructor(@Inject(MAT_DIALOG_DATA) public data: OrderLineDialogData, public dialogRef: MatDialogRef<OrderLineDialogComponent>,
  private dataService: DataService) {
    this.projectCtrl = new FormControl('', [Validators.required]);
    this.orderCtrl = new FormControl('', [Validators.required]);
    this.formGroup = new FormGroup({
      'members': new FormControl('', [Validators.required]),
      'artNumber': new FormControl('', [Validators.required]),
      'speedHours' : new FormControl(0, [Validators.min(0)]),
      'comment': new FormControl('', [])
    });
    this.formGroup.get('comment').disable();
    // adds only one of the controlles for validation purposes.
    if (this.data && this.data.artNumber.value === ArtNumber.PROJECT) {
      this.formGroup.addControl('project', this.projectCtrl);
    } else if (this.data && this.data.artNumber.value === ArtNumber.ORDER) {
      this.formGroup.addControl('order', this.orderCtrl);
    }
    if (!data.selectedWeekDay) {
      this.speedHours.disable();
    }
  }
  speedHourChange(event: any) {
    if (this.formGroup.get('speedHours').value > 0) {
      this.formGroup.get('comment').enable();
    } else {
      this.formGroup.get('comment').disable();
    }
    if (this.formGroup.get('project') && this.formGroup.get('speedHours').value > 0) {
      this.formGroup.get('comment').setValidators([Validators.required]);
      this.formGroup.get('comment').markAsTouched();
    } else {
      this.formGroup.get('comment').setValidators([]);
    }
    this.formGroup.get('comment').updateValueAndValidity();
  }
  ngOnInit() {
    this.setDefaultArtNumber();
    if (this.data && this.data.artNumber.value === ArtNumber.ORDER) {
      this.dataService.getOrders().pipe(take(1)).subscribe( orders => {
        this.orders = orders;
        this.setDefaultOrder();
      });
    } else if (this.data && this.data.artNumber.value === ArtNumber.PROJECT) {
      this.dataService.getProjects().pipe(take(1)).subscribe( projs => {
        this.projects = projs;
        this.setDefaultProject();
      });
    }
    this.dataService.getTeamMembers().pipe(take(1)).subscribe( members => {
      this.members = members;
    });
  }
  ngOnDestroy() {
  }
  projectChanged(event: any) {
    this.getExistingWeekHour(this.projectCtrl.value);
  }
  orderChanged(event: any) {
    this.getExistingWeekHour(this.orderCtrl.value);
  }
  private getExistingWeekHour(orderOrProject: Order|Project) {
  }
  save() {
    const selectedMembers = this.formGroup.get("members").value;
    const selectedartNumber = this.formGroup.get("artNumber").value;
    const speedHours = this.formGroup.get('speedHours').value;
    const comment = this.formGroup.get('comment').value;
    const res: OrderLineDialogData = {
      members: selectedMembers,
      artNumber: selectedartNumber,
      isNew: this.data.isNew,
      speedHours: speedHours,
      selectedWeekDay: this.data.selectedWeekDay,
      comment: comment
    };
    if (this.data && this.data.artNumber.value === ArtNumber.ORDER) {
      res.order = this.formGroup.get("order").value;
    } else if (this.data && this.data.artNumber.value === ArtNumber.PROJECT) {
      res.project = this.formGroup.get("project").value;
    }
    this.dialogRef.close(res);
  }
  private setDefaultArtNumber() {
    if (this.data && this.data.artNumber) {
      const existingArtNumbers = this.artNumbers.filter( a => {
        return a.value === this.data.artNumber.value;
      });
      if (existingArtNumbers && existingArtNumbers.length > 0) {
        this.artNumberFormCtrl.setValue(existingArtNumbers[0]);
      }
    }
  }
  private setDefaultProject() {
    let foundProject = false;
    if (this.data && this.data.project) {
      for (const project of this.projects) {
        if (project.projNum === this.data.project.projNum) {
          foundProject = true;
          this.projectCtrl.setValue(project);
        }
      }
    }
    if (!foundProject) {
      this.projects.push(this.data.project);
      this.projectCtrl.setValue(this.data.project);
    }
  }
  private setDefaultOrder() {
    let foundOrder = false;
    if (this.data && this.data.order) {
      for (const order of this.orders) {
        if (order.orderNum === this.data.order.orderNum) {
          foundOrder = true;
          this.orderFormCtrl.setValue(order);
        }
      }
    }
    if (!foundOrder) {
      this.orders.push(this.data.order);
      this.orderFormCtrl.setValue(this.data.order);
    }
  }
  get membersFormCtrl(): FormControl {
    return <FormControl> this.formGroup.get("members");
  }
  get orderFormCtrl(): FormControl {
    return <FormControl> this.formGroup.get("order");
  }
  get artNumberFormCtrl(): FormControl {
    return <FormControl> this.formGroup.get("artNumber");
  }
  get speedHours(): FormControl {
    return <FormControl>this.formGroup.get("speedHours");
  }
  get comment(): FormControl {
    return <FormControl>this.formGroup.get("comment");
  }
}

export class OrderLineDialogData {
  order?: Order;
  project?: Project;
  isNew: boolean;
  members: Array<IsiWeekPerson>;
  artNumber: {value: string, label: string};
  speedHours?: number; // number of hours to be registered today
  comment?: string; // the comment on the speedHours
  selectedWeekDay: IsiWeekDay;
  constructor() {
  }
}
