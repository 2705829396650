<h1 mat-dialog-title>
  <span *ngIf="data?.artNumber?.value===ARTNUMBER_ORDER">Ordrelinje</span>
  <span *ngIf="data?.artNumber?.value===ARTNUMBER_PROJECT">Prosjektlinje</span>
</h1>
<div mat-dialog-content>
  <form [formGroup]="formGroup">

      <mat-form-field *ngIf="data?.artNumber?.value===ARTNUMBER_ORDER">
        <mat-select placeholder="Velg ordre" formControlName="order" (selectionChange)="orderChanged($event)">
          <mat-option *ngFor="let order of orders" [value]="order">
            {{order?.snum}} {{order?.subject}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="data?.artNumber?.value===ARTNUMBER_PROJECT">
          <mat-select placeholder="Velg prosjekt" formControlName="project" (selectionChange)="projectChanged($event)">
            <mat-option *ngFor="let project of projects" [value]="project">
              {{project?.projNum +' – '+ project?.projName}}
            </mat-option>
          </mat-select>
      </mat-form-field>
      <app-employee-picker [membersFormCtrl]="membersFormCtrl" [multiple]="true" [defaultMembers]="data?.members" [selectAllTeamMembers]="data?.members?false:true"></app-employee-picker>
      <mat-form-field>
        <mat-select placeholder="Velg lønnsart" formControlName="artNumber" [disabled]="true">
          <mat-option *ngFor="let artNumber of artNumbers" [value]="artNumber">
              {{artNumber?.value}} – {{artNumber?.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="data.isNew" class="speed-hours">
        <mat-label>Hurtigregistrering for timer i dag ({{now | date: 'EEEE dd.MM.yyyy'}})</mat-label>
        <input matInput appCustomIncDecBtns [useFiniteNumbers]="true" formControlName="speedHours" type="number" placeholder="Antall timer" (change)="speedHourChange($event)">
        <mat-error *ngIf="speedHours.getError('min')">Kan ikke lagre negative timer</mat-error>
        <mat-hint *ngIf="!data.selectedWeekDay" class="error-hint-text">Kan ikke hurtiregistrere timer pga manglende dagføring i dag</mat-hint>
      </mat-form-field>
      <mat-form-field *ngIf="data.isNew">
        <mat-label>Kommentar til hurtigregistrering</mat-label>
        <input matInput type="text" placeholder="Skriv en kommentar" formControlName="comment">
        <mat-error *ngIf="comment.getError('required')">Man må skrive kommentar dersom man registrer timer på prosjekt</mat-error>
        <mat-hint>Kommentar blir kun lagret dersom man hurtigregistrerer timer</mat-hint>
      </mat-form-field>
  </form>
</div>
<div>&nbsp;</div>
<div class="mat-dialog-actions">
  <button mat-raised-button (click)="save()" [disabled]="!formGroup.valid || !formGroup.dirty" color="primary">Lagre</button>
  <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial color="warning">Avbryt</button>
</div>