<mat-card>
    <mat-card-title>
      {{data?.name}} (Antall: {{data?.quantity}})
    </mat-card-title>
    <mat-card-subtitle>
      Type: {{data?.rtType}}
    </mat-card-subtitle>
    <mat-card-content>
      <ul>
        <li *ngFor="let art of articles">
          {{art.id}} {{art.name}}, (Antall: {{art.quantityTotal}})
        </li>
      </ul>
    </mat-card-content>
  </mat-card>