import { Component, OnDestroy, OnInit, HostBinding, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '../../data.service';
import { Order } from '../../order';
import { NaviService } from '../../navi.service';
import { AllotmentHeader } from '../../allotment-header';
import { NotImplementedDialogComponent } from '../not-implemented-dialog/not-implemented-dialog.component';
import { MatDialog, MatSnackBar, MatBottomSheet } from '@angular/material';
import { Subscription, BehaviorSubject, from } from 'rxjs';
import { Allotment } from '../../allotment';
import { HeaderService } from '../header/header.service';
import { SjaDataSource } from '../../sja/sja-data-source';
import { AddArticleDialogComponent } from '../add-article-dialog/add-article-dialog.component';
import { OrderCommentDialogComponent } from './order-comment-dialog/order-comment-dialog.component';
import { OrderComment } from '../../order-comment';
import { ElectronService } from '../../electron.service';
import { TimeSheetService } from '../../time-sheet.service';
import { take } from 'rxjs/operators';
import { IsiWeekHour } from '../../isi-week-hour';
import { ContactSheetComponent } from '../contact-sheet/contact-sheet.component';
import { OrderApprovalComponent } from './order-approval/order-approval.component';
import { ParcelsTableComponent } from '../parcels-table/parcels-table.component';
import { GpsService } from '../../gps/gps.service';
import { MediaService } from '../../shared/media.service';
import { CameraDialogComponent } from '../camera-dialog/camera-dialog.component';
import { OrderApprovalArticlesComponent } from './order-approval-articles/order-approval-articles.component';
import { AppConfig } from '../../../environments/environment';
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit, OnDestroy {
  @HostBinding('class.app-order-component') hostClass = true;
  @ViewChild('parcelsTable', { static: false }) parcelsTable: ParcelsTableComponent;
  order: Order;
  orderComments: Array<OrderComment>;
  orderUnid: string;
  sa: any;
  displayedColumns: Array<string> = [
    'orderId', 'rtType', 'sideOfRoad', 'hpStart', 'startType', 'hpEnd',
    'endType', 'lengthFromOrder', 'riv', 'vrak', 'body'
  ];
  parcels: Array<AllotmentHeader>;
  subject: BehaviorSubject<any>;
  dataSource: SjaDataSource;
  attachments: Array<any> = [];
  orderlines: Array<any> = [];
  orderDetailsSubscription: Subscription;
  STATE_REGISTRATION_COMPLETE = Allotment.STATE_REGISTRATION_COMPLETE;
  attachmentColumns: string[] = ['filename', 'fileSize', 'modified'];
  orderlineColumns: string[] = ['artName', 'offerCount', 'regCount'];
  weekHours: Array<IsiWeekHour>;
  totalHoursOnOrder: IsiWeekHour = new IsiWeekHour();
  isMobileView: boolean;
  selectedParsell: Array<any> = [];
  images: Array<any> = [];
  public isElectron;
  step = 0;
  constructor(private router: Router, private dataService: DataService, private navi: NaviService,
    private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer, private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private headerService: HeaderService,
    private electronService: ElectronService,
    private timeSheetService: TimeSheetService,
    private _bottomSheet: MatBottomSheet,
    private gpsService: GpsService,
    private mediaService: MediaService) {
    this.orderUnid = this.activatedRoute.snapshot.params['orderUnid'];
    this.mediaService.getMedia().subscribe(media => {
      this.isMobileView = media.isMobileView;
    });
    this.isElectron = this.electronService.isElectron();
  }

  ngOnInit() {
    this.getOrderDetails(this.orderUnid);
    this.getSaJson();

    this.subject = new BehaviorSubject(this.parcels);
    this.dataSource = new SjaDataSource(this.subject.asObservable());
    this.getOrderComments(this.orderUnid);
    this.getOrderImages(this.orderUnid);
    this.dataService.getOrderLines(this.orderUnid).subscribe(data => {
      this.orderlines = data;
    });

  }
  ngOnDestroy() {
    if (this.orderDetailsSubscription) {
      this.orderDetailsSubscription.unsubscribe();
    }
  }

  onAllotmentsLoaded(allotments: Array<AllotmentHeader>) {
    this.parcels = allotments;
  }

  getOrderComments(orderUnid) {
    this.dataService.getOrderComments(orderUnid).subscribe((comments) => {
      this.orderComments = comments;
    });
  }
  openAttachment(attachment) {
    console.log("open attachment, ", attachment);
    if (this.electronService.isElectron()) {
      this.electronService.open(attachment.url);
    }
  }

  openImage(img) {
    if (this.electronService.isElectron()) {
      this.electronService.open(`http://localhost:8080/attachment/${img.replicaId}/${img.unid}?file=${img.filename}`);
    }
  }

  getImgUrl(img) {
    if (this.electronService.isElectron()) {
      return AppConfig.apiUrl + `/attachment/${img.replicaId}/${img.unid}?file=${img.filename}`;
    } else {
      return `https://files.agjerde.no/v3/${img.replicaId}/${img.unid}/${img.filename}`;
    }
    
    //return `http://localhost:8080/attachment/${img.replicaId}/${img.unid}?file=${img.filename}`;
  }

  openArticleDialog() {
    const dialogRef = this.dialog.open(OrderApprovalArticlesComponent, {
      height: '80%',
      width: '90%',
      data: { type: OrderApprovalArticlesComponent, OrderUnid: this.orderUnid, OrderNumber: this.order.snum, OrderStatus: this.order.status, readonly: true }
    });
    dialogRef.beforeClosed().subscribe(result => { });
    dialogRef.afterClosed().subscribe(result => { });
  }

  getFileIcon(attachment) {
    const fileName: string = attachment.filename.toLowerCase();
    if (fileName.endsWith('png') || fileName.endsWith('jpg') || fileName.endsWith('jpeg') || fileName.endsWith('gif')) {
      return 'image';
    } else if (fileName.endsWith('pdf')) {
      return 'note';
    } else {
      return 'insert_drive_file';
    }
  }

  getCableStatusIcon(status) {
    if (status === "1") {
      return "clear";
    } else {
      return "check";
    }
  }
  getOrderDetails(orderUnid: string) {
    this.orderDetailsSubscription = this.dataService.getOrder(orderUnid).subscribe((order) => {
      this.order = order;
      this.getWeekHours(this.order.orderNum);
      
      this.headerService.addNavItem({
        route: `/order/${this.order.unid}`,
        label: `Ordre: ${this.order.snum}`,
        queryParams: ''
      });
    });
  };

  goToTimeSheetRegistration(order: Order) {
    this.router.navigateByUrl('/timesheet');
  }
  navigateTo(route: string) {
    const urlTree = this.router.createUrlTree([route]);
    this.router.navigateByUrl(urlTree);
  }

  getOrderImages(orderUnid: string) {    
    this.dataService.getOrderImages(orderUnid).pipe(take(1)).subscribe((images) => {
      this.images = images;
    });
  }

  editComment(comment: OrderComment) {
    const dialogRef = this.dialog.open(OrderCommentDialogComponent, {
      height: '80%',
      width: '90%',
      data: { orderUnid: this.orderUnid, comment: comment }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('update comment list')
      this.getOrderComments(this.orderUnid);
    })
  }

  addComment() {
    const dialogRef = this.dialog.open(OrderCommentDialogComponent, {
      height: '80%',
      width: '90%',
      data: { orderUnid: this.orderUnid }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('update comment list')
      this.getOrderComments(this.orderUnid);
    })
  }

  addArticleClick(article?: any) {
    const dialogRef = this.dialog.open(AddArticleDialogComponent, {
      height: '80%',
      width: '90%',
      data: { type: AddArticleDialogComponent, typeOfArticle: 'article', editArticle: article }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancel') {
        if (result.article && result.quantity >= 0) {
          const ol = {
            artNum: result.article.id,
            count: result.quantity,
            parentId: this.orderUnid
          }
          if (article && article.unid) {
            ol['unid'] = article.unid;
          }
          this.dataService.saveOrderLine(ol).subscribe(data => {
            console.log("orderline saved", data);
            this.dataService.getOrderLines(this.orderUnid).subscribe(orderlines => {
              this.orderlines = orderlines;
            });
          });
        }
      }
    });
  }

  addImage() {
    //open dialog with image.
    const dialogRef = this.dialog.open(CameraDialogComponent, {
      height: '90%',
      width: '90%',
      data: { order: this.order }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getOrderImages(this.orderUnid);
        console.log('dialog closed', result)
      }
    });
  }

  deleteImage(image) {
    this.dataService.deleteOrderImage(image.unid).subscribe(data => {
      console.log(data)
      this.getOrderImages(this.orderUnid);
    }
    );
  }

  openContact() {
    // open contact sheet..

    this._bottomSheet.open(ContactSheetComponent, {
      data: {
        compId: this.order.compId,
        contactName: this.order.custContact
      }
    });
  }
  gotoOrderMap() {
    const urlTree = this.router.createUrlTree(['order/' + this.order.unid + '/teammap']);
    this.router.navigateByUrl(urlTree);
  }
  /**
   * Keep the application from sending the event to its parent.
   * Preventing the app from opening the measure component.
   * Open new window with the computers built in navigation app.
   * @param event
   */
  onNaviClick(event, latLon, destinationName) {
    if (event) {
      event.stopPropagation();
    }
  }
  openNavigationApp(latLon, destinationName) {
    if (!latLon) {
      return "";
    } else {
      return this.sanitizer.bypassSecurityTrustUrl(this.navi.navigateTo(latLon, destinationName));
    }
  }
  onParcelClick(parcel: AllotmentHeader) {
    const parcelId = parcel.unid || '';
    const urlTree = this.router.createUrlTree(['measure/', this.order.unid, parcelId]);
    this.router.navigateByUrl(urlTree);
  }

  private getSaJson() {
    this.dataService.getStructureArticles().subscribe(data => {
      this.sa = data;
    });
  }
  private getWeekHours(orderNum: string) {
    this.timeSheetService.getWeekHoursByOrderId(orderNum).pipe(take(1)).subscribe(res => {
      this.weekHours = res;
      this.weekHours.forEach(weekHour => {
        this.totalHoursOnOrder.addWeekHour(weekHour, weekHour.teamMembers.length);
      })
    });
  }
  newParcel() {
    this.parcelsTable.newParcel();
  }
  onParsellSelected(parsells) {
    this.selectedParsell = parsells;
    console.log(this.selectedParsell);
  }
  unntaParcel() {
    this.selectedParsell.forEach(parsell => {
      this.dataService.getParcelExcluded(parsell.orderId, parsell.docId).subscribe(data => {
        this.parcelsTable.getParcels(this.orderUnid);
      })
    })
  }
  getLengthFromOrder(parcel: AllotmentHeader): number {
    let res = 0;
    if (parcel && parcel.startPos && parcel.endPos) {
      try {
        res = Math.abs(parseInt(parcel.endPos, 10) - parseInt(parcel.startPos, 10));
      } catch (err) {

      }
    }
    return res;
  }
}
