import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState, MatSnackBar } from '@angular/material';
import { SjaService } from '../../../sja/sja.service';

@Component({
  selector: 'app-mobile-dialog',
  templateUrl: './mobile-dialog.component.html',
  styleUrls: ['./mobile-dialog.component.scss']
})
export class MobileDialogComponent implements OnInit {
  dialogFormGroup: FormGroup;
  public title: string;
  public day;
  public archived: boolean;

  constructor(public dialogRef: MatDialogRef<MobileDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private sjaService: SjaService, private snackbar: MatSnackBar) {
      this.dialogFormGroup = new FormGroup ({
        date: new FormControl(),
        comment: new FormControl()
      });
      this.archived = data.archived;
  }

  ngOnInit() {
    this.title = 'Dagføring'
    this.dialogFormGroup.get('date').setValue(this.data.date);
    this.dialogFormGroup.get('comment').setValue(this.data.comment);
  }

  confirm(event) {
    const comment = this.dialogFormGroup.get('comment').value;
    const dayDate = this.dialogFormGroup.get('date').value;
    this.dialogRef.close({dayComment: comment, dayDate: dayDate});
  }

  closeDialog() {
    this.dialogRef.close();
  }
  onRiskFactorAdded(day: any) {
      console.log('mobile compoent on risk factor added..', this.data);
      this.save(this.data, undefined, undefined);
  }

  save(day: any, dayComment: NgModel, dayDate: NgModel) {
    this.sjaService.saveDayComment(this.data.orderUnid, day).subscribe(response => {
      this.snackbar.open("Risikomoment er lagret.", '', { duration: 5000 });
      day.unid = response.unid;
      if (dayComment && dayDate) {
        dayComment.reset(day.comment);
        dayDate.reset(day.date);
      }
    });
  }

}
