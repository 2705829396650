import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TimsheetProjectService } from '../timsheet-project.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { AbstractDialogComponent } from '../../components/abstract-dialog/abstract-dialog.component';
import { MatDialogRef, MatSelect, MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { take, takeUntil, map } from 'rxjs/operators';
@Component({
  selector: 'app-create-project-line',
  templateUrl: './create-project-line.component.html',
  styleUrls: ['./create-project-line.component.scss']
})
export class CreateProjectLineComponent implements OnInit {
  @Input() selectedWeekYear: Observable<any>;
  @Input() selectedDate: moment.Moment;
  @Input() employeeId: Observable<any>;
  @Input() fullWeek: Observable<any>;
  beginWeek: moment.Moment;
  currentWeekNumber: number;
  currentYear: number;
  projectLineFormGroup: FormGroup;
  projects: any[];
  salaryCodes: string[];
  projectCodeList: Array<object> = [];
  rowVisible: Array<boolean> = [];
  public timesheetProjectForm: FormGroup;
  projectWeekData:object = {};
  formFields = [
    {
      name: 'Project Select',
      formControl: 'projectNumber',
      type: 'select'
    },
    {
      name: 'SalaryCode Select',
      formControl: 'salaryCode',
      type: 'select'
    },
    {
      name: 'Day 1',
      formControl: 'day1',
      type: 'text',
      day: 'mandag',
      visible: false,
      dayVisible:true
    },
    {
      name: 'Day 2',
      formControl: 'day2',
      type: 'text',
      day: 'tirsdag',
      visible: false,
      dayVisible:true
    },
    {
      name: 'Day 3',
      formControl: 'day3',
      type: 'text',
      day: 'onsdag',
      visible: false,
      dayVisible:true
    },
    {
      name: 'Day 4',
      formControl: 'day4',
      type: 'text',
      day: 'torsdag',
      visible: false,
      dayVisible:true
    },
    {
      name: 'Day 5',
      formControl: 'day5',
      type: 'text',
      day: 'fredag',
      visible: false,
      dayVisible:false
    },
    {
      name: 'Day 6',
      formControl: 'day6',
      type: 'text',
      day: 'lørdag',
      visible: false,
      dayVisible:false
    },
    {
      name: 'Day 7',
      formControl: 'day7',
      type: 'text',
      day: 'søndag',
      visible: false,
      dayVisible: false
    },
  ]
  selectedProject: Array<any> = [];
  projectList: any[];
  empId: string;
  filteredProjects: ReplaySubject<Array<any>> = new ReplaySubject<Array<any>>(0);
  protected _onDestroy = new Subject<void>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  showFullWeek: boolean;
  selectableSalaryCode: object[];
  saveIndex: Number = -1;
  weekProjects: Array<object> = [];
  currentUserId: string;
  isAdmin: boolean;

  constructor(
    private timesheetProjectService: TimsheetProjectService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,) {
    this.selectedDate = moment();
    this.selectedDate.locale('').weekday();
    this.beginWeek = this.selectedDate.clone().startOf('isoWeek').tz('Europe/Oslo');
    this.beginWeek.set('hours', 12);
    this.currentWeekNumber = this.beginWeek.isoWeek();
    this.currentYear = this.beginWeek.year();
  }

  ngOnInit() {
  /*
    this.getProjects();
    this.getSalaryCodes(); */
    this.getProjectList();

    this.timesheetProjectForm = this.fb.group({
      projectLine: this.fb.array([this.addProjectLineDetails()])
    });

    // subscription for week and year changes 
    this.selectedWeekYear.subscribe(data => {
      this.currentWeekNumber = data.week;
      this.currentYear = data.year;
      this.getProjectList();
      this.getWeekData(this.currentYear,this.currentWeekNumber,this.empId);
    });

    // subscription for employee changes
    this.employeeId.subscribe(data => {
      this.empId = data.userId;
      this.currentUserId  = data.currentUserId;
      this.isAdmin = data.isAdmin;
      this.getProjectList();
      this.getWeekData(this.currentYear,this.currentWeekNumber,this.empId);
    });

    // subscription for showFullWeek
    this.fullWeek.subscribe(data => {
      this.showFullWeek = data.data;
      if(this.showFullWeek)[6,7,8].map(a => this.formFields[a].dayVisible = true)
      else  [6,7,8].map(a => this.formFields[a].dayVisible = false)
    })
   
  }
  filterProjects(i: any) {
    if(!this.projects) return
    if (this.projectRows.length == 0 ) return 
    var search = this.timesheetProjectForm.value.projectLine[i].projectNumberfilter;
    if(!search && search == "") {
      this.filteredProjects.next(this.projects);
      return;
    }
    else {
      search = search.toLowerCase();
    } 
    this.filteredProjects.next(
      this.projects.filter(project => project['projName'].toLowerCase().indexOf(search) > -1)
    );
  }
  ngAfterViewInit() {
    this.setInitialValue();
  }
  projectSearch(index) {
    this.filterProjects(index);
  }

   /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredProjects
      .pipe(take(0), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a, b) => a && b && a.projName === b.projName;
      });
  }
  async getProjectList() {
    var projectList = await<any> new Promise(resolve => {
      this.getProjects().subscribe(res => {
        resolve(res);
      })
    });
    var salaryCodes = <any>await new Promise(resolve => {
      this.getSalaryCodes().subscribe(res => {
        resolve(res);
      })
    });
    this.projectCodeList = [];
    projectList.forEach(proj => {   
      salaryCodes.forEach(code => {
        this.projectCodeList.push({ project: proj, salaryCode: code });
      });
    })
    this.getWeekData(this.currentYear, this.currentWeekNumber,this.empId);
    
  }
  getWeekData(year, week, empId) {
    return new Promise(resolve => {
      this.timesheetProjectService.getWeekProjectDetails(year, week,empId).subscribe(res => {
        setTimeout(() => {
          resolve(res);
        }, 2000);
        this.createProjectLines(res);
      });
    })
    
  }
  createProjectLines(weekProjectsData: any) {
    this.weekProjects = [];
    this.projectList = this.projectCodeList;
    this.saveIndex = weekProjectsData['weekProjects'].length;
    
    if(this.saveIndex > 0) {
      weekProjectsData['weekProjects'].forEach(project => {
        var pName = this.projects.filter(proj => proj.projNum == project.projectNumber);
        var sName = project.salaryCode == ""? [] : this.salaryCodes.filter(code => code[0] == project.salaryCode);
        this.weekProjects.push({
          projectNumber: pName[0].projNum, 
          projectName: pName[0].projName, 
          salaryCode: project.salaryCode == "" ? "100" : sName[0][0],
          salaryCodeName : project.salaryCode == "" ? "Internt" : sName[0][1]
        })
      });
    }
    this.projectRows.clear();
   
    weekProjectsData.weekProjects.forEach((data, i) => {
      this.addProjectLineToFormArray();
      var dayInfo = [];
     data.projectHours.forEach (phours => {
       var dayOfWeek = phours.dayOfWeek;
       switch (dayOfWeek) {
        case 1: 
        this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue(
          {
            day7: phours.hoursWork,
            day7comment: phours.comment,
            day7salary: phours.generateSalary== "" || phours.generateSalary == "0" ? false : true ,
            day7unid: phours.unid,
          },{emitEvent: false})
          break;
        case 2: 
        this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue(
          {
            day1: phours.hoursWork,
            day1comment: phours.comment,
            day1salary: phours.generateSalary== "" || phours.generateSalary == "0" ? false : true,
            day1unid: phours.unid,
          },{emitEvent: false})
          break;
        case 3: 
          this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue(
            {
              day2: phours.hoursWork,
              day2comment: phours.comment,
              day2salary:phours.generateSalary== "" || phours.generateSalary == "0" ? false : true,
              day2unid: phours.unid,
            },{emitEvent: false})
          break;
        case 4: 
        this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue(
          {
            day3: phours.hoursWork,
            day3comment: phours.comment,
            day3salary: phours.generateSalary== "" || phours.generateSalary == "0" ? false : true,
            day3unid: phours.unid,
          },{emitEvent: false})
        break;
        case 5: 
        this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue(
          {
            day4: phours.hoursWork,
            day4comment: phours.comment,
            day4salary: phours.generateSalary== "" || phours.generateSalary == "0" ? false : true,
            day4unid: phours.unid,
          },{emitEvent: false})
        break;
        case 6: 
        this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue(
          {
            day5: phours.hoursWork,
            day5comment: phours.comment,
            day5salary: phours.generateSalary== "" || phours.generateSalary == "0" ? false : true,
            day5unid: phours.unid,
          },{emitEvent: false})  
        break;
        case 7: 
        this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue(
          {
            day6: phours.hoursWork,
            day6comment: phours.comment,
            day6salary: phours.generateSalary== "" || phours.generateSalary == "0" ? false : true,
            day6unid: phours.unid,
          },{emitEvent: false})
        break;
       
       }
     })
      this.timesheetProjectForm['controls']['projectLine']['controls'][i].patchValue(
        {
          projectNumber: data.projectNumber,
          salaryCode: data.salaryCode == "" ? "100": data.salaryCode,
        },{emitEvent: false}
      );
      this.timesheetProjectForm['controls']['projectLine']['controls'][i].get('projectNumber')['disable']();
      this.timesheetProjectForm['controls']['projectLine']['controls'][i].get('projectNumberfilter')['disable']();
      this.timesheetProjectForm['controls']['projectLine']['controls'][i].get('salaryCode')['disable']();
      var index = this.projectCodeList.findIndex(prow => prow['project']['projNum'] == data.projectNumber && prow['salaryCode'][0] == data.salaryCode);
      this.projectCodeList.splice(index,1);
    });
  }
  getProjects() {
    var projList = new Subject<any>();
    this.timesheetProjectService.getProjects().subscribe(res => {
      this.projects = res;
      projList.next(res);
       // load the initial project list
       this.filteredProjects.next(this.projects);
    
       let initialProjectId = "0";
       if(this.projects.length > 0) initialProjectId = this.projects[0]['projNum'];
       for (var i=0 ; i < this.projectRows.length; i++) {
         this.timesheetProjectForm['controls']['projectLine']['controls'][i]['controls']['projectNumber'].setValue(initialProjectId);
       }
    });
    return projList.asObservable();
  }
  getSalaryCodes() {
    var sCode = new Subject<any>();
    this.timesheetProjectService.getSalaryCode().subscribe(res => {
      this.salaryCodes = [];
      res.forEach(data => {
          const code = data.split(" ");
          this.salaryCodes.push(code);
      });
      sCode.next(this.salaryCodes);
    });
    return sCode.asObservable();
  }
  addCommentBox(fc,index) {
    console.log(fc);
    this.formFields.map(data => { data.visible = false });
    this.rowVisible.fill(false);
    this.rowVisible[index] = true;
    fc.visible = true;
  }
  projectSelect(project) {
    this.selectableSalaryCode = this.projectCodeList.filter(pcode => {
      if(pcode['project']['projNum'] == project) return pcode });
  }

  saveWeekData() {
    this.projectWeekData['year'] = this.currentYear;
    this.projectWeekData['weekNumber'] = this.currentWeekNumber;
    this.projectWeekData['empId'] = this.empId;
    var weekProjects = [];
    this.timesheetProjectForm.getRawValue().projectLine.forEach((data) => {
      var pHours = [];
      for (var i = 1; i <= 7; i++) {
        const hoursWork = eval('data.day' + i).length == 0 ? 0 : eval('data.day' + i);
        const comment = eval('data.day' + i + 'comment') == 0 ? '' : eval('data.day' + i + 'comment');
        const salary = eval('data.day' + i + 'salary') == false ? '0' : '1';
        const unid = eval('data.day' + i + 'unid') == 0 ? '' : eval('data.day' + i + 'unid');
        const dt = moment().year(this.currentYear).isoWeek(this.currentWeekNumber);
        const daynumber = dt.startOf('isoWeek').isoWeekday(1).add('d', i - 1).toISOString();
        if (hoursWork !== 0 ) {
          pHours.push({ 'unid': unid, 'dateEnd': daynumber, 'hoursWork': hoursWork, 'comment': comment, 'generateSalary': salary });
        }
      }
      weekProjects.push({ 'projectNumber': data.projectNumber, 'salaryCode': data.salaryCode, 'projectHours': pHours, 'emplId': this.empId });
    })
    this.projectWeekData['weekProjects'] = weekProjects;
    if (this.timesheetProjectForm.valid) {
      this.timesheetProjectService.saveWeekProjectDetails(this.projectWeekData).subscribe(res => {
        this.getWeekData(this.currentYear, this.currentWeekNumber, this.empId);
        this.snackbar.open('Lagring vellyket', '', {duration: 2000});
      });
    }
  }
  addProjectLineDetails() {
    const projectLineFormGroup = this.fb.group({});
    this.formFields.forEach(field => {
      if (field.type == 'select') {
        projectLineFormGroup.addControl(field.formControl, this.fb.control([], Validators.required));
        if(field.formControl == 'projectNumber') {
          projectLineFormGroup.addControl(field.formControl + 'filter', this.fb.control(''));
        }
      } else if (field.type == 'text') {
        projectLineFormGroup.addControl(field.formControl + 'comment', this.fb.control([]));
        projectLineFormGroup.addControl(field.formControl + 'salary', this.fb.control(false));
        projectLineFormGroup.addControl(field.formControl + 'unid', this.fb.control([]));
        projectLineFormGroup.addControl(field.formControl, this.fb.control([]));
      }
    });
    this.filteredProjects.next(this.projects);
    return projectLineFormGroup;
  }
  addProjectLineToFormArray() {
    this.projectRows.push(this.addProjectLineDetails());
    this.rowVisible.push(false);
  }
  get projectRows() {
    return (<FormArray>this.timesheetProjectForm.get('projectLine'));
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
