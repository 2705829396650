import { Component, OnInit, OnDestroy } from '@angular/core';
import { Map, layerGroup, latLng } from 'leaflet';
import * as L from 'leaflet';
import { HeaderService } from '../../components/header/header.service';
import { StorageService } from '../storage.service';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MapService } from '../../map.service';
@Component({
  selector: 'app-storage-map',
  templateUrl: './storage-map.component.html',
  styleUrls: ['./storage-map.component.scss']
})
export class StorageMapComponent implements OnInit, OnDestroy {
  myMap: Map;
  markerGroup: any;
  options;
  storageSubscription: any;
  storages: any[];
  loading: boolean;
  locationIcon = L.icon({
    iconUrl: 'assets/map-icons/Warehouse.png',
    iconSize:     [50, 50], // size of the icon
    popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
});
  storageUnid: any;
  storagePos:any = [];

  constructor(private headerService: HeaderService, private storageService: StorageService,private router: Router,private activatedRoute: ActivatedRoute, private mapService: MapService) {
    this.options = {
      layers: [],
      zoomControl: false,
      zoom: 11,
      maxZoom: 23,
      minZoom: 4,
      center: latLng(62.527048, 7.556067)
    };
    this.storageUnid = this.activatedRoute.snapshot.params['storageUnid'];
    this.storagePos = this.activatedRoute.snapshot.params.gpsPos;
  }

  ngOnInit() {
    this.headerService.addNavItem({
      route: `/map`,
      label: 'Kart',
    });
    this.storageSubscription = this.storageService.getOpenStorages().subscribe(data => {
      this.storages = data;
      this.storages = this.storages.map( storage => {
        storage.gpsPos = storage.unloadGps ? storage.unloadGps : storage.gpsPos;
        return storage;
      })
      const filteredStorage = this.storages.filter( storage => {
        return storage.gpsPos && storage.gpsPos !== '' && storage.gpsPos !== '0;0' && storage.gpsPos !== 'ingen'
      });
      const gpsPos = filteredStorage.map(storage => storage.gpsPos);
      this.AddStorageMarker(filteredStorage);
    }, catchError( (err) => {
      return err;
    }))
  }
  AddStorageMarker(filteredStorage: any[]) {
    const coordinates = [];
    filteredStorage.forEach(data => {
      const res = data.gpsPos.split(";")
      const lngLat = L.latLng(res[0], res[1]);
      const points = [];
      points.push(res[0], res[1]);
      coordinates.push(points);
      const popupMsg = `<a href="#/lager/${data.unid}">${data.subject}</a><hr> Fylke:${data.countyName}`;
      L.marker(lngLat, {icon: this.locationIcon}).bindPopup(popupMsg).addTo(this.markerGroup);
    });
    this.markerGroup.addTo(this.myMap);
    if(!this.storagePos) {
      this.myMap.fitBounds(coordinates);
    } else {
      const lngLat = L.latLng(this.storagePos.split(',')[0], this.storagePos.split(',')[1]);
      this.myMap.flyTo(lngLat, 12);
    }
  }
  async onMapReady(map: L.Map): Promise<void> {
    this.myMap = map;
    map.setView(L.latLng(62.527048, 7.556067), 11);
    const vector = await this.mapService.getVectorTileLayer();
    vector.addTo(map);
    this.markerGroup = layerGroup().addTo(this.myMap);
  }

  ngOnDestroy() {
    this.headerService.removeNavItem({
      route: `/map`,
      label: 'Kart',
      queryParams: ''
    });
  }

}
