import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../data.service';
import { Subscription, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { IsiWeekPerson } from '../isi-week-person';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { UserService } from '../user.service';

@Component({
  selector: 'app-employee-picker',
  templateUrl: './employee-picker.component.html',
  styleUrls: ['./employee-picker.component.scss']
})
export class EmployeePickerComponent implements OnInit, OnDestroy {

  private memberSub: Subscription;
  members: Array<IsiWeekPerson>;
  membersGroups: Array<{groupName: string, members: Array<IsiWeekPerson>}> = []; // members are references to members array
  /**
   * Subset of members. Can only select one that is available.
   * (Used in order to select only available members in diets, those found in the weekDay).
   * If the array is null or empty, all members can be selected.
   */
  @Input() availableMembers: Array<IsiWeekPerson> = [];
  @Input() membersFormCtrl: FormControl;
  @Input() multiple: boolean;
  @Input() displayEmptyOption: boolean;
  @Input() placeholder = 'Velg ansatte';
  @Input() selectAllTeamMembers: boolean; // only teamMembers, not the whole list
  @Input() defaultMembers: Array<IsiWeekPerson> = [];
  @Output() memberChange = new EventEmitter<MatSelectChange>();
  @Output() membersInit = new EventEmitter<boolean>(); // emit value when teammembers have been added
  constructor(private dataService: DataService, private userSerevice: UserService) {
  }

  /**
   * If the defaultMembers input decorator is not empty, it will override the values already in the formControl
   * @param teamMembers the members to be default in case there are no deafult members in the input, nor in the formControl on init
   */
  private setDefaultTeamMembers(teamMembers: Array<IsiWeekPerson>) {
    let members = [];
    if (!this.defaultMembers || this.defaultMembers.length <= 0) { // use exisiting value
      this.defaultMembers = this.membersFormCtrl.value;
    }
    if (this.defaultMembers && this.defaultMembers.length > 0) { // add the default members from input
      for (const exisitingMember of this.defaultMembers) {
        const foundMember = this.members.find( (a) => {
          return (exisitingMember && a && a.unid === exisitingMember.unid);
        });
        if (foundMember) {
          members = [...members, ...[foundMember]];
        }
      }
      this.membersFormCtrl.setValue(members);
    }
    if (!this.multiple && members && members.length > 0) { // assume first member to be the default
      this.membersFormCtrl.setValue(members[0]);
    }
    if (!this.membersFormCtrl.value) {
      this.membersFormCtrl.setValue("");
    }
    if (this.multiple && this.selectAllTeamMembers && !this.defaultMembers && !this.membersFormCtrl.value) {
      this.membersFormCtrl.setValue(teamMembers);
    }
  }
  selectionChange(event: MatSelectChange) {
    this.memberChange.next(event);
  }
  ngOnInit() {
    const prom1 = this.dataService.getTeamMembers().pipe(take(1));
    const prom2 = this.dataService.getTeamMembers(true).pipe(take(1));
    this.membersGroups.push({ groupName: 'Lag ' + this.userSerevice.teamId, members: []});
    this.membersGroups.push({ groupName: 'Andre', members: []});
    forkJoin([prom1, prom2]).subscribe( res => {
      this.members = [...res[1]];
      const teamMembers = res[0];
      this.addTeamMemberIfMissing(teamMembers);
      this.setAvailableMembers();
      this.setDefaultTeamMembers(teamMembers);
      this.groupTeamMembmers(teamMembers);
      this.membersInit.next(true);
    });
  }
  /**
   * Ads the current week's teammembers to the list of all members if they are not present.
   * The same applies to default members and members already present in the formControl.
   * @param teamMembers the list of teammembers on your current team for this week.
   */
  private addTeamMemberIfMissing(teamMembers: Array<IsiWeekPerson>): void {
    const membersToAdd: {[unid: string]: IsiWeekPerson} = {};
    teamMembers = [...teamMembers, ...this.defaultMembers];
    if (this.membersFormCtrl && this.membersFormCtrl.value) {
      teamMembers = [...teamMembers, ...this.membersFormCtrl.value];
    }
    teamMembers.forEach(teamMember => {
      const foundMem = this.members.find((mem) => mem && teamMember && mem.unid === teamMember.unid);
      if (!foundMem && teamMember) {
        membersToAdd[teamMember.unid] = teamMember;
      }
    });
    for (const unid in membersToAdd) {
      if (membersToAdd.hasOwnProperty(unid)) {
        this.members.unshift(membersToAdd[unid]);
      }
    }
  }
  /**
   * If no members are used in the @Input()-decorator, make sure that all members can be selected
   */
  private setAvailableMembers(): void {
    if (this.availableMembers && this.availableMembers.length > 0) {
    } else {
      this.availableMembers = [...this.members];
    }
  }
  /**
   * Will group your team at the top, and the rest afterwards in alphabetic order. Will
   * get the members from the this.members list, making sure that we have the reference, and not a copy.
   * @param teamMembers The list of teammembers in your current team for this week
   */
  private groupTeamMembmers (teamMembers: Array<IsiWeekPerson>): void {
    if (teamMembers && teamMembers.length > 0) {
      const groupTeam = this.members.filter(mem => {
        return (mem && teamMembers.find(teamMem => teamMem.unid === mem.unid));
      })
      const restOfMembers = this.members.filter((mem) => {
        return (mem && !groupTeam.find(teamMem => teamMem.unid === mem.unid));
      });
      this.membersGroups[0].members = groupTeam;
      this.membersGroups[1].members = restOfMembers;
    } else {
      this.members.forEach((mem) => {
        this.membersGroups[1].members.push(mem);
      });
    }
  }
  ngOnDestroy() {
    if (this.memberSub && !this.memberSub.closed) {
      this.memberSub.unsubscribe();
    }
  }
  isAvailable(employee: IsiWeekPerson) {
    return this.availableMembers.find((member) => member.unid === employee.unid);
  }
}
