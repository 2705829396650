import { Directive, ElementRef, Renderer2,
  OnInit, AfterViewInit, ViewContainerRef, ComponentFactoryResolver, OnChanges, SimpleChange, Input} from '@angular/core';
import { DynamicButtonComponent } from '../dynamic-button/dynamic-button.component';
import { ReturnStatement } from '@angular/compiler';

@Directive({
  selector: '[appCustomIncDecBtns]'
})
export class CustomIncDecBtnsDirective implements OnInit, AfterViewInit {
  @Input() useFiniteNumbers: boolean;
  constructor(private el: ElementRef,
    public renderer: Renderer2,
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver) {
  }
  ngOnInit() {
    let parent = this.renderer.parentNode(this.el.nativeElement);
    console.log("(<HTMLInputElement>this.el.nativeElement).value", (<HTMLInputElement>this.el.nativeElement).value);
    if (!parent) {
      parent = this.renderer.createElement('div');
    }
    if (!this.useFiniteNumbers) {
      this.renderer.setAttribute(this.el.nativeElement, 'step', '0.01');
    } else if ((<HTMLInputElement>this.el.nativeElement).value && // convert comma numbers to whole numbers
      (<HTMLInputElement>this.el.nativeElement).value.match(/\.|,/)) {
        this.stepUp(new Event('change'));
    }
    this.renderer.listen(this.el.nativeElement, 'keydown', (evt: KeyboardEvent) => {
      if (evt.key === 'ArrowUp') {
        evt.preventDefault();
        this.stepUp(evt);
      } else if (evt.key === 'ArrowDown') {
        evt.preventDefault();
        this.stepDown(evt);
      }
    });
    const container = this.renderer.createElement('div');
    this.renderer.addClass(container, 'custom-number-input');
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DynamicButtonComponent);
    const componentRefIncrease = this.viewContainerRef.createComponent(componentFactory);
    const componentRefDecrease = this.viewContainerRef.createComponent(componentFactory);
    componentRefIncrease.instance.clickFunction = this.stepUp;
    componentRefIncrease.instance.btnTxt = '+';
    componentRefDecrease.instance.clickFunction = this.stepDown;
    componentRefDecrease.instance.btnTxt = '-';
    this.renderer.appendChild(container, componentRefDecrease.location.nativeElement);
    this.renderer.appendChild(container, this.el.nativeElement);
    this.renderer.appendChild(container, componentRefIncrease.location.nativeElement);
    this.renderer.addClass(container, 'hide-default-number-input');
    this.renderer.appendChild(parent, container);
  }
  stepUp = (evt: Event) => {
    const isDisabled = (<HTMLInputElement>this.el.nativeElement).disabled
    if (isDisabled) {
      return;
    }
    evt.stopPropagation();
    if (this.useFiniteNumbers) {
      (<HTMLInputElement>this.el.nativeElement).stepUp(1);
    } else {
      (<HTMLInputElement>this.el.nativeElement).stepUp(100);
    }
    (<HTMLInputElement>this.el.nativeElement).dispatchEvent(new Event('change'));
  }
  stepDown = (evt: Event) => {
    const isDisabled = (<HTMLInputElement>this.el.nativeElement).disabled
    if (isDisabled) {
      return;
    }
    evt.stopPropagation();
    if (this.useFiniteNumbers) {
      (<HTMLInputElement>this.el.nativeElement).stepDown(1);
    } else {
      (<HTMLInputElement>this.el.nativeElement).stepDown(100);
    }
    (<HTMLInputElement>this.el.nativeElement).dispatchEvent(new Event('change'));
  }
  ngAfterViewInit(): void {
  }

}
