import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import * as moment from 'moment';
var DayInputComponent = /** @class */ (function () {
    function DayInputComponent() {
        this.dateChange = new EventEmitter();
        if (!this.selectedDate) {
            this.selectedDate = moment();
        }
    }
    DayInputComponent.prototype.getNextDay = function () {
        this.selectedDate = moment(this.selectedDate).add(1, 'day');
        this.dateChange.next(this.selectedDate);
    };
    DayInputComponent.prototype.getPrevDay = function () {
        this.selectedDate = moment(this.selectedDate).add(-1, 'day');
        this.dateChange.next(this.selectedDate);
    };
    DayInputComponent.prototype.ngOnInit = function () {
    };
    DayInputComponent.prototype.ngOnDestroy = function () {
    };
    DayInputComponent.prototype.onContainerClick = function (event) {
    };
    Object.defineProperty(DayInputComponent.prototype, "selectedDay", {
        get: function () {
            return moment(this.selectedDate);
        },
        enumerable: true,
        configurable: true
    });
    return DayInputComponent;
}());
export { DayInputComponent };
