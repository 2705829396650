import {Component, Inject, OnInit} from '@angular/core';
import {AbstractDialogComponent} from '../abstract-dialog/abstract-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef, MatTabChangeEvent} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-count-dialog',
  templateUrl: './count-dialog.component.html',
  styleUrls: ['./count-dialog.component.scss']
})
export class CountDialogComponent extends AbstractDialogComponent implements OnInit {
  dialogFormGroup: FormGroup;
  txtConfirmBtn: string;
  confirmDemolitionPoint = false;
  confirmCountPoint = false;
  selectedIndex = 0;
  constructor(public dialogRef: MatDialogRef<AbstractDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    super(dialogRef);
    this.dialogFormGroup = new FormGroup({
      'demolitionCheckboxForm': new FormControl('', []),
      'countCheckboxForm': new FormControl('', [])});
  }
  ngOnInit() {
    // TODO (john) set the selected Index to the tab that makes sense. If demolition only, select tab2, if measure only, select tab 1.
  }
  confirm(event) {
    if (this.dialogFormGroup.controls.demolitionCheckboxForm.value) {
      // TODO (john) do some logic
    }
    if (this.dialogFormGroup.controls.countCheckboxForm.value) {
      // TODO (john) do some logic
    }
    this.dialogRef.close(this.data);
  }
  onTabChanged(event: MatTabChangeEvent) {
    this.selectedIndex = event.index;
    switch (event.index) {
      case 0:
        this.txtConfirmBtn = 'Bekreft monteringsmateriell';
        break;
      case 1:
        this.txtConfirmBtn = 'Bekreft rivingsmateriell';
        break;
      default:
        break;
    }
  }
}
