import { Component, OnInit, Renderer2, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import { SettingsService } from '../../settings.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-order-article',
  templateUrl: './order-article.component.html',
  styleUrls: ['./order-article.component.scss']
})
export class OrderArticleComponent implements OnInit, OnDestroy {

  @HostBinding('class.agjerde-icon-container') hostClass = true;

  isNightMode: boolean;
  private nightModeSub: Subscription;
  constructor(private settingsService: SettingsService, private renderer: Renderer2, private elemRef: ElementRef) {
    this.nightModeSub = this.settingsService.getNightMode().subscribe(isNightMode => {
      this.isNightMode = isNightMode;
    })
  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
    if (this.nightModeSub) {
      this.nightModeSub.unsubscribe();
    }
  }

}
