import {Component, Inject, OnInit, Type, ViewChild} from '@angular/core';
import {AbstractDialogComponent} from '../abstract-dialog/abstract-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {RailingType} from '../../railing-type';
import {RailingCategoriesSelectComponent} from '../railing-categories-select/railing-categories-select.component';

@Component({
  selector: 'app-railing-dialog',
  templateUrl: './railing-dialog.component.html',
  styleUrls: ['./railing-dialog.component.scss']
})
export class RailingDialogComponent extends AbstractDialogComponent implements OnInit {

  dialogFormGroup: FormGroup;
  @ViewChild(RailingCategoriesSelectComponent, {static: false}) railingCatSelectComponent;
  constructor(protected dialogRef: MatDialogRef<AbstractDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                type: Type<AbstractDialogComponent>,
                isDemolition?: boolean,
                railingType?: RailingType
              },
              private http: HttpClient, private formBuilder: FormBuilder) {
    super(dialogRef);
  }

  ngOnInit() {
    this.dialogFormGroup = new FormGroup({'': new FormControl('', [])});
  }

  confirm(event): void {
    this.dialogRef.close({
      railingType: this.railingCatSelectComponent.typeFormControl.value,
      type: RailingDialogComponent,
      isDemolition: this.data.isDemolition
    });
  }
}
