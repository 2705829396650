<div class="dialogHeader">
      <h1 mat-dialog-title>{{ storage?.subject }}</h1>
      <button class="btn-close" color="accent" type="button" mat-icon-button (click)="onNoClick()">
            <mat-icon aria-label="Close">close</mat-icon>
      </button>
</div>
<div class="dialogContent">
      <div class="block">
            <div class="grid1">
                  <h2 class="updated-date">Sist endret: </h2> {{ storage?.created | date:'dd.MM.yyyy' }} <br>
                  <h2 class="updatedBy">Endret av: </h2> {{ storage?.responsible }} <br>
                  <h2 class="status">Status: </h2>
                  <span *ngIf="storage?.status == 1">Åpen</span>
                  <span *ngIf="storage?.status == 5">Arkivert</span>
                  <br><br>
            </div>
            <div class="grid2">
                  <h2 class="position">Posisjon: </h2><span *ngIf="!locationAvailable">Mangler posisjon</span> <a
                        *ngIf="locationAvailable" [routerLink]="['/lager/'+storage?.unid + '/map',{'gpsPos':gpsPos}]"
                        (click)="onNoClick()">Vis i kart</a> <br>
                  <h2 class="contact-person"> Kontakt Person Lag: </h2> {{ storage?.teamContact }} <br>
                  <button mat-raised-button color="primary" *ngIf="storage?.status == 1"
                        (click)="archiveStorage()">Slett, lageret er tomt</button>
            </div>
            <div class="row">
                  <h2>Kommentar:</h2>
                  &nbsp;{{ storage?.comment }}
            </div>
            <div class="row">
                  <form class="dialog" [formGroup]="dialogFormGroup">
                        <div class='add-article' formGroupName='addArticleFormGroup'>
                              <mat-form-field>
                                    <!-- <mat-label>Velg artikkel</mat-label> -->
                                    <mat-select formControlName='articleSelect' [(value)]="selected" required #singleSelect>
                                          <mat-option>
                                                <ngx-mat-select-search formControlName="articleFilterCtrl"
                                                      placeholderLabel="Velg artikkel" i18n-placeholderLabel
                                                      clearSearchInput 
                                                      noEntriesFoundLabel="'ingen angitt'" i18n-noEntriesFoundLabel>
                                                </ngx-mat-select-search>
                                          </mat-option>

                                          <mat-option *ngFor="let article of filteredArticles | async"
                                                [value]="article.id" >
                                                {{ article.id }} - {{ article.name }}
                                          </mat-option>
                                    </mat-select>
                              </mat-form-field>
                              <mat-form-field>
                                    <input type="number" matInput formControlName='articleCount' placeholder="Antall"
                                          required>
                              </mat-form-field>
                              <!-- <p>you selected {{selected}}</p> -->
                              <div class="comment" [hidden]="selected != 99999">
                                    <mat-form-field>
                                          <input type="text" matInput formControlName='comment' placeholder="Kommenter">
                                    </mat-form-field>
                              </div>
                              <button type="button" class="btn-create" color="primary" mat-raised-button
                                    [disabled]="!dialogFormGroup.get('addArticleFormGroup').valid"
                                    (click)="addArticle()"> Legg til
                                    lagerartikkel </button>
                        </div>

                  </form>
            </div>
      </div>

      <mat-tab-group>
            <mat-tab label="Levert">
                  <table mat-table id="articleTable" [dataSource]="articles" *ngIf="articles && articles.length>0"
                        [class.isMobile]="isMobile">
                        <ng-container matColumnDef="article">
                              <th mat-header-cell *matHeaderCellDef> Artikkel </th>
                              <td mat-cell *matCellDef="let element">
                                    <span class="mobile-label">Artikkel: &nbsp;</span>
                                    {{element?.artNr}} - {{getName(element)}}
                              </td>
                        </ng-container>
                        <ng-container matColumnDef="count">
                              <th mat-header-cell *matHeaderCellDef> Antall </th>
                              <td mat-cell *matCellDef="let element">
                                    <span class="mobile-label">Antall: &nbsp;</span>
                                    {{element?.antall}}
                              </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                              <th mat-header-cell *matHeaderCellDef> Endre materiell av lager </th>
                              <td mat-cell *matCellDef="let element">
                                    <span class="mobile-label">Endre materiell av lager:</span>
                                    <form class="dialog" [formGroup]="dialogFormGroup">
                                          <mat-form-field>
                                                <input type="number" appCustomIncDecBtns matInput
                                                      [formControlName]="element?.artNr" name="count" placeholder=""
                                                      required>
                                          </mat-form-field>
                                          <button type="button" color="primary" mat-raised-button
                                                [disabled]="!isValid(element.artNr,element?.antall)"
                                                (click)="updateMaterialCount(element.artNr, 'remove')">
                                                Ta ut
                                          </button>
                                          <button type="button" color="primary" mat-raised-button
                                                [disabled]="!isValidLeggTil(element.artNr)"
                                                (click)="updateMaterialCount(element.artNr, 'add')">
                                                Legg til
                                          </button>
                                          <!-- <button type="button" class="btn-close" mat-button
                                                (click)="setCountToDeafult(element.artNr)"> NullStill </button> -->
                                    </form>
                              </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="articleColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: articleColumns"></tr>
                  </table>
            </mat-tab>
            <mat-tab label="Historie">
                  <table mat-table id="historyTable" [dataSource]="storage?.log"
                        *ngIf="storage?.log && storage?.log?.length>0" [class.isMobile]="isMobile">
                        <ng-container matColumnDef="time">
                              <th mat-header-cell *matHeaderCellDef> Tidpunkt </th>
                              <td mat-cell *matCellDef="let element">
                                    <span class="mobile-label">Tidpunkt:</span>
                                    {{element?.time | date: 'dd.MM.yyyy mm.ss'}}
                              </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                              <th mat-header-cell *matHeaderCellDef> Handling </th>
                              <td mat-cell *matCellDef="let element">
                                    <span class="mobile-label">Handling:</span>
                                    {{element?.action}}
                              </td>
                        </ng-container>
                        <ng-container matColumnDef="user">
                              <th mat-header-cell *matHeaderCellDef> Bruker </th>
                              <td mat-cell *matCellDef="let element">
                                    <span class="mobile-label"> Bruker: </span>
                                    {{element?.user}}
                              </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="historyColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: historyColumns"></tr>
                  </table>
            </mat-tab>
      </mat-tab-group>
</div>
<div class="dialog-actions" align="end">
</div>