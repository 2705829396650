import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-year-input',
  templateUrl: './year-input.component.html',
  styleUrls: ['./year-input.component.scss']
})
export class YearInputComponent implements OnInit {
  @Input() selectedDate: moment.Moment;
  @Output() dateChange: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();
  constructor() {
    if (!this.selectedDate ) {
      this.selectedDate = moment();
    }
  }

  ngOnInit() {
  }
  getNextYear() {
    this.selectedDate = moment(this.selectedDate).add(1, 'year');
    this.dateChange.next(this.selectedDate);
  }
  getPrevYear() {
    this.selectedDate = moment(this.selectedDate).add(-1, 'year');
    this.dateChange.next(this.selectedDate);
  }
  get selectedYear() {
    return moment(this.selectedDate).startOf('isoWeek').add(3, 'day').year();
  }
}
