import { OnInit, EventEmitter } from '@angular/core';
import * as moment from 'moment';
var YearInputComponent = /** @class */ (function () {
    function YearInputComponent() {
        this.dateChange = new EventEmitter();
        if (!this.selectedDate) {
            this.selectedDate = moment();
        }
    }
    YearInputComponent.prototype.ngOnInit = function () {
    };
    YearInputComponent.prototype.getNextYear = function () {
        this.selectedDate = moment(this.selectedDate).add(1, 'year');
        this.dateChange.next(this.selectedDate);
    };
    YearInputComponent.prototype.getPrevYear = function () {
        this.selectedDate = moment(this.selectedDate).add(-1, 'year');
        this.dateChange.next(this.selectedDate);
    };
    Object.defineProperty(YearInputComponent.prototype, "selectedYear", {
        get: function () {
            return moment(this.selectedDate).startOf('isoWeek').add(3, 'day').year();
        },
        enumerable: true,
        configurable: true
    });
    return YearInputComponent;
}());
export { YearInputComponent };
