import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import { DataService } from '../../data.service';
import { Article } from '../../article';

@Component({
  selector: 'app-composite-article-sheet',
  templateUrl: './composite-article-sheet.component.html',
  styleUrls: ['./composite-article-sheet.component.scss']
})
export class CompositeArticleSheetComponent implements OnInit {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private dataService: DataService, private cd: ChangeDetectorRef) {}
  articles: Array<Article>;
  ngOnInit() {
    this.dataService.getArticlesFromCompositeArticle(this.data.rtType, this.data.saNum, this.data.quantity)
    .subscribe((articles: Array<Article>) => {
      this.articles = articles;
      this.cd.markForCheck();
    });
  }

}
