export class GpsQuality {

  public getGpsQuality(fixQuality: string, hdop: number): number {
    let gpsQuality = GPS_QUALITY.BAD;
    if (fixQuality && hdop > 0) {
      if (fixQuality === 'rtk') {
        gpsQuality = GPS_QUALITY.BEST;
      } else if ((fixQuality === 'rtk-float' && hdop < 2) || hdop <= 1) {
        gpsQuality = GPS_QUALITY.GOOD;
      } else {
        gpsQuality = GPS_QUALITY.LOW;
      }
    }
    return gpsQuality;
  }

  public getGpsQualityFromFixedNumber(fixQuality: number, hdop: number): number {
    let gpsQuality = GPS_QUALITY.BAD;
    if (fixQuality && hdop > 0) {
      if (fixQuality === 4) {
        gpsQuality = GPS_QUALITY.BEST;
      } else if ((fixQuality === 5 && hdop < 2) || hdop <= 1) {
        gpsQuality = GPS_QUALITY.GOOD;
      } else {
        gpsQuality = GPS_QUALITY.LOW;
      }
    }
    return gpsQuality;
  }
}

export const enum GPS_QUALITY {
  BEST = 3,
  GOOD = 2,
  LOW = 1,
  BAD = 0
}
