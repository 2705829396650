<form class="dialog" #pointDialogForm="ngForm" [formGroup]="dialogFormGroup" (ngSubmit)="confirm($event)">
  <!-- <h2>Registrering</h2> -->
  <button class="btn-close" color="accent" type="button" mat-icon-button (click)="closeDialog()">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>

  <section class="switch switch-section-measure">
    <mat-slide-toggle [color]="'primary'" [formControl]="measureSwitch" (change)="measureSwitchChange($event)" [disabled]="isCalculating">
      Montering
      <!--<span>: {{data?.rtType?.id || 'Ingen valgt rekkverkstype'}}</span>-->
    </mat-slide-toggle>
    <app-railing-categories-select #mounting *ngIf="data.rtType" [isDemolition]="false" [railingType]="data.rtType"
      (railingTypeChanged)="onRtTypeChanged($event)"></app-railing-categories-select>
  </section>
  <section class="switch switch-section-demolition">
    <mat-slide-toggle [color]="'primary'" [formControl]="demolitionSwitch" (change)="demolitionSwitchChange($event)"
      (click)="switchClick('demolition')" [disabled]="isCalculating">
      Riving
      <!--<span>: {{data.demolitionType?.id || 'Ingen valgt rekkverkstype'}}</span>-->
    </mat-slide-toggle>
    <app-railing-categories-select #demolition *ngIf="data.demolitionType" [isDemolition]="true"
      [railingType]="data.demolitionType" (railingTypeChanged)="onDemolitionTypeChanged($event)">
    </app-railing-categories-select>
  </section>
  <div *ngIf="parsellStatus===1" class="start-end-article">
    <app-select-article-form #startEndArticleSelect [placeholder]="'Endetype på første punkt'" [typeOfArticle]="'compositeArticle'"
      [(parentFormGroup)]="dialogFormGroup" [childFormControlName]="articleFormControlName"
      [childValue]="startOrEndArticle" (onArticleChanged)="onChildStartOrEndArticleChanged($event)" [includeCategories]="['Ende','Ingen']">
    </app-select-article-form>
  </div>
  <div *ngIf="parsellStatus===3 || parsellStatus===6" class="start-end-article">
    <app-select-article-form #startEndArticleSelect [placeholder]="'Endetype på siste punkt'" [typeOfArticle]="'compositeArticle'"
      [(parentFormGroup)]="dialogFormGroup" [childFormControlName]="articleFormControlName"
      [(childValue)]="startOrEndArticle" (onArticleChanged)="onChildStartOrEndArticleChanged($event)" [includeCategories]="['Ende','Ingen']">
    </app-select-article-form>
  </div>
  <section class="measure-status">
      <mat-horizontal-stepper #stepper linear="false">
        <mat-step *ngFor="let step of steps" completed="false">
          <ng-template matStepLabel>{{step.label}}
              <div class="mat-step-optional ng-star-inserted">{{step.endType}}</div>
          </ng-template>
        </mat-step>
        <ng-template matStepperIcon="done">
            <mat-icon>done_all</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="start">
          <mat-icon>tab_unselected</mat-icon>
        </ng-template>
        
      </mat-horizontal-stepper>
    </section>
  <div class="articles" [@hideArticleState]="hideArticleState">
    <h2>Foreslåtte artikler</h2>
    <div class="length-props caption">
      <span>Byggelengde{{' — ' + buildLength.toFixed(1) + 'm. '}}</span>
      <span>Riving{{' — ' + demolitionLength.toFixed(1) + 'm. '}}</span>
      <span>Spor{{' — ' + trackLength.toFixed(1) + 'm. '}}</span>
    </div>
    <div class="all-articles">
      <div *ngIf="tmpCalculatedArticles.data.length<=0"> 
        Vennligst vent mens vi foreslår artikler
        <mat-spinner></mat-spinner>
      </div>
      <app-articles [smallerCell]="{'id': true}" id="save-point-articles" [editable]="true" [viewModeOnly]="false" *ngIf="tmpCalculatedArticles.data.length>0"
        [articles]="tmpCalculatedArticles.data" [(articleAdjustments)]="tmpArticleAdjustments"></app-articles>
      <div *ngIf="compositeArticles && compositeArticles.length>0">
        <app-composite-articles [compositeArticlesSubject]="compositeArticlesSubject" [compositeArticles]="compositeArticles" [rtType]="data?.rtType?.id">
        </app-composite-articles>
      </div>
    </div>
  </div>
  <div class="select-article-section" [@addArticleState]="addArticleState">
    <app-select-article-form [typeOfArticle]="'compositeArticle'" [parentFormGroup]="addArticleFormGroup"
      [childFormControlName]="childFormControlName" [excludeCategories]="['Ende']">
    </app-select-article-form>
    <mat-form-field>
      <input type="number" matInput appCustomIncDecBtns useFiniteNumbers="true" placeholder="Antall" [formControl]="quantityFormControl" required>
      <mat-error *ngIf="quantityFormControl.invalid">Kan kun legge til et positivt antall artikler</mat-error>
    </mat-form-field>
    <button mat-raised-button type="button" color="warning" (click)="cancelAddArticle($event)">Avbryt</button>
    <button mat-raised-button type="button" color="primary" (click)="confirmAddArticle($event)"
      [disabled]="addArticleFormGroup.invalid">
      Bekreft
    </button>
  </div>
  <div class="bottom-section">
    <section class="quality-bars-section">
        Nøyaktighet &nbsp; <app-quality-bars [satQuality]="data.gpsQuality"></app-quality-bars>
    </section>
    <section class="calc-articles-checkbox-section">
        <mat-slide-toggle [color]="'primary'" (change)="changeDirection(true)" [(checked)]="directionChanged" [disabled]="parsellStatus !== 1">
        Snu retning
      </mat-slide-toggle>
    </section>
      
    <section class="calc-articles-checkbox-section">
      <mat-checkbox [formControl]="showArticlesCheckbox"
        [disabled]="data?.parcel?.points?.length==0 || (!isDemobilizing && !isMeasuring) || parsellStatus === 3 || isCalculating"
        (change)="showArticlesChange($event)">Beregn materialer</mat-checkbox>
    </section>
    <!-- TODO (john) add this function if requested by the customer. Currently not needed -->
    <button *ngIf="false" type="button" color="accent" mat-raised-button class="add-new-article-btn" [disabled]="!(tmpCalculatedArticles && data?.parcel?.points?.length>0 && (showArticles || !isDemobilizing && !isMeasuring)) ||
            (addingManualArticle || addingCompositeArticle)" (click)="showAddArticle($event)">
      Legg til artikkel
    </button>
    <button type="button" color="accent" mat-raised-button class="add-new-article-btn" [disabled]="!(tmpCalculatedArticles && data?.parcel?.points?.length>0 && (showArticles || !isDemobilizing && !isMeasuring)) ||
            (addingManualArticle || addingCompositeArticle)" (click)="showAddCompositeArticle($event)">
      Legg til strukturartikkel
    </button>
    <button *ngIf="showArticles"
      [disabled]="(tmpCalculatedArticles.data.length==0 || data?.parcel?.points?.length==0) || dialogFormGroup.invalid"
      class="add-articles-to-list-btn" type="button" color="primary" (click)="addArticlesAndPoint($event)"
      mat-raised-button>
      {{(!isDemobilizing && !isMeasuring)?'Lagre siste punkt og foreslåtte artikler':'Lagre punkt og foreslåtte artikler'}}
    </button>
    <button *ngIf="data?.parcel?.points?.length === 0" type="submit" class="btn-save success" color="primary"
      mat-raised-button
      [disabled]="(!isDemobilizing && !isMeasuring && data.points.length===0) || dialogFormGroup.invalid">
      Lagre første punkt
    </button>
    <button *ngIf="data?.parcel?.points?.length > 0 && !showArticles" type="submit" class="btn-save success"
      color="primary" mat-raised-button
      [disabled]="(!isDemobilizing && !isMeasuring && data.points.length===0) || dialogFormGroup.invalid">
      {{savePointTxt}}
    </button>
  </div>
</form>