import * as SerialPort from 'serialport';
import * as GPS from 'gps';
import * as geolib from 'geolib';
import { Injectable, HostListener } from '@angular/core';
import { timer, Observable, Subject, zip } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { takeUntil, retryWhen, delay, tap, mergeMap, flatMap, map, timeout, switchMap} from 'rxjs/operators';
// import 'rxjs/add/observable/timer';
import {GpsData} from '../gps-data';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from '../../environments/environment';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { ElectronService } from '../electron.service';
import { IsiRoadRef } from '../isi-road-ref';


@Injectable({
  providedIn: 'root'
})
export class GpsService {
  SerialPort: typeof SerialPort;
  Readline: typeof SerialPort.parsers.Readline;
  GPS: typeof GPS;
  geolib: typeof geolib;
  fs;
  watchID;
  private _port;
  private _parser;
  private _gps;
  com: Observable<any>;
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  gps$ = new BehaviorSubject<GpsData>(new GpsData(0.0, 0.0));
  gpsWithRoadRef$ = new BehaviorSubject<{gpsData: GpsData, roadRef: IsiRoadRef}>({gpsData: new GpsData(0.0, 0.0), roadRef: null});
  roadRefWebSocket: WebSocketSubject<any>;
  @HostListener('window:beforeunload')
  beforeunload() {
    this.stopGps();
  }
  constructor(private http: HttpClient, private electronService: ElectronService) {
    console.log("this.roadRefWebSocket", this.roadRefWebSocket);
    this.setupGps();
  }

  public setupGps() {
    console.log("setup gps");
    if (this.electronService.isElectron()) {
      this.SerialPort = window.require('serialport');
      // this.SerialPort.Binding = window.require('@serialport/bindings');
      this.GPS = window.require('gps');
      this._gps = new this.GPS;

      this.geolib = window.require('geolib');
      this.fs = window.require('fs');
      this.Readline = window.require('@serialport/parser-readline');
      window.require('electron').ipcRenderer.on('gps', (event, action) => {
        if (action === 'stop') {
          this.stopGps();
        }
      });
    }
    if (AppConfig.production) {
      this.startGps();
    } else {
      this.startGps();
    }
    if (this.electronService.isElectron()) {
      this.roadRefWebSocket = webSocket('ws://localhost:8080/roadref/roadref-ws');
      this.roadRefWebSocket.asObservable().pipe(
        retryWhen(errors =>
          errors.pipe(
            tap(err => {
              console.error('Got error', err);
            }),
            delay(1000),
          )
        ));
      zip(this.gps$, this.roadRefWebSocket).pipe(timeout(4000),
      retryWhen(errors =>
        errors.pipe(
          tap(err => {
            console.log('gps timeout', err);
            this.gpsWithRoadRef$.next({ gpsData: null, roadRef: null });
            try {
              if (!this._port.isOpen) {
                this.ngUnsubscribe.next();
                this.initComPort();
                this.gpsObserver();
              }
            } catch (erro) {
              console.log('feil', erro);
            }
          }),
          delay(2000),
        )
      )).subscribe(gpsDataAndRoadRef => {
        this.gpsWithRoadRef$.next({ gpsData: gpsDataAndRoadRef[0], roadRef: gpsDataAndRoadRef[1] });
      }, err => {
        console.log('error', err);
        this.gpsWithRoadRef$.next({ gpsData: null, roadRef: null });
      });
    } else {
      this.roadRefWebSocket = new WebSocketSubject('');
      this.gps$.pipe(
        switchMap(gpsData => {
          return this.getRoadRefWeb(gpsData.lat, gpsData.lon).pipe(map(roadRef => {
            return {gpsData, roadRef};
          }))
        })
      ).subscribe(({gpsData, roadRef}) => {
        this.gpsWithRoadRef$.next({ gpsData , roadRef});
      });
    }
  }

  public getRoadRefWeb(lat: number, lon: number): Observable<IsiRoadRef> {
    return this.http.get(`https://nvdbapiles-v3.atlas.vegvesen.no/posisjon?lat=${lat}&lon=${lon}`)
      .pipe(map(data => {
        
        const rr = new IsiRoadRef();
        const vegref = data[0].vegsystemreferanse;
        if (vegref) {
          rr.roadCategory = vegref.vegsystem.vegkategori;
          rr.roadStatus = vegref.vegsystem.fase;
          rr.section = vegref.strekning.strekning;
          rr.subSection = vegref.strekning.delstrekning;
          rr.meter = vegref.strekning.meter;
          rr.roadNumber = vegref.vegsystem.nummer;
          if (vegref.kommune) {        
            const kommune = vegref.kommune.toString();
            rr.municipality = kommune.substr(0,kommune.length-2);
            rr.county = kommune.substr(kommune.length-2);
          }
          rr.shortForm = vegref.kortform;
        }
        return rr;
      }));
  }

  public getObservable(): Observable<{gpsData: GpsData, roadRef: IsiRoadRef}> {
    return this.gpsWithRoadRef$.asObservable();
  }

  public getCurrentPos(): {gpsData: GpsData, roadRef: IsiRoadRef} {
    return this.gpsWithRoadRef$.getValue();
  }

  private startGps = (): void => {
    if (this.electronService.isElectron()) {
      this._gps = new this.GPS;
      this.initComPort();
      this.gpsObserver();
    } else {
      this.watchID = navigator.geolocation.watchPosition(position => {
        console.log('position', position);
        const gpsData = new GpsData(position.coords.latitude, position.coords.longitude);
        gpsData.quality =  Number(position.coords.accuracy).toString();
        gpsData.hdop = 2.5;
        gpsData.type = 'WEB';
        this.gps$.next(gpsData);
      });
    }
  }

  startFakeGps = (): void => {
    this._gps = new this.GPS;
    let next = 100;
    const lines = this.fs.readFileSync('src/assets/output_e136.nmea', 'utf-8')
    .split('\n')
    .filter(Boolean);
    const t = timer(0, 1000).pipe(
      takeUntil(this.ngUnsubscribe)
    );
    t.subscribe(() => {
      this._gps.update(lines[next]);
      if (!lines[next]) {
        next = 0;
      } else {
        next++;
      }
    });
    this.gps$.pipe(takeUntil(this.ngUnsubscribe));
    this._gps.on('data', (data: GpsData) => {
      if (data.lat) {
        this.roadRefWebSocket.next(data);
        this.gps$.next(data);
      }
    });
  }

  public stopGps() {
    this.ngUnsubscribe.next();
    if (this._port && this._port.isOpen) {
      this._port.close();
      this.roadRefWebSocket.unsubscribe();
    }
  }

  distance(from, to) {
    return this.GPS.Distance(from.lat, from.lon, to.lat, to.lon) * 1000;
  }

  private initComPort() {
    this._port = new this.SerialPort('COM14', { // change path
      baudRate: 4800
    });
    this._parser = this._port.pipe(new this.Readline());
  }

  private gpsObserver = (): void => {
    this.gps$.pipe(takeUntil(this.ngUnsubscribe));

    this.comPortObserver().pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe();

    this._gps.on('data', (data: GpsData) => {
      if (data && data.lat) {
        this.roadRefWebSocket.next(data);
        this.gps$.next(data);
      }
    });
  }


  private comPortObserver = (): Observable<any> => {
    return this.com = Observable.create(observer => {
      this._parser.on('data', (data) => {
        try {
          const nmea = data;
          if (nmea.indexOf('GPGGA') !== -1) {
            this._gps.update(nmea);
          }
        } catch (e) {
          console.error(e);
        }
      });
    });
  }

  getGpsData = (): Observable<any> => {
    return new Observable(observer => {
      return this.http.get('assets/gps-data.json').subscribe(data => {
        setInterval(() => {
          observer.next(data);
        }, 1000);
      });
    });
  }
  /**
   * all params are radians
   * https://www.movable-type.co.uk/scripts/latlong.html
   * @param {number} lat1
   * @param {number} lon1
   * @param {number} lat2
   * @param {number} lon2
   * @returns {number}
   */
  getDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    const radius = 6371e3;
    const deltaLat = lat2 - lat1;
    const deltaLon = lon2 - lon1;
    const a = Math.pow(Math.sin(deltaLat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) *
      Math.pow(Math.sin(deltaLon / 2), 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return radius * c ;
  }
  getRadiansFromDegreesMinutes(degMinutes) {
    return this.getDecimalDegreesFromDegreeMinutes(degMinutes) * Math.PI / 180;
  }
  getDecimalDegreesFromDegreeMinutes (degMinutes) {
    return parseFloat(degMinutes.substr(0, degMinutes.indexOf('.') - 2)) +
      parseFloat(degMinutes.substr(degMinutes.indexOf('.') - 2)) / 60;
  }
}
