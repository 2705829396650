import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  taskActions: Subject<any>;
  apiUrl;
  constructor(private http: HttpClient) {
    this.taskActions = new Subject();
    this.apiUrl = AppConfig.apiUrl;
  }

  getTaskActions() {
    return this.taskActions.asObservable();
  }

  getWorkshopTasks(team: string, week: number): Observable<Array<any>> {
    const url = `${this.apiUrl}/tasks/workshop/${team}?week=${week}`;
    return this.http.get<any>(url).pipe(map( res => {
      return res.data;
    }));

  }

  saveWorkshopTask(task: any): Observable<any> {
    const url = `${this.apiUrl}/tasks/workshop`;
    return this.http.post<any>(url, task).pipe(map( res => {
      this.taskActions.next('updated');
      return res.data;
    }));
  }

  deleteTask(task): Observable<any> {
    const url = `${this.apiUrl}/tasks/delete/${task.unid}`;
    return this.http.post<any>(url, {}).pipe(map( res => {
      this.taskActions.next('updated');
      return res.data;
    }));
  }
}
