var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { AppConfig } from '../../../environments/environment';
import { GpsService } from '../../gps/gps.service';
import * as piexif from 'piexifjs';
import * as moment from 'moment';
import { ElectronService } from '../../electron.service';
import { UserService } from '../../user.service';
import { MatSnackBar } from '@angular/material';
var CameraComponent = /** @class */ (function () {
    function CameraComponent(gpsService, http, snackbar, electronService, userService) {
        this.gpsService = gpsService;
        this.http = http;
        this.snackbar = snackbar;
        this.electronService = electronService;
        this.userService = userService;
        this.onImageSave = new EventEmitter();
        this.showLive = true;
        this.devices = [];
    }
    CameraComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var devices;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAvaliableDevices()];
                    case 1:
                        devices = _a.sent();
                        this.devices = devices.filter(function (device) { return device.kind === 'videoinput'; });
                        this.selectedDevice = this.devices[0].deviceId;
                        this.initCamera(this.selectedDevice);
                        return [2 /*return*/];
                }
            });
        });
    };
    CameraComponent.prototype.getAvaliableDevices = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, navigator.mediaDevices.enumerateDevices()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CameraComponent.prototype.changeCamera = function () {
        console.log('change camera', this.selectedDevice);
        this.stopCamera();
        this.initCamera(this.selectedDevice);
    };
    CameraComponent.prototype.initCamera = function (deviceId) {
        var _this = this;
        navigator.mediaDevices.enumerateDevices()
            .then(function (devices) {
            devices.forEach(function (device) {
                console.log(device.kind + ": " + device.label + " id = " + device.deviceId);
            });
        })
            .catch(function (err) {
            console.error(err.name + ": " + err.message);
        });
        navigator.mediaDevices.getUserMedia({
            video: {
                width: { min: 1280, ideal: 1920 },
                height: { min: 720, ideal: 1280 },
                deviceId: deviceId //"daf62a9e9da90107ff33e6d11a80a67d38641b9b3f067bb0b2ff00460dff422d"
            }
        })
            .then(function (mediaStream) {
            document.querySelector('video').srcObject = mediaStream;
            _this.track = mediaStream.getVideoTracks()[0];
            _this.imageCapture = new ImageCapture(_this.track);
        })
            .catch(function (error) { return console.log(error); });
    };
    CameraComponent.prototype.stopCamera = function () {
        if (this.track) {
            this.track.stop();
        }
    };
    CameraComponent.prototype.takePicture = function () {
        var _this = this;
        this.initCamera(this.selectedDevice);
        this.imageCapture.takePhoto()
            .then(function (blob) {
            _this.showLive = false;
            _this.track.stop();
            return createImageBitmap(blob);
        })
            .then(function (imageBitmap) {
            // console.log('got image', imageBitmap.width + 'x' + imageBitmap.height);
            _this.drawOnCanvas(imageBitmap);
        })
            .catch(function (error) { return console.log(error); });
    };
    CameraComponent.prototype.newImage = function () {
        //this.track.start();
        this.showLive = true;
        this.initCamera(this.selectedDevice);
    };
    CameraComponent.prototype.drawOnCanvas = function (imageBitmap) {
        //const height = this.myCanvas.nativeElement.height;// = 360;
        //const width = this.myCanvas.nativeElement.width;// = 640;
        //console.log(width, height);
        this.hiddenCanvas.nativeElement.height = imageBitmap.height;
        this.hiddenCanvas.nativeElement.width = imageBitmap.width;
        console.log('imageBitmap w:', imageBitmap.width + 'y:' + imageBitmap.height);
        //this.ctx = this.myCanvas.nativeElement.getContext('2d');
        var ctx2 = this.hiddenCanvas.nativeElement.getContext('2d');
        ctx2.drawImage(imageBitmap, 0, 0);
        // get position text
        this.currPos = this.gpsService.getCurrentPos();
        if (this.currPos.roadRef) {
            var gps_1 = this.currPos.gpsData.lat.toFixed(6) + ", " + this.currPos.gpsData.lon.toFixed(6);
            var roadRefShort = this.currPos.roadRef.shortForm; // this.currPos.roadRef.roadCategory + 'V' + ' ' + this.currPos.roadRef.roadNumber + ", HP " + this.currPos.roadRef.section + " / " + this.currPos.roadRef.meter + 'm ' + this.currPos.roadRef.curve.streetName;
            //draw text
            ctx2.font = '48px serif';
            ctx2.fillStyle = 'orange';
            ctx2.fillText(roadRefShort, 20, 50);
            ctx2.fillText(gps_1, 20, 100);
        }
        //this.ctx.drawImage(this.hiddenCanvas.nativeElement, 0, 0, width, height);
        this.imageData = this.hiddenCanvas.nativeElement.toDataURL('image/jpeg', 1.0);
        //this.getCapturedImage();
    };
    CameraComponent.prototype.addExif = function (imageData) {
        var zeroth = {};
        var exif = {};
        var gps = {};
        var lat = 0.0;
        ;
        var lng = 0.0;
        if (this.currPos && this.currPos.gpsData && this.currPos.gpsData.lon && this.currPos.gpsData.lat) {
            lat = this.currPos.gpsData.lat;
            lng = this.currPos.gpsData.lon;
            gps[piexif.GPSIFD.GPSDateStamp] = this.currPos.gpsData.time;
        }
        gps[piexif.GPSIFD.GPSLatitudeRef] = lat < 0 ? 'S' : 'N';
        gps[piexif.GPSIFD.GPSLatitude] = piexif.GPSHelper.degToDmsRational(lat);
        gps[piexif.GPSIFD.GPSLongitudeRef] = lng < 0 ? 'W' : 'E';
        gps[piexif.GPSIFD.GPSLongitude] = piexif.GPSHelper.degToDmsRational(lng);
        zeroth[piexif.ImageIFD.Make] = "iSi AS";
        zeroth[piexif.ImageIFD.Artist] = this.userService.userName || "App Bruker";
        zeroth[piexif.ImageIFD.XResolution] = [777, 1];
        zeroth[piexif.ImageIFD.YResolution] = [777, 1];
        zeroth[piexif.ImageIFD.Software] = "AGjerde App";
        exif[piexif.ExifIFD.DateTimeOriginal] = moment().format('YYYY:MM:DD HH:mm:ss');
        exif[piexif.ExifIFD.LensMake] = "iSi Lens";
        exif[piexif.ExifIFD.Sharpness] = 777;
        exif[piexif.ExifIFD.LensSpecification] = [[1, 1], [1, 1], [1, 1], [1, 1]];
        gps[piexif.GPSIFD.GPSVersionID] = [7, 7, 7, 7];
        var exifObj = { "0th": zeroth, "Exif": exif, "GPS": gps };
        var exifStr = piexif.dump(exifObj);
        return piexif.insert(exifStr, imageData);
    };
    // get captured image and return it as FormData
    CameraComponent.prototype.saveImg2 = function (order) {
        return __awaiter(this, void 0, void 0, function () {
            var imageData, imageWithExif;
            var _this = this;
            return __generator(this, function (_a) {
                this.snackbar.open('Lagrer bilde...', 'Lukk', { duration: 1000 });
                imageData = this.hiddenCanvas.nativeElement.toDataURL('image/jpeg', 1.0);
                imageWithExif = this.addExif(imageData);
                fetch(imageWithExif).then(function (res) { return res.blob(); }).then(function (blob) {
                    var fd = new FormData();
                    fd.append('image', blob);
                    fd.append('orderUnid', order.unid);
                    fd.append('orderNum', order.orderNum);
                    fd.append('snum', order.snum);
                    fd.append("appVersion", _this.electronService.getVersion());
                    fd.append("user", _this.userService.userName || "");
                    fd.append("team", _this.userService.teamId);
                    _this.http.post(AppConfig.apiUrl + "/images", fd).pipe(take(1)).subscribe(function (res) {
                        _this.snackbar.open('Bilde lagret', 'Lukk', { duration: 1000 });
                        //this.dialogRef.close();
                    });
                });
                return [2 /*return*/];
            });
        });
    };
    CameraComponent.prototype.getCapturedImage = function () {
        var _this = this;
        var imageData = '';
        imageData = this.hiddenCanvas.nativeElement.toDataURL('image/jpeg', 1.0);
        var imageWithExif = this.addExif(imageData);
        fetch(imageWithExif).then(function (res) { return res.blob(); }).then(function (blob) {
            var fd = new FormData();
            fd.append('image', blob);
            fd.append("appVersion", _this.electronService.getVersion());
            fd.append("user", _this.userService.userName || "");
            fd.append("team", _this.userService.teamId);
            if (_this.currPos && _this.currPos.gpsData) {
                fd.append("startVegLatLon", _this.currPos.gpsData.lat + " " + _this.currPos.gpsData.lon);
                if (_this.currPos.roadRef) {
                    fd.append("startVegKortform", _this.currPos.roadRef.shortForm);
                }
            }
            var datasave = { imageData: imageData, formData: fd };
            _this.onImageSave.emit(datasave);
        });
    };
    return CameraComponent;
}());
export { CameraComponent };
