import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { GpsService } from '../../gps/gps.service';
import { DataService } from '../../data.service';
import { DataSource } from '@angular/cdk/collections';
import { MatTable, MatTableDataSource } from '@angular/material';
import {MatSort, Sort} from '@angular/material/sort';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../../user.service';
import { HeaderService } from '../header/header.service';
import { catchError, map } from 'rxjs/operators';
import { MediaService } from '../../shared/media.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Order } from '../../order';
import { ElectronService } from '../../electron.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('table', { static: false }) matTable: MatTable<any>
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  orders: any;
  displayedColumns: Array<string> = ['snum', 'allotmentHeaders', 'allotmentLength', 'rivLength', 'county', 'customer', 'customerContact','week', 'orderSelect'];
  loading = true;
  ordersSubscription: Subscription;
  isMobileView: boolean;
  step = 0;
  frmGroup: FormGroup;
  footerFormGroup: FormGroup;
  selectedType = 1;
  orderList: MatTableDataSource<any>;
  isElectron: boolean;
  setStep(index: number) {
    this.step = index;
  }
  constructor(private gpsService: GpsService,
    private dataService: DataService,
    private router: Router,
    public userService: UserService,
    private headerService: HeaderService,
    private mediaService: MediaService,
    private electronService: ElectronService) {
    this.mediaService.getMedia().subscribe(media => {
      this.isMobileView = media.isMobileView;
    })
    this.footerFormGroup = new FormGroup({});
    this.isElectron = this.electronService.isElectron();

  }

  ngOnInit() {
    this.headerService.resetNavItems();
    this.getOrders();
  }
  ngOnDestroy() {
    if (this.ordersSubscription) {
      this.ordersSubscription.unsubscribe();
    }
  }
 
  getOrders() {
    this.loading = true;
    if (this.selectedType == 1) {
      this.ordersSubscription = this.dataService.getOrders().pipe(map(data => data.sort((a: any, b: any) => a.sortKey - b.sortKey))).subscribe(orders => {
        this.orders = orders
        
        if (!this.displayedColumns.includes('modifiedDate')) this.displayedColumns.pop();
        if (!this.displayedColumns.includes('orderSelect')) this.displayedColumns.push('orderSelect') // to add the order select column when status is montering
        // this.orderList = new MatTableDataSource(orders);
        this.getOrdersTable(orders)
        this.loading = false;
      }, catchError((err) => {
        this.loading = false;
        return null;
      }));
    } else if (this.selectedType == 2) {
      this.ordersSubscription = this.dataService.getCompletedOrders().subscribe(orders => {
        this.orderList = new MatTableDataSource(orders);
        this.displayedColumns.pop(); // to remove the order select column when status is fullførte
        this.displayedColumns.push('modifiedDate')
        this.getOrdersTable(orders);
        this.loading = false;
      }, catchError((err) => {
        this.loading = false;
        return null;
      }));
    }
  }
  
  getOrdersTable(orderList) {
    this.orderList = new MatTableDataSource(orderList);
    this.orderList.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.orderList.filter = filterValue.trim().toLowerCase();
  }

  moveUp(i, row) {
    /* row.sortKey = i-1;
    const order = this.orders.filter(data => {
      if (data.snum == row.snum) return data
    });
    console.log(i, this.orders);
    this.orderList = new MatTableDataSource(this.orders); */
    if (i > 0) {
      console.log(i)
      const temp = this.orders[i - 1]
      this.orders[i - 1] = this.orders[i]
      this.orders[i] = temp;
      console.log(this.orders);
      this.getOrdersTable(this.orders);
    }
  }
  moveDown(i, row) {
    if (i < this.orders.length - 1) {
      console.log(i)
      const temp = this.orders[i + 1]
      this.orders[i + 1] = this.orders[i]
      this.orders[i] = temp;
      console.log(this.orders);
      this.getOrdersTable(this.orders);
    }
  }
  orderSelected(e) {
    console.log(e);
  }

  saveSortOrder() {
    this.orders.forEach((order, i) => {
      order.sortKey = i
    });
    const sortOrder = {};
    this.orders.forEach((order) => {
      sortOrder[order.orderNum] = order.sortKey;
    })
    console.log(this.orders, sortOrder);
    this.dataService.saveSortOrder(sortOrder).subscribe(res => {
      console.log({ res });
    })
  }

  onRowClick(row) {
    const urlTree = this.router.createUrlTree(['order/' + row.unid]);
    this.router.navigateByUrl(urlTree);
  }
  goToTimeSheetRegistration() {
    this.router.navigateByUrl('/timesheet');
  }
  goToInfoPage() {
    this.router.navigateByUrl('/info');
  }
  goToStoragePage() {
    this.router.navigateByUrl('/lager');
  }
  goToLocationMap() {
    this.router.navigateByUrl('/teammap');
  }
}
