import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../components/header/header.service';
import { StorageService } from './storage.service';
import { MediaService } from '../shared/media.service';
import { MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { catchError } from 'rxjs/operators';
import { StorageDetailsComponent } from './storage-details/storage-details.component';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { CreateNewStorageComponent } from './create-new-storage/create-new-storage.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss']
})
export class StorageComponent implements OnInit {
  storageFormGroup: FormGroup;
  storages: any;
  counties: any;
  displayedColumns: Array<string> = ['date','name','fylke','lag'];
  isMobileView: boolean;
  loading = true;
  storageSubscription: Subscription;
  filteredStorage: any;
  storageType = 1;
  county:String = "0" ;

  constructor(private headerService: HeaderService,
    private router: Router,
    private storageService: StorageService,
    private mediaService: MediaService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute) {
    this.mediaService.getMedia().subscribe( media => {
      this.isMobileView = media.isMobileView;
    });
    this.storageFormGroup = new FormGroup({
     fylkeSelect: new FormControl(),
     storageTypeSelect: new FormControl()
    })
   }

  ngOnInit() {
    this.headerService.addNavItem({
      route: `/lager`,
      label: `Lager`,
      queryParams: ''
    });
    this.getCounties();
    this.storageFormGroup.get('storageTypeSelect').setValue(1);
    this.storageFormGroup.get('fylkeSelect').valueChanges.subscribe(data => {
     this.county = data;
     this.getfilteredstorageData();
    })
    this.storageFormGroup.get('storageTypeSelect').valueChanges.subscribe(data => {
      this.storageType = data;
      this.storageFormGroup.get('fylkeSelect').setValue(0);
      this.getStorages();
      //this.getfilteredstorageData();
    })
    this.getStorages();

    const storageUnid = this.activatedRoute.snapshot.params.unid;
    if (storageUnid) {
      this.openStorage(storageUnid);
    }
  }

  getCounties() {
    this.storageService.getCounties().subscribe(data => {
      this.counties = data
    })
  }
  getfilteredstorageData() {
    this.filteredStorage = this.storages;
    let county = this.county.toString();
    console.log(this.filteredStorage);
    if(this.county != "0") {
      this.filteredStorage = this.storages.filter(function(storage){
        return storage.countyNumber == county;
      })
    }
    if(this.filteredStorage){
      this.filteredStorage = new MatTableDataSource(this.filteredStorage);
    }
  }

  getStorages() {
    this.loading = true;
    this.storageType = this.storageFormGroup.get('storageTypeSelect').value;
    if(this.storageType == 1) {
      this.storageSubscription = this.storageService.getOpenStorages().subscribe(data => {
        this.storages = data;
        this.filteredStorage = new MatTableDataSource(this.storages);
        this.loading= false;
      }, catchError( (err) => {
        this.loading = false; 
        return null;
      }))
    } else if(this.storageType == 2) {
      this.storageSubscription = this.storageService.getArkivStorages().subscribe(data => {
        this.storages = data;
        this.filteredStorage = new MatTableDataSource(this.storages);
        this.loading= false;
      }, catchError( (err) => {
        this.loading = false; 
        return null;
      }))
    }
  }
 createNewStorage() {
    const newStorageDialogRef = this.dialog.open ( CreateNewStorageComponent, {
      height: '80%',
      width: '90%',
      data: { },
     // disableClose: false
    });
    newStorageDialogRef.afterClosed().subscribe(result => {
      this.getStorages();
      if (result) {
          if (result && result.unid) {
            this.snackbar.open("Nytt storage er lagret", '', {duration: 5000});
          }
      }
    });
 }
  openStorage(unid: any) {
    const dialogRef =  this.dialog.open( StorageDetailsComponent, {
      height: '80%',
      width: '90%',
      data: { storageId: unid }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('storage dialog closed', result);
      if (result.do_refresh) {
        this.getStorages();
      }
    });
  }
  gotoStorageMap() {
    const urlTree = this.router.createUrlTree(['lager/map']);
    this.router.navigateByUrl(urlTree);
  }

  ngOnDestroy() {
    /* this.headerService.removeNavItem({
      route: `/lager`,
      label: `Lager`,
      queryParams: ''
    }) */
    if(this.storageSubscription) { this.storageSubscription.unsubscribe(); }
  }

}
