import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {AbstractDialogComponent} from '../../abstract-dialog/abstract-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SjaService } from '../../../sja/sja.service';
import { GpsService } from '../../../gps/gps.service';
import { GpsData } from '../../../gps-data';
import { RoadRefPipe } from '../../../road-ref.pipe';
import { take } from 'rxjs/operators';
import { UserService } from '../../../user.service';
import { Sign } from '../../../sja/sign';
import { SignLog } from '../../../sja/sign-log';
import { Position } from '../../../sja/position';
import { IsiRoadRef } from '../../../isi-road-ref';
import { MediaService } from '../../../shared/media.service';

@Component({
  templateUrl: './sja-add-sign-dialog.component.html',
  styleUrls: ['./sja-add-sign-dialog.component.scss']
})
export class SjaAddSignDialogComponent extends AbstractDialogComponent implements OnInit, OnDestroy {
  dialogFormGroup: FormGroup;
  signs: Array<any>;
  selectedSign;
  currentPos: {gpsData: GpsData, roadRef: IsiRoadRef};
  counter;
  title: string;
  buttonLabel: string;
  isMobile: boolean;

  constructor(protected dialogRef: MatDialogRef<AbstractDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private sjaService: SjaService,
              private gpsService: GpsService,
              private mediaService: MediaService,
              private roadRefPipe: RoadRefPipe) {
    super(dialogRef);
    this.dialogFormGroup = new FormGroup ({
      sign: new FormControl('', Validators.required),
      side: new FormControl(),
      meters: new FormControl(0, Validators.required),
      comment: new FormControl(),
      coordinates: new FormControl(),
      section: new FormControl(),
      subSection: new FormControl(),
      shortForm: new FormControl(),
      road: new FormControl()
    });
    this.mediaService.getMedia().subscribe(media => {
      this.isMobile = media.isMobileView;
    })
  }
  ngOnDestroy() {

  }
  ngOnInit() {
    this.sjaService.getSignsIcons().subscribe(signs => {
      this.signs = signs;
      if (this.data.type === 'edit') {
        this.title = 'Rediger skilt';
        this.buttonLabel = "Endre";
        for (let i = 0; i < this.signs.length; i++ ) {
          if (this.signs[i].value === this.data.sign.id) {
            this.dialogFormGroup.get("sign").setValue(this.signs[i]);
            break;
          }

        }
        this.dialogFormGroup.get("sign").setValue(this.data.sign.id);
        this.dialogFormGroup.get("side").setValue(this.data.sign.side);
        console.log("edit sidng: " , this.data.sign.log[this.data.sign.log.length - 1].position);
        this.dialogFormGroup.get("shortForm").setValue(this.data.sign.log[this.data.sign.log.length - 1].position.shortForm);
      } else if (this.data.type === 'new') {
        this.title = "Nytt skilt"
        this.buttonLabel = "Legg til"
        this.getCurrentGpsPos();
      }
    })
  }

  getCurrentGpsPos() {
    this.currentPos = this.gpsService.getCurrentPos();
    if (this.currentPos && this.currentPos.gpsData) {
      this.dialogFormGroup.get('coordinates').setValue([this.currentPos.gpsData.lat, this.currentPos.gpsData.lon]);
      const roadRef: IsiRoadRef = this.currentPos.roadRef;
      if (roadRef) {
        this.dialogFormGroup.get('meters').setValue(roadRef.meter);
        this.dialogFormGroup.get('section').setValue(roadRef.section);
        this.dialogFormGroup.get('subSection').setValue(roadRef.subSection);
        this.dialogFormGroup.get('shortForm').setValue(roadRef.shortForm);
        this.dialogFormGroup.get('road').setValue(roadRef.shortForm.split(" ")[0]);
      } else {
        this.dialogFormGroup.get('shortForm').setValue("");
        this.dialogFormGroup.get('section').setValue("");
        this.dialogFormGroup.get('road').setValue("");
      }
    }
  }

  confirm(event) {
    this.dialogRef.close(this.dialogFormGroup.value);
  }

}
