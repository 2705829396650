import { enableProdMode } from '@angular/core';
import { AppConfig } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (AppConfig.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory, {
    preserveWhitespaces: false
})
    .catch(function (err) { return console.error(err); });
