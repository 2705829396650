<div class="measure-container mat-typography">
  <h1 class="title">Ordre: {{order?.snum}} {{order?.location}} - Parsell {{parcel.title}} <span *ngIf="parcel?.invoiceType==='orderArticle'">(Ordreartikler)</span></h1>
  <h5 class="sub-title">Start: {{parcel?.startVegKortform}}, Slutt: {{parcel?.sluttVegKortform}}</h5>
  <div [formGroup]="formGroup" class="gpsSwitch">
    <mat-slide-toggle formControlName="gpsEnabled" (change)="disableGps($event)"><label> Manuell registrering</label></mat-slide-toggle>
  </div>
  <mat-card class="main-info" *ngIf="!gpsDisabled">
    <div id="stalites" class="satelite-quality">Satelitter</div>
    <div id="stalitesNum" class="satelite-quality">{{gpsData?.satelites}}</div>
    <div id="precission" class="satelite-quality">Nøyaktighet</div>
    <app-quality-bars id="precissionBars" [satQuality]="satQuality"></app-quality-bars>
    <div id="measurePoint" class="measure-info">Antall punkt</div>
    <div id="measurePointNum" class="measure-info">{{parcel?.points?.length}}</div>
    <div id="countPoint" class="measure-info">&nbsp;</div>
    <div id="countPointNum" class="measure-info">{{parcel?.verifyArticlesPoints?.length}}</div>
    <div id="length" class="measure-info">Sporets lengde</div>
    <div id="lengthMeter" class="measure-info">{{parcel?.distance?.toFixed(2) || 0}} m</div>
    <div id="lengthLast" class="measure-info">Avstand forrige punkt</div>
    <div id="lengthLastMeter" class="measure-info">{{distance?.toFixed(2)}} m</div>
    <div *ngIf="roadRef" id="roadName" class="measure-info">Veginformasjon</div>
    <div *ngIf="roadRef" id="roadKm" class="measure-info"> {{roadRef?.shortForm + 'm'}} {{roadRef?.streetName}}
    </div>
    <div id="onMapHtml">
      <div id="gutterStart">&nbsp;</div>
      <div id="mapControls">
        <div id="zoomIn" class="map-control-container first-map-control">
          <button mat-raised-button (click)="zoomIn($event)">
            <mat-icon>zoom_in</mat-icon>
          </button>
          <div class="border-divider">&nbsp;</div>
        </div>
        <div id="zoomOut" class="map-control-container">
          <button mat-raised-button (click)="zoomOut($event)">
            <mat-icon>zoom_out</mat-icon>
          </button>
          <div class="border-divider">&nbsp;</div>
        </div>
        <div id="myPosDiv" class="map-control-container">
          <button mat-raised-button (click)="goToMyLocation($event)">
            <mat-icon>my_location</mat-icon>
          </button>
          <div class="border-divider">&nbsp;</div>
        </div>
        <div id="allotmentTrackPos" class="map-control-container last-map-control">
          <button mat-raised-button (click)="goToTrackLocatoin($event)">
            <mat-icon>timeline</mat-icon>
          </button>
        </div>
      </div>
      <div id="gutterEnd">&nbsp;</div>
    </div>
    <div id="mapid" leaflet [leafletOptions]="options" (leafletMapReady)="onMapReady($event)">
    </div>
  </mat-card>
  <div id="articles">
    <mat-card>
      <h2>Artikler</h2>
      <mat-card-subtitle *ngIf="!accumulatedArticles || accumulatedArticles.length<=0">
        <span>Her kommer alle bekreftede monterings- og rivingsartikler på denne parsellen</span>
      </mat-card-subtitle>
      <mat-card-subtitle *ngIf="accumulatedArticles && accumulatedArticles.length>0">
        <span>Byggelengde {{buildLength}}m.</span>&nbsp;
        <div *ngIf="!gpsDisabled"><span>Sporets lengde {{parcel?.distance?.toFixed(2) || 0}}m</span>&nbsp;</div>
      </mat-card-subtitle>
      <div id="addArticleButtons">
        <button id="addDemolitionArticle" class="btn-demolition" type="button" mat-raised-button color="primary"
          (click)="addArticleClick($event)" *ngIf="accumulatedArticles.length > 0"
          [disabled]="parcelHeader?.state>=4 && parcel?.isComplete  ">Legg til artikkel</button>
        <button id="addArticle" type="btn-article" mat-raised-button color="primary"
          (click)="addCompositeArticleClick($event)" *ngIf="gpsDisabled && accumulatedArticles.length > 0"
          [disabled]="parcelHeader?.state>=4">Legg til strukturartikkel</button>
      </div>
      <label class="lbl-disable-composite-article" *ngIf="lastPoint">Kan ikke legge til flere strukturartikler fordi
        siste punkt er lagret</label>
      <label class="lbl-disable-composite-article" *ngIf="parcel.points.length<=0 && !gpsDisabled">Før vi kan legge til
        strukturartikkel må det registreres minst ett punkt</label>
      <div id="emptyRow"><br></div>
      <mat-card-content>
        <app-articles #measureArticles id="measure-articles" [articles]="accumulatedArticles"
          [(articleAdjustments)]="articleAdjustments" (articlesChangedEmitter)="onArticleChanged($event)"
          [(viewModeOnly)]="parcel.isComplete"></app-articles>
        <div *ngIf="compositeArticles && compositeArticles.length>0">
          <app-composite-articles [compositeArticlesSubject]="compositeArticlesSubject"
            [compositeArticles]="compositeArticles" [rtType]="selectedRailingType?.id"></app-composite-articles>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div id="comment" *ngIf="parcelHeader?.body">
    <mat-card>
      <h2>Kommentar</h2>
      <mat-card-subtitle>
        <span>{{parcelHeader?.body}}</span>
      </mat-card-subtitle>
    </mat-card>
  </div>


</div>
<div class="sticky-footer">
  <div class="placeholder"></div>
  <form [formGroup]="formGroup" #firstForm="ngForm">
    <button *ngIf="selectedPointMarker" class="btn-delete-point" color="accent" mat-raised-button
      (click)="deletePoint(selectedMeasuringPoint)" [disabled]="parcel?.points[0] === selectedMeasuringPoint">Slett
      valgt punkt</button>
    <div *ngIf="!gpsDisabled" class="txt-point-status">
      {{pointStatusTxt}}
    </div>
    <button *ngIf="parcel?.points?.length===0" type="button" class="btn-save success" color="primary" mat-raised-button
      (click)="openSavePointDialog()" [disabled]="!gpsDisabled && !gpsData || !allotmentLoaded  ||!isElectron">
      Start registrering av parsell &nbsp;
      <mat-icon *ngIf="!gpsDisabled" class="satelite-quality" [class.satelite-quality-1]="satQuality===0"
        [class.satelite-quality-1]="satQuality===1" [class.satelite-quality-2]="satQuality===2"
        [class.satelite-quality-3]="satQuality===3">settings_input_antenna</mat-icon>
    </button>
    <button *ngIf="!lastPoint && parcel?.points?.length>0" type="button" class="btn-save success" color="primary"
      mat-raised-button (click)="openSavePointDialog()" [disabled]="!gpsData">
      Legg til punkt &nbsp;
      <mat-icon class="satelite-quality" [class.satelite-quality-1]="satQuality===0"
        [class.satelite-quality-1]="satQuality===1" [class.satelite-quality-2]="satQuality===2"
        [class.satelite-quality-3]="satQuality===3">settings_input_antenna</mat-icon>
    </button>
    <button *ngIf="lastPoint && !parcel.isComplete" type="button" class="btn-save success" color="primary"
      mat-raised-button (click)="saveAndConfirmLength()" [disabled]="savingInProgress">
      <div class="button-content">
        Lagre og bekreft lengde
        <mat-spinner *ngIf="savingInProgress" [diameter]="20" color="accent"></mat-spinner>
      </div>
    </button>
    <button *ngIf="accumulatedArticles.length > 0  && gpsDisabled" class="btn-save success" color="primary"
      (click)="saveParsell()" mat-raised-button>
      <div class="button-content">
        Lagre
        <mat-spinner *ngIf="savingInProgress" [diameter]="20" color="accent"></mat-spinner>
      </div>
    </button>
    <button *ngIf="parcel.isComplete && (order?.status < 6)" type="button" class="btn-edit success" color="primary" mat-raised-button
      (click)="editParcel()">
      Rediger
    </button>
    <button *ngIf="parcel.isComplete" type="button" class="btn-save success" color="primary" mat-raised-button
      (click)="goToOrders()">
      Lukk
    </button>
  </form>
  <div class="placeholder"></div>
</div>
<app-road-information></app-road-information>
