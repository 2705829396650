<div class="home mat-typography">
      <div class="home-card">
            <mat-card>
                  <mat-card-header>
                        <mat-card-title>
                              Lager
                        </mat-card-title>
                        <button type="button" class="my-h2" mat-button (click) ="gotoStorageMap()">Vis i kart <mat-icon>map</mat-icon></button>
                  </mat-card-header>
                  <mat-card-content>
                        <div class="block">
                              <div class="row">
                                    <div class="grid1">
                                          <form class="dialog" [formGroup]="storageFormGroup">
                                                <mat-form-field appearance="fill">
                                                      <mat-label>Fylke</mat-label>
                                                      <mat-select formControlName='fylkeSelect'>
                                                            <mat-option [value]="0"> Alle </mat-option>
                                                            <mat-option *ngFor="let county of counties" [value]="county.number">
                                                                  {{county.name}}
                                                            </mat-option>
                                                      </mat-select>
                                                </mat-form-field>
                                          </form>
                                    </div>
                                    <div class="grid2">
                                          <form class="dialog" [formGroup]="storageFormGroup">
                                                <mat-form-field appearance="fill">
                                                      <mat-label> Status </mat-label>
                                                      <mat-select formControlName='storageTypeSelect' required>
                                                            <mat-option [value]="1"> Åpen </mat-option>
                                                            <mat-option [value]="2"> Arkivert </mat-option>
                                                      </mat-select>
                                                </mat-form-field>
                                          </form>
                                    </div>
                                    <div class="grid3">
                                          <button type="button" mat-raised-button color="primary" (click)= "createNewStorage()"> Nytt Lager </button>
                                    </div>
                              </div>
                        </div>
                        <mat-table #table [dataSource]="filteredStorage" [class.isMobile]="isMobileView">
                             <ng-container matColumnDef="date">
                                    <mat-header-cell *matHeaderCellDef> Oppdatert </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                          <span class="mobile-label">Oppdatert:</span>
                                          {{ element?.created | date:'dd.MM.yyyy' }} 
                                    </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef> Navn </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                          <span class="mobile-label">Navn:</span>
                                          {{ element?.subject }}
                                    </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="fylke" >
                                    <mat-header-cell *matHeaderCellDef> Fylke </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                          <span class="mobile-label">Fylke:</span>
                                          {{ element?.countyName }}
                                    </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="lag">
                                    <mat-header-cell *matHeaderCellDef> Lag </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                          <span class="mobile-label">Lag:</span>
                                          {{ element?.teamId }}
                                    </mat-cell>
                              </ng-container>
                              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                              <mat-row *matRowDef="let row; columns: displayedColumns;" (click) = "openStorage(row?.unid)"></mat-row> 
                        </mat-table> 
                  </mat-card-content>
            </mat-card>
      </div>
</div>
