import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CompositeArticleDataSource } from '../../composite-article-data-source';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CompositeArticle } from '../../composite-article';
import { DataService } from '../../data.service';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { MatTable, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-composite-articles',
  templateUrl: './composite-articles.component.html',
  styleUrls: ['./composite-articles.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class CompositeArticlesComponent implements OnInit, OnChanges {
  displayedColumns = ['name'];
  expandedElement: any;
  dataSource: CompositeArticleDataSource = new CompositeArticleDataSource();
  @Input()
  compositeArticlesSubject:Subject<Array<CompositeArticle>>;

  @Input() compositeArticles: Array<any>;
  mergedCompositeArticles: Array<CompositeArticle>;
  @Input() rtType: string;
  @ViewChild('compositeArticleTable', { static: false }) matTable: MatTable<CompositeArticle>;
  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.compositeArticlesSubject.subscribe(event => {
      this.compositeArticles = event;
      console.log(event);
      this.updateCompositeArticles();
    });
  }
  ngOnDestroy() {
    this.compositeArticlesSubject.unsubscribe();
  }
  isExpansionDetailRow = (i, row) => {
    return row.hasOwnProperty('detailRow');
  }
  selectMenuItem(row: any) {
    if (row === this.expandedElement) {
      this.expandedElement = null;
    } else if (row.id) {
      row.quantity = row.quantity || 1;
      if (!row.subItems || row.subItems.length <= 0) {
        this.dataService.getArticlesFromCompositeArticle(this.rtType, row.num, row.selectedQuantity).subscribe(res => {
          row.subItems = res;
        });
      }
      this.expandedElement = row;
    }
  }
  ngOnChanges(simpleChanges: SimpleChanges) {
    this.updateCompositeArticles();
  }
  private updateCompositeArticles() {
    this.dataSource = new CompositeArticleDataSource();
    this.mergedCompositeArticles = this.mergeCompositeArticles(this.compositeArticles);
    this.dataSource.data = this.mergedCompositeArticles;
  }
  private mergeCompositeArticles(compositeArticles: Array<CompositeArticle>) {
    const tmpMap: { [key: string]: { compositeArticle: CompositeArticle, quantity: number } } = {};
    const mergedArticles: Array<CompositeArticle> = [];
    if(compositeArticles){
      for (const ca of compositeArticles) {
        const mergedCa = Object.assign({}, ca); // copy instead of changing the reference from parent component.
        if (tmpMap.hasOwnProperty(mergedCa.num)) {
          mergedCa.selectedQuantity = mergedCa.selectedQuantity || 1;
          tmpMap[mergedCa.num].quantity = Number(tmpMap[mergedCa.num].quantity) + Number(mergedCa.selectedQuantity);
        } else {
          mergedCa.selectedQuantity = mergedCa.selectedQuantity || 1;
          tmpMap[mergedCa.num] = { compositeArticle: mergedCa, quantity: mergedCa.selectedQuantity };
        }
      }
      for (const key in tmpMap) {
        if (tmpMap.hasOwnProperty(key)) {
          const mergedCa = tmpMap[key].compositeArticle;
          mergedCa.selectedQuantity = tmpMap[key].quantity;
          mergedArticles.push(mergedCa);
        }
      }
    }
    return mergedArticles;
  }
}
