<mat-form-field>
  <mat-select id="article-picker" panelClass="article-picker"
    placeholder="{{placeholder?placeholder:typeOfArticle==='compositeArticle'?'Velg strukturartikkel':'Velg artikkel'}}"
    [formControl]="articleFormControl" required (selectionChange)="onChanged($event)" #singleSelect>
    <mat-option>
      <ngx-mat-select-search [formControl]="articleFilterCtrl" placeholderLabel="Velg artikkel"
        i18n-placeholderLabel clearSearchInput noEntriesFoundLabel="'ingen angitt'" i18n-noEntriesFoundLabel>
      </ngx-mat-select-search>
    </mat-option>
    <ng-container *ngFor="let article of filteredArticles | async">
      <ng-container *ngIf="(includeCategories.length===0 || includeCategories.includes(article.category)) &&
                  !excludeCategories.includes(article.category)">
       
        <mat-option  [value]="article">
          {{ article.id }} - {{ article.name }}
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-select>
  <mat-error *ngIf="articleFormControl.invalid">Vennligst velg en artikkel fra listen</mat-error>
</mat-form-field>