<mat-table *ngIf="articles && dataSource.data.length>0" [dataSource]="dataSource">
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef>Artikkel ID</mat-header-cell>
    <mat-cell *matCellDef="let article" [class.smaller-cell]="smallerCell['id']">
      <span class="mobile-label">Artikkel ID:</span>
      {{article.id}} {{article.name}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="length">
    <mat-header-cell *matHeaderCellDef>Lengde</mat-header-cell>
    <mat-cell *matCellDef="let article">
      <span class="mobile-label">Lengde:</span>
      {{(article.id[0] === '1')?'':article.length}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="quantitySuggested">
    <mat-header-cell *matHeaderCellDef>Foreslått antall</mat-header-cell>
    <mat-cell *matCellDef="let article">
      <span class="mobile-label">Foreslått antall:</span>
      {{article.quantitySuggested}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="quantityUsed">
    <mat-header-cell *matHeaderCellDef>Herav brukte</mat-header-cell>
    <mat-cell *matCellDef="let article">
      <span class="mobile-label">Herav brukte:</span>
      <mat-form-field class="article-number-field" *ngIf="article && article.id && articleAdjustments[article.id] && editable && !article.humanWork">
        <input type="number"
               appCustomIncDecBtns
               useFiniteNumbers="true"
               matInput
               [formControl]="formControlMap[article.id].usedControl"
               (change)="adjustArticle($event, article)">
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="quantityTotal">
    <mat-header-cell *matHeaderCellDef>Justert/Totalt</mat-header-cell>
    <mat-cell *matCellDef="let article">
      <span class="mobile-label">Justert/Totalt:</span>
      <mat-form-field class="article-number-field" *ngIf="article && article.id && articleAdjustments[article.id] && editable">
        <input type="number"
        matInput
        appCustomIncDecBtns
        useFiniteNumbers="true"
        [formControl]="formControlMap[article.id].totalControl"
        (change)="adjustArticle($event, article)">
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
