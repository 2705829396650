import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { AbstractDialogComponent } from '../../abstract-dialog/abstract-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../data.service';
import { UserService } from '../../../user.service';

@Component({
  selector: 'app-create-new-proposal',
  templateUrl: './create-new-proposal.component.html',
  styleUrls: ['./create-new-proposal.component.scss']
})
export class CreateNewProposalComponent extends AbstractDialogComponent implements OnInit {

  dialogFormGroup: FormGroup;
  //UserName: String;
  departments: Array<string> = [] ;
  data: any;
  confirm(event: any): void {
    throw new Error("Method not implemented.");
  }

  constructor(
    protected dialogRef: MatDialogRef<AbstractDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) data: any, 
    private dataService: DataService,
    private user: UserService ){
    super(dialogRef);
    this.data = data;
    this.dialogFormGroup = new FormGroup({
      subject: new FormControl('', Validators.required),
      department: new FormControl('', Validators.required),
      body: new FormControl('')
    });
  }

  ngOnInit() {
    this.dataService.getEmployeeDepartments().pipe().subscribe(depts => {
      this.departments = depts;
      this.departments.push("Andre(eksterne)");
    });
    
  }
   
  saveProposal(event: any): void {
    const proposalDate = this.dialogFormGroup.value;
    if(proposalDate) {
      this.dataService.SaveProposal(proposalDate).subscribe (proposal => {
        this.dialogRef.close(proposal.data);
        })
    }
  }

}
