var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatTableDataSource, MatStepper } from '@angular/material';
import { DataService } from '../../data.service';
import { MeasuringPoint } from '../../measuring-point';
import { MeasureService } from '../../measure.service';
import { forkJoin, Subject } from 'rxjs';
import { RailingCategoriesSelectComponent } from '../railing-categories-select/railing-categories-select.component';
import { SelectArticleFormComponent } from '../select-article-form/select-article-form.component';
var SavePointDialogComponent = /** @class */ (function (_super) {
    __extends(SavePointDialogComponent, _super);
    function SavePointDialogComponent(dialogRef, data, dataService, measureService, snackbar) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.dataService = dataService;
        _this.measureService = measureService;
        _this.snackbar = snackbar;
        _this.hostClass = true;
        _this.isMeasuring = false;
        _this.isDemobilizing = false;
        _this.addingManualArticle = false;
        _this.addingCompositeArticle = false;
        _this.addArticleState = "inactive";
        _this.hideArticleState = "active";
        _this.savePointTxt = "Lagre";
        _this.tmpCalculatedArticles = new MatTableDataSource();
        _this.pointsToCalculate = [];
        _this.articleFormControlName = "articleFormControl";
        _this.showArticles = false;
        _this.containsMeasuringPoint = false;
        _this.buildLength = 0;
        _this.demolitionLength = 0;
        _this.trackLength = 0;
        _this.childFormControlName = "manualArticleForm";
        _this.compositeArticlesSubject = new Subject();
        _this.compositeArticles = []; // used for displaying all composite articles (from `data` and `tmpPoint`)
        _this.tmpNewCompoisteArticles = []; // used to keep track of added composite articles inside this dialog
        _this.tmpNewArticles = []; // used to keep track of added articles inside this dialog
        _this.directionChanged = false;
        _this.endIsSelected = false;
        _this.isCalculating = false;
        _this.steps = [
            {
                label: 'Startende',
                endType: ''
            },
            {
                label: 'Målepunkt',
                endType: ''
            },
            {
                label: 'Sluttende',
                endType: ''
            }
        ];
        _this.parsellStatus = _this.data.parsellStatus;
        _this.isMeasuring = _this.data.isMeasuringState;
        _this.isDemobilizing = _this.data.isDemobilizingState;
        _this.invoiceType = _this.data.parcel.invoiceType;
        console.log('invoiceType', _this.data);
        if (_this.data.parcel && _this.data.parcel.points && _this.data.parcel.points.length === 0) {
            _this.isMeasuring = false;
            _this.isDemobilizing = false;
            _this.data.typeOfPoint = null;
        }
        if (_this.data.parcel && _this.data.parcel.points && _this.data.parcel.points.length > 0) {
            // get start end type
            // find first measure point
            if (_this.data.parcel.stopEndFirst) {
                _this.changeDirection(false);
            }
            _this.data.parcel.points.some(function (point) {
                if (point.state === 1 || point.state === 2) {
                    point.compositeArticles.forEach(function (sa) {
                        if (sa.category.toUpperCase() === 'ENDE') {
                            _this.steps[0].endType = sa.name;
                        }
                    });
                    return true;
                }
            });
        }
        _this.isiRoadRef = data.isiRoadRef;
        _this.lengthSinceLastCountingPoint = _this.data.lengthSinceLastCountingPoint;
        _this.data.demolitionType = _this.data.demolitionType || { id: '' };
        _this.data.rtType = _this.data.rtType || { id: '' };
        _this.tmpArticleAdjustments = data.tmpArticleAdjustments;
        _this.measureSwitch = new FormControl({
            value: _this.isMeasuring,
            disabled: _this.data.rtType.id ? false : true
        }, []);
        _this.changeDirectionControl = new FormControl({
            value: _this.directionChanged
        });
        _this.demolitionSwitch = new FormControl({
            value: _this.isDemobilizing,
            disabled: _this.data.demolitionType.id ? false : true
        }, []);
        _this.showArticlesCheckbox = new FormControl(_this.showArticles, []);
        _this.calcLengthInput = new FormControl(_this.lengthCalculation, [Validators.required]);
        _this.quantityFormControl = new FormControl(1, [Validators.min(1), Validators.required]);
        _this.addArticleFormGroup = new FormGroup({
            'quantityFormControl': _this.quantityFormControl
        });
        _this.dialogFormGroup = new FormGroup({
            'measureSwitch': _this.measureSwitch,
            'demolitionSwitch': _this.demolitionSwitch,
            'showArticlesCheckbox': _this.showArticlesCheckbox,
            'changeDirectionControl': _this.changeDirectionControl
        });
        _this.updateSavePoint();
        if (!_this.isDemobilizing && !_this.isMeasuring && _this.data.parcel.points.length > 0) {
            _this.calculateArticles();
        }
        return _this;
    }
    SavePointDialogComponent.prototype.updateStartOrEndDialogText = function () {
        var points = this.data.parcel.points;
        if (points && this.tmpPoint &&
            (points.length === 0 || (points.length > 0 && !this.isDemobilizing && !this.isMeasuring))) {
            var allotmentHeader = this.measureService.getAllotmentHeader(this.data.order, this.data.parcel.unid);
            if (allotmentHeader) {
                if (this.measureService.isPointClosestToEndOfAllotment(this.tmpPoint, allotmentHeader)) {
                    if (this.parsellStatus === 1) {
                        this.data.parcel.stopEndFirst = true;
                        this.changeDirection(false);
                    }
                }
                else {
                    if (this.parsellStatus === 1) {
                        this.directionChanged = false;
                    }
                }
                if (this.parsellStatus !== 1) {
                    this.changeDirectionControl.disable();
                }
            }
        }
    };
    SavePointDialogComponent.prototype.setStepperIndex = function () {
        switch (this.parsellStatus) {
            case 1:
                this.stepper.selectedIndex = 0;
                break;
            case 2:
                this.stepper.selectedIndex = 1;
                break;
            case 3:
                if (this.stepper) {
                    this.stepper.selectedIndex = 2;
                    if (this.stepper.selected) {
                        this.stepper.selected.completed = true;
                        this.stepper.selected.editable = false;
                    }
                }
                break;
        }
    };
    SavePointDialogComponent.prototype.ngOnInit = function () {
    };
    SavePointDialogComponent.prototype.ngOnDestroy = function () {
        if (this.calculateArticlesSubscription) {
            this.calculateArticlesSubscription.unsubscribe();
        }
    };
    SavePointDialogComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.setStepperIndex();
        });
        if (this.data && this.data.parcel && this.data.parcel.points) {
            this.initRailingTypeSelectState();
        }
    };
    SavePointDialogComponent.prototype.initRailingTypeSelectState = function () {
        var _this = this;
        setTimeout(function () {
            for (var _i = 0, _a = _this.data.parcel.points; _i < _a.length; _i++) {
                var p = _a[_i];
                if (p.state === 0 /* DEMOLITION_POINT */) {
                    _this.demolitionSelectComponent.formGroup.disable({ onlySelf: false });
                }
                else if (p.state === 2 /* MEASURE_POINT */) {
                    _this.mountingSelectComponent.formGroup.disable({ onlySelf: false });
                }
                else {
                    _this.demolitionSelectComponent.formGroup.disable({ onlySelf: false });
                    _this.mountingSelectComponent.formGroup.disable({ onlySelf: false });
                }
            }
        });
    };
    SavePointDialogComponent.prototype.confirm = function (event, articles) {
        var isLastPoint = !this.isMeasuring && !this.isDemobilizing;
        if (!articles && isLastPoint) {
            articles = this.tmpCalculatedArticles.data;
        }
        if (!this.isMeasuring && this.trackContainsMeasuringPoint(this.data.points)) { // assumes that the endArticle is added
            this.data.endArticleAdded = true;
        }
        this.data.parcel.stopEndFirst = this.directionChanged;
        if (!this.tmpPoint.isiRoadRef) {
            this.tmpPoint.isiRoadRef = this.isiRoadRef;
        }
        var resData = {
            type: SavePointDialogComponent,
            parsellStatus: this.parsellStatus,
            typeOfPoint: this.data.typeOfPoint,
            gpsData: this.data.gpsData,
            gpsQuality: this.data.gpsQuality,
            isLastPoint: isLastPoint,
            articles: articles,
            rtType: this.data.rtType,
            demolitionType: this.data.demolitionType,
            tmpArticleAdjustments: this.tmpArticleAdjustments,
            tmpPoint: this.tmpPoint,
            endArticleAdded: this.data.endArticleAdded,
            isMeasuringState: this.isMeasuring,
            isDemobilizingState: this.isDemobilizing
        };
        this.dialogRef.close(resData);
    };
    ;
    SavePointDialogComponent.prototype.measureSwitchChange = function (event) {
        this.isMeasuring = !this.isMeasuring;
        if (this.previousParsellState) {
            if (this.previousParsellState === 10) {
                this.parsellStatus = undefined;
            }
            else {
                this.parsellStatus = this.previousParsellState;
            }
            this.previousParsellState = undefined;
        }
        this.updateSavePoint();
        this.updateStartOrEndDialogText();
        this.showOrHideArticles();
        this.setStepperIndex();
        this.addStartOrEndCompositeArticle();
        this.updateCompositeArticles();
        this.calculateArticles();
        if (this.parsellStatus === 3) {
            this.showArticlesCheckbox.setValue(true);
            this.showArticlesChange("measure");
        }
    };
    SavePointDialogComponent.prototype.demolitionSwitchChange = function (event) {
        this.isDemobilizing = !this.isDemobilizing;
        if (this.previousDemoParsellState && this.parsellStatus !== 3 /* MEASURING_END */) {
            if (this.previousDemoParsellState === 10) {
                this.parsellStatus = undefined;
            }
            else {
                this.parsellStatus = this.previousDemoParsellState;
            }
            this.previousParsellState = undefined;
        }
        this.updateSavePoint();
        this.calculateArticles();
        this.updateStartOrEndDialogText();
        this.showOrHideArticles();
        if (this.parsellStatus === 3) {
            this.showArticlesCheckbox.setValue(true);
            this.showArticlesChange("measure");
        }
        else if (this.parsellStatus === 1) {
            this.addStartOrEndCompositeArticle();
            this.updateCompositeArticles();
        }
    };
    SavePointDialogComponent.prototype.changeDirection = function (doCheck) {
        var step2type = this.steps[2].endType;
        this.steps[2].endType = this.steps[0].endType;
        this.steps[0].endType = step2type;
        this.steps = this.steps.reverse();
        this.directionChanged = !this.directionChanged;
        this.checkStartOrEndCompositeArticles(doCheck); // false default
    };
    SavePointDialogComponent.prototype.showOrHideArticles = function () {
        if (this.tmpCalculatedArticles &&
            this.data.parcel.points.length > 0 &&
            (this.showArticles || !this.isDemobilizing && !this.isMeasuring)) {
            this.hideArticleState = "inactive";
        }
        else {
            this.hideArticleState = "active";
        }
    };
    SavePointDialogComponent.prototype.showArticlesChange = function (event) {
        this.showArticles = this.showArticlesCheckbox.value;
        this.showOrHideArticles();
        var doCheck = true;
        if (this.endIsSelected) {
            doCheck = false;
        }
        this.checkStartOrEndCompositeArticles(doCheck);
        if (event !== 'measure') {
            // do not calculate when event come from measureSwitch change
            this.calculateArticles();
        }
    };
    SavePointDialogComponent.prototype.calcLengthChange = function (event) {
        this.updateSavePoint();
        this.calculateArticles();
    };
    SavePointDialogComponent.prototype.switchClick = function (switchId) {
        if (switchId === 'demolition') {
            if (this.demolitionSwitch.disabled) {
                this.snackbar.open("Vennligst velg en rivingstype 🏗️", "", { duration: 5000 });
            }
        }
    };
    SavePointDialogComponent.prototype.onChildStartOrEndArticleChanged = function (article) {
        if ('category' in article && typeof article.category === "string") {
            var articleCpy = Object.assign({}, article);
            articleCpy.selectedQuantity = 1;
            this.startOrEndArticle = articleCpy;
            this.checkStartOrEndCompositeArticles(false);
            this.addStartOrEndCompositeArticle();
            this.calculateArticles();
            this.endIsSelected = true;
        }
    };
    SavePointDialogComponent.prototype.updateSavePoint = function () {
        this.previousParsellState = this.parsellStatus;
        if (this.previousParsellState === undefined) {
            this.previousParsellState = 10;
        }
        this.previousDemoParsellState = this.parsellStatus;
        if (this.previousDemoParsellState === undefined) {
            this.previousDemoParsellState = 10;
        }
        this.tmpPoint = new MeasuringPoint(this.data.gpsData, this.data.typeOfPoint);
        // different cases
        switch (this.parsellStatus) {
            case 0 /* DEMOLITION_BEFORE_MEASURING */:
                if (this.isMeasuring && this.isDemobilizing) {
                    this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                    this.parsellStatus = 1 /* MEASURING_START */;
                }
                else if (this.isMeasuring && this.tmpPoint.state === 0 /* DEMOLITION_POINT */) {
                    this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                    this.parsellStatus = 1 /* MEASURING_START */;
                }
                else if (this.isMeasuring) {
                    this.data.typeOfPoint = 2 /* MEASURE_POINT */;
                    this.parsellStatus = 1 /* MEASURING_START */;
                }
                else if (this.isDemobilizing) {
                    this.data.typeOfPoint = 0 /* DEMOLITION_POINT */;
                }
                this.savePointTxt = "Lagre punkt";
                break;
            case 1 /* MEASURING_START */:
                if (this.isMeasuring && this.isDemobilizing) {
                    this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                    this.parsellStatus = 5 /* MEASURING_AND_DEMOLITION */;
                    this.previousDemoParsellState = this.parsellStatus;
                    this.previousParsellState = this.parsellStatus;
                }
                else if (!this.isDemobilizing && this.isMeasuring && this.tmpPoint.state === 1 /* DEMOLITION_AND_MEASURE_POINT */) {
                    this.savePointTxt = "Lagre punkt";
                    if (this.trackContainsMeasuringPoint(this.data.points)) {
                        this.parsellStatus = 2 /* MEASURING */;
                        this.data.typeOfPoint = 2 /* MEASURE_POINT */;
                    }
                    else {
                        this.parsellStatus = 1 /* MEASURING_START */;
                        this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                    }
                }
                else if (!this.isDemobilizing && this.tmpPoint.state === 1 /* DEMOLITION_AND_MEASURE_POINT */) {
                    this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                    this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
                }
                else if (this.isMeasuring && !this.isDemobilizing) {
                    this.data.typeOfPoint = 2 /* MEASURE_POINT */;
                    this.savePointTxt = "Lagre punkt";
                    this.parsellStatus = 2 /* MEASURING */;
                }
                else if (this.isDemobilizing) {
                    this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                    this.savePointTxt = "Lagre punkt";
                    this.parsellStatus = 4 /* DEMOLITION_AFTER_MEASURING */;
                }
                else if (!this.isDemobilizing && !this.isMeasuring) {
                    // keep current point state
                    this.data.typeOfPoint = this.tmpPoint.state;
                    this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
                    this.parsellStatus = 3 /* MEASURING_END */;
                }
                break;
            case 2 /* MEASURING */:
                if (!this.isMeasuring && this.isDemobilizing) {
                    this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                    this.parsellStatus = 6 /* MEASURING_END_AND_DEMOLITION */;
                    this.savePointTxt = "Lagre punkt";
                }
                else if (!this.isMeasuring && !this.isDemobilizing) {
                    this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
                    this.parsellStatus = 3 /* MEASURING_END */;
                }
                else if (this.isDemobilizing && this.isMeasuring) {
                    this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                    this.parsellStatus = 5 /* MEASURING_AND_DEMOLITION */;
                }
                else if (!this.isDemobilizing && this.isMeasuring) {
                    this.data.typeOfPoint = 2 /* MEASURE_POINT */;
                    this.parsellStatus = 2 /* MEASURING */;
                }
                break;
            case 5 /* MEASURING_AND_DEMOLITION */:
                this.savePointTxt = "Lagre punkt";
                if (!this.isMeasuring && !this.isDemobilizing) {
                    this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
                    this.parsellStatus = 3 /* MEASURING_END */;
                    this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                }
                else if (this.isMeasuring && !this.isDemobilizing) {
                    this.parsellStatus = 2 /* MEASURING */;
                }
                else if (!this.isMeasuring && this.isDemobilizing) {
                    this.parsellStatus = 6 /* MEASURING_END_AND_DEMOLITION */;
                }
                break;
            case 3 /* MEASURING_END */:
                if (!this.isMeasuring && !this.isDemobilizing) {
                    if (this.tmpPoint.state === 1 /* DEMOLITION_AND_MEASURE_POINT */
                        && this.previousParsellState !== 3 /* MEASURING_END */) {
                        this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                        this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
                        this.parsellStatus = 4 /* DEMOLITION_AFTER_MEASURING */;
                    }
                    else if (this.parsellStatus === 3 /* MEASURING_END */
                        && this.tmpPoint.state === 1 /* DEMOLITION_AND_MEASURE_POINT */) {
                        this.data.typeOfPoint = 2 /* MEASURE_POINT */;
                        this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
                    }
                    else if (this.parsellStatus === 3 /* MEASURING_END */
                        && this.tmpPoint.state !== 0 /* DEMOLITION_POINT */) {
                        this.parsellStatus = 4 /* DEMOLITION_AFTER_MEASURING */;
                        this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
                    }
                    else {
                        this.data.typeOfPoint = 2 /* MEASURE_POINT */;
                        this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
                    }
                }
                else if (!this.isMeasuring && this.isDemobilizing) {
                    if (this.previousParsellState === 1 /* MEASURING_START */) {
                        this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                        this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
                    }
                    else if (this.previousParsellState === 3 /* MEASURING_END */) {
                        this.parsellStatus = 6 /* MEASURING_END_AND_DEMOLITION */;
                        this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                        this.savePointTxt = "Lagre punkt";
                    }
                    else {
                        this.data.typeOfPoint = 0 /* DEMOLITION_POINT */;
                        this.savePointTxt = "Lagre punkt";
                        if (this.tmpPoint.state === 2 /* MEASURE_POINT */
                            && this.previousParsellState !== 3 /* MEASURING_END */) {
                            this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                            this.parsellStatus = 3 /* MEASURING_END */;
                        }
                        else {
                            this.parsellStatus = 3 /* MEASURING_END */;
                            this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                        }
                    }
                }
                else if (this.isMeasuring && !this.isDemobilizing) {
                }
                else if (this.isMeasuring && this.isDemobilizing) {
                }
                break;
            case 4 /* DEMOLITION_AFTER_MEASURING */:
                if (!this.isDemobilizing) {
                    this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
                }
                else {
                    this.savePointTxt = "Lagre punkt";
                }
                this.parsellStatus = 4 /* DEMOLITION_AFTER_MEASURING */;
                this.data.typeOfPoint = 0 /* DEMOLITION_POINT */;
                break;
            case 6 /* MEASURING_END_AND_DEMOLITION */:
                if (this.isDemobilizing) {
                    this.savePointTxt = "Lagre punkt";
                }
                else {
                    this.savePointTxt = "Legg til siste punkt og foreslåtte artikler";
                }
                this.parsellStatus = 4 /* DEMOLITION_AFTER_MEASURING */;
                this.data.typeOfPoint = 0 /* DEMOLITION_POINT */;
                break;
            case undefined:
                if (this.isMeasuring && this.isDemobilizing) {
                    this.data.typeOfPoint = 1 /* DEMOLITION_AND_MEASURE_POINT */;
                    this.parsellStatus = 1 /* MEASURING_START */;
                }
                else if (this.isMeasuring) {
                    this.data.typeOfPoint = 2 /* MEASURE_POINT */;
                    this.parsellStatus = 1 /* MEASURING_START */;
                }
                else if (this.isDemobilizing) {
                    this.data.typeOfPoint = 0 /* DEMOLITION_POINT */;
                    this.parsellStatus = 0 /* DEMOLITION_BEFORE_MEASURING */;
                }
                this.savePointTxt = "Lagre første punkt";
                break;
        }
        this.tmpPoint.isiRoadRef = this.isiRoadRef;
        var isLastPoint = this.parsellStatus === 3;
        var isEnd = false;
        if ((isLastPoint || this.directionChanged) && !(isLastPoint && this.directionChanged)) {
            isEnd = true;
            //this.endIsSelected = false;
        }
        if (!this.endIsSelected) {
            this.startOrEndArticle = this.measureService.
                findNearestStartEndCompositeArticle(this.data.order, this.tmpPoint, this.data.parcel.unid, isEnd);
        }
        else {
            // this.checkStartOrEndCompositeArticles(false);
            this.addStartOrEndCompositeArticle();
        }
        if (this.tmpNewCompoisteArticles.length > 0) {
            this.tmpPoint.compositeArticles = [].concat(this.tmpNewCompoisteArticles);
        }
        this.tmpPoint.state = this.data.typeOfPoint;
        this.containsMeasuringPoint = this.trackContainsMeasuringPoint(this.data.points);
    };
    SavePointDialogComponent.prototype.checkStartOrEndCompositeArticles = function (doCheck) {
        /** COPYED FROM updateSavePoint */
        this.tmpPoint = new MeasuringPoint(this.data.gpsData, this.data.typeOfPoint);
        var isLastPoint = this.parsellStatus === 3;
        var isEnd = false;
        if ((isLastPoint || this.directionChanged) && !(isLastPoint && this.directionChanged)) {
            isEnd = true;
        }
        if (doCheck) {
            this.startOrEndArticle = this.measureService.
                findNearestStartEndCompositeArticle(this.data.order, this.tmpPoint, this.data.parcel.unid, isEnd);
        }
        if (this.startEndArticleSelect) {
            this.startEndArticleSelect.setValue(this.startOrEndArticle);
        }
        if (this.tmpNewCompoisteArticles) {
            this.tmpPoint.compositeArticles = [].concat(this.tmpNewCompoisteArticles);
        }
        if (doCheck) {
            this.addStartOrEndCompositeArticle();
        }
        /** END COPY */
    };
    SavePointDialogComponent.prototype.addArticlesAndPoint = function (event) {
        this.confirm(event, this.tmpCalculatedArticles.data);
    };
    SavePointDialogComponent.prototype.calculateArticles = function () {
        var _this = this;
        this.isCalculating = true;
        this.pointsToCalculate = [];
        for (var _i = 0, _a = this.data.points; _i < _a.length; _i++) {
            var originalPoint = _a[_i];
            var pointCopy = Object.assign({}, originalPoint);
            if (pointCopy.verifyArticlesPoint) {
                pointCopy.compositeArticles = [];
            }
            this.pointsToCalculate.push(pointCopy); // make a copy of the points instead of changing reference.
        }
        this.pointsToCalculate.push(this.tmpPoint);
        this.lengthCalculation = this.calcDistsanceBetweenPoints(this.pointsToCalculate);
        this.calcLengthInput.setValue(this.lengthCalculation.toFixed(2));
        var splitPoints = this.splitPoints(this.pointsToCalculate);
        var requests = this.createCalculatedArticlesRequests(splitPoints, this.data.rtType.id, this.data.demolitionType.id, this.invoiceType, this.data.parcel.unid).requests;
        if (this.calculateArticlesSubscription) {
            this.calculateArticlesSubscription.unsubscribe();
        }
        this.calculateArticlesSubscription = forkJoin(requests).subscribe(function (res) {
            var articlesMap = _this.measureService.createArticlesMap(res);
            var articles = [];
            for (var key in articlesMap) {
                if (articlesMap[key]) {
                    articles.push(articlesMap[key]);
                }
            }
            _this.buildLength = _this.measureService.getBuildLength(articles);
            _this.demolitionLength = _this.getDemolitionLength(_this.pointsToCalculate);
            _this.trackLength = +_this.getTrackLength(_this.pointsToCalculate);
            _this.tmpCalculatedArticles = new MatTableDataSource(articles);
            _this.isCalculating = false;
        }, function (error) {
            _this.isCalculating = false;
        });
        this.updateCompositeArticles();
    };
    SavePointDialogComponent.prototype.onDemolitionTypeChanged = function (railingType) {
        this.data.demolitionType = railingType;
        if (railingType.id) {
            this.demolitionSwitch.enable();
        }
        else {
            this.demolitionSwitch.disable();
        }
    };
    SavePointDialogComponent.prototype.onRtTypeChanged = function (railingType) {
        this.data.rtType = railingType;
        if (railingType.id) {
            this.measureSwitch.enable();
        }
        else {
            this.measureSwitch.disable();
        }
    };
    /**
     * Will use the splitPoints array to figure out if the points should be demolition or measure.
     * Will also calculate new distance since last point on all the entries in splitPoints.
     * @param {Array<Array<MeasuringPoint>>} splitPoints
     * @returns {railIds: Array<string>, requests: Array<Observable<Array<Article>>>}
     */
    SavePointDialogComponent.prototype.createCalculatedArticlesRequests = function (splitPoints, rtTypeId, demolitionTypeId, invoiceType, allotmentUnid) {
        return this.measureService.createCalculatedArticlesRequests(splitPoints, rtTypeId, demolitionTypeId, invoiceType, allotmentUnid);
    };
    SavePointDialogComponent.prototype.splitPoints = function (points) {
        return this.measureService.splitPoints(points);
    };
    SavePointDialogComponent.prototype.showAddCompositeArticle = function (event) {
        this.addingCompositeArticle = true;
        this.addArticleState = "active";
        this.hideArticleState = "active";
    };
    SavePointDialogComponent.prototype.showAddArticle = function (event) {
        this.addingManualArticle = true;
        this.addArticleState = "active";
        this.hideArticleState = "active";
    };
    SavePointDialogComponent.prototype.cancelAddArticle = function (event) {
        this.addingCompositeArticle = false;
        this.addingManualArticle = false;
        this.addArticleState = "inactive";
        this.hideArticleState = "inactive";
    };
    SavePointDialogComponent.prototype.confirmAddArticle = function (event) {
        var val = this.addArticleFormGroup.get(this.childFormControlName).value;
        if (this.addingManualArticle && val) {
            var articleCpy = Object.assign({}, val);
            this.tmpNewArticles.push(articleCpy);
        }
        else if (this.addingCompositeArticle && val) {
            var compositeArticleCpy = Object.assign({}, val);
            compositeArticleCpy.selectedQuantity = this.quantityFormControl.value;
            this.tmpNewCompoisteArticles.push(compositeArticleCpy);
            this.checkStartOrEndCompositeArticles(true);
            this.calculateArticles();
        }
        this.cancelAddArticle(event);
    };
    SavePointDialogComponent.prototype.updateCompositeArticles = function () {
        this.compositeArticles = [];
        var calcPoints = this.data.points.concat([this.tmpPoint]);
        for (var _i = 0, calcPoints_1 = calcPoints; _i < calcPoints_1.length; _i++) {
            var p = calcPoints_1[_i];
            if (p && p.compositeArticles && !p.verifyArticlesPoint) { // dont add composite articles if point has been counted.
                this.compositeArticles = this.compositeArticles.concat(p.compositeArticles);
                this.notifyChildren();
            }
        }
    };
    SavePointDialogComponent.prototype.notifyChildren = function () {
        this.compositeArticlesSubject.next(this.compositeArticles);
    };
    // XXX assumes that there are only one continuous track of measuring points,
    // XXX and demolition is only done before and/or after a measuring track.
    SavePointDialogComponent.prototype.addStartOrEndCompositeArticle = function () {
        this.tmpPoint.compositeArticles = this.tmpPoint.compositeArticles || [];
        if (this.parsellStatus === 3 /* MEASURING_END */) { // add end article
            this.tmpPoint.compositeArticles.push(this.startOrEndArticle);
            this.steps[2].endType = this.startOrEndArticle.name;
        }
        else if (this.parsellStatus === 1 /* MEASURING_START */) { // add start article
            this.tmpPoint.compositeArticles.push(this.startOrEndArticle);
            this.steps[0].endType = this.startOrEndArticle.name;
        }
        else if (!this.isMeasuring && this.trackContainsMeasuringPoint(this.data.points) && !this.data.endArticleAdded) { // add end article
            this.tmpPoint.compositeArticles.push(this.startOrEndArticle);
            this.steps[2].endType = this.startOrEndArticle.name;
        }
    };
    SavePointDialogComponent.prototype.trackContainsMeasuringPoint = function (allPoints) {
        for (var _i = 0, allPoints_1 = allPoints; _i < allPoints_1.length; _i++) {
            var p = allPoints_1[_i];
            if (p &&
                (p.state % 10 /* MODULATOR */ === 2 /* MEASURE_POINT */ ||
                    p.state % 10 /* MODULATOR */ === 1 /* DEMOLITION_AND_MEASURE_POINT */)) {
                return true;
            }
        }
        return false;
    };
    SavePointDialogComponent.prototype.calcDistsanceBetweenPoints = function (points) {
        return this.measureService.calcDistsanceBetweenPoints(points);
    };
    SavePointDialogComponent.prototype.getDemolitionLength = function (points) {
        var tempPoints = [];
        for (var i = 0; i < points.length; i++) {
            var point = Object.assign({}, points[i]);
            if (point.state <= 1 /* DEMOLITION_AND_MEASURE_POINT */) {
                if (i > 0) {
                    var prevPoint = points[i - 1];
                    if (prevPoint.state > 1 /* DEMOLITION_AND_MEASURE_POINT */) {
                        point.distanceFromPreviousPoint = 0;
                    }
                    else {
                        point.distanceFromPreviousPoint = this.measureService.getDistanceBetweenMeasuringPoints(point, prevPoint);
                    }
                }
                tempPoints.push(point);
            }
        }
        return this.getTrackLength(tempPoints);
    };
    SavePointDialogComponent.prototype.getTrackLength = function (points) {
        var tmpLength = 0;
        for (var _i = 0, points_1 = points; _i < points_1.length; _i++) {
            var p = points_1[_i];
            tmpLength += p.distanceFromPreviousPoint;
        }
        return tmpLength;
    };
    return SavePointDialogComponent;
}(AbstractDialogComponent));
export { SavePointDialogComponent };
