/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./team-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@asymmetrik/ngx-leaflet/dist/leaflet/core/leaflet.directive";
import * as i6 from "./team-map.component";
import * as i7 from "../header/header.service";
import * as i8 from "../../geo.service";
import * as i9 from "@angular/router";
import * as i10 from "../../shared/media.service";
import * as i11 from "../../user.service";
import * as i12 from "../../map.service";
var styles_TeamMapComponent = [i0.styles];
var RenderType_TeamMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TeamMapComponent, data: {} });
export { RenderType_TeamMapComponent as RenderType_TeamMapComponent };
export function View_TeamMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "home mat-typography"]], [[2, "mobile-view", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "mat-card", [["class", "home-card mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i2.View_MatCardHeader_0, i2.RenderType_MatCardHeader)), i1.ɵdid(4, 49152, null, 0, i3.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["id", "mapid"], ["leaflet", ""]], null, [[null, "leafletMapReady"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("leafletMapReady" === en)) {
        var pd_1 = (_co.onMapReady($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 606208, null, 0, i5.LeafletDirective, [i1.ElementRef, i1.NgZone], { options: [0, "options"] }, { mapReady: "leafletMapReady" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.options; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMobileView; _ck(_v, 0, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_1); }); }
export function View_TeamMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-team-map", [], null, null, null, View_TeamMapComponent_0, RenderType_TeamMapComponent)), i1.ɵdid(1, 245760, null, 0, i6.TeamMapComponent, [i7.HeaderService, i8.GeoService, i9.ActivatedRoute, i10.MediaService, i11.UserService, i12.MapService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TeamMapComponentNgFactory = i1.ɵccf("app-team-map", i6.TeamMapComponent, View_TeamMapComponent_Host_0, {}, {}, []);
export { TeamMapComponentNgFactory as TeamMapComponentNgFactory };
