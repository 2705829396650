import { OnInit } from '@angular/core';
import { TimeSheetService } from '../../../time-sheet.service';
import * as moment from 'moment';
import { UserService } from '../../../user.service';
var WeekTeamDietComponent = /** @class */ (function () {
    function WeekTeamDietComponent(timeSheetService, user) {
        this.timeSheetService = timeSheetService;
        this.user = user;
        this.namesTeam = [];
        this.diets = [];
    }
    Object.defineProperty(WeekTeamDietComponent.prototype, "currentYear", {
        get: function () {
            return moment(this.selectedDate).startOf('isoWeek').add(3, 'day').year();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WeekTeamDietComponent.prototype, "currentWeek", {
        get: function () {
            return moment(this.selectedDate).isoWeek();
        },
        enumerable: true,
        configurable: true
    });
    WeekTeamDietComponent.prototype.ngOnInit = function () {
        this.weekData(this.selectedWeek, this.selectedYear);
    };
    WeekTeamDietComponent.prototype.weekData = function (wn, y) {
        var _this = this;
        this.timeSheetService.getWeek(this.user.teamId, y, wn).subscribe(function (res) {
            _this.namesTeam = Object.keys(res.dietsSummary);
            _this.diets = res.dietsSummary;
        });
    };
    return WeekTeamDietComponent;
}());
export { WeekTeamDietComponent };
