import { IsiWeekPerson } from "./isi-week-person";
import { ArtNumber } from "./art-number.enum";

export class IsiWeekHour {
  artNumber: ArtNumber;
  author: string;
  body: string; // comment
  created: Date;
  haDate: Date;
  haUnid: string;
  hoursOvertime50: number;
  // hoursOvertime100: number; replaced by hoursNigth
  hoursNight: number;
  hoursTravel: number;
  hoursWork: number;
  lineKey: string;
  intern: IsiWeekPerson;
  orderSNum: string;
  orderNum: string;
  orderSubject: string;
  projNum: string;
  projName: string;
  status: string;
  teamMembers: Array<IsiWeekPerson>;
  teamId: string;
  unid: string;
  readonly projInfo: Array<String>; // used in the old system
  readonly orderInfo: Array<String>; // used in the old system
  constructor() {
    this.hoursNight = 0;
    this.hoursOvertime50 = 0;
    this.hoursWork = 0;
    this.hoursTravel = 0;
    this.unid = '';
  }
  /**
   * @param factor Can multiply all hours with a factor (usually the size of teamMembers)
   */
  addWeekHour(weekHour: IsiWeekHour, factor = 1) {
    this.hoursWork += weekHour.hoursWork * factor;
    this.hoursNight += weekHour.hoursNight * factor;
    this.hoursOvertime50 += weekHour.hoursOvertime50 * factor;
  }
}
