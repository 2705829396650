var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy } from '@angular/core';
import { layerGroup, latLng } from 'leaflet';
import * as L from 'leaflet';
import { HeaderService } from '../../components/header/header.service';
import { StorageService } from '../storage.service';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MapService } from '../../map.service';
var StorageMapComponent = /** @class */ (function () {
    function StorageMapComponent(headerService, storageService, router, activatedRoute, mapService) {
        this.headerService = headerService;
        this.storageService = storageService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.mapService = mapService;
        this.locationIcon = L.icon({
            iconUrl: 'assets/map-icons/Warehouse.png',
            iconSize: [50, 50],
            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        });
        this.storagePos = [];
        this.options = {
            layers: [],
            zoomControl: false,
            zoom: 11,
            maxZoom: 23,
            minZoom: 4,
            center: latLng(62.527048, 7.556067)
        };
        this.storageUnid = this.activatedRoute.snapshot.params['storageUnid'];
        this.storagePos = this.activatedRoute.snapshot.params.gpsPos;
    }
    StorageMapComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.headerService.addNavItem({
            route: "/map",
            label: 'Kart',
        });
        this.storageSubscription = this.storageService.getOpenStorages().subscribe(function (data) {
            _this.storages = data;
            _this.storages = _this.storages.map(function (storage) {
                storage.gpsPos = storage.unloadGps ? storage.unloadGps : storage.gpsPos;
                return storage;
            });
            var filteredStorage = _this.storages.filter(function (storage) {
                return storage.gpsPos && storage.gpsPos !== '' && storage.gpsPos !== '0;0' && storage.gpsPos !== 'ingen';
            });
            var gpsPos = filteredStorage.map(function (storage) { return storage.gpsPos; });
            _this.AddStorageMarker(filteredStorage);
        }, catchError(function (err) {
            return err;
        }));
    };
    StorageMapComponent.prototype.AddStorageMarker = function (filteredStorage) {
        var _this = this;
        var coordinates = [];
        filteredStorage.forEach(function (data) {
            var res = data.gpsPos.split(";");
            var lngLat = L.latLng(res[0], res[1]);
            var points = [];
            points.push(res[0], res[1]);
            coordinates.push(points);
            var popupMsg = "<a href=\"#/lager/" + data.unid + "\">" + data.subject + "</a><hr> Fylke:" + data.countyName;
            L.marker(lngLat, { icon: _this.locationIcon }).bindPopup(popupMsg).addTo(_this.markerGroup);
        });
        this.markerGroup.addTo(this.myMap);
        if (!this.storagePos) {
            this.myMap.fitBounds(coordinates);
        }
        else {
            var lngLat = L.latLng(this.storagePos.split(',')[0], this.storagePos.split(',')[1]);
            this.myMap.flyTo(lngLat, 12);
        }
    };
    StorageMapComponent.prototype.onMapReady = function (map) {
        return __awaiter(this, void 0, void 0, function () {
            var vector;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.myMap = map;
                        map.setView(L.latLng(62.527048, 7.556067), 11);
                        return [4 /*yield*/, this.mapService.getVectorTileLayer()];
                    case 1:
                        vector = _a.sent();
                        vector.addTo(map);
                        this.markerGroup = layerGroup().addTo(this.myMap);
                        return [2 /*return*/];
                }
            });
        });
    };
    StorageMapComponent.prototype.ngOnDestroy = function () {
        this.headerService.removeNavItem({
            route: "/map",
            label: 'Kart',
            queryParams: ''
        });
    };
    return StorageMapComponent;
}());
export { StorageMapComponent };
