/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./week-team-diet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./week-team-diet.component";
import * as i4 from "../../../time-sheet.service";
import * as i5 from "../../../user.service";
var styles_WeekTeamDietComponent = [i0.styles];
var RenderType_WeekTeamDietComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WeekTeamDietComponent, data: {} });
export { RenderType_WeekTeamDietComponent as RenderType_WeekTeamDietComponent };
function View_WeekTeamDietComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [": ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.location; _ck(_v, 1, 0, currVal_0); }); }
function View_WeekTeamDietComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ( ", " )"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.meals; _ck(_v, 1, 0, currVal_0); }); }
function View_WeekTeamDietComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "day-diet-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " : \u00A0 "])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WeekTeamDietComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WeekTeamDietComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = (_v.context.$implicit.location != ""); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_v.context.$implicit.meals && (_v.context.$implicit.meals.length > 0)); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.dato, "dd.MM.yyyy")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.diett; _ck(_v, 5, 0, currVal_1); }); }
function View_WeekTeamDietComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "diet-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ":"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WeekTeamDietComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.diets[_v.context.$implicit]; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
export function View_WeekTeamDietComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "dietSummary-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WeekTeamDietComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.namesTeam; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_WeekTeamDietComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-week-team-diet", [], null, null, null, View_WeekTeamDietComponent_0, RenderType_WeekTeamDietComponent)), i1.ɵdid(1, 114688, null, 0, i3.WeekTeamDietComponent, [i4.TimeSheetService, i5.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WeekTeamDietComponentNgFactory = i1.ɵccf("app-week-team-diet", i3.WeekTeamDietComponent, View_WeekTeamDietComponent_Host_0, { selectedDate: "selectedDate", selectedWeek: "selectedWeek", selectedYear: "selectedYear" }, {}, []);
export { WeekTeamDietComponentNgFactory as WeekTeamDietComponentNgFactory };
