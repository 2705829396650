var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractDialogComponent } from '../../abstract-dialog/abstract-dialog.component';
import { MatDialogRef, MatTableDataSource, MatSelect } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../data.service';
import { groupBy, toArray, mergeMap, takeUntil, take } from 'rxjs/operators';
import { from } from 'rxjs/internal/observable/from';
import { MediaService } from '../../../shared/media.service';
import { ReplaySubject, Subject } from 'rxjs';
import { SettingsService } from '../../../settings.service';
var OrderApprovalArticlesComponent = /** @class */ (function (_super) {
    __extends(OrderApprovalArticlesComponent, _super);
    function OrderApprovalArticlesComponent(dialogRef, data, dataService, cd, mediaService, settingsService) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.dataService = dataService;
        _this.cd = cd;
        _this.mediaService = mediaService;
        _this.settingsService = settingsService;
        _this.hostClass = true;
        _this.articleColumns = ['Artikkel', 'Tilbud', 'Getac', 'Godkjent', 'Habrukte', 'Avvik'];
        _this.parsellColumns = ['Parsell', 'Tilbud', 'Getac', 'Godkjent', 'Habrukte', 'Avvik'];
        _this.groupArticles = [];
        _this.dataArticle = [];
        _this.filteredArticles = new ReplaySubject(0);
        _this._onDestroy = new Subject();
        _this.isNightMode = false;
        _this.dialogFormGroup = new FormGroup({
            // parsell: new FormControl(),
            article: new FormControl('', Validators.required),
            articleFilterCtrl: new FormControl(''),
            count: new FormControl(0, [Validators.required, Validators.min(1)])
        });
        _this.mediaService.getMedia().subscribe(function (media) {
            _this.isMobileView = media.isMobileView;
        });
        _this.orderUnid = data.OrderUnid;
        _this.orderSnum = data.OrderNumber;
        _this.orderStatus = data.OrderStatus;
        _this.readonly = data.readonly;
        _this.settingsService.getNightMode().subscribe(function (nightMode) {
            _this.isNightMode = nightMode;
        });
        return _this;
    }
    OrderApprovalArticlesComponent.prototype.confirm = function (event) {
        throw new Error("Method not implemented.");
    };
    OrderApprovalArticlesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getOrderForApproval();
        /* this.dataService.getParcels(this.orderUnid).subscribe(parcels => {
          this.parcels = parcels.sort((a, b) => {
            try {
              return (Number(a.title) > Number(b.title)) ? 1 : -1;
            } catch (err) {
              return 0;
            }
          });
        }); */
        this.getWorkArticleList();
        // listen for search field value changes
        this.dialogFormGroup.get('articleFilterCtrl').valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(function () {
            _this.filterArticles();
        });
    };
    OrderApprovalArticlesComponent.prototype.getOrderForApproval = function () {
        var _this = this;
        this.dataService.getOrderForApproval(this.orderUnid).subscribe(function (res) {
            _this.articles = res.allArticles;
            _this.buildLength = res.prodLength;
            _this.createArticleGroup();
            _this.mapData();
        });
    };
    OrderApprovalArticlesComponent.prototype.ngAfterViewInit = function () {
        this.setInitialValue();
    };
    /**
      * Sets the initial value after the filteredArticles are loaded initially
      */
    OrderApprovalArticlesComponent.prototype.setInitialValue = function () {
        this.filteredArticles
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(function () {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredArticles are loaded initially
            // and after the mat-option elements are available
            // this.singleSelect.compareWith = (a: any, b: any) => a && b && a.name === b.name;
        });
    };
    OrderApprovalArticlesComponent.prototype.filterArticles = function () {
        if (!this.workArticles)
            return;
        var search = this.dialogFormGroup.get('articleFilterCtrl').value;
        if (!search) {
            this.filteredArticles.next(this.workArticles);
            return;
        }
        else {
            search = search.toLowerCase();
        }
        // filter the ArticleList
        this.filteredArticles.next(this.workArticles.filter(function (article) { return article['name'].toLowerCase().indexOf(search) > -1; }));
    };
    OrderApprovalArticlesComponent.prototype.getWorkArticleList = function () {
        var _this = this;
        this.dataService.getWorkArticles()
            .subscribe(function (res) {
            _this.workArticles = res;
            // load the initial article list
            _this.filteredArticles.next(_this.workArticles);
            // Set initial selection 
            //this.dialogFormGroup.get('articleFilterCtrl').setValue(this.workArticles[0]['id']  +"-"+ this.workArticles[0]['name']);
        });
    };
    OrderApprovalArticlesComponent.prototype.createArticleGroup = function () {
        var _this = this;
        from(this.articles).pipe(groupBy(function (a) { return a['artNum']; }), mergeMap(function (group$) { return group$.pipe(toArray()); })).subscribe(function (val) {
            _this.groupArticles.push(val);
        });
    };
    OrderApprovalArticlesComponent.prototype.saveWorkArticle = function () {
        if (this.dialogFormGroup.valid) {
            var ol = {
                artNum: this.dialogFormGroup.get('article').value,
                count: this.dialogFormGroup.get('count').value,
                parentId: this.orderUnid
            };
            this.dataService.saveOrderLine(ol).subscribe();
            this.articles = [];
            this.groupArticles = [];
            this.dataArticle = [];
            this.getOrderForApproval();
            this.dialogFormGroup.reset();
        }
        /* const parsell = this.dialogFormGroup.get('parsell').value;
        const articleId = this.dialogFormGroup.get('article').value;
        const count = this.dialogFormGroup.get('count').value;
        const articleName = this.getArticle(articleId);
        console.log(parsell,articleId,articleName[0],count,this.orderUnid); */
    };
    OrderApprovalArticlesComponent.prototype.getArticle = function (articleId) {
        return this.workArticles.filter(function (_a) {
            var id = _a.id;
            return id === articleId;
        }).map(function (_a) {
            var name = _a.name;
            return name;
        });
    };
    OrderApprovalArticlesComponent.prototype.mapData = function () {
        var _this = this;
        this.groupArticles.forEach(function (article) {
            var parsells = [];
            article.forEach(function (details) {
                parsells.push({
                    Parsell: details.parsellNum == 0.0 ? 'M' : 'Parsell ' + details.parsellNum,
                    Getac: details.antall,
                    Tilbud: details.antallTilbud,
                    Godkjent: details.justert,
                    Avvik: details.antallAvvik,
                    Habrukte: details.heravBrukte
                });
            });
            var tilbud = parsells.map(function (el) { return el.Tilbud; }).reduce(function (a, b) { return a + b; }, 0);
            var getac = parsells.map(function (el) { return el.Getac; }).reduce(function (a, b) { return a + b; }, 0);
            var godkjent = parsells.map(function (el) { return el.Godkjent; }).reduce(function (a, b) { return a + b; }, 0);
            var haBrukt = parsells.map(function (el) { return el.Habrukte; }).reduce(function (a, b) { return a + b; }, 0);
            var avvik = parsells.map(function (el) { return el.Avvik; }).reduce(function (a, b) { return a + b; }, 0);
            _this.dataArticle.push({
                Artikkel: article[0].artNum + ' ' + article[0].artInfo[0],
                Tilbud: tilbud, Getac: getac, Godkjent: godkjent, Habrukte: haBrukt, Avvik: avvik,
                parsell: new MatTableDataSource(parsells)
            });
        });
        this.dataSource = new MatTableDataSource(this.dataArticle);
    };
    OrderApprovalArticlesComponent.prototype.toggleRow = function (element) {
        element.parsell && element.parsell.data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
        this.cd.detectChanges();
    };
    OrderApprovalArticlesComponent.prototype.ngOnDestroy = function () {
        this._onDestroy.next();
        this._onDestroy.complete();
    };
    return OrderApprovalArticlesComponent;
}(AbstractDialogComponent));
export { OrderApprovalArticlesComponent };
