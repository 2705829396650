<div class="dialogHeader">
      <h2 class="headerTitle">Forslag</h2>
      <button class="btn-close" color="accent" type="button" mat-icon-button (click)="onNoClick()">
            <mat-icon aria-label="Close">close</mat-icon>
      </button>
</div>
<div class="dialogContent">
      <div class="proposalDetails">
            <h2 class="title">Title: </h2> {{ proposal.subject }} <br>
            <h2 class="author">Fra: </h2> {{ proposal.author }} <br>
            <h2 class="date"> Dato: </h2> {{ proposal.docDate | date: 'dd.MM.yyyy' }} <br>
            <h2 class="department">Område: </h2> {{ proposal.department }}
            <h2 class="body">Forslag til forbedring: </h2> {{ proposal.body }}
            <h2 class="signature">Digital Signatur: </h2> Signert og sendt til behandling av 
            <br><br>
            <form class="dialog"  *ngIf="showCommentbox" [formGroup]="commentsFormGroup" (ngSubmit)="saveComment($event)">
                  <mat-form-field  #commentInput appearance="outline">
                        <textarea matInput 
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="5" 
                        required="true"
                        formControlName="comment" 
                        name="comment"
                        placeholder="Legg svar her"></textarea>  
                  </mat-form-field>
                  <button mat-raised-button class="btn-save" color="primary" type="submit"   [disabled] = "!commentsFormGroup.valid">
                        Lagre svar
                  </button>
            </form>
           
           
            <button mat-raised-button *ngIf="showAddCommentBtn" class="btn-create" color="primary" type="button" (click)="openCommentField()">
                  Lag svar
            </button>
            <button mat-button class="btn-create" color="primary" type="button" (click)="closeInputBox()">
                  Avbryt
            </button>
      </div>
     <div class="comments">
		<h2>Kommentarer</h2>
		<div *ngFor="let comment of proposal.proposalComments">
			<h5>{{ comment.docDate | date: "dd.MM.yyyy"}} | {{ comment.author }}</h5>
			<p>{{ comment.body }}</p>
			<button mat-button class="btn-create" color="primary" type="button" (click)="openCommentField()">
				Lag svar
		  	</button>
			<mat-divider></mat-divider>
		</div>
	</div> 
</div>
