var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
var ConfirmDeletePointDialogComponent = /** @class */ (function (_super) {
    __extends(ConfirmDeletePointDialogComponent, _super);
    function ConfirmDeletePointDialogComponent(dialogRef, data) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.dialogFormGroup = new FormGroup({});
        _this.pointToDelete = _this.data.pointToDelete;
        _this.isLastVerifiedPoint = _this.data.isLastVerifiedPoint;
        return _this;
    }
    ConfirmDeletePointDialogComponent.prototype.deleteArticlesOnClose = function (event, deleteArticles) {
        var result = {
            type: this.data.type,
            deleteArticles: deleteArticles,
            pointToDelete: this.pointToDelete,
            isLastVerifiedPoint: this.isLastVerifiedPoint
        };
        this.dialogRef.close(result);
    };
    ConfirmDeletePointDialogComponent.prototype.ngOnInit = function () {
    };
    ConfirmDeletePointDialogComponent.prototype.confirm = function (event) {
        this.dialogRef.close('cancel');
    };
    return ConfirmDeletePointDialogComponent;
}(AbstractDialogComponent));
export { ConfirmDeletePointDialogComponent };
