import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { AppConfig } from '../../../environments/environment';
import { GpsService } from '../../gps/gps.service';
import * as piexif from 'piexifjs';
import { GpsData } from '../../gps-data';
import { IsiRoadRef } from '../../isi-road-ref';
import * as moment from 'moment';
import { Order } from '../../order';
import { ElectronService } from '../../electron.service';
import { UserService } from '../../user.service';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {
  @ViewChild('myCanvas', { static: false }) myCanvas: ElementRef;
  @ViewChild('hiddenCanvas', { static: false }) hiddenCanvas: ElementRef;
  @Output() onImageSave = new EventEmitter<any>();

  public showLive = true;
  private ctx: CanvasRenderingContext2D;
  private imageCapture: ImageCapture;
  private track;
  public imageData: any;
  public selectedDevice: string;
  public devices: MediaDeviceInfo[] = [];
  private currPos: { gpsData: GpsData, roadRef: IsiRoadRef };
  constructor(private gpsService: GpsService,
    private http: HttpClient,
    private snackbar: MatSnackBar,    
    private electronService: ElectronService,
    private userService: UserService) { }

  async ngOnInit() {
    let devices = await this.getAvaliableDevices();
    this.devices = devices.filter(device => device.kind === 'videoinput');
    this.selectedDevice = this.devices[0].deviceId;
    this.initCamera(this.selectedDevice);
  }

  async getAvaliableDevices(): Promise<Array<any>> {
    return await navigator.mediaDevices.enumerateDevices();
  }

  changeCamera() {
    console.log('change camera', this.selectedDevice)
    this.stopCamera();
    this.initCamera(this.selectedDevice);
  }

  initCamera(deviceId:string) {

    navigator.mediaDevices.enumerateDevices()
    .then((devices) => {
      devices.forEach((device) => {
        console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
      });
    })
    .catch((err) => {
      console.error(`${err.name}: ${err.message}`);
    });
    navigator.mediaDevices.getUserMedia({
      video: {
        width: { min: 1280, ideal: 1920 },
        height: { min: 720, ideal: 1280 },
        deviceId: deviceId //"daf62a9e9da90107ff33e6d11a80a67d38641b9b3f067bb0b2ff00460dff422d"
      }
    })
      .then(mediaStream => {
        document.querySelector('video').srcObject = mediaStream;
        this.track = mediaStream.getVideoTracks()[0];
        this.imageCapture = new ImageCapture(this.track);
      })
      .catch(error => console.log(error));
  }

  stopCamera() {  
    if (this.track) {
      this.track.stop();
    }
  }


  takePicture() {
    this.initCamera(this.selectedDevice);
    this.imageCapture.takePhoto()
      .then(blob => {
         this.showLive = false;
         this.track.stop();
        return createImageBitmap(blob);
      })
      .then(imageBitmap => {
        // console.log('got image', imageBitmap.width + 'x' + imageBitmap.height);
        this.drawOnCanvas(imageBitmap);
      })
      .catch(error => console.log(error));
  }
  newImage(): void {
    //this.track.start();
    this.showLive = true;
    this.initCamera(this.selectedDevice);
  }


  drawOnCanvas(imageBitmap) {

    //const height = this.myCanvas.nativeElement.height;// = 360;
    //const width = this.myCanvas.nativeElement.width;// = 640;
    //console.log(width, height);
    this.hiddenCanvas.nativeElement.height = imageBitmap.height;
    this.hiddenCanvas.nativeElement.width = imageBitmap.width;
    console.log('imageBitmap w:', imageBitmap.width + 'y:' + imageBitmap.height);
    //this.ctx = this.myCanvas.nativeElement.getContext('2d');
    const ctx2 = this.hiddenCanvas.nativeElement.getContext('2d');
    ctx2.drawImage(imageBitmap, 0, 0);
    // get position text
    this.currPos = this.gpsService.getCurrentPos();
    if (this.currPos.roadRef) {
      const gps = this.currPos.gpsData.lat.toFixed(6) + ", " + this.currPos.gpsData.lon.toFixed(6);
      const roadRefShort = this.currPos.roadRef.shortForm; // this.currPos.roadRef.roadCategory + 'V' + ' ' + this.currPos.roadRef.roadNumber + ", HP " + this.currPos.roadRef.section + " / " + this.currPos.roadRef.meter + 'm ' + this.currPos.roadRef.curve.streetName;
      //draw text
      ctx2.font = '48px serif';
      ctx2.fillStyle = 'orange'
      ctx2.fillText(roadRefShort, 20, 50);
      ctx2.fillText(gps, 20, 100);
    }
    //this.ctx.drawImage(this.hiddenCanvas.nativeElement, 0, 0, width, height);
    this.imageData = this.hiddenCanvas.nativeElement.toDataURL('image/jpeg', 1.0);
    //this.getCapturedImage();
  }

  addExif(imageData) {
    var zeroth = {};
    var exif = {};
    var gps = {};
    var lat = 0.0;;
    var lng = 0.0;
    if (this.currPos && this.currPos.gpsData && this.currPos.gpsData.lon && this.currPos.gpsData.lat) {
      lat = this.currPos.gpsData.lat;
      lng = this.currPos.gpsData.lon;
      gps[piexif.GPSIFD.GPSDateStamp] = this.currPos.gpsData.time;
    }

    gps[piexif.GPSIFD.GPSLatitudeRef] = lat < 0 ? 'S' : 'N';
    gps[piexif.GPSIFD.GPSLatitude] = piexif.GPSHelper.degToDmsRational(lat);
    gps[piexif.GPSIFD.GPSLongitudeRef] = lng < 0 ? 'W' : 'E';
    gps[piexif.GPSIFD.GPSLongitude] = piexif.GPSHelper.degToDmsRational(lng);
    zeroth[piexif.ImageIFD.Make] = "iSi AS";
    zeroth[piexif.ImageIFD.Artist] = this.userService.userName || "App Bruker";
    zeroth[piexif.ImageIFD.XResolution] = [777, 1];
    zeroth[piexif.ImageIFD.YResolution] = [777, 1];
    zeroth[piexif.ImageIFD.Software] = "AGjerde App";    
    exif[piexif.ExifIFD.DateTimeOriginal] = moment().format('YYYY:MM:DD HH:mm:ss');
    exif[piexif.ExifIFD.LensMake] = "iSi Lens";
    exif[piexif.ExifIFD.Sharpness] = 777;
    exif[piexif.ExifIFD.LensSpecification] = [[1, 1], [1, 1], [1, 1], [1, 1]];
    gps[piexif.GPSIFD.GPSVersionID] = [7, 7, 7, 7];
    
    var exifObj = { "0th": zeroth, "Exif": exif, "GPS": gps };
    var exifStr = piexif.dump(exifObj);
    return piexif.insert(exifStr, imageData);
  }

  // get captured image and return it as FormData

  public async saveImg2(order: Order) {
    this.snackbar.open('Lagrer bilde...', 'Lukk', { duration: 1000 });


    const imageData = this.hiddenCanvas.nativeElement.toDataURL('image/jpeg', 1.0);

    const imageWithExif = this.addExif(imageData);
    fetch(imageWithExif).then(res => res.blob()).then(blob => {
      const fd = new FormData();
      fd.append('image', blob);
      fd.append('orderUnid', order.unid);
      fd.append('orderNum', order.orderNum);
      fd.append('snum', order.snum);
      fd.append("appVersion", this.electronService.getVersion());
      fd.append("user", this.userService.userName || "");
      fd.append("team", this.userService.teamId);
      this.http.post(`${AppConfig.apiUrl}/images`, fd).pipe(take(1)).subscribe(
        res => {
          this.snackbar.open('Bilde lagret', 'Lukk', { duration: 1000 });
          //this.dialogRef.close();
        }

      );
    })
  }
  
  public getCapturedImage() {
    let imageData = '';
    imageData = this.hiddenCanvas.nativeElement.toDataURL('image/jpeg', 1.0);
    const imageWithExif = this.addExif(imageData);
    fetch(imageWithExif).then(res => res.blob()).then(blob => {
      const fd = new FormData();
      fd.append('image', blob);      
      fd.append("appVersion", this.electronService.getVersion());   
      fd.append("user", this.userService.userName || "");
      fd.append("team", this.userService.teamId);
      if (this.currPos && this.currPos.gpsData) {
        fd.append("startVegLatLon",this.currPos.gpsData.lat + " " + this.currPos.gpsData.lon);
        if (this.currPos.roadRef) {
          fd.append("startVegKortform", this.currPos.roadRef.shortForm);
        }
      }
      const datasave = { imageData: imageData, formData: fd }
      this.onImageSave.emit(datasave);
    })
  }

}
