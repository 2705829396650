<h1 mat-dialog-title>
  <span>Timeføring</span>
  <span> {{data?.orderLine?.orderSubject}}</span>
</h1>
<div mat-dialog-content>
  <form [formGroup]="formGroup" id="ngForm" [class.mobile-view]="isMobileView" (ngSubmit)="save()">
      <app-employee-picker *ngIf="membersFormCtrl" class="full-span" [membersFormCtrl]="membersFormCtrl" [multiple]="true" [defaultMembers]="defaultTeamMembers" (membersInit)="teamMembersInit($event)"></app-employee-picker>
      <mat-form-field *ngIf="membersFormCtrl && membersFormCtrl.value" class="full-span">
        <mat-select placeholder="Velg eventuell lærling" [formControl]="internFormCtrl">
          <mat-option value=""> -- Ingen -- </mat-option>
          <mat-option *ngFor="let member of membersFormCtrl.value" [value]="member">
            {{member?.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="sum-hours">
        <input matInput appCustomIncDecBtns placeholder="sum timer" type="number" [formControl]="sumHoursCtrl" [value]="sumHours" required
        (focusin)="onFocusInHours($event, sumHoursCtrl)" (focusout)="onFocusOutHours($event, sumHoursCtrl)">
        <mat-error *ngIf="sumHoursCtrl.getError('min')">Sum timer må være mer enn 0</mat-error>
        <mat-error *ngIf="sumHoursCtrl.getError('max')">Sum timer må være mindre enn 24</mat-error>
      </mat-form-field>
      <div class="full-span overwork-group">
        <mat-form-field>
          <input matInput appCustomIncDecBtns placeholder="Herav flytting" type="number" [formControl]="sumTravelCtrl" [value]="sumHoursTravel"
          (focusin)="onFocusInHours($event, sumTravelCtrl)" (focusout)="onFocusOutHours($event, sumTravelCtrl)">
          <mat-error *ngIf="sumTravelCtrl.getError('min')">Flytting kan ikke være negativ</mat-error>
          <mat-error *ngIf="sumTravelCtrl.getError('max')">Flytting må ha verdi mindre enn 24</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="data?.orderLine?.artNumber!=='10'">
          <input matInput appCustomIncDecBtns placeholder="Herav overtid 50%" type="number" [formControl]="sum50Ctrl" [value]="sumHours50"
          (focusin)="onFocusInHours($event, sum50Ctrl)" (focusout)="onFocusOutHours($event, sum50Ctrl)">
          <mat-error *ngIf="sum50Ctrl.getError('min')">Overtid kan ikke være negativ</mat-error>
          <mat-error *ngIf="sum50Ctrl.getError('max')">Overtid må ha verdi mindre enn 24</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="data?.orderLine?.artNumber!=='10'">
          <input matInput appCustomIncDecBtns placeholder="Herav nattarbeid" type="number" [formControl]="sumNightCtrl" [value]="sumHoursNight"
          (focusin)="onFocusInHours($event, sumNightCtrl)" (focusout)="onFocusOutHours($event, sumNightCtrl)">
          <mat-error *ngIf="sumNightCtrl.getError('min')">Overtid kan ikke være negativ</mat-error>
          <mat-error *ngIf="sumNightCtrl.getError('max')">Overtid må ha verdi mindre enn 24</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field class="full-span">
        <textarea matInput placeholder="Kommentar" [formControl]="commentCtrl" [required]="data?.orderLine?.artNumber==='10'?'true':'false'"></textarea>
        <mat-error *ngIf="commentCtrl.getError('required')">Kommentar er påkrevd for prosjekttimer</mat-error>
        <mat-error *ngIf="commentCtrl.getError('otherFieldPresent')">Dersom overtid registreres må det skrives en kommentar</mat-error>
      </mat-form-field>
      <div class="full-span color-warn" 
        *ngIf="data?.orderLine?.intern?.unid && !data?.weekHour?.intern">
        * Det er ikke registrert lærling på denne timen, selv om det er registrert læring på andre timeføringer på denne ordrelinjen
      </div>
      <div class="full-span color-warn"
        *ngIf="data?.orderLine?.intern?.unid && data?.weekHour?.intern && data?.weekHour?.intern?.unid !== data?.orderLine?.intern?.unid">
        * Det er registrert en annen lærling ({{data?.weekHour?.intern?.name}}) på denne timeføringen, som er forskjellig fra
        andre timeføringer på denne ordrelinjen ({{data?.orderLine?.intern?.name}})
      </div>
  </form>
</div>
<div>&nbsp;</div>
<div class="mat-dialog-actions">
  <button type="submit" form="ngForm" mat-raised-button color="primary" [disabled]="savingInProgress">
    <div class="button-content">
      Lagre timeføring
      <mat-spinner *ngIf="savingInProgress" [diameter]="20" color="accent"></mat-spinner>
    </div>
  </button>
  <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial color="warning" [disabled]="savingInProgress">
    <div class="button-content">
      Avbryt
      <mat-spinner *ngIf="savingInProgress" [diameter]="20" color="accent"></mat-spinner>
    </div>
  </button>
</div>