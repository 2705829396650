import { Pipe, PipeTransform } from '@angular/core';
import { Sign } from './sign';

@Pipe({
  name: 'signStatus'
})
export class SignStatusPipe implements PipeTransform {

  transform(value: number, args?: any): any {

    switch (value) {
      case Sign.CREATED:
        return "Opprettet";
      case Sign.PLACED:
        return "Satt ut";
      case Sign.REMOVED:
        return "Tatt inn";
      case Sign.VERIFIED:
        return "Kontrollert";
      case Sign.REMOVED_FOR_NIGHT:
        return "Tatt inn for kvelden";
      default:
        return "";
    }

  }

}
