var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
var NotImplementedDialogComponent = /** @class */ (function (_super) {
    __extends(NotImplementedDialogComponent, _super);
    function NotImplementedDialogComponent(dialogRef) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.dialogFormGroup = new FormGroup({
            '': new FormControl('', [])
        });
        return _this;
    }
    NotImplementedDialogComponent.prototype.ngOnInit = function () {
    };
    NotImplementedDialogComponent.prototype.confirm = function (event) {
        this.closeDialog();
    };
    ;
    return NotImplementedDialogComponent;
}(AbstractDialogComponent));
export { NotImplementedDialogComponent };
