import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-contact-sheet',
  templateUrl: './contact-sheet.component.html',
  styleUrls: ['./contact-sheet.component.scss']
})
export class ContactSheetComponent implements OnInit {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private dataService: DataService, private cd: ChangeDetectorRef) { }
  contact: any;
  ngOnInit() {
    this.dataService.getCompanyContact(this.data.compId, this.data.contactName).subscribe(data => {
      this.contact = data;
      this.cd.markForCheck();
    });
  }

}
