import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AppConfig } from '../../../environments/environment';
import { Order } from '../../order';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { CameraComponent } from '../camera/camera.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-camera-dialog',
  templateUrl: './camera-dialog.component.html',
  styleUrls: ['./camera-dialog.component.scss']
})
export class CameraDialogComponent extends AbstractDialogComponent implements OnInit {
  @ViewChild(CameraComponent, {static: false}) camera : CameraComponent
  dialogFormGroup: FormGroup;
  private fd: FormData;
  public showLive = false;
  public order: Order;
  canvasImage: any;


  constructor(protected dialogRef: MatDialogRef<AbstractDialogComponent>, 
              @Inject(MAT_DIALOG_DATA) data: any, 
              private http: HttpClient,
              private snackbar: MatSnackBar) { 
    super(dialogRef);
    console.log('orderData',data)
    this.order = data.order;
  }

  
  confirm(event: any): void {
    this.dialogRef.close({"data": "test"});
  }

  closeDialog() {
    this.dialogRef.close(this.canvasImage);
  }
  
  triggerSnapshot() {
    this.showLive = false;
    this.camera.takePicture();
  }
  saveImg() {
    this.snackbar.open('Lagrer bilde...', 'Lukk', { duration: 1000 });
    //this.camera.saveImg2(this.order);
    this.http.post(`${AppConfig.apiUrl}/images`, this.fd).pipe(take(1)).subscribe(
      res => {
        this.snackbar.open('Bilde lagret', 'Lukk', { duration: 1000 });
        this.dialogRef.close({'status' : 'ok'});
      }

    );
  }
  newImg() {
    this.showLive = true;
    this.camera.newImage();
    //this.camera.showLive = true;
  }
  getImage(e, order) {
    //this.canvasImage = e    
    console.log('save image',e.formData);
    this.fd = e.formData;
    this.fd.append('orderNum', this.order.orderNum);
    this.fd.append('snum', this.order.snum);
    this.fd.append('orderUnid', this.order.unid);
    this.snackbar.open('Lagrer bilde...', 'Lukk', { duration: 1000 });
    //this.camera.saveImg2(this.order);
    this.http.post(`${AppConfig.apiUrl}/images`, this.fd).pipe(take(1)).subscribe(
      res => {
        this.snackbar.open('Bilde lagret', 'Lukk', { duration: 1000 });
        this.dialogRef.close({'status' : 'ok'});
      }

    );

  }
  ngOnInit() {
  }

}
