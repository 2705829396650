import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
import * as i0 from "@angular/core";
import * as i1 from "./user.service";
import * as i2 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(userService, router) {
        this.userService = userService;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.userService.getCurrentUser().pipe(map(function (user) {
            if (user.commonName !== 'Anonymous') {
                return true;
            }
            else {
                return _this.router.parseUrl('/login');
            }
        }));
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
