/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./parcels.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../road-information/road-information.component.ngfactory";
import * as i3 from "../road-information/road-information.component";
import * as i4 from "../../gps/gps.service";
import * as i5 from "../../user.service";
import * as i6 from "../../road-information.service";
import * as i7 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i8 from "@angular/material/card";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "../parcels-table/parcels-table.component.ngfactory";
import * as i14 from "../parcels-table/parcels-table.component";
import * as i15 from "@angular/router";
import * as i16 from "../../data.service";
import * as i17 from "@angular/material/snack-bar";
import * as i18 from "@angular/material/dialog";
import * as i19 from "@angular/material/bottom-sheet";
import * as i20 from "@angular/forms";
import * as i21 from "@angular/common";
import * as i22 from "./parcels.component";
import * as i23 from "../../electron.service";
var styles_ParcelsComponent = [i0.styles];
var RenderType_ParcelsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ParcelsComponent, data: {} });
export { RenderType_ParcelsComponent as RenderType_ParcelsComponent };
function View_ParcelsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-road-information", [], [[2, "road-information-component", null]], null, null, i2.View_RoadInformationComponent_0, i2.RenderType_RoadInformationComponent)), i1.ɵdid(1, 245760, null, 0, i3.RoadInformationComponent, [i4.GpsService, i5.UserService, i6.RoadInformationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hostClass; _ck(_v, 0, 0, currVal_0); }); }
export function View_ParcelsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { parcelsTable: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "parcels"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "mat-card", [["class", "mat-typography mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i7.View_MatCard_0, i7.RenderType_MatCard)), i1.ɵdid(3, 49152, null, 0, i8.MatCard, [[2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 7, "mat-card-header", [["class", "mat-card-header"], ["id", "parcelHeader"]], null, null, null, i7.View_MatCardHeader_0, i7.RenderType_MatCardHeader)), i1.ɵdid(5, 49152, null, 0, i8.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(6, 0, null, 1, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i8.MatCardTitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["Velg parsell"])), (_l()(), i1.ɵeld(9, 0, null, 2, 2, "button", [["color", "primary"], ["id", "newParcelBtn"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.newParcel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ny tom parsell"])), (_l()(), i1.ɵeld(12, 0, null, 0, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i8.MatCardContent, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-parcels-table", [], null, null, null, i13.View_ParcelsTableComponent_0, i13.RenderType_ParcelsTableComponent)), i1.ɵdid(15, 245760, [[1, 4], ["parcelsTable", 4]], 0, i14.ParcelsTableComponent, [i15.Router, i16.DataService, i17.MatSnackBar, i18.MatDialog, i4.GpsService, i19.MatBottomSheet, i20.FormBuilder], { orderUnid: [0, "orderUnid"] }, null), (_l()(), i1.ɵeld(16, 0, null, 0, 1, "mat-card-actions", [["class", "mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(17, 16384, null, 0, i8.MatCardActions, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParcelsComponent_1)), i1.ɵdid(19, 16384, null, 0, i21.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "primary"; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.orderUnid; _ck(_v, 15, 0, currVal_4); var currVal_6 = _co.isElectron; _ck(_v, 19, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 10).disabled || null); var currVal_2 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_1, currVal_2); var currVal_5 = (i1.ɵnov(_v, 17).align === "end"); _ck(_v, 16, 0, currVal_5); }); }
export function View_ParcelsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-parcels", [], null, null, null, View_ParcelsComponent_0, RenderType_ParcelsComponent)), i1.ɵdid(1, 114688, null, 0, i22.ParcelsComponent, [i15.Router, i16.DataService, i15.ActivatedRoute, i17.MatSnackBar, i23.ElectronService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParcelsComponentNgFactory = i1.ɵccf("app-parcels", i22.ParcelsComponent, View_ParcelsComponent_Host_0, {}, {}, []);
export { ParcelsComponentNgFactory as ParcelsComponentNgFactory };
