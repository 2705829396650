<mat-form-field>
  <mat-select [placeholder]="placeholder" [formControl]="membersFormCtrl" [multiple]="multiple"
  (selectionChange)="selectionChange($event)">
    <mat-option *ngIf="displayEmptyOption" value=""> -- Ingen -- </mat-option>
    <mat-optgroup *ngFor="let group of membersGroups" [label]="group.groupName">
      <mat-option *ngFor="let member of group.members" [value]="member" [disabled]="!isAvailable(member)">
        {{member?.name}}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>