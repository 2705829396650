import {DataSource} from '@angular/cdk/collections';
import {Observable} from 'rxjs';
import {of} from 'rxjs';
import {CompositeArticle} from './composite-article';

export class CompositeArticleDataSource extends DataSource<any> {
  data: Array<CompositeArticle> = [
  ];
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const rows = [];
    this.data.forEach(element => {
      rows.push(element, {detailRow: true, element});
    });
    return of(rows);
  }

  disconnect() { }
}
