import { Component, OnInit } from '@angular/core';
import {UserService} from '../../user.service';
import {DataService} from '../../data.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-team-resources',
  templateUrl: './team-resources.component.html',
  styleUrls: ['./team-resources.component.scss']
})
export class TeamResourcesComponent implements OnInit {
  loading = true;
  assets = [];
  employees = [];
  constructor(public userService: UserService, private dataService: DataService,) { }

  ngOnInit() {
    this.loading = true;
    this.dataService.getTeamResources().pipe().subscribe(data => {
      this.assets = data.currentAssets;
      this.employees = data.currentEmployees;
      this.loading = false;
    }, catchError( (err) => {
      this.loading = false;
      return null;
    }));
    
  }

}
