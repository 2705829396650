import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Sign } from './sign';
import { SignLog } from './sign-log';
import { UserService } from '../user.service';
import { AppConfig} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class SjaService {
  apiUrl;

  constructor(private http: HttpClient, private userService: UserService) {
    this.apiUrl = AppConfig.apiUrl;
  }
  getOrder(orderUnid: string) {
    const url = `${this.apiUrl}/orders/${orderUnid}/sja`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }
  getOrderSjaArchive(orderUnid: string) {
    const url = `${this.apiUrl}/orders/${orderUnid}/sja`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }

  getWorkExamples() {
    const url = `${this.apiUrl}/orders/sja/signplans`;
    return this.http.get<any>(url).pipe(map(res => {
      res.data.unshift(res.data.pop()); // move the last element in the array first.
      return res.data;
    }));
  }

  getSignsIcons() {
    const url = `${this.apiUrl}/orders/sja/signs`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }

  saveSja(orderUnid: string, sja: any) {
    const url = `${this.apiUrl}/orders/${orderUnid}/sja`;
    return this.http.post<any>(url, sja).pipe(map(res => {
      return res.data;
    }));
  }

  deleteDayComment(orderUnid, day) {
    const url = `${this.apiUrl}/orders/${orderUnid}/sja/day/delete/${day.unid}`;
    return this.http.get(url)
  }

  saveDayComment(orderUnid, day: any) {
    const url = `${this.apiUrl}/orders/${orderUnid}/sja/day`;
    return this.http.post<any>(url, day).pipe(map(res => {
      return res.data;
    }));
  }

  saveSignsOnSja(orderUnid: string, sja: any) {
    const url = `${this.apiUrl}/orders/${orderUnid}/sja/signs`;
    return this.http.post<any>(url, sja).pipe(map(res => {
      return res.data;
    }));
  }

  removeAllSigns(signs: Array<Sign>, night: boolean) {
    if (signs) {
      signs.forEach((sign: Sign) => {
        const signStatus = sign.log[sign.log.length - 1].status;
        if (signStatus === Sign.PLACED || signStatus === Sign.VERIFIED) {
          this.removeSign(sign, night);
        }
      });
    }
  }

  removeSign(sign: Sign, night) {
    const log = new SignLog();
    if (night) {
      log.status = Sign.REMOVED_FOR_NIGHT;
      log.comment = 'Tatt inn for kvelden.';
    } else {
      log.status = Sign.REMOVED;
    }
    log.user = this.userService.commonName;
    log.date = new Date().getTime();
    sign.log.push(log);
  }
}
