import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimeSheetRoutingModule } from './time-sheet-routing.module';
import { TimeSheetComponent } from './components/time-sheet/time-sheet.component';
import { SharedModule } from '../shared/shared.module';
import { WeekInputComponent } from './components/week-input/week-input.component';
import { OrderLineDialogComponent } from './components/order-line-dialog/order-line-dialog.component';
import { TimeSheetRegistrationDialogComponent } from './components/time-sheet-registartion-dialog/time-sheet-registration-dialog.component';
import { EmployeePickerComponent } from '../employee-picker/employee-picker.component';
import { WeekDayDialogComponent } from './components/week-day-dialog/week-day-dialog.component';
//import { DayInputComponent } from './components/day-input/day-input.component';
import { YearInputComponent } from './components/year-input/year-input.component';
import { WeekTeamDietComponent } from './components/week-team-diet/week-team-diet.component';

@NgModule({
  imports: [
    CommonModule,
    TimeSheetRoutingModule,
    SharedModule
  ],
  exports: [],
  declarations: [TimeSheetComponent, OrderLineDialogComponent,
    TimeSheetRegistrationDialogComponent, WeekDayDialogComponent, WeekTeamDietComponent],
  entryComponents: [OrderLineDialogComponent, TimeSheetRegistrationDialogComponent, WeekDayDialogComponent]
})
export class TimeSheetModule { }
