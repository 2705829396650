import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TaskService = /** @class */ (function () {
    function TaskService(http) {
        this.http = http;
        this.taskActions = new Subject();
        this.apiUrl = AppConfig.apiUrl;
    }
    TaskService.prototype.getTaskActions = function () {
        return this.taskActions.asObservable();
    };
    TaskService.prototype.getWorkshopTasks = function (team, week) {
        var url = this.apiUrl + "/tasks/workshop/" + team + "?week=" + week;
        return this.http.get(url).pipe(map(function (res) {
            return res.data;
        }));
    };
    TaskService.prototype.saveWorkshopTask = function (task) {
        var _this = this;
        var url = this.apiUrl + "/tasks/workshop";
        return this.http.post(url, task).pipe(map(function (res) {
            _this.taskActions.next('updated');
            return res.data;
        }));
    };
    TaskService.prototype.deleteTask = function (task) {
        var _this = this;
        var url = this.apiUrl + "/tasks/delete/" + task.unid;
        return this.http.post(url, {}).pipe(map(function (res) {
            _this.taskActions.next('updated');
            return res.data;
        }));
    };
    TaskService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TaskService_Factory() { return new TaskService(i0.ɵɵinject(i1.HttpClient)); }, token: TaskService, providedIn: "root" });
    return TaskService;
}());
export { TaskService };
