import { Injectable } from '@angular/core';
import {LatLngTuple} from 'leaflet';

@Injectable()
export class NaviService {

  constructor() { }
  navigateTo(latLon: LatLngTuple, title: string) {
    title = title ? title : 'AGjerde Intranet';
    const uri = `ms-drive-to:?destination.latitude=${latLon[0]}&destination.longitude=${latLon[1]}&destination.name=${title}`;
    return uri;
  }

}
