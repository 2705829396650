import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ICulvert } from '../../i-culvert';

@Component({
  selector: 'app-culverts-dialog',
  templateUrl: './culverts-dialog.component.html',
  styleUrls: ['./culverts-dialog.component.scss']
})
export class CulvertsDialogComponent implements OnInit {

  culverts: Array<ICulvert> = [];
  constructor(
    public dialogRef: MatDialogRef<CulvertsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CulvertsDialogData) {
      this.culverts = data.culverts;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
  }
}

export interface CulvertsDialogData {
  culverts: Array<ICulvert>;
}
