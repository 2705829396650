export class OrderComment {
  body: string;
  user: string;
  created: Date;
  lastModified: Date;
  unid: string;
  orderNum: string;
  parsNum: string;
  teamId: string;
  orderComment: string;
  editable: boolean;
}

