import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivationEnd, NavigationEnd, Router, Params, NavigationStart} from '@angular/router';
import {Subscription} from 'rxjs';
// import {Params} from '@angular/router/src/shared';
import {MatSnackBar, MatDialog} from '@angular/material';
import { HeaderService } from './header.service';
import { WorkshopTaskDialogComponent } from '../workshop-task-dialog/workshop-task-dialog.component';
import { SettingsService } from '../../settings.service';
import { UserService } from '../../user.service';
import { ElectronService } from '../../electron.service';
import { debounceTime, filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  navItems: Array<{label: string, route: string, queryParams: Params}> = [];
  isNightMode: boolean;
  isAdmin: boolean;
  public isAuth;
  allTeams = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'Z'];
  constructor(private router: Router,
    private snackbar: MatSnackBar,
    private headerService: HeaderService,
    public dialog: MatDialog,
    private settingsService: SettingsService,
    private userService: UserService,
    private electronService: ElectronService) {
    this.headerService.getPageTitle().subscribe(navItem => {
      if (navItem.action === 'add') {
        this.generateNavItems(navItem);
      } else if (navItem.action === 'remove') {
        this.removeNavItem(navItem);
      } else if (navItem.action === 'removeAll') {
        this.navItems = [];
      }
    });
    this.settingsService.getNightMode().subscribe( nightMode => {
      this.isNightMode = nightMode;
    });
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(routeChanges => {
      console.log('route changed', new Date().getTime());
      setTimeout( () => {
        this.updateAuth();
      },200);
      
    })

  }

  ngOnInit() {
    this.updateAuth();
  }
  private updateAuth() {
    console.log('update auth timestamp: ', new Date().getTime());
    this.userService.getCurrentUser().pipe(debounceTime(100),take(1)).subscribe(user => {
      if (user.user !== 'Anonymous') {
        this.isAuth = true;
      } else {
        this.isAuth = false;
      }
      console.log('auth is updated', user, new Date().getTime());
    });
  }

  public isElectron() {
    return this.electronService.isElectron();
  }

  public openLink(url: string): void {
    if (this.electronService.isElectron()) {
      this.electronService.openExternalUrl(url);
    } else {
      window.open(url, '_blank');
    }
  }

  updateRoles() {
    if (!this.userService.roles) {
      this.userService.setCurrentUser().subscribe( () => {
        this.isAdmin  = this.userService.isAdmin();
      });

    } else {
      this.isAdmin  = this.userService.isAdmin();
    }
  }
  changeTeam(team) {
    this.userService.changeTeam(team).subscribe( data => {
      // rebuild title
      document.title = `Arvid Gjerde App - ${this.userService.commonName}, Lag ${this.userService.teamId}`;
      this.snackbar.open("Byttet til Lag " + team, '', {duration: 5000});
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigateByUrl(this.router.url);
    })
  }

  ngOnDestroy() {
  }

  goTo(route: string, queryParams?: Params) {
    // TODO (john) Ask user if she really wants to leave the registration, then navigate if yes.
    const urlTree = this.router.createUrlTree([route], {queryParams: queryParams});
    this.router.navigateByUrl(urlTree);
  }

  createWorkshopTask() {
    const dialogRef = this.dialog.open(WorkshopTaskDialogComponent, {
      height: '80%',
      width: '90%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          if (result.task && result.task.unid) {
            this.snackbar.open("Ny Verkstedsoppgave er opprettet", '', {duration: 5000});
          }
      }
    });
  }

  createDeviation() {
    /* TODO (john) open custom dialog to auto fill a deviation.
     * Users wanted to quickly create a decviation if the signal is bad because of an obstacle.
     */
    this.snackbar.open("Denne funkjsonen er ikke implementert enda", '', {duration: 5000});
  }
  openSettings() {
    // TODO (john) create a new tab (or dialog), with some settings, such as setting the stickHeight (altitude adjustment)?
    this.snackbar.open("Denne funkjsonen er ikke implementert enda", '', {duration: 5000});
  }
  toggleNightMode() {
    this.settingsService.toggleNightMode();
  }

  private generateNavItems(item) {
    // check if items exists
    const itemsFound = this.navItems.filter(filterItem => {
      return filterItem.route === item.route;
    })
    if (itemsFound.length === 0) {
      this.navItems.push(item);
    }
  }

  private removeNavItem(item) {
    this.navItems = this.navItems.filter(filterItem => {
      return filterItem.route !== item.route
    });
  }
}
