<form 
#timeSheetForm="ngForm"
[formGroup]="formGroup"
class="time-sheet"
[class.mat-elevation-z3]="!isMobileView"
[class.mini-grid]="!showFullWeek"
[class.mega-grid]="showFullWeek"
[class.mobile-view]="isMobileView">
<div class="top-bar" [class.mobile-view]="isMobileView" [class.mini-grid]="!showFullWeek" [class.mega-grid]="showFullWeek">
  <ng-container *ngIf="!isMobileView">
    <div class="year-week-picker double-span-start test" [class.full-week]="showFullWeek">
      <app-year-input (dateChange)="onDateChanged($event)" [selectedDate]="selectedDate"></app-year-input>
      <app-week-input (dateChange)="onDateChanged($event)" [selectedDate]="selectedDate"></app-week-input>
    </div>
    <div [class.mini-grid]="!showFullWeek" [class.mega-grid]="showFullWeek" class="week-hours">
      <div *ngFor="let number of [0,1,1,1,1,1,1]; let i = index;" class="week-day week-day-mon-sun">
        <span *ngIf="(i < 4 || i > 3 && showFullWeek) || (isMobileView)">{{beginWeek.add(number,'day') | date : 'EEEdd.MMM'}}</span>
      </div>
    </div>
    <div class="checkbox double-span-end">
      <mat-checkbox formControlName="showFullWeek" (change)="toggleShowFullWeek($event)">Vis helg</mat-checkbox>
    </div>
  </ng-container>
  <ng-container *ngIf="isMobileView">
    <button class="date-picker-btn" mat-button color="accent" (click)="picker.open()">Velg dato</button>
    <mat-form-field id="datepickerFormField">
      <input matInput [matDatepicker]="picker" placeholder="Choose a date" (dateChange)="datePickerChanged('change', $event)">
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <div class="year-day-picker double-span-start">
      <app-day-input (dateChange)="onDateChanged($event)" [selectedDate]="selectedDate"></app-day-input>
    </div>
  </ng-container>
  </div>
  <h2 class="double-span-start mat-h2"> Dagføring </h2>
  <ng-container *ngIf="!isMobileView">
    <div *ngFor="let weekDay of weekDays; let i = index" class="week-day">
      <ng-container *ngIf="(i < 4 || i > 3 && showFullWeek) || (isMobileView)">
        <ng-container *ngTemplateOutlet="weekDayButton; context:{weekDay: weekDay, i: i}"></ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="isMobileView">
    <div class="single-day">
      <ng-container
        *ngTemplateOutlet="weekDayButton; context:{weekDay: weekDays[selectedDate.locale('en-gb').weekday()], i: selectedDate.locale('en-gb').weekday()}">
      </ng-container>
    </div>
  </ng-container>
  <ng-template #weekDayButton let-weekDay="weekDay" let-i="i">
    <button (click)="createOrEditWeekDay(i, weekDay)" mat-raised-button
      type="button" [color]="weekDay?'primary':'accent'">
      <span *ngIf="!weekDay">
        Ny dagføring
      </span>
      <span *ngIf="weekDay">
        {{weekDay.workStart | date:'HH:mm'}}—{{weekDay.workEnd | date:'HH:mm'}}
      </span>
      <span *ngIf="weekDay?.comment">
        <br>
        <mat-icon>comment</mat-icon>
      </span>
    </button>
  </ng-template>
  <div class="saturday">
    <div *ngIf="showFullWeek"></div>
  </div>
  <div class="double-span-end">&nbsp;</div>
  <mat-divider></mat-divider>
  <h2 class="double-span-start mat-h2">Ordre / Prosjekt</h2>
  <ng-container *ngIf="!isMobileView">
    <ng-container *ngFor="let weekDay of weekDays; let i = index">
      <div *ngIf="(i < 4 || i > 3 && showFullWeek) || (isMobileView)" class="week-day">
        <span>timer/kjøring</span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isMobileView">
    <div class="single-day"><span>timer/kjøring</span></div>
  </ng-container>
  <h2 class="double-span-end mat-h2">Sum uke</h2>
  <div class="orders"
  [class.mini-grid]="!showFullWeek"
  [class.mega-grid]="showFullWeek"
  [class.mobile-view]="isMobileView"
  *ngFor="let orderLine of orderLines; let i = index" [@fadeout]="fadeoutStates[orderLine.id] ? 'true' : 'false'" >
    <div [id]="orderLine.id" class="double-span-start justify-start">
      <button mat-button type="button" color="accent" 
      (click)="createOrEditOrderLine(orderLine.artNumber,orderLine)">
        <span *ngIf="orderLine.artNumber===ARTNUMBER_ORDER">{{orderLine?.orderSNum + ' ' + orderLine?.orderSubject}}</span>
        <span *ngIf="orderLine.artNumber===ARTNUMBER_PROJECT">{{orderLine?.projNum + ' ' + orderLine?.projName}}</span>
        <br>
        <span *ngFor="let member of orderLine.teamMembers" [class.intern]="member?.unid===orderLine?.intern?.unid">{{member?.initials}} </span>
      </button>
    </div>
    <div 
      [class.mini-grid]="!showFullWeek"
      [class.mega-grid]="showFullWeek" 
      class="week-hours"
      *ngIf="orderLine && weekHours">
      <ng-container *ngIf="!isMobileView">
        <div *ngFor="let weekHour of weekHours[orderLine.id]; let j = index;">
          <ng-container *ngTemplateOutlet="weekHourButton; context:{orderLine: orderLine, weekHour: weekHour, j: j}"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="isMobileView">
        <div class="single-day">
          <ng-container *ngTemplateOutlet="weekHourButton; context:{orderLine: orderLine, weekHour: getSelectedOrderLineWeekHour(orderLine), j: selectedDate.locale('en-gb').weekday()}"></ng-container>
      </div>
        
      </ng-container>
      <ng-template #weekHourButton  let-weekHour="weekHour" let-j="j" let-orderLine="orderLine">
        <button mat-raised-button *ngIf="(j < 4 || j > 3 && showFullWeek) || (isMobileView)" (click)="createOrEditWeekHour(weekHour,orderLine,j)"
          [disabled]="!weekDays[j]" [class.empty-week-hour]="!weekHour">
          <!-- Disable button if no weekDay has been made-->
          <ng-container *ngIf="!weekHour">
            <mat-icon>add</mat-icon>
          </ng-container>
          <div class="week-hour" *ngIf="weekHour">
            <div id="work-travel-time">{{weekHour.hoursWork | number:'1.1-2'}} / {{weekHour.hoursTravel | number:'1.1-2'}}
            </div>
            <mat-icon *ngIf="weekHour.body" color="primary">comment</mat-icon>
            <mat-icon
              *ngIf="orderLine?.intern?.unid && !weekHour?.intern?.unid || (weekHour?.intern && orderLine?.intern?.unid !== weekHour?.intern?.unid)"
              color="warn">warning</mat-icon>
            <mat-icon *ngIf="weekHour.hoursOvertime50 || weekHour.hoursOvertime100" color="primary">alarm</mat-icon>
          </div>
        </button>
      </ng-template>
    </div>
    <div class="order-line-sum-hours-work justify-end">
      {{orderLine.sumHoursWork | number:'1.1-2'}} / {{orderLine.sumHoursTravel | number:'1.1-2'}}
    </div>
    <div class="justify-end" *ngIf="!isMobileView">
      <button class="clear-button" mat-icon-button color="accent" (click)="deleteOrder(orderLine)">
          <mat-icon aria-label="Delete line">clear</mat-icon>
      </button>
    </div>
  </div>
  <h1 *ngIf="!loadingWeek && (!orderLines || orderLines.length == 0)" class="span-middle mat-h1" color="primary">
    Ingen timeføringer registrert i uke {{selectedDate.isoWeek()}} for lag {{user.teamId}}.
    Vennligst opprett en ny rad fra nederst på siden
  </h1>
  <div *ngIf="loadingWeek" class="span-middle">
      <mat-spinner></mat-spinner>
  </div>
  <h4 class="double-span-start justify-end align-end mat-h4 sum-days">Tilgjengelige timer</h4>
  <div class="week-hours" [class.mini-grid]="!showFullWeek" [class.mega-grid]="showFullWeek">
    <ng-container *ngIf="!isMobileView">
      <div *ngFor="let weekDay of weekDays; let i = index" class="justify-end align-end">
        <label class="mat-small" *ngIf="(i < 4 || i > 3 && showFullWeek) || (isMobileView)">
          {{getAvailableTime(weekDay)}}
        </label>
      </div>
    </ng-container>
    <ng-container *ngIf="isMobileView">
      <div class="single-day"><label>
        {{getAvailableTime(getSelectedWeekDay())}}
      </label></div>
    </ng-container>
  </div>
  <div class="double-span-start justify-end mat-h4 sum-days">
    <label>Sum betalte timer</label>
    <br>
    <label>Sum arbeidstid</label>
  </div>
  <div class="week-hours sum-days" 
  [class.mini-grid]="!showFullWeek"
  [class.mega-grid]="showFullWeek">
    <ng-container *ngIf="!isMobileView">
      <div *ngFor="let weekDaySum of weekDaysSums; let i = index" class="justify-end sum-days">
        <ng-container *ngTemplateOutlet="weekDaySumTemplate; context:{weekDaySum: weekDaySum, i: i}"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="isMobileView">
      <div class="single-day" *ngIf="weekDaysSums && weekDaysSums.length">
        <ng-container *ngTemplateOutlet="weekDaySumTemplate; context:{weekDaySum: weekDaysSums[selectedDate.locale('en-gb').weekday()], i: selectedDate.locale('en-gb').weekday()}"></ng-container>
      </div>
    </ng-container>
    <ng-template #weekDaySumTemplate let-i="i" let-weekDaySum="weekDaySum">
      <div *ngIf="(i < 4 || i > 3 && showFullWeek) || (isMobileView)" class="sum-day">
        <div class="sum-paid-hours">
          <label>{{weekDaySum.hoursWork|number:'1.1-2'}} / {{weekDaySum.hoursTravel|number:'1.1-2'}}</label>
          <mat-icon *ngIf="weekDaySum.hoursOvertime100 || weekDaySum.hoursOvertime50" color="primary" aria-label="overtid"
            matTooltip="Overtid er registrert på denne dagen">alarm</mat-icon>
          <mat-icon *ngIf="weekDaySum.body" color="primary" aria-label="kommentar"
            matTooltip="Kommentar er registrert på denne dagen">comment</mat-icon>
        </div>
        <label class="sum-working-hours-minus-pause">{{weekDaySum.hoursWork - (weekDays[i]?.hourPauseWork || 0)}}</label>
      </div>
    </ng-template>
  </div>
  <h2 class="double-span-end">
    &nbsp;
  </h2>
  <div class="team-members-grid" 
  [class.mini-grid]="!showFullWeek"
  [class.mega-grid]="showFullWeek"
  [class.intern]="teamMembersSums[initials].intern"
  [class.first-team-members-row]="r === 0" 
  *ngFor="let initials of getKeys(teamMembersSums); let r = index">
    <div class="team-member-initials">{{initials}}</div>
    <ng-container *ngIf="!isMobileView">
      <div class="team-member justify-end" *ngFor="let weekHour of teamMembersSums[initials].hourList; let i = index">
        <ng-container *ngTemplateOutlet="teamMemberSumTemplate; context:{weekHour:weekHour, i:i}"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="isMobileView">
      <div class="single-day">
        <ng-container *ngTemplateOutlet="teamMemberSumTemplate; context:{weekHour:teamMembersSums[initials].hourList[selectedDate.locale('en-gb').weekday()], i:selectedDate.locale('en-gb').weekday()}"></ng-container>
      </div>
    </ng-container>
    <div class="team-member team-member-sum mat-h2" [class.single-day-member-sum]="isMobileView">
        {{getTotalSumMember(initials).hoursWork | number:'1.1-2'}} / {{getTotalSumMember(initials).hoursTravel | number:'1.1-2'}}
    </div>
  </div>
  <ng-template #teamMemberSumTemplate let-weekHour="weekHour" let-i="i">
    <div *ngIf="(i < 4 || i > 3 && showFullWeek) || (isMobileView)" class="sum-day-hour"
      [class.overtime]="weekDays[i] && weekHour.hoursWork > getAvailableTime(weekDays[i])">
      <ng-container *ngIf="!weekDays[i] || !(weekHour.hoursWork > getAvailableTime(weekDays[i]))">
        {{weekHour.hoursWork | number:'1.1-2'}} / {{weekHour.hoursTravel | number:'1.1-2'}}
      </ng-container>
      <ng-container *ngIf="weekDays[i] && (weekHour.hoursWork > getAvailableTime(weekDays[i]))">
        <span
          matTooltip="{{'Jobbet mer enn tilgjengelige timer: ' + getAvailableTime(weekDays[i])}}">{{weekHour.hoursWork | number:'1.1-2'}}
          / {{weekHour.hoursTravel | number:'1.1-2'}}</span>
      </ng-container>
    </div>
  </ng-template>
</form>
<mat-accordion togglePosition="before" class="week-summary">
  <mat-expansion-panel (opened)="weekSummaryIsOpen = true" (closed)="weekSummaryIsOpen = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Oppsummering
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="week-summary-content" [class.mini-grid]="!showFullWeek"
         [class.mega-grid]="showFullWeek">
         <div class="week-summary-titles double-span-start">
          <div *ngFor="let summaryTitle of summaryTitles; let i = index;">
            {{summaryTitle.label}}
          </div>
        </div>
         <div *ngFor="let day of weekDays; let i = index;">
          <div *ngIf="(i < 4 || i > 3 && showFullWeek) || (isMobileView)">
            <div *ngFor="let summary of summaryTitles; let j = index;" class="day-summary">
              <ng-container *ngFor="let key of summary.values; let k = index">
                <ng-container *ngIf="summary?.filter==='date'">
                  <ng-container *ngIf="day && day[key]; else noStartEnd">{{day[key]|date:'HH:mm'}}</ng-container>
                  <ng-template #noStartEnd>0</ng-template>
                </ng-container>
                <ng-container *ngIf="summary?.filter==='comment'">
                  <ng-container *ngIf="day && day[key];else noComment">
                    <mat-icon matTooltip="{{day[key]}}">comment</mat-icon>
                  </ng-container>
                  <ng-template #noComment>Ingen</ng-template>
                </ng-container>
                <ng-container *ngIf="!summary.filter">{{(day && day[key]) ? (day[key]) : 0}}</ng-container>
                <ng-container *ngIf="k<summary.values.length-1">/</ng-container>
              </ng-container>
            </div>
          </div>
         </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel (opened)="weekSummaryIsOpen = true" (closed)="weekSummaryIsOpen = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Oppsummering Diett
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-week-team-diet [selectedYear]= "currentYear" [selectedWeek] = "currentWeekNumber" ></app-week-team-diet>
  </mat-expansion-panel>
</mat-accordion>
<div class="sticky-footer">
  <form #timeSheetFooterForm="ngForm" [formGroup]="footerFormGroup">
    <button mat-raised-button type="button" (click)="createOrEditOrderLine(ARTNUMBER_ORDER)" color="primary" [disabled]="loadingWeek">Ny Ordrelinje</button>
    <button mat-raised-button type="button" (click)="createOrEditOrderLine(ARTNUMBER_PROJECT)" color="primary" [disabled]="loadingWeek">Ny Prosjektlinje</button>
    <button mat-raised-button type="button" (click)="completeWeek()" color="accent" [disabled]="loadingWeek">
        Avslutt uke
    </button>
  </form>
</div>