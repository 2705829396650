<div class="approveOrder-container mat-typography" [class.mobile-view]="isMobileView">
      <h2 class="title">Godkjenn Ordre: {{ order?.snum }} {{ order?.subject }} </h2>
      <mat-card>
            <h2> Oppsummering av order </h2>
            <!-- Her vises en oppsummering av ordre for godkjenning og innsending.  -->
            <mat-card-content>
                  <div class="grid1">
                        <div> <strong>Samlet lengde:</strong> {{ approvalOrder?.prodLength }} </div>
                        <div> <strong>Antall Parseller:</strong> {{ approvalOrder?.parsCount }} </div>
                        <div>
                              <strong>Antall Avvik:</strong>
                              <span *ngIf="approvalOrder?.qaReportsCount != 0; else thenBlock">
                                    {{ approvalOrder?.qaReportsCount }} </span>
                              <ng-template #thenBlock>
                                    ingen avvik registrert
                              </ng-template>
                        </div>
                        <br>
                        <ng-container [formGroup]="orderFormGroup">
                              <div>
                                    <mat-form-field>
                                          <label>Km Kjøring:</label>
                                          <input matInput type="number" formControlName="kilometer" appCustomIncDecBtns>
                                    </mat-form-field>
                              </div>
                              <div>
                                    <mat-form-field >
                                          <label>Ant. varslinger:</label>
                                          <input matInput type="number" formControlName="warnings" appCustomIncDecBtns>
                                    </mat-form-field>
                              </div>
                              <div>
                                    <mat-form-field >
                                          <label>Lysregulering, stk.:</label>
                                          <input matInput type="number" formControlName="lights" appCustomIncDecBtns>
                                    </mat-form-field>
                              </div>
                              <!-- <div> <strong>Km Kjøring: </strong> {{ approvalOrder?.kmDrive }} </div> 
                              <div> <strong>Ant. varslinger: </strong> {{ approvalOrder?.numWarnings }} </div>
                              <div> <strong>Ant. meter lysregulering: </strong> {{ approvalOrder?.numLights }} </div> -->
                        </ng-container>
                  </div>
                  <br>
                  <div> <button mat-raised-button color="accent" (click)="openDialog()"> Vis Artikler </button> </div>
                  <br>
                  <div> <strong> Arbeidsvarsling: </strong>
                        <div *ngIf="approvalOrder?.sjaSummaryList.length > 0; else elseBlock">
                              <br>
                              <p *ngFor="let varslinger of approvalOrder?.sjaSummaryList"> {{ varslinger }} </p>
                        </div>
                        <ng-template #elseBlock>
                              <p>SJA ikke opprettet</p>
                        </ng-template>
                  </div>
                  <br>
                  <div>
                        <strong>Timeforbruk: </strong>
                        <mat-table *ngIf=" approvalOrder?.personHours.length" [dataSource]="approvalOrder?.personHours">
                              <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef> Navn </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="hoursWork">
                                    <mat-header-cell *matHeaderCellDef> Timer totalt </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.hoursWork}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="hoursTravel">
                                    <mat-header-cell *matHeaderCellDef> Herav flytting </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.hoursTravel}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="hoursOvertime50">
                                    <mat-header-cell *matHeaderCellDef> Herav 50% </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.hoursOvertime50}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="hoursNight">
                                    <mat-header-cell *matHeaderCellDef> Herav nattarbeid </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.hoursNight}} </mat-cell>
                              </ng-container>
                              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                  </div>
                  <br><br>
                 
                  
                  <button *ngIf="order?.status=='5'" mat-raised-button color="primary" (click)="SaveAsOrderCompleted()"
                        [disabled]="!parsellsApproved || !sjaAvailable">Godkjenn ordre</button><br><br>
                  <mat-hint *ngIf='!parsellsApproved'>*Ordre kan ikke godkjennes før Parseller er godkjent</mat-hint><br>
                  <mat-hint *ngIf='!sjaAvailable'>*Ordre kan ikke godkjennes før SJA er opprettet</mat-hint>   
                  <p class="success-message" *ngIf="order?.status >= 7">Ordren er godkjent av laget</p>
            </mat-card-content>
      </mat-card>
</div>