var Sign = /** @class */ (function () {
    function Sign() {
    }
    Object.defineProperty(Sign.prototype, "placedPosition", {
        get: function () {
            var placedPosition;
            for (var i = this.log.length; i > 0; i--) {
                if (this.log[i - 1].status === Sign.PLACED) {
                    // find the last placed position
                    placedPosition = this.log[i - 1].position.shortForm;
                    break;
                }
            }
            if (placedPosition === undefined) {
                // no sign has been placed, use created place
                if (this.log) {
                    placedPosition = "" + this.log[0].position.meter;
                }
                else {
                    placedPosition = "";
                }
            }
            return placedPosition;
        },
        enumerable: true,
        configurable: true
    });
    Sign.prototype.removeSign = function () {
    };
    Sign.CREATED = 1;
    Sign.PLACED = 2;
    Sign.REMOVED = 3;
    Sign.VERIFIED = 4;
    Sign.REMOVED_FOR_NIGHT = 5;
    return Sign;
}());
export { Sign };
