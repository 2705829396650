var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { AbstractDialogComponent } from '../../abstract-dialog/abstract-dialog.component';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { UserService } from '../../../user.service';
import { DataService } from '../../../data.service';
import { OrderComment } from '../../../order-comment';
var OrderCommentDialogComponent = /** @class */ (function (_super) {
    __extends(OrderCommentDialogComponent, _super);
    function OrderCommentDialogComponent(dialogRef, data, user, dataService) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.user = user;
        _this.dataService = dataService;
        _this.data = data;
        _this.dialogFormGroup = new FormGroup({
            body: new FormControl('', Validators.required),
        });
        if (data.comment) {
            _this.dialogFormGroup.get('body').setValue(data.comment.body);
        }
        return _this;
    }
    OrderCommentDialogComponent.prototype.ngOnInit = function () {
    };
    OrderCommentDialogComponent.prototype.confirm = function (event) {
        var _this = this;
        var orderComment = new OrderComment();
        orderComment.body = this.dialogFormGroup.get('body').value;
        orderComment.teamId = this.user.teamId;
        orderComment.user = this.user.commonName;
        if (this.data.comment) {
            orderComment.unid = this.data.comment.unid;
        }
        this.dataService.saveOrderComment(this.data.orderUnid, orderComment).subscribe(function (comment) {
            _this.dialogRef.close(_this.data);
        });
    };
    return OrderCommentDialogComponent;
}(AbstractDialogComponent));
export { OrderCommentDialogComponent };
