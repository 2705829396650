import { Pipe, PipeTransform } from '@angular/core';
import { Sign } from './sign';

@Pipe({
  name: 'sign',
  pure: false
})
export class SignPipe implements PipeTransform {

  transform(value: Sign, args?: any): any {
    if (args === 'placed') {
      const sign: Sign = new Sign();
      sign.count = value.count;
      sign.id = value.id;
      sign.log = value.log;
      sign.required = value.required;
      sign.side = value.required;
      return sign.placedPosition;
    }
  }

}
