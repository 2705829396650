var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AbstractDialogComponent } from '../../components/abstract-dialog/abstract-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StorageService } from '../storage.service';
import { MediaService } from '../../shared/media.service';
import { GpsService } from '../../gps/gps.service';
import { UserService } from '../../user.service';
var CreateNewStorageComponent = /** @class */ (function (_super) {
    __extends(CreateNewStorageComponent, _super);
    function CreateNewStorageComponent(dialogRef, storageService, mediaService, gpsService, userservice, data) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.storageService = storageService;
        _this.mediaService = mediaService;
        _this.gpsService = gpsService;
        _this.userservice = userservice;
        _this.data = data;
        _this.allTeams = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'Z'];
        _this.teamId = '';
        _this.myTeamId = '';
        _this.orders = [];
        _this.mediaService.getMedia().subscribe(function (media) {
            _this.isMobileView = media.isMobileView;
        });
        _this.dialogFormGroup = new FormGroup({
            name: new FormControl('', Validators.required),
            team: new FormControl(''),
            county: new FormControl('', Validators.required),
            plannedPosition: new FormControl(''),
            position: new FormControl(''),
            roadnumber: new FormControl(''),
            hp: new FormControl(''),
            kilometer: new FormGroup({
                From: new FormControl(''),
                To: new FormControl('')
            }),
            order: new FormGroup({
                linkOrder: new FormControl('')
            }),
            status: new FormControl(''),
            comments: new FormControl('')
        });
        return _this;
    }
    CreateNewStorageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getCounties();
        this.myTeamId = this.userservice.teamId;
        this.dialogFormGroup.get('team').valueChanges.subscribe(function (val) {
            _this.teamId = val;
            _this.getTeamOrders(val);
        });
    };
    CreateNewStorageComponent.prototype.getCounties = function () {
        var _this = this;
        this.storageService.getCounties().subscribe(function (data) {
            _this.counties = data;
        });
    };
    CreateNewStorageComponent.prototype.getTeamOrders = function (teamId) {
        var _this = this;
        this.storageService.getOrderList(teamId).subscribe(function (data) {
            _this.orders = data;
        });
    };
    CreateNewStorageComponent.prototype.getCurrentPosition = function () {
        this.currentPos = this.gpsService.getCurrentPos();
        if (this.currentPos && this.currentPos.gpsData) {
            var lat = this.currentPos.gpsData.lat.toFixed(7);
            var lon = this.currentPos.gpsData.lon.toFixed(7);
            this.dialogFormGroup.patchValue({ position: lat + ';' + lon });
        }
    };
    CreateNewStorageComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    CreateNewStorageComponent.prototype.confirm = function (event) {
        var _this = this;
        var name = this.dialogFormGroup.get('name').value;
        var team = this.teamId;
        var county = this.dialogFormGroup.get('county').value;
        var plannedPosition = this.dialogFormGroup.get('plannedPosition').value;
        var position = this.dialogFormGroup.get('position').value;
        var order = this.dialogFormGroup.get('order.linkOrder').value;
        var status = this.dialogFormGroup.get('status').value;
        var comments = this.dialogFormGroup.get('comments').value;
        var storage = {
            "subject": name,
            "teamId": team,
            "countyNumber": county,
            "status": "1",
            "comment": comments,
            "gpsPos": plannedPosition,
            "unloadGps": position,
            "order": order
        };
        this.storageService.addStorage(storage).subscribe(function (data) {
            _this.dialogRef.close(data);
        });
    };
    return CreateNewStorageComponent;
}(AbstractDialogComponent));
export { CreateNewStorageComponent };
