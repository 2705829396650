import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AbstractDialogComponent} from '../abstract-dialog/abstract-dialog.component';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-not-implemented-dialog',
  templateUrl: './not-implemented-dialog.component.html',
  styleUrls: ['./not-implemented-dialog.component.scss']
})
export class NotImplementedDialogComponent extends AbstractDialogComponent implements OnInit {

  dialogFormGroup: FormGroup;
  constructor(protected dialogRef: MatDialogRef<AbstractDialogComponent>) {
    super(dialogRef);
    this.dialogFormGroup = new FormGroup({
      '': new FormControl('', [])
    });
  }

  ngOnInit() {
  }
  confirm(event) {
    this.closeDialog();
  };

}
