<form class="projectForm" [formGroup]="timesheetProjectForm">
      <div class="projectForm" *ngFor="let row of projectRows.controls; let ind = index;">
            <div formArrayName="projectLine">
                  <div [formGroupName]="ind">
                        <mat-card>
                              <div [hidden] ="ind < saveIndex">
                                    <mat-form-field>
                                          <mat-label>Velg Projekt</mat-label>
                                          <mat-select formControlName="projectNumber" #singleSelect
                                                (ngModelChange)="onProjectSelect($event)">
                                                <mat-option>
                                                      <ngx-mat-select-search formControlName="projectNumberFilter"
                                                            placeholderLabel="Velg Projekt" i18n-placeholderLabel
                                                            noEntriesFoundLabel="'ingen angitt'"
                                                            i18n-noEntriesFoundLabel
                                                            (ngModelChange)="projectSearch($event)">
                                                      </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let project of filteredProjects | async"
                                                      [value]="project.projNum">
                                                      {{project.projName}}
                                                </mat-option>
                                          </mat-select>
                                    </mat-form-field>
                                    <mat-form-field>
                                          <mat-label>Velg SalaryCode</mat-label>
                                          <mat-select formControlName="salaryCode">
                                                <!-- <mat-option> Velg SalaryCode </mat-option> -->
                                                <mat-option *ngFor="let code of selectableSalaryCode"
                                                      [value]="code.salaryCode[0]">
                                                      {{code.salaryCode[0]+" "+code.salaryCode[1]}}
                                                </mat-option>
                                          </mat-select>
                                    </mat-form-field>
                              </div>
                              <div *ngIf = "ind < saveIndex && dayProjects.length > 0"> 
                                    <p><strong>Project: </strong> &nbsp; {{dayProjects[ind].projectNumber + " " + dayProjects[ind].projectName}}</p>
                                    <p><strong> SalaryCode: </strong> &nbsp; {{dayProjects[ind].salaryCode + " " + dayProjects[ind].salaryCodeName}}</p>
                              </div>
                              <mat-form-field>
                                    <mat-label>Arbeid timer</mat-label>
                                    <input type="number" matInput [placeholder]="" formControlName="hoursWork"
                                          (ngModelChange)="hoursChange($event)" />
                              </mat-form-field>
                              <mat-form-field>
                                    <mat-label>Kommentar</mat-label>
                                    <textarea matInput [placeholder]="" formControlName="comment"></textarea>
                              </mat-form-field>
                              <div hidden>
                                    <mat-form-field>
                                          <input matInput [placeholder]="" formControlName="unid" />
                                    </mat-form-field>
                                    <mat-form-field>
                                          <input type="number" matInput [placeholder]=""
                                                formControlName="projectNumberFilter" />
                                    </mat-form-field>
                              </div>

                        </mat-card>
                        <br>
                  </div>
            </div>
      </div>
</form>
<div  *ngIf = "this.empId == this.currentUserId">
      <button mat-raised-button color="primary" (click)="addProjectLineToFormArray()">Ny Prosjektlinje</button>
      <button mat-raised-button color="primary" class="save-button" (click)="saveWeekData()"
            [disabled]="this.projectRows.length < 1">Lagre</button>
</div>