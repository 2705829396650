<mat-table [dataSource]="dataSource" [class.isMobile]="isMobileView">
  <ng-container matColumnDef="sjaId">
    <mat-header-cell *matHeaderCellDef> Opprettet </mat-header-cell>
    <mat-cell *matCellDef="let sja">
      <span class="mobile-label">Opprettet:</span>
      {{sja.created|date:'dd.MM.yy HH:mm'}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="sjaStatus">
    <mat-header-cell *matHeaderCellDef> Parsell </mat-header-cell>
    <mat-cell *matCellDef="let sja">
      <span class="mobile-label">Parsell:</span> {{sja.parsells}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="sjaDate">
    <mat-header-cell *matHeaderCellDef> Arkivert </mat-header-cell>
    <mat-cell *matCellDef="let sja">
      <span class="mobile-label">Arkivert:</span> {{sja.archived|date:'dd.MM.yy HH:mm'}} </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
  <mat-row *matRowDef="let myRowData; columns: columnsToDisplay" matRipple class="element-row" (click)="onSjaClick(myRowData)"></mat-row>
</mat-table>