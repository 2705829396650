import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import * as moment from 'moment';
var WeekInputComponent = /** @class */ (function () {
    function WeekInputComponent() {
        this.dateChange = new EventEmitter();
        if (!this.selectedDate) {
            this.selectedDate = moment();
        }
    }
    WeekInputComponent.prototype.getNextWeek = function () {
        this.selectedDate = moment(this.selectedDate).add(1, 'week');
        this.dateChange.next(this.selectedDate);
    };
    WeekInputComponent.prototype.getPrevWeek = function () {
        this.selectedDate = moment(this.selectedDate).add(-1, 'week');
        this.dateChange.next(this.selectedDate);
    };
    WeekInputComponent.prototype.ngOnInit = function () {
    };
    WeekInputComponent.prototype.ngOnDestroy = function () {
    };
    WeekInputComponent.prototype.onContainerClick = function (event) {
    };
    Object.defineProperty(WeekInputComponent.prototype, "selectedWeek", {
        get: function () {
            return moment(this.selectedDate).isoWeek();
        },
        enumerable: true,
        configurable: true
    });
    return WeekInputComponent;
}());
export { WeekInputComponent };
