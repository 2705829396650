import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { MatFormFieldControl } from '@angular/material';
import * as moment from 'moment';
import { Observer, Observable } from 'rxjs';

@Component({
  selector: 'app-week-input',
  templateUrl: './week-input.component.html',
  styleUrls: ['./week-input.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: WeekInputComponent}],
})
export class WeekInputComponent implements OnInit, OnDestroy {
  @Input() selectedDate: moment.Moment;
  @Output() dateChange: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();
  constructor() {
    if (!this.selectedDate) {
      this.selectedDate = moment();
    }
  }

  getNextWeek() {
    this.selectedDate = moment(this.selectedDate).add(1, 'week');
    this.dateChange.next(this.selectedDate);
  }
  getPrevWeek() {
    this.selectedDate = moment(this.selectedDate).add(-1, 'week');
    this.dateChange.next(this.selectedDate);
  }
  ngOnInit() {
  }

  ngOnDestroy() {
  }
  onContainerClick(event: MouseEvent) {
  }

  get selectedWeek() {
    return moment(this.selectedDate).isoWeek();
  }
}
