import {MeasuringPoint} from './measuring-point';
import {Article} from './article';
import {GGAsentence} from './ggasentence';

export class VerifyArticlesPoint {
// "308fe255-5be6-4507-b941-b613c974bfe5"
  uniqueId: string;

// ordren sin plassering  i ordreliste
  orderIndex: number;

// parsellens plassering i ordrens parsell-liste
  allotmentIndex: number;

// Posisjons-objektet
  gga: GGAsentence;

// indikator på dekning eller ikke
  hasPosition: boolean;

// angitt posisjon dersom ikke dekning
  givenPositionKey: string;

// alltid null, ikke i bruk lengre, bruker measuringPointIds
  measuringPoints: Array<MeasuringPoint>;

// Artikler beregnet på tellepunktet
  articles: Array<Article>;

// id-liste for hvilke målepunkt som er med i artikkelberegningen
  measuringPointIds: Array<string>;
}
