/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./download-progress.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "./download-progress.component";
import * as i6 from "@angular/material/bottom-sheet";
import * as i7 from "../../electron.service";
var styles_DownloadProgressComponent = [i0.styles];
var RenderType_DownloadProgressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DownloadProgressComponent, data: {} });
export { RenderType_DownloadProgressComponent as RenderType_DownloadProgressComponent };
export function View_DownloadProgressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Laster ned oppdatering..."])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "determinate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(3, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"], mode: [1, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = ((_co.progress == null) ? null : _co.progress.percent); var currVal_4 = "determinate"; _ck(_v, 3, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 3).mode === "indeterminate") || (i1.ɵnov(_v, 3).mode === "query")) ? null : i1.ɵnov(_v, 3).value); var currVal_1 = i1.ɵnov(_v, 3).mode; var currVal_2 = i1.ɵnov(_v, 3)._isNoopAnimation; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_DownloadProgressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-download-progress", [], null, null, null, View_DownloadProgressComponent_0, RenderType_DownloadProgressComponent)), i1.ɵdid(1, 114688, null, 0, i5.DownloadProgressComponent, [i6.MatBottomSheetRef, i7.ElectronService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DownloadProgressComponentNgFactory = i1.ɵccf("app-download-progress", i5.DownloadProgressComponent, View_DownloadProgressComponent_Host_0, {}, {}, []);
export { DownloadProgressComponentNgFactory as DownloadProgressComponentNgFactory };
