import { Injectable } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Observable, BehaviorSubject } from 'rxjs';
import { IMedia } from './interfaces/i-media';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  private media: BehaviorSubject<IMedia> = new BehaviorSubject<IMedia>({isMobileView: false});
  constructor(private mediaMatcher: MediaMatcher) {
    const isMobileView = window.innerWidth <= 900;
    this.media.next({isMobileView: isMobileView});
    const mediaQueryList = mediaMatcher.matchMedia('(min-width: 900px)');
    mediaQueryList.addEventListener('change', (event) => this.mediaListener(event));
  }

  mediaListener(event: MediaQueryListEvent) {
    const isMobileView = !(event && event.matches);
    this.media.next({isMobileView: isMobileView});
  }

  getMedia(): Observable<IMedia> {
    return this.media as Observable<IMedia>;
  }
}
