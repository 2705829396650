var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { AppConfig } from '../../../environments/environment';
import { AbstractDialogComponent } from '../abstract-dialog/abstract-dialog.component';
import { CameraComponent } from '../camera/camera.component';
import { take } from 'rxjs/operators';
var CameraDialogComponent = /** @class */ (function (_super) {
    __extends(CameraDialogComponent, _super);
    function CameraDialogComponent(dialogRef, data, http, snackbar) {
        var _this = _super.call(this, dialogRef) || this;
        _this.dialogRef = dialogRef;
        _this.http = http;
        _this.snackbar = snackbar;
        _this.showLive = false;
        console.log('orderData', data);
        _this.order = data.order;
        return _this;
    }
    CameraDialogComponent.prototype.confirm = function (event) {
        this.dialogRef.close({ "data": "test" });
    };
    CameraDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close(this.canvasImage);
    };
    CameraDialogComponent.prototype.triggerSnapshot = function () {
        this.showLive = false;
        this.camera.takePicture();
    };
    CameraDialogComponent.prototype.saveImg = function () {
        var _this = this;
        this.snackbar.open('Lagrer bilde...', 'Lukk', { duration: 1000 });
        //this.camera.saveImg2(this.order);
        this.http.post(AppConfig.apiUrl + "/images", this.fd).pipe(take(1)).subscribe(function (res) {
            _this.snackbar.open('Bilde lagret', 'Lukk', { duration: 1000 });
            _this.dialogRef.close({ 'status': 'ok' });
        });
    };
    CameraDialogComponent.prototype.newImg = function () {
        this.showLive = true;
        this.camera.newImage();
        //this.camera.showLive = true;
    };
    CameraDialogComponent.prototype.getImage = function (e, order) {
        var _this = this;
        //this.canvasImage = e    
        console.log('save image', e.formData);
        this.fd = e.formData;
        this.fd.append('orderNum', this.order.orderNum);
        this.fd.append('snum', this.order.snum);
        this.fd.append('orderUnid', this.order.unid);
        this.snackbar.open('Lagrer bilde...', 'Lukk', { duration: 1000 });
        //this.camera.saveImg2(this.order);
        this.http.post(AppConfig.apiUrl + "/images", this.fd).pipe(take(1)).subscribe(function (res) {
            _this.snackbar.open('Bilde lagret', 'Lukk', { duration: 1000 });
            _this.dialogRef.close({ 'status': 'ok' });
        });
    };
    CameraDialogComponent.prototype.ngOnInit = function () {
    };
    return CameraDialogComponent;
}(AbstractDialogComponent));
export { CameraDialogComponent };
