import {Component, Inject, OnInit} from '@angular/core';
import {AbstractDialogComponent} from '../abstract-dialog/abstract-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormGroup} from '@angular/forms';
import {MeasuringPoint} from '../../measuring-point';

@Component({
  selector: 'app-confirm-delete-point-dialog',
  templateUrl: './confirm-delete-point-dialog.component.html',
  styleUrls: ['./confirm-delete-point-dialog.component.scss']
})
export class ConfirmDeletePointDialogComponent extends AbstractDialogComponent implements OnInit {
  dialogFormGroup: FormGroup;
  pointToDelete: MeasuringPoint;
  isLastVerifiedPoint: boolean;
  constructor(public dialogRef: MatDialogRef<AbstractDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    super(dialogRef);
    this.dialogFormGroup = new FormGroup({});
    this.pointToDelete = this.data.pointToDelete;
    this.isLastVerifiedPoint = this.data.isLastVerifiedPoint;
  }

  deleteArticlesOnClose(event, deleteArticles: boolean) {
    const result = {
      type: this.data.type,
      deleteArticles: deleteArticles,
      pointToDelete: this.pointToDelete,
      isLastVerifiedPoint: this.isLastVerifiedPoint};
    this.dialogRef.close(result);
  }
  ngOnInit() {
  }
  confirm(event): void {
    this.dialogRef.close('cancel');
  }

}
