import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from '../environments/environment';
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
        this._teamId = 'Z';
        this._userName = 'Bruker';
        this._commonName = 'Bruker';
        this.userChanged$ = new BehaviorSubject(this._userName);
        this.apiUrl = AppConfig.apiUrl;
    }
    Object.defineProperty(UserService.prototype, "teamId", {
        get: function () { return this._teamId; },
        set: function (id) { this._teamId = id; },
        enumerable: true,
        configurable: true
    });
    ;
    ;
    Object.defineProperty(UserService.prototype, "userName", {
        get: function () { return this._userName; },
        set: function (userName) { this._userName = userName; },
        enumerable: true,
        configurable: true
    });
    ;
    ;
    Object.defineProperty(UserService.prototype, "commonName", {
        get: function () { return this._commonName; },
        set: function (commonName) { this._commonName = commonName; },
        enumerable: true,
        configurable: true
    });
    ;
    ;
    Object.defineProperty(UserService.prototype, "password", {
        get: function () {
            return this._password;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "roles", {
        get: function () { return this._roles; },
        set: function (roles) {
            this._roles = roles;
        },
        enumerable: true,
        configurable: true
    });
    ;
    UserService.prototype.isAdmin = function () {
        var isAdmin = false;
        if (this._roles && this._roles.length > 0) {
            this._roles.forEach(function (role) {
                if (role.indexOf("[Admin]") !== -1) {
                    isAdmin = true;
                }
            });
        }
        else {
            return false;
        }
        return isAdmin;
    };
    UserService.prototype.changeTeam = function (team) {
        this._teamId = team;
        return this.http.post(this.apiUrl + '/login/team', { teamId: team }, { withCredentials: true });
    };
    UserService.prototype.setCurrentUser = function () {
        var _this = this;
        return this.http.get(this.apiUrl + '/login/current', { withCredentials: true }).pipe(map(function (user) {
            _this._commonName = user.data.commonName;
            _this._roles = user.data.roles;
            _this._teamId = user.data.team;
            _this._userName = user.data.user;
        }));
    };
    UserService.prototype.getCurrentUser = function () {
        var _this = this;
        return this.http.get(this.apiUrl + '/login/current', { withCredentials: true }).pipe(map(function (user) {
            if (_this._userName == 'Bruker') {
                console.log('set user settings', user);
                _this._userName = user.data.user;
                _this._commonName = user.data.commonName;
                _this._roles = user.data.roles;
                _this._teamId = user.data.team;
            }
            return user.data;
        }));
    };
    UserService.prototype.setLoggedInUser = function (fullName, commonName, team, roles, password) {
        this._userName = fullName;
        this._commonName = commonName;
        this._teamId = team;
        this._roles = roles;
        this._password = password;
        this.userChanged$.next(this._userName);
    };
    UserService.prototype.getUser = function () {
        console.log("user get");
        return this.userChanged$.asObservable();
    };
    return UserService;
}());
export { UserService };
