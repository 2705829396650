import { Component, OnInit, Input } from '@angular/core';
import { TimeSheetService } from '../../../time-sheet.service';
import * as moment from 'moment';
import { UserService } from '../../../user.service';
import { IsiWeekPerson } from '../../../isi-week-person';

@Component({
  selector: 'app-week-team-diet',
  templateUrl: './week-team-diet.component.html',
  styleUrls: ['./week-team-diet.component.scss']
})
export class WeekTeamDietComponent implements OnInit {
  @Input() selectedDate: moment.Moment;
  @Input() selectedWeek: number;
  @Input() selectedYear: number;
  namesTeam = []
  diets = [];
  constructor(private timeSheetService: TimeSheetService, public user: UserService) {
  }

  get currentYear() {
    return moment(this.selectedDate).startOf('isoWeek').add(3, 'day').year();
  }
  get currentWeek() {
    return moment(this.selectedDate).isoWeek();
  }
  ngOnInit() {
    this.weekData(this.selectedWeek, this.selectedYear);
  }
  weekData(wn, y) {
    this.timeSheetService.getWeek(this.user.teamId, y, wn).subscribe(res => {
      this.namesTeam = Object.keys(res.dietsSummary);
      this.diets = res.dietsSummary;
    })
    } 
}
