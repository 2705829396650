var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DataSource } from '@angular/cdk/collections';
import { of } from 'rxjs';
var CompositeArticleDataSource = /** @class */ (function (_super) {
    __extends(CompositeArticleDataSource, _super);
    function CompositeArticleDataSource() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.data = [];
        return _this;
    }
    /** Connect function called by the table to retrieve one stream containing the data to render. */
    CompositeArticleDataSource.prototype.connect = function () {
        var rows = [];
        this.data.forEach(function (element) {
            rows.push(element, { detailRow: true, element: element });
        });
        return of(rows);
    };
    CompositeArticleDataSource.prototype.disconnect = function () { };
    return CompositeArticleDataSource;
}(DataSource));
export { CompositeArticleDataSource };
