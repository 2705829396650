<form class="dialog" #countDialogForm="ngForm" [formGroup]="dialogFormGroup" (ngSubmit)="confirm($event)">
  <h2>Beregn nye artikler</h2>
  <button class="btn-close" type="button" color="accent" mat-icon-button (click)="closeDialog()">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
  <mat-tab-group (selectChange)="onTabChanged($event)" [selectedIndex]="selectedIndex">
    <mat-tab label="Monteringsartikler">
      <!--<app-articles [type]="'measure'"></app-articles>-->
      Her kommer liste med artikler for montering
    </mat-tab>
    <mat-tab label="Rivingsartikler">
      <!--<app-articles [type]="'demolition'"></app-articles>-->
      Her kommer liste med artikler for riving
    </mat-tab>
  </mat-tab-group>
  <section class="checkbox-section">
    <!--<mat-checkbox *ngIf="selectedIndex===0" [value]="confirmDemolitionPoint" formControlName="demolitionCheckboxForm">Jeg bekrefter at rivingsartikler stemmer</mat-checkbox>-->
    <!--<mat-checkbox *ngIf="selectedIndex===1" [value]="confirmCountPoint" formControlName="countCheckboxForm">Jeg bekrefter at materiellartikler stemmer</mat-checkbox>-->
    <button *ngIf="selectedIndex===0" type="submit" class="btn-save success" color="primary" mat-raised-button>
      Legg til monteringsartikler {{selectedIndex}}
    </button>
    <button *ngIf="selectedIndex===1" type="submit" class="btn-save success" color="primary" mat-raised-button>
      Legg til rivingsartikler {{selectedIndex}}
    </button>
  </section>
  <!--<button type="submit" class="btn-save success" color="primary" mat-raised-button>-->
    <!--Lagre og lukk-->
  <!--</button>-->
  <div class="placeholder"></div>
</form>
