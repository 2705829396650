import { Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

private nightMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor() {
    if (localStorage.getItem('theme') === 'nightMode') {
      this.nightMode$.next(true);
    } else {
      this.nightMode$.next(false);
    }
  }
  toggleNightMode() {
    this.nightMode$.next(!this.nightMode$.value);
    localStorage.setItem('theme', this.nightMode$.value ? 'nightMode' : 'dayMode');
  }
  getNightMode(): Observable<boolean> {
    return this.nightMode$.asObservable();
  }
}
