<form class="mobile-dialog" [formGroup]="dialogFormGroup" (ngSubmit)="confirm($event)">
  <h2>{{title}}</h2>
  <button class="btn-close" color="accent" type="button" mat-icon-button (click)="closeDialog()">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
  <mat-form-field style="grid-row: 2;">
    <input matInput [matDatepicker]="picker" placeholder="Velg dato" formControlName="date" required>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <div >
    <mat-form-field style="width: 100%">
      <mat-label>Kommentar</mat-label>
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="10" formControlName="comment" required></textarea>
    </mat-form-field>
  </div>
  
  <button mat-raised-button class="btn-save" color="primary">Lagre dagføring</button>
  
  <div>
    <app-sja-risk-factors title="Risikomoment" [riskFactors]="data.riskFactors"
      (riskfactorAdded)="onRiskFactorAdded(data)" [archived]="archived" [inlineForm]="true">
    </app-sja-risk-factors>
  </div>
  
</form>