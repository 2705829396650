/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bridge.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./bridge.component";
import * as i3 from "../../settings.service";
var styles_BridgeComponent = [i0.styles];
var RenderType_BridgeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BridgeComponent, data: {} });
export { RenderType_BridgeComponent as RenderType_BridgeComponent };
export function View_BridgeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "agjerde-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, ":svg:svg", [[":xml:space", "preserve"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["enable-background", "new 0 0 1000 1000"], ["version", "1.1"], ["viewBox", "0 0 1000 1000"], ["x", "0px"], ["xmlns", "http://www.w3.org/2000/svg"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:metadata", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Svg Vector Icons : http://www.onlinewebfonts.com/icon "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:path", [["d", "M990,285.6v-61.3H10v61.3h122.5v122.5H10v367.5h122.5c0-135.3,164.5-245,367.5-245s367.5,109.7,367.5,245H990V408.1h-61.3V285.6H990z M193.7,408.1V285.6h122.5v122.5H193.7z M377.5,408.1V285.6H500v122.5H377.5z M561.3,408.1V285.6h122.5v122.5H561.3z M867.5,408.1H745V285.6h122.5V408.1z"]], [[1, "fill", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isNightMode ? "white" : "black"); _ck(_v, 5, 0, currVal_0); }); }
export function View_BridgeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bridge", [], [[2, "agjerde-icon-container", null]], null, null, View_BridgeComponent_0, RenderType_BridgeComponent)), i1.ɵdid(1, 245760, null, 0, i2.BridgeComponent, [i3.SettingsService, i1.Renderer2, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hostClass; _ck(_v, 0, 0, currVal_0); }); }
var BridgeComponentNgFactory = i1.ɵccf("app-bridge", i2.BridgeComponent, View_BridgeComponent_Host_0, {}, {}, []);
export { BridgeComponentNgFactory as BridgeComponentNgFactory };
