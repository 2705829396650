import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var StorageService = /** @class */ (function () {
    function StorageService(http) {
        this.http = http;
        this.apiurl = AppConfig.apiUrl;
    }
    StorageService.prototype.getOpenStorages = function () {
        var _this = this;
        var url = this.apiurl + "/storages/open";
        return new Observable(function (observer) {
            _this.http.get(url).pipe(map(function (res) {
                observer.next(res.data);
            })).subscribe();
        });
    };
    StorageService.prototype.getArkivStorages = function () {
        var _this = this;
        var url = this.apiurl + "/storages/archived";
        return new Observable(function (observer) {
            _this.http.get(url).pipe(map(function (res) {
                observer.next(res.data);
            })).subscribe();
        });
    };
    StorageService.prototype.getStorage = function (storageId) {
        var url = this.apiurl + "/storages/" + storageId;
        return this.http.get(url).pipe(map(function (storage) {
            return storage.data;
        }));
    };
    StorageService.prototype.getCounties = function () {
        var url = this.apiurl + "/storages/counties";
        return this.http.get(url).pipe(map(function (data) {
            return data.data;
        }));
    };
    StorageService.prototype.getArticleList = function () {
        var url = this.apiurl + "/articles/maincategories/materiale";
        return this.http.get(url).pipe(map(function (res) {
            return res.data;
        }));
    };
    StorageService.prototype.UpdateStorage = function (storageId, article) {
        var url = this.apiurl + "/storages/" + storageId + "/article";
        return this.http.post(url, article)
            .pipe(map(function (res) {
            return res.data.articles;
        }));
    };
    StorageService.prototype.getOrderList = function (teamId) {
        var url = this.apiurl + "/orders/open/" + teamId;
        return this.http.get(url).pipe(map(function (data) {
            return data.data;
        }));
    };
    StorageService.prototype.addStorage = function (storage) {
        var url = this.apiurl + "/storages/";
        return this.http.post(url, storage)
            .pipe(map(function (res) {
            return res.data;
        }));
    };
    StorageService.prototype.saveStorageAsArkiv = function (storage) {
        var url = this.apiurl + "/storages/" + storage.unid + "/archive";
        return this.http.post(url, storage).pipe(map(function (res) {
            return res.data;
        }));
    };
    StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(i0.ɵɵinject(i1.HttpClient)); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
