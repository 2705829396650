import { HttpClient } from '@angular/common/http';
import { UserService } from '../user.service';
import { AppConfig } from '../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../user.service";
var TimsheetProjectService = /** @class */ (function () {
    function TimsheetProjectService(http, userService) {
        this.http = http;
        this.userService = userService;
        this.apiUrl = AppConfig.apiUrl;
    }
    TimsheetProjectService.prototype.getProjects = function () {
        var url = this.apiUrl + "/projects";
        return this.http.get(url).pipe(map(function (res) {
            return res.data;
        }, function (error) {
            return error;
        }));
    };
    TimsheetProjectService.prototype.getSalaryCode = function () {
        var url = this.apiUrl + "/hours/weeks/salarycodes";
        return this.http.get(url).pipe(map(function (res) {
            return res.data;
        }, function (error) {
            return error;
        }));
    };
    TimsheetProjectService.prototype.getWeekProjectDetails = function (year, week, empId) {
        var url = this.apiUrl + "/hours/weeks/projects?emplId=" + empId + "&year=" + year + "&week=" + week;
        return this.http.get(url).pipe(map(function (res) {
            if (res) {
                return res.data;
            }
            return null;
        }, function (error) {
            return error;
        }));
    };
    TimsheetProjectService.prototype.saveWeekProjectDetails = function (projectWeekData) {
        var url = this.apiUrl + "/hours/weeks/hours/projects";
        return this.http.post(url, projectWeekData).pipe(map(function (res) {
            if (res) {
                return res.data;
            }
            return null;
        }, function (error) {
            return error;
        }));
    };
    TimsheetProjectService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TimsheetProjectService_Factory() { return new TimsheetProjectService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserService)); }, token: TimsheetProjectService, providedIn: "root" });
    return TimsheetProjectService;
}());
export { TimsheetProjectService };
