import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SignDataSource } from '../../../sja/sign-data-source';
import { BehaviorSubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatDialog, MatSnackBar } from '@angular/material';
import { SjaAddSignDialogComponent } from '../../sja/sja-add-sign-dialog/sja-add-sign-dialog.component';
import { GpsService } from '../../../gps/gps.service';
import { GpsData } from '../../../gps-data';
import { RoadRefPipe } from '../../../road-ref.pipe';
import { UserService } from '../../../user.service';
import { Sign } from '../../../sja/sign';
import { SignLog } from '../../../sja/sign-log';
import { Position } from '../../../sja/position';
import { SjaService } from '../../../sja/sja.service';
import { IsiRoadRef } from '../../../isi-road-ref';
import { MediaService } from '../../../shared/media.service';

@Component({
  selector: 'app-sja-signs-log',
  templateUrl: './sja-signs-log.component.html',
  styleUrls: ['./sja-signs-log.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SjaSignsLogComponent implements OnInit, OnChanges, OnDestroy {
  @Input() signs: Array<Sign>;
  @Input() extraSigns: Array<Sign>;
  @Input() archived: boolean;
  @Output() saveSigns = new EventEmitter();

  columnsToDisplay = ['sign', 'meter', 'number', 'side', 'required', 'actions', 'log', 'removeAction'];
  dataSource: SignDataSource;
  extraSignsDataSource: SignDataSource;
  subject: BehaviorSubject<any>;
  extraSignsSubject: BehaviorSubject<any>;
  mockSign: Sign;
  currentPos: GpsData;
  currentRoadRef: IsiRoadRef;
  hasRemovedSign: boolean;
  gpsSub: Subscription;
  isMobileView: boolean;

  constructor(public dialog: MatDialog,
    private gpsService: GpsService,
    private roadRefPipe: RoadRefPipe,
    private userService: UserService,
    private sjaService: SjaService,
    private snackbar: MatSnackBar,
    private mediaService: MediaService) {
      this.mediaService.getMedia().subscribe(media => {
        this.isMobileView = media.isMobileView;        
      })
    this.signs = new Array();
    this.hasRemovedSign = false;
    this.mockSign = new Sign();
    this.mockSign.id = "362-50";
    const logEntry: SignLog = new SignLog();
    logEntry.date = new Date().getTime();
    logEntry.comment = "";
    logEntry.user = "Test";
    logEntry.status = Sign.PLACED;
    const pos: Position = new Position();
    pos.meter = 100;
    logEntry.position = pos;
    this.mockSign.side = 'B';
    this.mockSign.count = 2;
    this.mockSign.required = 'ja';
    this.mockSign.log = new Array<SignLog>();
    this.mockSign.log.push(logEntry);
  }

  ngOnInit() {
    this.gpsSub = this.gpsService.getObservable().subscribe(gpsAndRoadRef => {
      this.currentPos = gpsAndRoadRef.gpsData;
      this.currentRoadRef = gpsAndRoadRef.roadRef;
    });
    if (this.userService.commonName === 'Bruker') {
      this.userService.setCurrentUser().pipe(take(1)).subscribe();
    }

  }

  ngOnDestroy() {
    if (this.gpsSub) {
      this.gpsSub.unsubscribe();
    }
  }
  isSignPlaced(sign): boolean {
    const signStatus = sign.log[sign.log.length - 1].status;
    return signStatus === Sign.CREATED || signStatus === Sign.REMOVED;
  }

  ngOnChanges(event) {
    this.subject = new BehaviorSubject(this.signs);
    this.dataSource = new SignDataSource(this.subject.asObservable());

    if (!this.extraSigns) {
      this.extraSigns = [];
    }

    this.extraSignsSubject = new BehaviorSubject(this.extraSigns);
    this.extraSignsDataSource = new SignDataSource(this.extraSignsSubject.asObservable());

    this.hasNightRemovedSigns();
  }

  refresh(signs) {
    this.subject.next(signs);
  }

  hasNightRemovedSigns() {
    this.hasRemovedSign = false;
    if (this.signs) {
      for (let i = 0; i < this.signs.length; i++) {
        const sign: Sign = this.signs[i];
        if (sign.log[sign.log.length - 1].status === Sign.REMOVED_FOR_NIGHT) {
          this.hasRemovedSign = true;
          break;
        }
      }
    }
    // check extra signs
    if (this.extraSigns) {
      for (let i = 0; i < this.extraSigns.length; i++) {
        const sign: Sign = this.extraSigns[i];
        if (sign.log[sign.log.length - 1].status === Sign.REMOVED_FOR_NIGHT) {
          this.hasRemovedSign = true;
          break;
        }
      }
    }
  }

  onSignClick(row) {
  }

  getSignImg(id) {
    return id.replace(/-/g, '_') + '.jpg';
  }

  editSign(sign: Sign) {
    const dialogRef = this.dialog.open(SjaAddSignDialogComponent, {
      height: '80%',
      width: '90%',
      data: { type: "edit", sign: sign }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.sign) {
        sign.id = result.sign;
        sign.side = result.side;
        sign.log[sign.log.length - 1].position.meter = result.meters;
        sign.log[sign.log.length - 1].position.road = result.road;
        sign.log[sign.log.length - 1].position.section = result.section;
        sign.log[sign.log.length - 1].position.subSection = result.subSection;
        sign.log[sign.log.length - 1].position.shortForm = result.shortForm;
        sign.log[sign.log.length - 1].date = new Date().getTime();
        sign.log[sign.log.length - 1].user = this.userService.commonName;
        sign.log[sign.log.length - 1].comment = result.comment;
        if (result.coordinates) {
          sign.log[sign.log.length - 1].position.coordinates = result.coordinates;
        }

        this.save();
      }
    });
  }

  hasExtrasigns() {
    if (this.extraSigns && this.extraSigns.length > 0) {
      return true;
    }
    return false;
  }

  newSign() {
    // add new extra sign
    const dialogRef = this.dialog.open(SjaAddSignDialogComponent, {
      height: '80%',
      width: '90%',
      data: { type: "new" }
    });

    dialogRef.afterClosed().subscribe(result => {
      // add to extrasigns array
      if (result && result.sign) {
        const sign = new Sign();
        sign.id = result.sign;
        sign.required = 'Nei';
        sign.side = result.side;
        sign.count = 1;
        if (result.side === 'B') {
          sign.count = 2;
        }
        const log = new SignLog();
        log.status = Sign.PLACED;
        log.date = new Date().getTime();
        log.user = this.userService.commonName;
        const position = new Position();
        position.meter = result.meters;
        position.coordinates = result.coordinates;
        position.road = result.road;
        position.section = result.section;
        position.subSection = result.subSection;
        position.shortForm = result.shortForm;
        log.position = position;
        log.comment = result.comment;
        sign.log = [];
        sign.log.push(log);
        if (!this.extraSigns) {
          this.extraSigns = [];
        }

        this.extraSigns.push(sign);
        this.extraSignsSubject.next(this.extraSigns);
        this.save();
      }

    });
  }

  placeSign(sign) {
    if (!this.currentPos || this.currentPos.lat === 0.0) {
      this.snackbar.open("Ingen posisjon fra GPS, setter skilt ut i fra skiltplan.", '', { duration: 5000 });
      const logEntry: SignLog = new SignLog();
      logEntry.status = Sign.PLACED;
      logEntry.user = this.userService.commonName;
      logEntry.date = new Date().getTime();
      const pos = new Position();
      pos.meter = sign.log[0].position.meter;
      logEntry.position = pos;
      sign.log.push(logEntry);
      this.save();
    } else {
      const logEntry: SignLog = new SignLog();
      logEntry.status = Sign.PLACED;
      logEntry.user = this.userService.commonName;
      logEntry.date = new Date().getTime();
      const position: Position = new Position();
      position.coordinates = [this.currentPos.lat, this.currentPos.lon];
      if (this.currentRoadRef && this.currentRoadRef.meter) {
        position.meter = this.currentRoadRef.meter;
        position.section = this.currentRoadRef.section;
        position.subSection = this.currentRoadRef.subSection;
        position.shortForm = this.currentRoadRef.shortForm;
        position.road = this.currentRoadRef.shortForm.split(" ")[0];
      }
      logEntry.position = position;
      sign.log.push(logEntry);
      this.save();
    }

  }
  save() {
    this.saveSigns.emit();
  }

  verify() {
    this.signs.forEach((sign: Sign) => {
      if (sign.log[sign.log.length - 1].status === Sign.PLACED) {
        this.verifySign(sign);
      }
    });
    this.extraSigns.forEach((sign: Sign) => {
      if (sign.log[sign.log.length - 1].status === Sign.PLACED) {
        this.verifySign(sign);
      }
    });
    this.save();
  }

  verifySign(sign: Sign) {
    const log = new SignLog();
    log.status = Sign.VERIFIED;
    log.user = this.userService.commonName;
    log.date = new Date().getTime();
    sign.log.push(log);
  }

  removeAll(night: boolean) {
    this.sjaService.removeAllSigns(this.signs, night);
    this.sjaService.removeAllSigns(this.extraSigns, night);
    this.hasNightRemovedSigns();
    this.save();
  }

  removeOneSign(sign: Sign) {
    this.sjaService.removeSign(sign, false);
    this.save();
  }

  placeSignsBack() {
    // place signs back from last position before status REMOVED_FOR_NIGHT
    this.signs.forEach((sign: Sign) => {
      const signStatus = sign.log[sign.log.length - 1].status;
      if (signStatus === Sign.REMOVED_FOR_NIGHT) {
        this.setLastPosition(sign);
      }
    });
    this.extraSigns.forEach((sign: Sign) => {
      const signStatus = sign.log[sign.log.length - 1].status;
      if (signStatus === Sign.REMOVED_FOR_NIGHT) {
        this.setLastPosition(sign);
      }
    });

    this.hasNightRemovedSigns();
    this.save();
  }

  setLastPosition(sign: Sign) {
    // find last position..
    let pos: Position;
    for (let i = sign.log.length - 1; i >= 0; i--) {
      if (sign.log[i].status === Sign.PLACED) {
        pos = sign.log[i].position;
        break;
      }
    }

    const logEntry = new SignLog;
    logEntry.status = Sign.PLACED;
    logEntry.user = this.userService.commonName;
    logEntry.date = new Date().getTime();
    logEntry.position = pos;
    logEntry.comment = 'Satt ut igjen fra siste posisjon.';
    sign.log.push(logEntry);
    this.save();
  }

}
