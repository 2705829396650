import { Component, OnInit, Input } from '@angular/core';
import { ICulvert } from '../../i-culvert';

@Component({
  selector: 'app-culverts',
  templateUrl: './culverts.component.html',
  styleUrls: ['./culverts.component.scss']
})
export class CulvertsComponent implements OnInit {
  @Input() culverts: Array<ICulvert>
  constructor() {
    console.log("culverts", this.culverts);
  }

  ngOnInit() {
    this.culverts.forEach(culvert => {
      culvert['background'] = ''; 
    })
  }

  changeBackground(culvert: any) {
    if(culvert['background'] == '') {
    culvert['background'] = 'green'; } else {
      culvert['background'] = ''
    }
    console.log(culvert,this.culverts)
  }
}
