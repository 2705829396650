import { timer, Observable, Subject, zip } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { takeUntil, retryWhen, delay, tap, map, timeout, switchMap } from 'rxjs/operators';
// import 'rxjs/add/observable/timer';
import { GpsData } from '../gps-data';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from '../../environments/environment';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { ElectronService } from '../electron.service';
import { IsiRoadRef } from '../isi-road-ref';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../electron.service";
var GpsService = /** @class */ (function () {
    function GpsService(http, electronService) {
        var _this = this;
        this.http = http;
        this.electronService = electronService;
        this.ngUnsubscribe = new Subject();
        this.gps$ = new BehaviorSubject(new GpsData(0.0, 0.0));
        this.gpsWithRoadRef$ = new BehaviorSubject({ gpsData: new GpsData(0.0, 0.0), roadRef: null });
        this.startGps = function () {
            if (_this.electronService.isElectron()) {
                _this._gps = new _this.GPS;
                _this.initComPort();
                _this.gpsObserver();
            }
            else {
                _this.watchID = navigator.geolocation.watchPosition(function (position) {
                    console.log('position', position);
                    var gpsData = new GpsData(position.coords.latitude, position.coords.longitude);
                    gpsData.quality = Number(position.coords.accuracy).toString();
                    gpsData.hdop = 2.5;
                    gpsData.type = 'WEB';
                    _this.gps$.next(gpsData);
                });
            }
        };
        this.startFakeGps = function () {
            _this._gps = new _this.GPS;
            var next = 100;
            var lines = _this.fs.readFileSync('src/assets/output_e136.nmea', 'utf-8')
                .split('\n')
                .filter(Boolean);
            var t = timer(0, 1000).pipe(takeUntil(_this.ngUnsubscribe));
            t.subscribe(function () {
                _this._gps.update(lines[next]);
                if (!lines[next]) {
                    next = 0;
                }
                else {
                    next++;
                }
            });
            _this.gps$.pipe(takeUntil(_this.ngUnsubscribe));
            _this._gps.on('data', function (data) {
                if (data.lat) {
                    _this.roadRefWebSocket.next(data);
                    _this.gps$.next(data);
                }
            });
        };
        this.gpsObserver = function () {
            _this.gps$.pipe(takeUntil(_this.ngUnsubscribe));
            _this.comPortObserver().pipe(takeUntil(_this.ngUnsubscribe))
                .subscribe();
            _this._gps.on('data', function (data) {
                if (data && data.lat) {
                    _this.roadRefWebSocket.next(data);
                    _this.gps$.next(data);
                }
            });
        };
        this.comPortObserver = function () {
            return _this.com = Observable.create(function (observer) {
                _this._parser.on('data', function (data) {
                    try {
                        var nmea = data;
                        if (nmea.indexOf('GPGGA') !== -1) {
                            _this._gps.update(nmea);
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                });
            });
        };
        this.getGpsData = function () {
            return new Observable(function (observer) {
                return _this.http.get('assets/gps-data.json').subscribe(function (data) {
                    setInterval(function () {
                        observer.next(data);
                    }, 1000);
                });
            });
        };
        console.log("this.roadRefWebSocket", this.roadRefWebSocket);
        this.setupGps();
    }
    GpsService.prototype.beforeunload = function () {
        this.stopGps();
    };
    GpsService.prototype.setupGps = function () {
        var _this = this;
        console.log("setup gps");
        if (this.electronService.isElectron()) {
            this.SerialPort = window.require('serialport');
            // this.SerialPort.Binding = window.require('@serialport/bindings');
            this.GPS = window.require('gps');
            this._gps = new this.GPS;
            this.geolib = window.require('geolib');
            this.fs = window.require('fs');
            this.Readline = window.require('@serialport/parser-readline');
            window.require('electron').ipcRenderer.on('gps', function (event, action) {
                if (action === 'stop') {
                    _this.stopGps();
                }
            });
        }
        if (AppConfig.production) {
            this.startGps();
        }
        else {
            this.startGps();
        }
        if (this.electronService.isElectron()) {
            this.roadRefWebSocket = webSocket('ws://localhost:8080/roadref/roadref-ws');
            this.roadRefWebSocket.asObservable().pipe(retryWhen(function (errors) {
                return errors.pipe(tap(function (err) {
                    console.error('Got error', err);
                }), delay(1000));
            }));
            zip(this.gps$, this.roadRefWebSocket).pipe(timeout(4000), retryWhen(function (errors) {
                return errors.pipe(tap(function (err) {
                    console.log('gps timeout', err);
                    _this.gpsWithRoadRef$.next({ gpsData: null, roadRef: null });
                    try {
                        if (!_this._port.isOpen) {
                            _this.ngUnsubscribe.next();
                            _this.initComPort();
                            _this.gpsObserver();
                        }
                    }
                    catch (erro) {
                        console.log('feil', erro);
                    }
                }), delay(2000));
            })).subscribe(function (gpsDataAndRoadRef) {
                _this.gpsWithRoadRef$.next({ gpsData: gpsDataAndRoadRef[0], roadRef: gpsDataAndRoadRef[1] });
            }, function (err) {
                console.log('error', err);
                _this.gpsWithRoadRef$.next({ gpsData: null, roadRef: null });
            });
        }
        else {
            this.roadRefWebSocket = new WebSocketSubject('');
            this.gps$.pipe(switchMap(function (gpsData) {
                return _this.getRoadRefWeb(gpsData.lat, gpsData.lon).pipe(map(function (roadRef) {
                    return { gpsData: gpsData, roadRef: roadRef };
                }));
            })).subscribe(function (_a) {
                var gpsData = _a.gpsData, roadRef = _a.roadRef;
                _this.gpsWithRoadRef$.next({ gpsData: gpsData, roadRef: roadRef });
            });
        }
    };
    GpsService.prototype.getRoadRefWeb = function (lat, lon) {
        return this.http.get("https://nvdbapiles-v3.atlas.vegvesen.no/posisjon?lat=" + lat + "&lon=" + lon)
            .pipe(map(function (data) {
            var rr = new IsiRoadRef();
            var vegref = data[0].vegsystemreferanse;
            if (vegref) {
                rr.roadCategory = vegref.vegsystem.vegkategori;
                rr.roadStatus = vegref.vegsystem.fase;
                rr.section = vegref.strekning.strekning;
                rr.subSection = vegref.strekning.delstrekning;
                rr.meter = vegref.strekning.meter;
                rr.roadNumber = vegref.vegsystem.nummer;
                if (vegref.kommune) {
                    var kommune = vegref.kommune.toString();
                    rr.municipality = kommune.substr(0, kommune.length - 2);
                    rr.county = kommune.substr(kommune.length - 2);
                }
                rr.shortForm = vegref.kortform;
            }
            return rr;
        }));
    };
    GpsService.prototype.getObservable = function () {
        return this.gpsWithRoadRef$.asObservable();
    };
    GpsService.prototype.getCurrentPos = function () {
        return this.gpsWithRoadRef$.getValue();
    };
    GpsService.prototype.stopGps = function () {
        this.ngUnsubscribe.next();
        if (this._port && this._port.isOpen) {
            this._port.close();
            this.roadRefWebSocket.unsubscribe();
        }
    };
    GpsService.prototype.distance = function (from, to) {
        return this.GPS.Distance(from.lat, from.lon, to.lat, to.lon) * 1000;
    };
    GpsService.prototype.initComPort = function () {
        this._port = new this.SerialPort('COM14', {
            baudRate: 4800
        });
        this._parser = this._port.pipe(new this.Readline());
    };
    /**
     * all params are radians
     * https://www.movable-type.co.uk/scripts/latlong.html
     * @param {number} lat1
     * @param {number} lon1
     * @param {number} lat2
     * @param {number} lon2
     * @returns {number}
     */
    GpsService.prototype.getDistance = function (lat1, lon1, lat2, lon2) {
        var radius = 6371e3;
        var deltaLat = lat2 - lat1;
        var deltaLon = lon2 - lon1;
        var a = Math.pow(Math.sin(deltaLat / 2), 2) +
            Math.cos(lat1) * Math.cos(lat2) *
                Math.pow(Math.sin(deltaLon / 2), 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return radius * c;
    };
    GpsService.prototype.getRadiansFromDegreesMinutes = function (degMinutes) {
        return this.getDecimalDegreesFromDegreeMinutes(degMinutes) * Math.PI / 180;
    };
    GpsService.prototype.getDecimalDegreesFromDegreeMinutes = function (degMinutes) {
        return parseFloat(degMinutes.substr(0, degMinutes.indexOf('.') - 2)) +
            parseFloat(degMinutes.substr(degMinutes.indexOf('.') - 2)) / 60;
    };
    GpsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GpsService_Factory() { return new GpsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ElectronService)); }, token: GpsService, providedIn: "root" });
    return GpsService;
}());
export { GpsService };
