import { Component, OnInit, OnDestroy } from '@angular/core';
import {UserService} from '../user.service';
import { HeaderService } from '../components/header/header.service';
import { MediaService } from '../shared/media.service';
import { FormGroup, FormControl } from '@angular/forms';
import { DataService } from '../data.service';

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.scss']
})
export class InfoPageComponent implements OnInit ,OnDestroy{
  news: any;
  selected: any;
  defaultNews: any;
  defaultNewsIndex: any;
  isMobileView: boolean;
  step = 0;
  frmGroup: FormGroup;
  footerFormGroup: FormGroup;
  selectFormControl = new FormControl('');

  setStep(index: number) {
    this.step = index;
  }
  constructor(private dataService: DataService,
    public userService: UserService,
    private headerService: HeaderService,
    private mediaService: MediaService) { 
      this.mediaService.getMedia().subscribe( media => {
        this.isMobileView = media.isMobileView;
      })
      this.footerFormGroup = new FormGroup({});
    }

  ngOnInit() {
    this.headerService.addNavItem({
      route: `/info`,
      label: `Info`,
      queryParams: ''
    });
    this.getNews();
  }

  getNews(){
    this.dataService.getNewsItems().pipe().subscribe(data=> {
      this.news = data;
      this.defaultNews = data[0];
      this.defaultNewsIndex = 0
      this.selectFormControl.setValue( this.defaultNewsIndex );
    })
  }
  changeDefaultNews(selected) {
    this.defaultNewsIndex = selected;
    this.defaultNews = this.news[this.defaultNewsIndex];
  }
  ngOnDestroy() {
    this.headerService.removeNavItem({
      route: `/info`,
      label: `Info`,
      queryParams: ''
    })
  }

}
